import i18n from './Translation';

export default function CrooglooSub(planId) {
  this.product = null;
  for (let storeProduct of global.productList) {
    if (storeProduct.planId == planId) {
      this.product = storeProduct;
      break;
    }
  }
  if (this.product === null) {
    throw new Error('Invalid plan ID: ' + planId);
  }
}

CrooglooSub.prototype.getName = function() {
  return this.product.name;
}

CrooglooSub.prototype.getDescription = function() {
  switch (this.product.billingPeriod) {
    case 'month':
      return i18n.t("js.sub.periods.month");
    case 'year':
      return i18n.t("js.sub.periods.year");
    default:
      return i18n.t("js.subscription");
  }
}

CrooglooSub.prototype.getAmount = function() {
  return this.product.price;
}

CrooglooSub.prototype.optionsOfTypeAsHtml = function() {
  return this.getFilteredOptions(false, true,
    p => p.productionType == this.product.productionType);
}

CrooglooSub.prototype.optionsAsHtml = function(disableActiveProduct = false, ignoreActivePlanReplacement = false) {
  return this.getFilteredOptions(disableActiveProduct, ignoreActivePlanReplacement, p => p.isVisibleInStore || p.planId == this.product.planId);
}

/**
 * If ignoreActivePlanReplacement is set to false, the active plan will not be 
 * retrieved if its not offered and/or replaced by an offered product.
 * If ignoreActivePlanReplacement is set to true, the active plan will always
 * be retrieved, and its replacement, if any, will not.
 */
CrooglooSub.prototype.getFilteredOptions = function(disableActiveProduct, ignoreActivePlanReplacement, filter) {
  let htmlOptions = '';
  for (let storeProduct of global.productList
      .filter(filter)
      .filter(p => ignoreActivePlanReplacement 
        ?(p.planId != this.product.replacedBy) 
        :(p.isOffered && typeof global.productList.find(p2 => p2.planId == p.replacedBy) == "undefined"))
      .sort((p1, p2) => (p1.price - p2.price) || p1.name.localeCompare(p2.name))) {
    htmlOptions += `<option value="${storeProduct.planId}" product="${storeProduct.productionType}"`;
    if (disableActiveProduct && storeProduct.planId == this.product.planId) {
      htmlOptions += ' disabled';
    }
    let priceString = storeProduct.price.toString();
    htmlOptions += `>${storeProduct.name} `
      + ` ($${priceString.substring(0, priceString.length-2)}.`
      + `${priceString.substring(priceString.length-2)}/${i18n.t("js.sub.billing."+(storeProduct.billingPeriod || "month"))}`
      + `)</option>`;
  }
  return htmlOptions;
}

CrooglooSub.areManyPlansForProductionType = function() {
  let activeProduct = global.productList
    .find(p => p.planId == croogloo_auth.crooglooauth.planId);
  let isReplacementForActivePlan = activeProduct.replacedBy != '' 
    && typeof global.productList
      .find(p => p.planId == activeProduct.replacedBy) != 'undefined';
  return global.productList
    .filter(p => p.productionType == croogloo_auth.crooglooauth.productionType 
      && p.planId != activeProduct.planId).length 
      + (isReplacementForActivePlan ?0 :1) > 1; 
}