import swal from 'sweetalert2';
import i18n from './components/Translation';

export function init() {
  loader.add(showSpinner)
        .add(initGlobals, 'globals')
        .add(ui, 'foundation')
        .add(getMetaData, ['security-lists', 'restricted-memebers', 'departments'])
        .add(initListeners)
        .execute();
}

function ui() {
  $('#main').foundation();
  disableAgentSwitch();
  disableOptionBtns();
  loader.check('foundation');
}

var contactQuery;

var groupMembers;
var notGroupMembers;

var groupMembers2;
var notGroupMembers2;

var currentSelection;
var list1;

var currentGroup;
var otherGroup;
var selectedGroup;

var selectedGroupContact;

var _firstVisist;
var isNewListCreated;

var isAutoAgentToggle;
var restrictedMembers;

var allDepartments, departmentMap;

function initGlobals() {
  groupMembers = [];
  notGroupMembers = [];

  groupMembers2 = [];
  notGroupMembers2 = [];

  currentSelection = "";
  list1 = null;

  currentGroup = [];
  otherGroup = [];
  selectedGroup = "";

  selectedGroupContact = "";

  _firstVisist = false;
  isNewListCreated = false;

  isAutoAgentToggle = false;
  restrictedMembers = {};

  allDepartments = [];
  departmentMap = {};

  loader.check('globals');
}

function getSelectedListName(selectId) {
  let listName = findSelectedCellForLists().innerText;

  if(listName.lastIndexOf('(') > 0) {
    listName = listName.substring(0, listName.lastIndexOf('(')-1);
  }
  if(!listName) {
    console.error('Failed getting selected list for ' + selectId);
  }
  return listName || '';
}

function showDeleteModal() {
  console.debug('selected list to delete is: '
    + document.getElementById('securityGroupList').value);

  if(document.getElementById('securityGroupList') !== '') {
    swal({
      title: i18n.t("js.sec.lists.conf-del"),
      html: i18n.t("js.sec.lists.del.msg")
        + ('<b>' + getSelectedListName() + '</b>')
          .replace(/^<b><\/b>$/, i18n.t("js.sec.lists.current"))
        + '?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      confirmButtonText: i18n.t("utils.delete"),
      confirmButtonColor: '#BC2121',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
      showCloseButton: true,
      preConfirm: function() {
        return new Promise((resolve, reject) => {
          deleteGroup(resolve, reject);
        });
      }
    }).then(function() {
      swal({
        title: i18n.t("response.success"),
        type: 'success',
        showCancelButton: false,
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK"),
        allowOutsideClick: true
      }).catch(swal.noop);
    }).catch(swal.noop);
  } else {
    cgToast(i18n.t("js.sec.no-list"));
    console.error('removal option should be disabled and protected by the groupSelectionBlocker function');
  }
}

function showAddModal() {
  swal({
    title: i18n.t("js.sec.add.swal"),
    input: 'text',
    inputPlaceholder: i18n.t("js.sec.listname"),
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    confirmButtonText: i18n.t("button.confirm"),
    confirmButtonColor: '#13C46A',
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !swal.isLoading(),
    showCloseButton: true,
    preConfirm: function(newGroupName) {
      return new Promise((resolve, reject) => {
        addGroup(newGroupName, resolve, reject);
      });
    }
  }).then(function() {
    swal({
      title: i18n.t("response.success"),
      type: 'success',
      showCancelButton: false,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("OK"),
      allowOutsideClick: true
    }).catch(swal.noop);
  }).catch(swal.noop);
}

function validateListName(listName) {
  listName = listName.trim();
  if(listName === '') {
    throw { message: i18n.t("js.sec.list.reject.name.empty") }
  }
  //important condition for functions calling querySelector with the lists' id
  else if(/^\d/.exec(listName)) {
    throw { message: i18n.t("js.sec.list.reject.name.format") }
  }
}

function isAlteringAdminList(listName, errorDesc, reject) {
  if(listName.toLowerCase() === 'admin') {

    reject(errorDesc);

    saveSystemActivity({
      action: 'failed request',
      params: 'none',
      message: errorDesc
    });

    return true;
  }
  return false;
}

function addGroup(newListName, resolve, reject) {
  if (croogloocurrentpage.getAccessLevel() < 2) {
    cgToast(i18n.t("js.unauthorized"));
    return;
  }
  newListName = newListName.trim();

  try {
    validateListName(newListName);
  } catch(e) {
    reject(e.message);
    return;
  }

  if(isAlteringAdminList(newListName, i18n.t("js.sec.admin-only"), reject)) {
    return;
  }

	var tmp = new Object();
	tmp.securityListName = newListName;

	saveSystemActivity({
		action: 'submit',
		params: findSystemActivityParams(tmp),
		message: 'User created a security list.'
	});

	apicall('securityadminapi','addSecurityList',tmp).then(function(resp) {
		if(resp && resp.responseCode && resp.responseCode === '0'){
			// hideAddModal();
      resolve();
      showSpinner();

			fillCombos(tmp.securityListName, tmp.securityListName, () => {
        $('#securityGroupList option[value="'+resp.entityId+'"]').prop('selected', true);
        initializeToFirstList();
        hideSpinner();
      });

    } else if (resp && resp.responseCode && resp.responseCode === '-1') {
      reject(i18n.t("js.sec.list.error.server"));
      hideSpinner();
		}
	});
}

function deleteGroup(resolve, reject) {
  if (croogloocurrentpage.getAccessLevel() < 2) {
    cgToast(i18n.t("js.unauthorized"));
    return;
  }
	var tmp = new Object();
  tmp.securityListName = findSelectedCellForLists().id;

  if(isAlteringAdminList(tmp.securityListName, i18n.t("js.sec.list.reject.admin-del"), reject)) {
    return;
  }

	saveSystemActivity({
		action: 'submit',
		params: findSystemActivityParams(tmp),
		message: 'User removed a distribution list.'
	});

	apicall('securityadminapi','removeSecurityList',tmp).then(function(resp) {
		if(resp){
			if(resp.responseCode === '0') {
        // TODO whether this is still needed should be tested out by removing
        // all lists. The selection should go back to its disabled option.
				// clearSelectBox();
			}
      resolve();
      showSpinner();
      $('div.contact-search').css('visibility', 'hidden');
      $('div.contact-search > label > input').val("");
			fillCombos('', '', () => {
        initializeToFirstList();
        hideSpinner();
      });
		}
	});
}

function hideAllTableElements() {
  $('table.distro-table').hide();
  $('#tableControlsContainer').hide();
  $('div.mngmt-text').hide();
  $('div#editListContainer').hide();
  $('div#tablePlaceholder').hide();
  $('div.add-remove-btn > a').hide();
  disableAgentSwitch();
  disableOptionBtns();
}

function clearSelectBox() {

	let select = document.getElementById("securityGroupList");
	select.innerHTML = '';

	if(select.className.includes('selectpicker')) {
		$('.selectpicker').selectpicker('render');
		$('.list-select').trigger('chosen:updated');
	}

}

function toggleCB() {
	var x = document.getElementById("securityGroupList").value;

    if (currentSelection != x && x != null && x != "") {
		currentSelection = x;
		reloadTable();
	}
}

function reloadTable(id) {
  //take the id of the selected security group and use it to call the api
  var tmp = new Object();

  var e = document.getElementById("securityGroupList");
  var distlist = '';

  
  if (e!=='undefined') {
    distlist = id;
    selectedGroup = id;
  }
  tmp.securityListId = distlist;

  if(!distlist) {
    hideAllTableElements();
    return;
  }

  apicall('securityadminapi','fetchSecurityListMembers',tmp).then(function(resp) {

    $('.container').css('display', 'flex')
    $('.contact-search').css('display', 'block')
    if (resp.items) {
      groupMembers = resp.items.filter(secListMember => secListMember.selected)
        .sort(compareSecListMembers);
      notGroupMembers = resp.items.filter(secListMember => !secListMember.selected)
        .sort(compareSecListMembers);
      rebuildTable();
      return currentGroup, otherGroup;
    }
  });

  return selectedGroup, currentGroup, otherGroup;
}

function compareSecListMembers(a, b) {
  try {
    let aDeptDisplayOrder = parseInt(departmentMap[a.departmentId].properties.displayOrder);
    let bDeptDisplayOrder = parseInt(departmentMap[b.departmentId].properties.displayOrder);
    if(aDeptDisplayOrder !== bDeptDisplayOrder) {
      return aDeptDisplayOrder - bDeptDisplayOrder;
    }
    return (b.firstName + (b.lastName || '')) // order must be reversed (using insertBefore)
      .localeCompare(a.firstName + (a.lastName || ''));
  } catch(e) {
    console.error(e);
    return 0;
  }
}

function fillCombo(listId,entityName,valueAttrib,displayAttrib,sortAttrib,
		selectedGroup, selectedGroupId, callback)
{
	showSpinner();

  //emptying the previous tables to avoid duplicates of the table
  if (document.getElementById("dist-table")){
    var emptytable = document.getElementById("dist-table");
    var first = emptytable.firstElementChild;
    while(first){
      first.remove();
      first = emptytable.firstElementChild;
    }
    emptytable.remove();
  }

	var tmp = new Object();
	tmp.entityName=entityName;
	tmp.valueAttrib=valueAttrib;
	tmp.displayAttrib=displayAttrib;
	tmp.sortAttrib=sortAttrib;
	tmp.filterAttribute='-';
	tmp.filterValue='-';

	var select = document.getElementById(listId);
  $('#'+listId+' > option').each(function(idx, opt) {
    if(opt.value) {
      $(this).remove();
    }
  });

  var selected = '';
  // IMPORTANT -- only specific kinds are allowed for the "entityName" property,
  // see API method implementation for details
  apicall('securityadminapi','getComboValues',tmp).then(function(resp) {

    loader.check('security-lists');

		if (resp.items)
			{
				let items = resp.items;
        var sortingList = ['ADMIN', 'DTR', 'CREW', 'EXEC'];
            items = items.sort((a, b) => sortingList.indexOf(a.label) - sortingList.indexOf(b.label));
				var str = "<table id='dist-table'>";
				let selectedValue = '';
				for (var i = 0; i<=items.length; i++)
					{
          var item = items[i];
					if (item!=null)
						{
						if (selectedGroup == item.label) {
              selected = ' selected';
							selectedValue = selectedGroup.toUpperCase();
						}

            if(item.label === "DH") {
              item.label = "DTR"
            }
              item.label = item.label.toUpperCase();
              str = str + "<tr><td id=\"" + item.value+"\">"+ item.label + "</td><tr>";
						}
					}
					select.innerHTML += str;
          select.value = selectedValue;
          str+= "</table>";

          //There is a bug that creates empty rows, this is removing them
          var distTable = document.getElementById('dist-table');
          var rows = distTable.getElementsByTagName('tr');
          rows.forEach(currentRow => {
            if (currentRow.innerHTML===''){
              currentRow.remove();
            }
          });
			}

		toggleCB();
    addRowHandlers();

    if(callback) {
      callback();
    }
  });

  //Adding a click event to each row 
  function addRowHandlers() {
    var table = document.getElementById("securityGroupList");
    var rows = table.getElementsByTagName("tr");
    for (var i = 0; i < rows.length; i++) {
      var currentRow = rows[i];
      var cell = currentRow.getElementsByTagName("td")[0];
      if (cell.hasAttribute('class')) {
        cell.removeAttribute('class');
      }

      var createClickHandler = function (row) {
        return function () {
          //removing the class clicked from all other contacts to remove previous background color
          var table = document.getElementById("securityGroupList");
          var rows = table.getElementsByTagName("tr");
          for (var i = 0; i < rows.length; i++) {
            var currentRow2 = rows[i];
            if (currentRow2 !== row) {
              var cell = currentRow2.getElementsByTagName("td")[0];
              cell.removeAttribute("class");
            }
          };
          var cell = row.getElementsByTagName("td")[0];
          cell.setAttribute('class', 'clicked');
          var id = cell.id;
          showSpinner()
          reloadTable(id);
        };
      };
      currentRow.onclick = createClickHandler(currentRow);
    }
  }
}

function fillCombos(selectedGroup, selectedGroupId, callback) {
  fillCombo("securityGroupList","security_list","securityListId",
    "securityListName","securityListName", selectedGroup, selectedGroupId,
     callback);
}

function getMetaData() {
	// Get the list of previous scores
  loadDepartments();
  fetchRestrictedMembers(() => loader.check('restricted-memebers'));
	fillCombos('', '');
	reloadTable(1);
}

function compareNameWithDefaults(a,b) {
  let nameSortBy = document.getElementById('sort-name-button').getElementsByTagName('i')[0].className;
  let depSortBy = document.getElementById('sort-dep-button').getElementsByTagName('i')[0].className;
 
  if(depSortBy == "fa fa-sort-down") {
    a = a.department;
    b = b.department;
    return a < b ?-1 :b < a ?1 :0;
  } else if(depSortBy == "fa fa-sort-up") {
    a = a.department;
    b = b.department;
    return b < a ?-1 :a < b ?1 :0;
  } else if(nameSortBy == "fa fa-sort-up") {    
    a = (a.firstName + " " +  a.lastName).toUpperCase();
    b = (b.firstName + " " +  b.lastName).toUpperCase();   
    return b < a ?-1 :a < b ?1 :0;
  } else {    
    a = (a.firstName + " " +  a.lastName).toUpperCase();
    b = (b.firstName + " " +  b.lastName).toUpperCase();   
    return a < b ?-1 :b < a ?1 :0;
  }
}

function compareNbNameAgent(p1, p2) {
  let sortingProp = 'firstName';
  let p1Nb = parseContactNumber(p1.getAttribute(sortingProp));
  let p2Nb = parseContactNumber(p2.getAttribute(sortingProp));
  if(p1Nb !== p2Nb) { return p1Nb - p2Nb }
  return p1.getAttribute(sortingProp).localeCompare(p2.getAttribute(sortingProp));
}

function fetchRestrictedMembers(done) {
  apicall('securityadminapi', 'fetchRestrictedMembers', {}).then(resp => {
    // resp.productionOwnerPersonId is not verified for backward compatibility
    if(resp.activeUserPersonId && resp.activeUserSecurityListId) {
      restrictedMembers = Object.assign({}, resp);
      done();
    } else {
      throw new Error('Invalid restricted member response.');
    }
  }).catch(err => {
    // NOTE no need to inform user - important restrictions are enforced by the back-end
    console.error('Failed to retrieve restricted members.');
    done();
  });
}

let nbChecked = 0;
let nbCheckboxes = 0;
function notifyChildCheckboxStateChange(checked){
  nbChecked += (checked ? 1:-1);
  let checkParent = $('#selectAllChk');
  if(nbChecked == 0){
    checkParent.prop("indeterminate", false);
    checkParent.prop("checked", false);
  }else if(nbChecked == nbCheckboxes){
    checkParent.prop("indeterminate", false);
    checkParent.prop("checked", true);
  }else{
    checkParent.prop("indeterminate", true);
  }
}
function setupSelectParent(){
  nbChecked = 0;
  nbCheckboxes = $(".distro-child-chk:not(:disabled)").length;
  let checkParent = $('#selectAllChk');
  checkParent.prop("indeterminate", false);
  checkParent.prop("checked", false);
}

var disabledBtnsClickEvt = new Object();
function initListeners() {

  // Initializing to the first security group
  initializeToFirstList();

  document.getElementById('createListBtn').onclick = showAddModal;
  document.getElementById('deleteListBtn').onclick = showDeleteModal;

  // these listeners require a list to be selected
  $('#deleteListBtn').off('click');
  $('#deleteListBtn').on('click', showDeleteModal); // important to use jQuery here
  // for the eventBlocker to work properly
  disabledBtnsClickEvt.deleteListBtn = showDeleteModal;
  disableOptionBtns();

  $('#listModifBtnsContainer').show();

  $('#addMemberBtn').on('click', toggleSelectedMembers);
  $('#removeMemberBtn').on('click', toggleSelectedMembers);

  // select-all / unselect-all / invert selection
  /*$('#invert-selection').on('click', toggleAllCheckboxes);*/
  $('#selectAllChk').on('click', (e)=>{
    let checkbox = e.target;
    if(nbChecked > 0)
      checkbox.checked=false;
    toggleAllCheckboxes(checkbox.checked);
    $('input.parent-chk:not(#checkbox-parent-00)').prop("checked", checkbox.checked);
  });

  //list/contact management switch
  $('div.list-contact-switch > input').change(function() {
    adaptDisplayToFilters();
    if(getManagementMode() === MNGMT.BY_LIST) {
      reloadTable();
    } else {
      // reloadTableContact();
    }
  });

  $('.contact-search input').focus(function() {
    $(this).parent().addClass('focused');
  }).blur(function() {
    $(this).parent().removeClass('focused');
  });

  document.querySelector('.contact-search > label > input').onkeyup = function() {
    if(this.value)
      setTimeout(() => searchContacts(this.value, this), 0);
  }

  document.querySelector('.contact-search > label > input').addEventListener('input', (e) => {
    const value = e.currentTarget.value;
    if(value === '')
      setTimeout(() => searchContacts(value, e.currentTarget), 0);
  })

  //member/non-member switch
  $('.show-members-switch > input').change(() => {
    $('div.contact-search > label > input').val("");
      rebuildTable();
  });

  //select boxes
  $('select#securityGroupList').change(function() {
    $('div#editListContainer').show();
    enableAgentSwitch();
    enableOptionBtns();
    reloadTable(findSelectedCellForLists().id);
  });
}

function rebuildTable() {
  if(getMemberDisplayMode() === FILTER.SHOW_MEMBERS) {
    if(getManagementMode() === MNGMT.BY_LIST) {
      showMembers();
    } else {
      showContactLists();
    }
  }
  else {
    if(getManagementMode() === MNGMT.BY_LIST) {
      showNonMembers()
    } else {
      showNonContactLists();
    }
  }
  setupSelectParent();
}

function toggleSelectedMembers() {
  switch(getManagementMode()) {
    case MNGMT.BY_LIST:
      let personIdArr = new Array();
      $('input.distro-child-chk').each(function(idx, input) {
        if(input.checked) {
          let personId = input.getAttribute('personId');
          if(!personIdArr.includes(personId)) {
            personIdArr.push(personId);
          }
        }
      });
      if(personIdArr.length) {
        if(getMemberDisplayMode() === FILTER.SHOW_MEMBERS) {
          removePerson(personIdArr.join(','));
        } else {
          addPerson(personIdArr.join(','));
        }
      } else {
        cgToast(i18n.t("js.wtmrk.contact-none"));
      }
      break;
    case MNGMT.BY_CONTACT:
      break;
    default: throw 'Invalid management mode';
  }
}

function enableAgentSwitch() {
  $('.tab-switch#includeAgentSwitch').removeClass('disabled-switch');
  $('.tab-switch#includeAgentSwitch').off('click');
}

function disableAgentSwitch() {
  $('.tab-switch#includeAgentSwitch').addClass('disabled-switch');
  $('.tab-switch#includeAgentSwitch').on('click', groupSelectionBlocker);
}

function disableOptionBtns() {
  $('a.option-btn.require-list').each((idx, elem) => {
    if(!$(elem).hasClass('disabled-button')) {
      if(!elem.id || !disabledBtnsClickEvt.hasOwnProperty(elem.id)) {
        // the disabledBtnsClickEvt obj will be empty until the listeners have
        // been initiated
        if(Object.keys(disabledBtnsClickEvt).length) {
          console.error({ element: elem, message: 'Elements with the require-list '
          + 'class must have their listeners stored in the disabledBtnsClickEvt '
          + 'object in order to be disabled.' });
          return;
        }
      }
      $(elem).off('click');
    }
  });
  if (croogloocurrentpage.getAccessLevel() < 2) {
    $("#removeMemberBtn").hide();
    $("#addMemberBtn").hide();
    $("#createListBtn").hide();
    $("#deleteListBtn").hide();
  }
}

function enableOptionBtns() {
  $('a.option-btn.require-list').each((idx, elem) => {
    if($(elem).hasClass('disabled-button')) {
      if(!elem.id || !disabledBtnsClickEvt.hasOwnProperty(elem.id)) {
        // the disabledBtnsClickEvt obj will be empty until the listeners have
        // been initiated
        if(Object.keys(disabledBtnsClickEvt).length) {
          console.error({ element: elem, message: 'Elements with the require-list '
          + 'class must have their listeners stored in the disabledBtnsClickEvt '
          + 'object in order to be disabled.' });
          return;
        }
      }
      $(elem).removeClass('disabled-button');
      $(elem).off('click');
      $(elem).on('click', disabledBtnsClickEvt[elem.id]);
    }
  });
}

function groupSelectionBlocker(event) {
  event.preventDefault();
  event.stopImmediatePropagation();
  cgToast(i18n.t("js.sec.no-list"));
}

const MNGMT = { BY_LIST: 0, BY_CONTACT: 1 }
const FILTER = { SHOW_MEMBERS: 0, SHOW_NON_MEMBERS: 1 }
const VIEW_BY = { DEPARTMENT: 0, NAME: 1 }

function getManagementMode() {
  return MNGMT.BY_LIST; // IMPORTANT - BY CONTACT IS NOT CURRENTLY SUPPORTED
}

function getMemberDisplayMode() {
  return $('.show-members-switch > input#dist-members').is(':checked')
    ?FILTER.SHOW_MEMBERS :FILTER.SHOW_NON_MEMBERS;
}

function getViewByMode() {
  return document.getElementById('deptOrderOpt').checked
   ?VIEW_BY.DEPARTMENT :VIEW_BY.NAME;
}

/**
 * Adapts select boxes, text content and tables' display to the selected filters
 */
function adaptDisplayToFilters() {
  $("input:checkbox").prop("checked", false);
  $("input:checkbox").prop("indeterminate", false);

  switch(getMemberDisplayMode()) {
      case FILTER.SHOW_MEMBERS:
      $('#addMemberBtn').hide();
      $('#removeMemberBtn').show();
      break;
      case FILTER.SHOW_NON_MEMBERS:
      $('#removeMemberBtn').hide();
      $('#addMemberBtn').show();
      break;
    default: throw 'Invalid member display mode returned';
  }

  switch(getManagementMode()) {
    case MNGMT.BY_LIST:
      //select boxes
      $('select#distributionContactList').hide();
      $('select#securityGroupList').show();
      if(document.getElementById('securityGroupList')) {
        $('div#editListContainer').show();
        enableOptionBtns();
        enableAgentSwitch();
      } else {
        $('div#editListContainer').hide();
        disableOptionBtns();
        disableAgentSwitch();
      }

      //data tables
      $('table#contactTable').hide();
      $('table#distroListTable').show();
      $('#tableControlsContainer').show();
      $('div.mngmt-text').show();

      //include agents switch
      //visibility is changed rather than display to prevent UI changes
      $('div#agentSwitchContainer').css('visibility', 'visible');

      //create and remove options
      $('a.option-btn').show();
      break;
    case MNGMT.BY_CONTACT:
      //select boxes
      $('select#securityGroupList').hide();
      $('div#editListContainer').hide();
          $('select#distributionContactList').show();

      //data tables
      $('table#distroListTable').hide();
      $('table#contactTable').show();
      $('#tableControlsContainer').show();
      $('div.mngmt-text').show();

      //include agents switch
      //visibility is changed rather than display to prevent UI changes
      $('div#agentSwitchContainer').css('visibility', 'hidden');

      //create and remove options
      $('a.option-btn').hide();
      break;
    default: throw 'Invalid management mode returned';
  }
  if (croogloocurrentpage.getAccessLevel() < 2) {
    $("#removeMemberBtn").hide();
    $("#addMemberBtn").hide();
    $("#createListBtn").hide();
    $("#deleteListBtn").hide();
  }else{
    $("#deleteListBtn").show();
  }
}

function showMembers()
{
  adaptDisplayToFilters();
  loadDistroListTable(groupMembers);
}

function showNonMembers()
{
  adaptDisplayToFilters();
  loadDistroListTable(notGroupMembers);
}

function getDepartmentName(departmentId) {
  try {
    return departmentMap[departmentId].properties.departmentName
      || departmentId.replace('DEPARTMENT_', '');
  } catch(err) {
    console.error(departmentId, err);
    return departmentId.replace('DEPARTMENT_', '');
  }
}

function loadDepartments() {
  apicall('personapi', 'fetchDepartments', {}).then(function(resp){
    if(resp && resp.items){
      allDepartments = resp.items;
      departmentMap = mapEntitiesByKey(allDepartments);
    }
    loader.check('departments');
  });
}

function mapEntitiesByKey(entities) {
  let entityMap = {};
  for(let ent of entities) {
    entityMap[ent.key.name] = ent;
  }
  return entityMap;
}

//this loads the right hand table for members/non-members
function loadDistroListTable(data) {
  let viewBy = getViewByMode();
  if (viewBy != VIEW_BY.DEPARTMENT) {
    data.sort(compareNameWithDefaults);
    $('div.contact-search').css('visibility', 'visible');
  } else {
    $('div.contact-search').css('visibility', 'hidden');
    $('div.contact-search > label > input').val("");
  }

  clearTable();
  fillTable();
  hideSpinner();

  function clearTable() {
    let allRows = document.querySelectorAll('#distroListTable tbody tr');
    for(let r = allRows.length-1; r >= 0; r--) {
      if(!allRows[r].className.includes('cg-blueprint')) {
        allRows[r].parentNode.removeChild(allRows[r]);
      }
    }
  }

  function fillTable() {
    let table = document.getElementById('distroListTable');
    if(!data.length) {
      $('div#tablePlaceholder').show();
      return;
    } else {
      $('div#tablePlaceholder').hide();
    }
    for(let i = 0; i < data.length; i++) {
      if (viewBy == VIEW_BY.DEPARTMENT) {
      let foundParentRow = false;
      let departmentLabels = document.querySelectorAll('tr.distro-row td label');
      for(let j = 0, label; label = departmentLabels[j++];) {
        if(label.getAttribute('department-id') === data[i].departmentId) {
          addChildRow(data[i], Utility.findEnclosingElement(label, 'TR'));
          foundParentRow = true;
          break;
        }
      }
      if(!foundParentRow) {
        let parentRow = createParentRow(getDepartmentName(data[i].departmentId),
          data[i].departmentId);
        addChildRow(data[i], parentRow);
      }
    } else{
      addRow(data[i]);
    }
    }
    alphaSortNonCrew();
  }

  function alphaSortNonCrew() {
    ['CAST','AGENT'].forEach(deptName => {
      let lastAddedRow = document.querySelector('tr.parent-row[departmentId="DEPARTMENT_'+deptName+'"]');
      [].slice.call(document.querySelectorAll('tr[departmentId="DEPARTMENT_'+deptName+'"]:not(.parent-row)'))
      .sort(compareNbNameAgent).forEach(childRow => {
        if(lastAddedRow.nextSibling) {
          childRow.parentElement.insertBefore(childRow, lastAddedRow.nextSibling);
        } else {
          childRow.parentElement.appendChild(childRow);
        }
        lastAddedRow = childRow;
      });
    });
  }

  function createParentRow(rowName, departmentId) {
    let nbOfParentRows = document.querySelectorAll('tr.distro-row').length;
    let row = copyBlueprint('parentRowBP', true);
    row.id = 'parentRow_' + departmentId.replace(/\s/g,'_');
    row.setAttribute('data-id', nbOfParentRows);

    let checkbox = document.querySelector('tr#' + row.id +' td input');
    checkbox.setAttribute('department', departmentId);
    checkbox.id = 'checkbox-parent-' + nbOfParentRows;
    let label = document.querySelector('#' + row.id + ' td label');
    label.textContent = rowName;
    label.setAttribute('for', checkbox.id);
    label.setAttribute('department-id', departmentId)

    return row;
  }

  function addChildRow(person, parentRow) {
    let tr = document.createElement('tr');
    tr.setAttribute('data-parent', parentRow.getAttribute('data-id'));
    tr.className = 'child-collapse';
    if(parentRow.className.includes('toggle-open')) {
      tr.className += ' child-expanded';
    }

    let tdDept = document.createElement('td');
    tdDept.innerHTML = parentRow.children[0].innerHTML
      .replace(/checkbox-parent-\d+/g,'checkbox-child-' +
      document.querySelectorAll('tr').length);
    tr.appendChild(tdDept);

    let checkbox = $(tr).find('input')[0];
    checkbox.className = 'distro-child-chk ' + person.departmentId.replace(/\s/g,'_');
    checkbox.setAttribute('department', person.departmentId);
    checkbox.setAttribute('personId', person.personId);
    if(restrictedMembers.productionOwnerPersonId && restrictedMembers.productionOwnerPersonId == person.personId) {
      checkbox.disabled = true;
      tdDept.parentElement.classList.add('disabled-row');
      tdDept.parentElement.title = i18n.t("js.sec.list.prod-owner");
    }else{
      checkbox.addEventListener('click', (e) => notifyChildCheckboxStateChange(e.target.checked));
    }

    addTableData('first-name', person.firstName, tr);
    addTableData('last-name', person.lastName, tr);
    addTableData('job-name', person.jobTitle, tr);

    tr.appendChild(document.createElement('td'));
    if(parentRow.nextSibling) {
      parentRow.parentElement.insertBefore(tr, parentRow.nextSibling);
    } else {
      parentRow.parentElement.appendChild(tr);
    }

    function addTableData(className, content, row) {
      let td = document.createElement('td');
      td.className = className;
      td.textContent = content;
      row.appendChild(td);
    }
  }

  function addRow(person) {
    let nbOfParentRows = document.querySelectorAll('tr.distro-row').length;
    let row = copyBlueprint('parentRowBP', true);
    row.id = getRowId(person);
    row.setAttribute('department-id', person.departmentId);
    row.setAttribute('person-id', person.personId);
    row.setAttribute('data-id', nbOfParentRows);
    row.classList.remove("parent-row");
    row.classList.add("alphabetical");
    if (nbOfParentRows % 2 == 1) {
      row.classList.add("odd");
    }

    let checkbox = document.querySelector('tr#'+row.id+' td input');
    checkbox.setAttribute('departmentId', person.departmentId);
    checkbox.setAttribute('personId', person.personId);
    checkbox.id = 'checkbox-parent-'+nbOfParentRows;
    checkbox.classList.add("distro-child-chk");
    checkbox.addEventListener('click', (e) => notifyChildCheckboxStateChange(e.target.checked));
    let label = document.querySelector('#'+row.id+' td label');
    label.textContent = person.firstName + " " + person.lastName;
    label.setAttribute('departmentId', person.departmentId);
    label.setAttribute('for', checkbox.id);

    let editBtn = document.querySelector('tr#'+row.id+' td a');
    $(editBtn).on('click', function(e) {
      showSpinner();
      let payload = new Object();
      payload.personId = person.personId;
      apicall('personapi', 'fetchContact', payload).then(function (resp) {
        hideSpinner();
        quickModifyPerson(resp.entity);
      }, function() {
        console.error("Failed to fetch contact");
      });
    });

    let departmentColumn = document.querySelector('#'+row.id+' td.department-name');
    departmentColumn.innerHTML = getDepartmentName(person.departmentId)
    let jobNameColumn = document.querySelector('#'+row.id+' td.job-name');
    jobNameColumn.innerHTML = person.jobTitle;
    row.appendChild(document.createElement('td'));
  }
}

function getRowId(person) {
  if(getViewByMode() == VIEW_BY.DEPARTMENT){
    return Utility.getValidId('parentRow_' + person.departmentId);
  } else {
    return Utility.getValidId('parentRow_' + person.departmentId + '_' + person.personId);
  }
}

var isSearching = false;
var searchAgain = false;
function searchContacts(searchText, searchInput) {
  if(getMemberDisplayMode() === FILTER.SHOW_MEMBERS) {
    var sortedCrewMembers = groupMembers;
  } else {
    var sortedCrewMembers = notGroupMembers;
  }
  if(isSearching) {
    searchAgain = true;
    console.warn('search already running');
    return;
  }
  isSearching = true;
  for(let c = 0, crewMember; crewMember = sortedCrewMembers[c++];) {
    let textMatch = false;
    for(let propVal of [
        (crewMember.firstName||'') + ' ' + (crewMember.lastName||''), 
        crewMember.jobTitle
    ]) {
      if(propVal.trim().toLowerCase().includes(searchText.toLowerCase())) {
        textMatch = true;
        break;
      }
    }
    try {
      document
        .querySelector(`tr.distro-row[person-id="${crewMember.personId}"][department-id="${crewMember.departmentId}"]`)
        .style.display = textMatch ?'table-row' :'none';
    } catch(e) {
      console.error(e);
    }
  }
  isSearching = false;
  if(searchAgain) {
    searchAgain = false;
    searchContacts(searchInput.value, searchInput);
  }
}


function toggleAllCheckboxes(shouldBeChecked) {
  let targetCheckboxClass = getManagementMode() === MNGMT.BY_LIST
    ?'distro-child-chk' :'contact-chk';
  if(shouldBeChecked !== undefined){
    if(shouldBeChecked) nbChecked = nbCheckboxes;
    else nbChecked = 0;
  }
  $('input.'+targetCheckboxClass+':not(:disabled)').each(function(idx, input) {
    if(shouldClick(input)) {
      if(shouldBeChecked === undefined) $(input).click();
      else input.checked = shouldBeChecked;
    }
  });
  function shouldClick(input) {
      return shouldBeChecked === undefined || input.checked !== shouldBeChecked;
  }
}

function showContactLists()
{
  adaptDisplayToFilters();
  loadContactTable(groupMembers2);
}

function showNonContactLists()
{
  adaptDisplayToFilters();
  loadContactTable(notGroupMembers2);
}


function confirmAccessLoss(isPartialRemoval) {
  return new Promise(resolve => {
    swal({
      title: i18n.t("js.warning"),
      html: i18n.t("js.sec.list.removal." + (isPartialRemoval ? "admin":"list"))+" "+i18n.t("js.sec.list.proceed"),
      type: 'warning',
      confirmButtonText: i18n.t("yes"),
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      showCloseButton: true,
      allowOutsideClick: true
    }).then(() => {
      resolve(true);
    }).catch(() => {
      resolve(false);
    })
  });
}

async function addPerson(personId) {
  if (croogloocurrentpage.getAccessLevel() < 2) {
    cgToast(i18n.t("js.unauthorized"));
    return;
  }

  var params = new Object();
  params.securityListId = findSelectedCellForLists().id;
  params.personId = personId;

  if (restrictedMembers.activeUserPersonId
    && restrictedMembers.activeUserSecurityListId == 'ADMIN'
    && params.securityListId != 'ADMIN'
    && personId.split(',').includes(restrictedMembers.activeUserPersonId)) {
    if(!await confirmAccessLoss(true)) { return; }
  }

  saveSystemActivity({
    action: 'submit',
    params: findSystemActivityParams(params),
    message: 'User added a member to a security list.'
  });

  showSpinner();
  apicall('securityadminapi', 'addSecurityListMember', params).then(function(resp) {
    if(resp.responseCode == '0') {
      reloadTable(findSelectedCellForLists().id);
      hideSpinner();
    } else {
      throw new Error('');
    }
  }).catch(() => {
    swalToast({
      title: i18n.t("js.sec.list.err.add.member"),
      type: 'error'
    });
  });
}

async function removePerson(personId) {
  if (croogloocurrentpage.getAccessLevel() < 2) {
    cgToast(i18n.t("js.unauthorized"));
    return;
  }
  var params = new Object();
  params.securityListId = findSelectedCellForLists().id;
  params.personId = personId;

  if (restrictedMembers.activeUserPersonId
    && personId.split(',').includes(restrictedMembers.activeUserPersonId)) {
    if(!await confirmAccessLoss(false)) { return; }
  }

  saveSystemActivity({
    action: 'submit',
    params: findSystemActivityParams(params),
    message: 'User removed a member from a security list.'
  });

  showSpinner();
  apicall('securityadminapi', 'removeSecurityListMember', params).then(function(resp) {
    if(resp.responseCode == '0') {
      reloadTable(findSelectedCellForLists().id);
      hideSpinner();
    } else {
      throw new Error('');
    }
  }).catch(() => {
    swalToast({
      title: i18n.t("js.sec.list.err.remove.member"),
      type: 'error'
    });
  });

}

function findSelectedCellForLists (){
  var table = document.getElementById("securityGroupList");
  var rows = table.getElementsByTagName("tr");
  for (var i=0;i<rows.length;i++){
    var currentRow = rows[i];
    var cell = currentRow.getElementsByTagName("td")[0];
    if (cell.hasAttribute("class")){
      return cell;
    }
  }
}

function initializeToFirstList(){
  var table = document.getElementById("securityGroupList");
  var rows = table.getElementsByTagName("tr");
  for (var i = 0; i < rows.length; i++) {
    var currentRow = rows[i];
    var cell = currentRow.getElementsByTagName("td")[0];
    cell.removeAttribute('class');
    if (i == 0) {
      cell.setAttribute('class', 'clicked');
      reloadTable(cell.id);
    }
  }
} 
