import i18n from './components/Translation';
import DocUtility from './components/DocUtility';
import swal from 'sweetalert2';

export function init() {
  loader.add(initGlobals, 'globals')
        .add(loadExtraLibraries, 'watermarkOpt')
        .add(initListeners, 'listeners')
        .add(getAllData, ['data','metadata', 'callsheets'])
        .add(ui)
        .execute();
}

function initGlobals() {
  _subcategory = Utility.getPageParam('subcategory');
  _entityType = "DOCUMENT";
  _altPage = Utility.getPageParam('altpagename');
  docListProperties = [];
  selectedDocument = null;
  _tempFileName = "";
  _token = croogloo_auth.usertoken;
  _tenantId = croogloo_auth.tenantId;
  callsheets = new Array();
  loader.check('globals');
}

function loadExtraLibraries() {
  Utility.loadStylesheet('/assets/croogloo-custom/css/watermarkingOptions.css');
  Utility.loadScript('/assets/croogloo-custom/js/watermarkingOptions.js', () => loader.check('watermarkOpt'));
}

function initListeners() {
  $('#sortSelect').change(function(){
    console.debug("sorting");
    sortDocumentEntityList($('#sortSelect').val(), function(){
      refreshDocList();
    });
  });

  document.getElementById('downloadBtn').onclick = function() {
    if(selectedDocument !== null) {
      DocUtility.openFile(selectedDocument.id, selectedDocument.name, selectedDocument.isWatermarked, true);
    }
  }

  if(_altPage){
    $('#toggleAltPage').show();
  }

  loader.check('listeners');
}

function ui() {
  $('#main').foundation();
  if (croogloocurrentpage.getAccessLevel() < 2) {
    $('#dropdown-delete').hide();
  }
  if(Utility.getPageParam('subcategory') !== 'script') {
    document.getElementById('dropdown-parsescript').parentElement.style.display = 'none';
  }
  if(Utility.getPageParam('subcategory') !== 'side') {
    document.getElementById('dropdown-prependcallsheet').parentElement.style.display = 'none';
    if (document.getElementById('dropdown-updatecallsheet') !== null) {
      document.getElementById('dropdown-updatecallsheet').parentElement.style.display = 'none';
    }
  }
}

var _subcategory;
var _entityType;
var _altPage;
var docListProperties;
var selectedDocument;
var _tempFileName;
var _token, _tenantId;
var callsheets;

function getAllData() {
  getData();
  getMetaData();
  if(Utility.getPageParam('subcategory') === 'side') {
    fetchCallSheets();
  }else{
    loader.check('callsheets');
  }
}
function fetchCallSheets() {
  apicall('documentsapi', 'fetchCallSheets', { targetEpisode: '2' }).then(resp => {
    console.debug('callsheets loaded');
    console.debug(resp);
    callsheets = resp.items;
    loader.check('callsheets');
  });
}
// TODO change for setTitle
function getMetaData() {
  document.title = "Croogloo - DocViewer";
  $('#pageTitle').html(_subcategory[0].toUpperCase() + _subcategory.substring(1) + (_subcategory.endsWith('s')?'':'s'));
  loader.check('metadata');
}

function getData(){
  showSpinner();
  initDocList();
}

function initDocList(){
  docListProperties = [];
  emptyDocList();

  var fetchDocsParameters = new Object();
  fetchDocsParameters.subcategory = _subcategory;

  console.debug('calling fetchDocumentsBySubcategory with following params: ' + JSON.stringify(fetchDocsParameters));
  apicall('documentsapi', 'fetchDocumentsBySubcategory', fetchDocsParameters).then(function (resp) {
    if (resp.items) {
      initDocListProp(resp.items, function () {
        if (docListProperties.length===0){
          hideSpinner();
          sidesOverlayOn();
        }
        sortDocumentEntityList($('#sortSelect').val(), function () {
          for (var i = 0; i < docListProperties.length; i++) {
            createDocCard(i);
          }
        })
      });
    } else {
      console.debug('Documents list is empty')
    }

    loader.check('data');
  }).catch((e) => {
    console.error(e);
    console.error('Failed to fetch documents by subcategory');
  });
}

function refreshDocList(){

  emptyDocList();

  sortDocumentEntityList($('#sortSelect').val(), function(){

    for(var i = 0; i < docListProperties.length; i++){

      createDocCard(i);
    }
  });
}

function initDocListProp(items, callback){

  for(var i = 0; i < items.length; i++){

    docListProperties[i] = items[i].properties;
  }

  callback && callback();
}

function sortDocumentEntityList(sortType, callback){

  switch(sortType){

    case '1': sortBy(true, 'fileName');
    break;

    case '2': sortBy(false, 'fileName');
    break;

    case '3': sortBy(true, 'lastModifBaseTime'); // defaults to timeCreated if no lastModifBaseTime
    break;

    case '4': sortBy(false, 'lastModifBaseTime'); // defaults to timeCreated if no lastModifBaseTime
    break;
  }

  callback && callback();
}

function sortBy(ascending, data){
  docListProperties.sort(function(a, b){
    try {
      if(data == 'fileName') {
        return a[data].localeCompare(b[data]);
      } else {
        let aTimeCreated = !isNaN(a[data])
          ?parseInt(a[data])
          :(typeof a.timeCreated == 'string' ?new Date(a.timeCreated).getTime() :0);
        let bTimeCreated = !isNaN(b[data])
          ?parseInt(b[data])
          :(typeof b.timeCreated == 'string' ?new Date(b.timeCreated).getTime() :0);
        return aTimeCreated - bTimeCreated;
      }
    } catch(e) {
      console.error(e);
      return 0;
    }
  });

  if(!ascending) {
    docListProperties.reverse();
  }
}

/**
 * This builds the HTML for docCard and docMenu which is the card for a specific document as well as the 3 dots menu next to that document.
 * It also handles watermarking the document when someone clicks on it for display
 * 
 * @param {int} index 
 * @param {*} callback 
 */
function createDocCard(index, callback) {
    try {
        // The information for this card is fetched from the `docListProperties` array which must be filled before this is called.
        var docCard = docListProperties[index];

        // If the docCard has no id for some reason, don't try to use that value
        if (!docCard.hasOwnProperty('id')) {
            return null;
        }

        // Creates the doc card view itself before appending the fileName and id in it.
        var docCardView = document.createElement("div");
        docCardView.className = "docCardView medium-10 cell";
        docCardView.setAttribute('file_id', docCard.id);

        var docCardText = document.createElement("div");
        docCardText.innerHTML = docCard.fileName;
        docCardText.className = "docCardText";

        // Creates the 3 dot menu
        let docMenuView = document.createElement("div");
        docMenuView.className = "docMenuView medium-2 cell";

        let threeDots = document.createElement("a");
        threeDots.className = "moreMenuClass";
        threeDots.id = "moreMenu" + docCard.id;

        // Create an intercom target so that product tours have a consistent anchor to attach to
        threeDots.setAttribute("data-intercom-target", "intercom_" + index);
        threeDots.innerHTML = '<i class="icon-more"></i>';
        threeDots.setAttribute("data-toggle", "docviewer-dropdown");

        threeDots.onclick = function () {
            if (_subcategory === 'side') {

                let prependCSInput = document.getElementById('dropdown-prependcallsheet');
                /* 
                  When the update callsheet functionality is working, re-work this block into the TODO below
                  to make sure callsheets can be added to sides without a callsheet or the existing callsheet 
                  on sides can be updated
                */
                prependCSInput.parentElement.style.display = 'list-item';
                prependCSInput.onclick = () => {
                    if (croogloocurrentpage.getAccessLevel() < 2) {
                        cgToast(i18n.t("js.insufficient-priv"));
                        return;
                    }
                    appendCallSheet(docCard);
                }
                /* TODO : uncomment when the update callsheet functionality is working
                let updateCSInput = document.getElementById('dropdown-updatecallsheet');
                if(docCard.callSheetDocId && docCard.callSheetDocId != null){
                  prependCSInput.parentElement.style.display = 'none';
                  updateCSInput.parentElement.style.display = 'list-item' ;
                  updateCSInput.onclick = () => {
                    if (croogloocurrentpage.getAccessLevel() < 2) {
                      cgToast(i18n.t("js.insufficient-priv"));
                      return;
                    }
                    updateCallSheet(docCard);
                  }
                }else{
                  updateCSInput.parentElement.style.display = 'none' ;
                  prependCSInput.parentElement.style.display = 'list-item';
                  prependCSInput.onclick = () => {
                    if (croogloocurrentpage.getAccessLevel() < 2) {
                      cgToast(i18n.t("js.insufficient-priv"));
                      return;
                    }
                    appendCallSheet(docCard);
                  }
                }
                */

                // prevent adding multiple callsheets if the sides already contains a callsheet
                if (docCard.callSheetDocId && docCard.callSheetDocId != null) {
                    prependCSInput.parentElement.style.display = 'none';
                }
                else {
                    prependCSInput.parentElement.style.display = 'list-item';
                }
            }

            document.getElementById('dropdown-download').onclick = function () {
                DocUtility.openFile(docCard.id, docCard.fileName, docCard.isWatermarked == 1, true);
            }
            document.getElementById('dropdown-watermark').onclick = function () {
                if (croogloocurrentpage.getAccessLevel() < 2) {
                    cgToast(i18n.t("js.insufficient-priv"));
                    return;
                }
                applyWatermark(docCard);
            }
            document.getElementById('dropdown-parsescript').onclick = function () {
                if (croogloocurrentpage.getAccessLevel() < 2) {
                    cgToast(i18n.t("js.insufficient-priv"));
                    return;
                }
                parseScript(docCard);
            }
            document.getElementById('dropdown-delete').onclick = function () {
                if (croogloocurrentpage.getAccessLevel() < 2) {
                    cgToast(i18n.t("js.insufficient-priv"));
                    return;
                }
                deleteScript(docCard);
            }
        }

        // Set the date on the doc card
        var docCardDate = document.createElement("div");
        docCardDate.className = "docCardDate";
        if (docCard.timeCreated) {
            var date = new Date(docCard.timeCreated || docCard.lastModifBaseTime);
            docCardDate.innerHTML = date.toISOString().substring(0, 10);;
        }

        $(docCardView).off('click');
        $(docCardView).on('click', async function (event) {
            selectDocument(docCard);
            // Handle watermarking the document before displaying it to the user.
            if (docCard.isWatermarked == 1) {
                cgToast(i18n.t("js.wtmrk.confidential"), 3000);
                var url = "/WatermarkServlet?token=" + _token + "&tenantId=" + _tenantId + "&fileId=" + docCard.id;
                authenticatedcall(url, 'GET', '', 'application/json', null, "blob")
                    .then(blob => {
                        if (blob.type == 'text/plain' || blob.type == 'text/json') {
                            var reader = new FileReader();
                            reader.onload = function () {
                                $('#viewObject').attr('data', reader.result);
                            }
                            reader.readAsText(blob);
                        } else {
                            $('#viewObject').attr('data', window.URL.createObjectURL(blob));
                        }
                    });
            }
            else {
                if (docCard.fileExtension === "pdf") {
                    $('#viewObject').attr('type', "application/pdf");
                }
                else if (docCard.fileExtension !== "pdf") {
                    $('#viewObject').attr('type', "image/svg+xml");
                }
                $('#viewObject').attr('data', (await DocUtility.getSignedURL(docCard.id)) + '&filename=' + docCard.fileName + ';');
            }
        });

        var docListDiv = document.getElementById("docListDiv");
        docCardView.appendChild(docCardText);
        docCardView.appendChild(docCardDate);
        docMenuView.appendChild(threeDots);
        docListDiv.appendChild(docCardView);
        docListDiv.appendChild(docMenuView);

        if (index == 0) {
            selectDocument(docCard);
            document.getElementById('downloadBtn').style.display = 'block';
            docCardView.click();
        }
    } catch (e) {
        console.error("Error occurred while loading document card: " + e);
        console.error(docListProperties[index]);
    }

    callback && callback();
}

function generateWatermarkingOption() {
  var wmSettingsSwalContainer = document.createElement('div');
  wmSettingsSwalContainer.id = 'wmSettingsSwalContainer';
  //////////////////////////////////////////////////////////////////////
  //first line options
  var firstLineDiv = new WatermarkOptionDiv('lineOne', i18n.t("watermark.line1.title"));
  firstLineDiv.addLine([
    {id: 'wmFullName', label: i18n.t("watermark.line1.rec-name"), type: 'checkbox'}
  ]);
  firstLineDiv.addLine([
		{id: 'firstOtherCheckbox', label: i18n.t("other"), type: 'checkbox'},
		{id: 'firstOtherInput', label: null, type: 'text'}
	]);
  firstLineDiv.build(wmSettingsSwalContainer);
  //second line options
  let secondLineDiv = new WatermarkOptionDiv('lineTwo', i18n.t("watermark.line2.title"));

	secondLineDiv.addLine([
		{id: 'wmTitle', label: i18n.t("watermark.line2.opts-title"), type: 'checkbox'},
		{id: 'wmDepartment', label: i18n.t("watermark.line2.opts-dept"), type: 'checkbox'}
	]);
  secondLineDiv.addLine([
		{id: 'wmDate', label: i18n.t("watermark.line2.opts-date"), type: 'checkbox'},
		{id: 'wmIPAddress', label: i18n.t("watermark.line2.opts-ip"), type: 'checkbox'}
	]);

	secondLineDiv.addLine([
		{id: 'wmBlank', label: i18n.t("watermark.line2.opts-blank"), type: 'checkbox'}
	]);

	secondLineDiv.addLine([
		{id: 'secondOtherCheckbox', label: i18n.t("watermark.line2.opts-other"), type: 'checkbox'},
		{id: 'secondOtherInput', label: null, type: 'text'}
	]);
  secondLineDiv.build(wmSettingsSwalContainer);

  WatermarkOptionDiv.addEncryptionOption(wmSettingsSwalContainer);

  //////////////////////////////////////////////////////////////////////
  //the elements' event listeners from watermarkingOptions.js do not work
  //on swal
  $(document).on('click', '.wm-checkbox-lineOne', function() {
    if(this.checked) {
      currentWmFirstLine = this.id.match(/Other|Title|Department|FirstName|LastName|FullName|Blank|IPAddress|Date/)[0];
    } else {
      currentWmFirstLine = '';
    }
    var ref = this;
    $('.wm-checkbox-lineOne').each(function() {
      if(ref.id !== this.id) {
        this.checked = false;
      }
    });
  });
  $(document).on('click', '.wm-checkbox-lineTwo', function() {
    if(this.checked) {
      currentWmSecondLine = this.id.match(/Other|Title|Department|FirstName|LastName|FullName|Blank|IPAddress|Date/)[0];
    } else {
      currentWmSecondLine = '';
    }
    let ref = this;
    $('.wm-checkbox-lineTwo').each(function() {
      if(ref.id !== this.id) {
        this.checked = false;
      }
    });
  });
  $(document).on('focus', '#firstOtherInput', function() {
    if(!$('#firstOtherCheckbox').is(':checked')) {
      $('#firstOtherCheckbox').click();
    }
  });
  $(document).on('blur', '#firstOtherInput', function() {
    wmFirstOtherField = this.value;
    console.debug('changing wmFirstOtherField for ' + wmFirstOtherField);
  });
  $(document).on('focus', '#secondOtherInput', function() {
    if(!$('#secondOtherCheckbox').is(':checked')) {
      $('#secondOtherCheckbox').click();
    }
  });
  $(document).on('blur', '#secondOtherInput', function() {
    wmSecondOtherField = this.value;
    console.debug('changing wmSecondOtherField for ' + wmSecondOtherField);
  });
  return wmSettingsSwalContainer.outerHTML;
}

var currentWmFirstLine;
var currentWmSecondLine;
var wmFirstOtherField;
var wmSecondOtherField;

function resetWmGlobals() {
  currentWmFirstLine = '';
  currentWmSecondLine = '';
  wmFirstOtherField = '';
  wmSecondOtherField = '';
}

function applyWatermark(docCard) {
  resetWmGlobals();
  swal({
    title: i18n.t("docviewer.menu.apply-wtmrk"),
    html: generateWatermarkingOption(),
    type: 'info',
    input: 'text',
    inputClass: 'hidden-input', //used to prevent focus error on reject
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("button.confirm"),
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    showCloseButton: true,
    background: '#fff url(' + IMG_DIRECTORY + 'watermarkLogo.png)',
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !swal.isLoading(),    
    onOpen: function () {
      try {
        document.getElementById('wmFullName').click();
        document.getElementById('wmBlank').click();
      } catch (e) {
        console.error(e);
      }
    },
    preConfirm: function (text) {
      return new Promise(function (resolve, reject) {
        try {
          if (!currentWmFirstLine || !currentWmSecondLine) {
            reject(i18n.t("js.file.wtmrk.reject.selection"));
          } else if (currentWmFirstLine === 'Other' && !wmFirstOtherField || currentWmSecondLine === 'Other' && !wmSecondOtherField) {
            reject(i18n.t("js.docviewer.wtmrk.other-field"));
          } else {
            let addEncryption = document.getElementById('encryptChk') != null && document.getElementById('encryptChk').checked;
            updateWatermark([docCard.id], 1, addEncryption, resolve, reject, docCard);
          }
        } catch(err) {
          console.error(err);
          reject(i18n.t("js.docviewer.wtmrk.wrong"));
          // TODO consider logging error via redAlert feature
        }
      });
    }
  }).then(function () {
    swal({
      title: i18n.t("response.success"),
      type: 'success',
      showCloseButton: true,
      confirmButtonText: i18n.t("close"),
      confirmButtonColor: '#13C46A',      
      allowOutsideClick: true
    }).catch(swal.noop);
  }).catch(swal.noop);
}

function updateWatermark(selectedItems, value, addEncryption, resolve, reject, docCard) {
  var wmFirstLine = currentWmFirstLine;
  if(wmFirstLine === 'Other') {
    wmFirstLine = 'Other~' + wmFirstOtherField;
  }
  var wmSecondLine = currentWmSecondLine;
  if(wmSecondLine === 'Other') {
    wmSecondLine = 'Other~' + wmSecondOtherField;
  }
  DocUtility.removeCardViews(docCard.parents.value ? docCard.parents.value : docCard.parentFolder);
  apicall('settingsapi', 'changeWatermarkSettings', {
    isWatermarked: value + '',
    firstLine: wmFirstLine || '~',
    secondLine: wmSecondLine || '~',
    addEncryption: addEncryption
  }, {
    value: selectedItems.join(',')
  }).then(function(resp) {
    if(resp && resp.responseCode && resp.responseCode === '0') {
      docCard.isWatermarked = value === 1 ?1 :0;
      // if docCard selected, refresh to apply watermark
      if(selectedDocument != null && selectedDocument.id == docCard.id) {
        document.querySelector(`div.docCardView[file_id="${docCard.id}"]`).click();
      }
      resolve();
    } else {
      throw new Error('invalid server response');
    }
  }).catch(() => {
    console.error('failed to change watermark settings');
    reject(i18n.t("js.utils.server.error-support"));
  });
}

function appendCallSheet(docCard){//comehere
  var selectionData = new Object();
  selectionData.sidesDocsId = docCard.id;
  console.log(selectionData.sidesDocsId) ;

  swal({    
    title: i18n.t("js.sides.callsheet.title"),
    html: createCallSheetOptions(),
    input: 'text',
    inputClass: 'hidden-input', //used to prevent focus error on reject
    showCloseButton: true,
    confirmButtonText: i18n.t("button.submit"),
    confirmButtonColor: '#13C46A',
    allowOutsideClick: false,
    showLoaderOnConfirm: true,
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    allowOutsideClick: () => !swal.isLoading(),
    preConfirm: function(text) {
      return new Promise(function(accept, refuse) {
        console.debug('checking call sheet settings');

        let callSheetId = document.getElementById('callSheetSelect').value;
        if(!callSheetId) {
          refuse(i18n.t("js.docviewer.sides.cs.rejection"));
          return;
        }

        let pageInput = document.getElementById('callSheetPageInput');
        if(pageInput.value.trim().length !== 0) { // must accept empty string (matches all pages)
          for(let pageNbString of pageInput.value.trim().split(/,\s*/g)) {
            if(pageNbString.trim().match(/^\d+(\s*-\s*\d+)?$/) == null) {
              refuse(i18n.t("js.sides.callsheet.refuse"));
              return;
            }
          }
        }

        try {
          document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'not-allowed';
        } catch(e) {
          console.error(e);
        }
        selectionData.callSheetDocId = callSheetId;
        selectionData.pagesToExtract = pageInput.value.trim().replace(/^\s*$/,'-');
        accept();
      });
    }
  }).then(() => {
    showSpinner();
    console.debug(selectionData);
    apicall('documentsapi', 'prependCallSheet', selectionData, {}).then((resp) => {
      hideSpinner();
      if(resp && resp.responseCode && resp.responseCode === '0') {
        docCard.callSheetDocId = selectionData.callSheetDocId;
        docCard.callSheetPages = selectionData.pagesToExtract;
        document.getElementById('dropdown-prependcallsheet').parentElement.style.display = 'none';
        // if docCard selected, refresh to apply callsheet
        if(selectedDocument != null && selectedDocument.id == selectionData.sidesDocsId) {
          document.querySelector(`div.docCardView[file_id="${docCard.id}"]`).click();
        }
      } else {
        throw new Error('invalid server response');
      }
    }).catch((e) => {
      console.error(e);
      console.error('failed to prepend call sheet');
    });
  }).catch(swal.noop);
}

function updateCallSheet(docCard){//comehere
  var selectionData = new Object();
  selectionData.sidesDocsId = docCard.plainPdfFileName;
  console.log(selectionData.sidesDocsId) ;

  swal({
    animation: false,
    title: i18n.t("js.sides.callsheet.title"),
    html: createCallSheetOptions(),
    input: 'text',
    inputClass: 'hidden-input', //used to prevent focus error on reject
    showCloseButton: true,
    confirmButtonText: i18n.t("button.submit"),
    confirmButtonColor: '#13C46A',
    allowOutsideClick: false,
    showLoaderOnConfirm: true,
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    preConfirm: function(text) {
      return new Promise(function(accept, refuse) {
        console.debug('checking call sheet settings');

        let callSheetId = document.getElementById('callSheetSelect').value;
        if(!callSheetId) {
          refuse(i18n.t("js.docviewer.sides.cs.rejection"));
          return;
        }

        let pageInput = document.getElementById('callSheetPageInput');
        if(pageInput.value.trim().length !== 0) { // must accept empty string (matches all pages)
          for(let pageNbString of pageInput.value.trim().split(/,\s*/g)) {
            if(pageNbString.trim().match(/^\d+(\s*-\s*\d+)?$/) == null) {
              refuse(i18n.t("js.sides.callsheet.refuse"));
              return;
            }
          }
        }

        try {
          document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'not-allowed';
        } catch(e) {
          console.error(e);
        }
        selectionData.callSheetDocId = callSheetId;
        selectionData.pagesToExtract = pageInput.value.trim().replace(/^\s*$/,'-');
        selectionData.nbCallSheetPages = docCard.nbCallSheetPages ;
        accept();
      });
    }
  }).then(() => {
    showSpinner();
    console.debug(selectionData);
    apicall('documentsapi', 'updateCallSheet', selectionData, {}).then((resp) => {
      hideSpinner();
      if(resp && resp.responseCode && resp.responseCode === '0') {
        docCard.callSheetDocId = selectionData.callSheetDocId;
        docCard.callSheetPages = selectionData.pagesToExtract;
        document.getElementById('dropdown-prependcallsheet').parentElement.style.display = 'none';
        // if docCard selected, refresh to apply callsheet
        if(selectedDocument != null && selectedDocument.id == selectionData.sidesDocsId) {
          document.querySelector(`div.docCardView[file_id="${docCard.id}"]`).click();
        }
      } else {
        throw new Error('invalid server response');
      }
    }).catch((e) => {
      console.error(e);
      console.error('failed to prepend call sheet');
    });
  }).catch(swal.noop);
}

function createCallSheetOptions() {
  let container = document.createElement('div');

  let selectCSTitle = document.createElement('div');
  selectCSTitle.innerHTML = i18n.t("js.docviewer.sides.cs.text");
  selectCSTitle.style.marginTop = '0.6rem';
  selectCSTitle.style.marginBottom = '0.5rem';
  container.appendChild(selectCSTitle);

  let csSelect = document.createElement('select');
  csSelect.id = 'callSheetSelect';
  let orderedCallsheets = DocUtility.sortCallsheetOptions(callsheets);

  orderedCallsheets.forEach(callsheet => {
    let csOpt = document.createElement('option');
    if(typeof callsheet.properties.fileName == 'string') {
      csOpt.textContent = callsheet.properties.fileName;
      csOpt.value = callsheet.key.name;
    }
    csSelect.appendChild(csOpt);
  });
  container.appendChild(csSelect);

  let lineBreak = document.createElement('br');
  lineBreak.style.lineHeight = '2rem';
  container.appendChild(lineBreak);

  let selectPagesTitle = document.createElement('div');
  selectPagesTitle.innerHTML = i18n.t("js.sides.callsheet.subtext.p1")
  +'<br/><span style="font-size:0.75rem">'
  +i18n.t("js.sides.callsheet.subtext.p2")
  +'</span>';
  selectPagesTitle.style.marginBottom = '0.5rem';
  container.appendChild(selectPagesTitle);

  let pageInput = document.createElement('input');
  pageInput.id = 'callSheetPageInput';
  pageInput.type = 'text';
  pageInput.placeholder = 'Ex.: 1, 3, 5 - 8';
  container.appendChild(pageInput);

  return container.outerHTML;
}

function selectDocument(docCard) {
  selectedDocument = {
    id: docCard.id,
    name: docCard.fileName,
    isWatermarked: docCard.isWatermarked == 1
  }
}

function parseScript(docCard) {
  console.debug(docCard);
  DocUtility.showParseScriptAlert(docCard.id, docCard.fileName);
}

function deleteScript(docCard) {
  console.debug(docCard);
  let parentId = docCard.parents.value ? docCard.parents.value : docCard.parentFolder;
  DocUtility.removeCardViews(parentId);
  if (parentId.includes(',')) {
    let parentIds = parentId.split(/,\s*/);
    for (let id of parentIds) {
      if (id.trim().length) {
        // selecting any non-empty parent string
        parentId = id;
        break;
      }
    }
  }
  DocUtility.deleteItem(docCard.id, docCard.fileName, 'file', parentId, true, 'menu_scripts', function () {
    $("." + Utility.getValidId(docCard.id.replace('.', '_').replace(' ', '_'))).remove();
  });
}

function emptyDocList(){

  var div = document.getElementById('docListDiv');

  while(div.firstChild){

    div.removeChild(div.firstChild);
  }
}

function sidesOverlayOn(){
	document.getElementById("emptySidesOverlay").style.display = "block";
  }
