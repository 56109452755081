import i18n from './Translation';
import DownloadNotification from './notifications/DownloadNotification.js';

export default function addCancelSubListener() {
  document.getElementById('cancelSubscriptionBtn').onclick = wrapUp;

  async function wrapUp() {
    let prodOwnerEmail = croogloo_auth.crooglooauth.productionOwnerEmail || (await fetchSubscriptionInfo()).productionOwnerEmail;
    if((!prodOwnerEmail || prodOwnerEmail !== croogloo_auth.crooglooauth.email) && croogloo_auth.crooglooauth.securityListId !== 'ADMIN') {
      swalToast({
        title: i18n.t("js.sub.cancel.admin-only"),
        type: 'info'
      });
      return;
    }
    let isTrial = croogloo_auth.crooglooauth.isTrial || (await isTrialProd());
    if(isTrial) {
      swalToast({
        title: i18n.t("js.sub.cancel.trial"),
        type: 'info'
      });
      return;
    }
    if(croogloo_auth.crooglooauth.totalNbOfProds > 1) {
      swalToast({
        title: i18n.t("js.sub.cancel.not-auto"),
        type: 'info'
      });
      return;
    }
    let paymentMethod = await fetchPaymentMethod();
    if(paymentMethod !== 'stripe') {
      swal({
        title: i18n.t("js.sub.cancel.incompatible.title"),
        html: i18n.t("js.sub.cancel.incompatible.text", 
          {contactUs:'<span id="wrapSupportLink" class="contact-creation-swal-link">$t(js.sub.cancel.incompatible.contactUs)</span>'}),
        type: 'info',
        confirmButtonText: i18n.t("OK"),
        onOpen: function() {
          document.getElementById('wrapSupportLink').onclick = function() {
            // empty function, should be refactored in the future to remove this section.
          };
        }
      }).catch(swal.noop);
      return;
    }
    swal({
      html: '<b style="font-size: 1.4rem">'+i18n.t("js.sub.cancel.wrapped")+'</b><br style="line-height: 3rem">' + i18n.t("js.sub.cancel.data"),
      input: 'radio',
      inputOptions: {
        zip: i18n.t("js.sub.cancel.zip"),
        cloud: i18n.t("js.sub.cancel.cloud", {amount: 99})//'Archive to the cloud ($99/yr)'
      },
      inputValue: 'zip',
      inputClass: 'cg-swal-radio',
      background: 'center / cover no-repeat url(' + IMG_DIRECTORY + 'dashboard/confetti_background.jpg)',
      customClass: 'swal-white-font',
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      confirmButtonText: i18n.t("button.confirm"),
    }).then(wrapOption => {
      swal({
        type: 'info',
        animation: false,
        title: i18n.t("js.sub.cancelling.title"),
        html: wrapOption == 'zip' ? i18n.t("js.sub.cancelling.zip"):undefined,
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        showConfirmButton: false,
        onOpen: function() {
          swal.clickConfirm();
        },
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            apicall('productionapi', 'wrapProduction', {
              convertToArchive: wrapOption !== 'zip'
            }).then(resp => {
              if(resp.responseCode === '0') {
                if(wrapOption == 'zip') {
                  console.debug('wrap success, moving on to ZIP creation');
                  try {
                    document.getElementById('swal2-title').innerHTML = i18n.t("js.sub.cancelling.archiving");
                  } catch(err1) {
                    console.error('Failed to update swal title', err1);
                  }
                  apicall('importexportapi', 'downloadAllFiles', {}).then(innerResp => {
                    if(innerResp.responseCode === '0') {
                      DownloadNotification.add(false, false, innerResp.responseMessage.split(",")[0], 
                        innerResp.responseMessage.split(",")[1] || null, new Date().getTime());        
                      resolve();
                    } else {
                      throw new Error('Failed to create ZIP file.');
                    }
                  }).catch(err => {
                    throw err;
                  });
                } else {
                  resolve();
                }
              } else if(resp.responseCode === '1') {
                reject(i18n.t("js.sub.cancelling.cancelled"));
              } else {
                throw new Error('Failed to wrap production');
              }
            }).catch(err => {
              console.error(err);
              reject(i18n.t("js.utils.something.bad"));
            });
          });
        }
      }).then(function() {
        swal({
          type: 'success',
          title: i18n.t("js.sub.cancelled.title"),
          html: i18n.t("js.sub.cancelled." + (wrapOption == 'zip' ? "zip":"cloud")),
          allowOutsideClick: false
        }).then(function() {
          setTimeout(surveyClient, 500);
        }).catch(swal.noop);
      }).catch(swal.noop);
    }).catch(swal.noop);
    
    function isTrialProd() {
      return new Promise(resolve => {
        fetchSubscriptionInfo().then(() => resolve(isTrial));
      });
    }

    function fetchPaymentMethod() {
      return new Promise(resolve => {
        showSpinner();
        apicall('securityadminapi', 'fetchPaymentMethod', {}).then(resp => {
          if(resp.responseCode === '0') {
            hideSpinner();
            resolve(resp.responseMessage);
          } else {
            throw new Error('Failed to retrieve payment method.');
          }
        }).catch(err => {
          console.error(err);
          hideSpinner();
          resolve(null);
        });
      });
    }

    function fetchSubscriptionInfo() {
      return new Promise(resolve => {
        showSpinner();
        apicall('securityadminapi', 'fetchSubscriptionInfo', {}).then(resp => {
          if(resp !== null && typeof resp === 'object'
              && typeof resp.productionOwnerEmail === 'string'
              && resp.productionOwnerEmail.trim() !== '') {
            hideSpinner();
            isTrial = !(resp.isTrial === false); // only allow trial to be false if the response is a boolean whose value is false
            resolve(resp);
          } else {
            throw new Error('Failed to retrieve production owner.');
          }
        }).catch(err => {
          console.error(err);
          hideSpinner();
          resolve(null);
        });
      });
    }
  }
}
