import i18n from './Translation';
import moment from 'moment';
import swal from 'sweetalert2';

export default function getExpiringContacts() {
  const EXPIRY_ACTIONS = {EXPIRY_ACTION_NONE:0, EXPIRY_ACTION_DELETE:1,
    EXPIRY_ACTION_REMOVE_DISTRIBUTION_LIST_MEMBERSHIPS:2, EXPIRY_ACTION_DONE:3};
  Object.freeze(EXPIRY_ACTIONS);

  let tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  let yesterday = moment().add(-1, 'days').format('YYYY-MM-DD');
  let threeWeeksLater = moment().add(3, 'weeks').format('YYYY-MM-DD');
  let today = moment().format('YYYY-MM-DD');
  fetchExpiringContacts(tomorrow, 'tomorrow', i18n.t("js.cntct-mngr.tomorrow.title"), i18n.t("js.cntct-mngr.tomorrow.text"), true, false);
  fetchExpiringContacts(threeWeeksLater, 'three-weeks', i18n.t("js.cntct-mngr.3weeks.title"), i18n.t("js.cntct-mngr.3weeks.text"), false, false);
  fetchExpiringContacts(yesterday, 'yesterday', i18n.t("js.cntct-mngr.expired.title"), i18n.t("js.cntct-mngr.expired.text"), true, true);

  function buildSwal(textForTitle, arr, txtSub, renderBtn, anim){
    swal({
      title: textForTitle,
      html: renderExpiringContacts(arr, txtSub, renderBtn),
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("OK"),
      allowOutsideClick: true,
      showCloseButton: true,
      animation: anim,
      onClose: () =>{
            $('.swal2-popup').removeClass('swal2-noanimation');
            $('.swal2-popup').addClass('swal2-hide');
          },
      onOpen: function() {
        $('.remove-expired-contact').click(function(e) {
          if (!$(this).is("[disabled]")) {
            handleExpiredContact($(this).attr('personId'), $(this),
              EXPIRY_ACTIONS.EXPIRY_ACTION_DELETE, textForTitle, arr, txtSub, renderBtn);
          }
        });
        $('.remove-expired-contact-distro-lists').click(function(e) {
          if (!$(this).is("[disabled]")) {
            handleExpiredContact($(this).attr('personId'), $(this),
              EXPIRY_ACTIONS.EXPIRY_ACTION_REMOVE_DISTRIBUTION_LIST_MEMBERSHIPS, textForTitle, arr, txtSub, renderBtn);
          }
        });
      }
    }).catch(swal.noop);    
  }
  function fetchExpiringContacts(expiryDate, textForSpanId, textForTitle, textForSubTitle, renderButtons, showExpiredContacts) {
    apicall('personapi', 'fetchExpiredContacts', { expiryDate: expiryDate }).then(async (resp) => {
      if (resp.items.length > 0) {
        if ($('#contact-expire-' + textForSpanId + '-notif').length > 0) {
          $('#contact-expire-' + textForSpanId + '-notif').parent().parent().remove();
          cgNotification.addCounter(-1);
        }
        resp.items = resp.items.filter(function(e) {
          let actionAlreadyTaken = e.properties.expiryAction && e.properties.expiryAction != EXPIRY_ACTIONS.EXPIRY_ACTION_NONE;
          if (showExpiredContacts) {
            return true && !actionAlreadyTaken;
          } else {
            return !moment(e.properties.endDate).isBefore(today) && !actionAlreadyTaken;
          }
        })
        if (resp.items.length == 0) {
          return;
        }
        let span = document.createElement('span');
        span.id = 'contact-expire-' + textForSpanId + '-notif';
        span.innerHTML = i18n.t("js.cntct-mngr.click");
        span.addEventListener('click', function(e) {  
        buildSwal(textForTitle, resp.items, textForSubTitle, renderButtons, true); 
        });
        cgNotification.addNotification(textForTitle, span);
      }
    }, rej => {
      throw rej;
    }).catch(err => {
      if(err !== 'request context changed') {
        swalToast({
          type: 'error',
          title: i18n.t("js.cntct-mngr.fetch.err")
        });
        console.error(err);
      }
    });
  }

  function renderExpiringContacts(people, subTitle, renderButtons, showExpiredContacts,) {
    let container = document.createElement('div');
    let subTitleContainer = document.createElement('h5');
    subTitleContainer.innerHTML = subTitle;
    container.appendChild(subTitleContainer);
    let personNameColumn = renderButtons? "6":"12";
    let buttonVisible = renderButtons? "": "none";
    for (let i in people) {
      let person = people[i];
      let personDiv = document.createElement('div');
      personDiv.innerHTML = "<div class='grid-x grid-margin-x'><div class='cell medium-"+personNameColumn+"' style='padding-top:5px;'>"
      + "<span style='font-size:110%;'>" + person.properties.firstName + " "
      + person.properties.lastName + "</span></div>"
      + "<div class='cell medium-3' style='display:"+ buttonVisible +"'>"
      + "<a class='button small warning remove-expired-contact-distro-lists' style='font-size:0.6rem;height:70%;padding-left:0;padding-right:0;' personId='"
      + person.key.name + "' " + ">"
      + i18n.t("js.cntct-mngr.remove")+"</a></div>"
      + "<div class='cell medium-3' style='display:"+ buttonVisible +"'>"
      + "<a class='button slim-2x alert remove-expired-contact' style='height:70%;width:100%;padding-top:1rem;' personId='"
      + person.key.name + "' " + ">"
      + i18n.t("utils.delete")+"</a></div></div>";
      container.appendChild(personDiv);
    }
    return container.outerHTML;
  }

  function handleExpiredContact(personId, $button, action, textForTitle, arr, txtSub, renderBtn) {
    // Unfortunately, multiple swal alert cannot exist at the same time due to
    // their technical debt... So we make a whole new swal but now without the removed element.

    //Swal that would confirm if user wants to delete/ remove from distro the contact
    swal({
      title: i18n.t("js.cntct-mngr.proceed"),
      type: 'warning',
      allowOutsideClick: false,
      showCloseButton: true,
      showCancelButton: true,
      showConfirmButton: true,
      animation: false,
      useRejections: true,     
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
      }
    }).then((resp) =>{     
      if(resp){
        showSpinner();   
        var tmp = new Object();
        tmp.personIds = personId;
        tmp.expiryAction = action;      
        apicall('personapi','registerExpiryAction',tmp).then(function(resp) {
          if(resp && resp.responseCode && resp.responseCode === '0'){
            //For loop to find the element that was removed by the user.
            if(arr.length > 1){

              let tempIndex;
                for(let i in arr){

                if(arr[i].key.name === personId){

                  tempIndex = i;
                  break;
                }
              }
              //Removing the elemnt from array    
              arr.splice(tempIndex,1);

            }else{
              //Setting array to an empty array since we cannot splice the last elemnt. (removing last element = empty array)
              arr = [];
            }
            hideSpinner();                  
            //Creating new swal with remaining elements
            buildSwal(textForTitle, arr, txtSub, renderBtn, false);            
          } else {
            hideSpinner();            
            cgToast(i18n.t("js.utils.server.error-internet"));
          }
        }, rej => {
          throw rej;
        }).catch(err => {
          hideSpinner();
          cgToast(i18n.t("js.cntct-mngr.handling.err"));
          console.error(err);
        });

      }        
    }).catch(() =>{
      console.debug("User decided not to remove contact");
      buildSwal(textForTitle, arr, txtSub, renderBtn, false);      
    });
 }
}
