import './FileDropper.css';

export default class FileDropper {
  constructor(dropZoneId, dragText, ondrop, scrollElement = null) {
    this.configureDropZone(dropZoneId);
    this.dragText = dragText;
    this.ondrop = ondrop;
    this.scrollElement = scrollElement;
    this.buildOverlays();
    this.initListeners();
  }

  configureDropZone(dropZoneId) {
    let dropZone = document.getElementById(dropZoneId);
    if(dropZone.style.position !== 'relative') {
      console.warn(`settings ${dropZoneId} position to relative`);
      dropZone.style.position = 'relative';
    }
    this.dropZone = dropZone;
  }

  resetTextOverlay() {
    this.updateTextOverlay(this.dragText);
  }

  updateTextOverlay(newText) {
    this.textOverlay.innerHTML = `<i ${window.croogloocurrentpage.securityPageName == 'compose' 
      ?('style="font-size: 2.75rem" class="icon-attachment"') 
      :('class="fa fa-file-upload"')}></i>&nbsp;${newText}`;
  }

  buildOverlays() {
    let that = this;

    deleteRemnants();
    this.backgroundOverlay = buildOverlayElem('dropper-background-overlay');
    this.textOverlay = buildOverlayElem('dropper-text-overlay');
    this.resetTextOverlay();

    function buildOverlayElem(className) {
      let overlay = document.createElement('div');
      overlay.classList.add('file-dropper-overlay', className);
      overlay.style.display = 'none';
      that.dropZone.appendChild(overlay);
      return overlay;
    }

    function deleteRemnants() {
      let remnantOverlay;
      let i = 0;
      while((remnantOverlay = document.querySelector('div.file-dropper-overlay')) !== null 
          && i++ < 20) {
        remnantOverlay.delete();
      }
    }
  }
  
  initListeners() {
    let that = this;

    let draggedOverElemCount = 0;
    let dropZone = this.dropZone;
    let backgroundOverlay = this.backgroundOverlay;
    let textOverlay = this.textOverlay;
    
    const VISIBLE_OVERLAY_CLASS = 'visible-drop-overlay';
    const FADE_IN_CLASS = 'fade-in';

    dropZone.ondragenter = function(event) {
      if(that.isDisabled) {
        console.debug('FileDropper is disabled');
        return;
      }
      event.preventDefault();
      if(draggedOverElemCount++ == 0) {
        event.dataTransfer.dropEffect = event.dataTransfer.effectAllowed = 'copy';
        if(that.scrollElement) {
          backgroundOverlay.style.top = `${that.scrollElement.scrollTop.toString()}px`;
          backgroundOverlay.style.left = `${that.scrollElement.scrollLeft.toString()}px`;
          textOverlay.style.top = `${that.scrollElement.scrollTop.toString()}px`;
          textOverlay.style.left = `${that.scrollElement.scrollLeft.toString()}px`;
        }
        backgroundOverlay.classList.add(VISIBLE_OVERLAY_CLASS, FADE_IN_CLASS);
        textOverlay.classList.add(VISIBLE_OVERLAY_CLASS);
      }
    }
    
    dropZone.ondragover = function(event) {
      event.preventDefault();
      if(that.isDraggingHTMLElem) {
        console.debug('FileDropper is disabled');
        return;
      }
      event.dataTransfer.dropEffect = event.dataTransfer.effectAllowed = 'copy';
    }
    
    dropZone.ondragleave = function(event) {
      event.preventDefault();
      if(that.isDraggingHTMLElem) {
        console.debug('FileDropper is disabled');
        return;
      }
      if(--draggedOverElemCount <= 0) {
        backgroundOverlay.classList.remove(VISIBLE_OVERLAY_CLASS, FADE_IN_CLASS);
        textOverlay.classList.remove(VISIBLE_OVERLAY_CLASS);
      }
    }
    
    dropZone.ondrop = function(event) {
      event.preventDefault();
      if(that.isDraggingHTMLElem) {
        console.debug('FileDropper is disabled');
        return;
      }
      draggedOverElemCount = 0;
      backgroundOverlay.classList.remove(VISIBLE_OVERLAY_CLASS, FADE_IN_CLASS);
      textOverlay.classList.remove(VISIBLE_OVERLAY_CLASS);
      try {
        let files = (typeof event.dataTransfer.files !== 'undefined' && event.dataTransfer.files !== null)
          ?event.dataTransfer.files
          :[].map.call(event.dataTransfer.items, item => item.getAsFile());
        let fileList = [].slice.call(files);
        if(fileList.length) {
          that.ondrop(fileList, event);
        } else {
          console.debug('no file dropped; might have been an html element');
        }
      } catch(e) {
        console.error(e);
      } finally {
        that.resetTextOverlay();
      }
    }
  }
}