import swal from 'sweetalert2';
import i18n from './Translation';
import saveAs from 'file-saver';

export default class DocUtility{

    static downloadFile(fileId) {
        showSpinner();
        apicall('documentsapi', 'fetchDownloadLink', {fileId: fileId}).then(resp => {
            if(resp.responseCode === '0') {
                window.location.href = resp.responseMessage;
            } else {
                throw new Error('server error');
            }
        }).catch(() => {
            cgToast(i18n.t("js.utils.server.error"));
        }).then(hideSpinner);
    }

    static deleteItem(itemid, name, type, parentId, removeAllCopies, page, callback) {
        swal({
          title: i18n.t("js.doc-utils.delete.title", {name}),
          text: i18n.t("js.doc-utils.delete.text."+(type == 'file' ? "file":"folder")),
          type: 'question',
          showCancelButton: true,
          confirmButtonText: i18n.t("js.doc-utils.delete.submit"),
          confirmButtonColor: '#BC2121',
          cancelButtonText: i18n.t("js.doc-utils.delete.cancel"),
          showCloseButton: true,
          allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
          if (result) {
            var tmp = new Object();
            tmp.parentId = parentId;
            tmp.page = page;
            tmp.removeAllCopies = removeAllCopies;
            console.debug(tmp);
            saveSystemActivity({
              action: 'submit',
              params: findSystemActivityParams(tmp),
              message: 'User deleted ' + type + ' ' + name + '.'
            });
            
            apicall('documentsapi', 'deleteItemAndChildren', tmp, {
              value: itemid
            }).then(function(resp) {
              if (resp) {
                  callback && callback();
                cgToast(i18n.t("js.doc-utils.deleted."+(type=="file"?"file":"folder")), 2000);
              } else {
                cgToast(i18n.t("js.doc-utils.deleted.failed."+(type=="file"?"file":"folder"), {name}));
              }
            },()=>{
              cgToast(i18n.t("js.doc-utils.deleted.failed."+(type=="file"?"file":"folder"), {name}));
            });
          }
        }).catch(swal.noop);
    }

    static showParseScriptAlert(itemid, name, onSuccess) {
        var versionDropdowns = "<form><div class='colorSelector'>"+i18n.t("js.file.script.version")+"<div><br>" +
          '<div><select name="colorMult" class="browser-default" id="colorMult">' +
          '<option selected value="-">'+i18n.t("js.file.script.version")+'</option>' +
          '<option value="draft">Draft</option>' +
          '<option value="single">Single</option>' +
          '<option value="double">Double</option>' +
          '<option value="triple">Triple</option>' +
          '<option value="quadruple">Quadruple</option>' +
          '<option value="quintuple">Quintuple</option></select>' +
          '<select name="colorVal" class="browser-default" id="colorVal">' +
          '<option selected value="-">'+i18n.t("js.file.script.color.default")+'</option>' +
          '<option value="white">White</option>' +
          '<option value="blue">Blue</option>' +
          '<option value="pink">Pink</option>' +
          '<option value="yellow">Yellow</option>' +
          '<option value="green">Green</option>' +
          '<option value="goldenrod">Goldenrod</option>' +
          '<option value="buff">Buff</option>' +
          '<option value="salmon">Salmon</option>' +
          '<option value="cherry">Cherry</option>' +
          '<option value="tan">Tan</option>' +
          '<option value="lavender">Lavender</option>' +
          '</select></div><br/>' +
          '<label style="font-size:18px;">'+i18n.t("episode")+'</label><input id="episodeEntry" '
          + 'placeholder="'+i18n.t("episode.number")+'" type="text" name="episode">';
      
        let episode, color;
      
        swal({
          title: i18n.t("js.doc-utils.script.info.title"),
          html: versionDropdowns,
          showCloseButton: true,
          confirmButtonText: i18n.t("button.next"),
          confirmButtonColor: '#13C46A',
          cancelButtonText: i18n.t("button.cancel"),         
          useRejections: true, //important for swal2 v7.1.2
          expectRejections: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !swal.isLoading(),
          onOpen: function (swal) {
            jQuery('#episodeEntry').off();
            jQuery('#episodeEntry').on('keypress', function (event) {
              return validKey(event.key);
            });
          },
          preConfirm: function () {
            return new Promise(function (resolve, reject) {
      
              if (jQuery('select[name=colorVal]').val() == "-") {
                reject(i18n.t("js.file.script.reject.color"));
                return;
              }
              episode = parseInt(jQuery('#episodeEntry').val().trim() || '0');
              color = formatScriptColor(jQuery('select[name=colorMult]').val(), jQuery('select[name=colorVal]').val());
      
              DocUtility.parseScript(itemid, name, name, episode, color, false, resolve);
            });
          }
        }).then(function (resp) {
          if (resp.responseCode && resp.responseCode === '-1') {
            let uniqueID = 'a' + new Date().getTime();
            let errorMsg = '';
            if (resp.responseMessage === 'PDF unreadable') {
              errorMsg = i18n.t("js.upload.unparsable", {scriptName: name})
                + '&nbsp;<a class="more-details-msg-swal no-text-select" id="' + uniqueID + '_link">'+i18n.t("js.upload.details")+'</a>';
            } else {
              errorMsg = i18n.t("js.upload.script.failed.parsing")+" "+i18n.t("js.upload.script.failed.file");
            }
            swal({
              title: i18n.t("response.error"),
              html: errorMsg,
              type: 'error',
              confirmButtonColor: '#13C46A',
              confirmButtonText: i18n.t("OK"),
              onOpen: function () {
                jQuery('#' + uniqueID + '_link').off('click');
                jQuery('#' + uniqueID + '_link').on('click', function () {
                  showUnreadablePDFDetails();
                });
              }
            });
            function showUnreadablePDFDetails() {
              swal({
                title: i18n.t("js.upload.pdf.unparsable.title"),
                text: i18n.t("js.upload.pdf.unparsable.text"),
                type: 'info',
                confirmButtonColor: '#13C46A',
                confirmButtonText: i18n.t("OK"),
                showCancelButton: true,
                onOpen: function () {
                  swal.getContent().className += ' swal2-justified';
                }
              });
            }
          } else {
            swal({
              title: i18n.t("response.success"),
              html: i18n.t("js.doc-utils.parsing"),
              type: 'success',
              confirmButtonText: i18n.t("OK"),
              confirmButtonColor: '#13C46A',
              allowOutsideClick: true
            });
            if(typeof onSuccess == 'function') {
              onSuccess(episode, color);
            }
          }
        }).catch(swal.noop);
      
        function formatScriptColor(mult, col) {
          mult = mult !== "-" ? mult : "";
          col = col !== "-" ? col : "";
          let color = 'white';
          if (mult == 'draft')
            color = 'draft';
          else if (mult && col) {
            if (mult == 'single')
              color = col;
            else
              color = mult + col[0].toUpperCase() + col.substring(1);
          }
          else if (col) {
            color = col;
          }
          return color;
        }
      
        function validKey(key) {
      
          if (key === 'Enter') {
            return false; //important
          }
      
          var isValid = !isNaN(key) || key === 'Backspace' ||
            /Arrow/.exec(key) !== null;
      
          if (!isValid && !isInvalidKeyToastShowing) {
      
            isInvalidKeyToastShowing = true;
            cgToast(i18n.t("js.utils.reject.number.only"), 3000);
      
            setTimeout(function () {
      
              isInvalidKeyToastShowing = false
      
            }, 3000);
          }
      
          return isValid;
        }
    }

    static getFileCategories(fileType = 'reportsAndSchedules') {
        let uploadType;
        if (fileType === 'reportsAndSchedules') {
          uploadType = [
            {
              key: "callsheet",
              value: i18n.t("call-sheets")
            }, {
              key: "castList",
              value: i18n.t("cast-list")
            }, {
              key: "chrono",
              value: i18n.t("chrono")
            }, {
              key: "crewList",
              value: i18n.t("crew-list")
            }, {
              key: "oneliner",
              value: i18n.t("oneliner")
            }, {
              key: "dood",
              value: i18n.t("doods")
            }, {
              key: "maps",
              value: i18n.t("maps")
            }, {
              key: "photo",
              value: i18n.t("photo")
            }, {
              key: "production",
              value: i18n.t("prod-sched")
            }, {
              key: "shooting",
              value: i18n.t("shoot-sched")
            }, {
              key: "prepschedule",
              value: i18n.t("prepschedule")
            }, {
              key: "dpr",
              value: i18n.t("dpr")
            }, {
              key: "breakdown",
              value: i18n.t("breakdown")
            }, {
              key: "clearance",
              value: i18n.t("clearance")
            }, {
              key: "script",
              value: i18n.t("script")
            }, {
              key: "script timing",
              value: i18n.t("script-timing")
            }, {
              key: "revision",
              value: i18n.t("revision")
            }, {
              key: "side",
              value: i18n.t("sides")
            }, {
              key: "other",
              value: i18n.t("other")
            }
          ];
        } else if (fileType == "report") {
          uploadType = [
            {
              key: "callsheet",
              value: i18n.t("call-sheets")
            }, {
              key: "castList",
              value: i18n.t("cast-list")
            }, {
              key: "chrono",
              value: i18n.t("chrono")
            }, {
              key: "crewList",
              value: i18n.t("crew-list")
            }, {
              key: "oneliner",
              value: i18n.t("oneliner")
            }, {
              key: "dood",
              value: i18n.t("doods")
            }, {
              key: "dpr",
              value: i18n.t("dpr")
            }, {
              key: "breakdown",
              value: i18n.t("breakdown")
            }, {
              key: "clearance",
              value: i18n.t("clearance")
            }, {
              key: "script",
              value: i18n.t("script")
            }, {
              key: "script timing",
              value: i18n.t("script-timing")
            }, {
              key: "revision",
              value: i18n.t("revision")
            }, {
              key: "side",
              value: i18n.t("sides")
            }, {
              key: "other",
              value: i18n.t("other")
            }
          ];
        } else if (fileType == "schedules") {
          uploadType = [
            {
              key: "maps",
              value: i18n.t("maps")
            }, {
              key: "production",
              value: i18n.t("prod-sched")
            }, {
              key: "shooting",
              value: i18n.t("shoot-sched")
            }, {
              key: "prepschedule",
              value: i18n.t("prepschedule")
            }
          ];
        } else if (fileType == "script") {
          uploadType = [
            {
              key: "script",
              value: i18n.t("script")
            }, {
              key: "script timing",
              value: i18n.t("script-timing")
            }, {
              key: "revision",
              value: i18n.t("revision")
            }, {
              key: "side",
              value: i18n.t("sides")
            }
          ];
        } else if (fileType == 'photo') {
          uploadType = [
            {
              key: "photo",
              value: i18n.t("photo")
            }
          ];
        } else {
          uploadType = [];
        }
        return uploadType;
    }

    static generateUniqueId(fileName) { // to test
        const minIdBaseLength = 10, maxIdBaseLength = 25;
        fileName = fileName.trim();
        let fileExtension = fileName.match(/^[\s\S]*\.[^\.]{2,5}$/) == null
            ?""
            :fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase();
        let uniqueID = fileName.replace(/[^A-Za-z\d]/g,'').replace(/(^\d)/, 'f$1');
        if(uniqueID.length < minIdBaseLength) {
            uniqueID = uniqueID.padEnd(minIdBaseLength, (Math.random()*10).toString()
                .replace('.',''));
        } else if(uniqueID.length > maxIdBaseLength) {
            uniqueID = uniqueID.substring(0, maxIdBaseLength);
        }
        uniqueID += '_' + new Date().getTime().toString() + '_';
        uniqueID = uniqueID.padEnd(uniqueID.length + 8,
            (Math.random()*10).toString().replace('.',''));
        uniqueID += (fileExtension == '' ?'' :'.') + fileExtension;
        console.debug('generated file id: ', uniqueID);
        return uniqueID;
    }

    static ensureFileNameUnicity(fileName, onComplete, onCancel, tenantId = null) {
        showSpinner();
        let urlParams = { fileName: fileName };
        if(tenantId !== null) {
          urlParams.tenantId = tenantId;
        }
        apicall('documentsapi', 'fetchDocumentsByName', urlParams).then(function(resp){
          if (resp && resp.items && resp.items.length) {
            console.debug(resp.items);
            var next = resp.items[0].properties.COUNTER_PARTIAL_FOR_NEW_FILES + 1;
            var sug = fileName.substr(0,fileName.lastIndexOf("."))+"("+next+")"
              + fileName.substr(fileName.lastIndexOf("."));
            let parentFilePath = resp.items[0].properties.parentPath;
            let parentFolderName;
            try {
              parentFolderName = parentFilePath[parentFilePath.length-1][1] || 'Documents';
            } catch(e1) {
              console.error(e1);
              parentFolderName = 'Documents';
            }
            swal({              
              title: i18n.t("js.doc-utils.duplicate.title"),
              html: i18n.t("js.doc-utils.duplicate.text",
                {folder: `<a id="duplicateFileFolderLink" style="color: #00b96e; font-weight: bold">${parentFolderName}</a>`}), 
              type: 'info',           
              input: "text",
              inputValue: sug,
              showCancelButton: true,
              animation: true, //We could use animations.css (plugin) to make other swal animations
              inputPlaceholder: i18n.t("docs.rename.plchldr"),
              useRejections: true, //important for swal2 v7.1.2
              expectRejections: true,
              allowOutsideClick: true,
              showCloseButton: true,
              onOpen: function() {
                document.getElementById('duplicateFileFolderLink').onclick = e => {
                  try {
                    let fileIdPath = parentFilePath.map(p => p[0]);
                    let fileNamePath = parentFilePath.map(p => p[1]);
                    // shifting to ignore the ROOT folder
                    fileIdPath.shift();
                    fileNamePath.shift();
                    var pathUrl = (['documents'].concat(fileIdPath)).join('/');
                    let docPageParams = {multiSelectPanel: [], path: fileNamePath};
                    r.loadPage(pathUrl, docPageParams);
                  } catch(e) {
                    console.error(e);
                    r.loadPage('documents');
                  } finally {
                    swal.close();
                  }
                };
              },
              preConfirm: function(newfilename) {
                return new Promise(function(resolve,reject){
                  if(!newfilename.trim()) {
                    reject(i18n.t("js.doc-utils.duplicate.reject.name"));
                    return;
                  }
                  if(newfilename === getValidFileName(newfilename)) {
                    let newUrlParams = { fileName: newfilename };
                    if(tenantId !== null) {
                      newUrlParams.tenantId = tenantId;
                    }
                    resolve();
                  } else {
                    reject(i18n.t("js.doc-utils.duplicate.reject.characters"));
                  }
                });
              }
            }).then(onComplete, onCancel).catch(onCancel);
          } else {
            onComplete(fileName);
          }
        }).catch(onCancel).then(hideSpinner);
    }

    static parseScript(fileId, fileName, desc, episode, version, showFailureToasts, callback){
        var tmp = Object();
        tmp.episode = episode;
        tmp.color = version;//.toLowerCase();
        tmp.scriptName = fileName;
        tmp.fileId = fileId;
        tmp.description = desc;
    
        apicall('scriptapi', 'parseScript', tmp, undefined, false).then(function (resp) {
        if(resp.responseCode && resp.responseCode === '-1') {
          tmp.responseMessage = resp.responseMessage;
          saveSystemActivity({
              action: 'submit',
              params: findSystemActivityParams(tmp),
              message: 'User failed to parsed a script from the upload page.'
          });
          if(resp.responseMessage === 'PDF unreadable' && showFailureToasts) {
            let uniqueID = 'a' + new Date().getTime();
            cgToast(i18n.t("js.upload.unparsable", {scriptName: tmp.scriptName})
              + '&nbsp;<a class="more-details-msg no-text-select" id="'+uniqueID+'_link">'+i18n.t("js.upload.details")+'</a>',
              { duration: 3600000,  showCloseButton: true, id: uniqueID+'_toast' });
            jQuery('#'+uniqueID+'_link').off('click');
            jQuery('#'+uniqueID+'_link').on('click', function() {
              showUnreadablePDFDetails();
              jQuery('#'+uniqueID+'_toast > div > span.cg-toast-close-btn').click();
            });
          } else if(showFailureToasts) {
            cgToast(i18n.t("js.upload.script.failed.parsing"), i18n.t("js.upload.script.failed.file"));
          }
        } else {
          saveSystemActivity({
              action: 'submit',
              params: findSystemActivityParams(tmp),
              message: 'User parsed a script.'
          });
        }
            if(typeof callback === 'function') {
                callback(resp);
            }
      });
    
      function showUnreadablePDFDetails() {
        swal({
          title: i18n.t("js.upload.pdf.unparsable.title"),
          text: i18n.t("js.upload.pdf.unparsable.text"),
          type: 'info',
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("OK"),
          showCloseButton: true,
          animation: ($('#docBody').hasClass('swal2-shown') ? false : true),
          onClose: () =>{
            $('.swal2-popup').removeClass('swal2-noanimation');
            $('.swal2-popup').addClass('swal2-hide');
          },
          onOpen: function() {
            swal.getContent().className += ' swal2-justified';
          }
        });
      }
    }

  static getSignedURL(fileId, tenantId = null) {
    console.debug('Retrieving signed URL for fileId: ' + fileId)
        return new Promise(resolve => {
            let urlParams = { fileId: fileId };
            if(tenantId !== null) {
                urlParams.tenantId = tenantId;
            }
            apicall('documentsapi', 'fetchSignedURL', urlParams)
                .then(resp => {
                    if(resp.responseCode == '0') {
                        resolve(resp.responseMessage);
                    } else {
                        console.debug(resp.responseMessage);
                        resolve(null);
                    }
                }, function(rejection) {
                    throw rejection;
                }).catch(err => {
                  console.error(err)
                    cgToast(i18n.t("js.photos.failed-fetch"));
                });
        });
    }

    static openFile(fileId, fileName, isWatermarked = false, forceDownload = false, cgNotification = null, url = null, tenantId = null) {
        if (url != null && url != "" && (url.indexOf("www.dropbox.com") >= 0 || url.indexOf('google.com') >= 0 || url.indexOf('app.box.com'))) {
            viewOrDownload();
        } else {
            DocUtility.getFileURL(fileId, isWatermarked, tenantId).then(fetchedURL => {
                url = fetchedURL;
                viewOrDownload();
            });
        }

        function viewOrDownload() {
            if(!forceDownload && fileId.toLowerCase().endsWith('pdf')) {
                DocUtility.viewFile(url, fileName);
            } else {
                downloadFile(url);
            }
        }

        function downloadFile(url) {
            // DEPRECATION WARNING - download suffix should not be used anymore
            // NOTE: we remove spaces in the file name because firefox only takes letters before the first space
            let forceDownlaodSuffix = '&response-content-disposition=attachment;filename='+fileName.replace(/\s/g, '_')+';';
            if(!url.endsWith(forceDownlaodSuffix) && url.includes('storage.googleapis')) {
                url += forceDownlaodSuffix;
                r.newTab(url);
                return;
            } else if (!url.startsWith('blob:')) {
                r.newTab(url);
                return;
            }

            try {
                saveAs(url, fileName);
            } catch(err) {
                console.error(err);
                swalToast({
                    title: i18n.t("js.doc-utils.download.failed"),
                    type: 'error'
                });
            }

            if(cgNotification !== null) {
                cgNotification.addNotification(i18n.t("utils.download"), i18n.t("js.doc-utils.download.file", {fileName}));
            }
        }
    }

    static viewFile(url, fileName) {
        if(url.startsWith('blob:')) {
            let linkId = 'l' +(Math.random()+'').substring(2);
            localStorage.setItem(linkId, JSON.stringify({
                blobURL: url,
                fileName: fileName
            }));
            window.open('/pdfViewer/web/viewer.html?linkId='+linkId);
        } else {
            if(url.includes('storage.googleapis')) {
                url += '&filename='+fileName+';';
            }
            r.newTab(url);
        }
    }

    static getFileURL(fileId, isWatermarked, tenantId = null) {
        console.log(fileId)
        return new Promise((resolve, reject) => {
            if(isWatermarked === true || isWatermarked === '1') {
                cgToast(i18n.t("js.doc-utils.watermarking"));
            }
            let requestTenantId = tenantId || croogloo_auth.tenantId;
            watermarkFile();
            function watermarkFile() {
                var url = "/WatermarkServlet?token="+croogloo_auth.usertoken+"&tenantId="+
                    requestTenantId+"&fileId="+fileId;
                if(tenantId !== null && sessionStorage.getItem('sharing_id') != null) {
                    url += "&sharingLinkId=" + sessionStorage.getItem('sharing_id');
                }
                authenticatedcall(url,'GET','','application/json',null,"blob")
                .then((blob)=>{
                    if(blob.type == 'text/plain' || blob.type == 'text/json') {
                        var reader = new FileReader();
                        reader.onload = function() {
                            resolve(reader.result);
                        }
                        reader.readAsText(blob);
                    } else {
                        resolve(window.URL.createObjectURL(blob));
                    }
                });
            }
        });
    }

    static getStoredCardViews(folderId, onsuccess, onfailure) {
        let storedItemListJSON = sessionStorage.getItem(Utility.cleanForKey(folderId));
        if(storedItemListJSON !== null) {
            try {
                let storedItemList = JSON.parse(storedItemListJSON);
                if(!Array.isArray(storedItemList)) {
                    console.error(storedItemList);
                    throw new Error('item list is not an array');
                }
                console.log("Stored item list: ", storedItemList)
                if(typeof onsuccess == 'function') { onsuccess(storedItemList); }
            } catch(e1) {
                console.error(e1);
                if(typeof onfailure == 'function') { onfailure(); }
            }
        } else {
            if(typeof onfailure == 'function') { onfailure(); }
        }
    }

    static storeCardViews(folderId, cardViews) {
        let cleanFolderId = Utility.cleanForKey(folderId);
        try {
            if(folderId !== 'ROOT') {
                let storedCardViewFoldersJSON = sessionStorage.getItem('storedFolders');
                let storedCardViewFolders;
                if(storedCardViewFoldersJSON !== null) {
                    storedCardViewFolders = JSON.parse(storedCardViewFoldersJSON);
                    if(storedCardViewFolders.length >= 5) { // we store 5 folders max + ROOT to save space
                        sessionStorage.removeItem(storedCardViewFolders.shift());
                    }
                    storedCardViewFolders.push(cleanFolderId);
                } else {
                    storedCardViewFolders = [cleanFolderId];
                }
                sessionStorage.setItem('storedFolders', JSON.stringify(storedCardViewFolders));
            }
        } catch(e) {
            console.error(e);
            sessionStorage.setItem('storedFolders', JSON.stringify([cleanFolderId]));
        }
        sessionStorage.setItem(cleanFolderId, JSON.stringify(cardViews));
    }

    static removeCardViews(folderIds) {
        try {
            console.debug('removing stored card views', folderIds);
            let storedCardViewFoldersJSON = sessionStorage.getItem('storedFolders');
            let storedCardViewFolders = [];
            if(storedCardViewFoldersJSON !== null) {
                storedCardViewFolders = JSON.parse(storedCardViewFoldersJSON);
            }
            for(let folderId of folderIds.split(',')) {
                if(folderId) {
                    let cleanFolderId = Utility.cleanForKey(folderId);
                    sessionStorage.removeItem(cleanFolderId);
                    if(storedCardViewFolders.includes(cleanFolderId)) {
                        storedCardViewFolders.splice(storedCardViewFolders.indexOf(cleanFolderId), 1);
                        sessionStorage.setItem('storedFolders', JSON.stringify(storedCardViewFolders));
                    }
                }
            }
        } catch(e) {
            console.error(e);
            sessionStorage.clear();
        }
    }

    static clearStoredFiles() {
          try {
              console.debug('removing all stored card views');
              let storedCardViewFoldersJSON = sessionStorage.getItem('storedFolders');
              if(storedCardViewFoldersJSON !== null) {
                  let storedCardViewFolders = JSON.parse(storedCardViewFoldersJSON);
                  for(let folderId of storedCardViewFolders) {
                      let cleanFolderId = Utility.cleanForKey(folderId);
                      sessionStorage.removeItem(cleanFolderId);
                  }
              }
          sessionStorage.removeItem('ROOT');
              sessionStorage.removeItem('storedFolders');
          } catch(e) {
              console.error(e);
              sessionStorage.clear();
          }
    }

    static getDocumentDescription(object) {
        return new Promise(resolve => {
            swal({
                title: i18n.t("js.doc-utils.description.title"),
                type: 'info',
                input: 'textarea',
                inputPlaceholder: i18n.t("js.doc-utils.description.placeholder"),
                confirmButtonColor: '#13C46A',
                confirmButtonText: i18n.t("button.confirm"),
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: i18n.t("js.sides.callsheet.skip"),
                animation: false,
                showLoaderOnConfirm: false,
                useRejections: true, //important for swal2 v7.1.2
                expectRejections: true,
            }).then(function(description) {
                object.description = (description || '').trim();
                resolve(true);
            }, dismiss => resolve(dismiss === 'cancel')); // cancel = skip button
        });
    }

  static sortCallsheetOptions(unorderedCallsheets) {
    let callsheets = [...unorderedCallsheets];

    try {
      // There must be at least 2 callsheets in order to sort them.
      if (callsheets.length > 1) {
        callsheets.map((callsheet) => {
          // Check if the value we need to compare against and order the callsheets exists on this callsheet object.
          if ('lastModifBaseTime' in callsheet.properties) {
            callsheets.sort(function (a, b) {
              return Utility.compare(b.properties.lastModifBaseTime, a.properties.lastModifBaseTime);
            })
          } else if ('timeCreated' in callsheet.properties) {
            return Utility.compare(b.properties.timeCreated, a.properties.timeCreated);
          } else {
            // Nothing to compare against, console log an error.
            console.error('Could not retrieve the lastModifBaseTime of callsheet to compare and order it. Callsheet: ' + callsheet);
          }
        });
      }
    } catch (error) {
      console.warning("An error occurred while sorting callsheets, returning them unsorted: " + error);
      return callsheets;
    }

    return callsheets;
  }

}