import i18n from './components/Translation';
import DocUtility from './components/DocUtility';
import Chart from 'chart.js';
import swal from 'sweetalert2';

export function init() {
  loader.add(showSpinner)
        .add(initGlobals)
        .add(ui)
        .add(function() {
          getMetaData(() => loader.check('distroListRendered'));
        }, 'distroListRendered')
        .execute();
}

const STATUS = 2;

Object.freeze(STATUS);

function escapeHTML(html) {
  let div = document.createElement('div');
  div.textContent = html;
  return div.innerHTML;
}

function ui() {
  $('#main').foundation();
  Utility.loadStylesheet('/assets/croogloo-custom/css/no-data.css');
  Utility.loadStylesheet('/assets/croogloo-custom/css/mailbox-menu.css');
  Utility.loadStylesheet('/assets/material-design-icons/css/material-design-icons.min.css');

  let historyContentClassName = 'content-sent';

  try {
    if(historyContentClassName) {
      document.getElementById('distro-history-content').classList.add(historyContentClassName);
    }
  } catch(err) {
    console.error(err);
  }
}

var rowNum; 
function initListeners() {
  $('div.message-row').off('click');

  //View the last sent message in the inbox (highlighted and showing message preview on the right)
  showFullReportOverlay(copyOfMsgAttributes[0].id);

  $('div.message-row').on('click', function(event) {
    rowNum = parseInt(this.id.split('row', 2)[1]);
    showFullReportOverlay(copyOfMsgAttributes[rowNum].id);
    
    $('div.forwardBtn').off('click');
    $('.forwardBtn').on('click', function(e) {
        e.stopImmediatePropagation();
        copyOfMsgAttributes.forEach(msg=>{
            if(msg.itemNum==rowNum){
                restoreMessage(msg.id, false);
            }
        });
    });
    $('.copyBtn').on('click', copyRecipients);
 
  });

  $('#searchMsgInput').on('search', () => {
    let names = document.querySelectorAll('.message-row')
    
    names.forEach(item => {
      item.style.display = 'inline-block'
    })
  }) 

  $('#searchMsgInput').on("keyup", (e) => {

    
    let value = e.target.value
    let regex = new RegExp(value, 'i')

    let names = document.querySelectorAll('.message-row')
    names.forEach(item => {

      let subItem = item.querySelector('.distro-info')

      if(!regex.test(subItem.textContent)) {
        item.style.display = 'none'
      }
      else {
        item.style.display = 'inline-block'
      }

    })
  })

}

function restoreMessage(msgId, restoreRecipients = true, restoreContent = true,
    onBeforeUnload = null) {
  showSpinner();
  apicall('distributionapi', 'fetchMessageForId', { msgId: msgId }).then(resp => {
    hideSpinner();
    if (resp.status == STATUS) {
      resp.restoreRecipients = restoreRecipients;
      resp.restoreContent = restoreContent;
      if(typeof onBeforeUnload == 'function') { onBeforeUnload(); }
      r.loadPage('compose', { savedMessage: resp });
    } else {
      console.error(resp);
      cgToast(i18n.t("js.distro.hist.load.err"));
    }
  }, function(resp) {
    console.error(resp);
    hideSpinner();
    cgToast(i18n.t("js.distro.hist.load.err"));
  });
}

var cont;
var totalPages;
var status;
var lastKeyStroke;
var distributionListNameMap, departmentNameMap;
const NB_OF_TOTAL_ITEMS = 500; // must be a constant with the current mechanics, should be infinity in a perfect world
var latestFetchedMessages;
var historySearchCursor;
var copyOfMsgAttributes=[];

function initGlobals() {
  
  status = Utility.getPageParam('status');
  lastKeyStroke = null;
  distributionListNameMap = {};
  departmentNameMap = {};
  latestFetchedMessages = null;
  totalPages = 1;
  historySearchCursor = null;
  cont = true;
}


function fetchDistroLists(done) {
  if(Object.entries(distributionListNameMap).length) {
    done();
  } else {
    apicall('distributionapi', 'fetchDistributionListNames', {}).then(resp => {
      for(let distroList of resp.items) {
        distributionListNameMap[distroList.key.name] = distroList.properties.distributionListName;
      }
    }).catch(() => {
      console.error('server error - fetchDistributionListNames');
    }).then(done);
  }
}

function fetchDepartments(done) {
  if(Object.entries(departmentNameMap).length) {
    done();
  } else {
    apicall('distributionapi', 'fetchDepartmentNames', {}).then(resp => {
      for(let department of resp.items) {
        departmentNameMap[department.key.name] = department.properties.departmentName;
      }
    }).catch(() => {
      console.error('server error - fetchDepartmentNames');
    }).then(done);
  }
}

function fetchPageEntities(currPage){
  return new Promise(resolve => {
    apicall('distributionapi', 'fetchPageEntites', {
      status: status,
      limit: NB_OF_TOTAL_ITEMS,
      page: 1
    }).then(resp => {
      resolve(Object.values(resp));

    }).catch(() =>{
      console.errpr("Issue retrieving page entities of page " + currPage);
      resolve([]);
    });
  });
}

//Fetching total number of pages  
function fetchTotalNumberMessages() {
  return new Promise((resolve) => {
    apicall('distributionapi', 'countEmailPages', {
    pageSize: NB_OF_TOTAL_ITEMS,
        status: status
    }).then((resp) => {
        if (resp.responseCode && resp.responseCode === '0' && resp.responseMessage) {
            const pageCount = JSON.parse(resp.responseMessage);
            resolve(pageCount);
        } else {
            // If we can't get the number of pages, return 1.
            resolve(1);
        }
  }).catch(() => {
      console.log("Page count not retrieved from distributionapi.countEmailPages");
  });
  });
}

function fetchMessageHistory(done, page) {
  if(typeof page == 'undefined'){
    page = 1;
  }
    fetchPageEntities(page).then(resp =>{
      let msgEntities = resp[0];
      if (typeof msgEntities == 'undefined' || !msgEntities.length || (msgEntities = msgEntities.filter(failedMsgFilter)).length == 0) {
        done([]);
      } else {        
        done(msgEntities);
      }  
    }).catch(err => {
      console.error(err);
      done([]);
    });
  
    /**
   * Used to filter out failed messages that were processed over 3 days from 
   * the current time.
   * @param {CrooglooEntity} msg 
   */
  function failedMsgFilter(msg) {
    return parseInt(msg.properties.status);
  }
}
/**
 * Sometimes the msgEntity.properties.distributionListId is an object with a value key 
 * having the string of all distribution lists in it
 * This was the case for the production BEYONDBLACKBEAUTY
 * @returns string
 */
function getDistributionListIdParamter(distributionListId) {
  if (typeof (distributionListId) == 'string') {
    return distributionListId
  } else {
    return distributionListId.value
  }
}

function displayMsgHistory(msgEntities, clearPreviousMessages = true, isSearchResult = false) {

  let totalNbOfMessages = msgEntities.length;
  
  if(totalNbOfMessages <= 0 && !isSearchResult) {
  } else {
    $("#no-data-span").hide();
    for (let msgEntity of msgEntities) {
      const distributionListId = getDistributionListIdParamter(msgEntity.properties.distributionListId)
      msgEntity.properties.distributionListName = distributionListId
        .split(/\s*,\s*/g)
        .filter(x => !(/^[,\-~]*$/.test(x)))
        .map(x => distributionListNameMap[x])
        .join(', ');
      msgEntity.properties.departmentName = msgEntity.properties.departmentId
        .split(/\s*,\s*/g)
        .filter(x => !(/^[,\-~]*$/.test(x)))
        .map(x => departmentNameMap[x])
        .join(', ');
      msgEntity.properties.dateSentString = formatMsgDate(msgEntity.properties.dateSent);
    }
    renderDistroList(msgEntities, 0);
    
  }
}

function enableSearchBar() {
  document.getElementById('searchMsgInput').disabled = false;
}

function getMetaData(callback, page) {
  let messages;
  new TaskList({ passReference: true })
    .add(showSpinner)
    .add(list => {
      new Promise((resolve) => {        
        if (cont) {
          fetchTotalNumberMessages().then(numsP => {          
            totalPages = numsP;          
          }).catch(() => {
            console.log("Error with the promise");
          });
          cont = false;
        }
        resolve();

      }).then(() => {
        fetchDistroLists(() => list.check('distro-lists'));
        fetchDepartments(() => list.check('departments'));
        fetchMessageHistory(msgEntities => {
          latestFetchedMessages = messages = msgEntities;
          list.check('messages');
        }, page);
      });
    }, ['distro-lists', 'departments', 'messages'])
    .add(() => {
      displayMsgHistory(messages);
      initListeners();

      if($('#row0').is(':visible')) {
        $('#row0').trigger('click') 
      }
      if (messages.length) {
        enableSearchBar();
      }
      if (typeof callback === 'function') {
        callback();
      }
    })
    .oncomplete(hideSpinner)
    .execute();
}

function formatMsgDate(date) {
  date = new Date(date);
  return date.toLocaleDateString('en-CA') + ' &ndash; ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

function renderStatusSection(json) {
  
  var lst = JSON.parse(json.value);

  let nbOfErrors = 0;
  let nbOfSuccess = 0;

  for (var x=0;x<lst.length;x++) {
    var obj = lst[x];
    if (obj.status == "bounce" || obj.status=="dropped" || obj.status=="deferred" ){
      nbOfErrors++;
    }

    if(obj.status == "click" || obj.status=="sending" || obj.status=="sent" || obj.status=="open" || obj.status=="delivered" || obj.status=="processed"){
      nbOfSuccess++
    }

  }

  document.getElementById('successTag1').innerHTML = nbOfSuccess + " SUCCESS";
  document.getElementById('failTag1').innerHTML = nbOfErrors + " FAIL";

}

function renderDistroList(item, itemNum) {
  // Clearing any previous display from search 
  document.getElementById("distro-history-content").innerHTML = '';
  copyOfMsgAttributes = [];
  item.map(msg => {
    var myMessage = new Object();  
    const distributionListId = getDistributionListIdParamter(msg.properties.distributionListId)
    var sentTo = (msg.properties.distributionListName || distributionListId || '').replace(/^\s*[-~]\s*$/, '');
    sentTo += (sentTo?',':'')+(msg.properties.departmentName || msg.properties.departmentId || '').replace(/^\s*[-~]\s*$/,'');
    sentTo = sentTo.replace(/^(\s*,*)*([^\s,]?((\s|\S)(?!((\s*,*)*$)))*[^,\s]?)(\s*,*)*$/,'$2')
      .replace(/(\s*,+\s*)+/g,', ').replace(/^\s*$/,'-');
   
    const formattedDateOrTime = getFormattedDateOrTime(msg.properties.dateSent);

    var subject = escapeHTML(msg.properties.subject);
    var sentToEmails = typeof msg.properties.emails === 'object'
      ? msg.properties.emails.value.replace(";", "; ")
      : (typeof msg.properties.emails === 'string' ? msg.properties.emails : '');

    var msgContent = msg.properties.message.value || '';
    var onlyText=msgContent.replace(/<[^>]+>/g, '');

    let msgStatus = parseInt(msg.properties.status);
    renderStatusSection(msg.properties.recipientListJSON);

    //Creating temp object with info needed to display message 
    myMessage.sentTo=sentTo;
    myMessage.sentDate = formattedDateOrTime;
    myMessage.subject = subject;
    myMessage.sentToEmails=sentToEmails;
    myMessage.msgContent = msgContent;
    myMessage.onlyText = onlyText;
    myMessage.msgStatus = msgStatus;
    myMessage.id = msg.properties.id;
    myMessage.itemNum = itemNum;
    myMessage.statusNb = msg.properties.recipientListJSON;
    myMessage.sentToEmails = sentToEmails;

    let sentDateId = Utility.uniqueID();
    var message = $("<div class='message-row unseen-msg sent-message" + "' id='row"+itemNum+"'></div>");
    message.attr('message-id', msg.key.name);
    let msgPreview = subject;
    let topRowContent = '<div class="unseen-msg-tab"></div>' + ("<div id='subRow"+itemNum+"' class='message-top-row'>"+
    "<div class='distro-info subject subject-smaller' id='mySubject' title='" + subject.replace(/'/g, "\"") + "'>" + msgPreview.toUpperCase() + "</div>" +
      "<div id='" + sentDateId + "' class='distro-info sent-date'>" + (formattedDateOrTime) + "</div>" +
    "<br><div class='email-content' ><div class='plain-text'>"+onlyText+"</div></div>" +
    "<br><div class='distro-info sent-to'>" + (sentTo.toUpperCase()) + "</div>");

    var msgBody = $("<div class='msg-body' id='msg"+itemNum+"'></div>");

    let uploadedFiles = [];
    try {
      uploadedFiles = JSON.parse(msg.properties.uploadedFilesJSON.value);
      myMessage.files = uploadedFiles;

    } catch(e) {
      console.warn('adding attachments (if any) in compatibility for: ' + msg.key.name);
      
    }

    topRowContent += '</div>';
    message.append(topRowContent);
    itemNum++;
    copyOfMsgAttributes.push(myMessage);
    message.append(msgBody);
    $("#distro-history-content").append(message);
  });

}

/**
 * Formats the dateTime that the email was sent at. The format is: '2023-03-05T18:54:43.556Z'
 * If we used the dateSent property the format would be an actual date object like 
 * @param {String} sentDate which is a string of a DateTime object
 * @returns String of the formatted date
 */
function getFormattedDateOrTime(sentDate) {
  const date = new Date(sentDate);
  const today = new Date();
  // Store the time before wiping the hours
  const time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
  // The date is today so show only the time
  if (date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
    return time;
    // Only show the date, not the time.
  } else if (date < today) {
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    });
  }

  // Should never get here
  return '';
}

function buildFileViewer(fileLink) {

  let overlay = document.getElementById('overlay');
  let viewBody = document.getElementById('viewerBody');
  let viewContainer = document.getElementById('fileContainer');
  let btn = document.getElementById('closeBtn')

  let fileIframe = document.createElement('iframe');
  fileIframe.setAttribute('src', fileLink);
  fileIframe.innerHTML = "Iframe not supported"; 

  viewContainer.classList.add('active');
  overlay.classList.add('active');

  viewBody.appendChild(fileIframe);

  btn.addEventListener('click', () =>{

    viewContainer.classList.remove('active');
    overlay.classList.remove('active');
    viewBody.removeChild(fileIframe);
  });

  overlay.addEventListener('click', ()=>{
    viewContainer.classList.remove('active');
    overlay.classList.remove('active');
    viewBody.removeChild(fileIframe);
  })
}

function copyRecipients(e) {
  e.preventDefault();
  e.stopImmediatePropagation();  
  swal({
    title: i18n.t("js.distro.hist.copy-recp"),
    text: i18n.t("js.distro.hist.copy.text"),
    type: 'question',
    showCancelButton: true,
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("js.distro.hist.copy.write"),
    cancelButtonText: i18n.t("js.distro.hist.copy.list"),
    showCloseButton: true,
    useRejections: true, //important for swal2 v7.1.2
    allowOutsideClick: true
  }).then(function() {
    copyOfMsgAttributes.forEach(msg => {
        if(msg.itemNum==rowNum){
            restoreMessage(msg.id, true, false);
        }
    })
   
  }, function(dismiss) {
    if(dismiss !== 'cancel') { return; } // dismiss = "cancel" when clicking "Save a List"
    swal({
      title: i18n.t("js.dislist.create.title"),
      input: 'text',
      inputPlaceholder: i18n.t("js.dislist.create.placeholder"),
      showConfirmButton: true,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("button.confirm"),
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      showLoaderOnConfirm: true,
      showCloseButton: true,
      animation: false,
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
      },      
      preConfirm: function(newListName) {
        return new Promise((resolve, reject) => {
          try {
            validateListName(newListName);
          } catch(e) {
            reject(e.message);
            return;
          }

          var tmp = new Object();
          tmp.distributionListName = newListName;
          tmp.msgId = copyOfMsgAttributes[rowNum].id

          saveSystemActivity({
            action: 'submit',
            params: findSystemActivityParams(tmp),
            message: 'User created a distribution list with members.'
          });

          apicall('distributionapi','addDistributionListFromMsg',tmp)
          .then(function(resp) {
            if(resp && resp.responseCode && resp.responseCode == '0') {
              resolve({status: 'success'});
            } else if(resp.responseCode === '-1') {
              resolve({status: 'warning', message: resp.responseMessage});
            } else if(resp.responseCode === '-2') {
              reject(resp.responseMessage);
            } else {
              reject(i18n.t("js.utils.connection.error"));
            }
          });

          function validateListName(listName) {
            listName = listName.trim();
            if(listName === '') {
              throw { message: i18n.t("js.dislist.reject.name")}
            }
            //important condition for functions calling querySelector with the lists' id
            else if(/^\d/.exec(listName)) {
              throw { message: i18n.t("js.distro.hist.reject.digit") }
            }
          }
        });
      }
    }).then(function(response) {
      if(response.status === 'success') {
        swal({
          title: i18n.t("response.success"),
          showConfirmButton: true,
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("OK"),
          showCancelButton: false,
          type: 'success',
          allowOutsideClick: true
        }).catch(swal.noop);
      } else if(response.status === 'warning') {        
        swal({
          title: i18n.t("js.warning"),
          text: i18n.t("js.distro.hist.copy.warning", {contacts: response.message}),
          showConfirmButton: true,
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("OK"),
          showCancelButton: false,
          type: 'warning',
          showCloseButton: true,          
        }).catch(swal.noop);
      }
    }).catch(swal.noop);
  }).catch(swal.noop);
}

function showMessageOverlay(num){
    
    document.getElementById("view-message1").style.backgroundColor = '#DEF7EE';
    document.getElementById("view-full-report1").style.backgroundColor = '#ffffff';
    document.getElementById("viewMessageButton").style.display = 'grid';
    document.getElementById('viewFullReportButton').style.display = 'none';
    
    function forwardBtn(e){
      e.stopImmediatePropagation();
      copyOfMsgAttributes.forEach(msg => {
        if (msg.itemNum == rowNum) {
          restoreMessage(msg.id, false);
        }
      });
    }

    copyOfMsgAttributes.forEach(msg => {
        if (msg.itemNum == num){
            document.getElementById('forward-button').onclick = function(){forwardBtn};
            document.getElementById('messageSubject').innerHTML= msg.subject;
            document.getElementById('messageSentTo').innerHTML = msg.sentTo;
            document.getElementById('messageContent').innerHTML = msg.msgContent;
            document.getElementById('messageFiles').innerHTML = '';
            renderStatusSection(msg.statusNb);

            //adding attachments from email
            msg.files.forEach(file =>{
              let container = document.createElement("div");
              container.className= "sent-file";
              let attachment = document.createElement('a');
              let fileIcon = document.createElement('i');
              fileIcon.className = 'icon-file';
              container.appendChild(fileIcon);
              attachment.className = 'distro-link file-image';
              attachment.textContent = file.fileName;
              attachment.download = file.fileName;
              attachment.onclick = async function(e) {
                e.stopImmediatePropagation();
        
                if(!file.id.toLowerCase().endsWith('pdf')){
                  DocUtility.downloadFile(file.id);
        
                }else{
                  showSpinner();
                  DocUtility.getFileURL(file.id, file.isWatermarked === '1', null).then(fetchedURL => {
                    let url = fetchedURL; 
                    buildFileViewer(url); 
                    hideSpinner();  
                  });          
                }
              }
              container.appendChild(attachment);
              document.getElementById("messageFiles").appendChild(container);
           });
           document.getElementById("view-full-report1").onclick = function(){showFullReportOverlay(msg.id)};
           document.getElementById("view-full-report2").onclick = function(){showFullReportOverlay(msg.id)};
        }
        
    })

}

function showFullReportOverlay(msgId){

  //highlighting chosen email
  copyOfMsgAttributes.forEach(msg => {
    if (msg.id == msgId){
      document.getElementById('row'+msg.itemNum).style.backgroundColor = '#DEF7EE';
    }
    else{
      document.getElementById('row'+msg.itemNum).style.backgroundColor = '#ffffff';
    }
  });

  $('.viewMessage').on('click', function (event) {
    document.getElementById('view-message1').style.backgroundColor = '#DEF7EE';
    document.getElementById('view-full-report1').style.backgroundColor = '#ffffff';
    console.log("did the view message work? " + msgId);
    copyOfMsgAttributes.forEach(msg => {
      if (msgId == msg.id) {
        showMessageOverlay(msg.itemNum);
      }
    });
  });
  
  document.getElementById('viewFullReportButton').style.display = "grid";
  document.getElementById('view-message2').style.backgroundColor = '#ffffff';
  document.getElementById('view-full-report2').style.backgroundColor = '#DEF7EE';
  document.getElementById('viewMessageButton').style.display = 'none';

  new TaskList({ passReference: true })
    .add(list => {
      showSpinner();
      generateTable(() => list.check('table'));
    }, ['table'])
    .oncomplete(hideSpinner).execute();

  function generateTable(done) {

    apicall('distributionapi', 'generateDistroReports', {msgId: msgId}).then(resp => {
      let results = [];
      results['Sent'] = resp.processed;
      results['Received'] = resp.delivered;
      results['Opened'] = resp.opened;
      results['Bounced'] = resp.bounced;
      results['Deferred'] = resp.deferred;
      results['Dropped'] = resp.dropped;
      
      let successNum = results['Sent'].length + results['Received'].length + results['Opened'].length;
      let failureNum = results['Bounced'].length + results['Deferred'].length + results['Dropped'].length;

      document.getElementById("successTag2").innerHTML = successNum + " SUCCESS";
      document.getElementById("failTag2").innerHTML = failureNum + " FAIL";

      let table_data = [];
      //We do this because processed contains 'sent' and 'processed' 
      //emails and certain status aren't constant with the other ones
      Object.keys(results).forEach(key => {
        const status_tled = i18n.t("js.distro.hist.status."+key.toLowerCase());
        results[key].forEach(r=>{
          r.status = key;//Required for colored circle
          r.status_tled = status_tled;//Required for search
        });
        table_data.push.apply(table_data, results[key]);
      });

      $('.search-bar input').on("keyup", (e) => {
        let value = e.target.value
        let regex = new RegExp(value, 'i')

        let names = document.querySelectorAll('.sorting_1')
        names.forEach(item => {
          if(!regex.test(item.textContent)) {
            item.parentNode.style.display = 'none'
          }
          else {
            item.parentNode.style.display = 'table-row'
          }

        })


      })

      var tableInstance = $("#reportsDataTable").DataTable({
        destroy: true,
        data: table_data,
        paging: false,
        scrollY: "65vh",
        scrollCollapse: true,
        columns: [
          { title: i18n.t("js.distro.hist.name"), data: 'name', name:'name' , width: '32%'},
          { title: i18n.t("js.distro.hist.email"), data: 'email', name: 'email' , width: '36%'},
          { title: i18n.t("js.distro.hist.status"), data: null, defaultContent: '', name:'icon', searchable:false, orderData: 3, width: '32%'},
          { title: 'Hidden', data:'status_tled', name:'status', visible:false}
        ],
        fixedHeader: {
          header: true
        },
        language: {
          search: '',
          emptyTable: i18n.t("js.distro.hist.emptyTable"),
          zeroRecords: i18n.t("js.distro.hist.zeroRecords"),
          searchPlaceholder: i18n.t("js.distro.hist.searchPlaceholder"),
          info: i18n.t("js.distro.hist.info"),
          infoEmpty: i18n.t("js.distro.hist.infoEmpty"),
          infoFiltered: i18n.t("js.distro.hist.infoFiltered"),
        },
        rowCallback: (row, data, index) => {
          $("td:eq(2)", row).addClass("fa fa-2x fa-circle reports-status-" + data['status']);
        }
      });

      const chartColors = [
          'rgba(131, 219, 86, 1)',
          'rgba(112, 189, 74, 1)',
          'rgba(0, 153, 76, 1)',
          'rgba(208, 113, 84, 1)',
          'rgba(195, 80, 45, 1)',
          'rgba(169, 47, 9, 1)'
      ];

      const statusArray = [
        "Sent", "Received", "Opened", "Bounced", "Deferred", "Dropped"
      ]

      var myChart;
      
      //clearing the previous donut chart
      document.getElementById('donutChart').innerHTML='';
      
      var chart = document.createElement('canvas');
      chart.id = 'reportsChart';
      
      // Needed for custom legend for chart 
      const getOrCreateLegendList = (chart, id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector('ul');

        if (!listContainer) {
          listContainer = document.createElement('ul');
          listContainer.style.display = 'flex';
          listContainer.style.flexDirection = 'column';
          listContainer.style.margin = 0;
          listContainer.style.padding = 0;

          legendContainer.appendChild(listContainer);
        }

        return listContainer;
      };

      //plugin for custom legend for chart 
      const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
          const ul = getOrCreateLegendList(chart, 'customLegend');

          while (ul.firstChild) {
            ul.firstChild.remove();
          }
          var sentObj = new Object();
          sentObj.text = "Sent: "+ results["Sent"].length;
          sentObj.color = "rgba(131, 219, 86, 1)";

          var recObj = new Object();
          recObj.text = "Received: "+ results["Received"].length;
          recObj.color = "rgba(112, 189, 74, 1)";

          var openObj = new Object();
          openObj.text = "Opened: "+ results["Opened"].length;
          openObj.color = "rgba(0, 153, 76, 1)";

          var bounceObj = new Object();
          bounceObj.text = "Bounced: "+ results["Bounced"].length;
          bounceObj.color = "rgba(208, 113, 84, 1)";


          var defObj = new Object();
          defObj.text = "Deferred: "+ results["Deferred"].length;
          defObj.color = "rgba(195, 80, 45, 1)";


          var dropObj = new Object();
          dropObj.text = "Dropped: "+ results["Dropped"].length;
          dropObj.color = "rgba(169, 47, 9, 1)";

          
          const items =[]
          items.push(sentObj);
          items.push(recObj);
          items.push(openObj);
          items.push(bounceObj);
          items.push(defObj);
          items.push(dropObj);

          items.forEach(item => {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.listStyle = "none";
            li.style.marginLeft = '10px';

            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.backgroundColor = item.color;
            boxSpan.style.borderColor = item.color;
            boxSpan.style.borderWidth = "5"+ 'px';
            boxSpan.style.display = 'inline-block';
            boxSpan.style.height = '20px';
            boxSpan.style.marginRight = '10px';
            boxSpan.style.width = '20px';
            boxSpan.style.borderRadius = '50%';

            const textContainer = document.createElement("div");
            const text = document.createTextNode(item.text);
            textContainer.appendChild(boxSpan);
            textContainer.appendChild(text);
            textContainer.style.fontWeight = "bold";
            textContainer.style.display = "inline-block";

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);

            // Enable each status to be clickable and show only emails of that status
            li.onclick = () => {
              var list = document.getElementById('legendList');
              var items = list.getElementsByTagName('li');
              for (var i = 0; i < items.length; ++i) {
                items[i].style.backgroundColor = "";
              }
              li.style.backgroundColor = "#DEF7EE";
              var status = li.textContent.split(":")[0];
              tableInstance.search(status).draw();

              // Show description title and appropriate description
              $("#email-status-description-title").text(i18n.t("js.distro.hist.status.description.title"));
              $("#email-status-description").text(i18n.t("js.distro.hist.status.desc." + status.toLowerCase()));
            };
          });
        }
      };
    

      myChart = new Chart(chart.getContext('2d'), {
        type: 'doughnut',
        data: {
            datasets: [{
                data: Object.values(results).map((l)=>{return l.length}),
                backgroundColor: chartColors,
                borderColor: chartColors,
                borderWidth: 1
            }]
        },
        options: {
          onClick: (evt)=>{
            var point = myChart.getElementAtEvent(evt)[0];
            if(point){
              let key = statusArray[point._index];
              tableInstance.search(key).draw();
            }
          },
          onHover: (evt, elem) =>{evt.target.style.cursor = elem[0] ? 'pointer' : 'default';},
          tooltips: {
            bodyFontSize: 16,
            callbacks: {
              label: function(tooltipItem, data) {
                let index = tooltipItem.index;
                let label = data.labels[index] || '';
                let nbEmails = data.datasets[tooltipItem.datasetIndex].data[index];
                return label + ' : ' + nbEmails + '/' + table_data.length;
              }
            }
          },
          plugins: {
            htmlLegend: {
              containerID: 'customLegend',
            },
            legend: {
              display: false,
            }
          }
        },
        plugins: [htmlLegendPlugin],
      });
     
      document.getElementById('donutChart').appendChild(chart);
      
      done();
      

    }).catch(err => {
      console.error(err);
      swalToast({
        type: 'error',
        title: i18n.t("js.distro.hist.err.fetch")
      });
      
    });
    
  
  }
 
}
