import i18n from './Translation';
import moment from 'moment';
import Selectables from './Selectables.js';
import DownloadNotification from './notifications/DownloadNotification.js';
import '../../assets/js/jquery.inputmask.bundle.js';
import Litepicker from 'litepicker';
import swal from 'sweetalert2';
import DocUtility from './DocUtility';

export default function loadDailyTimeReportPrompt(allDepartments, userDepartmentId) {
	let securityListId = croogloo_auth.crooglooauth.securityListId;

	let submitTimesBtn = document.getElementById('submitTimesBtn');
	let submitWeeklyBtn = document.getElementById('submitWeeklyBtn');
  let isUserAnAdmin = securityListId === 'ADMIN';

  let activeDTRDate = new Date();

	if(isUserAnAdmin) {
		submitTimesBtn.style.display = 'none';
		submitWeeklyBtn.style.display = 'none';
		document.getElementById('dtrmenu_exportDtrBtn').onclick = function() {
			swal({
				title: i18n.t("dtr.times.export"),
				text: i18n.t("js.dtr.excel"),
				type: 'info',
				confirmButtonText: i18n.t("button.confirm"),
        showCloseButton: true,
				showCancelButton: true,        
        cancelButtonText: i18n.t("button.cancel"),
				showLoaderOnConfirm: true,
        allowOutsideClick: () => !swal.isLoading(),       
				preConfirm: function() {
					return new Promise((resolve, reject) => {
						apicall('personapi', 'exportDtrToExcel', {
              targetReportTime: activeDTRDate.getTime()
            }).then(resp => {
							if(resp.responseCode === '0') {
								DownloadNotification.add(false, false, resp.responseMessage.split(",")[0],
									resp.responseMessage.split(",")[1] || null, new Date().getTime());
								resolve();
							} else {
								throw new Error('server error');
							}
						}).catch(() => {
							reject(i18n.t("js.utils.something.wrong"));
						});
					});
				}
			}).then(() => {
				swal({
					title: i18n.t("response.success"),
					html: i18n.t("js.dtr.submitted"),
					type: 'success',
          allowOutsideClick: true
				}).catch(swal.noop);
			}).catch(swal.noop);
		}
	} else {
		allDepartments = [allDepartments.find(d => d.key.name == userDepartmentId)];
  }
  // Always disable Action button with Excel export as this feature is broken.
  // Always display and use submit times single button
  submitTimesBtn.style.display = 'inline';
  submitWeeklyBtn.style.display = 'inline';
  submitTimesBtn.onclick = submitTimes;
  submitWeeklyBtn.onclick = submitWeeklyTimes;
  document.getElementById('dtrmenu_submitTimesBtn').onclick = submitTimes;
	document.getElementById('dtrmenu_submitWeeklyBtn').onclick = submitWeeklyTimes;

	function submitTimes() {
    console.log(activeDTRDate.getTime());
    let minMaxTime = getDailyMinMaxTime();
    let minLogTime = minMaxTime[0];
    let maxLogTime = minMaxTime[1];
    console.log(getDailyMinMaxTime());
    showSpinner();
    apicall('personapi', 'fetchDepartmentMembersTitle', {
      departmentId: document.getElementById('dtrDepartmentDropdown').value,
      minTime: minLogTime,
      maxTime: maxLogTime
    }).then(resp => {
      console.debug(resp);
      let members = resp.items;
      members.forEach(member => member[2] = (member[2] == 'true' || member[2] == true));
      members = members.filter(m => m[2]);
      let departmentMemberIds = []
      members.forEach(m => departmentMemberIds.push(m[0]));
      console.debug("Fetching DTRs for the members: ", departmentMemberIds);
      apicall('personapi', 'fetchDailyTimeLogs', {
        minTime: minLogTime,
        maxTime: maxLogTime
      }, {
        value: departmentMemberIds.join(",")
      }).then(resp2 => {
        hideSpinner();
        console.debug(resp2);
        openSwalWithReview(members, resp2);
      }).catch(() => {
        hideSpinner();
        console.error('failed to fetch time logs for department members');
        openSwalWithNoReview();
      });
    }).catch(() => {
      hideSpinner();
      console.error('failed fetch department members for DTR');
      openSwalWithNoReview();
    });

    function openSwalWithReview(members, dailyTimeLogs) {
      let tableContainer = document.createElement("div");
      tableContainer.style.overflow = "auto";
      tableContainer.style.maxHeight = "50vh";
      let table = document.createElement("table");
      table.style.borderCollapse = "separate";
      tableContainer.appendChild(table);
      let headerRow = document.createElement("tr");
      headerRow.className = "simple-sticky-header";
      let nameHeader = buildHeader(headerRow, i18n.t("utils.name"), "name-review-column");
      let callTimeHeader = buildHeader(headerRow, i18n.t("dtr.fields.callTime"), "call-time-review-column");
      let mealStartHeader = buildHeader(headerRow, i18n.t("dtr.fields.1stMealStart"), "meal-start-time-review-column");
      let mealFinishHeader = buildHeader(headerRow, i18n.t("dtr.fields.1stMealFinish"), "meal-end-time-review-column");
      let wrapHeader = buildHeader(headerRow, i18n.t("dtr.fields.wrapTime"), "wrap-time-review-column");
      let travelStartHeader = buildHeader(headerRow, i18n.t("dtr.fields.travelStart"), "travel-start-time-review-column");
      let travelEndHeader = buildHeader(headerRow, i18n.t("dtr.fields.travelFinish"), "travel-end-time-review-column");
      let secondMealStartHeader = buildHeader(headerRow, i18n.t("dtr.fields.2ndMealStart"), "second-meal-start-time-review-column");
      let secondMealEndHeader = buildHeader(headerRow, i18n.t("dtr.fields.2ndMealFinish"), "second-meal-end-time-review-column");
      let kitHeader = buildHeader(headerRow, i18n.t("dtr.fields.kit"), "kit-review-column");
      let upgradeHeader = buildHeader(headerRow, i18n.t("dtr.fields.upgrade"), "upgrade-review-column");
      table.appendChild(headerRow);
      
      let i = 0;
      let isTravelStartEntered = false;
      let isTravelEndEntered = false;
      let isSecondMealStartEntered = false;
      let isSecondMealEndEntered = false;
      let isKitEntered = false;
      let isUpgradeEntered = false;

      for (const p of members.map(dmt => dmt[0])) {
        i++;
        let row = document.createElement("tr");
        row.style.backgroundColor = i % 2 == 0? '#F0F0F0':'#F8F8F8';
        let name = members.filter(m => m[0] == p)[0][1];
        let callTime = dailyTimeLogs.props[p]["call_time"] ? dailyTimeLogs.props[p]["call_time"].value : null;
        let mealStart = dailyTimeLogs.props[p]["first_meal_start_time"] ? dailyTimeLogs.props[p]["first_meal_start_time"].value : null;
        let mealEnd = dailyTimeLogs.props[p]["first_meal_end_time"] ? dailyTimeLogs.props[p]["first_meal_end_time"].value : null;
        let wrapTime = dailyTimeLogs.props[p]["wrap_time"] ? dailyTimeLogs.props[p]["wrap_time"].value : null;
        let travelStartTime = dailyTimeLogs.props[p]["travel_start_time"] ? dailyTimeLogs.props[p]["travel_start_time"].value : null;
        let travelEndTime = dailyTimeLogs.props[p]["travel_end_time"] ? dailyTimeLogs.props[p]["travel_end_time"].value : null;
        let secondMealStartTime = dailyTimeLogs.props[p]["second_meal_start_time"] ? dailyTimeLogs.props[p]["second_meal_start_time"].value : null;
        let secondMealEndTime = dailyTimeLogs.props[p]["second_meal_end_time"] ? dailyTimeLogs.props[p]["second_meal_end_time"].value : null;
        let kit = dailyTimeLogs.props[p]["kit_time"] ? dailyTimeLogs.props[p]["kit_time"].value : null;
        let upgrade = dailyTimeLogs.props[p]["upgrade_time"] ? dailyTimeLogs.props[p]["upgrade_time"].value : null;
        buildColumn(row, name, "name-review-column", false);
        buildColumn(row, callTime, "call-time-review-column", (dailyTimeLogs.props[p]["call_time"] ? dailyTimeLogs.props[p]["call_time"].data_type === 'time' : false)) // true);
        buildColumn(row, mealStart, "meal-start-time-review-column", (dailyTimeLogs.props[p]["first_meal_start_time"] ? dailyTimeLogs.props[p]["first_meal_start_time"].data_type === 'time' : false)) // true);
        buildColumn(row, mealEnd, "meal-end-time-review-column", (dailyTimeLogs.props[p]["first_meal_end_time"] ? dailyTimeLogs.props[p]["first_meal_end_time"].data_type === 'time' : false)) // true);
        buildColumn(row, wrapTime, "wrap-time-review-column", (dailyTimeLogs.props[p]["wrap_time"] ? dailyTimeLogs.props[p]["wrap_time"].data_type === 'time' : false)) // true);
        if (buildColumn(row, travelStartTime, "travel-start-time-review-column", (dailyTimeLogs.props[p]["travel_start_time"] ? dailyTimeLogs.props[p]["travel_start_time"].data_type === 'time' : false))) { // true)) {
          isTravelStartEntered = true;
        }
        if (buildColumn(row, travelEndTime, "travel-end-time-review-column", (dailyTimeLogs.props[p]["travel_end_time"] ? dailyTimeLogs.props[p]["travel_end_time"].data_type === 'time' : false))) { // true)) {
          isTravelEndEntered = true;
        }
        if (buildColumn(row, secondMealStartTime, "second-meal-start-time-review-column", (dailyTimeLogs.props[p]["second_meal_start_time"] ? dailyTimeLogs.props[p]["second_meal_start_time"].data_type === 'time' : false))) { // true)) {
          isSecondMealStartEntered = true;
        }
        if (buildColumn(row, secondMealEndTime, "second-meal-end-time-review-column", (dailyTimeLogs.props[p]["second_meal_end_time"] ? dailyTimeLogs.props[p]["second_meal_end_time"].data_type === 'time' : false))) { // true)) {
          isSecondMealEndEntered = true;
        }
        if (buildColumn(row, kit, "kit-review-column", (dailyTimeLogs.props[p]["kit_time"] ? dailyTimeLogs.props[p]["kit_time"].data_type === 'time' : false))) { // false)) {
          isKitEntered = true;
        }
        if (buildColumn(row, upgrade, "upgrade-review-column", (dailyTimeLogs.props[p]["upgrade_time"] ? dailyTimeLogs.props[p]["upgrade_time"].data_type === 'time' : false))) { // false)) {
          isUpgradeEntered = true;
        }
        table.appendChild(row);
      }
      setColumnsVisibility(table, isTravelStartEntered, "travel-start-time-review-column");
      setColumnsVisibility(table, isTravelEndEntered, "travel-end-time-review-column");
      setColumnsVisibility(table, isSecondMealStartEntered, "second-meal-start-time-review-column");
      setColumnsVisibility(table, isSecondMealEndEntered, "second-meal-end-time-review-column");
      setColumnsVisibility(table, isKitEntered, "kit-review-column");
      setColumnsVisibility(table, isUpgradeEntered, "upgrade-review-column");    
 
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      swal({
        customContainerClass: 'submitDailySwalContainer',
        html: tableContainer.outerHTML,
        showCancelButton: true,
        confirmButtonText: i18n.t("js.dtr.daily.submit"),
        cancelButtonText: i18n.t("js.dtr.daily.edit"),
        showLoaderOnConfirm: true,
        showCloseButton: true,
        allowOutsideClick: () => !swal.isLoading(),    
        preConfirm: function() {
          return new Promise((resolve, reject) => {
            apicall('personapi', 'submitDailyTimeReport', {
              departmentId: document.getElementById('dtrDepartmentDropdown').value,
              targetReportTime: activeDTRDate.getTime(),
              timezone: timezone,
            }).then(resp => {
              if(resp.responseCode === '0') {
                /**
                 * Clearing stored files to make sure the folder containing the DTR (for admins)
                 * is cleared, since the document ID will have changed
                */
                DocUtility.clearStoredFiles();
                resolve();
              } else {
                throw new Error('invalid server response');
              }
            }).catch(() => {
              console.error('failed to submit times');
              reject(i18n.t("js.utils.server.error"));
            });
          });
        }
      }).then(function() {
        swal({
          title: i18n.t("response.success"),
          html: i18n.t("js.dtr.submit.daily.success"),
          type: 'success',
          allowOutsideClick: true,
        }).catch(swal.noop);
      }).catch(swal.noop);
    }

    function buildHeader(headerRow, label, className) {
      let headerColumn = document.createElement("th");
      headerColumn.textContent = label;
      headerColumn.classList.add(className);
      headerRow.appendChild(headerColumn);
      return headerColumn;
    }

    function buildColumn(row, value, className, forceHourFormat) {
      let isColumnSet = false;
      let column = document.createElement("td");
      column.classList.add(className);
      row.appendChild(column);
      if (value && (value != "-1" || value != -1)) {
        isColumnSet = true;
        if (forceHourFormat) {
          column.textContent = moment(parseInt(value)).format("HH:mm");
        } else {
          column.textContent = value;
        }
      } else {
        column.textContent = "";
      }
      return isColumnSet;
    }

    function setColumnsVisibility(table, isEntered, className) {
      if (!isEntered) {
        let cols = table.querySelectorAll("." + className);
        for (let i = 0; i < cols.length; i++) {
          cols[i].style.display = "none";
        }
      }
    }

    function openSwalWithNoReview() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      swal({
        title: i18n.t("js.dtr.daily.title"),
        html: i18n.t("js.dtr.daily.text"),
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        confirmButtonText: i18n.t("button.submit"),
        type: 'info',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !swal.isLoading(),
        showCloseButton: true,
        preConfirm: function() {
          return new Promise((resolve, reject) => {
            apicall('personapi', 'submitDailyTimeReport', {
              departmentId: document.getElementById('dtrDepartmentDropdown').value,
              targetReportTime: activeDTRDate.getTime(),
              timezone: timezone,
            }).then(resp => {
              if(resp.responseCode === '0') {
                resolve();
              } else {
                throw new Error('invalid server response');
              }
            }).catch(() => {
              console.error('failed to submit times');
              reject(i18n.t("js.utils.server.error"));
            });
          });
        }
      }).then(function() {
        swal({
          title: i18n.t("response.success"),
          html: i18n.t("js.dtr.submit.daily.success"),
          type: 'success',
          allowOutsideClick: true,
        }).catch(swal.noop);
      }).catch(swal.noop);
    }
	}

  async function submitWeeklyTimes() {
    const departmentSelect = document.getElementById('dtrDepartmentDropdown');
    const departmentName = departmentSelect.querySelector(`option[value="${departmentSelect.value}"]`).textContent;

    let targetWeeklyReportTime;

    if (window.wtrDatePicker) {
      // important to prevent bug where you can no longer select a date range after cancelling swal
      window.wtrDatePicker.destroy();
    }

    swal.mixin({
      title: i18n.t("js.dtr.weekly.title"),
      confirmButtonText: i18n.t("button.next")+' &rarr;',
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      progressSteps: ['1', '2'],
      useRejections: false,
      expectRejections: true,
      showLoaderOnConfirm: true,    
      allowOutsideClick: () => !swal.isLoading(),
      showCloseButton: true,   
      onBeforeOpen: () => {
        const $swalTitle = $('#swal2-title');
        $swalTitle.css('margin-bottom', '0')
        $(`<div style="margin-bottom: 1rem">${i18n.t("utils.department")}:&nbsp;<b>${departmentName}</b></div>`).insertAfter($swalTitle);
      }
    }).queue([
      {
        html: '<div>'+i18n.t("js.dtr.weekly.input.label")+'</div><br><input id="weeklyReportDatePicker" placeholder="'+i18n.t("js.dtr.weekly.input.placeholder")+'" />',   
        onOpen: () => {
          let expectEndDate = false;
          window.wtrDatePicker = new Litepicker({
            element: document.getElementById('weeklyReportDatePicker'),
            singleMode: false,
            showTooltip: false,
            firstDay: 0,
            lang: i18n.language,
            buttonText: {"apply": i18n.t("utils.apply"), "cancel": i18n.t("button.cancel")},
            autoApply: false,
            setup: (picker) => {
              picker.on('before:click', (target) => {
                if (!target.classList.contains('day-item')) {
                  // the clicked item is not a day on the calendar
                  return;
                }
  
                const time = parseInt(target.getAttribute('data-time'));
                const date = new Date(time);
                const dayOfWeek = date.getDay();
                const isSunday = dayOfWeek == 0;
                const isSaturday = dayOfWeek == 6;
  
                const oneDayMillis = 24 * 3600 * 1000;
                const closestSundayBefore = time - dayOfWeek * oneDayMillis;
                const closestSaturdayAfter = closestSundayBefore + 6 * oneDayMillis;
                targetWeeklyReportTime = closestSundayBefore;
  
                if (isSunday && !expectEndDate) {
                  setTimeout(() => {
                    expectEndDate = true;
                    let closestSaturdayAfterElem = document.querySelector(`div.day-item[data-time="${closestSaturdayAfter}"]`);
  
                    if(!closestSaturdayAfterElem) {
                      document.querySelector('button.button-next-month').click();
                      setTimeout(() => {
                        closestSaturdayAfterElem = document.querySelector(`div.day-item[data-time="${closestSaturdayAfter}"]`);
                        closestSaturdayAfterElem && closestSaturdayAfterElem.click();
                      }, 0);
                    } else {
                      closestSaturdayAfterElem.click();
                    }

                  }, 0);
                } else if (!(isSaturday && expectEndDate)) {
                  picker.preventClick = true;
                  setTimeout(() => {
                    let closestSundayBeforeElem = document.querySelector(`div.day-item[data-time="${closestSundayBefore}"]`);
                    
                    if(!closestSundayBeforeElem) {
                      document.querySelector('button.button-previous-month').click();
                      setTimeout(() => {
                        closestSundayBeforeElem = document.querySelector(`div.day-item[data-time="${closestSundayBefore}"]`);
                        closestSundayBeforeElem && closestSundayBeforeElem.click();
                      }, 0);
                    } else {
                      closestSundayBeforeElem.click();
                    }

                  }, 0);
                } else {
                  expectEndDate = false;
                }
              });
            },
          })
        },
        preConfirm: () => {
          return new Promise((resolve, reject) => {
            if (!targetWeeklyReportTime || targetWeeklyReportTime > new Date().getTime()) {
              reject(i18n.t("js.dtr.weekly.reject.past"));
            } else {
              resolve(targetWeeklyReportTime);
            }
          });
        }
      }, {
        html: i18n.t("js.dtr.weekly.format.ask"),
        input: 'radio',
        inputOptions: {
          EP: i18n.t("js.dtr.weekly.format.ep"),
          CAST_CREW: i18n.t("js.dtr.weekly.format.cc")
        },
        animation: false, 
        allowOutsideClick: false,
        onClose: () =>{
            $('.swal2-popup').removeClass('swal2-noanimation');
            $('.swal2-popup').addClass('swal2-hide');
        },
        confirmButtonText: i18n.t("js.dtr.weekly.submit"),
        preConfirm: reportType => {
          return new Promise((resolve, reject) => {
            if (!reportType) {
              reject(i18n.t("js.dtr.weekly.reject.format"));
            } else {
              const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              apicall('personapi', 'submitWeeklyTimeReport', {
                departmentId: departmentSelect.value,
                reportType,
                targetReportTime: targetWeeklyReportTime,
                timeZone
              }).then(resp => {
                if(resp.responseCode === '0') {
                  /**
                   * Clearing stored files to make sure the eventual folder containing the WTR (for admins)
                   * is cleared, since the document ID will have changed. This feature has only 
                   * been implemented for DTRs so far, so we're only clearing stored files preemptively.
                  */
                  DocUtility.clearStoredFiles();
                  resolve();
                } else {
                  throw new Error('invalid server response');
                }
              }).catch(_ => {
                console.error('failed to submit weekly report');
                reject(i18n.t("js.utils.server.error"));
              });
            }
          });
        }
      }
    ]).then(results => {
      if (!('dismiss' in results)) {
        swal({
          title: i18n.t("response.success"),
          type: 'success',
          html: i18n.t("js.dtr.submit.weekly.success"),
          allowOutsideClick: true,
        }).catch(swal.noop);
      }
    }).catch(swal.noop);    
  }

  let nbOfSavingsRequests = 0;

  function alterNbOfSavingRequests(delta) {
    nbOfSavingsRequests += delta;
    let savingStatusElem = document.getElementById('dtrSavingStatus');
    if(nbOfSavingsRequests > 0) {
      savingStatusElem.firstElementChild.className = "hidden";
      savingStatusElem.lastElementChild.className = "";
    } else {
      savingStatusElem.firstElementChild.className = "";
      savingStatusElem.lastElementChild.className = "hidden";
    }
  }

  // fill in departments
  let departmentSelect = document.getElementById('dtrDepartmentDropdown');
  let sortedDepartments = allDepartments
    .sort((a, b) => a.properties.departmentName.localeCompare(b.properties.departmentName));
  for(let department of sortedDepartments) {
    let deptOption = document.createElement('option');
    deptOption.value = department.key.name;
    deptOption.textContent = department.properties.departmentName;
    departmentSelect.appendChild(deptOption);
  }
  departmentSelect.value = userDepartmentId;

  departmentSelect.onchange = refreshDTRContent;

  document.getElementById('dtrActiveDateLeftArrow').onclick = () => updateActiveDate(-1);
  document.getElementById('dtrActiveDateRightArrow').onclick = () => updateActiveDate(1);

  document.getElementById('dtrDailyCallOpt').onchange = () => updateDailyWeeklyStatusFromRadiosBtns('daily');
  document.getElementById('dtrWeeklyCallOpt').onchange = () => updateDailyWeeklyStatusFromRadiosBtns('weekly');
	document.getElementById('dtrNotInCallOpt').onchange = () => updateDailyWeeklyStatusFromRadiosBtns('not-in');
  document.getElementById('dtrRightArrow').onclick = function() { changeDeptMemberSelection(this.getAttribute('target-member-id')) };
  document.getElementById('dtrLeftArrow').onclick = function() { changeDeptMemberSelection(this.getAttribute('target-member-id')) };
  document.getElementById('dtrApplyAllBtn').onclick = applyListViewEventsToAllDeptMembers;
  document.getElementById('dtrApplyNextBtn').onclick = applyEventsToNextMember;
	document.getElementById('deleteDtrMemberIcon').onclick = showDeptMemberRemovalForm;

  document.getElementById('dtrViewControllerBtn').onclick = toggleGridView;

	let hideOptionalFieldsCheckbox = document.getElementById('dtrHideOptionalFields');
	hideOptionalFieldsCheckbox.onchange = function() {
		hideOptionalFields();
  }
  
	function hideOptionalFields() {
		if (hideOptionalFieldsCheckbox.checked) {
			$('.optional-field').hide();
		} else {
			$('.optional-field').show();
		}
	}

  // Configuring new member buttons for both list and grid view.
  // It's safer to use two different versions of the button for UI and listener
  // persistence, and should be just as maintainable given the current limited
  // configuration requirements.
  for(let addDeptMemberBtn of document.querySelectorAll('#dtrAddDeptMemberGridViewBtn, #dtrAddDeptMemberNestedBtn')) {
    addDeptMemberBtn.onclick = function (event) {
      showAddDeptMemberForm();
    };
  }

  let manageIncludedMembersBtn = document.getElementById('manageDtrDeptMembers');
  let orderIncludedMembersBtn = document.getElementById('orderDtrDeptMembers');

  orderIncludedMembersBtn.addEventListener('click', (evt)=>{
    let minMaxTime = getDailyMinMaxTime();
    apicall('personapi', 'fetchDepartmentMembersTitle',{
      departmentId: document.getElementById('dtrDepartmentDropdown').value,
      minTime: minMaxTime[0], 
      maxTime: minMaxTime[1]
    }).then(resp=>{
      let data = resp.items
        .filter(item=>{return item[2]=='true'})
        .map(item=>{return {person_id:item[0], name:item[1]}});
      renderSortingSwal(data);
    })
  });

  function renderSortingSwal(data){
    let htmlContent = render();
    swal({
      title: i18n.t("js.dtr.ordering.title"),
      customContainerClass: 'swal-container-orderMembers',
      html: htmlContent,
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      confirmButtonText: i18n.t("utils.apply"),
      allowOutsideClick: false,
      showCloseButton: true,
    }).then(applyChanges).catch(swal.noop);

    function applyChanges(){
      let personProps = {};
      let order = 1;

      for(let memberRow of htmlContent.querySelectorAll('div.dtr-ordering-row')){
        let personId = memberRow.getAttribute('person_id');
        personProps[personId] = order++;
      }
      
      alterNbOfSavingRequests(1);    
      apicall('personapi', 'overwriteDTRPersonOrdering', {
        departmentId: document.getElementById('dtrDepartmentDropdown').value
      },{
        props: personProps
      }).then(resp => {
        if(resp.responseCode !== '0') {
          throw new Error('invalid server response');
        }
        cgToast(i18n.t("js.dtr.ordering.success"), { className: 'success-toast' });
        refreshDTRContent();
      }).catch(() => {
        console.error('server error - overwriteDTRPersonOrdering failed');
        cgToast(i18n.t("js.dtr.ordering.failed"), { className: 'error-toast' });
      }).then(function() {
        alterNbOfSavingRequests(-1);
      });
    }

    function render(){
      let container = document.createElement('div');
      container.className = 'dtr-ordering-container';
      data.forEach(member=>{
        let row = document.createElement('div');
        row.className = 'dtr-ordering-row';
        row.setAttribute('person_id', member['person_id'])
        row.appendChild(renderArrow('up', (evt) => $(row).insertBefore($(row).prev())));
        row.appendChild(renderName(member['name']))
        row.appendChild(renderArrow('down', (evt) => $(row).insertAfter($(row).next())));
        container.appendChild(row);
      });
      return container;
    }
    function renderName(name){
      let span = document.createElement('span');
      span.textContent = name;
      return span;
    }
    function renderArrow(direction, listener){
      let arrow = document.createElement('i');
      arrow.className = `fa fa-arrow-${direction} order-arrow-${direction}`;
      arrow.addEventListener('click', listener);
      return arrow;
    }
  }

  let includedDeptMemberClass = 'active';
  let deptMemberSelectable = new Selectables({
    zone: '#dtrIncludeMemberContainer',
    elements: 'div.dtr-include-member-opt',
    selectedClass: includedDeptMemberClass,
    alwaysMoreUsing: true,
    onSelect: function(element) {
      element.querySelector('input[type=checkbox]').checked = true;
    },
    onDeselect: function(element) {
      element.querySelector('input[type=checkbox]').checked = false;
    }
  });

  $(document).off('hide.zf.dropdown');
  $(document).on('hide.zf.dropdown', function(event, $item) {
    if($item[0].id === 'dtr-dept-users-dropdown') {
      // reset the checkboxes to their initial values, or those that have just been applied
      if(Array.isArray(deptMemberSelectable.initialDeptMembers)) {
        for(let member of deptMemberSelectable.initialDeptMembers) {
          try {
            let memberCheckbox = getCorrespondingInclusionCheckbox(member[0]);
            memberCheckbox.checked = member[2] === true;
          } catch(err1) {
            console.error(err1);
          }
        }
      }
    }
  });

  document.getElementById('dtrIncludeMemberApplyBtn').onclick = function() {
    let changedMemberInclusion = {};
    for(let member of deptMemberSelectable.initialDeptMembers) {
      let memberCheckbox = getCorrespondingInclusionCheckbox(member[0]);
      changedMemberInclusion[member[0]] = member[2] = memberCheckbox.checked;
    }
    $('#dtr-dept-users-dropdown').foundation('close');
    alterNbOfSavingRequests(1);
    manageIncludedMembersBtn.style.display = 'none';
    orderIncludedMembersBtn.style.display = 'none';
    let minMaxTime = getDailyMinMaxTime();
    // We must save all members even if there's no difference, because it's the
    // only way to be 100% sure that the applied configuration becomes the
    // default one with the current related API methods' process.
    apicall('personapi', 'updateDtrInclusions', {
      minTime: minMaxTime[0],
      maxTime: minMaxTime[1],
      targetReportTime: activeDTRDate.getTime(),
      props: changedMemberInclusion
    }).then(resp => {
      if(resp.responseCode !== '0') {
        throw new Error('invalid server response');
      }
    }).catch(() => {
      console.error('server error - updateDtrInclusions failed');
      cgToast(i18n.t("js.dtr.inclusion.failure"), { className: 'error-toast' });
    }).then(function() {
      alterNbOfSavingRequests(-1);
      refreshDTRContent();
    });
  };

  function getCorrespondingInclusionCheckbox(personId) {
    return document.querySelector(`${deptMemberSelectable.options.zone} `
      + `${deptMemberSelectable.options.elements} > input[person_id="${personId}"]`);
  }

  let latestDeptChangeTime = null;
  updateActiveDate(0);

  function updateMemberManagementBox(sortedDeptMembers) {
    deptMemberSelectable.disable(); // must disable before removing the content, and re-enable it after
                                    // or there will be issues with listeners
    let dtrIncludeMemberContainer = document.getElementById('dtrIncludeMemberContainer');
    $(dtrIncludeMemberContainer).empty();
    for(let member of sortedDeptMembers) {
      createDtrMemberIncludeOption(member);
    }
    deptMemberSelectable.enable();
    deptMemberSelectable.initialDeptMembers = [].concat(sortedDeptMembers);

    document.getElementById('manageDtrDeptMembers').style.display = sortedDeptMembers.length ?'inline-block' :'none';
    document.getElementById('orderDtrDeptMembers').style.display = sortedDeptMembers.length ?'inline-block' :'none';

    if(!sortedDeptMembers.length) {
      $('#dtr-dept-users-dropdown').foundation('close');
    }

    function createDtrMemberIncludeOption(member) {
      let row = document.createElement('div');
      row.className = "manageMemberRow";
      let memberDiv = document.createElement('div');
      memberDiv.className = 'dtr-include-member-opt no-text-select';
      let isIncludedCheckbox = document.createElement('input');
      isIncludedCheckbox.setAttribute('person_id', member[0]);
      isIncludedCheckbox.setAttribute('type', 'checkbox');
      memberDiv.appendChild(isIncludedCheckbox);
      memberDiv.appendChild(document.createTextNode(member[1]));
      row.appendChild(memberDiv);
      if(!member[0].startsWith('PERSON')){
        let buttonDiv = document.createElement('div');
        buttonDiv.className = "";
        let trashIcon = document.createElement('i');
        trashIcon.className = 'fa fa-trash';
        trashIcon.onclick = (evt) => {
          swal({
            title: i18n.t("js.dtr.delete.title"),
            html: i18n.t("js.dtr.delete.text", {deptMemberTitle: member[1]}),
            showCancelButton: true,
            cancelButtonText: i18n.t("button.cancel"),
            confirmButtonText: i18n.t("utils.delete"),
            confirmButtonColor: '#ff4136',
            type: 'warning',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
            showCloseButton: true,
            preConfirm: function() {
              return new Promise((resolve, reject) => {
                apicall('personapi', 'removeTimeReportPerson', {}, {
                  value: member[0]
                }).then(resp => {
                  if(resp.responseCode === '0') {
                    resolve();
                  } else {
                    throw new Error('invalid server response');
                  }
                }).catch(() => {
                  console.error('failed to remove time report person');
                  reject(i18n.t("js.utils.server.error"));
                });
              });
            }
          }).then(function() {
            swal({
              title: i18n.t("response.success"),
              html: i18n.t("js.dtr.delete.success", {deptMemberTitle: member[1]}),
              type: 'success',
              allowOutsideClick: true,
            }).catch(swal.noop);
            refreshDTRContent();
          }).catch(swal.noop);
        }
        buttonDiv.appendChild(trashIcon);
        row.appendChild(buttonDiv);
      }
      dtrIncludeMemberContainer.appendChild(row);

      if(member[2] === true) { // must be done after appending
        isIncludedCheckbox.checked = true;
        memberDiv.classList.add(includedDeptMemberClass);
      }
    }
  }

  let dtrGridClipboard = null;

  function onDtrCopyRow(event, memberTimeRow, member) {
    dtrGridClipboard = {};
    for(let elem of memberTimeRow.querySelectorAll('select, input')) {
      dtrGridClipboard[elem.getAttribute('name')] = elem.value;
    }
    console.debug('dtrGridClipboard is now: ', dtrGridClipboard);
    swalToast({
      type: 'info',
      title: `${i18n.t("js.dtr.copied", {member: member[1]})}&nbsp;<a class="dtr-row-apply-all">${i18n.t("dtr.apply.all.text")}</a>`,
      onOpen: function() {
        document.querySelector('a.dtr-row-apply-all').onclick = function() {
          applyAllCopiedData();
          swal.close();
        };
      }
    });
  }

  /**
   * Event handler for moving up people in a department grid.
   * The person's new position will be saved to the datastore
   * as a user preference.
   * 
   * @param {HTMLElement} memberTimeRow The row to move up.
   */
  function onOrderUp(memberTimeRow) {
    if (!memberTimeRow.previousSibling || 
        !memberTimeRow.previousSibling.classList || 
        !memberTimeRow.previousSibling.classList.contains('grid-member-time-row') || 
        memberTimeRow.previousSibling.id == 'dtrGridTimeRowBP') {
      console.debug('can no longer move up');
      return;
    }
    //let currentRowIndex = $(memberTimeRow).index() - 2;
    let previous = $(memberTimeRow).prev();
    /*previous.find('input[type="number"').val(currentRowIndex);
    $(memberTimeRow).find('input[type="number"').val(currentRowIndex - 1);*/
    $(memberTimeRow).insertBefore(previous);
    overwriteDeptOrdering();
  }

  /**
   * Event handler for moving down people in a department grid.
   * The person's new position will be saved to the datastore
   * as a user preference.
   * 
   * @param {HTMLElement} memberTimeRow The row to move down.
   */
  function onOrderDown(memberTimeRow) {
    if (!memberTimeRow.nextSibling) {
      console.debug('can no longer move down');
      return;
    }
    //let currentRowIndex = $(memberTimeRow).index() - 2;
    let next = $(memberTimeRow).next();
    /*next.find('input[type="number"').val(currentRowIndex);
    $(memberTimeRow).find('input[type="number"').val(currentRowIndex + 1);*/
    $(memberTimeRow).insertAfter(next);
    overwriteDeptOrdering();
  }

  /**
   * Overwrites the order of department members in the context of a DTR.
   * New orders are saved as user preferences.
   */
  function overwriteDeptOrdering() {
    let personProps = {};
    let order = 1;
    
    // Creating a mapping of person IDs and their order as they are currently displayed in the grid
    for(let memberTimeRow of document.querySelectorAll('div.grid-member-time-row:not(.cg-blueprint)')) {
      let personId = memberTimeRow.getAttribute('person_id');
      personProps[personId] = order++;
    }

    alterNbOfSavingRequests(1);    
  
    // Overwrite the previous ordering saved by the user for this department, if any
    apicall('personapi', 'overwriteDTRPersonOrdering', {
      departmentId: document.getElementById('dtrDepartmentDropdown').value
    }, {
      props: personProps
    }).then(resp => {
      if(resp.responseCode !== '0') {
        throw new Error('invalid server response');
      }
    }).catch(() => {
      console.error('server error - overwriteDTRPersonOrdering failed');
      cgToast(i18n.t("js.dtr.ordering.failed"), { className: 'error-toast' });
    }).then(function() {
      alterNbOfSavingRequests(-1);
    });
  }

  function onDtrPasteRow(event, memberTimeRow, member) {
    if(dtrGridClipboard == null || Object.keys(dtrGridClipboard).length == 0) {
      console.debug('no data on dtrGridClipboard');
      swalToast({
        type: 'info',
        title: i18n.t("js.dtr.paste.nothing", {button: '&nbsp;<i class="far fa-copy"></i>&nbsp;'}),
        timer: 4500,
        showCloseButton: false
      });
      return;
    } else {
      // pasting all copied data to the target row
      let props = {
        personIds: member[0]
      };
      pasteGridRowDataWithoutSaving(memberTimeRow, member[0]);
      updateDailyTimeLogs(Object.assign(props, dtrGridClipboardToProps()));
    }
  }

  function applyAllCopiedData() {
    console.debug('applying data from dtrGridClipboard to all grid rows');
    let personIdList = [];
    for(let memberTimeRow of document.querySelectorAll('div.grid-member-time-row:not(.cg-blueprint)')) {
      let personId = memberTimeRow.getAttribute('person_id');
      pasteGridRowDataWithoutSaving(memberTimeRow, personId);
      personIdList.push(personId);
    }
    applyEventsToDeptMembersInBatches(personIdList, dtrGridClipboardToProps());
  }

  function dtrGridClipboardToProps() {
    let props = {};
    let dtrGridRowPB = document.getElementById('dtrGridTimeRowBP');
    for(let entry of Object.entries(dtrGridClipboard)) {
      let dtrGridCellBP = dtrGridRowPB
          .querySelector(`select[name="${entry[0]}"], input[name="${entry[0]}"]`);
      if(entry[0] == 'call_type') {
        props[entry[0]] = entry[1];
      } else {
        props[entry[0]] = getFormattedDTRInputValue(entry[1], dtrGridCellBP.type, entry[0]);
      }
    }
    return props;
  }

  function pasteGridRowDataWithoutSaving(memberTimeRow, personId) {
    for(let entry of Object.entries(dtrGridClipboard)) {
      try {
        let dtrGridCell = memberTimeRow
          .querySelector(`select[name="${entry[0]}"], input[name="${entry[0]}"]`);
        dtrGridCell.value = entry[1];
        if(entry[0] == 'call_type') {
          adaptUIForDailyWeeklyStatus(entry[1], `div.grid-member-time-row[person_id="${personId}"] input.dtr-grid-time-input`);
        }
      } catch(e1) {
        console.error(e1);
      }
    }
  }

  function isGridView() {
    return document.getElementById('dtrViewControllerBtn').classList
      .contains('active-dtr-grid-view');
  }

  function getFormattedDTRDate() {
    const locale = i18n.language || 'en-US';
    let weekDay = new Intl.DateTimeFormat(locale, { weekday: 'long'}).format(activeDTRDate);
    let monthFull = new Intl.DateTimeFormat(locale, { month: 'long'}).format(activeDTRDate);
    let monthMin = activeDTRDate.getMonth() + 1;
    let dayNb = activeDTRDate.getDate().toString();
    if(locale.includes("fr")){
      return `${weekDay}`
        + `<span class="desktop-content">, ${dayNb} ${monthFull}</span>`
        + `<span class="mobile-content">&nbsp;- ${dayNb}/${monthMin}</span>`;
    }
    return `${weekDay}`
        + `<span class="desktop-content">, ${monthFull}</span>`
        + `<span class="mobile-content">&nbsp;- ${monthMin}/</span>`
        + dayNb;
  }

  function refreshDTRContent() {
    if(isGridView()) {
      onGridViewDeptChange();
    } else {
      onDeptChange();
    }
    hideOptionalFields();
    $(".dtr-time-input-mask").inputmask("99:99", {
      // inputFormat: "HH:MM",
      placeholder: "",
      clearMaskOnLostFocus: true,
      jitMasking: true,
      showMaskOnFocus: false,
      showMaskOnHover: false,
      // max: 24
    });
  }

  function updateActiveDate(dayDelta) {
    let nbOfMillisInADay = 1000 * 3600 * 24;
    activeDTRDate = new Date(activeDTRDate.getTime() + dayDelta * nbOfMillisInADay);
    let activeDateElem = document.getElementById('dtrActiveDate');
    activeDateElem.innerHTML = getFormattedDTRDate();
    refreshDTRContent();
    //activeDatePicker.setDate(activeDTRDate);
  }

	function showAddDeptMemberForm() {
		swal({
			title: i18n.t("js.dtr.add.title"),
			html: `${i18n.t("js.dtr.add.info")} `
				+ `<a id="dtrContactPageLink" href="#contact" style="text-decoration: underline">${i18n.t("js.dtr.add.click-here")}</a>`
        + ` ${i18n.t("js.dtr.add.tocreate")}<br><br>`
				+ `<input id="dtrNewContactFirstName" autofocus type="text" placeholder="${i18n.t("utils.firstname")}" />`
				+ `<input id="dtrNewContactLastName" type="text" placeholder="${i18n.t("utils.lastname")}" />`
				+ `<input id="dtrNewContactJobTitle" type="text" placeholder="${i18n.t("utils.jobtitle")}" />`,
			showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
			confirmButtonText: i18n.t("create"),
			type: 'question',
      allowOutsideClick: () => !swal.isLoading(),
      showCloseButton: true,
			onOpen: function() {
        document.getElementById('dtrContactPageLink').onclick = () => swal.close();
        document.getElementById('dtrNewContactFirstName').focus();
			},
			showLoaderOnConfirm: true,
			preConfirm: function() {
				return new Promise((resolve, reject) => {
					let firstName = document.getElementById('dtrNewContactFirstName').value.trim();
					let lastName = document.getElementById('dtrNewContactLastName').value.trim();
					let jobTitle = document.getElementById('dtrNewContactJobTitle').value.trim();
					if(!firstName) {
						reject(i18n.t("js.dtr.add.reject.firstname"));
						return;
					}
					if(!lastName) {
						reject(i18n.t("js.dtr.add.reject.lastname"));
						return;
					}
					if(!jobTitle) {
						reject(i18n.t("js.dtr.add.reject.jobtitle"));
						return;
					}
					apicall('personapi', 'addTimeReportPerson', {
						departmentId: document.getElementById('dtrDepartmentDropdown').value,
						firstName: firstName,
						lastName: lastName,
						jobTitle: jobTitle
					}).then(resp => {
						if(resp.responseCode === '0') {
							resolve(resp.entityId);
						} else {
							throw new Error('invalid server response');
						}
					}).catch(() => {
						console.error('failed to add time report person');
						reject(i18n.t("js.utils.server.error"));
					});
				});
			}
		}).then(function(newPersonId) {
			swal({
				title: i18n.t("response.success"),
				html: i18n.t("js.dtr.add.success"),
				type: 'success',
        allowOutsideClick: true,
      }).catch(swal.noop);
      if(isGridView()) {
        onGridViewDeptChange();
      } else {
        onDeptChange(function() {
          if(newPersonId) {
            try {
              console.log('updating selected member to ' + newPersonId);
              changeDeptMemberSelection(newPersonId);
            } catch(err3) {
              console.error(err3);
            }
          }
        });
      }
		}).catch(swal.noop);
	}

	function showDeptMemberRemovalForm() {
		let deptMembersDropdown = document.getElementById('deptMembersDropdown');
		let deptMemberId = deptMembersDropdown.value;
		let deptMemberTitle = document.querySelector(`#deptMembersDropdown > option[value="${deptMemberId}"]`).textContent;
		swal({
			title: i18n.t("js.dtr.delete.title"),
			html: i18n.t("js.dtr.delete.text", {deptMemberTitle}),
			showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
			confirmButtonText: i18n.t("utils.delete"),
			confirmButtonColor: '#ff4136',
			type: 'warning',
			showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
      showCloseButton: true,
			preConfirm: function() {
				return new Promise((resolve, reject) => {
					apicall('personapi', 'removeTimeReportPerson', {}, {
						value: deptMemberId
					}).then(resp => {
						if(resp.responseCode === '0') {
							resolve();
						} else {
							throw new Error('invalid server response');
						}
					}).catch(() => {
						console.error('failed to add time report person');
						reject(i18n.t("js.utils.server.error"));
					});
				});
			}
		}).then(function() {
			swal({
				title: i18n.t("response.success"),
				html: i18n.t("js.dtr.delete.success", {deptMemberTitle}),
				type: 'success',
        allowOutsideClick: true,
			}).catch(swal.noop);
			refreshDTRContent();
		}).catch(swal.noop);
	}

  function toggleGridView() {
    this.classList.toggle('active-dtr-grid-view');
    let dtrOverlay = document.getElementById('dailyTimeReportOverlay');
		let viewIcon = this.querySelector('i');
    if(isGridView()) {
			viewIcon.title = i18n.t("js.dtr.views.member");
			viewIcon.classList.replace('fa-list', 'fa-user-alt');
      dtrOverlay.classList.remove('dtr-list-view');
    } else {
			viewIcon.title = i18n.t("js.dtr.views.grid");
			viewIcon.classList.replace('fa-user-alt', 'fa-list');
      dtrOverlay.classList.add('dtr-list-view');
    }
    refreshDTRContent();
  }

  function applyListViewEventsToAllDeptMembers() {
    let deptMembersSelect = document.getElementById('deptMembersDropdown');
    let personIdList = [];
    for(let deptMemberOption of deptMembersSelect.querySelectorAll('option')) {
      personIdList.push(deptMemberOption.value);
    }
    applyEventsToDeptMembersInBatches(personIdList, getActiveEventProps())
  }

  function applyEventsToDeptMembersInBatches(personIdList, srcEventProps) {
    let personIdBatches = [];
    let batchSize = 30;
    let i = 0;
    for(let personId of personIdList) {
      if(i % batchSize === 0) {
        personIdBatches.push([]);
      }
      personIdBatches[parseInt(i / 30)].push(personId);
      i++;
    }
    // sending save request in batch of 30 members to avoid 60 second deadline
    showSpinner();
    let nbOfCompletedRequests = 0;
    for(let personIdBatch of personIdBatches) {
      updateDailyTimeLogs(Object.assign({ personIds: personIdBatch.join(',') },
          srcEventProps), () => {
        if(++nbOfCompletedRequests == personIdBatches.length) {
          hideSpinner();
        }
      });
    }
  }

  function applyEventsToNextMember() {
    let targetMemberId = this.getAttribute('target-member-id');
    updateDailyTimeLogs(Object.assign({ personIds: targetMemberId }, getActiveEventProps()),
      () => changeDeptMemberSelection(targetMemberId));
  }

  function getActiveEventProps() {
    let props = {};
    props.call_type = document.getElementById('dtrDailyCallOpt').checked
			?'daily'
			:(document.getElementById('dtrWeeklyCallOpt').checked
				?'weekly'
				:'not-in');
    for(let timeInput of document.querySelectorAll('input.dtr-time-input')) {
      props[timeInput.getAttribute('name')] = getFormattedDTRInputValue(timeInput.value, timeInput.type, timeInput.getAttribute('name'));
    }
    return props;
  }

	function updateDailyWeeklyStatusFromRadiosBtns(callType) {
    let selectedMemberId = document.getElementById('deptMembersDropdown').value;
		updateDailyWeeklyStatus(selectedMemberId, callType, 'input.dtr-time-input');
	}

  function updateDailyWeeklyStatus(personId, callType, timeInputSelector) {
    adaptUIForDailyWeeklyStatus(callType, timeInputSelector);
		updateDailyTimeLogs(Object.assign({ personIds: personId }, { call_type: callType }));
  }

	function adaptUIForDailyWeeklyStatus(callType, timeInputSelector) {
		for(let timeInput of document.querySelectorAll(timeInputSelector)) {
      callType === 'not-in' ? timeInput.readOnly = true : timeInput.readOnly = false;
		}
	}

  function getFormattedDTRInputValue(value, inputType, eventName) {
    if (eventName == "kit_time" || eventName == "upgrade_time") {
      return value;
		} else {
			return value;
		}
  }

  function updateDailyTimeLogs(props, callback = null) {
    if(!props.personIds) {
      console.error('missing person Ids - cannot save');
      return;
    }
    if (props.call_type && props.call_type === 'not-in') {
      console.log("Call Type is Not-In. Clearing times...");
      props.travel_start_time = -1;
      props.call_time = -1;
      props.first_meal_start_time = -1;
      props.first_meal_end_time = -1;
      props.second_meal_start_time = -1;
      props.second_meal_end_time = -1;
      props.wrap_time = -1;
      props.travel_end_time = -1;
      props.kit_time = '';
      props.upgrade_time = '';
    }
    props.targetReportTime = activeDTRDate.getTime();
    alterNbOfSavingRequests(1);
    apicall('personapi', 'saveDailyTimeLogs', {}, {
      props: props
    }).then(resp => {
      if(resp.responseCode === '0') {
        console.debug('successfully updated daily/weekly call status');
      } else {
        throw new Error('server error');
      }
    }).catch(() => {
      console.error('server error saving daily/weekly call status');
      cgToast(i18n.t("js.dtr.server.error"));
    }).then(() => {
      alterNbOfSavingRequests(-1);
      if(typeof callback === 'function') {
        callback();
      }
    });
  }

  function changeDeptMemberSelection(targetMemberId) {
    let deptMembersSelect = document.getElementById('deptMembersDropdown');
    deptMembersSelect.value = targetMemberId;
    deptMembersSelect.onchange();
  }

  function getDailyMinMaxTime() {
    // we use activeDTRDate to make sure that the correct day is used
    let nowDate = new Date(activeDTRDate);
    nowDate.setHours(0);
    nowDate.setMinutes(0);
    nowDate.setSeconds(0);
    nowDate.setMilliseconds(0);
    let minDailyTime = nowDate.getTime();
    nowDate.setHours(23);
    nowDate.setMinutes(59);
    nowDate.setSeconds(59);
    nowDate.setMilliseconds(999);
    let maxDailyTime = nowDate.getTime();
    return [minDailyTime, maxDailyTime];
	}

	function updateTimeInputValidityBorder(timeInput) {
		if(timeInput.name !== "kit_time" && timeInput.name !== "upgrade_time") {
			timeInput.classList.remove('invalid-dtr-input');
    } else if (timeInput.name == "kit_time" || timeInput.name == "upgrade_time") {
      // Kit and Upgrade are not actually time inputs. So any alphanumeric values are good
      timeInput.classList.remove('invalid-dtr-input');
    }
    else {
			timeInput.classList.add('invalid-dtr-input');
		}
	}

  function onDeptChange(onSuccess = null) {
    updateMemberManagementBox([]);
    let deptChangeTime = new Date().getTime();
    latestDeptChangeTime = deptChangeTime;
    let latestDeptMemberChangeTime = null;
    let deptMembersSelect = document.getElementById('deptMembersDropdown');

    let minMaxTime = getDailyMinMaxTime();
    let minLogTime = minMaxTime[0];
    let maxLogTime = minMaxTime[1];

    apicall('personapi', 'fetchDepartmentMembersTitle', {
      departmentId: departmentSelect.value,
      minTime: minLogTime,
      maxTime: maxLogTime
    }).then(resp => {
      if(latestDeptChangeTime !== deptChangeTime) {
        console.debug('newer dept member title request found - returning');
        return;
      }
      if(resp && resp.items) {
        let sortedDeptMembers = resp.items; // sorted in backend
        sortedDeptMembers.forEach(member => member[2] = (member[2] == 'true' || member[2] == true));
        updateMemberManagementBox(sortedDeptMembers);
        sortedDeptMembers = sortedDeptMembers.filter(m => m[2]);
        deptMembersSelect.onchange = function() {}
        while(deptMembersSelect.firstChild) {
          deptMembersSelect.removeChild(deptMembersSelect.firstChild);
        }
        for(let member of sortedDeptMembers) {
          let deptMemberOption = document.createElement('option');
          deptMemberOption.value = member[0];
          deptMemberOption.textContent = member[1];
          deptMembersSelect.appendChild(deptMemberOption);
        }
        if(sortedDeptMembers.length) {
          deptMembersSelect.value = sortedDeptMembers[0][0];
          document.getElementById('emptyDeptPlaceholder').style.display = 'none';
          document.getElementById('deptMembersDropdownContainer').style.display = '';
          document.getElementById('dtrTimesContainer').style.display = '';
          document.getElementById('preDtrGridSeparator').style.display = '';
          document.getElementById('deleteDtrMemberIcon').style.display =
            (deptMembersSelect.value.startsWith('PERSON_'))? 'none':'';
        } else {
          document.getElementById('deptMembersDropdownContainer').style.display = 'none';
          document.getElementById('dtrTimesContainer').style.display = 'none';
          document.getElementById('preDtrGridSeparator').style.display = 'none';
          document.getElementById('includeMemberPlaceholder').style.display
            = resp.items.length ?'inline' :'none';
          document.getElementById('emptyDeptPlaceholder').style.display = 'block';
          document.getElementById('deleteDtrMemberIcon').style.display = 'none';
        }
        onDeptMemberChange(sortedDeptMembers);
        // NOTE - must not take event as parameter, as the onchange event will
        // not always be triggered by the user (done manually when clicking on arrow)
        deptMembersSelect.onchange = () => onDeptMemberChange(sortedDeptMembers);
				if(typeof onSuccess == 'function') {
					onSuccess();
				}
      } else {
        throw new Error('invalid server response');
      }
    }).catch(() => {
      console.error('server error fetching department members');
      cgToast(i18n.t("js.dtr.fetch.dept.members.err"));
      updateMemberManagementBox([]);
      onDeptMemberChange([]);
    });

    function onDeptMemberChange(sortedDeptMembers) {
      if(sortedDeptMembers.length){
        document.getElementById('deleteDtrMemberIcon').style.display =
            (deptMembersSelect.value.startsWith('PERSON_'))? 'none':'';
      }else{
        document.getElementById('deleteDtrMemberIcon').style.display = 'none';
      }
      let rightArrow = document.getElementById('dtrRightArrow');
      let leftArrow = document.getElementById('dtrLeftArrow');
      let applyAllBtn = document.getElementById('dtrApplyAllBtn');
      let applyNextBtn = document.getElementById('dtrApplyNextBtn');
      if(!sortedDeptMembers.length) {
        toggleAllMultiMemberElems(false);
        resetAllDTRInputs();
        document.getElementById('dtrCallTypeContainer').style.display = 'none';
        return;
      }
      document.getElementById('dtrCallTypeContainer').style.display = '';
      let selectedPersonId = deptMembersSelect.value;
      if(sortedDeptMembers.length > 1 && selectedPersonId.trim().length > 0) {
        let currentMemberIndex = sortedDeptMembers.findIndex(x => x[0] == selectedPersonId);
        let nextMemberIndex = (currentMemberIndex < sortedDeptMembers.length - 1)
          ?(currentMemberIndex + 1)
          :0
        let previousMemberIndex = (currentMemberIndex > 0)
          ?(currentMemberIndex - 1)
          :(sortedDeptMembers.length - 1);
        let previousMember = sortedDeptMembers[previousMemberIndex];
        let nextMember = sortedDeptMembers[nextMemberIndex];
        let fillInMemberInfo = (member, side)=>{
          document.getElementById(side+'NameTag').textContent = member[3] + ' ' + member[4];
          document.getElementById(side+'JobTag').textContent = member[5];
        };
        applyNextBtn.setAttribute('target-member-id', nextMember[0]);
        /*applyNextBtn.textContent = `Apply to next (${nextMember[1]})`;*/
        rightArrow.setAttribute('target-member-id', nextMember[0]);
        fillInMemberInfo(nextMember, 'right');
        leftArrow.setAttribute('target-member-id', previousMember[0]);
        fillInMemberInfo(previousMember, 'left');
        toggleAllMultiMemberElems(true);
      } else {
        toggleAllMultiMemberElems(false);
      }

      resetAllDTRInputs();

      let deptMemberChangeTime = new Date().getTime();
      latestDeptMemberChangeTime = deptMemberChangeTime;
      apicall('personapi', 'fetchDailyTimeLogs', {
        minTime: minLogTime,
        maxTime: maxLogTime
      }, {
        value: selectedPersonId
      }).then(resp => {
        if(latestDeptMemberChangeTime !== deptMemberChangeTime) {
          console.debug('deptMemberChangeTime changed - returning');
          return;
        }
        if(resp && (Object.keys(resp).length == 0 || resp.hasOwnProperty('props'))) {
          let processedEventNames = [];
          let callType = ''; // default call type
          if(resp.props) {
            if(resp.props[selectedPersonId].hasOwnProperty('call_type')) {
              callType = resp.props[selectedPersonId]['call_type'].value;
              document.getElementById('dtrDailyCallOpt').checked = callType == 'daily';
              document.getElementById('dtrWeeklyCallOpt').checked = callType == 'weekly';
              document.getElementById('dtrNotInCallOpt').checked = callType == 'not-in';
              delete resp.props[selectedPersonId]['call_type'];
            }
            for(let timeLog of Object.entries(resp.props[selectedPersonId])) {
              try {
                let eventName = timeLog[0];
                let eventInput = document.querySelector(`input.dtr-time-input[name="${eventName}"]`);
                // if (eventName == "kit_time" || eventName == "upgrade_time") {
                if(timeLog[1] && timeLog[1].data_type === 'string') {
                  eventInput.value = timeLog[1] ? (timeLog[1].value || '') : '';
                } else if(eventName == "kit_time" || eventName == "upgrade_time") {
                  eventInput.value = timeLog[1] ? (timeLog[1].value || '') : '';
                } else if(timeLog[1] && timeLog[1].data_type === 'time' && (eventInput.type == 'time' || eventName.endsWith("_time"))) {
									let eventTime = parseInt(timeLog[1].value);
									if(eventTime == -1) {
										eventInput.value = "";
									} else {
										let eventDate = new Date(eventTime);
										eventInput.value = `${eventDate.getHours().toString().padStart(2, '0')}:${eventDate.getMinutes().toString().padStart(2, '0')}`;
									}
								} else {
									eventInput.value = timeLog[1] ? (timeLog[1].value || '') : '';
								}
                processedEventNames.push(eventName);
              } catch(err) {
                console.error(err);
              }
            }
          } else {
						console.log("call_type is not set. Setting the currently checked type as default");
						callType = document.getElementById('dtrDailyCallOpt').checked? 'daily':
            document.getElementById('dtrWeeklyCallOpt').checked? 'weekly':
            document.getElementById('dtrNotInCallOpt').checked? 'not-in' : 'weekly';
            console.log("Default Call Type is set to : " + callType);
						updateDailyWeeklyStatus(selectedPersonId, callType, 'input.dtr-time-input');
            document.getElementById('dtrDailyCallOpt').checked = callType == 'daily';
            document.getElementById('dtrWeeklyCallOpt').checked = callType == 'weekly';
            document.getElementById('dtrNotInCallOpt').checked = callType == 'not-in';
					}
					adaptUIForDailyWeeklyStatus(callType, 'input.dtr-time-input');
          for(let timeInput of document.querySelectorAll('input.dtr-time-input')) {
            if(!processedEventNames.includes(timeInput.getAttribute('name'))) {
              timeInput.value = timeInput.getAttribute('default-value');
            }

						timeInput.onfocus = function() {
							updateTimeInputValidityBorder(this);
						}

						timeInput.oninput = function() {
							updateTimeInputValidityBorder(this);
						}

            timeInput.onblur = function() {
              // this.classList.remove('invalid-dtr-input');
              updateTimeInputValidityBorder(this);
							this.value = this.value; // necessary to reset the content of the time input with invalid data
							let eventName = this.getAttribute('name');
							let props = {
								personIds: deptMembersSelect.value
							};
              props[eventName] = getFormattedDTRInputValue(this.value, this.type, eventName);
              let isExceptionFields = this.name == "kit_time" || this.name == "upgrade_time";
              if (isExceptionFields || eventName.endsWith("_time")) {//(moment(this.value, "HH:mm", true).isValid() || !this.value)) {
                updateDailyTimeLogs(props);
              }
						}
          }
        } else {
          throw new Error('server error');
        }
      }).catch((err) => {
        console.error('failed to fetch time logs for ' + selectedPersonId, err);
      });

      function toggleAllMultiMemberElems(shouldBeVisible) {
        let arrDisplayStyle = shouldBeVisible ?'flex' :'none';
        let btnDisplayStyle = shouldBeVisible ?'inline-block' :'none';
        // Unhide Arrows as they are required by users
        rightArrow.style.display = arrDisplayStyle;
        leftArrow.style.display = arrDisplayStyle;
        applyAllBtn.style.display = btnDisplayStyle;
        applyNextBtn.style.display = btnDisplayStyle;
      }

      function resetAllDTRInputs() {
        for(let timeInput of document.querySelectorAll('input.dtr-time-input')) {
          timeInput.value = '';
          timeInput.onblur = function() {}
          timeInput.onfocus = function() {}
          timeInput.oninput = function() {}
        }
      }
    }
  }

  function onGridViewDeptChange() {
    updateMemberManagementBox([]);
    let departmentDtrNote = document.getElementById('departmentDtrNote');
    departmentDtrNote.oninput = function() {};
    departmentDtrNote.value = '';
    departmentDtrNote.disabled = true;

    let rowToDelete;
    while((rowToDelete = document.querySelector('div.grid-member-time-row:not(#dtrGridTimeRowBP)')) != null) {
      rowToDelete.parentNode.removeChild(rowToDelete);
    }

    let minMaxTime = getDailyMinMaxTime();
    let minLogTime = minMaxTime[0];
    let maxLogTime = minMaxTime[1];

    let deptChangeTime = new Date().getTime();
    latestDeptChangeTime = deptChangeTime;
    apicall('personapi', 'fetchDepartmentMembersTitle', {
      departmentId: departmentSelect.value,
      minTime: minLogTime,
      maxTime: maxLogTime
    }).then(resp => {
      if(latestDeptChangeTime !== deptChangeTime) {
        console.debug('newer dept member title request found - returning [1]');
        return;
      }
      if(resp && resp.items) {
        let sortedDeptMembers = resp.items; // sorted in backend
        sortedDeptMembers.forEach(member => member[2] = (member[2] == 'true' || member[2] == true));
        updateMemberManagementBox(sortedDeptMembers);
        sortedDeptMembers = sortedDeptMembers.filter(m => m[2]);
        if(!sortedDeptMembers.length) {
          document.getElementById('dtrGridTimesContainer').style.display = 'none';
					document.getElementById('departmentDtrNoteContainer').style.display = 'none';
          document.getElementById('includeMemberPlaceholder').style.display
            = resp.items.length ?'inline' :'none';
          document.getElementById('emptyDeptPlaceholder').style.display = 'block';
        } else {
          document.getElementById('emptyDeptPlaceholder').style.display = 'none';
          document.getElementById('departmentDtrNoteContainer').style.display = '';
          document.getElementById('dtrGridTimesContainer').style.display = '';
          let i = 0;
          for(let member of sortedDeptMembers) {
            let memberTimeRow = copyBlueprint('dtrGridTimeRowBP', true);
            memberTimeRow.querySelector('div.dtr-grid-member-name').textContent = member[1];
            memberTimeRow.setAttribute('person_id', member[0]);
            let copyDtrRowBtn = memberTimeRow.querySelector('div.dtr-copy-paste-cell > div > i.copy-dtr-row');
            copyDtrRowBtn.onclick = function(event) {
              onDtrCopyRow(event, memberTimeRow, member);
            };
            let pasteDtrRowBtn = memberTimeRow.querySelector('div.dtr-copy-paste-cell > div > i.paste-dtr-row');
            pasteDtrRowBtn.onclick = function(event) {
              onDtrPasteRow(event, memberTimeRow, member);
            };

            try {
              let orderUpArrow = memberTimeRow.querySelector('div.dtr-ordering-arrows > i.order-arrow-up');
              orderUpArrow.onclick = function(event) {
                onOrderUp(memberTimeRow);
              }
              let orderDownArrow = memberTimeRow.querySelector('div.dtr-ordering-arrows > i.order-arrow-down');
              orderDownArrow.onclick = function(event) {
                onOrderDown(memberTimeRow);
              }
            } catch (e) {
              console.error(e);
            }
            i++;
          }
        }
        onDeptMembersFetched(sortedDeptMembers);
      } else {
        throw new Error('invalid server response');
      }
    }).catch(() => {
      console.error('server error fetching department members');
      cgToast(i18n.t("js.dtr.fetch.dept.members.err"));
      updateMemberManagementBox([]);
      onDeptMembersFetched([]);
    });

    apicall('personapi', 'fetchDailyTimeReportNote', {
      minTime: minLogTime,
      maxTime: maxLogTime,
      departmentId: departmentSelect.value
    }).then(resp => {
      if(latestDeptChangeTime !== deptChangeTime) {
        console.debug('newer dept member title request found - returning [1.5]');
        return;
      }
      if(resp.responseCode === '0') {
        departmentDtrNote.disabled = false;
        departmentDtrNote.value = resp.responseMessage;
      } else {
        throw new Error('server error');
      }
    }).catch(() => {
      console.error('failed to fetch department note');
      departmentDtrNote.disabled = false;
    }).then(function() {
      let latestOnInputTime = null;
      departmentDtrNote.oninput = function() {
        let newNoteValue = this.value;
        let onInputTime = new Date().getTime();
        latestOnInputTime = onInputTime;
        setTimeout(function() {
          if(latestOnInputTime === onInputTime) {
            console.debug('updating department note');
            alterNbOfSavingRequests(1);
            apicall('personapi', 'updateDailyTimeReportNote', {
              minTime: minLogTime,
              maxTime: maxLogTime,
              departmentId: departmentSelect.value,
              targetReportTime: activeDTRDate.getTime()
            }, {
              value: newNoteValue
            }).then(resp2 => {
              if(resp2.responseCode === '0') {
                console.debug('successfully update department note');
              } else {
                throw new Error('invalid server response');
              }
            }).catch(() => {
              console.error('failed to update department note');
            }).then(() => alterNbOfSavingRequests(-1));
          }
        }, 750);
      }
    });

    function onDeptMembersFetched(sortedDeptMembers) {
      let deptPersonIds = sortedDeptMembers.map(m => m[0]);
      if(deptPersonIds.length > 50) {
        showSpinner();
      }
      apicall('personapi', 'fetchDailyTimeLogs', {
        minTime: minLogTime,
        maxTime: maxLogTime
      }, {
        value: deptPersonIds.join(',')
      }).then(resp => {
        if(latestDeptChangeTime !== deptChangeTime) {
          console.debug('newer dept member title request found - returning [2]');
          return;
        }
        if(resp && (Object.keys(resp).length == 0 || resp.hasOwnProperty('props'))) {
          for(let personId of deptPersonIds) {
            try {
              let memberTimeRow = document.querySelector(`div.grid-member-time-row[person_id="${personId}"]`);
              let dailyWeeklySelect = memberTimeRow.querySelector('select.dtr-grid-daily-weekly');
              let processedEventNames = [];
              let timeInputSelector = `div.grid-member-time-row[person_id="${personId}"] input.dtr-grid-time-input`;
							let callType = 'weekly';
							if(resp.props && resp.props.hasOwnProperty(personId)) {
                let personProps = resp.props[personId];
								if(personProps.hasOwnProperty('call_type')) {
                  callType = personProps['call_type'].value ? personProps['call_type'].value : personProps['call_type'];
                  dailyWeeklySelect.value = callType;
                  delete resp.props[personId]['call_type'];
                }
                for(let timeLog of Object.entries(personProps)) {
                  try {
                    let eventName = timeLog[0];
                    let eventInput = memberTimeRow.querySelector(`input.dtr-grid-time-input[name="${eventName}"]`);
                    if(timeLog[1] && timeLog[1].data_type === 'string') {
                      eventInput.value = timeLog[1] ? (timeLog[1].value || '') : '';
                    } else if(eventName == "kit_time" || eventName == "upgrade_time") {
                      eventInput.value = timeLog[1] ? (timeLog[1].value || '') : '';
                    } else if(timeLog[1] && timeLog[1].data_type === 'time' && (eventInput.type == 'time' || eventName.endsWith("_time"))) {
                      let eventTime = parseInt(timeLog[1].value);
											if(eventTime == -1) {
												eventInput.value = "";
											} else {
												let eventDate = new Date(eventTime);
												eventInput.value = `${eventDate.getHours().toString().padStart(2, '0')}:${eventDate.getMinutes().toString().padStart(2, '0')}`;
											}
										} else {
											eventInput.value = timeLog[1] ? (timeLog[1].value || '') : '';
										}
                    processedEventNames.push(eventName);
                  } catch(err1) {
                    console.error(err1);
                  }
                }
              }
							adaptUIForDailyWeeklyStatus(callType, timeInputSelector);
              dailyWeeklySelect.onchange = function() {
								updateDailyWeeklyStatus(personId, this.value, timeInputSelector);
              };
              for(let timeInput of memberTimeRow.querySelectorAll('input.dtr-grid-time-input')) {
                if(!processedEventNames.includes(timeInput.getAttribute('name'))) {
                  timeInput.value = timeInput.getAttribute('default-value');
                }

								timeInput.onfocus = function() {
									updateTimeInputValidityBorder(this);
								}

								timeInput.oninput = function() {
									updateTimeInputValidityBorder(this);
								}

                timeInput.onblur = function() {
                  // this.classList.remove('invalid-dtr-input');
                  updateTimeInputValidityBorder(this);
									this.value = this.value; // necessary to reset the content of the time input with invalid data
									let eventName = this.getAttribute('name');
									let props = {
										personIds: personId
									};
                  props[eventName] = getFormattedDTRInputValue(this.value, this.type, eventName);
                  let isExceptionFields = this.name == "kit_time" || this.name == "upgrade_time";
                  if (isExceptionFields || eventName.endsWith("_time")) {//(moment(this.value, "HH:mm", true).isValid() || !this.value)) {
                      updateDailyTimeLogs(props);
                  }
								}
              }
              // In Grid view, each row is dynamically generated so we need to
              // apply 24-hour format mask after the rows are created
              $(".dtr-time-input-mask").inputmask("99:99", {
                // inputFormat: "HH:MM",
                placeholder: "",
                clearMaskOnLostFocus: true,
                jitMasking: true,
                showMaskOnFocus: false,
                showMaskOnHover: false,
                // max: 24
              });
            } catch(err2) {
              console.error(err2);
            }
          }
        } else {
          throw new Error('server error');
        }
      }).catch((err) => {
        console.error('failed to fetch time logs for ' + selectedPersonId, err);
      }).then(() => {
        if(latestDeptChangeTime == deptChangeTime) {
          hideSpinner();
        }
      });
    }
  }
}