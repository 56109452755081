import i18n from './components/Translation';
import DocUtility from './components/DocUtility';
import Dropbox from 'dropbox';
import moment from 'moment';
import Upload from './components/Upload.js';
import SidePanel from './components/SidePanel.js';
import CGGoogleAuth from './components/CGGoogleAuth.js';
import SmartFolder from './components/SmartFolder.js';
import FileDropper from './components/FileDropper.js';
import UploadManager from './components/UploadManager.js';
import DownloadNotification from './components/notifications/DownloadNotification.js';
import Selectables from './components/Selectables.js';
import swal from 'sweetalert2';

var singleSelectPanel = null;
var multiSelectPanel = null;
var docTableSelectables;

var selectAllFiles;
var extraParams = null;
var upload = null;
var isInvalidKeyToastShowing;
var currentWmFirstLine;
var currentWmSecondLine;
var wmFirstOtherField;
var wmSecondOtherField;
var mainList = null;
var fileDropper;
var lastClickedMenuTarget;
var lastFolderModalTriggerType, smartFolderInEditing;

export function init() {
  isInvalidKeyToastShowing = false;
  extraParams = croogloocurrentpage.extraParams;
  lastClickedMenuTarget = null;
  singleSelectPanel = null;
  multiSelectPanel = null;
  docTableSelectables = null;
  lastFolderModalTriggerType = null;
  smartFolderInEditing = null;


  resetWmGlobals();
  var params = croogloocurrentpage.urlParams;
  var paramsKey = Object.keys(params);
  var path = 'ROOT';
  if (paramsKey.length > 0 && ['Dropbox', 'gDrive', 'Box'].includes(params[paramsKey[0]])) {
    window.isNonCrooglooDirectory = true;
    var pathbuilder = [];
    if (paramsKey.length > 1) {
      for (var i = 1; i < paramsKey.length; i++) {
        pathbuilder.push(params[paramsKey[i]]);
      }
    }
    path = pathbuilder.length > 0 ? '/' + pathbuilder.join("/") : '';
    switch(params[paramsKey[0]]){
      case 'Dropbox':
        mainList = new DropboxFileList(path);
        break;
      case 'Box':
        mainList = new BoxFileList(path);
        break;
      default:
        mainList = new GDriveFileList(path);
    }
    //mainList = params[paramsKey[0]] == 'Dropbox' ? new DropboxFileList(path) : new GDriveFileList(path);
  } else {
    window.isNonCrooglooDirectory = false;
    path = paramsKey.length == 0 ? 'ROOT' : params[paramsKey[paramsKey.length - 1]];
    mainList = new CrooglooFileList(path);
  }
  loader.add(() => {
    loader.check('gDrive');
  }, 'gDrive')
  loader.add(() => {
    mainList.fetch()
    .then(() => {
      ui(
        extraParams.path
        ? extraParams.path
        : ['']);
      loader.check("mainload");
    })
    .catch(err => {
      console.log(err)
    })
  }, "mainload")
  .add(()=>{
    singleSelectPanel = new SingleItemSidePanel();
    multiSelectPanel = new SidePanel('main', true);
    if (croogloocurrentpage.getAccessLevel() >= 2) {
      var icoparent = document.createElement('i');
      icoparent.className = 'icon-folder-up';
      multiSelectPanel.addListItem(icoparent, i18n.t("docs.menu.import"), e => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        mainList.importFilesAndFolders();
        mainList.unselectall();
        e.panel.clearSelected();
        e.panel.hide();
      }, ['dbfile', 'boxfile']);
      
      var icoparent = document.createElement('i');
      icoparent.className = 'icon-folder-up';
      multiSelectPanel.addListItem(icoparent, i18n.t("docs.menu.move"), e => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        mainList.changeParentFolder();
        e.panel.hide();
      }, ['file', 'folder']);

      let downloadIcon = document.createElement('i');
      downloadIcon.className = 'fa fa-download';
      multiSelectPanel.addListItem(downloadIcon, i18n.t("utils.download"), e => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        mainList.downloadAsZIP();
        e.panel.hide();
      }, ['file', 'folder']);

      var icoRecover = document.createElement('i');
      icoRecover.className = 'fa fa-box-open';
      multiSelectPanel.addListItem(icoRecover, i18n.t("js.documents.menu.recover"), e => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        let files = mainList.selectedids.map(id=>mainList.getById(id));
        mainList.changeArchiveStatus(files, false);
        mainList.unselectall();
        e.panel.clearSelected();
        e.panel.hide();
      }, 'archived');

      var icoArchive = document.createElement('i');
      icoArchive.className = 'fa fa-archive';
      multiSelectPanel.addListItem(icoArchive, i18n.t("js.documents.menu.archive"), e => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        let files = mainList.selectedids.map(id=>mainList.getById(id));
        mainList.changeArchiveStatus(files, true);
        mainList.unselectall();
        e.panel.clearSelected();
        e.panel.hide();
      }, 'archiveable');

      var ico = document.createElement('i');
      ico.className = 'icon-merge';
      multiSelectPanel.addListItem(ico, i18n.t("js.documents.menu.merge"), e => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        mainList.merge();
        e.panel.hide();
      }, 'file');

      var icoedit = document.createElement('i');
      icoedit.className = 'icon-pencil';
      multiSelectPanel.addListItem(icoedit, i18n.t("docs.menu.category"), (e) => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        mainList.editcategoryall();
        e.panel.hide();
      }, 'file');
      
      var icosec = document.createElement('i');
      icosec.className = 'icon-lock';
      multiSelectPanel.addListItem(icosec, i18n.t("docs.menu.access"), (e) => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        mainList.accesslevelall();
        e.panel.hide();
      }, 'file');

      var ico = document.createElement('i');
      ico.className = 'icon-mail';
      multiSelectPanel.addListItem(ico, i18n.t("docs.menu.send-email"), (e) => {
        let selectedFileIds = Object.values(e.panel.selectedItems).map(o => o.itemid);
        r.loadPage('compose', { attachmentID: selectedFileIds });
        mainList.unselectall();
        e.panel.clearSelected();
        e.panel.hide();
      }, 'file');

      let watermarkIcon = document.createElement('i');
      watermarkIcon.className = 'fa fa-tint';
      multiSelectPanel.addListItem(watermarkIcon, i18n.t("js.documents.menu.wtmrk.add"), e => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        mainList.applyWatermarkToAll();
        e.panel.hide();
      }, 'clean-pdf-file');

      let removeWatermarkIcon = document.createElement('i');
      removeWatermarkIcon.className = 'fa fa-tint';
      multiSelectPanel.addListItem(removeWatermarkIcon, i18n.t("js.documents.menu.wtmrk.del"), e => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        mainList.removeWatermarkToAll();
        e.panel.hide();
      }, 'watermarked-pdf-file');

      var icodel = document.createElement('i');
      icodel.className = 'icon-trash';
      icodel.style.paddingLeft = '5px';
      multiSelectPanel.addListItem(icodel, i18n.t("js.documents.menu.delete"), (e) => {
        mainList.selectedids = Object.values(e.panel.selectedItems).map(o => o.itemid);
        mainList.deleteall();
        e.panel.hide();
      }, ['file', 'folder']);
      var icoclean = document.createElement('i');
      icoclean.className = 'icon-exit';
      multiSelectPanel.addListItem(icoclean, i18n.t("js.utils.clear-selection"), e => {
        mainList.unselectall();
        e.panel.clearSelected();
        e.panel.hide();
        r.deleteExtraParam('multiSelectPanel');
      }, ['file', 'folder', 'dbfile', 'boxfile']);

      // TODO: re-enable for Publish to Studio feature
      
      // var icoimport = document.createElement('i');
      // multiSelectPanel.addListItem(null, i18n.t("docs.menu.import.cloud"), e => {
      //   e.panel.hide();
      //   publishToStudioSwal(e.panel.selectedItems)
      //   e.panel.clearSelected();
      // }, ['file', 'folder']);
    }
    loader.check("multiSelectPanel");
  }, "multiSelectPanel")
  .add(() => {
    if(document.getElementById('loadingScreen') != null) {
      hideLoadingScreen();
    }
  }).add(checkTriggers)
  .add(initListeners)
  .add(initUploadManager) // TODO dont hide spinner until upload ready, without slowing down process... to prevent having to check every time when changing parent folder or deleting items
  .execute();
  if(window.croogloocurrentpage.getAccessLevel() < 2) {
    $('#btnContainerRight').css('display','none');
  }
  window.onbeforeunload = function() {
    r.deleteExtraParam('multiSelectPanel');
  }
}

function initListeners() {
  let folderName = document.getElementById('docbchead').lastChild.textContent;

  document.getElementById('mainDocumentContainer').onclick = function(e) {
    if(e.target.closest('a.moreMenuClass') === null) {
      try {
        $('#dropdownmenuitem').foundation('close');
        console.debug('forced closed dropdown menu item');
      } catch(err) {
        // Error expected sometimes when the dropdown is already closed. 
        // It should not cause any actual problems.
        console.debug(err);
      }
    }       
    
  };

  fileDropper = new FileDropper('mainDocumentContainer', i18n.t("js.utils.file.dropper.label", {folderName}), 
    (files) => {
      if(!window.isNonCrooglooDirectory) {
        uploadFiles(files, typeof fileDropper.targetFolderId == 'string' ? fileDropper.targetFolderId:window.activeFolderId);
      } else {
        cgToast(i18n.t("js.documents.upload.drp-google"));
      }
    },
    document.getElementById('main')
  );
  // preparing multi-selection tool (click and drag)
  docTableSelectables = new Selectables({
    zone: '#mainDocumentContainer',
    elements: '#doc-table > tbody > tr',
    selectedClass: 'selected-document',
    boundarySelectionElem: '#doc-table',
    onSelect: function(element) {
      console.debug('selected element', element);
      let itemid = element.getAttribute('item-id');
      // must use the element ID as the key for query selectors later
      multiSelectPanel.addSelectedItem(element.id, mainList.getById(itemid || element.id) || { id: element.id, itemid: itemid });
      updateSidePanelsVisibility();
    },
    onDeselect: function(element) {
      console.debug('unselected element', element);
      multiSelectPanel.removeSelectedItem(element.id);
      updateSidePanelsVisibility();
    },
    onFirstSelect: function(element) {
      mainList.lastNonShiftClickedElem = element;
    }
  });
  document.getElementById('dropdownmenuitem').onshow = function() {
    
    let sidePanelFileOptionsBtn = document.querySelector('div.panel-content.single-item i.icon-more.panel-file-options');
    if (lastClickedMenuTarget !== null && lastClickedMenuTarget === sidePanelFileOptionsBtn) {
      let optionBtnContainer = sidePanelFileOptionsBtn.parentElement;
      let topMenuBarHeight = document.getElementById('topbar-center-logo').clientHeight;
      let singleItemPanelWidth = singleSelectPanel.htmlElement.clientWidth;
      this.style.top = `${(optionBtnContainer.offsetTop - topMenuBarHeight + optionBtnContainer.clientHeight).toString()}px`;
      this.style.right = `${(singleItemPanelWidth - optionBtnContainer.clientWidth).toString()}px`;
      this.style.left = '';
    } else {
      let $tdContainer = $(lastClickedMenuTarget).closest('td');
      $tdContainer.append($("#dropdownmenuitem"));
      this.style.top = "" ;
    }
  }

  document.getElementById("refreshBtn").onclick = ()=>{
    DocUtility.removeCardViews(getCurrentFolderId());//should I clear all cache instead? => done in settings page
    r.reload();
  };
}

function initUploadManager() {
  if(!window.uploadManager) {
    new UploadManager();
  }
}

function isBoxDirectory() {
  if(mainList === null) {
    throw new Error('main list has not been instanciated yet');
  }
  return mainlist instanceof BoxFileList;
}

function isDropboxDirectory() {
  if(mainList === null) {
    throw new Error('main list has not been instantiated yet');
  }
  return mainList instanceof DropboxFileList;
}

/**
 * Custom replacement of DH or dh string value with DTR to display in the frontend 
 * should not affect sharing settings ie: dh will still be sent to the backend API
 * @param {String} sharedString unparsed value of what security groups this document is shared with
 */
function replaceDHInShared(sharedString) {
  let modifiedString = sharedString;
  if (sharedString.includes('dh')) {
    modifiedString = sharedString.replace('dh', 'dtr');
  }
  if (sharedString.includes('DH')) {
    modifiedString = sharedString.replace('DH', 'DTR');
  }
  if (sharedString.includes('Dh')) {
    modifiedString = sharedString.replace('Dh', 'DTR');
  }

  return modifiedString;
}

function updateSidePanelsVisibility() {
  let isFolderSelected = Object.values(multiSelectPanel.selectedItems)
    .find(item => item != null && (item.type == 'folder' || item.type == 'dbfolder' || item.type == 'boxfolder')) != null;
  let isNonCrooglooFolderLinkSelected = Object.values(multiSelectPanel.selectedItems)
    .find(item => ['dropboxfolder', 'gDriveFolder', 'boxfolder'].includes(item.itemid)) != null;
  let isSecuredFolderSeclected = Object.values(multiSelectPanel.selectedItems)
    .find(item => ['PHOTO_ROOT'].includes(item.itemid)) != null;

  if(window.isNonCrooglooDirectory) {
    if(Object.keys(multiSelectPanel.selectedItems).length >= 2) {
      multiSelectPanel.show(isDropboxDirectory() ? (isFolderSelected ?'dbfolder' :'dbfile') : 'none');
    } else {
      multiSelectPanel.hide();
    }
  } else {
    switch(Object.keys(multiSelectPanel.selectedItems).length) {
      case 0: 
        multiSelectPanel.hide();
        singleSelectPanel.hide();
        break;
      case 1:
        multiSelectPanel.hide();
        singleSelectPanel.clearSelected();
        if(!isNonCrooglooFolderLinkSelected) {
          let selectedItem = Object.entries(multiSelectPanel.selectedItems)[0];
          singleSelectPanel.addSelectedItem(selectedItem[0], selectedItem[1]);
          try {
            singleSelectPanel.update();
            singleSelectPanel.show();
          } catch(e) {
            console.error(e);
            singleSelectPanel.hide();
          }
        } else {
          singleSelectPanel.hide();
        }
        break;
      default:
        let panelCategories = [];
        let mainCategory = (isNonCrooglooFolderLinkSelected || isSecuredFolderSeclected)
          ? 'none' // no applicable options
          : (isFolderSelected
            ? 'folder'
            : 'file');
        panelCategories.push(mainCategory);
        if(mainCategory == 'file'){
          if(Object.values(multiSelectPanel.selectedItems).find(doc => !doc.itemid.toLowerCase().endsWith('.pdf')) == null){
            if(Object.values(multiSelectPanel.selectedItems).find(doc => doc.watermark) == null){
              panelCategories.push('clean-pdf-file')
            }else if(Object.values(multiSelectPanel.selectedItems).find(doc => !doc.watermark) == null){
              panelCategories.push('watermarked-pdf-file')
            }else{
              panelCategories.push('pdf-file')
            }
          }
          if(Object.values(multiSelectPanel.selectedItems).find(doc => !doc.archived) == null){
            panelCategories.push('archived')
          }else if(Object.values(multiSelectPanel.selectedItems).find(doc => doc.archived) == null){
            panelCategories.push('archiveable');
          }
        }
        multiSelectPanel.show(panelCategories);
        singleSelectPanel.hide();
    }
  }
}

function resetWmGlobals() {
  currentWmFirstLine = '';
  currentWmSecondLine = '';
  wmFirstOtherField = '';
  wmSecondOtherField = '';
}

function checkTriggers() {
  let trigger = Utility.getPageParam('trigger');
  console.debug('trigger: ', trigger);
  if(trigger !== null) {
    r.deleteExtraParam('trigger');
    switch(trigger) {
      case 'gDrive': document.getElementById('gDriveFolder').ondblclick(); break;
      case 'dropbox': document.getElementById('dropboxfolder').ondblclick(); break;
      case 'box': document.getElementById('boxfolder').ondblclick(); break;
      default: console.error('invalid trigger');
    }
  }
}

function alphanumCompare(a, b) {
  let nbPrefixRegex = /^\h*(\d+)/;
  if(nbPrefixRegex.test(a) && nbPrefixRegex.test(b)) {
    return parseInt(a.match(nbPrefixRegex)[1]) - parseInt(b.match(nbPrefixRegex)[1]);
  } else {
    return a.localeCompare(b);
  }
}

function datatable() {
  $.extend( $.fn.dataTable.ext.type.order, {
    "crooglooname-asc": (x,y) => {
      let xSortVal = parseInt((/sortval="(\d+)"/.exec(x)||[55,55])[1]||75);
      let ySortVal = parseInt((/sortval="(\d+)"/.exec(y)||[55,55])[1]||75);
      x=x.replace(/<[^>]*>/g,'').toUpperCase();
      y=y.replace(/<[^>]*>/g,'').toUpperCase();
      if (x=="DROPBOX" || x=="GOOGLE DRIVE" || x=="BOX") return -1;
      if (y=='DROPBOX' || y=="GOOGLE DRIVE" || y=="BOX") return 1;
      return xSortVal - ySortVal || alphanumCompare(x, y);
    },
    "crooglooname-desc": (x,y)=>{
      let xSortVal = parseInt((/sortval="(\d+)"/.exec(x)||[55,55])[1]||75);
      let ySortVal = parseInt((/sortval="(\d+)"/.exec(y)||[55,55])[1]||75);
      x=x.replace(/<[^>]*>/g,'').toUpperCase();
      y=y.replace(/<[^>]*>/g,'').toUpperCase();
      if (x=="DROPBOX" || x=="GOOGLE DRIVE" || x=="BOX") return -1;
      if (y=='DROPBOX' || y=="GOOGLE DRIVE" || y=="BOX") return 1;
      return ySortVal - xSortVal || alphanumCompare(y, x);
    }
  });

  $.fn.dataTable.ext.errMode = 'none';
  $('#doc-table').DataTable().destroy();
  $('#doc-table').dataTable({
    "pageResize": true,
    "responsive": true,
    "paging": false,
    "info": false,
    "columnDefs": [
      {
        "type": "crooglooname",
        "targets": 0
      },
      {
        "orderData": 2,
        "targets": 1
      }, {
        "targets": 2,
        "searchable": false
      }, {
        "orderable": false,
        "targets": 4
      }
    ],
    "dom": '<"toolbar">frtip',
    "language": {
      "search": "",
      "zeroRecords": i18n.t("js.utils.datatables.zeroRecords")
    }
  });
  $('#doc-table').on('error.dt', function(e, settings, techNote, msg) {
    console.error('dataTable error');
    console.error(msg);
  });
}

function ui(path) {
  if($('.newfoldermodal').length > 1) {
    // important to remove previous folder modal when #main gets recreated
    // or newly created folders may get created with old filters
    $($('.newfoldermodal')[0]).remove();
  }
  $('#main').foundation();
  /* Documents table sorting */
  datatable();
  let pathnames = path && path.length ?path :[];
  pathnames = ['Documents'].concat(pathnames);
  let pathids = ['ROOT'];
  for (let idx in croogloocurrentpage.urlParams) {
    pathids.push(croogloocurrentpage.urlParams[idx]);
  }
  $("div.toolbar").html('<h2 id="docbchead"></h2>');
  let docbchead = document.getElementById('docbchead');

  for(let i = pathnames.length - 1; i >= 0; i--) {
    if(pathnames[i] == '') {
      pathnames.splice(i, 1);
      console.debug('removed extra path param');
      continue;
    }
  }
  for(let idx in pathnames) {
    let l = document.createElement('a');
    l.innerText = pathnames[idx];
    l.dataset.pathid = pathids[idx];
    docbchead.appendChild(l);
    if (idx < pathnames.length - 1) {
      docbchead.appendChild(document.createTextNode('>'));
      l.onclick=(e)=>{
        let clickidx = pathids.indexOf(e.target.dataset.pathid);
        let localpathids = pathids.slice(1,clickidx+1);
        localpathids = ['documents'].concat(localpathids);
        let localpathnames = pathnames.slice(1,clickidx+1);
        r.loadPage(localpathids.join("/"), {
          multiSelectPanel: isDropboxDirectory() ?[] :multiSelectPanel.selectedItems,
          path:localpathnames,
          gDrivePath: Utility.getPageParam('gDrivePath')
        });
      }
    }
    $('#dropdownmenuitem')[0].onclick = function(e) {
      if(e.target.tagName === 'A') {
        $(this).attr('aria-hidden', 'true');
        $(this).removeClass('is-open');
      }
    }
  }
  /* Hide search icon on parent focus */
  $('#doc-table_filter input, .contact-search input').focus(function() {
    $(this).parent().addClass('focused');
  }).blur(function() {
    $(this).parent().removeClass('focused');
  });
  path = path.length && ['Dropbox','gDrive', 'Box'].includes(path[0]) ? path[0] : Object.values(croogloocurrentpage.urlParams);
  binds(path);  
}

function restoreRegularFolder(smtFolder) {

  let htmlTemplate = document.getElementById('newfoldermodal'); 
  $('#addFilterLink').show();
  $('.smart-folder-only').hide();
  $('#smart-folder-title').hide();
  $('#smart-folder-title').text(i18n.t("folder.smart.new"));
  $('#regular-folder-title').show();
  $('#newFolderNameContainer').show();
  $('#folderNameContainer').css('paddingBottom', '50px');

  swal({
    type: 'info',    
    title: i18n.t("folder.reg.new"),       
    showCancelButton: true,
    showConfirmButton: true,
    showCloseButton: true,
    allowOutsideClick: true,
    confirmButtonText: i18n.t("button.confirm"),
    cancelButtonText: i18n.t("button.cancel"),
    useRejections: true,
    expectRejections: true,
    html: htmlTemplate.innerHTML,
    onOpen: () =>{

      $('.modal-header').css('paddingBottom', '0px');
      //Traversing the swal to get to the correct addFilterLink       
      let addFilterLink = document.querySelector('#swal2-content').querySelector("#addFilterLink");  
      addFilterLink.addEventListener('click', () =>{ 
        $(addFilterLink).fadeOut(400);
        $('#swal2-title').fadeOut(400);        
        $('.modal-header').fadeOut(400);
        $('.modal-header').css('paddingBottom', '40px');       
        setTimeout(function() {         
          $('.smart-folder-only').slideDown(800); 
          $('#newFolderNameContainer').slideDown(800);
          $('.modal-header').text(i18n.t("folder.smart.desc")); 
          $('.modal-header').fadeIn(800)  
          $('#swal2-title').text(i18n.t("folder.smart.new"));
          $('#swal2-title').fadeIn(800);
        }, 400);
      });        
    },

    onClose: () =>{
      $('.modal-header').text("");//Removing the smart folder text
      $('.modal-header').css('paddingBottom', '40px');     
    },
    
    preConfirm: () =>{      

      return new Promise(function (resolve, reject) {       
        let fileName = document.querySelector('#swal2-content').querySelector("#newfoldernameinput").value;         

        if($('.smart-folder-only').is(':visible')){//Checking for modification in at least one filter
          let tempFilters = smtFolder.parse();
          if(tempFilters == null){
            reject(i18n.t("js.smartfolder.reject.filter"));            
          }         
        }       
        if (fileName == "" ||fileName == null) {         
          reject(i18n.t("js.smartfolder.reject.file-name"));        
        }
        resolve();
      });
    }
  }).then(() =>{
    confirmBtn(smtFolder);   
  })  
}
//HERE
function showFolderFilterModal(template, smtFolder) {  
  $('#addFilterLink').hide();
  $('.smart-folder-only').show();
  $('#smart-folder-title').show();
  // "Review Filters" will be used to add back filters to a regular folder
  // and edit those of a smart folder.  ;
  $('#regular-folder-title').hide();
  $('#newFolderNameContainer').hide();
  $('#folderNameContainer').css('paddingBottom', '0px');
  
  swal({
    type: 'info',    
    title: i18n.t("docs.menu.filters.rev"),   
    showCancelButton: true,
    showConfirmButton: true,
    showCloseButton: true,
    allowOutsideClick: true,
    confirmButtonText: i18n.t("button.confirm"),
    cancelButtonText: i18n.t("button.cancel"),
    useRejections: true,
    expectRejections: true,
    html: template.innerHTML,
    onClose: () =>{
      $('.modal-header').text("");//Removing the smart folder text
    }
  }).then(() =>{
    confirmBtn(smtFolder);   
  });
  $('.modal-header').text(i18n.t("folder.smart.desc")); 
}

function confirmBtn(smtFolder){
  console.debug('oknewfoldermodal clicked, trigger type is ' + lastFolderModalTriggerType);
  if(lastFolderModalTriggerType == 'filter-edit') {
    if(smartFolderInEditing === null) {
      console.warn('smartFolderInEditing filters are still loading; ignoring click event');
      return;
    }

    let selectedItem = mainList.getById(
      (lastClickedMenuTarget === singleSelectPanel.fileOptionMenuBtn 
        ?singleSelectPanel.corresponding3DotMenu 
        :lastClickedMenuTarget
      ).closest('tr').getAttribute('item-id'));
    console.debug('selected item for filter toggle is ', selectedItem);
    let filters = {};
    smtFolder = smartFolderInEditing;
    filters = smtFolder.parse();
    if(filters == null) { // invalid filters       
      return;
    }
    selectedItem.editDocumentFolder({
      folderId: selectedItem.itemid,
      isSmartFolder: true
    }, filters);
    return;
  }
  let isSmartFolder = $('.smart-folder-only').is(':visible');
  let filters = {};
  if(isSmartFolder) {
    filters = smtFolder.parse();
    if(filters == null) { // invalid filters        
      return;
    }
  }

  var inputnewfolder = document.querySelector('#swal2-content').querySelector("#newfoldernameinput");
  if (inputnewfolder.value.trim() == '') {
    inputnewfolder.focus();      
  } else {
    var tmp = new Object();
    var dirlist = location.hash.replace("#", "").split("/");
    var currentFolderId = dirlist.length > 1 ? dirlist[dirlist.length - 1] : 'ROOT';
    tmp.parents = currentFolderId;
    tmp.folderName = inputnewfolder.value.trim();
    tmp.isSmartFolder = isSmartFolder = (isSmartFolder && Object.keys(filters).length !== 0);
    tmp.page = 'menu_documents';
    console.debug(filters);
    DocUtility.removeCardViews(currentFolderId);
    showSpinner();
    apicall('documentsapi', 'addDocumentFolder', tmp, filters).then(function(resp) {
      try {
        
        let folderName = resp.entity.properties.fileName;
        let addedItem = new FileListItem(resp.entityId, 'folder', 
          isSmartFolder ?'smartFolder' :'none',
          resp.entityId, folderName, 
          resp.entity.properties.timeCreated);
        mainList.addFileListItem(addedItem);
        upload.folderCollection.push({
          id: resp.entityId,
          parent: currentFolderId,
          text: folderName,
          icon: "jstree-folder"
        });
        if(isSmartFolder) {
          upload.smartFolders.push(resp.entity.properties);
        }
        hideSpinner();
      } catch(err1) {
        console.error(err1);
        console.error('failed to dynamically add new folder (e1)');
        // Dynamic insertion failed, reloading to fix display
        r.reload();
      }
    }).catch(() => {
      console.error('failed to dynamically add new folder (e2)');
      hideSpinner();
      // Failure at API level that reloading won't solve, informing user
      cgToast(i18n.t("js.documents.folder.add.failed"), { className: 'error-toast' });
    });
  }

}

function fetchFolderFilters(itemId) {
  return new Promise(resolve => {
    apicall('documentsapi', 'fetchFolderFilters', { fileId: itemId })
    .then(resp => {
      if(typeof resp == 'object') {
        resolve(resp);
      } else {
        throw new Error('invalid server response');
      }
    }).catch(err => {
      console.error(err);
      resolve({});
    });
  });
}

async function binds(path) {
  var currentFolderId = window.activeFolderId = ['Dropbox','gDrive', 'Box'].includes(path[0])?null:path.length>0?path[path.length-1]:'ROOT';
  // must be created before we set new folder listeners or the folder creation callback could 
  // technically fail (provided the Upload component took a very long time to load)
  upload = await new Upload(currentFolderId);
  
  // new folder button
  let smartFolder;
  let newfolderbtn = document.getElementById("newfolderbtn");

  newfolderbtn.onclick = (event) => {
    lastFolderModalTriggerType = 'new-folder';
    var inputnewfolder = document.getElementById("newfoldernameinput");
    inputnewfolder.value = "";
    if (Array.isArray(path) && path[0] == 'gDrive' || typeof path == 'string' && path == 'Dropbox' || typeof path == 'string' && path == 'Box') {
      cgToast(i18n.t("js.documents.folder.3rd-party.denied", {thirdParty: (typeof path == 'string' && path == 'Dropbox' ? 'Dropbox': typeof path == 'string' && path == 'gDrive' ? 'Google Drive' : 'Box')}));
      event.stopPropagation();
    }
    let htmlTemplate = document.getElementById('newfoldermodal')
    smartFolder = new SmartFolder(htmlTemplate);
    restoreRegularFolder(smartFolder);
  }
  newfolderbtn.classList.remove('disabled');  

  selectAllFiles = document.getElementById("selectAllBtn");
  //event handler to select all files displayed on the screen, can be tailor to select all folder too.
  selectAllFiles.onclick = (event) => {

    if (selectAllFiles.checked) {
      let docRowSelector = '#doc-table > tbody > tr';
      let allTableItems = document.querySelectorAll(docRowSelector);//all elements in the file table

      for (let selectedRow of document.querySelectorAll('#doc-table tr.selected-document')) {
        selectedRow.classList.remove('selected-document');
        multiSelectPanel.removeSelectedItem(selectedRow.id);
      }

      for (let row of allTableItems) {
        if (row.dataset.type != "dblink" && row.dataset.itemdata != "PHOTO_ROOT") {
          row.classList.add('selected-document');
          let itemid = row.getAttribute('item-id');
          
          multiSelectPanel.addSelectedItem(row.id,
            mainList.getById(itemid || row.id) || { id: row.id, itemid: itemid });
        }
      }
      
      singleSelectPanel.hide();
      docTableSelectables.disable()
      event.stopPropagation();
      multiSelectPanel.show();

    } else {

      singleSelectPanel.hide();
      docTableSelectables.enable();
      for (let selectedRow of document.querySelectorAll('#doc-table tr.selected-document')) {//Removing selected elements when unchecked
        selectedRow.classList.remove('selected-document');
        multiSelectPanel.removeSelectedItem(selectedRow.id);
      }     
      event.stopPropagation();
      multiSelectPanel.hide();
    }
  }
  let docWrap = document.getElementById('filelist')
  docWrap.onclick = () => {
    if (!docTableSelectables.on) {
      docTableSelectables.enable();
    }
    selectAllFiles.checked = false;
  }
  
  // add file
  var addfilebtn = document.getElementById("uploadbtn");
  addfilebtn.onclick = (event) => {
    if (window.isNonCrooglooDirectory) {
      cgToast(i18n.t("js.documents.upload.3rd-party.denied",{thirdParty: (typeof path == 'string' && path == 'Dropbox' ? 'Dropbox': typeof path == 'string' && path == 'gDrive' ? 'Google Drive' : 'Box')}));
      event.stopPropagation();
    } else {
      document.getElementById('formupload').reset();
      var inputfile = document.getElementById('inputfileupload');
      inputfile.onchange = (event)=>{
        uploadFiles(inputfile.files, currentFolderId);
      }
      inputfile.click();
    }
  };
}

async function publishToStudioSwal(self) {

  let selectedCloudProvider = null
 
  swal({
    type: 'info',    
    title: i18n.t("docs.menu.import.cloud"),       
    showCancelButton: true,
    showConfirmButton: true,
    showCloseButton: true,
    allowOutsideClick: true,
    confirmButtonText: i18n.t("button.confirm"),
    cancelButtonText: i18n.t("button.cancel"),
    html: 
      '<select style="height: 45px; margin: 0;" id="select_provider">'
        +'<option value=""> Select Studio Destination </option>'
        +'<option value="BOX">Box</option>'
        +'<option value="GDRIVE">Dropbox</option>'
        +'<option value="DBOX">Google Drive</option>'
        +'<option value="SHAREPOINT">SharePoint</option>'
      +'</select>'
    ,
    onOpen: () =>{

      try {
        singleSelectPanel.clearSelected()
        singleSelectPanel.hide()
      }
      catch(err) {
        console.log(err)
      }
      
      if(JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken !== null && JSON.parse(global.secureLocalStorage.get('crooglooauth')).hasOwnProperty('boxRefreshToken')) {
        document.getElementById('select_provider').value = "BOX"
        $('.swal2-confirm.swal2-styled').css('background-color', 'rgb(19, 196, 106)')
        selectedCloudProvider = "BOX"
      }else {

        $('.swal2-confirm.swal2-styled').css('background-color', '#aaa')
      }


      $('.swal2-content').css('padding', '15px 30px')

      $('#select_provider').on('change', async (e) => {
        let cloudProvider = e.target.value
        

        if(cloudProvider === "BOX") {
          
          selectedCloudProvider = "BOX"

          if(JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken !== null && JSON.parse(global.secureLocalStorage.get('crooglooauth')).hasOwnProperty('boxRefreshToken')) {
            $('.swal2-confirm.swal2-styled').css('background-color', 'rgb(19, 196, 106)')            
          }
          else {
            var w = 450;
            var h = 600;
            var left = 0;
            var top = 0;

            window.open("/box.html", "_blank",
            'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width='
            + w + ',height=' + h + ',top=' + top + ',left=' + left);
          }
        }
        else {
          $('.swal2-confirm.swal2-styled').css('background-color', '#aaa')
          selectedCloudProvider = null
        }
      })
    },

    onClose: () =>{
         
    },
    
    preConfirm: () =>{      

      return new Promise(function (resolve, reject) {       
        if(JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken !== null && JSON.parse(global.secureLocalStorage.get('crooglooauth')).hasOwnProperty('boxRefreshToken')) {
          if(selectedCloudProvider !== null) {
            resolve()
          }
        }
        reject()
      });
    }
  }).then(async (isConfirmed) =>{
      if(isConfirmed && selectedCloudProvider !== null) {
        let boxRefreshToken = JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken

        let id = ""

        if(self.hasOwnProperty('itemid') || self.hasOwnProperty('_ID')) {
          id = self.hasOwnProperty('itemid') ? self.itemid : self._ID
        }
        else {
          let filteredIds = []
          Object.values(self).forEach(item => {
            if (item.hasOwnProperty('itemid')) {
              let elem = document.querySelector(`[item-id="${item.itemid}"]`)
              if(elem.querySelector('.pts-flag') === null) {
                filteredIds.push(item.itemid)
              }
            }
            else if(item.hasOwnProperty('item._ID')) {
              let elem = document.querySelector(`[item-id="${item._ID}"]`)
              if(elem.querySelector('.pts-flag') === null) {
                filteredIds.push(item._ID)
              }
            }
          })

          id = filteredIds.join(',')
        }
        if(id !== "") {
          showSpinner()
          await croogloo_auth.box(boxRefreshToken, true)
          apicall(
            'documentsapi', 
            'publishToStudio', 
            {"cloudProviderName": "BOX", "authToken": croogloo_auth.crooglooauth.boxToken}, 
            {"value": id})
            .then(resp => {
              if(resp.responseCode === '0') {
                  swal("Published", "", "success")
              }
              else {
                swal("Please try again later", "", "error")
              }
              hideSpinner()
            })
            .catch(err => {
              hideSpinner()
              console.log(err)
            })
        }
        else {
          swal("Upload Failure", "These documents have already been uploaded to Box ", "error")
          hideSpinner()
        }
      }
  })  
  .catch(isCancelled => {
    
  })
}

function uploadFiles(files, parentFolderId) {
  var thepage = window.croogloocurrentpage;
  upload.parentFolderId = parentFolderId;
  let duplicateFile = new Object()
  duplicateFile.duplicate = false
  upload.toGoogleCloudStorage(files, duplicateFile).then((result) => {
    console.debug('upload success', result);

    if (thepage === window.croogloocurrentpage) {
      let areFileRequestsCancelled = false;
      let currentFolderId = getCurrentFolderId();
      for(let file of result) {
        try {
          if(file.id.toLowerCase().endsWith('.zip')) {
            try {
              console.debug(`skipping ZIP file ${file.id} from dynamic file insertion process`);
              cgToast(i18n.t("js.documents.zip.extraction", {filename: getValidFileName(file)}), {
                hiding: 'manual',
                showCloseButton: true      
              });
            } catch(err0) {
              console.error(err0);
            }
            continue;
          }
          if(!file.parents.split(',').includes(currentFolderId)) {
            console.debug(file.id + ' was not uploaded to the active directory and will not be added dynamically');
            continue;
          }
          apicall('documentsapi', 'fetchDocumentById', { fileId: file.id }).then(resp => {
            if (resp && resp.kind == 'DOCUMENT') {
              console.debug('successfully retrieved newly added document ' + file.id);
              if(!areFileRequestsCancelled) {
                mainList.addFileListItemFromEntity(resp);
              }
            } else {
              throw new Error('invalid server response');
            }
          }).catch(() => {
            areFileRequestsCancelled = true;
            console.error("failed to retrieve newly added document with ID " + file.id);
            // Failed to retrieve individual item, will reload to load the full list instead
            r.reload();
          });
        } catch(err1) {
          console.error(err1);
          console.error('failed to dynamically add new item');
          // Dynamic update failed, reloading to fix display
          r.reload();
          return;
        }
      }
    }
  },(errFileName) => {
    console.error('failed upload ' + errFileName)
    cgToast(i18n.t("js.utils.something.wrong"), { className: 'error-toast' });
  });
}

class CrooglooFileList {
  constructor(path) {
    selectAllFiles = document.getElementById("selectAllBtn");
    this.path = path;
    this.itemlist = null;
    this.target = document.getElementById('filelist');
    $(this.target).empty();
    // this.target.onclick = this.clickevent();
    this.selectedids = [];
    //document.getElementById('mergebtn').onclick = (e)=>{this.merge()};
  }
  
  getById(id) {
    return this.itemlist.find(item => item.itemid == id || item.id == id) || null;
  }

  getIndexById(id) {
    return this.itemlist.findIndex(item => item.itemid == id || item.id == id);
  }

  /**
   * Adds a file or folder to the item list, 
   * renders it, updates the DataTable and all 
   * listeners. 
   * 
   * @param {FileListItem} item 
   */
  addFileListItem(item) {
    this.itemlist.push(item);
    item.render(this.target);
    item.getDatatable()
      .rows.add([document.getElementById(item.id)])
      .draw();
    docTableSelectables.refresh();
  }

  /**
   * Converts a DOCUMENT type CrooglooEntity representing a file into a FileListItem, 
   * before adding it via addFileListItem(item). Folders are not currently supported.
   * 
   * @param {CrooglooEntity} fileEntity A croogloo entity reprensenting a file. 
   * Folders are not currently supported.
   */
  addFileListItemFromEntity(fileEntity) { // TODO update to support folders
    if((fileEntity.properties.subType || '').toLowerCase() === 'folder') {
      throw new Error('folders are not currently supported by addFileListItemFromEntity(fileEntity)');
    }
    this.addFileListItem(new FileListItem(fileEntity.key.name, 'file',
      'none', '', fileEntity.properties.fileName,
      new Date(parseInt(fileEntity.properties.lastModifBaseTime)).toISOString(),
      fileEntity.properties.limitTo, fileEntity.properties.subcategory,
      fileEntity.properties.isWatermarked === '1', fileEntity.properties.watermarkWithEncryption === '1'));
  }

  /**
   * Deletes file list items locally and removes the cached active directory, if any, 
   * from the browser's local storage. The corresponding DOCUMENT entities won't be 
   * removed from the database.
   * 
   * @param {String Array} itemIds The list of item IDs to delete locally.
   */
  deleteFileListItems(itemIds) {
    DocUtility.removeCardViews(getCurrentFolderId());
    for(let itemId of itemIds) {
      try {
        let itemIndex = this.getIndexById(itemId);
        let item = this.itemlist[itemIndex];
        // note that item.id is not necessarily equal to itemId
        let itemRow = document.getElementById(item.id);
        if(this.lastNonShiftClickedElem instanceof HTMLElement && this.lastNonShiftClickedElem.id === itemRow.id) {
          delete this['lastNonShiftClickedElem'];
        }
        item.removeRow();
        // note that item.id is not necessarily equal to itemId
        multiSelectPanel.removeSelectedItem(item.id);
        singleSelectPanel.clearSelected();
        singleSelectPanel.hide();
        docTableSelectables.refresh();
        this.itemlist.splice(itemIndex, 1);
      } catch (err) {
        console.error(err);
        console.error(`Failed to delete item with ID ${itemId}. Will reload.`);
        // dynamic deletion failed, reloading to fix display
        r.reload();
        break;
      }
    }
  }

  /**
   * Overwrites a FileListItem in the itemlist with a new one.
   * 
   * @param {String} itemId The itemId property of the FileListItem to overwrite.
   * @param {FileListItem} newFileListItem The new item that will overwrite the other.
   */
  overwriteFileListItem(itemId, newFileListItem) {
    this.deleteFileListItems([itemId]);
    this.addFileListItem(newFileListItem);
  }

  merge() {
    var self = this;
    if (self.selectedids.length > 1) {
      var isFileOverwriteAccepted = false;
      var lastChosenFileName = '';
      let currentFolderId = getCurrentFolderId();
      var mergedFileEntity;

      swal.mixin({
        confirmButtonText: i18n.t("button.next")+' &rarr;',
        confirmButtonColor: '#13C46A',
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        progressSteps: ['1', '2'],
        animation: false,
        useRejections: false,
        expectRejections: false
      }).queue([
        {
          title: i18n.t("js.documents.merge.order.title"),
          html: generateMergeOrderHtml(),
          animation: true,
          showCloseButton: true,
          allowOutsideClick: false,
          onBeforeOpen: function() {
            $('.order-arrow').off('click');
            $('.order-arrow').on('click', function() {
              let fileDiv = this.closest('div.merging-file-name');
              if(this.classList.contains('order-arrow-down')) {
                if(fileDiv.nextSibling) {
                  if(fileDiv.nextSibling.nextSibling) {
                    fileDiv.parentElement.insertBefore(fileDiv, fileDiv.nextSibling.nextSibling);
                  } else {
                    fileDiv.parentElement.appendChild(fileDiv);
                  }
                }
              } else if(this.classList.contains('order-arrow-up')) {
                if(fileDiv.previousSibling) {
                  fileDiv.parentElement.insertBefore(fileDiv, fileDiv.previousSibling);
                }
              }
            });
          },
          onClose: function(modal) {
            self.selectedids = [];
            [].forEach.call(document.querySelectorAll('div.merging-file-name'), fileDiv => {
              self.selectedids.push(fileDiv.getAttribute('fileid'));
            });
            console.debug(self.selectedids);
          }
        },
        {
          title: i18n.t("js.documents.merged.title"),
          input: 'text',
          inputPlaceholder: i18n.t("js.documents.merged.placeholder"),
          showCancelButton: true,
          cancelButtonText: i18n.t("button.cancel"),
          confirmButtonText: i18n.t("button.submit"),
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          showCloseButton: true,
          preConfirm: function(text) {
            text = text.trim();
            return new Promise(function(resolve, reject) {
              try {
                if(text !== lastChosenFileName) {
                  isFileOverwriteAccepted = false;
                }
                lastChosenFileName = text;
                var unsupportedCharacters = text.search(/[\<\>\:\"\/\\\|\?\!\%\~,\*\#\$]+/);
                if (unsupportedCharacters >= 0) {
                  throw i18n.t("js.documents.merged.reject.characters", {characters: '(<,>,:,",/,\,|,?,!,%,~,,,*,#,$)'});
                } else if (text === '') {
                  throw i18n.t("js.documents.merged.reject.name");
                } else {
                  var pckgFileIdList = [];
                  for (var idx in self.selectedids) {
                    var item = self.getById(self.selectedids[idx]);
                    if(item) {
                      pckgFileIdList.push(item.itemid);
                    }
                  }
                  // removing signed url and validating PDF type
                  for (var i = 0; i < pckgFileIdList.length; i++) {
                    pckgFileIdList[i] = decodeURI(pckgFileIdList[i].split("?")[0]);
                    // TODO This error thrown is a temporary solution. The option to 
                    // TODO merge shouldn't even be offered when a non-PDF file is selected. 
                    if(!pckgFileIdList[i].toLowerCase().endsWith('.pdf')) {
                      throw i18n.t("js.documents.merged.reject.pdf");
                    }
                  }
                  var tmp = {
                    filesIdToMerge: pckgFileIdList.join('~'),
                    mergedFileName: text,
                    destFolderId: currentFolderId,
                    isFileOverwriteAccepted: isFileOverwriteAccepted
                  }
                  DocUtility.removeCardViews(currentFolderId);
                  apicall('documentsapi', 'mergeFiles', tmp).then(function(resp) {
                    if (!resp) {
                      throw i18n.t("js.documents.merged.reject.error");
                    } else {
                      if (resp.responseCode === '0') {
                        mergedFileEntity = resp.entity;
                        resolve();
                      } else if (resp.responseCode === '-1') {
                        throw resp.responseMessage;
                        isFileOverwriteAccepted = true;
                      }
                    }
                  }, (err) => {
                    throw err;
                  }).catch(err => {
                    swal.hideLoading();
                    swal.showValidationError(typeof err == 'string' ?err :i18n.t("js.documents.merged.reject.unknown"));
                  });
                }
              } catch(e) {
                console.error(e);
                reject((typeof e == 'string' && e.trim().length > 0) ?e :i18n.t("js.utils.something.wrong"));
              }
            }).catch(swal.showValidationError);
          }
        }
      ]).then((result) => {
        console.debug(result);
        if(!result.hasOwnProperty('dismiss')) {
          try {
            let currentFolderName = document.querySelector(`#docbchead a[data-pathid="${currentFolderId}"]`).textContent;
            let mergedFileName = mergedFileEntity.properties.fileName;
            swal({
              title: i18n.t("js.documents.merged.success.title"),
              html: i18n.t(
                "js.documents.merged.success.html", 
                {mergedFileName:(mergedFileName || ''), currentFolderName: (currentFolderName || "$t(js.documents.merged.current)")}
              ),
              type: 'success',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: i18n.t("OK"),
              confirmButtonColor: '#13C46A',
              allowOutsideClick: true
            }).catch(swal.noop);
            mainList.addFileListItemFromEntity(mergedFileEntity);
          } catch(err) {
            console.error(err);
            // Dynamic insertion failed, reloading to fix display
            r.reload();
          }
        }
        mainList.unselectall();
        e.panel.clearSelected();
      });

      function generateMergeOrderHtml() {
        let selectedFiles = [];
        self.selectedids.forEach(fileId => {
          self.itemlist.forEach(item => {
            if(item.itemid === fileId) {
              selectedFiles.push(item);
            }
          });
        });
        let container = document.createElement('div');
        container.style.textAlign = 'left';
        selectedFiles.forEach(file => {
          let fileDiv = document.createElement('div');
          fileDiv.className = 'merging-file-name';
          fileDiv.setAttribute('fileid', file.itemid);
          fileDiv.innerHTML = '<i class="order-arrow order-arrow-down">\u{02193}</i>'
            + '<i class="order-arrow order-arrow-up">\u{02191}</i>&nbsp;'
            + file.name;
          container.appendChild(fileDiv);
        });
        return container.outerHTML;
      }

    } else {
      cgToast(i18n.t("js.documents.merge.atleast.two"));
    }
  }

  static hasFileName(item) {
    if(item.hasOwnProperty('fileName') && item.fileName !== "" && item.fileName !== undefined && item.fileName !== null) {
      return true
    }

    return false
  }

  fetch() {
    var self = this;
    return new Promise((done, err) => {
      DocUtility.getStoredCardViews(self.path, storedItemList => {
        self.update({
          itemlist: storedItemList.map(item => new FileListItem(item.itemid, item.type, 
            item.subType, item.data, item.name, item.datemod, replaceDHInShared(item.shared), item.category, 
            item.watermark, item.isEncrypted, item.archived, item.isPublishedToStudio, item.cloudProvider))
          });
          done();
        }, fetchCardViewDocuments);
      function fetchCardViewDocuments() {
        showLoadingScreen();
        var tmp = new Object();
        tmp.getReportsOnly = false;
        tmp.parentFolder = self.path;
        apicall('viewAPI', 'fetchCardViewDocuments', tmp).then((resp) => {

          console.log("resp: ", resp)
          console.debug('card view resp:');
          console.debug(resp);
          var itemlist = [];
          for (var idx in resp.items) {
            let item = resp.items[idx];
            var id = item.entityId;
            var type = item['line05'] == 'Folder' ? 'folder' : 'file';
            let subType = item.isSmartFolder ?'smartFolder' :'none';
            var shared = replaceDHInShared(item.line09);   
            var data = '';
            if (type === 'folder') {
              data = item.entityId;
            }
            var isPublishedToStudio = false
            var cloudProvider = null
            var uploadedSuccessfully = true

            if(!CrooglooFileList.hasFileName(item)) {
              uploadedSuccessfully = false
            }

            if(item.hasOwnProperty('isPublishedToStudio')) {
              isPublishedToStudio = item.isPublishedToStudio
            }
            if(item.hasOwnProperty('cloudProvider')) {
              cloudProvider = item.cloudProvider
            }
            var name = item.fileName;
            var datemod = item.line03 || '';
            var cat = item.line10;
            var wm = item.line07 == '1' ?true :false;
            let isEncrypted = item.line12 == '1' ?true :false;
            let isArchived = item.isArchived;
            itemlist.push(new FileListItem(id, type, subType,
              data, name, datemod, shared, cat, wm, isEncrypted, isArchived, isPublishedToStudio, cloudProvider, uploadedSuccessfully));
          }
          DocUtility.storeCardViews(self.path, itemlist.map(item => {
            return {
              itemid: item.itemid,
              type: item.type,
              subType: item.subType,
              data: item.data,
              name: item.name,
              datemod: item.datemod,
              shared: replaceDHInShared(item.shared),
              category: item.category,
              watermark: item.watermark,
              isEncrypted: item.isEncrypted,
              archived: item.archived,
              isPublishedToStudio: item.isPublishedToStudio,
              cloudProvider: item.cloudProvider,
              uploadedSuccessfully: uploadedSuccessfully
            }
          }));
          self.update({itemlist: itemlist});
          done();
        });
      }
    });
  }
  update(data) {
    if (typeof data === 'object') {
      for (var idx in data) {
        this[idx] = data[idx];
      }
      this.render();
    }
    if(singleSelectPanel != null) {
      singleSelectPanel.update(data);
    }
  }
  render() {
    $(this.target).empty();
    if (this.path == 'ROOT') {
      new FileListItem('dropboxfolder', 'dblink', 'none', '', 'Dropbox', '', i18n.t("js.documents.shared.not")).render(this.target);
      new FileListItem('boxfolder', 'boxlink', 'none', '', 'Box', '', i18n.t("js.documents.shared.not")).render(this.target);
      new FileListItem('gDriveFolder', 'gDriveLink', 'none', '', 'Google Drive', '', i18n.t("js.documents.shared.not")).render(this.target);
    }    
    
    for(let item of this.itemlist) {      
      item.render(this.target);
    }
    this.refreshWatermarkLabelListeners();
  }
  refreshWatermarkLabelListeners() {
    let self = this;
    $('span.watermarked-file > .wm-flag').off('click');
    $('span.watermarked-file > .wm-flag').on('click', function(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      self.showWatermarkDetails(this.getAttribute('itemid'));
    });
  }
  showWatermarkDetails(itemid) {
    let item = this.getById(itemid);    
    if(item !== null) {
      swal({
        title: i18n.t("js.documents.wtmrk.fetch.title"),
        text: i18n.t("js.documents.wtmrk.fetch.text"),        
        showConfirmButton: false,
        showCancelButton: false,      
        useRejections: true, //important for swal2 v7.1.2
        expectRejections: true,
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            let errMsg = i18n.t("js.documents.wtmrk.fetch.failed");
            apicall('documentsapi', 'fetchWatermarkSettings', {fileId: itemid}).then(resp => {
              console.debug(resp);
              if(typeof resp == 'object' && resp.hasOwnProperty('isEncrypted')) {
                resolve(resp);
              } else {
                reject(errMsg);
              }
            }).catch(() => reject(errMsg));
          });
        },
        onOpen: swal.clickConfirm
      }).then(function(resp) {
        item.changeWatermark(i18n.t("js.documents.wtmrk.fetch.title"), false, resp.firstLine, resp.secondLine, resp.isEncrypted === '1');
      }).catch(swal.noop);
    }
  }
  unselectall() {
    var self = this;
    self.selectedids = [];
    for(let selectedRow of document.querySelectorAll('#doc-table tr.selected-document')) {
      selectedRow.classList.remove('selected-document');
      multiSelectPanel.removeSelectedItem(selectedRow.id);
      delete mainList['lastNonShiftClickedElem'];
    }
    selectAllFiles.checked = false;
  }
  reselect() {
    console.error('reselect unsupported');
  }
  deleteall() {
    var self = this;
    var selectedItemName = [];
    let selectedItemsValues = Object.values(multiSelectPanel.selectedItems);
    for(let itemid of this.selectedids) {
      selectedItemName.push(selectedItemsValues
        .find(o => o.itemid == itemid).name);
    }
    swal({
      title: i18n.t("js.documents.delete.title"),
      html: i18n.t("js.documents.delete.html", {name:selectedItemName[0], count:selectedItemName.length}),
      type: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#BC2121',
      confirmButtonText: i18n.t("utils.delete"),
      cancelButtonText: i18n.t("button.cancel"),
      allowOutsideClick: true,
    }).then(function () {
      let allItemsId = [];
      for (var idx in self.selectedids) {
        allItemsId.push(self.selectedids[idx]);
      }
      let localpath = location.hash.replace('#','').split('/');
      localpath[0] = 'ROOT';
      var tmp = new Object();
      tmp.parentId = localpath[localpath.length-1];
      tmp.page = 'menu_documents';
      tmp.removeAllCopies = false;
      console.debug(tmp);
      showSpinner();
      DocUtility.removeCardViews(getCurrentFolderId());
      apicall('documentsapi', 'deleteItemAndChildren', tmp, {
        value: allItemsId.join(',')
      }).then(() => {
        hideSpinner();
        mainList.deleteFileListItems(allItemsId);
        // TODO if upload not ready, wait
        // TODO update folder list and smart folder list for all filesId that represent a folder
        multiSelectPanel.hide();
      });
      mainList.unselectall();
      e.panel.clearSelected();
    }, function (dismiss) {
      if (dismiss === 'cancel' || dismiss === 'overlay') {//can close on overlay click
        swal({
          title: i18n.t("js.documents.delete.cancelled.title"),
          text: i18n.t("js.documents.delete.cancelled.text"),
          type: 'info',
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("OK"),
          showCloseButton: true,
          allowOutsideClick: true          
        })
      }
      mainList.unselectall();
      e.panel.clearSelected();
    }).catch(swal.noop);
  }
  accesslevelall() {
    var self = this;
    getSharedField(null, function(sharedHtmlInput) {
      swal({
        type: "info",
        title: i18n.t("js.documents.access.title"),
        html: '<form class="sharedInputForm">' + sharedHtmlInput + '</form>',        
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: i18n.t("accept"),
        cancelButtonText: i18n.t("button.cancel"),
        confirmButtonColor: '#13C46A',
        cancelButtonColor: '#F44336',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !swal.isLoading(),
        allowEscapeKey: false,  
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            var chk = [];
            $('#sharedInput').children().each(function(){
              if($(this).is(':checked')) {
                chk.push($(this).attr('id'));
              }
            });
            let limitTo;
            if(chk.length > 0){
              limitTo = chk;
            } else {
              limitTo = ["ADMIN"];
            }
            
            var tmp = new Object();
            tmp.limitToType = 'securityList';
            tmp.isRecursive = false; // only applies to files here so recursive not needed
            apicall('documentsapi', 'changeAccessLevel', tmp, {
              props: {
                ids: self.selectedids.join(','),
                limitTo: limitTo.join(',')
              }
            }).then(function(resp){
              if(resp.responseCode === '0') {
                resolve(limitTo);
              } else {
                throw new Error('invalid server response');
              }
            }).catch(() => {
              reject(i18n.t("js.utils.something.wrong"));
              return;
            });
          });
        }
			}).then(function(limitTo) {
        swal({
          type: 'success',
          html: i18n.t("js.documents.access.changed", {people: limitTo.join(', ')}),
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("OK"),
          allowOutsideClick: true
        }).catch(swal.noop);
        DocUtility.removeCardViews(getCurrentFolderId());
        try {
          let sharedGroupString = limitTo.join(',');
          for(let item of self.selectedids.map(id => self.getById(id))) {
            item.update({ shared: sharedGroupString });
          }
        } catch(err) {
          console.error('dynamic access level update failed');
          console.error(err);
          // Dynamic update failed, reloading to fix display
          r.reload();
        }
        mainList.unselectall();
        e.panel.clearSelected();
			}).catch(swal.noop);
		});
  }
  moveupall() {
    var localpath = location.hash.replace('#','').split('/');
    localpath[0]='ROOT';
    if (localpath.length > 1){
      var oldparent = localpath[localpath.length-1];
      var newparent = localpath[localpath.length-2];
      let allSelectedIds = [];
      for (var idx in this.selectedids){
        allSelectedIds.push(this.selectedids[idx]);
      }
      var tmp = new Object();
      tmp.oldParentFolderId = oldparent;
      tmp.newParentFolderId = newparent;
      tmp.filesId = allSelectedIds.join(',');
      DocUtility.removeCardViews(`${oldparent},${newparent}`);
      showSpinner();
      apicall('documentsapi', 'changeParentFolder', tmp).then(() => {
        hideSpinner();
        mainList.deleteFileListItems(allSelectedIds);
        // TODO if upload not ready, wait
        // TODO update folder list and smart folder list for all filesId that represent a folder
      }).catch(hideSpinner);
    }
    this.unselectall();
  }
  async changeParentFolder() {
    let allSelectedIds = [];
    for(let idx in this.selectedids) {
      allSelectedIds.push(this.selectedids[idx]);
    }

    try {
      if (await upload.getUploadFolders({}, {}, false, true)) {
        try {
          let tmp = {};
          let localpath = location.hash.replace('#', '').split('/');
          tmp.oldParentFolderId = localpath.length == 1 ? 'ROOT' : localpath[localpath.length - 1];
          tmp.newParentFolderId = upload.selectedFolders[0].id;
          tmp.filesId = allSelectedIds.join(',');
          console.debug('changing parent folder');
          console.debug(tmp);
          DocUtility.removeCardViews(`${tmp.oldParentFolderId},${tmp.newParentFolderId}`);
          showSpinner();
          apicall('documentsapi', 'changeParentFolder', tmp).then(() => {
            hideSpinner();
            if (tmp.oldParentFolderId !== tmp.newParentFolderId) {
              mainList.deleteFileListItems(allSelectedIds);
              // TODO update folder list and smart folder list for all filesId that represent a folder
            }
          }).catch(showErrMsg);
        } catch (err1) {
          showErrMsg(err1);
        }
      }
    } catch (err2) {
      showErrMsg(err2);
    }

    function showErrMsg(err) {
      console.error(err);
      hideSpinner();
      swalToast({
        type: 'error',
        title: i18n.t("js.documents.change.folder.error")
      });
    }
    mainList.unselectall();
    e.panel.clearSelected();
  }

  changeArchiveStatus(items, archive){
    const many = (items instanceof Array);
    let success;
    if(archive === true){
      success = (item)=>item.archive();
    }else{
      success = (item)=>item.recover();
    }
    apicall('documentsapi', 'changeArchiveStatus', {archive: archive}, {
      value: (many) ? items.map(item => item.itemid).join(',') : items.itemid
    }).then( (resp)=>{
      if(resp && resp.responseCode && resp.responseCode === '0'){
        if(many){
          items.forEach(success);
        }else{
          success(items);
        }
        DocUtility.removeCardViews(getCurrentFolderId());
      }
    }).catch(err=>{
      console.error(err);
    });
  }

  downloadAsZIP() {
    if(this.data != null && (this.data.indexOf("www.dropbox.com") >= 0 || this.data.indexOf('google.com') >= 0)) {
      console.debug('tried to download non-Croogloo items as ZIP');
      cgToast(i18n.t("js.utils.unsupported.operation"));
      return;
    } else {
      console.debug('using primary download method');
      showSpinner();
      apicall('documentsapi', 'downloadItemsAsZip', {}, {
        value: this.selectedids.join(',')
      }).then(resp => {
        if(resp.responseCode === '0') {
          swal({
            title: i18n.t("response.success"),
            html: i18n.t("js.documents.zip.generating"),            
            type: 'success',
            allowOutsideClick: true
          }).catch(swal.noop);
          DownloadNotification.add(false, false, resp.responseMessage.split(",")[0], 
            resp.responseMessage.split(",")[1] || null, new Date().getTime());
        } else {
          throw new Error('server error');
        }
      }).catch(() => {
        cgToast(i18n.t("js.utils.something.wrong"));        
      }).then(hideSpinner);
    }
    mainList.unselectall();
    e.panel.clearSelected();
  }

  removeWatermarkToAll(){
    let that = this;
    swal({
      title: i18n.t("js.documents.menu.wtmrk.del"),
      html: i18n.t("js.documents.wtmrk.remove.text"),
      type: 'warning',
      input: 'text',
      inputClass: 'hidden-input', //used to prevent focus error on reject
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("yes"),
      cancelButtonText: i18n.t("no"),
      showCancelButton: true,
      showCloseButton: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
      preConfirm: function (text) {
        return new Promise(function (resolve, reject) {
          try {
            let addEncryption = document.getElementById('encryptChk') != null && document.getElementById('encryptChk').checked;
            console.debug('will now be updating the watermark properties of the following items: ' + that.selectedids.join(', '));
            that.updateWatermark(that.selectedids.map(id => that.getById(id)), 0, addEncryption, resolve, reject);
          } catch(err) {
            console.error(err);
            reject(i18n.t("js.utils.something.bad"));
          }
        });
      }
    }).then(function () {
      swal({
        title: i18n.t("js.documents.wtmrk.removed"),
        type: 'success',
        showCloseButton: true,
        confirmButtonText: i18n.t("close"),
        confirmButtonColor: '#13C46A',
        animation: true,
        allowOutsideClick: true
      }).catch(swal.noop);
      mainList.unselectall();
      e.panel.clearSelected();
    }).catch(swal.noop);
  }

  applyWatermarkToAll() {
    let that = this;
    if(this.data != null && (this.data.indexOf("www.dropbox.com") >= 0 || this.data.indexOf('google.com') >= 0)) {
      console.error('tried to apply watermark to non-Croogloo document items');
      cgToast(i18n.t("js.utils.unsupported.for-files"));
      return;
    } else {
      $('#dropdownmenuitem').foundation('close');
      resetWmGlobals();
      swal({
        title: i18n.t("js.documents.menu.wtmrk.add"),
        html: generateWatermarkingOption(),
        type: 'info',
        input: 'text',
        inputClass: 'hidden-input', //used to prevent focus error on reject
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("button.confirm"),
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        showCloseButton: true,
        background: '#fff url(' + IMG_DIRECTORY + 'watermarkLogo.png)',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !swal.isLoading(),
        onOpen: function () {
          try {
            document.getElementById('wmFullName').click();
            document.getElementById('wmBlank').click();
          } catch (e) {
            console.error(e);
          }
        },
        preConfirm: function (text) {
          return new Promise(function (resolve, reject) {
            try {
              if (!currentWmFirstLine || !currentWmSecondLine) {
                reject(i18n.t("js.file.wtmrk.reject.selection"));
              } else if (currentWmFirstLine === 'Other' && !wmFirstOtherField || currentWmSecondLine === 'Other' && !wmSecondOtherField) {
                reject(i18n.t("js.docviewer.wtmrk.other-field"));
              } else {
                let addEncryption = document.getElementById('encryptChk') != null &&  document.getElementById('encryptChk').checked;
                console.debug('will now be updating the watermark properties of the following items: ' + that.selectedids.join(', '));
                that.updateWatermark(that.selectedids.map(id => that.getById(id)), 1, addEncryption, resolve, reject);
              }
            } catch(err) {
              console.error(err);
              reject(i18n.t("js.utils.something.bad"));
              // TODO consider logging error via redAlert feature
            }
          });
        }
      }).then(function () {
        swal({
          title: i18n.t("js.documents.wtmrk.applied"),
          type: 'success',
          showCloseButton: true,
          confirmButtonText: i18n.t("close"),
          confirmButtonColor: '#13C46A',
          animation: true,
          allowOutsideClick: true,
        }).catch(swal.noop);
        mainList.unselectall();
        e.panel.clearSelected();
      }).catch(swal.noop);
    }
  }

  updateWatermark(selectedItems, value, addEncryption, resolve, reject) {
    let that = this;
    var wmFirstLine = currentWmFirstLine;
    if(wmFirstLine === 'Other') {
      wmFirstLine = 'Other~' + wmFirstOtherField;
    }
    var wmSecondLine = currentWmSecondLine;
    if(wmSecondLine === 'Other') {
      wmSecondLine = 'Other~' + wmSecondOtherField;
    }
    DocUtility.removeCardViews(getCurrentFolderId());
    apicall('settingsapi', 'changeWatermarkSettings', {
      isWatermarked: value + '',
      firstLine: wmFirstLine || '~',
      secondLine: wmSecondLine || '~',
      addEncryption: addEncryption
    }, {
      value: selectedItems.map(item => item.itemid).join(',')
    }).then(function(resp) {
      if(resp && resp.responseCode && resp.responseCode === '0') {
        for(let item of selectedItems) {
          item.update({
            watermark: value === 1, // validate number type as well for good measure
            isEncrypted: addEncryption === true // must explicitly check for a boolean
          });
          document.querySelector(`#${item.id} .watermark-tag-container`).innerHTML = 
            item.getWatermarkTagHTML(value === 1, item.itemid, item.name) 
            + item.getArchivedTagHTML(item.archived === true, item.itemid);
          document.querySelector(`#${item.id} .encryption-tag-container`).innerHTML = item.getEncryptionTagHTML(addEncryption === true);
          that.refreshWatermarkLabelListeners();
        }
        resolve();
      } else {
        throw new Error('invalid server response');
      }
    }).catch((e) => {
      console.error(e);
      console.error('failed to change watermark settings');
      reject(i18n.t("js.utils.server.error-support"));
    });
  }

  editcategoryall() {
    var self = this;
    let inputOptions = {
      breakdown: i18n.t("breakdown"),
      callsheet: i18n.t("call-sheet"),
      chrono: i18n.t("chrono"),
      clearance: i18n.t("clearance"),
      dood: i18n.t("dood"),
      dpr: i18n.t("dpr"),
      oneliner: i18n.t("oneliner"),
      maps: i18n.t("maps"),
      photo: i18n.t("photo"),
      prepschedule: i18n.t("prepschedule"),
      production: i18n.t("production"),
      revision: i18n.t("revision"),
      script: i18n.t("script"),
      shooting: i18n.t("shooting"),
      side: i18n.t("side"),
      techsurvey: i18n.t("tech-survey"),
      other: i18n.t("other")
    };
    swal({
			title: i18n.t("js.documents.category.title"),
			input: 'select',
			inputOptions: inputOptions,
			inputPlaceholder: i18n.t("js.documents.category.placeholder"),
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: i18n.t("button.confirm"),
      cancelButtonText: i18n.t("button.cancel"),
      confirmButtonColor: '#13C46A',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
      preConfirm: function(value) {
        return new Promise((resolve, reject) => {
          if(!value) {
            reject(i18n.t("js.documents.category.reject.empty"));
            return;
          }
          apicall('documentsapi', 'changeSubcategory', {
            newSubcategory: value
          }, {
            props: {
              docIds: self.selectedids.join(',')
            }
          }).then(resp => {
            if(resp.responseCode === '0') {
              resolve(value);
            } else {
              throw new Error('invalid server response');
            }
          }).catch(() => {
            console.error('server error');
            reject(i18n.t("js.utils.something.wrong"))
          });
        });
      }
		}).then(function (result) {
			swal({
				type: 'success',
        html: i18n.t("js.documents.category.success", {category: inputOptions[result], count: self.selectedids.length}),
				confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK"),
				showCloseButton: true,
        allowOutsideClick: true
			}).catch(swal.noop);
      DocUtility.removeCardViews(getCurrentFolderId());
      try {
        for(let item of self.selectedids.map(id => self.getById(id))) {
          item.update({ category: result });
        }
      } catch(err) {
        console.error('dynamic category update failed');
        console.error(err);
        // Dynamic update failed, reloading to fix display
        r.reload();
      }
      mainList.unselectall();
      e.panel.clearSelected();
		}).catch(swal.noop)
  }
}
class FileListItem {
  constructor(id, type, subType, data, name, datemod = '', shared = '', 
      category = '', watermark = false, isEncrypted = false, archived = false, isPublishedToStudio, cloudProvider, uploadedSuccessfully) {
    this.itemid = id;
    this.id = Utility.getValidId(id);
    this.type = type;
    this.subType = subType;
    this.data = data;
    this.name = name;
    this.datemod = datemod;
    this.lastmodhint = null;
    this.lastmod = null;
    this.sortlastmod = null;
    this.shared = replaceDHInShared(shared);
    this.sharedtext = i18n.t("js.documents.shared.everyone");
    this.target = null;
    this.category = category;
    this.watermark = watermark;
    this.isEncrypted = isEncrypted;
    this.datatable = null;
    this.archived = archived;
    this.isPublishedToStudio = isPublishedToStudio
    this.cloudProvider = cloudProvider
    this.uploadedSuccessfully = uploadedSuccessfully;
  }
  getDatatable() {
    if (this.datatable == null) {
      this.datatable = $('#doc-table').DataTable();
    }
    return this.datatable;
  }
  update(data) {
    if (typeof data === 'object') {
      for (var idx in data) {
        this[idx] = data[idx];
      }
    }
    this.render(this.target);
    if(singleSelectPanel != null) {
      singleSelectPanel.update(data);
    }
  }
  removeRow() {
    var dt = this.getDatatable();
    dt.row(document.getElementById(this.id)).remove().draw();
  }
  template() {

    var typeclass = this.type == 'dblink'
        ? 'icon-dropbox' : this.type == 'boxlink' ? 'icon-box'
      : this.type == 'folder' || this.type == 'dbfolder' || this.type == 'gDriveFolder' || this.type == 'boxfolder'
        ? (this.name.endsWith('.zip') ?'icon-zip-large' :'icon-folder')
        : 'icon-file';

    let canBeWatermarked = false;
    if(this.name) {
      if(this.name.endsWith(".pdf") && typeclass=='icon-file') {
        canBeWatermarked = true;
        typeclass = 'icon-file-pdf';
      }
      else if((this.name.endsWith(".xls")||this.name.endsWith(".xlsm")) && typeclass=='icon-file')
        typeclass = 'icon-file-excel';
      else if((this.name.endsWith(".png")||this.name.endsWith(".jpg")||this.name.endsWith(".jpeg")||this.name.endsWith(".gif")) && typeclass=='icon-file')
        typeclass = 'icon-file-picture';
    }
    else {
      this.name = "Corrupted"
    }

    let sortVal = this.subType == 'smartFolder' ? '0' :(this.type == 'folder' ?'1' :'2');
    let imgIcon = this.type == 'gDriveLink' ?'gDriveIcon.png' :this.subType == 'smartFolder' ?'smart-folder.png' :null;

    let isWatermarked = this.type == 'file' && this.watermark == true;
    let isArchived = this.type == 'file' && this.archived == true;
    let isEncrypted = isWatermarked && this.isEncrypted == true; // TODO add isEncrypted prop
    let isPublishedToStudio = this.isPublishedToStudio
    let uploadedSuccessfully = this.uploadedSuccessfully
    let cloudProviderFound = this.cloudProvider !== null
    let iconTag = imgIcon !== null ?'<img width="30" height="30" src="'+IMG_DIRECTORY+imgIcon+'" sortval="'+sortVal+'" style="max-width:30px;margin-left:-2px" />'
      :'<i style="position:relative" class="' + (typeclass + ' encryption-tag-container').trim() + '" sortval="'+sortVal+'">'
        + this.getEncryptionTagHTML(isEncrypted)
        + '</i>';

    return '<td style="display: flex; align-items: center" class="no-text-select doc-data">' + '<div class="doc-icon">' + iconTag + '</div><div class="file-title watermark-tag-container">'
      + this.getWatermarkTagHTML(isWatermarked, this.itemid, this.name) // the content of .watermark-tag-container might get cleared when updating the watermark property so it should be dedicated to this watermark HTML
      + this.getPublishedToStudioTagHTML(isPublishedToStudio, cloudProviderFound, this.itemid, this.cloudProvider)
      + this.getArchivedTagHTML(isArchived, this.itemid)
      + this.getUploadedSuccessfullyTagHTML(uploadedSuccessfully, this.itemid)
      + '</div></td>' + '<td class="last-mod-date">' + '<span title="' + this.lastmodhint + '">' + this.lastmod + '</span>' + '</td>' + '<td style="display: none">' + this.sortlastmod + '</td>' 
      + '<td class="shared-with">' + replaceDHInShared(this.sharedtext) + '</td>' + '<td>'
      + (this.id == 'PHOTO_ROOT' ? '' : ('<a id="moreMenu' + this.id + '" class="moreMenuClass" data-toggle="dropdownmenuitem"><i class="icon-more"></i></a>')) + '</td>';
  }

  // adds "Reupload Document" tag to document rows that have a missing fileName or fileURL attribute
  // so that user knows that a file is corrupted and needs to be reuploaded

  getUploadedSuccessfullyTagHTML(uploadedSuccessfully, itemId) {
  
    return (!uploadedSuccessfully && uploadedSuccessfully !== undefined  ? '<span class="us-flag label primary" itemid="' + itemId + '"> Reupload Document</span>' : '');
  }

  getWatermarkTagHTML(isWatermarked, itemId, name) {
    return '<span class="' + (isWatermarked ? 'can-watermark watermarked-file ' : '')+ '">'
      + `<span class="document-filename-container" title="${name}">${name}</span>` 
      + (isWatermarked ? '&nbsp;<span class="wm-flag label primary" itemid="' + itemId + '">'+i18n.t("js.documents.watermarked")+'</span>' : '')
      + '</span>';
  }
  getPublishedToStudioTagHTML(isPublishedToStudio, cloudProviderFound, itemId, cloudProvider) {
    return (isPublishedToStudio && cloudProviderFound ? '<span class="pts-flag label primary" itemid="' + itemId + '">'+ cloudProvider +'</span>' : '');
  }
  getArchivedTagHTML(isArchived, itemId) {
    return (isArchived ? '<span class="acvd-flag label primary" itemid="' + itemId + '">'+i18n.t("js.documents.archived")+'</span>' : '');
  }
  getEncryptionTagHTML(isEncrypted) {
    return isEncrypted ?'<i class="icon-lock" title="'+i18n.t("js.documents.encrypted")+'"></i>' :'';
  }
  parseDates() {
    this.lastmod = this.datemod;
    this.lastmodhint = this.lastmod;
    this.sortlastmod = this.lastmod;
    if (this.lastmod != '' && moment(this.lastmod).isValid()) {
      this.sortlastmod = moment(this.lastmod).unix();
      if (moment().diff(moment(this.lastmod), 'days') < 2) {
        this.lastmodhint = moment(this.lastmod).locale(i18n.language).format(i18n.t("js.moment.documents.format"));
        this.lastmod = moment(this.lastmod).locale(i18n.language).fromNow();
      } else {
        this.lastmod = moment(this.lastmod).locale(i18n.language).format(i18n.t("js.moment.documents.format"));
        this.lastmodhint = this.lastmod;
      }
    }
  }
  render(target) {
    this.target = target == null
      ? this.target
      : target;
    this.parseDates();
    this.sharedtext = i18n.t("js.documents.shared.everyone");//'Everyone';
    if (this.shared){
      if (this.shared.indexOf("@") > -1) {
        this.sharedtext = i18n.t("js.documents.shared.email");//'Shared By Email';
      } else {
        this.sharedtext = typeof this.shared == 'string' ? replaceDHInShared(this.shared).replace(/,/g, ', ') : (this.shared || '');
      }
    }
    var el = document.getElementById(this.id);
    if (el == null) {
      el = document.createElement('tr');
      el.id = this.id;
      el.setAttribute('item-id', this.itemid);
      el.dataset.type = this.type;
      if(this.type == 'file' || this.type == 'folder') {
        el.draggable = 'true';
        el.ondragstart = (e) => {
          fileDropper.isDisabled = true;
          var selecteditems = document.querySelectorAll('#doc-table tr.selected-document');
          var selectedItemsIds = [];
          let selectedElemsIds = [];
          if (selecteditems && selecteditems.length > 1){
            selecteditems.forEach((item)=>{
              selectedItemsIds.push(item.getAttribute('item-id'));
              selectedElemsIds.push(item.id);
            });
          }
          if (!selectedItemsIds.includes(el.getAttribute('item-id'))) {
            selectedItemsIds.push(el.getAttribute('item-id'));
            selectedElemsIds.push(el.id);
          }
          e.dataTransfer.setData('itemid', selectedItemsIds.join(','));
          e.dataTransfer.setData('elemid', selectedElemsIds.join(','));
        }
        el.ondragend = () => fileDropper.isDisabled = false;
      }
      if (this.type=='folder'){
        el.ondragover=(e)=>{
          var tel = e.toElement;
          if(tel){
            while(tel.tagName != 'TR')tel=tel.parentElement;
            if (tel.dataset.type == 'folder'){
              tel.style.outline = '1px solid #000000';
            }
            let folderName = tel.querySelector('div.file-title > span').textContent;
            if(folderName) {
              fileDropper.updateTextOverlay(i18n.t("js.utils.file.dropper.label", {folderName}));
              fileDropper.targetFolderId = tel.getAttribute('item-id');
            }
          }
          e.preventDefault();
        }
        el.ondragleave=(e)=>{
          fileDropper.resetTextOverlay();
          fileDropper.targetFolderId = null;
          var tel = e.toElement;
          if(tel){
            while(tel.tagName!='TR')tel=tel.parentElement;
            if (tel.dataset.type=='folder'){
              tel.style.outline='';
            }
          }
        }
        el.ondrop = (e) => {
          e.preventDefault();
          var tel = e.toElement;
          if(tel) {
            while(tel.tagName != 'TR') {
              tel = tel.parentElement;
            }
            tel.style.outline = '';
            if(!fileDropper.isDisabled) {
              console.debug('drop will be handled by FileDropper');
              return;
            }
            let selectedElemsIds = e.dataTransfer.getData('elemid').split(',');
            var target = e.dataTransfer.getData('itemid');
            target = target.split(',');
            var targetIds = [];
            for (var idx in target){
              targetIds.push(target[idx]);
            }
            var localpath = location.hash.replace('#','').split('/');
            localpath[0]='ROOT';
            var tmp = new Object();
            tmp.oldParentFolderId = localpath[localpath.length-1];
            tmp.newParentFolderId = tel.getAttribute('item-id');
            tmp.filesId = targetIds.join(',');
            DocUtility.removeCardViews(`${tmp.oldParentFolderId},${tmp.newParentFolderId}`);
            showSpinner();
            if (tmp.oldParentFolderId != tmp.newParentFolderId) {
              apicall('documentsapi', 'changeParentFolder', tmp).then(() => {
                // TODO add validation
                hideSpinner();
                mainList.deleteFileListItems(targetIds);
                // TODO if upload not ready, wait
                // TODO update folder list and smart folder list for all filesId that represent a folder
              }).catch(err => {
                hideSpinner();
                // Failed to dynamically remove items that changed parent folder, reloading to fix display
                r.reload();
              });
            }
            // clear selected documents to avoid selection being affected by dropped folders
            // if ctrl key was clicked while dragging unselected file
            multiSelectPanel.clearSelected();
            singleSelectPanel.clearSelected();
            for(let selectedRow of document.querySelectorAll('#doc-table tr.selected-document')) {
              selectedRow.classList.remove('selected-document');
            }
            updateSidePanelsVisibility();
          }
        }
      }
      el.innerHTML = this.template();
      this.target.appendChild(el);
      el.ondblclick = this.dblclick();
      this.dblclick()
      el.onclick = this.oneclick();
      let moreMenuElem = el.querySelector('.moreMenuClass');
      if(moreMenuElem !== null) {
        el.querySelector('.moreMenuClass').onclick = this.moremenu();
      }
    } else {
      el.querySelector("td.doc-data > div.file-title > span").innerText = this.name;
      el.querySelector("td.last-mod-date > span").innerText = this.lastmod;
      el.querySelector("td.last-mod-date > span").setAttribute('title', this.lastmodhint);
      el.querySelector("td.shared-with").innerText = replaceDHInShared(this.sharedtext);
    }
    el.setAttribute('data-itemdata', this.data);
  }

  importToCloud() {
    
    var self = this;
    publishToStudioSwal(self)

  }

  rename(){ 
    return (event) =>{
      var self = this;
      let type = self.type.toLowerCase().includes('folder') ?'folder' :'file';      
      var extension = (type === 'folder' ? "" : self.name.substring(self.name.lastIndexOf('.'), self.name.length))
      //Name displayed in textbox on popup (no extension if file)
      var currName = (type === 'folder' ? self.name : self.name.substring(0, self.name.lastIndexOf('.')))         
      var id = self.itemid;

      swal({
        title: i18n.t("utils.rename"),
        input: 'text',
        inputValue: currName,
        inputPlaceholder: (type == "folder" ? i18n.t("folder.plchldr") : i18n.t("file.plchldr")),
        allowOutsideClick: true,
        showCloseButton: true,
        confirmButtonText: i18n.t("button.confirm"),
        cancelButtonText: i18n.t("button.cancel"),
        useRejections: true,
        inputValidator: (value) =>{
          return new Promise((resolve, reject) =>{
            if(value == "" || value == null || value.length == 0){
              reject(i18n.t('js.folder.rejection.name.invalid')); 

            }else{
              resolve()
            }                  
          });
        }
       
      }).then((result) => {
        //name displaye in the row (with extension if file)
        var displayedName = result.concat(extension);
        apicall('documentsapi', 'changeFileName', {
          docId: id
        }, {
          value: displayedName
        }).then(resp => {
          if(resp.responseCode === '0') {
            DocUtility.removeCardViews(getCurrentFolderId());
            self.update({name: displayedName});           
            cgToast(i18n.t("js.documents.rename."+type));
          } else {
            throw new Error('server error');
          }
        }).catch(() => {
          console.error('server error');
          cgToast(i18n.t("js.documents.rename.failure"));
        }).then(() => {
          id = '';
          displayedName = '';
        });
      });
    }   
  }  

  archive(){
    this.archived = true;
    var wrapper = document.createElement('div');
    wrapper.innerHTML=this.getArchivedTagHTML(this.archived === true, this.itemid);
    document.querySelector(`#${this.id} .watermark-tag-container`).appendChild(wrapper.firstChild);
  }

  recover(){
    this.archived = false;
    let root = document.querySelector(`#${this.id} .watermark-tag-container`);
    let flag = root.querySelector('.acvd-flag');
    root.removeChild(flag);
  }

  download() {
    if(this.data != null && (this.data.indexOf("www.dropbox.com")>=0 ||
    this.data.indexOf('google.com')>=0)) {
      console.debug('using secondary download method for non-Croogloo file')
      DocUtility.openFile(this.itemid, this.name, this.watermark, true, cgNotification, this.data);  
    } else {
      console.debug('using primary download method');
      showSpinner();
      apicall('documentsapi', 'fetchDownloadLink', {
        fileId: this.itemid
      }).then(resp => {
        if(resp.responseCode === '0') {
          window.location.href = resp.responseMessage;
        } else {
          throw new Error('server error');
        }
      }).catch(() => {
        console.warn('using failover download method')
        DocUtility.openFile(this.itemid, this.name, this.watermark, true, cgNotification, this.data);
      }).then(hideSpinner);
    }
  }
  generateDPR() {
    showSpinner();
    apicall('documentsapi', 'convertCsToDpr', {
      fileId: this.itemid
    }).then(resp => {
      if(resp.responseCode === '0') {
        window.location.href = resp.responseMessage;
      } else {
        throw new Error('server error');
      }
    }).catch(() => {
      console.error('dpr generation failed');
      swalToast({
        title: i18n.t("js.documents.dpr.failure"),
        type: 'error'
      }).catch(swal.noop);
    }).then(hideSpinner);
  }
  
  downloadAsZIP() {
    if(this.data != null && (this.data.indexOf("www.dropbox.com") >= 0 || this.data.indexOf('google.com') >= 0)) {
      console.debug('tried to download non-Croogloo folder as ZIP');
      cgToast(i18n.t("js.utils.unsupported.operation"));
      return;
    } else {
      console.debug('using primary download method');
      showSpinner();
      apicall('documentsapi', 'downloadFolderAsZip', {
        folderId: this.itemid
      }).then(resp => {
        if(resp.responseCode === '0') {
          swal({
            title: i18n.t("response.success"),
            html: i18n.t("js.documents.zip.generating"),            
            type: 'success',
            allowOutsideClick: true,
          }).catch(swal.noop);
          DownloadNotification.add(false, false, resp.responseMessage.split(",")[0], 
            resp.responseMessage.split(",")[1] || null, new Date().getTime());
        } else {
          throw new Error('server error');
        }
      }).catch(() => {
        cgToast(i18n.t("js.utils.something.wrong"));        
      }).then(hideSpinner);
    }
  }

  async editFilters() {
    showSpinner();
    lastFolderModalTriggerType = 'filter-edit';
    smartFolderInEditing = null; // must set to null in case use clicks ok button before filters loaded
    let pastFilters = await fetchFolderFilters(this.itemid);
    let newFolderModal = document.getElementById('newfoldermodal');
    let smartFolder = new SmartFolder(newFolderModal);
    showFolderFilterModal(newFolderModal,smartFolder);    
    if(Object.keys(pastFilters).length) {
      smartFolder.fill(pastFilters);
    }
    smartFolderInEditing = smartFolder;
    hideSpinner();
  }

  editDocumentFolder(params, filters) {
    DocUtility.removeCardViews(getCurrentFolderId());
    showSpinner();
    console.debug(params, filters);
    apicall('documentsapi', 'editDocumentFolder', params, filters).then(function(resp) {
      if(resp.responseCode === '0') {
        try {
          let addedItem = new FileListItem(resp.entityId, 'folder', 
            resp.entity.properties.isSmartFolder === true ?'smartFolder' :'none',
            resp.entityId, resp.entity.properties.fileName, 
            resp.entity.properties.timeCreated);
          mainList.overwriteFileListItem(resp.entityId, addedItem);
        } catch(err1) {
          console.error(err1);
          console.error('failed to dynamically edit folder');
          // Dynamic update failed, reloading to fix display
          r.reload();
        }
      } else {
        throw new Error('invalid server response');
      }
    }).catch(err => {
      console.error(err);
      cgToast(i18n.t("js.documents.folder.edit.failed"), { className: 'error-toast' });
    }).then(hideSpinner());
  }

  toggleFilters() {
    console.debug('toggling filters');
    if(this.subType !== 'smartFolder') {
      // var newFolderModal = new Foundation.Reveal($('#newfoldermodal'));
      // newFolderModal.open();      
      this.editFilters();
    } else { 
      this.editDocumentFolder({
        folderId: this.itemid,
        isSmartFolder: false
      }, {});
    }
  }

  distribute() {
    r.loadPage('compose', { attachmentID: this.itemid });
  }
  deleteitem() {
    var self = this;
    return(event) => {
      let localpath = location.hash.replace('#','').split('/');
      localpath[0] = 'ROOT';
      DocUtility.removeCardViews(getCurrentFolderId());
      DocUtility.deleteItem(self.itemid, self.name, self.type, localpath[localpath.length-1], false, 'menu_documents', function() {
        mainList.deleteFileListItems([self.itemid]);
      });
    }
  }
  accesslevel() {
    $('#recursiveChk').remove(); // preventive -- sweetalert2 should delete it
    var self = this;
    let itemType = (self.type.toLowerCase()=='folder'?'folder':'file');
    return(event) => {
      getSharedField(self.shared||null,function(sharedHtmlInput) {
        swal({
          type: "info",
          title: i18n.t("js.documents.access."+itemType),
          html: i18n.t("js.documents.access.text."+itemType)+'<br><br><form class="sharedInputForm">'+sharedHtmlInput+'</form>'
          + (itemType == 'folder' ?('<br/>'+i18n.t("js.documents.access.folder-apply")+' <input type="checkbox" id="recursiveChk" checked>'):''),          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: i18n.t("accept"),
          cancelButtonText: i18n.t("button.cancel"),
          confirmButtonColor: '#13C46A',
          cancelButtonColor: '#F44336',
          allowOutsideClick: () => !swal.isLoading(),
          preConfirm: function () {
            return new Promise(function (resolve, reject) {
              var chk = [];
              $('#sharedInput').children().each(function(){
                if($(this).is(':checked'))
                chk.push($(this).attr('id'));
              })
              resolve({
                sharedGroups: chk.length ?[chk] :['ADMIN'],
                isRecursive: itemType == 'folder' && document.getElementById('recursiveChk').checked
              });
            });
          }
        }).then(function(result) {
          changeSharedWith(itemType, result.sharedGroups, result.isRecursive);
        }).catch(swal.noop);
      });

      function changeSharedWith(itemType, sharedGroups, isRecursive) {
        let errorMsg = i18n.t("js.documents.access.single.err."+(self.type=='folder'?'folder':'file'), {name: self.name});
        var tmp = new Object();
        tmp.limitToType = 'securityList';
        tmp.isRecursive = isRecursive === true; // sets undefined and other types to false
        DocUtility.removeCardViews(getCurrentFolderId());
        apicall('documentsapi', 'changeAccessLevel', tmp, {
          props: {
            ids: self.itemid,
            limitTo: sharedGroups.join(',')
          }
        }).then(function(resp){
          if (resp && resp.responseCode && resp.responseCode == '0') {
            // This string needs to have no spaces because we parse it elsewhere and explode on the comma to get individual shared groups
            var sharedGroupString = replaceDHInShared(sharedGroups.join(','));
            // This needs a space for showing in the UI
            var sharedGroupStringForDisplay = replaceDHInShared(sharedGroups[0].join(', '));
            self.update({ shared: sharedGroupString });
            cgToast(i18n.t("js.documents.access.single.done."+((itemType=='folder' && isRecursive)? 'recursive':itemType),
              { groups: sharedGroupStringForDisplay, name: self.name }), 8000);
          } else {
            cgToast(errorMsg, { className: 'error-toast' });
          }
        });
      }
    }
  }
  updatecategory() {
    var self = this;
    let inputOptions = {
      breakdown: i18n.t("breakdown"),
      callsheet: i18n.t("call-sheet"),
      chrono: i18n.t("chrono"),
      clearance: i18n.t("clearance"),
      dood: i18n.t("dood"),
      dpr: i18n.t("dpr"),
      oneliner: i18n.t("oneliner"),
      maps: i18n.t("maps"),
      photo: i18n.t("photo"),
      prepschedule: i18n.t("prepschedule"),
      production: i18n.t("production"),
      revision: i18n.t("revision"),
      script: i18n.t("script"),
      shooting: i18n.t("shooting"),
      side: i18n.t("side"),
      techsurvey: i18n.t("tech-survey"),
      other: i18n.t("other")
    };
    swal({
			title: i18n.t("js.documents.category.title"),
			input: 'select',
			inputOptions: inputOptions,
      inputValue: self.category,
      inputPlaceholder: i18n.t("js.documents.category.placeholder"),
      showCancelButton: true,
      showCloseButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: i18n.t("button.confirm"),
      cancelButtonText: i18n.t("button.cancel"),
      confirmButtonColor: '#13C46A',
      allowOutsideClick: () => !swal.isLoading(),
      preConfirm: function(value) {
        return new Promise(function(resolve, reject) {
          if(!value) {
            reject(i18n.t("js.documents.category.reject.empty"));
            return;
          }
          updateCategory(self.itemid, value, () => {
            self.update({ category: value });
            try {
              DocUtility.removeCardViews(getCurrentFolderId());
              resolve(inputOptions[value]);
            } catch(e) {
              console.error(e);
              resolve(value);
            }
          }, () => {
            reject(i18n.t("js.utils.something.wrong"));
          });  
        });
      }
		}).then(newCategory => {
      swal({
        html: i18n.t("js.documents.category.single.changed", {name: self.name, newCategory:newCategory}),
        type: 'success',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK"),
        allowOutsideClick: true
      }).catch(swal.noop);
    }).catch(swal.noop);
    function updateCategory(selectedItemId, category, onSuccess, onFailure) {
      apicall('documentsapi', 'changeSubcategory', {
        newSubcategory: category
      }, {
        props: {
          docIds: selectedItemId
        }
      }).then(resp => {
        if(resp.responseCode === '0') {
          onSuccess();
        } else {
          throw new Error('invalid server response');
        }
      }).catch(() => {
        console.error('server error');
        onFailure();
      });
  	}
  }
  packtomerge() {
    $('#dropdownmenuitem').foundation('close');
    $(document.getElementById(this.id)).click();
    if(!multiSelectPanel.selectedItems || Object.keys(multiSelectPanel.selectedItems).length < 2) {
      swalToast({
        type: 'info',
        title: i18n.t("js.documents.merge.more.files"),
        showCloseButton: false,
        timer: 3000
      });
    }
  }
  
  parseScript() {
    var self = this;
    DocUtility.showParseScriptAlert(self.itemid, self.name, (episode, color) => {
      // we can update the single item side panel now that the episode and colors have been 
      // updated on the corresponding document entity
      if(singleSelectPanel != null) {
        // we don't use the returned episode and color because updating the side panel will 
        // refresh these values via the backend, making sure they've been properly 
        // validated and applied to the document entity
        singleSelectPanel.update();
      }  
    });
  }

  changeWatermark(title = null, isRemoval, line1 = 'FullName', line2 = 'Blank', isEncrypted = null) {
    $('#dropdownmenuitem').foundation('close');
    resetWmGlobals();
    var self = this;
    var initValue = self.watermark?"1":"0";
    if(typeof isRemoval !== 'boolean') {
      isRemoval = self.watermark;
    }
    if(typeof isEncrypted !== 'boolean') {
      isEncrypted = self.isEncrypted;
    }
    swal({
      title: title ? title:i18n.t("js.documents.menu.wtmrk."+(!isRemoval ? 'add':'del')),
      html: (!isRemoval ?generateWatermarkingOption():i18n.t("js.documents.wtmrk.remove-one.text", {filename: self.name})),
      type: !isRemoval ?'info' :'question',
      input: 'text',
      inputClass: 'hidden-input', //used to prevent focus error on reject
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t(!isRemoval ? "button.confirm":"yes"),
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: i18n.t(!isRemoval ? "button.cancel":"no"),
      background: !isRemoval ?'#fff url(' + IMG_DIRECTORY + 'watermarkLogo.png)' :'',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
      onOpen: function() {
        if(!isRemoval) {
          try {
            [
              { value: line1, idPrefix: 'first' },
              { value: line2, idPrefix: 'second' }
            ].forEach(line => {
              if(line.value.match(/other/i) !== null) {
                selectWmInputField(line.idPrefix, line.value);
              } else {
                console.debug('selecting wm line with id: "' + 'wm' + line.value + '"');
                document.getElementById('wm' + line.value).click();
              }
            });
            if(isEncrypted) {
              document.getElementById('encryptChk').click();
            }
          } catch(e) {
            console.error(e);
          }
        }
        function selectWmInputField(idPrefix, line) {
          document.getElementById(idPrefix + 'OtherCheckbox').click();
          document.getElementById(idPrefix + 'OtherInput').value = line.split('~', 2)[1];
        }
      },
      preConfirm: function(text) {
        return new Promise(function(resolve, reject) {
          if(!isRemoval && (!currentWmFirstLine || !currentWmSecondLine)) {
            reject(i18n.t("js.file.wtmrk.reject.selection"));
          } else if(initValue != 1 &&
              (currentWmFirstLine === 'Other' && !wmFirstOtherField ||
              currentWmSecondLine === 'Other' && !wmSecondOtherField)) {
            reject(i18n.t("js.docviewer.wtmrk.other-field"));
          } else {
            let addEncryption = document.getElementById('encryptChk') != null && document.getElementById('encryptChk').checked;
            mainList.updateWatermark([self], isRemoval ?0 :1, addEncryption, resolve, reject);
          }
        });
      }
    }).then(function() {
      swal({
        title: i18n.t("js.documents.wtmrk."+ (isRemoval ? "removed":(initValue != 1 ? "added":"updated"))),
        type: 'success',
        showCloseButton: true,
        confirmButtonText: i18n.t("close"),
        confirmButtonColor: '#13C46A',
        animation: true,
        allowOutsideClick: true
      }).catch(swal.noop);
    }).catch(swal.noop);
  }
  watermarkprint() {
    sessionStorage.setItem('fileId', this.itemid);
    sessionStorage.setItem('fileName', this.name);
    sessionStorage.setItem('fileUrl', this.data);
    r.loadPage('watermark');
  }
  movetoparent() {
    var self = this;
    var localpath = location.hash.replace('#','').split('/');
    localpath[0]='ROOT';
    if (localpath.length > 1){
      let oldparent = localpath[localpath.length-1];
      let newparent = localpath[localpath.length-2];
      var tmp = new Object();
      tmp.oldParentFolderId = oldparent;
      tmp.newParentFolderId = newparent;
      tmp.filesId = self.itemid;
      DocUtility.removeCardViews(`${oldparent},${newparent}`);
      apicall('documentsapi', 'changeParentFolder', tmp).then(() => {
        // TODO add API response validation
        mainList.deleteFileListItems([self.itemid]);
        // TODO if upload not ready, wait
        // TODO update folder list and smart folder list for all filesId that represent a folder
      });
    }
  }
  async changeParentFolder() {
    let self = this;
    try {
      if (await upload.getUploadFolders({}, {}, false, true)) {
        try {
          let tmp = {};
          let localpath = location.hash.replace('#', '').split('/');
          tmp.oldParentFolderId = localpath.length == 1 ? 'ROOT' : localpath[localpath.length - 1];
          tmp.newParentFolderId = upload.selectedFolders[0].id;
          tmp.filesId = self.itemid;
          console.debug('changing parent folder');
          console.debug(tmp);
          DocUtility.removeCardViews(`${tmp.oldParentFolderId},${tmp.newParentFolderId}`);
          apicall('documentsapi', 'changeParentFolder', tmp).then(() => {
            // TODO add API response validation
            if (tmp.oldParentFolderId !== tmp.newParentFolderId) {
              mainList.deleteFileListItems([self.itemid]);
              // TODO if upload not ready, wait
              // TODO update folder list and smart folder list for all filesId that represent a folder
            }
          }).catch(showErrMsg);
        } catch (err1) {
          showErrMsg(err1);
        }
      }
    } catch (err2) {
      showErrMsg(err2);
    }

    function showErrMsg(err) {
      console.error(err);
      swalToast({
        type: 'error',
        title: i18n.t("js.documents.change.folder.error")
      });
    }
  }

  boxlink() {
    var w = 450;
    var h = 600;
    var left = 0;
    var top = 0;
    var newwindow = window.open("/box.html", "boxauth", 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=' + w + ',height=' + h + ',top=' + top + ',left=' + left);
  }
  boxunlink() {
    croogloo_auth.boxunlink().then(()=>{//TODO
      cgToast(i18n.t("js.documents.box.unlinked"))//TODO
    });
  }
  boximport() {
    if(this.type == 'boxfolder') {
      console.debug('using mass box import for individual folder');
      if(isBoxDirectory()) {
        mainList.importFilesAndFolders(this.itemid);
      } else {
        console.error('found box file in non-box directory');
      }
      return;
    }
    var currentPath = window.location.hash.replace('#', '').split('/').slice(2).join('/');
    currentPath = currentPath === '' ? "/" : "/" + currentPath + '/';
    var filepath = currentPath + this.name;
    cgToast(i18n.t("js.documents.thrd-party.import"));
    upload.fromBox(filepath, {name: this.name}).then((dbResp)=>{//TODO
      let file = dbResp[0];
      let processingPromise = dbResp[1];
      processingPromise.then(function() {
        try {
          cgNotification.addNotification(i18n.t("js.documents.import.box"/*TODO*/), file.finalname, ()=>{
            r.loadPage('documents');
          });
        } catch(e1) {
          console.error(e1);
        }
      }).catch(err => {
        console.error('box import file processing promise failed', err);
        cgToast(i18n.t("js.compose.file.import.failure"), 8000)
      });
    }, ()=>{
      cgToast(i18n.t("js.documents.thrd-party.import-denied"));
    }).catch(err => {
      cgToast(i18n.t("js.documents.thrd-party.import-failed"));
    });
  }

  dropboxlink() {
    var w = 450;
    var h = 600;
    var left = 0;
    var top = 0;
    var newwindow = window.open("/dropbox.html", "dpauth", 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=' + w + ',height=' + h + ',top=' + top + ',left=' + left);
  }
  dropboxunlink() {
    croogloo_auth.dropboxunlink().then(()=>{
      cgToast(i18n.t("js.documents.dropbox.unlinked"))
    });
  }
  dropboximport() {
    if(this.type == 'dbfolder') {
      console.debug('using mass dropbox import for individual folder');
      if(isDropboxDirectory()) {
        mainList.importFilesAndFolders(this.itemid);
      } else {
        console.error('found db file in non-db directory');
      }
      return;
    }
    var currentPath = window.location.hash.replace('#', '').split('/').slice(2).join('/');
    currentPath = currentPath === '' ? "/" : "/" + currentPath + '/';
    var filepath = currentPath + this.name;
    cgToast(i18n.t("js.documents.thrd-party.import"));
    upload.fromDropbox(filepath, {name: this.name}).then((dbResp)=>{
      let file = dbResp[0];
      let processingPromise = dbResp[1];
      processingPromise.then(function() {
        try {
          cgNotification.addNotification(i18n.t("js.documents.import.dropbox"), file.finalname, ()=>{
            r.loadPage('documents');
          });
        } catch(e1) {
          console.error(e1);
        }
      }).catch(err => {
        console.error('dropbox import file processing promise failed', err);
        cgToast(i18n.t("js.compose.file.import.failure"), 8000)
      });
    }, ()=>{
      cgToast(i18n.t("js.documents.thrd-party.import-denied"));
    }).catch(err => {
      cgToast(i18n.t("js.documents.thrd-party.import-failed"));
    });
  }
  gDriveImport() {
    cgToast(i18n.t("js.documents.thrd-party.import"));
    upload.fromGoogleDrive(this.itemid, {name:this.name}).then((file)=>{
      cgNotification.addNotification(i18n.t("js.documents.import.gdrive"), file[0].finalname, ()=>{
        r.loadPage('documents');
      });
    }, ()=>{
      cgToast(i18n.t("js.documents.thrd-party.import-denied"));
    });
  }
  moremenu() {
    var self = this;
    return(event) => {
      lastClickedMenuTarget = event.target;
      self.changeMenuItem('dropdown-changefolder', !self.type.startsWith('db') && !self.type.startsWith('gDrive') 
        && !self.type.startsWith('box') && croogloocurrentpage.getAccessLevel() >= 2, (e)=>self.changeParentFolder());
      self.changeMenuItem('dropdown-rename', !self.type.startsWith('db') && !self.type.startsWith('gDrive') 
        && !self.type.startsWith('box') && croogloocurrentpage.getAccessLevel() >= 2, self.rename());
      self.changeMenuItem('dropdown-download', self.type.toLowerCase().includes('file') && croogloocurrentpage.getAccessLevel()>=1, (event) => self.download());
      self.changeMenuItem('dropdown-generatedpr', self.type.toLowerCase().includes('file') && croogloocurrentpage.getAccessLevel()>=1 && ['CORONERS2', 'ZTODELETEH10', 'PE4CLONE', 'PRIVATEEYES4', 'FILMFIXER'].includes(croogloo_auth.tenantId) && self.category == 'callsheet', (event) => self.generateDPR());
      self.changeMenuItem('dropdown-downloadzip', self.type.toLowerCase() == 'folder' && croogloocurrentpage.getAccessLevel() >= 1, (event) => self.downloadAsZIP());
      // self.changeMenuItem('dropdown-editfilters', self.type.toLowerCase() == 'folder' && self.subType.toLowerCase() == 'smartfolder' && croogloocurrentpage.getAccessLevel() >= 1, (event) => self.editFilters());
      self.changeMenuItem('dropdown-togglefilters', self.type.toLowerCase() == 'folder' && croogloocurrentpage.getAccessLevel()>=1, (event) => self.toggleFilters());
      self.changeMenuItem('dropdown-distribute', (self.type === 'file') && croogloocurrentpage.getAccessLevel()>=1, (event) => self.distribute());
      self.changeMenuItem("dropdown-accesslevel", ['file','folder'].includes(self.type) && croogloocurrentpage.getAccessLevel()>=2, self.accesslevel());
      self.changeMenuItem("dropdown-editcategory", self.type == 'file' && croogloocurrentpage.getAccessLevel()>=2, (event) => self.updatecategory());
      self.changeMenuItem("dropdown-parsescript", self.type == 'file' && self.name.endsWith(".pdf") 
        && ["script","revision"].includes(self.category.toLowerCase()) && croogloocurrentpage.getAccessLevel() == 2, (event) => self.parseScript());
      
      document.getElementById('dropdown-watermarkfile').innerHTML=i18n.t("js.documents.menu.wtmrk."+(self.watermark? "del":"add"));

      const preConditions = (self.type == 'file' && croogloocurrentpage.getAccessLevel() == 2);
      self.changeMenuItem("dropdown-archive", preConditions && self.archived == false, (event) => {
        mainList.changeArchiveStatus(self, true);
      });
      self.changeMenuItem("dropdown-recover", preConditions && self.archived == true, (event) => {
        mainList.changeArchiveStatus(self, false);
      });
      
      self.changeMenuItem("dropdown-watermarkfile", self.type == 'file' && self.name.endsWith(".pdf") && croogloocurrentpage.getAccessLevel() == 2, (event) => self.changeWatermark());
      self.changeMenuItem("dropdown-watermarkprint", self.type == 'file' && self.name.endsWith(".pdf") && croogloocurrentpage.getAccessLevel() == 2, (event) => self.watermarkprint());
      self.changeMenuItem("dropdown-merge", self.type == 'file' && self.name.endsWith(".pdf") && croogloocurrentpage.getAccessLevel() == 2, (event) => self.packtomerge());
      self.changeMenuItem("dropdown-delete", ['file','folder'].includes(self.type) && croogloocurrentpage.getAccessLevel()>=2, self.deleteitem());
      self.changeMenuItem("dropdown-dropboximport", ['dbfile','dbfolder'].includes(self.type), (event) => self.dropboximport());
      self.changeMenuItem("dropdown-boximport", ['boxfile','boxfolder'].includes(self.type), (event) => self.boximport());
      // TODO: re-enable for Publish to Studio feature
      // if (!self.isPublishedToStudio) {
      //   $('#dropdown-importToCloud').parent().css('display', 'block')
      //   self.changeMenuItem("dropdown-importToCloud", ['file','folder'].includes(self.type), () => self.importToCloud());
      // }
      // else {
      //   $('#dropdown-importToCloud').parent().css('display', 'none')
      // }
      $('#dropdown-importToCloud').parent().css('display', 'none')
      
      if(croogloo_auth.crooglooauth.boxToken && croogloo_auth.crooglooauth.boxToken !== '403'){//TODO
        document.getElementById("dropdown-dropboxfolder").innerText = i18n.t("docs.menu.unlink");
        self.changeMenuItem("dropdown-boxfolder", self.type == 'boxlink', (e)=>self.boxunlink());
      }else{
        document.getElementById("dropdown-boxfolder").innerText = i18n.t("docs.menu.link");
        self.changeMenuItem("dropdown-boxfolder", self.type == 'boxlink', e => {//TODO
          if(!croogloo_auth.crooglooauth.boxToken) {
            self.boxlink();
          }
        });
      }
      
      self.changeMenuItem("dropdown-gDriveImport", self.type == 'gDriveFile', (event) => self.gDriveImport());
      if(JSON.parse(secureLocalStorage.get('crooglooauth')).gDriveAccessToken) {
        document.getElementById("dropdown-gDriveSignOut").innerText = i18n.t("docs.menu.unlink");
        self.changeMenuItem("dropdown-gDriveSignOut", self.type == 'gDriveLink', (event) => {
          CGGoogleAuth.signOut()
          document.getElementById("dropdown-gDriveSignOut").innerText = i18n.t("docs.menu.link");
          self.changeMenuItem("dropdown-gDriveSignOut", self.type == 'gDriveLink', (event) => {
            CGGoogleAuth.signIn(function() {
              r.loadPage('documents/gDrive', {
                path: ['Google Drive'],
                gDrivePath: [{
                  folderName: self.name,
                  folderId: 'root'
                }]
              });
            });
          });  
        });
      } else {
        document.getElementById("dropdown-gDriveSignOut").innerText = i18n.t("docs.menu.link");
        self.changeMenuItem("dropdown-gDriveSignOut", self.type == 'gDriveLink', (event) => {
          CGGoogleAuth.signIn(function() {
            r.loadPage('documents/gDrive', {
              path: ['Google Drive'],
              gDrivePath: [{
                folderName: self.name,
                folderId: 'root'
              }]
            });
          });
        });
      }
      if (croogloo_auth.crooglooauth.dropboxToken && croogloo_auth.crooglooauth.dropboxToken !== '403') {
        document.getElementById("dropdown-dropboxfolder").innerText = i18n.t("docs.menu.unlink");
        self.changeMenuItem("dropdown-dropboxfolder", self.id == 'dropboxfolder', (e)=>self.dropboxunlink());
      } else {
        document.getElementById("dropdown-dropboxfolder").innerText = i18n.t("docs.menu.link");
        self.changeMenuItem("dropdown-dropboxfolder", self.id == 'dropboxfolder', e => {
          if(!croogloo_auth.crooglooauth.dropboxToken) {
            self.dropboxlink();
          }
        });
      }
      let showDdPlaceholder = $('#dropdown-list > li > a:not(#dropdown-placeholder):not(.hidden-dropdown-menu)').length == 0;
      self.changeMenuItem("dropdown-placeholder", showDdPlaceholder, function() {});
      event.preventDefault();
    };
  }
  changeMenuItem(domid, visible, clickevent) {
    if(visible) {
      $('#'+domid).removeClass('hidden-dropdown-menu');
    } else {
      $('#'+domid).addClass('hidden-dropdown-menu');
    }
    document.getElementById(domid).onclick = clickevent;
  }

  removeMenuItem(domid) {
    $('#'+domid).parent().remove()
  }

  // TODO: re-enable for Publish to Studio feature
  // This might actually be totally unused...
  // appendMenuItem(domid) {
  //   let li = document.createElement('li')
  //   let a = document.createElement('a')
  //   a.setAttribute('id', 'dropdown-importToCloud')
  //   a.setAttribute('data-i18n', 'docs.menu.import.cloud')
  //   a.textContent = "Publish To Studio"

  //   li.appendChild(a)

  //   $('#'+domid).append(li)
  // }
  
  oneclick() {
    var self = this;
    return(event) => {      
      var trel = event.target;
      if (trel.className.indexOf('moreMenuClass') >= 0 || trel.className == 'icon-more' || croogloocurrentpage.getAccessLevel() < 2)
        return false;
      while (trel.tagName != "TR") {
        trel = trel.parentElement;
      }
      let isSelecting = !(event.ctrlKey || event.metaKey) || !trel.classList.contains('selected-document');
      let affectedRows;
      let docRowSelector = '#doc-table > tbody > tr';
      if(event.shiftKey) {
        let allDocRows = document.querySelectorAll(docRowSelector);
        let firstShiftSelectItem;

        if(mainList.lastNonShiftClickedElem && [].includes.call(allDocRows, mainList.lastNonShiftClickedElem)) {
          firstShiftSelectItem = mainList.lastNonShiftClickedElem;
        } else {
          firstShiftSelectItem = document.querySelector(docRowSelector); // first row
        }

        if(firstShiftSelectItem === trel) {
          affectedRows = [trel];
        } else {
          affectedRows = [];
          let isAddingElements = false;
          for(let docRow of allDocRows) {
            if(docRow === trel || docRow === firstShiftSelectItem) {
              if(isAddingElements) {
                affectedRows.push(docRow);
                break;
              } else {
                isAddingElements = true;
              }
            }
            if(isAddingElements) {
              affectedRows.push(docRow);
            }
          }
        }
      } else {
        affectedRows = [trel];
        mainList.lastNonShiftClickedElem = trel;
      }

      if(!(event.ctrlKey || event.metaKey)) {
        for(let selectedRow of document.querySelectorAll('#doc-table tr.selected-document')) {
          selectedRow.classList.remove('selected-document');
          multiSelectPanel.removeSelectedItem(selectedRow.id);
        }
      }
      for(let row of affectedRows) {
          if (isSelecting) {
          row.classList.add('selected-document');
          let itemid = row.getAttribute('item-id');
          multiSelectPanel.addSelectedItem(row.id, 
          mainList.getById(itemid || row.id) || { id: row.id, itemid: itemid });
        } else {
          row.classList.remove('selected-document');
          multiSelectPanel.removeSelectedItem(row.id);
        }
      }

      updateSidePanelsVisibility();
    };
  }
  
  dblclick() {
    var self = this;
    let url;
    return async (event) => {
      if(event.target.closest('a.moreMenuClass') !== null) {
        console.debug('prevent dbl click event from 3-dot menu');
        return;
      }
      let newPath = extraParams.path?extraParams.path:[];
      if (['folder','dbfolder','gDriveFolder', 'boxfolder'].includes(self.type)) {
        newPath.push(self.name);
        sessionStorage.setItem('keepComponent', true);
        var nextFolder = location.hash.split("#")[1] + '/' + self.data;
        let nextPageParams = {multiSelectPanel: multiSelectPanel.selectedItems, path: newPath};
        if(self.type == 'gDriveFolder') {
          let gDrivePath = Utility.getPageParam('gDrivePath');
          gDrivePath.push({
            folderName: self.name,
            folderId: self.itemid
          });
          nextPageParams.gDrivePath = gDrivePath;
        }
        r.loadPage(nextFolder, nextPageParams);
      } else if (['file','dbfile','gDriveFile', 'boxfile'].includes(self.type) && croogloocurrentpage.getAccessLevel()>=1) {
        if(!self.itemid.toLowerCase().endsWith('pdf')){
            DocUtility.openFile(self.itemid, self.name, self.watermark, false, null, self.data);
        }else{
          showSpinner();
          DocUtility.getFileURL(self.itemid, self.watermark, null).then(fetchedURL => {         
            buildFileViewer(fetchedURL); 
            hideSpinner();  
          });
          
        }
        
      } 
      else if (self.type == 'dblink') {
        if (croogloo_auth.crooglooauth.dropboxToken && croogloo_auth.crooglooauth.dropboxToken !== '403') {
          r.loadPage('documents/Dropbox', { path: [self.name] });
        } else if(!croogloo_auth.crooglooauth.dropboxToken) {
          self.dropboxlink();
        }
      } 
      else if(self.type == 'gDriveLink') {
        CGGoogleAuth.signIn(function() {
          r.loadPage('documents/gDrive', {
            path: ['Google Drive'],
            gDrivePath: [{
              folderName: self.name,
              folderId: 'root'
            }]
          });
        });
      }
      else if(self.type == 'boxlink'){ 
          if (croogloo_auth.crooglooauth.boxToken) {
          await croogloo_auth.box(croogloo_auth.crooglooauth.boxRefreshToken, true)
          r.loadPage('documents/Box', { path: [self.name] });
        }
        else {
          self.boxlink();
        }
      }
    }
  }
}

function buildFileViewer(fileLink) {

  let overlay = document.getElementById('overlay');
  let viewBody = document.getElementById('viewerBody');
  let viewContainer = document.getElementById('fileContainer');
  let btn = document.getElementById('closeBtn')

  let fileIframe = document.createElement('iframe');
  fileIframe.setAttribute('src', fileLink);
  fileIframe.innerHTML = "Iframe not supported"; 

  singleSelectPanel.hide();

  viewContainer.classList.add('active');
  overlay.classList.add('active');

  viewBody.appendChild(fileIframe);

  btn.addEventListener('click', () =>{

    viewContainer.classList.remove('active');
    overlay.classList.remove('active');
    viewBody.removeChild(fileIframe);
  });

  overlay.addEventListener('click', ()=>{
    viewContainer.classList.remove('active');
    overlay.classList.remove('active');
    viewBody.removeChild(fileIframe);
  })
}

class BoxFileList extends CrooglooFileList {
  reselect() {}

  merge() {
    return (event)=> {
      cgToast(i18n.t("js.trdparty.err.merge", {thirdparty: "Box"}));
    }
  }

  boxlink() {
    var w = 450;
    var h = 600;
    var left = 0;
    var top = 0;
    var newwindow = window.open("/box.html", "boxauth", 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=' + w + ',height=' + h + ',top=' + top + ',left=' + left);
  }

    importFilesAndFolders(targetItem = null) {
  }

  fetch() {
    var self = this;
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${croogloo_auth.crooglooauth.boxToken}`);
    myHeaders.append("Content-Type", "application/json");
    let path = decodeURI(window.location.hash.replace('#', '').split('/')).split(',')
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    

      return new Promise((done, err) => {
      fetch(`https://api.box.com/2.0/folders/${path.length > 2 ? self.path : '0'}/items`, requestOptions)
      .then(response => response.text())
      .then(result => {
        let itemlist = []
        result = JSON.parse(result)
        result.entries.forEach((item, index) => {
          let id = item.id;
          let type = item.type == 'folder' ? 'boxfolder' : 'boxfile';
          let name = item.name;
          let datemod = ''
            let data = item.type === 'folder' ? item.id : encodeURI(`https://app.box.com/file/${item.id}`);
          itemlist.push(new FileListItem(id, type, '', data, name, datemod));
        })

        self.update({itemlist: itemlist});
          done()
      },(err)=>{
        if (err.status >= 400 && err.status < 500) {
          cgToast(i18n.t("js.trdparty.err.denied", {thirdparty: "Box"}));
          croogloo_auth.boxunlink().then(()=>{
            croogloocurrentpage.onBoxLogin = function() {
              r.loadPage('documents/Box');
            }
            self.boxlink();
          })
        } else if(err.status > 500){
          cgToast(i18n.t("js.trdparty.err.connection", {thirdparty: "Box"}));
        }else {
          cgToast(i18n.t("js.trdparty.err.load", {thirdparty: "Box"}));
        }
      }).catch((err)=>{
        console.error(err);
        cgToast(i18n.t("js.trdparty.err.load", {thirdparty: "Box"}));
      });
    });
  }
}

class DropboxFileList extends CrooglooFileList {
  reselect() {

  }

  merge() {
    return (event)=> {
      cgToast(i18n.t("js.trdparty.err.merge", {thirdparty: "Dropbox"}));
    }
  }

  dropboxlink() {
    var w = 450;
    var h = 600;
    var left = 0;
    var top = 0;
    var newwindow = window.open("/dropbox.html", "dpauth", 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=' + w + ',height=' + h + ',top=' + top + ',left=' + left);
  }

  importFilesAndFolders(targetItem = null) {
    let formData = new FormData();
    formData.append('fileIdList', targetItem || Object.values(multiSelectPanel.selectedItems).map(o => o.itemid).join(','));
    upload.listFromDropbox(formData).then(dbResp => {
      console.debug('db importFilesAndFolders resp: ', dbResp);
    }).catch(err => {
      if(err !== 'cancelled') {
        cgToast(i18n.t("js.utils.server.error"), err);
      } else {
        console.debug('mass dropbox import cancelled');
      }
    });
  }

  

  fetch() {
    var self = this;
    return new Promise((done, err) => {
      console.log('dropbox path is: "'+self.path+'"');
      var dbx = new Dropbox({accessToken: croogloo_auth.crooglooauth.dropboxToken});
      dbx.filesListFolder({path: decodeURI(self.path)}).then(function(response) {
        var itemlist = [];
        for (var itemidx in response.entries) {
          var item = response.entries[itemidx];
          var id = item.id;
          var type = item['.tag'] == 'folder' ? 'dbfolder' : 'dbfile';
          var name = item.name;
          var datemod = type == 'dbfile' ? item.client_modified : '';
          var currentPath = decodeURI(window.location.hash.replace('#', '').split('/').slice(2).join('/'));
          currentPath = currentPath === '' ? currentPath : currentPath + ' /';
          var data = type === 'dbfolder' ? name : encodeURI('https://www.dropbox.com/preview/' + currentPath + name);
          itemlist.push(new FileListItem(id, type, '', data, name, datemod));
        }
        self.update({itemlist: itemlist});
        done();
      },(err)=>{
        if (err.status >= 400 && err.status < 500) {
          cgToast(i18n.t("js.trdparty.err.denied", {thirdparty: "Dropbox"}));
          croogloo_auth.dropboxunlink().then(()=>{
            croogloocurrentpage.onDropboxLogin = function() {
              r.loadPage('documents/Dropbox');
            }
            self.dropboxlink();
          })
        } else if(err.status > 500){
          cgToast(i18n.t("js.trdparty.err.connection", {thirdparty: "Dropbox"}));
        }else {
          cgToast(i18n.t("js.trdparty.err.load", {thirdparty: "Dropbox"}));
        }
      }).catch((err)=>{
        console.error(err);
        cgToast(i18n.t("js.trdparty.err.load", {thirdparty: "Dropbox"}));
      });
    });
  }
}
class GDriveFileList extends CrooglooFileList {
  static signOutGDrive() {
  }
  clickevent(){

  }
  reselect() {

  }
  merge() {
    return (event)=> {
      cgToast(i18n.t("js.trdparty.err.merge", {thirdparty: "Google Drive"}));
    }
  }
  fetch() {
    var self = this;
    return new Promise(function(done, err) {

      let gDrivePath = Utility.getPageParam('gDrivePath');

      try {
        if(typeof self.path !== 'string' || gDrivePath === null) {
          throw 'Invalid page params';
        }
        let nbOfUrlParams = (self.path.match(/\/[^\/]/g)||[]).length;
        if(nbOfUrlParams >= (gDrivePath.length||0)) {
          throw 'Missing google drive path ids';
        }
        gDrivePath = gDrivePath.slice(0, nbOfUrlParams+1);
      } catch(e) {
        console.error(e);
        r.loadPage('documents');
        return;
      }

      CGGoogleAuth.apicall(gDrivePath)
        .then(resp => {
        var itemlist = [];
        resp.files.forEach(item => {
          var id = item.id;
          var type = item.mimeType.endsWith('folder') ? 'gDriveFolder' : 'gDriveFile';
          var name = item.name + ((typeof item.mimeType == 'string' && item.mimeType.endsWith('pdf') && !item.name.endsWith('pdf')) ?'.pdf' :'');
          var datemod = item.modifiedTime ?item.modifiedTime :'';
          var currentPath = window.location.hash.replace('#', '').split('/').slice(2).join('/');
          currentPath = currentPath === '' ? currentPath : currentPath + ' /';
          var data = type === 'gDriveFolder' ? name : item.webViewLink;
          itemlist.push(new FileListItem(id, type, '', data, name, datemod, i18n.t("js.documents.shared."+ (item.shared? "confidential":"not"))));
        })
        self.update({itemlist: itemlist});
        done();
      },(err)=>{
        if (err.status >= 400) {
          cgToast(i18n.t("js.trdparty.err.denied", {thirdparty: "Google Drive"}));
          croogloo_auth.dropboxunlink().then(()=>{
            r.loadPage('documents');
          })
        } else {
          cgToast(i18n.t("js.trdparty.err.load", {thirdparty: "Google Drive"}))
        }
      }).catch((err)=>{
        console.error(err);
        cgToast(i18n.t("js.trdparty.err.load", {thirdparty: "Google Drive"}))
      });
    });
  }
}

/**
 * 
 * @param {String} shared string representation of what groups this document is already shared with
 * @param {} callback Not quite sure how this is used but it's a callback for the sharedHtmlInput
 */
function getSharedField(shared, callback) {
  // HTML that will contain the checkboxes for the security list elements to share this document with.
  var sharedHtmlInput = '';
  var existingCheckedSharedSecurityGroups = shared ? replaceDHInShared(shared.split(",")) : [];
  var secListItems = [];
  var obj = new Object();
  apicall('securityadminapi', 'fetchSecurityLists', obj).then(function(resp) {
    if(resp.items){
      sharedHtmlInput = '<div id="sharedInput">';
      for(var i = 0; i < resp.items.length; i++){
        secListItems.push(resp.items[i].properties);
      }
      secListItems.sort(function(a, b) {
        return parseInt(a.displayOrder) - parseInt(b.displayOrder);
      });
      for(var i = 0; i < secListItems.length; i++){
        var item = secListItems[i].securityListId;
        // Capitalize the first letter only, lowercase the rest. Ex:  Admin, Exec
        var label = item[0] + item.substring(1, item.length).toLowerCase()
        // Special case where DH needs to be displayed as DTR but the id/value itself should not change.
        // The formatting for DH is Dh here
        if (label == 'Dh') {
          label = replaceDHInShared(label)
        }
        // the ADMIN list and the user's security list must be checked and disabled
        // to prevent the user from revoking his own access or an admin's access to a file
        // (even though the backend wouldn't allow ADMIN access to be revoked)
        if (item.toUpperCase() == 'ADMIN' ||
          typeof croogloo_auth.crooglooauth.securityListId == 'string' &&
          croogloo_auth.crooglooauth.securityListId.toUpperCase() == item.toUpperCase()) {
          sharedHtmlInput += '<input type="checkbox" name="sharedInput" id="' + item + '" disabled checked>&nbsp;' + label + '&emsp;';
        } else {
          // If the document is already shared with an existing security group
          if (existingCheckedSharedSecurityGroups.includes(label.toUpperCase())) {
            sharedHtmlInput += '<input type="checkbox" name="sharedInput" id="' + item + '" checked>&nbsp;' + label + '&emsp;';
          } else {
            sharedHtmlInput += '<input type="checkbox" name="sharedInput" id="' + item + '">&nbsp;' + label + '&emsp;';
          }
        }
      }
      sharedHtmlInput += '</div>';
    }
    callback && callback(sharedHtmlInput);
  });
}
function getCurrentFolderId() {
  try {
    let localpath = location.hash.replace('#','').split('/');
    localpath[0] = 'ROOT';
    return localpath[localpath.length-1];
  } catch(e) {
    console.error(e);
    sessionStorage.clear();
  }
}

class SingleItemSidePanel extends SidePanel {
  constructor() {
    super(...arguments);
    this.htmlElement.style.width = '350px';
    this.htmlElement.style.right = '-355px';
    let that = this;
    fillContent();
    function fillContent() {
      let content = that.htmlElement.querySelector('.panel-content');
      content.classList.add('single-item');

      let panelSelectedCount = content.querySelector('div.panel-selected-count');
      panelSelectedCount.style.marginBottom = '6px';

      let fileOptionMenu = document.createElement('div');
      fileOptionMenu.className = 'panel-file-options-container'
      let fileOptionMenuBtn = document.createElement('i');
      fileOptionMenuBtn.className = 'icon-more panel-file-options';
      fileOptionMenuBtn.setAttribute('data-toggle', 'dropdownmenuitem');
      that.fileOptionMenuBtn = fileOptionMenuBtn;
      fileOptionMenu.appendChild(fileOptionMenuBtn);
      content.insertBefore(fileOptionMenu, panelSelectedCount);

      fileOptionMenuBtn.onclick = function(event) {
        let correspondingFileMenuBtn = that.corresponding3DotMenu;
        // trigger the related item's onclick event to update the menu accordingly
        correspondingFileMenuBtn.onclick(event);
      };

      content.appendChild(document.createElement('hr'));

      let filenameDiv = document.createElement('div');
      filenameDiv.className = 'single-panel-filename';
      content.appendChild(filenameDiv);

      content.appendChild(document.createElement('hr'));

      let dataList = document.createElement('div');
      dataList.style.width = '100%';
      dataList.style.height = 'auto';
      dataList.style.position = 'relative';
      content.appendChild(dataList);

      createDataListItem(i18n.t("documents.shared"), 'single-panel-shared-with');
      createDataListItem(i18n.t("type"), 'single-panel-type'); // file, TODO regular folder, smart folder, archive
      createDataListItem(i18n.t("category"), 'single-panel-category');
      createDataListItem(i18n.t("modified-last"), 'single-panel-last-modified');
      createDataListItem(i18n.t("episode"), 'single-panel-episode');
      createDataListItem(i18n.t("color"), 'single-panel-color');
      createDataListItem(i18n.t("watermark"), 'single-panel-watermark');
      createDataListItem(i18n.t("encryption"), 'single-panel-encryption');
      createDataListItem(i18n.t("filters"), 'single-panel-item-filters');
      
      // createDataListItem('Description', 'single-panel-description'); // for future considerations

      function createDataListItem(name, className) {
        let dataListItem = document.createElement('div');
        dataListItem.className = 'single-panel-list-item';

        let labelDiv = document.createElement('div');
        labelDiv.textContent = name;
        labelDiv.className = 'single-panel-item-label';
        labelDiv.classList.add(className);

        let contentDiv = document.createElement('div');
        contentDiv.className = 'single-panel-item-content';
        contentDiv.classList.add(className);

        dataListItem.appendChild(labelDiv);
        dataListItem.appendChild(contentDiv);

        dataList.appendChild(dataListItem);
      }

    }
  }

  get corresponding3DotMenu() {
    try {
      return document.getElementById(Object.keys(this.selectedItems)[0]).querySelector('td a.moreMenuClass');
    } catch(err1) {
      console.error(err1);
      return null;
    }
  }

  hide() {
    if(lastClickedMenuTarget !== null && lastClickedMenuTarget === this.fileOptionMenuBtn) {
      $('#dropdownmenuitem').foundation('close');
    }
    super.hide();
  }

  update(data = null) {
    this.lastUpdate = new Date().getTime();

    let nbOfSelectedItems = Object.keys(this.selectedItems).length;
    if(nbOfSelectedItems !== 1) {
      console.debug('SingleItemSidePanel requires exactly one selected item upon update; found ' + nbOfSelectedItems.toString() + '.');
      return;
    }
    let selectedItem = Object.values(this.selectedItems)[0];

    // only show 3-dot menu if there's one of the corresponding row
    this.fileOptionMenuBtn.style.visibility = this.corresponding3DotMenu == null ? 'hidden':'visible';

    if(data != null) {
      try {
        for(let dataEntry of Object.entries(data)) {
          if(selectedItem.hasOwnProperty(dataEntry[0])) {
            selectedItem[dataEntry[0]] = dataEntry[1];
          }
        }
      } catch(err1) {
        console.error('SingleItemSidePanel data entry update failed', data);
        console.error(err1);
      }
    }
    let content = this.htmlElement.querySelector('.panel-content');
    updateFilename();

    updateDataListItem(
      'single-panel-shared-with',
      replaceDHInShared(selectedItem.shared).replace(/,/g, ', ') || i18n.t("js.documents.shared.everyone")
    );

    let itemType = i18n.t("specified.not");
    const SMART_FOLDER_TYPE = 'smart folder';
    if(selectedItem.type) {
      itemType = selectedItem.type != 'folder'
        ?'file'
        :(selectedItem.subType == 'smartFolder'
          ?SMART_FOLDER_TYPE
          :(selectedItem.name.toLowerCase().endsWith('.zip')
            ?'archive'
            :'regular folder'));
    }
    updateDataListItem('single-panel-type', i18n.t(itemType));
    
    if(selectedItem.type != 'folder') {
      this.hideDataListItems(['single-panel-item-filters']);
    } else {
      this.showDataListItems(['single-panel-item-filters']);
      let panelItemLabel = content.querySelector(`div.single-panel-item-label.single-panel-item-filters`);
      let panelItemContent = content.querySelector(`div.single-panel-item-content.single-panel-item-filters`);
      panelItemLabel.classList.remove('active-filters');
      panelItemContent.classList.remove('active-filters');
      if(itemType == SMART_FOLDER_TYPE) {
        updateDataListItem('single-panel-item-filters', i18n.t("utils.loading"));
        let expectedTimeStamp = this.lastUpdate;
        apicall('documentsapi', 'fetchFolderFilters', { fileId: selectedItem.itemid }).then(resp => {
          if(expectedTimeStamp != this.lastUpdate) {
            return; // update overridden by a more recent one
          }
          if(typeof resp == 'object' && resp.hasOwnProperty('filter_category')) {
            updateDataListItem('single-panel-item-filters', formatFolderFilters(resp), false);
            panelItemLabel.classList.add('active-filters');
            panelItemContent.classList.add('active-filters');
          } else {
            onFilterRetrievalFailure();
          }
        }).catch(() => {
          if(expectedTimeStamp != this.lastUpdate) {
            return; // update overridden by a more recent one
          }
          onFilterRetrievalFailure();
        });
        function onFilterRetrievalFailure() {
          console.error('failed to retrieve smart folder filters');
          updateDataListItem('single-panel-item-filters', i18n.t("unknown_"));
        }
        function formatFolderFilters(filters) {
          let dateFormatPatter = /^\w+\s(\w+\s\w+\s\w+)\s[\s\S]+$/;
          let MAX_SIZE = '1000000000'; // must match defaultvalue property for maxSize input in documents.html
          return `<b>${i18n.t("category")}:</b> ${filters.filter_category == '*' 
              ?i18n.t("anything-goes") :getFormattedCategory(filters.filter_category).toLowerCase()}<br>`
            + `<b>${i18n.t("type")}:</b> ${filters.filter_type.replace('*', i18n.t("anything-goes")).replace(/,(?!\s)/g, ', ')}<br>`
            + `<b>${i18n.t("episode")}:</b> ${filters.filter_episode.replace('*', i18n.t("anything-goes"))}<br>`
            + `<b>${i18n.t("color")}:</b> ${filters.filter_color.replace('*', i18n.t("anything-goes"))}<br>`
            + `<b>${i18n.t("min-size")}:</b> ${filters.filter_minSize} ${i18n.t("MB")}<br>`
            + `<b>${i18n.t("max-size")}:</b> ${(filters.filter_maxSize+' '+i18n.t("MB"))
              .replace(new RegExp(`^${MAX_SIZE}\\s${i18n.t("MB")}$`), i18n.t("none_"))}<br>`
            + `<b>${i18n.t("min-upload")}:</b> ${new Date(filters.filter_minDateCreated).toString().replace(dateFormatPatter, '$1')}<br>`
            + `<b>${i18n.t("max-upload")}:</b> ${new Date(filters.filter_maxDateCreated).toString().replace(dateFormatPatter, '$1')}`
        }
      } else {
        updateDataListItem('single-panel-item-filters', i18n.t("n-a"));
      }
    }
    
    if(selectedItem.type == 'file') {
      this.showDataListItems(['single-panel-category', 
        'single-panel-last-modified', 
        'single-panel-episode', 
        'single-panel-color',
        'single-panel-watermark',
        'single-panel-encryption'
      ]);
      updateDataListItem('single-panel-category', getFormattedCategory());
      let lastModDateString = moment(selectedItem.datemod).locale(i18n.language).format(i18n.t("js.moment.documents.format-plus"));
      updateDataListItem('single-panel-last-modified', lastModDateString, false);
      updateDataListItem('single-panel-episode', i18n.t("utils.loading"));
      updateDataListItem('single-panel-color', i18n.t("utils.loading"));

      let expectedTimeStamp = this.lastUpdate;
      apicall('documentsapi', 'fetchEpisodeAndColor', { fileId: selectedItem.itemid }).then(resp => {
        if(expectedTimeStamp != this.lastUpdate) {
          return; // update overridden by a more recent one
        }
        if(typeof resp == 'object' && resp.hasOwnProperty('episode')) {
          updateDataListItem('single-panel-episode', resp.episode.replace(/^[0\s]*$/, i18n.t("none_")));
          updateDataListItem('single-panel-color', resp.color.replace(/([A-Z])/, ' $1') || i18n.t("n-a"));
        } else {
          onEpColorRetrievalFailure();
        }
      }).catch(() => {
        if(expectedTimeStamp != this.lastUpdate) {
          return; // update overridden by a more recent one
        }
        onEpColorRetrievalFailure();
      });
      function onEpColorRetrievalFailure() {
        console.error('failed to retrieve episode and color');
        updateDataListItem('single-panel-episode', i18n.t("unknown_"));
        updateDataListItem('single-panel-color', i18n.t("unknown_"));
      }
    } else {
      this.hideDataListItems(['single-panel-category', 
        'single-panel-last-modified', 
        'single-panel-episode', 
        'single-panel-color',
        'single-panel-watermark',
        'single-panel-encryption'
      ]);
    }

    if(selectedItem.watermark === true) {
      updateDataListItem('single-panel-watermark', i18n.t("utils.loading"));
      updateDataListItem('single-panel-encryption', i18n.t("utils.loading"));

      let expectedTimeStamp = this.lastUpdate;
      apicall('documentsapi', 'fetchWatermarkSettings', { fileId: selectedItem.itemid }).then(resp => {
        if(expectedTimeStamp != this.lastUpdate) {
          return; // update overridden by a more recent one
        }
        if(typeof resp == 'object' && resp.hasOwnProperty('isEncrypted')) {
          updateDataListItem('single-panel-watermark', `&#8226;&nbsp;${formatWatermarkLine(resp.firstLine)}<br>`
            + `&#8226;&nbsp;${formatWatermarkLine(resp.secondLine)}`, false);
          updateDataListItem('single-panel-encryption', resp.isEncrypted === '1' ? i18n.t("js.documents.wtmrk.aes"):i18n.t("none_"), false);
        } else {
          onWatermarkRetrievalFailure();
        }
        function formatWatermarkLine(line) {
          return line.replace(/^Other~([\s\S]*)$/, i18n.t("watermark.line1.label")+': "$1"')
            .replace(/^FullName$/, i18n.t("watermark.line1.rec-name"))
            .replace(/^Blank$/, i18n.t("watermark.line2.opts-blank"))
            .replace(/^(IP)(Address)$/, "$1 $2");
        }
      }).catch(() => {
        if(expectedTimeStamp != this.lastUpdate) {
          return; // update overridden by a more recent one
        }
        onWatermarkRetrievalFailure();
      });
      function onWatermarkRetrievalFailure() {
        console.error('failed to retrieve watermark settings');
        updateDataListItem('single-panel-watermark', i18n.t("unknown_"));
        updateDataListItem('single-panel-encryption', i18n.t("unknown_"));
      }
    } else {
      if(selectedItem.type == 'file' && selectedItem.itemid.toLowerCase().endsWith('.pdf')) {
        updateDataListItem('single-panel-watermark', i18n.t("none_"));
        updateDataListItem('single-panel-encryption', i18n.t("none_"));
      } else {
        updateDataListItem('single-panel-watermark', i18n.t("n-a"));
        updateDataListItem('single-panel-encryption', i18n.t("n-a"));
      }
    }

    function updateDataListItem(itemClass, value, autoLowerCase = true) {
      content.querySelector(`div.single-panel-item-content.${itemClass}`)
        .innerHTML = (value.includes('.') || !autoLowerCase) ? value:value.toLowerCase();
    }

    function getFormattedCategory(category = selectedItem.category) {
      switch(category) {
        case 'callsheet': return i18n.t("call-sheets");
        case 'castList': return i18n.t("cast-list");
        case 'chrono': return i18n.t("chrono");
        case 'crewList': return i18n.t("crew-list");
        case 'oneliner': return i18n.t("oneliner");
        case 'dood': return i18n.t("doods");
        case 'maps': return i18n.t("maps");
        case 'photo': return i18n.t("photo");
        case 'production': return i18n.t("prod-sched");
        case 'shooting': return i18n.t("shoot-sched");
        case 'prepschedule': return i18n.t("prepschedule");
        case 'dpr': return i18n.t("dpr");
        case 'breakdown': return i18n.t("breakdown");
        case 'clearance': return i18n.t("clearance");
        case 'script': return i18n.t("script");
        case 'script timing': return i18n.t("script-timing");
        case 'revision': return i18n.t("revision");
        case 'side': return i18n.t("utils.sides");
        case 'techsurvey': return i18n.t("tech-survey");
        case 'other': return i18n.t("other");
        default: return i18n.t("specified.not");
      }
    }

    function updateFilename() {
      let filenameDiv = content.querySelector('div.single-panel-filename');
      while(filenameDiv.firstChild) {
        filenameDiv.removeChild(filenameDiv.firstChild);
      }
      filenameDiv.appendChild(getIconForFileType());
      let filenameSpan = document.createElement('span');
      filenameSpan.textContent = selectedItem.name;
      filenameDiv.appendChild(filenameSpan);
      function getIconForFileType() {
        if(selectedItem.type == 'folder' && selectedItem.subType == 'smartFolder') {
          let imgIcon = new Image(25, 25);
          imgIcon.src = IMG_DIRECTORY + 'smart-folder.png';
          imgIcon.style.maxWidth = '25px';
          imgIcon.style.marginLeft = '-2px';
          return imgIcon;
        } else {
          let lowercasedId = selectedItem.itemid.toLowerCase();
          let iconClassName = null;
          if(selectedItem.type == 'folder') {
            iconClassName = selectedItem.name.toLowerCase().endsWith('.zip') 
              ?'icon-zip-large' 
              :'icon-folder';
          } else {
            if(lowercasedId.endsWith('.pdf')) {
              iconClassName = 'icon-file-pdf';
            } else if(lowercasedId.endsWith('.xls') 
                || lowercasedId.endsWith('.xlsm')) {
              iconClassName = 'icon-file-excel';
            } else if(lowercasedId.endsWith('.png') 
                || lowercasedId.endsWith('.jpg') 
                || lowercasedId.endsWith('.jpeg') 
                || lowercasedId.endsWith('.gif')) {
              iconClassName = 'icon-file-picture';
            } else {
              iconClassName = 'icon-file';
            }
          }
          let icon = document.createElement('i');
          icon.style.position = 'relative';
          icon.className = iconClassName;
          if(selectedItem.watermark === true && selectedItem.isEncrypted === true) {
            let lockIcon = document.createElement('i');
            lockIcon.className = 'icon-lock';
            lockIcon.title = i18n.t("js.documents.encrypted");
            icon.appendChild(lockIcon);
          }
          return icon;
        }
      }
    }
  }

  showDataListItems(itemClassList) {
    for(let itemClass of itemClassList) {
      this.panelContent.querySelector(`div.single-panel-item-content.${itemClass}`)
        .parentElement.classList.remove('hidden-panel-item');
    }
  }

  hideDataListItems(itemClassList) {
    for(let itemClass of itemClassList) {
      this.panelContent.querySelector(`div.single-panel-item-content.${itemClass}`)
        .parentElement.classList.add('hidden-panel-item');
    }
  }
}

function generateWatermarkingOption() {
  var wmSettingsSwalContainer = document.createElement('div');
  wmSettingsSwalContainer.id = 'wmSettingsSwalContainer';
  //////////////////////////////////////////////////////////////////////
  //first line options
  let firstLineDiv = new WatermarkOptionDiv('lineOne', i18n.t("watermark.line1.title"));
	firstLineDiv.addLine([
		{id: 'wmFullName', label: i18n.t("watermark.line1.rec-name"), type: 'checkbox'}
	]);
	firstLineDiv.addLine([
		{id: 'firstOtherCheckbox', label: i18n.t("other"), type: 'checkbox'},
		{id: 'firstOtherInput', label: null, type: 'text'}
	]);
	firstLineDiv.build(wmSettingsSwalContainer);
  
  //second line options
  let secondLineDiv = new WatermarkOptionDiv('lineTwo', i18n.t("watermark.line2.title"));
	secondLineDiv.addLine([
		{id: 'wmTitle', label: i18n.t("watermark.line2.opts-title"), type: 'checkbox'},
		{id: 'wmDepartment', label: i18n.t("watermark.line2.opts-dept"), type: 'checkbox'}
	]);
	secondLineDiv.addLine([
		{id: 'wmDate', label: i18n.t("watermark.line2.opts-date"), type: 'checkbox'},
		{id: 'wmIPAddress', label: i18n.t("watermark.line2.opts-ip"), type: 'checkbox'}
	]);
	secondLineDiv.addLine([
		{id: 'wmBlank', label: i18n.t("watermark.line2.opts-blank"), type: 'checkbox'}
	]);
	secondLineDiv.addLine([
		{id: 'secondOtherCheckbox', label: i18n.t("watermark.line2.opts-other"), type: 'checkbox'},
		{id: 'secondOtherInput', label: null, type: 'text'}
	]);
  secondLineDiv.build(wmSettingsSwalContainer);

  WatermarkOptionDiv.addEncryptionOption(wmSettingsSwalContainer);

  //////////////////////////////////////////////////////////////////////
  //the elements' event listeners from watermarkingOptions.js do not work
  //on swal
  $(document).on('click', '.wm-checkbox-lineOne', function() {
    if(this.checked) {
      currentWmFirstLine = this.id
        .match(/Other|Title|Department|FirstName|LastName|FullName|Blank|IPAddress|Date/)[0];
    } else {
      currentWmFirstLine = '';
    }
    var ref = this;
    $('.wm-checkbox-lineOne').each(function() {
      if(ref.id !== this.id) {
        this.checked = false;
      }
    });
  });
  $(document).on('click', '.wm-checkbox-lineTwo', function() {
    if(this.checked) {
      currentWmSecondLine = this.id
        .match(/Other|Title|Department|FirstName|LastName|FullName|Blank|IPAddress|Date/)[0];
    } else {
      currentWmSecondLine = '';
    }
    let ref = this;
    $('.wm-checkbox-lineTwo').each(function() {
      if(ref.id !== this.id) {
        this.checked = false;
      }
    });
  });
  $(document).on('focus', '#firstOtherInput', function() {
    if(!$('#firstOtherCheckbox').is(':checked')) {
      $('#firstOtherCheckbox').click();
    }
  });
  $(document).on('blur', '#firstOtherInput', function() {
    wmFirstOtherField = this.value;
    console.debug('changing wmFirstOtherField for ' + wmFirstOtherField);
  });
  $(document).on('focus', '#secondOtherInput', function() {
    if(!$('#secondOtherCheckbox').is(':checked')) {
      $('#secondOtherCheckbox').click();
    }
  });
  $(document).on('blur', '#secondOtherInput', function() {
    wmSecondOtherField = this.value;
    console.debug('changing wmSecondOtherField for ' + wmSecondOtherField);
  });
  return wmSettingsSwalContainer.outerHTML;
}
