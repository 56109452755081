import i18n from './components/Translation';
import DataFetcher from './utils/dataUtility.js';

export function init() {
  $('#main').foundation();
  loader.add(showSpinner)
    .add(getAllData, ['depts','distroLists'])
    .add(bind)
    .execute();
}

var departmentList;
var distributionLists;

function getAllData() {
  fetchDepartments(() => loader.check('depts'));
  fetchDistributionLists(() => loader.check('distroLists'));
}

function fetchDepartments(done) {
  departmentList = [];
  apicall('personapi', 'fetchDepartments', {}).then(function(resp){
    if(resp && resp.items) {
      console.debug(resp.items);
      Array.prototype.push.apply(departmentList, resp.items);
    }
    done();
  }, err => {
    console.error(err);
    done();
  });
}

function fetchDistributionLists(done) {
  DataFetcher.fetchDistributionLists().then(lists => {
    distributionLists = lists;
    done();
  }, function() {
    done();
  });
}

function bind() {
  var inputfile = document.getElementById('fileinput');
  inputfile.onchange = (e)=>{
    var files = inputfile.files;
    processFiles(files);
  }
  function processFiles(files) {
    if (files.length > 0) {
      var formdata = new FormData();
      var hasFiles = true;
      let filenameList = [];
      for(var idx=0;idx<files.length;idx++){
        var filename = files[idx].name;
        if (filename.endsWith(".vcf")){
          formdata.append('file', files[idx]);
          filenameList.push(filename);
        } else {
          hasFiles=false;
        }
      }
      if (hasFiles) {
        swal({
          title: i18n.t("js.vcard.import.title"),
          type: 'question',
          html: getDepartmentSelect(filenameList.join(',')) + getDistroListSelect(),
          confirmButtonText: i18n.t("js.vcard.upload"),
          confirmButtonColor: '#13C46A',
          type: 'question',
          showCloseButton: true,
          showCancelButton: false,
          useRejections: true, //important for swal2 v7.1.2
          expectRejections: true,
          allowOutsideClick: true,
          onOpen: function() {
            let defaultDeptName = i18n.t("js.vcard.dept.default");
            let defaultDeptVal = 'DEPARTMENT_DEFAULT';
            let selectedOpt = document.querySelector('#departmentSelect > '
              + 'option[value="'+defaultDeptVal+'"]');
            if(selectedOpt != null) {
              selectedOpt.selected = true;
            } else {
              let deptSelect = document.getElementById('departmentSelect');
              createSelOption(deptSelect, defaultDeptVal, defaultDeptName, true);
              [].slice.call(document.querySelectorAll('#departmentSelect > option'))
              .sort((a, b) => { return a.textContent.localeCompare(b.textContent) })
              .forEach(deptOpt => {
                deptSelect.appendChild(deptOpt);
              });
            }
            $('#distroListSelect').chosen({
              no_results_text: i18n.t("js.vcard.none"),
              width: '100%'
            });
          },
          preConfirm: function () {
            return new Promise(function(resolve, reject) {
              let deptId = document.getElementById('departmentSelect').value;
              if(!deptId) {
                reject(i18n.t("js.vcard.selection"));
                return;
              }
              try {
                resolve({
                  dept: {
                    name: document.querySelector('#departmentSelect option[value='+deptId+']').textContent,
                    id: deptId
                  },
                  distroListIds: [
                    $('#distroListSelect').val()
                  ]
                });
              } catch(e) {
                console.error(e);
                reject(i18n.t("js.vcard.error"));
              }
            });
          }
        }).then(function(options) {
          let dept = options.dept;
          let distroListIds = options.distroListIds;
          console.debug(dept);
          console.debug(distroListIds);
          let not = document.createElement('span');
          not.innerText = i18n.t("utils.loading");
          let notItem = cgNotification.addNotification(i18n.t("js.vcard.importing"), not);
          authenticatedcall('/importVcard?isCast=false&deptId='+dept.id+'&deptName='
              + dept.name + '&distroListIds=' + (distroListIds.join(',')||','),
              'POST',formdata,"multipart/form-data").then(()=>{
                cgToast(i18n.t("js.vcard.import.finished") + " <a style='color:lightblue;' href='#taskMonitoring'>" + i18n.t("js.vcard.import.check") + "</a>");
            not.innerText = i18n.t("response.success");
            console.warn('success');
          }, (err)=>{
            console.log(err);
            Utility.showErrorCgToast(i18n.t("js.vcard.import.error"));
            not.innerText = i18n.t("js.vcard.import.error");
          });
          cgToast(i18n.t("js.vcard.import.started"), 1000);
        }).catch(swal.noop);
      } else {
        cgToast(i18n.t("js.vcard.import.reject"));
      }
      inputfile.type='text';
      inputfile.type='file';
    }
  }

  function getDepartmentSelect(vCardFileName) {
    let container = document.createElement('div');

    let filename = document.createElement('p');
    filename.textContent = vCardFileName;
    filename.style.textDecoration = 'underline';
    filename.style.fontWeight = 'bold';
    container.appendChild(filename);

    let uploadText = document.createElement('p');
    uploadText.textContent = i18n.t("js.vcard.upload-to");
    uploadText.style.color = 'darkgray';
    container.appendChild(uploadText);

    let select = document.createElement('select');
    select.id = 'departmentSelect';
    departmentList.forEach(dept => {
      createSelOption(select, dept.key.name,
        dept.properties.departmentName.toUpperCase());
    });
    container.appendChild(select);

    return container.outerHTML;
  }

  function getDistroListSelect() {
    let container = document.createElement('div');

    let uploadText = document.createElement('p');
    uploadText.textContent = i18n.t("js.vcard.preselect");
    uploadText.style.color = 'darkgray';
    container.appendChild(uploadText);

    let select = document.createElement('select');
    select.setAttribute('data-placeholder', i18n.t("js.vcard.select"));
    select.setAttribute('multiple', 'multiple');
    select.id = 'distroListSelect';
    distributionLists.forEach(list => {
      createSelOption(select, list.key.name, list.properties.distributionListName);
    });
    container.appendChild(select);
    return container.outerHTML
  }

  function createSelOption(select, val, textContent, isSelected = false) {
    let deptOpt = document.createElement('option');
    deptOpt.value = val;
    deptOpt.textContent = textContent;
    deptOpt.selected = isSelected;
    select.appendChild(deptOpt);
  }

  var cloudicon = document.getElementById('cgUploadImg');
  var clicable = document.getElementById('mainDiv');
  clicable.onclick = () => {
      $(inputfile).click();
  }
  clicable.ondragover = (e) =>{
    e.preventDefault();
    cloudicon.style.opacity = '0.5';
  }
  clicable.ondragleave = (e) => {
    e.preventDefault();
    cloudicon.removeAttribute('style');
  }
  clicable.ondrop = (e)=>{
    cloudicon.removeAttribute('style');
    e.preventDefault();
    e.stopPropagation();
    processFiles(e.dataTransfer.files);
  };
}
