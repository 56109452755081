import i18n from './components/Translation';
import DocUtility from './components/DocUtility';
import DataFetcher from './utils/dataUtility.js';

export function init() {
  loader.add(ui)
    .add(initGlobals)
    .add(showSpinner)
    .add(loadSettings, 'settings')
    .add(initListeners)
    .execute();
}

var changedProps;

function ui() {
  $('#main').foundation();
}

function initGlobals() {
  changedProps = {};
}

function loadSettings() {
  let settingsBuildFct = {
    twoFactorAuth: build2faSetting,
    outboxDelay: buildOutboxDelaySetting,
    emailNotifRecipients: buildEmailNotifSetting,
    draftVisibility: buildDraftVisibilitySettings,
    photoActivityNotification: buildPhotoActivitySettings,
    requireAttachmentFileInfo: buildAttachmentFileInfoSettings,
    googleImportDeletedContacts: buildDeletedContactImportSettings,
    isInactivityTimeoutEnabled: (value, hasAccess) => buildBooleanUserSetting('inactivityLogoutContainer', 'isInactivityTimeoutEnabled', value, hasAccess),
    areConcurrentSessionsEnabled: (value, hasAccess) => buildBooleanUserSetting('concurrentSessionContainer', 'areConcurrentSessionsEnabled', value, hasAccess),
    isIpVerificationRequired: (value, hasAccess) => buildBooleanUserSetting('ipVerificationContainer', 'isIpVerificationRequired', value, hasAccess),
    daysToArchive: buildArchiveSetting,
    userLanguage: buildUserLanguage,
    scriptationAttachments: buildScriptationAttachments
    // watermarkOpacity: buildWatermarkOpacitySetting
  };
  
  apicall('productionapi', 'fetchProductionSettings', {}).then(resp => {
    if(resp.items.length) {
      loader.check('settings');
      for(let setting of resp.items) {
        try {
          if(settingsBuildFct.hasOwnProperty(setting.name)) {
            settingsBuildFct[setting.name](setting.value, hasAccess(setting.requiredSecurityPageName, setting.requiredAccessLevel));
          }
        } catch(e) {
          console.error('failed to build setting', e);
        }
      }
    } else {
      throw new Error('failed to retrieve settings');
    }
  }).catch(err => {
    console.error('failed to fetch production settings', err);
    loader.check('settings');
  });

  function hasAccess(securityPageName, requiredAccessLevel) {
    return croogloo_auth.getAccessLevel(securityPageName) >= requiredAccessLevel && croogloocurrentpage.getAccessLevel() === 2;
  }
}

function buildScriptationAttachments(value, hasAccess){
  let container = document.getElementById('scriptationAttContainer');
  container.querySelector(`input[value="${value}"]`).checked = true;

  if(!hasAccess) {
    container.querySelector('div:first-child').classList.add('read-only-title');
    for(let radioOption of container.querySelectorAll('input[type=radio]')) {
      radioOption.disabled = true;
    }
  }

  container.style.display = 'block';
  
  changedProps.scriptationAttachments = {
    initialValue: value
  };

  for(let radioOption of container.querySelectorAll('input[type=radio]')) {
    radioOption.onchange = onScriptationAttachmentHandlingChange;
  }

  function onScriptationAttachmentHandlingChange() {
    let selectedInputVal = container.querySelector(`input:checked`).value;
    if(selectedInputVal == changedProps.scriptationAttachments.initialValue) {
      delete changedProps.scriptationAttachments.newValue;
    } else {
      changedProps.scriptationAttachments.newValue = selectedInputVal;
    }
  }
}

function buildUserLanguage(value, hasAccess){
  let languages = [
    {textContent: 'English', value: 'en'},
    {textContent: 'Français', value: 'fr'}
  ];
  document.getElementById('userLanguageContainer').style.display = 'block';
  let select = document.getElementById("userLanguageSelect");

  if(languages.filter(lng => lng.value === value).length == 0){
    languages.push({textContent: 'Unknown/Invalid', value: value});
  }

  for(let language of languages){
    let option = document.createElement('option');
    option.value = language.value;
    option.textContent = language.textContent;
    select.appendChild(option);
  }

  select.value = value;
  select.disabled = !hasAccess;

  changedProps.userLanguage = {
    initialValue: value
  };

  select.onchange = function() {
    if(this.value == changedProps.userLanguage.initialValue){
      delete changedProps.userLanguage.newValue;
    }else{
      changedProps.userLanguage.newValue = this.value;
    }
  }
  try{
    if(value !== croogloo_auth.crooglooauth.lang){
      changeLanguage(value);
    }
  }catch(err){
    console.error(err)
  }
}

function build2faSetting(value, hasAccess) {
  let sms2facheckbox = document.getElementById('sms2facheckbox');
  sms2facheckbox.checked = value === '1';
  sms2facheckbox.disabled = !hasAccess;
  document.getElementById('twoFactorAuthContainer').style.display = 'block';
  if(!hasAccess) {
    document.querySelector('#twoFactorAuthContainer > div:first-child').classList.add('read-only-title');
    document.querySelector('label[for=sms2facheckbox]').style.cursor = 'not-allowed';
  } else {
    changedProps.twoFactorAuth = {
      initialValue: value === '1'
    }
    initListener();
  }

  function initListener() {
    let sms2facheckbox = document.getElementById('sms2facheckbox');
    sms2facheckbox.onchange = (event) => {
      if (!hasAccess) {
        cgToast(i18n.t("js.unauthorized"))
        return;
      }
      let newstatus = event.target.checked;      
      let message = i18n.t("js.2fa.text."+(newstatus ? "enable":"disable"));
      swal({
        title: i18n.t("js.2fa.title"),
        text: message,
        type: 'info',
        confirmButtonText: i18n.t("OK"),
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        showCloseButton: true
      }).then((result)=>{
        if(result) {
          if(newstatus === changedProps.twoFactorAuth.initialValue) {
            delete changedProps.twoFactorAuth.newValue;
            delete changedProps.twoFactorAuth.save;   
          } else {
            changedProps.twoFactorAuth.newValue = newstatus;
            changedProps.twoFactorAuth.save = function() {
              return new Promise(resolve => {
                apicall('securityadminapi','changeTwoFactorAuthForProduction',{})
                .then((resp)=>{
                  if (resp && resp.responseCode == "1") {
                    console.log('2fa saved');
                    changedProps.twoFactorAuth.initialValue = changedProps.twoFactorAuth.newValue;
                    delete changedProps.twoFactorAuth.newValue;
                    delete changedProps.twoFactorAuth.save;
                    if(newstatus) {
                      setTimeout(function() {
                        // allow 3.5 sec to see confirmation swal
                        croogloo_auth.logout();
                      }, 3500);
                    }
                    resolve();
                  } else {
                    cgToast(i18n.t("js.2fa.error"));
                    resetChangeData();
                    resolve();
                  }
                }, ()=>{
                  cgToast(i18n.t("js.2fa.error"));
                  resetChangeData();
                  resolve();
                }).catch(() => {
                  console.error('error saving 2fa');
                  resetChangeData();
                  resolve();
                });
              });
              function resetChangeData() {
                sms2facheckbox.checked = !newstatus;
                delete changedProps.twoFactorAuth.newValue;
                delete changedProps.twoFactorAuth.save;
              }
            }
          }
        }
      }, ()=>{
        sms2facheckbox.checked = !newstatus;
      });
    }
  }
}

function initListeners() {

  document.querySelector('#resetMetadataContainer button').addEventListener('click', () => {
    showSpinner()
    localStorage.setItem('isCrewListMetadataSet', 'false')
    apicall('productionapi', 'updateProductionSettings', {}, {'isCrewListMetadataSet': 'false'})
    .then(resp => {
      hideSpinner()
      console.log(resp)
    })
    .catch(err => {
      hideSpinner()
      console.log(resp)
    })
  })

  document.getElementById('invalidateCacheBtn').onclick = function() {
    showSpinner();
    DocUtility.clearStoredFiles();
    apicall('dataMaintenance','clearCache', {}, {})
    .then(async function(resp) {
        hideSpinner();
    }).catch(() => {
      hideSpinner();
      swalToast({
        title: i18n.t("js.refresh.cache.failure"),
        type: 'error'
      });
    });
  }
  document.getElementById('saveSettingsBtn').onclick = function() {
    let changesToSave = {};
    for(let key in changedProps) {
      if(changedProps[key].hasOwnProperty('newValue')) {
        changesToSave[key] = changedProps[key].save || changedProps[key].newValue;
      }
    }
    if(!Object.keys(changesToSave).length) {
      cgToast(i18n.t("js.nochanges"));
    } else {
      let saveFunctions = [];
      let valueOnlyChangeData = {};
      for(let changeKey in changesToSave) {
        if(typeof changesToSave[changeKey] == 'function') {
          saveFunctions.push(changesToSave[changeKey]);
        } else {
          valueOnlyChangeData[changeKey] = changesToSave[changeKey];
        }
      }
      showSpinner();
      apicall('productionapi', 'updateProductionSettings', {}, valueOnlyChangeData).then(async function(resp) {
        if(valueOnlyChangeData.userLanguage){
          changeLanguage(valueOnlyChangeData.userLanguage);
        }
        hideSpinner();
        if(resp.responseCode === '0') {
          console.debug('updated settings, processing '+ saveFunctions.length.toString() + ' save functions');
          for(let saveFunction of saveFunctions) {
            await saveFunction();
          }
          swalToast({
            title: i18n.t("js.settings.updated"),
            type: 'info',
            timer: 3500
          });
          for(let key in changedProps) {
            if(changedProps[key].hasOwnProperty('newValue') && !changedProps[key].hasOwnProperty('save')) {
              changedProps[key].initialValue = changedProps[key].newValue;
              delete changedProps[key].newValue;
            }
          }
        } else {
          throw new Error('failed to save settings');
        }
      }).catch(() => {
        hideSpinner();
        swalToast({
          title: i18n.t("js.settings.failed"),
          type: 'error'
        });
      });
    }
  }

  r.onunload = function() {
    return new Promise((resolve, reject) => {
      if(!Object.values(changedProps).filter(x => x.hasOwnProperty('newValue')).length) {
        resolve();
      } else {
        swal({
          title: i18n.t("js.discard"),
          html: i18n.t("js.unsaved"),
          type: 'warning',
          confirmButtonText: i18n.t("js.imsure"),
          showCancelButton: true,
          cancelButtonText: i18n.t("button.cancel"),
          allowOutsideClick: true,
          showCloseButton: true
        }).then(resolve).catch(reject);
      }
    });
  }
}

function changeLanguage(lang){
  let button = document.getElementById("langSwitch");
  button.setAttribute("data-locale", lang)
  button.textContent = lang;
  button.click();
  try{
    croogloo_auth.crooglooauth.lang = lang;
    secureLocalStorage.set("crooglooauth", JSON.stringify(croogloo_auth.crooglooauth));
  }catch(err){
    console.error(err);
  }
}

function buildArchiveSetting(value, hasAccess) {
  let intValue = parseInt(value);
  let container = document.getElementById('archiveSettingContainer');
  let input = document.getElementById('daysToArchive');

  input.value = intValue;
  input.disabled = !hasAccess;

  if(!hasAccess) {
    container.querySelector('div:nth-child(1)').classList.add('read-only-title');
  }

  container.style.display = 'block';

  changedProps.daysToArchive = {
    initialValue: value
  };

  input.onchange = function() {
    if(this.value == changedProps.daysToArchive.initialValue) {
      delete changedProps.daysToArchive.newValue;
    } else {
      changedProps.daysToArchive.newValue = this.value;
    }
  }
}

function buildOutboxDelaySetting(value, hasAccess) {
  let possibleSecondsDelay = [
    30, 60, 120
  ];

  let intValue = parseInt(value);
  if(!possibleSecondsDelay.includes(intValue)) {
    possibleSecondsDelay.push(intValue);
    possibleSecondsDelay.sort((a, b) => a - b);
  }

  let container = document.getElementById('outboxDelayContainer');
  let select = container.querySelector('select');

  for(let delay of possibleSecondsDelay) {
    addOption(delay);
  }

  select.value = value;
  select.disabled = !hasAccess;

  if(!hasAccess) {
    container.querySelector('div:nth-child(1)').classList.add('read-only-title');
  }

  container.style.display = 'block';

  changedProps.outboxDelay = {
    initialValue: value
  };

  select.onchange = function() {
    if(this.value == changedProps.outboxDelay.initialValue) {
      delete changedProps.outboxDelay.newValue;
    } else {
      changedProps.outboxDelay.newValue = this.value;
    }
  }

  function addOption(delay) {
    let option = document.createElement('option');
    option.value = delay;
    option.textContent = formatDelayText();
    select.appendChild(option);
    
    function formatDelayText() {
      let minutes = parseInt(delay / 60);
      let seconds = delay % 60;
      return ((minutes > 0 ?(`${minutes} ${i18n.t("js.minute", {count:minutes})}`) :'') 
        + (seconds > 0 ?(`${seconds} ${i18n.t("js.second", {count:seconds})}`) :'')).trim();
    }
  }
}

function buildEmailNotifSetting(value, hasAccess) {
  changedProps.emailNotifRecipients = {
    initialValue: value
  };

  let valueObj = JSON.parse(value);
  let container = document.getElementById('emailNotifContainer');

  if(!hasAccess) {
    container.querySelector('div:nth-child(1)').classList.add('read-only-title');
    for(let checkbox of container.querySelectorAll('input[type=checkbox]')) {
      checkbox.disabled = true;
    }
    container.querySelector('select').disabled = true;
  }

  let checkboxData = [
    ['sender', 'isSender'], 
    ['reply-to', 'isReplyTo'], 
    ['admins', 'isAllAdmins']
  ];
  
  for(let item of checkboxData) {
    let checkbox = container.querySelector(`input[value=${item[0]}]`);
    checkbox.checked = valueObj[item[1]] || false;
    checkbox.onchange = function() {
      valueObj[item[1]] = this.checked;
      updateChangeData();
    }
  }

  let distroListCheckbox = container.querySelector('input[value=lists]');
  let $notifSelectContainer = $(document.getElementById('notifSelectContainer'));
  let $distroListSelect = $(container.querySelector('select'));
  let distributionLists;
  distroListCheckbox.onchange = async function() {
    valueObj.isDistroLists = this.checked;
    updateChangeData();
    if(this.checked) {
      if(typeof distributionLists === 'undefined') {
        showSpinner();
        distributionLists = await DataFetcher.fetchDistributionLists();
        fillDistroListSelect();
        $distroListSelect.chosen({
          width: '100%',
          hide_results_on_select: false
        });
        $distroListSelect[0].onchange = function() {
          valueObj.distributionListIds = $(this).val();
          updateChangeData();
        };
        hideSpinner();
      }
      $notifSelectContainer.slideDown(400);
    } else {
      $notifSelectContainer.slideUp(400);
    }
  }
  if(valueObj.isDistroLists) {
    distroListCheckbox.checked = true;
  }

  container.style.display = 'block';

  if(distroListCheckbox.checked) {
    distroListCheckbox.onchange();
  }

  function updateChangeData() {
    let latestValue = JSON.stringify(valueObj);
    if(latestValue == changedProps.emailNotifRecipients.initialValue) {
      delete changedProps.emailNotifRecipients.newValue;
    } else {
      changedProps.emailNotifRecipients.newValue = latestValue;
    }
  }

  function fillDistroListSelect() {
    let distroListMap = {};
    for(let list of distributionLists) {
      try {
        if(list.key.name && list.properties.distributionListName) {
          distroListMap[list.key.name] = list.properties.distributionListName;
          addListOption(list.key.name, distroListMap[list.key.name]);
        }
      } catch(e) {
        console.error(e);
      }
    }

    function addListOption(optionVal, textContent) {
      let option = document.createElement('option');
      option.value = optionVal;
      option.textContent = textContent;
      option.selected = (Array.isArray(valueObj.distributionListIds) 
        && valueObj.distributionListIds.includes(optionVal));
      $distroListSelect[0].appendChild(option);
    }
  }
}

function buildDraftVisibilitySettings(value, hasAccess) {
  let container = document.getElementById('draftsVisibilityContainer');
  container.querySelector(`input[value="${value}"]`).checked = true;

  if(!hasAccess) {
    container.querySelector('div:first-child').classList.add('read-only-title');
    for(let radioOption of container.querySelectorAll('input[type=radio]')) {
      radioOption.disabled = true;
    }
  }

  container.style.display = 'block';
  
  changedProps.draftVisibility = {
    initialValue: value
  };

  for(let radioOption of container.querySelectorAll('input[type=radio]')) {
    radioOption.onchange = onDraftVisibilityChange;
  }

  function onDraftVisibilityChange() {
    let selectedInputVal = container.querySelector(`input:checked`).value;
    if(selectedInputVal == changedProps.draftVisibility.initialValue) {
      delete changedProps.draftVisibility.newValue;
    } else {
      changedProps.draftVisibility.newValue = selectedInputVal;
    }
  }
}

function buildDeletedContactImportSettings(value, hasAccess) {
  let container = document.getElementById('deletedContactImportContainer');
  container.querySelector(`input[value="${value}"]`).checked = true;

  if(!hasAccess) {
    container.querySelector('div:first-child').classList.add('read-only-title');
    for(let radioOption of container.querySelectorAll('input[type=radio]')) {
      radioOption.disabled = true;
    }
  }

  container.style.display = 'block';
  
  changedProps.googleImportDeletedContacts = {
    initialValue: value
  };

  for(let radioOption of container.querySelectorAll('input[type=radio]')) {
    radioOption.onchange = onDeletedContactImportChange;
  }

  function onDeletedContactImportChange() {
    let selectedInputVal = container.querySelector(`input:checked`).value;
    if(selectedInputVal == changedProps.googleImportDeletedContacts.initialValue) {
      delete changedProps.googleImportDeletedContacts.newValue;
    } else {
      changedProps.googleImportDeletedContacts.newValue = selectedInputVal;
    }
  }
}

function buildPhotoActivitySettings(value, hasAccess) {
  let container = document.getElementById('photoActivityNotifContainer');
  container.querySelector(`input[value="${value}"]`).checked = true;

  if(!hasAccess) {
    container.querySelector('div:first-child').classList.add('read-only-title');
    for(let radioOption of container.querySelectorAll('input[type=radio]')) {
      radioOption.disabled = true;
    }
  }

  container.style.display = 'block';
  
  changedProps.photoActivityNotification = {
    initialValue: value
  };

  for(let radioOption of container.querySelectorAll('input[type=radio]')) {
    radioOption.onchange = onPhotoActivityNotifChange;
  }

  function onPhotoActivityNotifChange() {
    let selectedInputVal = container.querySelector(`input:checked`).value;
    if(selectedInputVal == changedProps.photoActivityNotification.initialValue) {
      delete changedProps.photoActivityNotification.newValue;
    } else {
      changedProps.photoActivityNotification.newValue = selectedInputVal;
    }
  }
}


function buildAttachmentFileInfoSettings(value, hasAccess) {
  let container = document.getElementById('attachmentFileInfoContainer');
  container.querySelector(`input[value="${value}"]`).checked = true;

  if(!hasAccess) {
    container.querySelector('div:first-child').classList.add('read-only-title');
    for(let radioOption of container.querySelectorAll('input[type=radio]')) {
      radioOption.disabled = true;
    }
  }

  container.style.display = 'block';
  
  changedProps.requireAttachmentFileInfo = {
    initialValue: value
  };

  for(let radioOption of container.querySelectorAll('input[type=radio]')) {
    radioOption.onchange = () => onRadioBtnSettingChange(container.id, 'requireAttachmentFileInfo');
  }
}

function buildBooleanUserSetting(containerId, name, value, hasAccess) {
  let container = document.getElementById(containerId);
  container.querySelector(`input[value="${value}"]`).checked = true;

  if(!hasAccess) {
    container.querySelector('div:first-child').classList.add('read-only-title');
    for(let radioOption of container.querySelectorAll('input[type=radio]')) {
      radioOption.disabled = true;
    }
  }

  container.style.display = 'block';

  changedProps[name] = {
    initialValue: value
  };

  for(let radioOption of container.querySelectorAll('input[type=radio]')) {
    radioOption.onchange = () => onRadioBtnSettingChange(container.id, name);
  }
}

function onRadioBtnSettingChange(containerId, propertyName) {
  let container = document.getElementById(containerId);
  let selectedInputVal = container.querySelector(`input:checked`).value;
  if(selectedInputVal == changedProps[propertyName].initialValue) {
    delete changedProps[propertyName].newValue;
  } else {
    changedProps[propertyName].newValue = selectedInputVal;
  }
}