import './SmartFolder.css';
import moment from 'moment';
import i18n from './Translation';

/**
 * Prerequisites - newfoldermodal html element to use as a blueprint
 */
export default class SmartFolder {

  constructor(htmlTemplate) {
    if(!SmartFolder.validPrerequisites()) { return Promise.reject() }
    this.htmlTemplate = htmlTemplate;
    this.fillWithDefaults();
    this.htmlTemplate.querySelector('.smart-folder-rejection').style.visibility = 'hidden';
  }

  static validPrerequisites() {
    return true;
    if(document.getElementById('newfoldermodal2') === null) {
      console.error('element with id "newfoldermodal" not found on document');
      return false;
    }
    if(!Utility && typeof Utility.copyBlueprint !== 'function') {
      console.error('method Utility.copyBlueprint not found');
      return false;
    }
    return true;
  }

  parse() {
    let that = this;
    that.isAtLeastOneFilter = false;
    this.props = new Object();
    [].forEach.call(document.querySelectorAll('.smart-folder-data'), elem => {
      try {
        if(elem.value && elem.value !== '*' && elem.value !== elem.getAttribute('defaultvalue')) {
          console.debug('found filter value for ' + elem.name + ' (' + elem.value + ')');
          that.isAtLeastOneFilter = true;
        }
        this.props[elem.name] = elem.getAttribute('datatype') == 'number'
          ?parseInt(elem.value||elem.getAttribute('defaultvalue'))
            :(elem.value||elem.getAttribute('defaultvalue'));
      } catch(e) {
        console.error(e);
      }
    });
    this.validateProps();
    return this.arePropsValid ?this.props :null;
  }

  fillWithDefaults() {
    [].forEach.call(document.querySelectorAll('.smart-folder-data'), elem => {
      try {
        let defaultValue = elem.getAttribute('defaultvalue');
        if(elem.tagName == 'INPUT' && defaultValue == '*') {
          defaultValue = '';
        }
        elem.value = defaultValue;
      } catch(e) {
        console.error(e);
      }
    });
  }

  fill(filters) {
    let that = this;
    console.debug('filling filters', filters);
    [].forEach.call(document.querySelectorAll('.smart-folder-data'), elem => {
      try {
        let elemNameWithPrefix = 'filter_' + elem.name;
        if(filters[elemNameWithPrefix] && filters[elemNameWithPrefix] !== '*') {
          console.debug(`setting preloaded filter value ${filters[elemNameWithPrefix].toString()} to ${elem.name}`);
          let formattedValue;
          if(elem.getAttribute('datatype') == 'date') {
            let filterDate = new Date(filters[elemNameWithPrefix]);
            let filterYear = filterDate.getFullYear().toString();
            let filterMonth = (filterDate.getMonth() + 1).toString().padStart(2, '0');
            let filterDay = filterDate.getDate().toString().padStart(2, '0');
            formattedValue = `${filterYear}-${filterMonth}-${filterDay}`;
          } else {
            formattedValue = filters[elemNameWithPrefix].toString();
          }
          elem.value = formattedValue;
        }
      } catch(e) {
        console.error(e);
      }
    });
  }

  validateProps() {
    if(!this.isAtLeastOneFilter) {
      this.rejectField(i18n.t("js.smartfolder.reject.filter"));
      return;
    }

    if(this.props.episode!=='*' && parseInt(this.props.episode)+'' !== this.props.episode) {
      this.rejectField(i18n.t("js.smartfolder.reject.episode"));
      return;
    }
    if(isNaN(this.props.minSize) || isNaN(this.props.maxSize)) {
      this.rejectField(i18n.t("js.smartfolder.reject.file-size"));
      return;
    }
    for(let i = 0; i < 2; i++) {
      let propName = ['minDateCreated', 'maxDateCreated'][i];
      let date = [this.props.minDateCreated, this.props.maxDateCreated][i];
      if(date.trim().match(/\d{4}-\d{2}-\d{2}/) == null) {
        this.rejectField(i18n.t("js.smartfolder.reject.date.format"));
        return;
      } else if(!moment(date.trim(), 'YYYY-MM-DD', true).isValid()) {
        this.rejectField(i18n.t("js.smartfolder.reject.date.invalid"));
        return;        
      } else {
        date = new Date(date.trim());
        date.setTime(date.getTime() + date.getTimezoneOffset()*60*1000
          + (i==1?1000*60*60*24-1:0));
        this.props[propName] = date;
      }
    }

    this.htmlTemplate.querySelector('.smart-folder-rejection').style.visibility = 'hidden';
    this.arePropsValid = true;
  }

  rejectField(errMsg) {
    let rejectDiv = this.htmlTemplate.querySelector('.smart-folder-rejection');
    rejectDiv.textContent = errMsg;
    rejectDiv.style.visibility = 'visible';
    this.arePropsValid = false;
  }

  static getMatchingFolderIds(file, fileProps, folders, defaultFolderId = 'ROOT') {
    let folderIds = [];
    console.debug(file);
    console.debug(fileProps);
    console.debug(folders);
    const MB = 1024;
    if(file === null || JSON.stringify(file) === '{}') {
      return [defaultFolderId];
    }
    for(let i = 0, folder; folder = folders[i++];) {
      try {
        if(folder.filter_category !== '*' &&
            folder.filter_category.toLowerCase() !== (fileProps.category||'').toLowerCase()) {
          continue;
        }
        if(folder.filter_episode !== '*' &&
            folder.filter_episode.toLowerCase() !== (fileProps.episode||'').toLowerCase()) {
          continue;
        }
        if(folder.filter_color !== '*' &&
            folder.filter_color.toLowerCase().replace(/\s+/g,'')
            !== (fileProps.color||'').toLowerCase().replace(/\s+/g,'')) {
          continue;
        }
        let extMatcher = (file.newname||file.name).match(/\.([^\.]+)$/);
        let extension = extMatcher !== null && typeof extMatcher[1] == 'string' ?extMatcher[1] :null;
        if(folder.filter_type !== '*' && (extension == null ||
            !folder.filter_type.toLowerCase().split(',').includes(extension.toLowerCase())
            && folder.filter_type.toLowerCase() !== 'other')) {
          continue;
        }
        if(parseInt(folder.filter_minSize) > file.size/MB ||
            parseInt(folder.filter_maxSize) < file.size/MB) {
          continue;
        }
        let now = new Date().getTime();
        let minDate = Date.parse(folder.filter_minDateCreated);
        let maxDate = Date.parse(folder.filter_maxDateCreated);
        if(now < minDate || now > maxDate) {
          continue;
        }
        folderIds.push(folder.id);
      } catch(e) {
        console.error(e);
      }
    }
    return folderIds.length ?folderIds :[defaultFolderId];
  }
}
