import swal from 'sweetalert2';
import i18n from './Translation';
export default class DynamicFooterButtons {
  constructor() {
    this.initProductToursBtn();
  }

  initProductToursBtn() {
    this.productToursBtn = document.getElementById('productToursBtn');
    this.productToursBtn.onclick = this.showProductToursPrompt;
    this.productToursBtn.style.display = '';
    
  }

  showProductToursPrompt(){    
    swal({
      type: "question",
      title: i18n.t("js.product.tours.title"),
      html: `${i18n.t("js.product.tours.text",
          // The order here does not change the order in the swal, the order relies on the order of variables in the i18n translation files
          {
              UPLOAD_SCRIPT: `<a onclick= "goToProductTour(361210, 'uploadScripts')" target="_blank"><span class="prodTours">${i18n.t("js.product.tours.upload-script")}</span></a>`,
              IMPORT_CONTACTS: `<a onclick= "goToProductTour(360844, 'allContacts')" target="_blank"><span class="prodTours">${i18n.t("js.product.tours.import-contacts")}</span></a>`,
              GENERATE_SIDES: `<a onclick= "goToProductTour(351441, 'sidesGeneration')" target="_blank"><span class="prodTours">${i18n.t("js.product.tours.generate-sides")}</span></a>`,
              SEND_EMAIL: `<a onclick= "goToProductTour(350652, 'default')" target="_blank"><span class="prodTours">${i18n.t("js.product.tours.send-email")}</span></a>`
          })}`,
      allowOutsideClick: true,
      showCloseButton: true,
      showConfirmButton: false,
    });
    document.querySelector(".swal2-actions").style.display = 'inline';
    document.querySelector(".swal2-actions").innerHTML += `
    <button class="swal-options-btn button slim blue" onclick='window.location.assign("https://support.croogloo.com/en/")'>${i18n.t("js.support_site")}</button>
    <button class="swal-options-btn button slim grey" onclick="swal.close(); return false;">${i18n.t("close")}</button>`;
  }
}