import i18n from './components/Translation';
import DocUtility from './components/DocUtility';
import Chart from 'chart.js';
import swal from 'sweetalert2';

export function init() {
  loader.add(showSpinner)
        .add(initGlobals)
        .add(ui)
        .add(function() {
          getMetaData(() => loader.check('distroListRendered'));
        }, 'distroListRendered')
        .execute();
}

const STATUS = { FAILED: -2, DRAFT: 0, READY: 1, SENT: 2, RECEIVED: 3 }
Object.freeze(STATUS);

function escapeHTML(html) {
  let div = document.createElement('div');
  div.textContent = html;
  return div.innerHTML;
}

function ui() {
  $('#main').foundation();
  Utility.loadStylesheet('/assets/croogloo-custom/css/no-data.css');
  Utility.loadStylesheet('/assets/croogloo-custom/css/mailbox-menu.css');
  Utility.loadStylesheet('/assets/material-design-icons/css/material-design-icons.min.css');

  let historyContentClassName;
  switch(Utility.getPageParam('status')) {
    case STATUS.DRAFT: historyContentClassName = 'content-draft'; break;
    case STATUS.READY: historyContentClassName = 'content-outbox'; break;
    case STATUS.SENT: historyContentClassName = 'content-sent'; break;
    case STATUS.RECEIVED: historyContentClassName = 'content-inbox'; break;
    default: console.error('Invalid status parameter');
  }

  try {
    if(historyContentClassName) {
      document.getElementById('distro-history-content').classList.add(historyContentClassName);
    }
  } catch(err) {
    console.error(err);
  }
}

function initListeners() {
  $('div.message-row').off('click');
  $('div.close-msg-btn').off('click');
  $('div.close-msg-btn').on('click', function(event) {
    // if(event.target.closest('div.message-top-row') != null || event.target.className == 'close-msg-btn') {
    //   this.classList.remove('unseen-msg');
      toggleContentDisplay(parseInt(this.id.split('row', 2)[1]));
    // }
  });
  $('div.message-row').on('click', function(event) {
    if(event.target.closest('div.message-top-row') != null || event.target.className == 'close-msg-btn') {
      this.classList.remove('unseen-msg');
      toggleContentDisplay(parseInt(this.id.split('row', 2)[1]));
    }
  });
  if(Utility.getPageParam('status') === STATUS.DRAFT) {
    let emptyDraftBtn = document.querySelector('#emptyDraftBtn');
    emptyDraftBtn.style.display = 'block';
    if(document.getElementsByClassName('message-row').length > 0) {
      emptyDraftBtn.onclick = deleteAllDrafts;
    } else {
      emptyDraftBtn.className += ' disabled-empty-draft';
    }
  }
  $('div.restore-draft').off('click');
  $('div.restore-draft').on('click', function(e) {
    e.stopImmediatePropagation();
    restoreMessage(this.id);
  });

  $('div.delete-single-draft > i').off('click');
  $('div.delete-single-draft > i').on('click', function(e) {
    e.stopImmediatePropagation();
    let triggerElem=this, msgId=this.parentElement.id;
    swal({
      title: i18n.t("js.utils.confirm"),
      text: i18n.t("js.distro.hist.draft.delete.text"),
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#BC2121',
      confirmButtonText: i18n.t("utils.delete"),
      cancelButtonText: i18n.t("button.cancel"),
      allowOutsideClick: false,
      useRejections: true, //important for swal2 v7.1.2
      expectRejections: true,
      showCloseButton: true,
      allowOutsideClick: true
    }).then(function(isConfirm) {
      if(isConfirm){
        deleteDraft(triggerElem, msgId);
      }
    }).catch(swal.noop);
  });

  $('div.forward-msg').off('click');
  $('div.forward-msg').on('click', function(e) {
    e.stopImmediatePropagation();
    restoreMessage(this.id, false);
  });
  $('div.retry-sending').off('click');
  $('div.retry-sending').on('click', function(e) {
    let msgId = this.id;
    if(e.target.tagName == 'SPAN') {
      e.stopImmediatePropagation();
      console.debug('message will be converted to a draft');
      // NOTE Eventually, we should automate distribution retries, but because
      // our most frequently occurring issues have to do with corrupted or
      // deleted documents, it is safer to have users go through the whole
      // process again, which is likely to provides the needed validation.
      swal({        
        title: i18n.t("js.distro.hist.retry.title"),
        html: i18n.t("js.distro.hist.retry.text"),
        type: 'info',
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        confirmButtonText: i18n.t("continue"),
        showLoaderOnConfirm: true,        
        showCloseButton: true,
        allowOutsideClick: () => !swal.isLoading(),
        preConfirm: function() {
          return new Promise((resolve, reject) => {
            apicall('distributionapi','convertToDraft', { msgId: msgId }).then(resp => {
              if(resp && resp.responseCode === '0') {
                restoreMessage(resp.entityId, true, true, resolve);
              } else {
                throw new Error('Server error.');
              }
            }).catch(err => {
              reject(i18n.t("js.distro.hist.retry.failed"));
              return;
            });
          });
        }
      }).catch(swal.noop);
    }
  });
  $('div.abort-sending').off('click');
  $('div.abort-sending.abort-activated').on('click', function(e) {
    e.stopImmediatePropagation();
    showSpinner();
    let timeSpan = $(this).closest('div.message-row').find('span.time-left')[0];
    timeSpan.setAttribute('aborted', '1');
    apicall('distributionapi','convertToDraft', { msgId: this.id }).then(resp => {
      if(resp && resp.responseCode === '0') {
        timeSpan.setAttribute('aborted', '2');
        $(this).off('click');
        $(this).removeClass('abort-activated');
        timeSpan.style.color = 'lightgray';
        timeSpan.style.textDecoration = 'line-through';
        this.textContent = i18n.t("js.distro.hist.aborted");
        this.style.color = 'lightgray';
        hideSpinner();
        let toastId = Utility.uniqueID();
        let hideToast = cgToast(i18n.t("js.distro.hist.retry.success")+'&nbsp;&#8211;&nbsp;'
         + '<span class="load-draft-toast no-text-select">'+i18n.t("js.distro.hist.retry.load")+'</span>', {
           id: toastId,
           hiding: 'manual',
           containerID: 'docBody',
           className: 'body-attached',
           showCloseButton: true
         });

         let isSettled = false;
         document.querySelector('#'+toastId+' > span.load-draft-toast')
          .onclick = function() {
            if(!isSettled) {
              isSettled = true;
              restoreMessage(resp.entityId);
              hideToast();
            }
         }
         setTimeout(function() {
           if(!isSettled) {
             isSettled = true;
             hideToast();
           }
         }, 10000);

      } else {
        cgToast('<span style="color:lightcoral">'+i18n.t("js.utils.connection.error")+'</span>');
        timeSpan.setAttribute('aborted', '0');
        hideSpinner();
        console.error(resp);
      }
    }, function(resp) {
      cgToast('<span style="color:lightcoral">'+i18n.t("js.utils.connection.error")+'</span>');
      timeSpan.setAttribute('aborted', '0');
      hideSpinner();
      console.error(resp);
    });
  });

  document.getElementById('searchMsgInput').onkeyup = function(e) {
    lastKeyStroke = e.keyCode + '_' + Math.random();
    let tmpKey = lastKeyStroke;
    let that = this;
    setTimeout(function() {
      searchEmails(tmpKey, that.value);
    }, 750);
  }
}

function searchEmails(keyStroke, searchText) {
  if(keyStroke === lastKeyStroke) {
    historySearchCursor = null;

    /* 
      We make sure that the user doesn't accidentally delete all drafts when 
      trying to delete only the visible ones. We don't use the display property
      because it's already used to determine whether or not the button should
      be displayed based on the current target messages' status.
    */ 
    document.getElementById('emptyDraftBtn').style.visibility = 'hidden';

    // if outbox, search using front-end only filtering out entities (those already filtered out for failed messages) else...
    if(searchText.trim().length > 0) {
      let searchOlderMsgBtn = document.getElementById('searchOlderMessagesBtn');
      let searchStatusBar = document.getElementById('searchStatusBar');
      let searchStatusSpan = document.getElementById('searchStatusSpan');

      if(status !== STATUS.READY) {
        searchOlderMsgBtn.onclick = () => searchDistributionHistory();
        searchDistributionHistory(true);
  
        function searchDistributionHistory(isFirstSearch = false) {
          showSpinner();
          searchStatusBar.style.display = 'none';
          apicall('distributionapi', 'searchDistributionHistory', {
            status: status,
            encodedCursor: historySearchCursor || ''
          }, {
            value: searchText.trim()
          }).then(resp => {
            let msgEntities = Object.values(resp)[0];
            if(msgEntities == null || !msgEntities.length) {
              msgEntities = [];
              handleNoResults();
            } else {
              let oldestResultDateString = msgEntities[0].properties.oldestResultDate;
              msgEntities.shift(); // we remove the dummy entity that's needed to 
                                   // ensure the encoded cursor is sent to the front-end
              historySearchCursor = Object.keys(resp)[0];
              searchStatusSpan.textContent = i18n.t("js.distro.hist.searched", {searchDate: formatOldestSearchDate(oldestResultDateString)});
              searchOlderMsgBtn.style.display = '';
              searchStatusBar.style.display = '';
            }
            displayMsgHistory(msgEntities, isFirstSearch, true);
            isFirstSearch = false;
          }).catch(() => {
            console.error('server error searching distribution history');
            handleNoResults();
          }).then(() => {
            initListeners();
            hideSpinner();
          });
  
          function formatOldestSearchDate(dateString) {
            let dateObj = new Date(dateString);
            return dateObj.getFullYear().toString() + '/' 
              + (dateObj.getMonth() + 1).toString().padStart(2, '0') 
              + '/' + dateObj.getDate().toString().padStart(2, '0')
          }  
        } 
      } else {
        let nbOfMatchedResults = 0;
        for(let message of latestFetchedMessages) {
          try {
            let isMessageIncluded = false;
            for(let prop of ["subject", "recipientListJSON", "mobiles",
                "distributionListId", "distributionListName", "departmentId", "message"]) {
              if(typeof message.properties[prop] == 'undefined') {
                continue;
              }
              let stringVal = typeof message.properties[prop] === 'string'
                  ?message.properties[prop] 
                  :message.properties[prop].value;
              if(stringVal.toLowerCase().includes(searchText.toLowerCase())) {
                isMessageIncluded = true;
                break;
              }
            }
            if(isMessageIncluded) {
              nbOfMatchedResults++;
            }
            document.querySelector('div.message-row[message-id="' 
              + message.key.name + '"]').style.display = isMessageIncluded 
                ?'' :'none'
          } catch(e1) {
            console.error(e1)
          }
        }
        handleNoResults();
        if(nbOfMatchedResults === 0){
          $("#pageNumber").html(i18n.t("js.distro.hist.found_zero"));
        }else{
          $("#pageNumber").html(i18n.t("js.distro.hist.found", {count: nbOfMatchedResults}));
        }
        document.getElementById('previousPage').style.visibility = 'hidden';
        document.getElementById('nextPage').style.visibility = 'hidden';
      }
      function handleNoResults() {
        searchStatusSpan.textContent = i18n.t("js.distro.hist.older.none");
        searchOlderMsgBtn.style.display = 'none';
        searchStatusBar.style.display = '';
      }
    } else {
      searchStatusBar.style.display = 'none';
      document.getElementById('emptyDraftBtn').style.visibility = 'visible';
      if(status !== STATUS.READY) {
        displayMsgHistory(latestFetchedMessages);
        initListeners();
      } else {
        for(let message of latestFetchedMessages) {
          try {
            document.querySelector('div.message-row[message-id="' 
                + message.key.name + '"]').style.display = '';
          } catch(e1) {
            console.error(e1);
          }
        }
        let pageStart = (currentPage - 1) * NB_OF_ITEMS_PER_PAGE + 1;
        let pageEnd = pageStart + latestFetchedMessages.length - 1;
        $("#pageNumber").html(pageStart.toString() + " - " + pageEnd.toString());
        document.getElementById('previousPage').style.visibility = 'visible';
        document.getElementById('nextPage').style.visibility = 'visible';
      }
      hideSpinner();
    }
  }
}

function restoreMessage(msgId, restoreRecipients = true, restoreContent = true,
    onBeforeUnload = null) {
  showSpinner();
  apicall('distributionapi', 'fetchMessageForId', { msgId: msgId }).then(resp => {
    hideSpinner();
    if (resp && resp.status == STATUS.DRAFT || resp.status == STATUS.SENT) {
      resp.restoreRecipients = restoreRecipients;
      resp.restoreContent = restoreContent;
      if(typeof onBeforeUnload == 'function') { onBeforeUnload(); }
      r.loadPage('compose', { savedMessage: resp });
    } else {
      console.error(resp);
      cgToast(i18n.t("js.distro.hist.load.err"));
    }
  }, function(resp) {
    console.error(resp);
    hideSpinner();
    cgToast(i18n.t("js.distro.hist.load.err"));
  });
}

var cont;
var totalPages;
var currentPage;
var lastPage;
var focusedMessageStatusInfo;
var status;
var lastKeyStroke;
var distributionListNameMap, departmentNameMap;
const NB_OF_ITEMS_PER_PAGE = 20; // must be a constant with the current mechanics
var msgEntityCursors;
var latestFetchedMessages;
var historySearchCursor;

function initGlobals() {
  status = Utility.getPageParam('status');
  lastKeyStroke = null;
  distributionListNameMap = {};
  departmentNameMap = {};
  msgEntityCursors = [''];
  latestFetchedMessages = null;
  currentPage = 1;
  lastPage = 1;
  totalPages = 1;
  focusedMessageStatusInfo = new Object();
  historySearchCursor = null;
  cont = true;
}


function nextPage()
{
  this.onclick = function() {}; // prevent spamming
  currentPage++;
  getMetaData(null, currentPage);
}

function previousPage()
{
  this.onclick = function() {}; // prevent spamming
  if(currentPage <= 1) { return; }
  currentPage--;
  if (currentPage<0) {currentPage=0;}
  getMetaData(null, currentPage);
}

function lPage() {
  this.onclick = function () { }; // prevent spamming   
  currentPage = totalPages;
  getMetaData(null, currentPage);
}

function fPage()
{
  this.onclick = function() {}; // prevent spamming
  if(currentPage <= 1){ return; };
  currentPage = 1;
  getMetaData(null, currentPage);
}

function deleteAllDrafts() {
  swal({
    title: i18n.t("js.utils.confirm"),
    text: i18n.t("js.distro.hist.drafts.delete.text"),
    type: 'question',
    showCancelButton: true,
    confirmButtonColor: '#BC2121',
    confirmButtonText: i18n.t("utils.delete"),
    cancelButtonText: i18n.t("button.cancel"),
    allowOutsideClick: false,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    allowOutsideClick: () => !swal.isLoading(),
    showCloseButton: true,
  }).then(function(isConfirm) {
    if(isConfirm){
      apicall('distributionapi', 'deleteAllDrafts', {}).then(resp => {
        if(resp && resp.responseCode === '0') {
          r.reload();
        } else if(resp && resp.responseCode === '-1') {
          console.warn('no draft found to delete');
        } else {
          cgToast('<span style="color:lightcoral">'+i18n.t("js.utils.connection.error")+'</span>');
        }
      }, function() {
        cgToast('<span style="color:lightcoral">'+i18n.t("js.utils.connection.error")+'</span>');
      });
    }
  }).catch(swal.noop);
}

function deleteDraft(triggerElem, msgId) {
  let hideToast = cgToast(i18n.t("deleting"), { hiding: 'manual' });
  apicall('distributionapi', 'deleteDraft', { messageId: msgId }).then(resp => {
    if(resp.responseCode === '0') {
      try {
        triggerElem.closest('div.message-row').remove();
      } catch(e) {
        console.error(e);
      }
    } else {
      throw new Error('server error');
    }
  }).catch(() => {
    cgToast(i18n.t("js.utils.server.error"));
  }).then(hideToast);
}

function fetchDistroLists(done) {
  if(Object.entries(distributionListNameMap).length) {
    done();
  } else {
    apicall('distributionapi', 'fetchDistributionListNames', {}).then(resp => {
      for(let distroList of resp.items) {
        distributionListNameMap[distroList.key.name] = distroList.properties.distributionListName;
      }
    }).catch(() => {
      console.error('server error - fetchDistributionListNames');
    }).then(done);
  }
}

function fetchDepartments(done) {
  if(Object.entries(departmentNameMap).length) {
    done();
  } else {
    apicall('distributionapi', 'fetchDepartmentNames', {}).then(resp => {
      for(let department of resp.items) {
        departmentNameMap[department.key.name] = department.properties.departmentName;
      }
    }).catch(() => {
      console.error('server error - fetchDepartmentNames');
    }).then(done);
  }
}

function fetchPageEntities(currPage){
  return new Promise(resolve => {
    apicall('distributionapi', 'fetchPageEntites', {
      status: status,
      limit: NB_OF_ITEMS_PER_PAGE,
      page: currPage
    }).then(resp => {
      resolve(Object.values(resp));

    }).catch(() =>{
      console.errpr("Issue retrieving page entities of page " + currPage);
      resolve([]);
    });
  });
}

//Fetching total number of pages  
function fetchTotalNumberMessages() {
  return new Promise((resolve) => {
      apicall('distributionapi', 'countEmailPages', {
      pageSize: NB_OF_ITEMS_PER_PAGE,
          status: status
      }).then((resp) => {
          if (resp.responseCode && resp.responseCode === '0' && resp.responseMessage) {
              const pageCount = JSON.parse(resp.responseMessage);
              resolve(pageCount);
          } else {
              // If we can't get the number of pages, return 1.
              resolve(1);
          }
    }).catch(() => {
        console.log("Page count not retrieved from distributionapi.countEmailPages");
    });
  });
}

//Fetches page entities using cursor (initial implimentation)

function fetchMessageEntities() {
  return new Promise(resolve => {
      apicall('distributionapi', 'fetchOutboxMessages', {}).then(resp => {
        resolve([resp.items, null]);
      }).catch(() => {
        console.error('failed to fetch outbox messages');
        resolve([[], null]);
      })   
  });
}

function fetchMessageHistory(done, page) {
  if(typeof page == 'undefined'){
    page = 1;
  }
  if(status !== STATUS.READY){
    fetchPageEntities(page).then(resp =>{
      let msgEntities = resp[0];
      if (typeof msgEntities == 'undefined' || !msgEntities.length || (msgEntities = msgEntities.filter(failedMsgFilter)).length == 0) {
        done([]);
      } else {        
        done(msgEntities);
      }  
    }).catch(err => {
      console.error(err);
      done([]);
    });
    
  }else{
    fetchMessageEntities().then(resp => {
      let msgEntities = resp[0];
      let cursor = resp[1];
      if (typeof msgEntities == 'undefined' || !msgEntities.length || (msgEntities = msgEntities.filter(failedMsgFilter)).length == 0) {
        done([]);
      } else {
        try {
          //The if-could be removed, since we retrieve all cursors at once.
          if (cursor != null && msgEntityCursors.length <= currentPage) {
            msgEntityCursors.push(cursor);
          }
          console.debug('msg history cursor is now "' + msgEntityCursors[msgEntityCursors.length - 1] + '"');
        } catch (e1) {
          console.error(e1);
          console.error('failed to update msg history cursor');
        }
        done(msgEntities);
      }
    }).catch(err => {
      console.error(err);
      done([]);
    });
  }

    /**
   * Used to filter out failed messages that were processed over 3 days from 
   * the current time.
   * @param {CrooglooEntity} msg 
   */
  function failedMsgFilter(msg) {
    const DAY_MS = 1000 * 3600 * 24;
    const DAYS_TO_EXPIRE = 3;
    return parseInt(msg.properties.status) !== STATUS.FAILED 
      || new Date(msg.properties.dateSent).getTime() > new Date().getTime() - DAYS_TO_EXPIRE * DAY_MS;
  }
}
/**
 * Sometimes the msgEntity.properties.distributionListId is an object with a value key 
 * having the string of all distribution lists in it
 * This was the case for the production BEYONDBLACKBEAUTY
 * @returns string
 */
function getDistributionListIdParamter(distributionListId) {
  if (typeof (distributionListId) == 'string') {
    return distributionListId
  } else {
    return distributionListId.value
  }
}

function displayMsgHistory(msgEntities, clearPreviousMessages = true, isSearchResult = false) {
  // must make sure we don't clear the current data if no results were returned for the next page
  if(msgEntities.length == 0 && currentPage > 1 && msgEntityCursors.length <= currentPage) {
    console.debug('no msg entity returned, resetting page number');
    currentPage--;
    cgToast(i18n.t("js.distro.hist.older.none-found")); // needed if we show the nextPage 
                                             // arrow because the last query returned 
                                             // exactly NB_OF_ITEMS_PER_PAGE results, 
                                             // but there are no more results to retrieve
    return;
  }
  let totalNbOfMessages = msgEntities.length;
  if (totalNbOfMessages == 0){
    if (window.croogloocurrentpage.pageName == "drafts"){
      hideSpinner();
      draftOverlayOn();
    }
    if (window.croogloocurrentpage.pageName == "outbox"){
      hideSpinner();
      outboxOverlayOn();
    }
  }
  if(clearPreviousMessages) {
    $("#distro-history-content").empty();
  } else {
    totalNbOfMessages += document.querySelectorAll('#distro-history-content > div.message-row').length;
  }
  if(totalNbOfMessages <= 0 && !isSearchResult) {
    document.getElementById('headBtnContainer').style.visibility = 'hidden';
    document.getElementById('searchStatusBar').style.display = 'none';
  } else {
    $("#no-data-span").hide();
    for (let msgEntity of msgEntities) {
      const distributionListId = getDistributionListIdParamter(msgEntity.properties.distributionListId)
      msgEntity.properties.distributionListName = distributionListId
        .split(/\s*,\s*/g)
        .filter(x => !(/^[,\-~]*$/.test(x)))
        .map(x => distributionListNameMap[x])
        .join(', ');
      msgEntity.properties.departmentName = msgEntity.properties.departmentId
        .split(/\s*,\s*/g)
        .filter(x => !(/^[,\-~]*$/.test(x)))
        .map(x => departmentNameMap[x])
        .join(', ');
      msgEntity.properties.dateSentString = formatMsgDate(msgEntity.properties.dateSent);
    }
    document.getElementById('headBtnContainer').style.visibility = 'visible';
    renderDistroList(msgEntities, totalNbOfMessages - msgEntities.length);
    
    let maxNbOfItemsPerPage = status === STATUS.READY 
      ?Infinity // no paging or limitation for the outbox 
      :NB_OF_ITEMS_PER_PAGE; // limited by paging values

    var pageStart = (currentPage - 1) * NB_OF_ITEMS_PER_PAGE + 1;
    var pageEnd = pageStart + msgEntities.length - 1;
    
    lastPage = msgEntities.length < maxNbOfItemsPerPage 
      ?currentPage // the current page is the last page
        :Infinity; // We don't know what the last page is; it could be the current page or not.  
 
    let previousPageBtn = document.getElementById("previousPage");
    if (currentPage == 1) {
      previousPageBtn.classList.add('disabled-page-nav');
      previousPageBtn.onclick = function () { };
    } else {
      previousPageBtn.classList.remove('disabled-page-nav');
      previousPageBtn.onclick = previousPage;
    }

    let nextPageBtn = document.getElementById("nextPage");
    if (currentPage == lastPage) {
      nextPageBtn.classList.add('disabled-page-nav');
      nextPageBtn.onclick = function () { };
    } else {
      nextPageBtn.classList.remove('disabled-page-nav');
      nextPageBtn.onclick = nextPage;
    }

    let endPageBtn = document.getElementById("endPage");
    if (currentPage == lastPage) {
      endPageBtn.classList.add('disabled-page-nav');
      endPageBtn.onclick = function () { };
    } else {
      endPageBtn.classList.remove('disabled-page-nav');
      endPageBtn.onclick = lPage;
    }

    let firstPageBtn = document.getElementById("beginPage");
    if (currentPage == 1) {
      firstPageBtn.classList.add('disabled-page-nav');
      firstPageBtn.onclick = function () { };
    } else {
      firstPageBtn.classList.remove('disabled-page-nav');
      firstPageBtn.onclick = fPage;
    }

    if(isSearchResult) {
      if(totalNbOfMessages === 0){
        $("#pageNumber").html(i18n.t("js.distro.hist.found_zero"));
      }else{
        $("#pageNumber").html(i18n.t("js.distro.hist.found", {count: totalNbOfMessages}));
      }
      previousPageBtn.style.visibility = 'hidden';
      nextPageBtn.style.visibility = 'hidden';
      endPageBtn.style.visibility = 'hidden';
      firstPageBtn.style.visibility = 'hidden';
    } else {
      $("#pageNumber").html(pageStart.toString() + " - " + pageEnd.toString());
      previousPageBtn.style.visibility = 'visible';
      nextPageBtn.style.visibility = 'visible';
      endPageBtn.style.visibility = 'visible';
      firstPageBtn.style.visibility = 'visible';
      
    }
  }
}

function enableSearchBar() {
  document.getElementById('searchMsgInput').disabled = false;
}

function getMetaData(callback, page) {
  let messages;
  new TaskList({ passReference: true })
    .add(showSpinner)
    .add(list => {
      new Promise((resolve) => {        
        if (cont) {
          fetchTotalNumberMessages().then(numsP => {          
            totalPages = numsP;          
          }).catch(() => {
            console.log("Error with the promise");
          });
          cont = false;
        }
        resolve();

      }).then(() => {
        fetchDistroLists(() => list.check('distro-lists'));
        fetchDepartments(() => list.check('departments'));
        fetchMessageHistory(msgEntities => {
          latestFetchedMessages = messages = msgEntities;
          list.check('messages');
        }, page);
      });
    }, ['distro-lists', 'departments', 'messages'])
    .add(() => {
      displayMsgHistory(messages);
      initListeners();
      if (messages.length) {
        enableSearchBar();
      }
      if (typeof callback === 'function') {
        callback();
      }
    })
    .oncomplete(hideSpinner)
    .execute();
}

function formatMsgDate(date) {
  date = new Date(date);
  return date.toLocaleDateString('en-CA') + ' &ndash; ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

function renderStatusSection(json, sentToEmails, itemNum, msgId) {
  var statusDiv = document.createElement("div");
  statusDiv.className="statusContainer";

  let failedStatusDiv = document.createElement("div");
  failedStatusDiv.className="statusContainer";

  if(typeof json == 'string' && json.trim().length > 0) {
    json = { value: json };
  } else if (json == undefined  || typeof json !== 'object') {
    return "<span class='content'>Recipients: </span>"+sentToEmails+"<br/><br/>";
  } else {
    var lst = JSON.parse(json.value);

    let nbOfErrors = 0;
    let nbOfSuccess = 0;

    for (var x=0;x<lst.length;x++) {
      var obj = lst[x];

      if(Utility.getPageParam('status') !== STATUS.SENT) {
        obj.status = i18n.t("js.distro.hist.status.notSent");
      }

      var innerDiv = document.createElement("div");
      innerDiv.innerHTML = obj.name || obj.email;
      innerDiv.title = obj.email+": "+obj.status;
      innerDiv.className="statusDiv";
      innerDiv.setAttribute('msgId', msgId);

      if(obj.status == "click"){
        innerDiv.className+=" statusClick";
        nbOfSuccess++
      }

      if (obj.status=="sending") {
        innerDiv.className+=" statusSending";
        nbOfSuccess++;
      }

      if (obj.status=="sent") {
        innerDiv.className+=" statusSent";
        nbOfSuccess++;
      }

      else if (obj.status=="open") {
        innerDiv.className+=" statusOpen";
        nbOfSuccess++
      }

      else if (obj.status=="delivered") {
        innerDiv.className+=" statusDelivered";
        nbOfSuccess++;
      }

      else if (obj.status=="processed") {
        innerDiv.className+=" statusProcessed";
        nbOfSuccess++;
      }

      else if (obj.status == i18n.t("js.distro.hist.status.notSent")) {
        innerDiv.className+=" statusNotSent";
        nbOfSuccess++;
      }

      else if (obj.status=="deferred") {
        innerDiv.className+=" statusDeferred";
        nbOfSuccess++;
        //failedStatusDiv.append(innerDiv);
        //continue;
      }

      else if (obj.status=="bounce") {
        innerDiv.className+=" statusBounced";
        nbOfErrors++;
        //failedStatusDiv.append(innerDiv);
        //continue;
      }

      else if (obj.status=="dropped") {
        innerDiv.className+=" statusDropped";
        nbOfErrors++;
        //failedStatusDiv.append(innerDiv);
        //continue;
      }

      statusDiv.appendChild(innerDiv);
    }

    var statusEnd = document.createElement("div");
    statusEnd.className="statusEnd";
    statusDiv.appendChild(statusEnd);

    focusedMessageStatusInfo = {
      statusDiv: statusDiv,
      failedStatusDiv: failedStatusDiv,
      nbOfErrors: nbOfErrors,
      nbOfSuccess: nbOfSuccess
    };
  }
}

function createSuccessNFailuresBoxes(itemNum) {
  var boxesContainer = document.createElement("div");
  boxesContainer.className = "statusContainer";
  boxesContainer.id = 'boxesContainer_' + itemNum;

  var nbOfSuccessDiv = document.createElement("div");
  nbOfSuccessDiv.innerHTML = focusedMessageStatusInfo.nbOfSuccess+' '+i18n.t("js.distro.hist.status."+ (status!==STATUS.SENT ? "notSent":"sent"));
  nbOfSuccessDiv.className='statusDiv success-report-box ' + (status!==STATUS.SENT?'statusNotSent':'statusDelivered');

  var nbOfErrorsDiv = document.createElement("div");
  nbOfErrorsDiv.innerHTML = focusedMessageStatusInfo.nbOfErrors+' '+i18n.t("js.distro.hist.status.bounced");
  nbOfErrorsDiv.className="statusDiv statusBounced";

  if(focusedMessageStatusInfo.nbOfSuccess > 0) {
    boxesContainer.append(nbOfSuccessDiv);
  }

  if(focusedMessageStatusInfo.nbOfErrors > 0) {
    boxesContainer.append(nbOfErrorsDiv);
  }

  return boxesContainer.outerHTML;
}

function renderDistroList(item, itemNum) {
  item.map(function (msg) {
    const distributionListId = getDistributionListIdParamter(msg.properties.distributionListId)
    var sentTo = (msg.properties.distributionListName || distributionListId || '').replace(/^\s*[-~]\s*$/, '');
    sentTo += (sentTo?',':'')+(msg.properties.departmentName || msg.properties.departmentId || '').replace(/^\s*[-~]\s*$/,'');
    sentTo = sentTo.replace(/^(\s*,*)*([^\s,]?((\s|\S)(?!((\s*,*)*$)))*[^,\s]?)(\s*,*)*$/,'$2')
      .replace(/(\s*,+\s*)+/g,', ').replace(/^\s*$/,'-');

    var sentDate = msg.properties.dateSentString;
    var subject = escapeHTML(msg.properties.subject);
    var sentToEmails = typeof msg.properties.emails==='object'
      ?msg.properties.emails.value.replace(";","; ")
        :(typeof msg.properties.emails === 'string' ?msg.properties.emails :'');

    var msgContent = msg.properties.message.value || '';

    let msgStatus = parseInt(msg.properties.status);
    renderStatusSection(msg.properties.recipientListJSON, sentToEmails, itemNum, msg.properties.id);

    let sentDateId = Utility.uniqueID();
    var message = $("<div class='message-row unseen-msg" + (msgStatus === STATUS.FAILED? ' grayed-out' :'') + "' id='row"+itemNum+"'></div>");
    message.attr('message-id', msg.key.name);
    let msgPreview = subject;
    let topRowContent = '<div class="unseen-msg-tab"></div>' + ("<div id='subRow"+itemNum+"' class='message-top-row'>"+
    "<div class='distro-info sent-to'><span>" + (msgStatus === STATUS.RECEIVED? (msg.properties.replyToEmail||'') :(sentTo + formatNumberOfErrors())) + "</span></div>" +
    "<div class='distro-info subject subject-smaller' title='" + subject.replace(/'/g, "\"") + "'>" + msgPreview + "</div>" +
    "<div id='"+sentDateId+"' class='distro-info sent-date'>"+(msgStatus===STATUS.READY?'':sentDate)+"</div>"+
    (msgStatus === STATUS.DRAFT?"<div id='"+msg.properties.id+"' class='distro-info restore-draft no-text-select'>"
      +i18n.t("js.distro.hist.restore")+"</div>":"")+
    (msgStatus === STATUS.READY?"<div id='"+msg.properties.id+"' class='distro-info abort-sending abort-activated no-text-select'>"
      +i18n.t("js.distro.hist.abort")+"</div>":"")+
    (msgStatus === STATUS.FAILED?"<div id='"+msg.properties.id+"' class='distro-info retry-sending no-text-select'>"
      +i18n.t("js.distro.hist.failed")+"&nbsp;&nbsp;<span>"+i18n.t("js.distro.hist.retry_")+"</span></div>":"")+
    ((msgStatus === STATUS.SENT || msgStatus === STATUS.RECEIVED)?"<div id='"+msg.properties.id+"' class='distro-info forward-msg no-text-select'><i class='fa fa-share'></i></div>":""));

    var msgBody = $("<div class='msg-body' id='msg"+itemNum+"'>" +
      '<div class="close-msg-btn"' + " id='row" + itemNum + "'>" + '<i class="fa fa-times"></i></div>' +
      createSuccessNFailuresBoxes(itemNum) +
      (msgStatus !== STATUS.RECEIVED ?generateFullReportLink(itemNum, msg.properties.id, typeof msg.properties.extraEmailsJSON === 'object'): '') +
      "<div class='distro-info message-content'><span class='content' >"+i18n.t("js.distro.hist.message")+": </span><div class='msg'>"+
      msgContent+"</div></div>");

    let uploadedFiles = [];
    try {
      uploadedFiles = JSON.parse(msg.properties.uploadedFilesJSON.value);
    } catch(e) {
      console.warn('adding attachments (if any) in compatibility for: ' + msg.key.name);
      for(let i = 1; i <= 10; i++) {
        if(msg.properties['file'+i].trim() !== '-') {
          uploadedFiles.push({
            id: msg.properties['file'+i],
            fileName: msg.properties['file'+i],
            url: msg.properties['file'+i+'URL'],
            isWatermarked: msg.properties.watermark == 'true' ?'1' :'0'
          });
        }
      }
    }
    if(msgStatus === STATUS.DRAFT) {
      topRowContent += ("<div id='"+msg.properties.id+"' class='delete-single-draft no-text-select' "
        + "style='vertical-align:middle; float:right; padding: 0 10px 0 5px'><i class='icon-trash' "
        + "style='font-size:1rem; vertical-align:sub'></i></div>");
    }
    if (uploadedFiles && uploadedFiles.length) {
      topRowContent += ("<div class='distro-clip-icon'><i class='icon-attachment' aria-hidden='true'></i></div>");
    }
    topRowContent += '</div>';
    message.append(topRowContent);
    let topRowHr = document.createElement('hr');
    topRowHr.className = 'top-row-division';
    topRowHr.style.display = 'none';
    message.append(topRowHr);
    uploadedFiles.forEach(file => {
      let attachment = document.createElement('a');
      attachment.className = 'distro-link doc-title';
      attachment.textContent = file.fileName;
      attachment.download = file.fileName;
      attachment.onclick = async function(e) {
        e.stopImmediatePropagation();

        if(!file.id.toLowerCase().endsWith('pdf')){
          DocUtility.downloadFile(file.id);

        }else{
          showSpinner();
          DocUtility.getFileURL(file.id, file.isWatermarked === '1', null).then(fetchedURL => {
            let url = fetchedURL; 
            buildFileViewer(url); 
            hideSpinner();  
          });          
        }
        // DocUtility.openFile(file.id, file.fileName);
      }
      msgBody.append(attachment);
    });
    itemNum++;
    message.append(msgBody);
    if(msgStatus === STATUS.READY) {
      // showing messages closer to be sent first
      $("#distro-history-content").prepend(message);
      updatingTimeSpan(msg, sentDateId);
    } else {
      $("#distro-history-content").append(message);
    }
  });

  addListenersToReportLinks();
}

function buildFileViewer(fileLink) {

  let overlay = document.getElementById('overlay');
  let viewBody = document.getElementById('viewerBody');
  let viewContainer = document.getElementById('fileContainer');
  let btn = document.getElementById('closeBtn')

  let fileIframe = document.createElement('iframe');
  fileIframe.setAttribute('src', fileLink);
  fileIframe.innerHTML = "Iframe not supported"; 

  viewContainer.classList.add('active');
  overlay.classList.add('active');

  viewBody.appendChild(fileIframe);

  btn.addEventListener('click', () =>{

    viewContainer.classList.remove('active');
    overlay.classList.remove('active');
    viewBody.removeChild(fileIframe);
  });

  overlay.addEventListener('click', ()=>{
    viewContainer.classList.remove('active');
    overlay.classList.remove('active');
    viewBody.removeChild(fileIframe);
  })
}

function updatingTimeSpan(msg, sentDateId) {
  let timeSpan = document.createElement('span');
  timeSpan.className = 'time-left';
  timeSpan.setAttribute('aborted', '0');
  
  let timeLeft = parseInt(msg.properties.timeLeft);
  timeLeft -= timeLeft % 5;

  document.getElementById(sentDateId).appendChild(timeSpan);

  if(timeLeft <= 0) {
    setSendingStatus();
  } else {
    timeSpan.textContent = i18n.t("js.distro.hist.sending-in", {timeLeft: formatTimeText(timeLeft)});
    let timeInterval = setInterval(function() {
      timeLeft -= 5;
      if(timeSpan.getAttribute('aborted') == '1') {
        return; // in the process of aborting msg sending
      }
      if(timeSpan.getAttribute('aborted') == '2') {
        clearInterval(timeInterval);
        console.log('msg aborted, stopped timer');
        return;
      }
      if(timeLeft <= 0) {
        setSendingStatus();
        clearInterval(timeInterval);
        return;
      }
      timeSpan.textContent = i18n.t("js.distro.hist.sending-in", {timeLeft: formatTimeText(timeLeft)});
    }, 5000);
  }

  function setSendingStatus() {
    timeSpan.textContent = i18n.t("js.distro.hist.revert.expired");
    timeSpan.className += ' delay-expired';
    let abortBtn = $(timeSpan).closest('div.message-row').find('div.abort-sending')[0];
    $(abortBtn).removeClass('abort-activated');
    $(abortBtn).addClass('abort-unactivated');
    abortBtn.textContent = i18n.t("js.distro.hist.sending").toUpperCase();
    $(abortBtn).off('click');
    let $statusDivs = $('div[msgId="'+msg.properties.id+'"]');
    $statusDivs.removeClass('statusNotSent');
    $statusDivs.addClass('statusSending');
    $statusDivs.each((idx, elem) => {
      elem.title = elem.title.replace(i18n.t("js.distro.hist.status.notSent"), i18n.t("js.distro.hist.sending"));
    });
    let $successReportBox = $(timeSpan).closest('div.message-row').find('div.success-report-box');
    $successReportBox.removeClass('statusNotSent');
    $successReportBox.addClass('statusSending');
    $successReportBox[0].textContent = 
      $successReportBox[0].textContent.replace(i18n.t("js.distro.hist.status.notSent"), i18n.t("js.distro.hist.sending"));
  }

  function formatTimeText(time) {
    return time <= 5 ?'< 5 '+i18n.t("js.second_other")
      :((time >= 60 ?parseInt(time/60) + ' '+i18n.t("js.timeunit.min_")+' ':'') + (time%60) + ' '+i18n.t("js.timeunit.sec_"));
  }
}

function generateFullReportLink(itemNum, msgId, supportsRecipientCopying) {
  let fullStatusReport = document.createElement('div');
  fullStatusReport.style.clear = 'both';

  let showReportLink = document.createElement('div');
  showReportLink.className = 'show-report-link';

  //condition kept in case we want to go back to separating successes and failures
  if(true || focusedMessageStatusInfo.nbOfSuccess > 0) {

    if(focusedMessageStatusInfo.statusDiv) {
      fullStatusReport.append(focusedMessageStatusInfo.statusDiv);
      focusedMessageStatusInfo.statusDiv.style.display = 'none';
      focusedMessageStatusInfo.statusDiv.id = 'statusDiv_' + itemNum;
    }

    showReportLink.textContent = i18n.t("js.distro.hist.full-report");
    showReportLink.className += ' contains-success-delivery';
    showReportLink.id = 'showReportLink_' + itemNum;
    showReportLink.setAttribute('msg_id', msgId);

  } else {
    showReportLink.textContent = i18n.t("js.distro.hist.success-distri.none");
  }

  fullStatusReport.append(showReportLink);

  if(Utility.getPageParam('status') === STATUS.SENT && supportsRecipientCopying) {
    let copyRecipientLink = document.createElement('div');
    copyRecipientLink.className = 'copy-recipient-link';
    copyRecipientLink.textContent = i18n.t("js.distro.hist.copy-recp");
    copyRecipientLink.setAttribute('msgId', msgId);
    fullStatusReport.append(copyRecipientLink);
  }

  return fullStatusReport.outerHTML;
}

function copyRecipients(e) {
  e.preventDefault();
  e.stopImmediatePropagation();  
  swal({
    title: i18n.t("js.distro.hist.copy-recp"),
    text: i18n.t("js.distro.hist.copy.text"),
    type: 'question',
    showCancelButton: true,
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("js.distro.hist.copy.write"),
    cancelButtonText: i18n.t("js.distro.hist.copy.list"),
    showCloseButton: true,
    useRejections: true, //important for swal2 v7.1.2
    allowOutsideClick: true
  }).then(function() {
    restoreMessage(e.target.getAttribute('msgId'), true, false);
  }, function(dismiss) {
    if(dismiss !== 'cancel') { return; } // dismiss = "cancel" when clicking "Save a List"
    swal({
      title: i18n.t("js.dislist.create.title"),
      input: 'text',
      inputPlaceholder: i18n.t("js.dislist.create.placeholder"),
      showConfirmButton: true,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("button.confirm"),
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      showLoaderOnConfirm: true,
      showCloseButton: true,
      animation: false,
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
      },      
      preConfirm: function(newListName) {
        return new Promise((resolve, reject) => {
          try {
            validateListName(newListName);
          } catch(e) {
            reject(e.message);
            return;
          }

          var tmp = new Object();
          tmp.distributionListName = newListName;
          tmp.msgId = e.target.getAttribute('msgId');

          saveSystemActivity({
            action: 'submit',
            params: findSystemActivityParams(tmp),
            message: 'User created a distribution list with members.'
          });

          apicall('distributionapi','addDistributionListFromMsg',tmp)
          .then(function(resp) {
            if(resp && resp.responseCode && resp.responseCode == '0') {
              resolve({status: 'success'});
            } else if(resp.responseCode === '-1') {
              resolve({status: 'warning', message: resp.responseMessage});
            } else if(resp.responseCode === '-2') {
              reject(resp.responseMessage);
            } else {
              reject(i18n.t("js.utils.connection.error"));
            }
          });

          function validateListName(listName) {
            listName = listName.trim();
            if(listName === '') {
              throw { message: i18n.t("js.dislist.reject.name")}
            }
            //important condition for functions calling querySelector with the lists' id
            else if(/^\d/.exec(listName)) {
              throw { message: i18n.t("js.distro.hist.reject.digit") }
            }
          }
        });
      }
    }).then(function(response) {
      if(response.status === 'success') {
        swal({
          title: i18n.t("response.success"),
          showConfirmButton: true,
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("OK"),
          showCancelButton: false,
          type: 'success',
          allowOutsideClick: true
        }).catch(swal.noop);
      } else if(response.status === 'warning') {        
        swal({
          title: i18n.t("js.warning"),
          text: i18n.t("js.distro.hist.copy.warning", {contacts: response.message}),
          showConfirmButton: true,
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("OK"),
          showCancelButton: false,
          type: 'warning',
          showCloseButton: true,          
        }).catch(swal.noop);
      }
    }).catch(swal.noop);
  }).catch(swal.noop);
}

function addListenersToReportLinks() {
  $('div.copy-recipient-link').off();
  $('div.copy-recipient-link').on('click', copyRecipients);

  $('.contains-success-delivery').each(function() {
    $(this).click(function(e) {
      e.stopPropagation();
      if(Utility.getPageParam('status') === STATUS.SENT) {
        showDeliveryReport(this.getAttribute('msg_id'));
      }
      else {
        $('#statusDiv_' + this.id.split('_')[1]).toggle();
        $('#boxesContainer_' + this.id.split('_')[1]).toggle();
        const fullReport = i18n.t("js.distro.hist.full-report");
        this.textContent = this.textContent === fullReport ? i18n.t("js.distro.hist.hide-report"):fullReport;
      }
    });
  });
}

function formatNumberOfErrors() {
  return '';

  let nbOfErrors = focusedMessageStatusInfo.nbOfErrors;

  if(nbOfErrors == 0)
  return '';
  else {
    let span = document.createElement('span');
    span.style.marginLeft = '8px';
    span.style.color = 'red';
    span.textContent = '  (' + nbOfErrors + ' error' + (nbOfErrors>1?'s)':')');

    return span.outerHTML;
  }
}

function toggleContentDisplay(id) {
  let msgBody = document.getElementById('msg' + id);
  let topRowDivision = msgBody.closest('div.message-row').querySelector('hr.top-row-division');
  if (msgBody.style.display != "block") {
    console.debug("showing msg body");
    msgBody.style.display = "block";
    topRowDivision.style.display = 'block';
  } else {
    console.debug("hiding msg body");
    msgBody.style.display = "none";
    topRowDivision.style.display = 'none';
  }
}

function showDeliveryReport(msgId) {
  let container = document.createElement('div');
  container.id = 'chartContainer';

  let innerContainer = document.createElement('div');
  innerContainer.className = 'distro-screen-overlay';
  container.appendChild(innerContainer);
  document.querySelector('.compose-email.main-content').appendChild(container);

  new TaskList({ passReference: true })
    .add(list => {
      showSpinner();
      generateTable(() => list.check('table'));
    }, ['table'])
    .add(renderReportViewControllers)
    .oncomplete(hideSpinner).execute();

  function generateTable(done) {
    apicall('distributionapi', 'generateDistroReports', {msgId: msgId}).then(resp => {
      let results = [];
      results['Sent'] = resp.processed;
      results['Received'] = resp.delivered;
      results['Opened'] = resp.opened;
      results['Bounced'] = resp.bounced;
      results['Deferred'] = resp.deferred;
      results['Dropped'] = resp.dropped;

      let table_data = [];
      //We do this because processed contains 'sent' and 'processed' 
      //emails and certain status aren't constant with the other ones
      Object.keys(results).forEach(key => {
        const status_tled = i18n.t("js.distro.hist.status."+key.toLowerCase());
        results[key].forEach(r=>{
          r.status = key;//Required for colored circle
          r.status_tled = status_tled;//Required for search
        });
        table_data.push.apply(table_data, results[key]);
      });

      let reportContainer = document.createElement('div');
      reportContainer.className = 'grid-x reports-grid';

      let canvasContainer = document.createElement('div');
      canvasContainer.className = "medium-6 cell reports-canvasCtn";
      let chart = document.createElement('canvas');
      chart.id = 'reportsChart';
      canvasContainer.append(chart);

      let successErrorContainer = document.createElement('div');
      successErrorContainer.className = "cell grid-x";
      const appendTitleContainers = (title, nb) => {
        let titleContainer = document.createElement('div');
        titleContainer.className = "cell small-6";
        let statusTitle = document.createElement('h2');
        statusTitle.textContent = title + ' : ' + nb;
        statusTitle.className = "margin-bottom-none";
        titleContainer.append(statusTitle);
        successErrorContainer.append(titleContainer);
      };
      let nbSuccess = results['Sent'].length + results['Received'].length + results['Opened'].length;
      appendTitleContainers(i18n.t("js.distro.hist.report.success"), nbSuccess);
      let nbFailure = results['Bounced'].length + results['Deferred'].length + results['Dropped'].length;
      appendTitleContainers(i18n.t("js.distro.hist.report.failed"), nbFailure);
      canvasContainer.append(successErrorContainer);

      reportContainer.append(canvasContainer);

      let tableContainer = document.createElement('div');
      tableContainer.className = "medium-6 cell reports-tableCtn";
      let table = document.createElement('table');
      table.id = "reportsDataTable";
      tableContainer.append(table);
      reportContainer.append(tableContainer);

      innerContainer.appendChild(reportContainer);
      addCloseBtnToNotifTable(reportContainer);
      
      var tableInstance = $("#reportsDataTable").DataTable({
        data: table_data,
        paging: false,
        scrollY: "65vh",
        scrollCollapse: true,
        columns: [
          { title: i18n.t("js.distro.hist.name"), data: 'name', name:'name' , width: '32%'},
          { title: i18n.t("js.distro.hist.email"), data: 'email', name: 'email' , width: '36%'},
          { title: i18n.t("js.distro.hist.status"), data: null, defaultContent: '', name:'icon', searchable:false, orderData: 3, width: '32%'},
          { title: 'Hidden', data:'status_tled', name:'status', visible:false}
        ],
        fixedHeader: {
          header: true
        },
        language: {
          search: '',
          emptyTable: i18n.t("js.distro.hist.emptyTable"),
          zeroRecords: i18n.t("js.distro.hist.zeroRecords"),
          searchPlaceholder: i18n.t("js.distro.hist.searchPlaceholder"),
          info: i18n.t("js.distro.hist.info"),
          infoEmpty: i18n.t("js.distro.hist.infoEmpty"),
          infoFiltered: i18n.t("js.distro.hist.infoFiltered"),
        },
        rowCallback: (row, data, index) => {
          $("td:eq(2)", row).addClass("fa fa-2x fa-circle reports-status-" + data['status']);
        }
      });

      let legendTabsHeader = document.createElement('div');
      legendTabsHeader.className="tab grid-x";
      canvasContainer.append(legendTabsHeader);
      Object.keys(results).forEach(k => {
        const statusName = i18n.t("js.distro.hist.status."+k.toLowerCase());
        let tabLinkBtn = document.createElement('button');
        tabLinkBtn.id = "tab-btn-"+statusName;
        tabLinkBtn.className="tablinks small-2";
        tabLinkBtn.onclick = (e) => {
          showLegendTab(e, statusName);
          tableInstance.search(statusName).draw();
        }
        tabLinkBtn.textContent = statusName;
        legendTabsHeader.append(tabLinkBtn);

        let tabContent = document.createElement('div');
        tabContent.className="tabcontent";
        tabContent.id = "tab-"+statusName;
        let title = document.createElement('h3');
        title.textContent=statusName +' ('+results[k].length+'/'+table_data.length+')';
        tabContent.append(title);
        let description = document.createElement('p');
        description.textContent=i18n.t("js.distro.hist.status.desc."+k.toLowerCase());
        tabContent.append(description);
        canvasContainer.append(tabContent);
      });

      const chartColors = [
        'rgba(131, 219, 86, 1)',
        'rgba(112, 189, 74, 1)',
        'rgba(0, 153, 76, 1)',
        'rgba(208, 113, 84, 1)',
        'rgba(195, 80, 45, 1)',
        'rgba(169, 47, 9, 1)'
      ];

      var myChart = new Chart(chart.getContext('2d'), {
        type: 'doughnut',
        data: {
            labels: Object.keys(results).map((k)=>{return i18n.t("js.distro.hist.status."+k.toLowerCase())}),
            datasets: [{
                data: Object.values(results).map((l)=>{return l.length}),
                backgroundColor: chartColors,
                borderColor: chartColors,
                borderWidth: 1
            }]
        },
        options: {
          onClick: (evt)=>{
            var point = myChart.getElementAtEvent(evt)[0];
            if(point){
              let key = myChart.data.labels[point._index];
              tableInstance.search(key).draw();
              document.getElementById("tab-btn-"+key).click();
            }
          },
          onHover: (evt, elem) =>{evt.target.style.cursor = elem[0] ? 'pointer' : 'default';},
          tooltips: {
            bodyFontSize: 16,
            callbacks: {
              label: function(tooltipItem, data) {
                let index = tooltipItem.index;
                let label = data.labels[index] || '';
                let nbEmails = data.datasets[tooltipItem.datasetIndex].data[index];
                return label + ' : ' + nbEmails + '/' + table_data.length;
              }
            }
          },
          legend: {
            position: 'left',
            onClick: (evt, legendItem)=>{
              tableInstance.search(legendItem.text).draw();
              document.getElementById("tab-btn-"+legendItem.text).click();
            },
            onHover: (evt) => evt.target.style.cursor = 'pointer',
            onLeave: (evt) => evt.target.style.cursor = 'default',
            labels: { 
              fontColor: '#000000',
              fontSize: 14,
              padding: 11
            }
          }
        }
      });

      //document.getElementById("tab-btn-Sent").click();
      document.getElementsByClassName("tabcontent")[0].style.display = "block";
      document.getElementsByClassName("tablinks")[0].className += " active";
      done();

    }).catch(err => {
      console.error(err);
      swalToast({
        type: 'error',
        title: i18n.t("js.distro.hist.err.fetch")
      });
    });
  }

  function showLegendTab(evt, key){
    var tabcontent = document.getElementsByClassName("tabcontent");
    for(let i=0; i<tabcontent.length; i++)
      tabcontent[i].style.display="none";
    var tablinks = document.getElementsByClassName("tablinks");
    for(let i=0; i<tablinks.length; i++)
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    document.getElementById("tab-"+key).style.display = "block";
    evt.currentTarget.className += " active";
  }

  function addCloseBtnToNotifTable(notificationTable) {
    let span = document.createElement("span");
    span.className="reports-close-container";
    let icon_x = document.createElement("i");
    icon_x.className="fa fa-close close-me";
    span.append(icon_x);

    notificationTable.appendChild(span);
  }

  function renderReportViewControllers() {
    $('#reportTableContainer').show();
    container.onclick = function(e) {
      if((e.target.className).includes('close-me') || e.target.id == 'chartContainer'){
        $(this).remove();
      }
    }
  }
}

function outboxOverlayOn(){
  document.getElementById("emptyOutboxOverlay").style.display = "block";
}

function outboxOverlayOff(){
  document.getElementById("emptyOutboxOverlay").style.display = "none";
}

function draftOverlayOn(){
  document.getElementById("emptyDraftOverlay").style.display = "block";
}

function draftOverlayOff(){
  document.getElementById("emptyDraftOverlay").style.display = "none";
}
