/**
 * Function that is used everywhere in the application for making API calls to the backend.
 * 
 * @param {string} endpoint that maps to an API in getAPIVersion
 * @returns 
 */




export function apicall(endpoint) {
  /**
   * Every API call route must have the value '_ah/api' added to it in order for AppEngine to recognize it.
   */
  var ROOT = endpoint + '_ah/api';


  /**
   * Function to make the API call
   * @param {string} api the endpoint itself
   * @param {string} method the resource of the API
   * @param {array} obj the body of the API call if any. If no body is needed, use an empty array.
   * @param {object} file the file to attach to the API call such as for document upload. This argument can be omitted.
   * @param {boolean} sandboxRequest to specify if the request is a dev one or not, not really sure what this does to be honest. This argument can be omitted.
   * @param {boolean} body the body of the request
   */
  return function(api, method, obj, file, sandboxRequest = true) {
    if(window.allowedAPIMethods && !window.allowedAPIMethods.includes(method)) {
      console.debug('method ' + method + ' not currently allowed')
      return;
    }
    return new Promise((accept,reject)=>{
      new Promise(async function(success,error) {
        // google drive api currently needs a different access token
        while(croogloo_auth.runningGDriveCalls) {
          console.debug('waiting for google drive request to complete for: ' + method);
          await new Promise(resolve => setTimeout(resolve, 50));
        }

        // console.log('getting here after waiting');
        croogloo_auth.runningAPICalls = (croogloo_auth.runningAPICalls || 0) + 1;
        gapi.client.setToken({ access_token: croogloo_auth.token });
        // console.log('token is now: ' + gapi.client.getToken().access_token);
        if (gapi.client[api] === undefined) {
          croogloo_auth.runningAPICalls++;
          gapi.client.load(api, getAPIVersion(api),()=>{
            croogloo_auth.runningAPICalls--;
            if (gapi.client[api]===undefined) {
              error("Unable to load API:"+api);
            } else {
              success();
            }
          }, ROOT);
        } else {
          success();
        }
      }).then(function(){
        if (obj['token']===undefined){
          obj['token'] = croogloo_auth.usertoken;
        }
        if (obj['tenantId']===undefined){
          obj['tenantId'] = croogloo_auth.tenantId;
        }
        let timebegin = Date.now();
        if(location.host.startsWith("localhost")) {
          console.debug('calling: ' + api + ' : ' + method, obj);
        }

        if(method != 'saveSystemActivities') {
          try {
            saveSystemActivity({
              action: 'apicall',
              params: "NA",
              message: 'API method called.',
            },
            {
              apiName: api,
              apiMethod: method,
              apiParams: JSON.stringify(obj||{}),
              apiObject: JSON.stringify(file||{})
            });
          } catch(e) {
            console.error(e);
          }
        }
        if(!gapi.client[api] || typeof gapi.client[api][method] != 'function') {
          throw 'gapi.' + api + '.' + method + ' is not a function';
        }
        let requestContext = (typeof window.croogloocurrentpage == 'object'
          && typeof croogloocurrentpage.securityPageName == 'string')
            ?croogloocurrentpage.securityPageName :null;
        gapi.client[api][method](obj, file).then(function(r){
          croogloo_auth.runningAPICalls--;
          if(sandboxRequest && requestContext !== null &&
              requestContext !== croogloocurrentpage.securityPageName) {
            console.info('ignoring ' + api + '.' + method + ' due to context change');
            reject('request context changed');
          }
          else if (r.result){
            accept(r.result);
          }
          else {
            accept(r);
          }
        },function(r) {
          croogloo_auth.runningAPICalls--;
          console.error(method + ' FAILED');
          console.error(r);
          if (r.status == 403) {
            sessionStorage.setItem('logout_reason', 'duplicatesession');
            try {
              if(!window.isLoggingOut) {
                croogloo_auth.logout();
              }
            } catch(e) {
              console.error(e);
              window.location.href = "login.html";
            }
          } else {
            reject(r);
          }
        });
      }, (err)=>{
        console.error('API rejection');
        throw err;
      }).catch(function(err) {
        console.log("an error in API", err);
        console.log(api, method, obj, file);
        reject(err);
      });
    });
  }
}
// Authenticated API call that returns a Promise, needed for APIs relating to file and document uploading
export function authenticatedcall(endpoint){
  return function(url, method = 'GET', payload = '', type = "application/json", progress = null, responseType = "") {
    return new Promise((accept, reject)=>{
      if (!croogloo_auth.token || croogloo_auth.token==='') {
        reject("Not authenticated");
        return;
      }
      if (!url.startsWith('http://') && !url.startsWith('https://')){
        url = url.startsWith('/')?url.substring(1):url;
        url = endpoint+url;
      }
      var urlparams = '';
      if(url.split('?').length>1) {
        urlparams = url.split('?')[1];
      }
      if (urlparams.indexOf('token=')<0)urlparams=urlparams+'&'+'token='+croogloo_auth.usertoken;
      if (urlparams.indexOf('tenantId=')<0)urlparams=urlparams+'&'+'tenantId='+croogloo_auth.tenantId;
      url = url.split('?')[0]+'?'+urlparams;
      method = method.toUpperCase();
      var req = {};
      req.method = method;
      if (method==='POST'||method==='PUT')
        req.body = type==='application/json'?JSON.stringify(payload):payload;
      else
        req.body=null;
      let timebegin = Date.now();
      let xhrMain = new XMLHttpRequest();
      xhrMain.open(req.method, url);
      xhrMain.responseType = responseType;
      xhrMain.setRequestHeader("Authorization",'Bearer ' + croogloo_auth.token);
      xhrMain.onload = ()=>{
        console.debug('xhrMain:');
        console.debug(xhrMain);
        if(xhrMain.status >= 200 && xhrMain.status < 300) {
          let timecomplete = Date.now() - timebegin;
          if (xhrMain.responseType === 'json') {
            accept(JSON.parse(xhrMain.response));
          } else {
            accept(xhrMain.response);
          }
        } else {
          reject();
        }
      }
      xhrMain.onerror = () => {
        if (xhrMain.status == 403) {
          sessionStorage.setItem('logout_reason', 'duplicatesession');
          try {
            if(!window.isLoggingOut) {
              croogloo_auth.logout();
            }
          } catch(e) {
            console.error(e);
            window.location.href = "login.html";
          }
        } else {
          reject(xhrMain);
        }
      }
      if (progress && typeof progress === 'function'){
        xhrMain.upload.onprogress = (eventUpload) => {
          if (eventUpload.lengthComputable) {
            progress(Math.ceil(eventUpload.loaded/eventUpload.total*100));
          }
        }
      }
      xhrMain.send(req.body);
    });
  }
}
function getAPIVersion(apiName) {
  switch(apiName) {
    case 'calendarapi': return 'v1';
    case 'callsheet': return 'v9';
    case 'dataMaintenance': return 'v9';
    case 'distributionapi': return 'v1';
    case 'documentsapi': return 'v1';
    case 'filewebapi': return 'v9';
    case 'importexportapi': return 'v1';
    case 'metadataAPI': return 'v9';
    case 'notificationapi': return 'v1';
    case 'personapi': return 'v2';
    case 'productionapi': return 'v9';
    case 'scriptapi': return 'v1';
    case 'searchapi': return 'v9';
    case 'securityadminapi': return 'v1';
    case 'settingsapi': return 'v1';
    case 'syncapi': return 'v1';
    case 'tasksapi': return 'v1';
    case 'userAPI': return 'v9';
    case 'viewAPI': return 'v9';
    default:
      console.error(apiName + '\'s version is not registered. Defaulting to v1');
      return 'v1';
  }
}
