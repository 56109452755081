import i18n from './components/Translation';
import Upload from './components/Upload.js';
import FileDropper from './components/FileDropper.js';
import UploadManager from './components/UploadManager.js';

export function init() {
  loader.add(ui)
    .add(showSpinner)
    .add(initGlobals)
    .add(setTitle)
    .add(initLibraries)
    .add(initListeners)
    .add(checkTriggers)
    .execute();
}

var upload, fileDropper;
var _fileType;

function ui() {
  $('#main').foundation();
}

function initGlobals() {
  _fileType = Utility.getPageParam('filetype') || 'reportsAndSchedules';
}

function setTitle(){
  let pageTitle;
  let title = '';
  switch(_fileType) {
    case 'reportsAndSchedules': pageTitle = 'Upload Documents'; break;
    case 'script': pageTitle = 'Upload Scripts'; break;
    case 'template': pageTitle = 'Upload Template'; title = i18n.t("js.template.title"); break;
    default: pageTitle = 'Upload';
  }
  document.title = 'Croogloo - ' + pageTitle;
  document.getElementById('pageTitle').textContent = title;
}

async function initLibraries() {
  if(!window.uploadManager) {
    new UploadManager();
  }

  upload = await new Upload(null, _fileType);

  fileDropper = new FileDropper('myDropzone', i18n.t("js.upload.drop"), 
    (files, event) => {
      uploadFiles(files);
    },
    document.getElementById('mainDiv')
  );
}

function initListeners() {
  var addfilebtn = document.getElementById("myDropzone");
  addfilebtn.onclick = (event) => {
    document.getElementById('formupload').reset();
    var inputfile = document.getElementById('inputfileupload');
    inputfile.onchange = (event)=>{
      uploadFiles(inputfile.files);
    }
    inputfile.click();
  };
}

function checkTriggers() {
  if(Utility.getPageParam('trigger') == 'upload') {
    r.deleteExtraParam('trigger');
    document.getElementById('myDropzone').click();
  }
}

function uploadFiles(files) {
  upload.toGoogleCloudStorage(files).then((result) => {
    console.debug('upload success', result);
  }, (errFileName) => {
    console.error('failed upload ' + errFileName);
  });
}