import $ from 'jquery';
import whatInput from 'what-input';
//import 'trumbowyg/dist/ui/trumbowyg.min.css';

window.jQuery = $;

//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';

require('./lib/trumbowyg/trumbowyg.js');
//require('trumbowyg/dist/trumbowyg.min.js');
require('./lib/jquery.dataTables.js');
require('./lib/trumbowyg/plugins/colors/trumbowyg.colors');
require('./lib/trumbowyg/plugins/table/trumbowyg.table');
require('chosen-js');
