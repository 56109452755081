import Trigger from '../components/Trigger.js';

global.IMG_DIRECTORY = '/assets/img/';
global.devEndpoint = location.host.startsWith("localhost")
  ? 'https://dev.mycroogloo.com' : '';
global.SUPPORT_ADDRESS = 'support@croogloo.com';
global.INFINITE_NB_OF_PRODS = 999;

global.swal = require('sweetalert2');
//IMPOTANT -> using swal.resetDefault() will overwrite these values, which may
//IMPOTANT -> force Promise resolving where rejection should take place
swal.setDefaults({
  useRejections: true, //important for swal2 v7.1.2
  expectRejections: true,
  allowOutsideClick: false,
  allowEscapeKey: false,
  confirmButtonColor: '#13C46A'
});

global.swalToast = swal.mixin({
  toast: true,
  position: 'top-end',
  showCloseButton: true,
  useRejections: false,
  showConfirmButton: false,
  expectRejections: false
  // timer: 3500
});


global.saveSystemActivity = function(systemActivity, apiCall = {}, callback) {
  //runs asynchronously
  const SA_STORAGE_KEY = 'systemActivities';
  const MAX_STORED_ACTIVITIES = 10;

  let systemActivityTriggers = [
    new Trigger('Maximum memory reached (~4MB).', function() {
      // the maximum memory should not exceed localStorage limit (~5GB) or the apicall size limit,
      // nor the datastore put size limit (1GB)
      const MB = 1024 * 1024;
      // we check window.localStorage, rather than the SecureLS object used to read 
      // and write to it (global.secureLocalStorage)
      return Buffer.byteLength(JSON.stringify(localStorage)) > 0.85 * MB;
    }),
    new Trigger(`Maximum number stored activity reached (${MAX_STORED_ACTIVITIES}).`, function(apiCall) {
      let storedActivities = global.secureLocalStorage.get(SA_STORAGE_KEY);
      return typeof storedActivities == 'string' && storedActivities.trim().length > 0
        && JSON.parse(storedActivities).items.length >= MAX_STORED_ACTIVITIES;
    }),
    new Trigger('First stored activity is over a week old.', function() {
      const ONE_WEEK_MS = 1000 * 3600 * 24 * 7;
      let storedActivities = global.secureLocalStorage.get(SA_STORAGE_KEY);
      return typeof storedActivities == 'string' && storedActivities.trim().length > 0
        && new Date().getTime() - JSON.parse(storedActivities).firstActivityTime > ONE_WEEK_MS;
    }),
    new Trigger('User token or tenantId changed.', function() {
      let storedActivities = global.secureLocalStorage.get(SA_STORAGE_KEY);
      return typeof storedActivities == 'string' && storedActivities.trim().length > 0 
        && (croogloo_auth.usertoken !== (storedActivities = JSON.parse(storedActivities)).usertoken
          || croogloo_auth.tenantId !== storedActivities.tenantId);
    })
  ];

  setTimeout(function() {
    try {
      var screenName = "";
      if(window.croogloocurrentpage) {
        screenName = window.croogloocurrentpage.pageName;
      }
      systemActivity.screen = formatPageId(screenName);
      systemActivity.urlEndpoint = croogloo_auth.crooglooauth.urlEndpoint;

      // it's important to check conditions BEFORE we store the new system activity
      // in case there's been a change of user since the last activity was saved
      let isCallbackRelayed = false;
      for(let trigger of systemActivityTriggers) {
        try {
          if(trigger.isConditionMet()) {
            console.debug('system activity submission triggered - ' + trigger.toString());
            submitSystemActivities(function() {
              storeSystemActivity(systemActivity, apiCall);
              if(typeof callback == 'function') { callback(); }
            });
            isCallbackRelayed = true;
            break;
          }
        } catch(err) {
          console.error(err);
          localStorage.removeItem(SA_STORAGE_KEY);
        }
      }

      if(!isCallbackRelayed) {
        storeSystemActivity(systemActivity, apiCall);
        if(typeof callback == 'function') { callback(); }
      }

    } catch(e) {
      console.error(e.message);
      localStorage.removeItem(SA_STORAGE_KEY);
      if(typeof callback == 'function') { callback(); }
    }
  }, 0);

  function storeSystemActivity(systemActivity, apiCall) {
    try {
      let storedActivitiesJSON = global.secureLocalStorage.get(SA_STORAGE_KEY);
      let storedActivities = (typeof storedActivitiesJSON == 'string' && storedActivitiesJSON.trim().length > 0)
        ?JSON.parse(storedActivitiesJSON)
        :createActivityStorageObj();

      if(!storedActivities || !storedActivities.usertoken
          || !storedActivities.tenantId || !storedActivities.firstActivityTime
          || !storedActivities.items) {
        storedActivities = createActivityStorageObj();
      }

      storedActivities.items.push({
        systemActivity: systemActivity,
        apiCall: apiCall,
        time: new Date().getTime()
      });

      global.secureLocalStorage.set(SA_STORAGE_KEY, JSON.stringify(storedActivities));

      console.debug('stored system activity - there are now ' + storedActivities.items.length + ' activities stored');

      function createActivityStorageObj() {
        let usertoken = croogloo_auth.usertoken;
        let tenantId = croogloo_auth.tenantId
        if(!usertoken) {
          throw new Error('User token not found for system activity storage.');
        } else if(!tenantId) {
          throw new Error('Tenant ID not found for system activity storage.');
        }
        return {
          firstActivityTime: new Date().getTime(),
          usertoken: usertoken,
          tenantId: tenantId,
          browser: window.findBrowser(),
          items: new Array()
        }
      }
    } catch(err) {
      console.error(err);
      localStorage.removeItem(SA_STORAGE_KEY);
    }
  }

  function submitSystemActivities(done) {
    try {
      if(!global.secureLocalStorage.get(SA_STORAGE_KEY)) {
        throw new Exception('no new system activity to submit');
      }
      let systemActivities = JSON.parse(global.secureLocalStorage.get(SA_STORAGE_KEY));
      localStorage.removeItem(SA_STORAGE_KEY);
      apicall('tasksapi', 'saveSystemActivities', {}, systemActivities, false)
        .then(resp => {
        if(resp.responseCode === '0') {
          console.debug('successfully submitted system activities');
        } else {
          console.error('Failed to submit system activities', resp);
        }
        done();
      }).catch(err => {
        console.error(err);
        done();
      });
    } catch(err) {
      console.error(err);
      localStorage.removeItem(SA_STORAGE_KEY);
      done();
    }
  }

  function formatPageId(pageId){
    if(pageId.match(/[A-Z][a-z]+/g)!=null){
      var words = pageId.split(/(?=[A-Z])/);
      pageId = words[0].charAt(0).toUpperCase();
      pageId += words[0].substring(1, words[0].length);

      for(var i = 1, word ; word = words[i++];) {
        pageId += ' ' + word;
      }
    }

    return pageId;
  }
}
