import i18n from './components/Translation';
import SidePanel from './components/SidePanel.js';
import CGGoogleContacts from './components/CGGoogleContacts.js';
import getExpiringContacts from './components/ContactManager.js';
import swal from 'sweetalert2';
import createNewDept from './contact';
import FileDropper from './components/FileDropper.js';
import Upload from './components/Upload.js';
import UploadManager from './components/UploadManager.js';

export function init() {
  loader.add(ui, 'ui')
        .add(showSpinner)
        .add(initGlobals, 'globals')
        .add(loadExtraLibraries, ['font-size', 'font-family', 'clean-paste', 'cgchosen'])
        .add(getMetaData, ['security-lists','distro-groups','access-level','departments','persons','user-sec-list'])
        .add(fillContactTable, 'contact-table')
        .add(addPanelListItems)
        .add(initListeners, ['upload-listeners'])
        .execute();
}

var reorderables;
var reorderablesDepartments;
var deptInsertIndex;
var reorderablesHTML;
var reorderablesDepartmentsHTML;
var dragStartIndex;
var allDistrGroups;
var allDepartments;
var departmentOrderMap;
var fileDropper;
var upload;
var dropzoneContainerClone
var metaDataObject;


var userAccessLevel;
var securityLists;
var userSecurityListId;

var sidePanel;
var isSidesStandAlone;

let targetListings;

//key -> the crew member's personId, value -> the crew member object
var crewMemberIdMap;
var sortedCrewMembers;
var crewIndexedList;
var lastRowSelected = undefined;

var MAX_CONCURRENT_INVITATIONS = 10;

var hideToast;

function ui() {
  setTitle();
  $('#main').foundation();
  /* Contact listings */
  $('.ellipse-toggle').click(function() {
    $(this).closest('tr').toggleClass('ellipsis')
  });
  try {
    if(croogloocurrentpage.securityPageName === 'crew') {
      document.getElementById('deptOrderOpt').click();
      $('#importContactBtn').css("display", "none")
      $('#addContactBtn').css("display", "none")
      $('#exportCrewListBtn').css("display", "inline")

    }
  } catch(e) {
    console.error(e);
  }
  loader.check('ui');
}

function setTitle() {
  let formattedPageName;
  try {
    formattedPageName = croogloocurrentpage.pageName.charAt(0).toUpperCase() + croogloocurrentpage.pageName.substring(1);
  } catch(e) {
    console.error(e);
  }
  formattedPageName = formattedPageName || 'Contact';
  if (formattedPageName === 'AllContacts') {
    formattedPageName = 'All Contacts';
  }
  try {
    document.getElementById('contactPageTitle').textContent = i18n.t("js.crew.title."+(croogloocurrentpage.pageName||"default"));
  } catch(e) {
    console.error(e);
  }
  document.title = "Croogloo - " + formattedPageName;
}

function initGlobals() {  
  reorderables = [];
  reorderablesHTML = [];
  reorderablesDepartments = [];
  reorderablesDepartmentsHTML = [];
  dragStartIndex = 0;
  crewMemberIdMap = new Object();
  sortedCrewMembers = new Array();
  crewIndexedList = [];
  allDistrGroups = [];
  deptInsertIndex = new Map();
  allDepartments = [];
  departmentOrderMap = new Object();
  hideToast = null;
  sidePanel = new SidePanel();
  metaDataObject = new Object()
  dropzoneContainerClone = document.getElementById('dropzoneContainer')
  targetListings = Utility.getPageParam('targetListings') || ['crew'];
  securityLists = [];
  userSecurityListId = null;
  fileDropper = null;
  upload = null;
  clickedMenu = { top: 0, left: 0, element: null };
  $("#contacts-listing-form").on("mousedown", function(e) {
    if (e.shiftKey) {
      e.preventDefault();

      if ($.support.msie) {
          this.onselectstart = function () { return false; };
          var me = this;  // capture in a closure
          window.setTimeout(function () { me.onselectstart = null; }, 0);
      }
  }
  })
  loader.check('globals');
}

function loadExtraLibraries() {
  Utility.loadScript('/assets/trumbowyg/fontsize.js', () => loader.check('font-size'));
  Utility.loadScript('/assets/trumbowyg/fontfamily.js', () => loader.check('font-family'));
  Utility.loadScript('/assets/trumbowyg/cleanpaste.js', () => loader.check('clean-paste'));
  Utility.loadScript('/assets/js/cgchosen.jquery.js', () => loader.check('cgchosen'));
  require('./ui/lib/trumbowyg/plugins/base64/trumbowyg.base64.js'); // base64.js has been customized; do not use base64.min.js
}

function addPanelListItems() {
  if(croogloocurrentpage.getAccessLevel() > 1) {
    if (userSecurityListId === 'ADMIN') {
      var icodel = fontIcon('icon-mail');
      sidePanel.addListItem(icodel, i18n.t("crew.menu.invite"), e => {
        let selectedKeys = Object.keys(e.panel.selectedItems);
        inviteToCroogloo(selectedKeys.join(','), '');
        clearSelectedContacts();
      });
    }
    sidePanel.addListItem(fontIcon('icon-pencil'), i18n.t("crew.menu.category"), e => {
      changeSubcategory(e);
      clearSelectedContacts();
    });
    sidePanel.addListItem(fontIcon('icon-pencil'), i18n.t("crew.menu.department"), e => {
      changeDepartment(e);
      clearSelectedContacts();
    });
  }

  sidePanel.addListItem(fontIcon('fa fa-tint'), i18n.t("watermark.btn.submit"), e=>{
    sessionStorage.setItem('watermark_contacts', Object.keys(e.panel.selectedItems));
    window.location.href = '#watermark';
  });

  try {
    if(r.routes['distributionList'].getAccessLevel() > 1) {
      sidePanel.addListItem(fontIcon('icon-distribution'), i18n.t("crew.menu.distro-add"), e=>{
          addToDistroList(e);
          clearSelectedContacts();
        });
    }
  } catch(e) {
    console.error(e);
  }

  var icodel = fontIcon('icon-trash');
  icodel.style.fontSize = '17px';
  sidePanel.addListItem(icodel, i18n.t("js.crew.delete.contacts"), e => {
    let selectedKeys = Object.keys(e.panel.selectedItems);
    confirmAndDeleteCrewMember(selectedKeys.join(','), i18n.t("js.crew.contact", {count: selectedKeys.length}));
    clearSelectedContacts();
  });

  var icoclean = fontIcon('icon-exit');
  icoclean.style.fontSize = '13px';
  croogloocurrentpage.pageName.toLowerCase() === 'crew' ? null : sidePanel.addListItem(icoclean, i18n.t("js.utils.clear-selection"), e => {
    clearSelectedContacts();
  });

  function fontIcon(className) {
    let icon = document.createElement('i');
    icon.className = className;
    return icon;
  }

  function clearSelectedContacts() {
    let selector = croogloocurrentpage.securityPageName === 'crew' ? $('member-container') : $('tr.crew-row.selected-row') 
    selector.removeClass('selected-row');
    sidePanel.clearSelected();
    sidePanel.hide();
    adjustSelectAllChk();
  }
}

function getMetaData(){
  findUserAccessLevel();
  fetchPersons();

  apicall('productionapi', 'fetchProductionSettings', {})
  .then(resp => {
    console.debug("production settings: ", resp.items)
    resp.items.forEach(obj => {
      if(obj.name === "isCrewListMetadataSet") {
        localStorage.setItem('isCrewListMetadataSet', `${obj.value}`)
        
      }
    })
  })
  .catch(err => {
    console.log(err)
  })

  fetchUserSecurityList(() => loader.check('user-sec-list')); // only admins can send invitations to join Croogloo;
  // This prevents users from inviting people and allowing them more privileges
  // than their own user benefit from

  fetchSecurityLists(() => loader.check('security-lists'));
  loadDistrGroups();
  loadDepartments();
  getExpiringContacts();
}

var clickedMenu;

function multiRowSelect(startValue, maxValue) {
  for (let i = startValue ; i < maxValue ; i++) {
    let row = document.querySelectorAll(`[index="${i}"]`)[0]
    let checkbox = $(row).find('div.checkbox-container input')[0] ;
    if (!$(row).hasClass('selected-row')) {
      $(row).toggleClass('selected-row')
      checkbox.checked = true
    } ;
    let personId = row.getAttribute('personId');
    sidePanel.addSelectedItem(personId, findCorrespondingContact(row));
  }
}

function crewRowClick(row, target, adjustSelectAll = true, deptView = false) {
  if(userAccessLevel < 2) {
    console.debug('read-only access');
    return;
  }
  if(['TD','TR'].includes(target.tagName) || (target.tagName !== 'I' && target.innerHTML === '') || deptView) {
    if (window.event.shiftKey && !deptView) {
      let clickedRowIndex = parseInt(row.getAttribute("index")) ;
      if (lastRowSelected === undefined) {
        multiRowSelect(0, clickedRowIndex + 1) ;
      }
      else if (lastRowSelected < clickedRowIndex) {
        multiRowSelect(lastRowSelected, clickedRowIndex + 1) ;
      }
      else if (lastRowSelected > clickedRowIndex) {
        multiRowSelect(clickedRowIndex, lastRowSelected) ;
      }
    }
    else {
      let selector = deptView ? ($(target).hasClass('member-container-reordering') ? null : target) : row
      if(selector !== null) {
        
        if($(selector.children[0]).hasClass('member-container')) {
          selector = selector.children[0]
        }
        else {
          while (deptView && !$(selector).hasClass('member-container')) {
            selector = selector.parentElement
          }
        }

        $(selector).toggleClass('selected-row');
        let personId = selector.getAttribute(`${deptView ? 'id' : 'personId'}`);
        let checkbox = $(selector).find(`.${deptView ? 'checkbox-container-crew' : 'checkbox-container'} input`)[0]
        if($(selector).hasClass('selected-row')) {
         checkbox.checked = true
         sidePanel.addSelectedItem(personId, `${deptView ? selector.id : findCorrespondingContact(selector)}`);
         lastRowSelected = `${deptView ? undefined : parseInt(row.getAttribute("index"))}` ;
        } else {
          checkbox.checked = false
          sidePanel.removeSelectedItem(personId);
        }
        if(adjustSelectAll) {
          adjustSelectAllChk();
        }
      }
    }

    if(Object.keys(sidePanel.selectedItems).length >= 2) {
      sidePanel.show();
    } else {
      sidePanel.hide();
    }
  }
}

function crewRowDblClick(target, deptView = false) {
  if(userAccessLevel < 2) {
    console.debug('read-only access');
    return;
  }
  
  if(deptView) {
    let selector = $(target).hasClass('member-container-reordering') ? null : target
    if(selector !== null) {
      
      if($(selector.children[0]).hasClass('member-container')) {
        selector = selector.children[0]
      }
      else {
        while (deptView && !$(selector).hasClass('member-container')) {
          selector = selector.parentElement
        }
      }

      editContact(target, selector.id)
    }
  }
  else if(['TD','TR'].includes(target.tagName) || (target.tagName !== 'I' && target.innerHTML === '')) {
    editContact(target);
  }
}

function initListeners() {
  //handling the three-dots menu's display and referenced person
  initUploadListeners(() => loader.check('upload-listeners'));
  let selector = croogloocurrentpage.securityPageName === 'crew' ? '.all-listings-container' : '#contact-table'

  document.querySelector(selector).onclick = function(e) {

    let correspondingContact = null

    if(($(e.target).hasClass('dot-menu-container') || $(e.target).hasClass('icon-more')) && selector === '.all-listings-container') {
      let contactDropdown = document.getElementById('contact-dropdown');
      if($(e.target).hasClass('icon-more')) {
        $(e.target).parent().append(contactDropdown)
      }
      else {
        $(e.target).append(contactDropdown)
      }
      clickedMenu.element = e.target
      $(contactDropdown).toggleClass('is-open')
      contactDropdown.style.top = '20px'
      contactDropdown.style.right = '50px'
      correspondingContact = findCorrespondingContact(clickedMenu.element, $(e.target).closest('div.member-container').attr('id'))
    }
    else if($(e.target).hasClass('icon-more') || $(e.target).hasClass('ellipsis-menu')) {
      let centeredIcon = $(e.target).hasClass('icon-more') ? e.target : $(e.target).find('i.icon-more')[0];
      let contactDropdown = document.getElementById('contact-dropdown');

      try {
        
        centeredIcon.closest('div.dot-menu-container').appendChild(contactDropdown);
        
        if(clickedMenu.element !== null && clickedMenu.element !== centeredIcon
          && $('#contact-dropdown').hasClass('is-open')) {
          clickedMenu.element = centeredIcon;
          $(centeredIcon).click();
        } else {
          clickedMenu.element = centeredIcon;
        }
        
        correspondingContact = findCorrespondingContact(clickedMenu.element);
      } catch(err1) {
        console.error(err1);
        console.error('failed to open contact menu', centeredIcon, centeredIcon.closest('div.dot-menu-container'), contactDropdown);
      }
      
    } else if(shouldCloseDropdown()) {
      $(clickedMenu.element).click();
    }

    if(correspondingContact !== null) {
      adaptContactMenuDisplay(correspondingContact.subcategory);
    }

    function shouldCloseDropdown() {
      if(!$('#contact-dropdown').hasClass('is-open')) { return false; }
      if($('#contact-dropdown')[0] === e.target) { return false; }
      return true;
    }
  }

  document.getElementById('contact-dropdown').onshow = function() {
    this.style.left = clickedMenu.left - this.clientWidth - 10 + 'px';
    this.style.top = clickedMenu.top + 'px';
  }

  if(croogloocurrentpage.securityPageName === 'crew') {
    document.getElementById('exportCrewListBtn').onclick = orderDepartments
  }
  else {
    document.getElementById('addContactBtn').onclick = addNewContact;
    document.getElementById('importContactBtn').onclick = importContacts;
  }


  if(userAccessLevel > 0) {
    $('ul.contact-menu > li[min-access=RO]').css('display', 'list-item');
    document.getElementById('menu_email').onclick = function() {
      let errorMsg = [i18n.t("js.crew.email-missing")];
      if(userAccessLevel > 1) {
        errorMsg.push(i18n.t("js.crew.suggestion"));
      }
      sendToCompose(
        'email', //required prop
        {OR: true}, //prop filter type
        errorMsg,
        true //add prop to session storage
      );
    };
  }
  if(userAccessLevel > 1) {
    $('ul.contact-menu > li[min-access=RW]').css('display', 'list-item');
    document.getElementById('menu_editContact').onclick = () => editContact(clickedMenu.element);
    document.getElementById('menu_delete').onclick = confirmAndDeleteCrewMember;
    document.getElementById('menu_editCategory').onclick = () => changeSubcategory(null);
    document.getElementById('menu_editDepartment').onclick = () => changeDepartment(null);
    document.getElementById('menu_addToDistrList').onclick = () => addToDistroList(null);
    if (userSecurityListId === 'ADMIN') {
      document.getElementById('menu_inviteContact').onclick = inviteToCroogloo;
    }
  }


  document.getElementById('deptOrderOpt').onchange = reorderContacts;
  document.getElementById('defaultOrderOpt').onchange = reorderContacts;

  document.querySelector('.contact-search > input').onkeydown = function(e) {
    if(croogloocurrentpage.securityPageName === 'crew') {
      searchContacts(e)
    }
    else {
      setTimeout(() => searchContacts(this.value, this), 0);
    }
  }
  document.querySelector('.contact-search > input').onsearch = function() {
    if(croogloocurrentpage.securityListName !== 'crew') {
      setTimeout(() => searchContacts(this.value, this), 0);
    }
  }

  $('tr.crew-row').off();
  $('tr.crew-row').on('click', function(e) { crewRowClick(this, e.target) });
  $('tr.crew-row').on('dblclick', function(e) { crewRowDblClick(e.target) });
  $('.member-container').on('click', (e) => { crewRowClick(null, e.target, false, true)})
  $('.member-container').on('dblclick', (e) => { crewRowDblClick(e.target, true)})
  
  document.getElementById('selectAllContainer').style.display = userAccessLevel > 1 ? 'block':'none';
  document.getElementById('selectAllChk').addEventListener('click', (deptView = true) => {
    
    [].slice.call(document.querySelectorAll('tr.crew-row:not(.cg-blueprint):not(.hidden-row)')).forEach(row => {
      if(row.classList.contains('selected-row') !== this.checked) {
        crewRowClick(row, row, false);
      }
    });
  
  })
}


async function initUploadListeners(done) {
  if(!window.uploadManager) {
    new UploadManager();
  }

  upload = await new Upload(null, 'script');

  fileDropper = new FileDropper('myDropzone', i18n.t("js.upload.drop"), (files, event) => uploadFiles(files));

  let fileUploadInput = document.getElementById('fileUploadInput');

  fileUploadInput.onchange = event => {
    let fr = new FileReader()

    fr.onload = function() { // file is loaded
      var img = new Image;
  
      img.onload = function() {
        console.log(img.width, img.height)
      };
      
      img.src = fr.result; // is the data URL because called with readAsDataURL
      uploadFiles(fileUploadInput.files, img.width, img.height)
    
    };
    
    fr.readAsDataURL(fileUploadInput.files[0])
  }

  document.getElementById('myDropzone').onclick = function() {
    document.getElementById('fileUploadForm').reset();
    fileUploadInput.click();
  }
  
  done();
}

function uploadFiles(files, width, height) {

  let file = files[0]
  let type = file.type.split('/')[0]
  const maximumSize = 2097152 

  if(type === 'image' && file.size <= maximumSize) {

    // passing in true to skip categorization SWAL and checking
    // for duplicate files

    upload.toGoogleCloudStorage(file, true)
    .then(resp => {
      if(resp) {
        metaDataObject.logoFileId = resp[0].id
        $('.cgUploadText').text("Finished Uploading Logo")
        $('.cgUploadText').css('font-weight', '700')
        $('#cgUploadImg').css('display', 'none')

      }else {
        cgToast("Upload failed, please try again later")
      }
    })
    .catch(err => {
      cgToast("Upload failed, please try again later")
    })
  }
  else if(file.size > maximumSize) {
    cgToast("File Size must be less than 2MB")
    return
  }
  else if(type !== 'image') {
    cgToast("File type must be an image")
  }

}


function adaptContactMenuDisplay(subcategory) {
  let listItems = document.querySelectorAll('ul.contact-menu > li');
  for(let listItem of listItems) {
    let subcategoryRestriction = listItem.getAttribute('subcategory');
    if(subcategoryRestriction && subcategoryRestriction !== subcategory) {
      listItem.classList.add('hidden-menu-item');
    } else {
      listItem.classList.remove('hidden-menu-item');
    }
  }
}

function adjustSelectAllChk(deptView = false) {
  let selectAllChk = document.getElementById('selectAllChk');
  selectAllChk.checked = deptView ? document.querySelectorAll('.checkbox-container-crew input').length > 0 : document.querySelectorAll('tr.crew-row.selected-row:not(.cg-blueprint):not(.hidden-row)').length > 0;
  selectAllChk.indeterminate = selectAllChk.checked &&
    ( deptView ? true : document.querySelectorAll('tr.crew-row:not(.selected-row):not(.cg-blueprint):not(.hidden-row)').length > 0 );
}

function addToDistroList(e) {
  let selectedKeys, selectedMemberText;
  $('#contact-dropdown').removeClass('is-open')

  if(e === null) {
    let crewMember = findCorrespondingContact(clickedMenu.element, croogloocurrentpage.securityPageName 
      === 'crew' ? $(clickedMenu.element).closest('.member-container').attr('id') : null)
    selectedKeys = [crewMember.id];
    selectedMemberText = i18n.t("js.crew.add-distro.text", {count:1, name:findCrewMemberTitle(crewMember)});
  } else {
    selectedKeys = Object.keys(e.panel.selectedItems);
    selectedMemberText = i18n.t("js.crew.add-distro.text", {count:selectedKeys.length, name:e.panel.selectedItems[selectedKeys[0]]});
  }

  let newDistroListId;

  swal({
    title: i18n.t("crew.menu.distro-add"),
    html: selectedMemberText,
    type: 'info',
    input: 'select',
    inputOptions: mapEntity(allDistrGroups, 'distributionListName'),
    inputPlaceholder: i18n.t("js.crew.add-distro.select"),
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("button.confirm"),
    cancelButtonText: i18n.t("button.cancel"),
    allowOutsideClick: () => !swal.isLoading(),
    showLoaderOnConfirm: true,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    preConfirm: function(listID) {
      return new Promise(function(resolve, reject) {
        if(listID === '') {
          reject(i18n.t("js.sec.no-list"));
        }
        apicall('distributionapi','addDistributionListMember', {
          personId: selectedKeys.join(','),
          distributionListId: newDistroListId = listID
        }).then(resolve);
      });
    }
  }).then(function() {
    swal({
      title: i18n.t("response.success"),
      type: 'success',
      showCancelButton: false,
      showCloseButton: false,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("OK"),
      allowOutsideClick: true
    }).catch(swal.noop);

    try {
      let distributionListName = allDistrGroups
       .find(d => d.key.name === newDistroListId).properties.distributionListName;
      selectedKeys.forEach(id => {
        try {
          let crewMember = crewMemberIdMap[id];
          let distributionLists;
          if(!crewMember.distributionLists) {
            distributionLists = distributionListName;
          } else if(!crewMember.distributionLists.split(', ').includes(distributionListName)) {
            distributionLists = crewMember.distributionLists + ', ' + distributionListName;
          } else {
            distributionLists = crewMember.distributionLists;
          }
          updateContactProperty(id, 'distributionLists', distributionLists);
          let crewRow = croogloocurrentpage.securityPageName === 'crew' ? document.querySelectorAll(`.member-container[id="${id}"]`) :  document.querySelector(`tr.crew-row[personid="${id}"]`);
          let distListDiv = croogloocurrentpage.securityPageName === 'crew' ? crewRow.querySelector('.dist-list-text') : crewRow.querySelector('div.contact-list')
          distListDiv.textContent = distributionLists;

        } catch(e1) {
          console.error(e1);
        }
      });
    } catch(e2) {
      console.error(e2);
    }


  }).catch(swal.noop);
}

function fetchUserSecurityList(done) {
  apicall('securityadminapi', 'fetchUserSecurityList', {}).then(resp => {
    if(resp.responseCode !== '0') {
      throw 'fetchUserSecurityList returned response code: ' + resp.responseCode;
    }
    userSecurityListId = resp.responseMessage;
    done();
  }, rejection => {
    throw rejection;
  }).catch(err => {
    console.error(err);
    swalToast({
      type: 'error',
      title: i18n.t("js.crew.error.seclist")
    });
    done();
  });
}

function changeSubcategory(e) {
  let selectedKeys, selectedMemberText;

  if (e === null) {
    let crewMember = findCorrespondingContact(clickedMenu.element);
    selectedKeys = [crewMember.id];
    selectedMemberText = i18n.t("js.crew.category.text", { count: 1, name: findCrewMemberTitle(crewMember) });
  } else {
    selectedKeys = Object.keys(e.panel.selectedItems);
    selectedMemberText = i18n.t("js.crew.category.text", { count: selectedKeys.length, name: e.panel.selectedItems[selectedKeys[0]] });
  }

  let newSubcategory;

  swal({
    title: i18n.t("crew.menu.category"),
    html: selectedMemberText,
    type: 'info',
    input: 'select',
    inputOptions: {
      agent: i18n.t("int.nav.cast-crew.agent"),
      cast: i18n.t("int.nav.cast-crew.cast"),
      crew: i18n.t("int.nav.cast-crew.crew"),
      studio: i18n.t("int.nav.cast-crew.studio"),
      union: i18n.t("int.nav.cast-crew.union"),
      vendor: i18n.t("int.nav.cast-crew.vendor"),
    },
    inputPlaceholder: i18n.t("js.crew.category.select"),
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("button.confirm"),
    cancelButtonText: i18n.t("button.cancel"),
    allowOutsideClick: () => !swal.isLoading(),
    showLoaderOnConfirm: true,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    preConfirm: function (value) {
      return new Promise(function (resolve, reject) {
        if (value === '') {
          reject(i18n.t("js.crew.category.rejection"));
          return;
        }
        apicall('personapi', 'changeSubcategory', {
          personIDs: selectedKeys.join(','),
          newSubcategory: newSubcategory = value
        }).then(resolve);
      });
    }
  }).then(function () {
    swal({
      title: i18n.t("response.success"),
      type: 'success',
      showCancelButton: false,
      showCloseButton: false,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("OK"),
      allowOutsideClick: true
    }).catch(swal.noop);

    if (croogloocurrentpage.securityPageName === 'allContacts') {
      console.debug('must now update ' + selectedKeys.join(', ') + ' to ' + newSubcategory);
      selectedKeys.forEach(id => updateContactProperty(id, 'subcategory', newSubcategory));
    } else {
      console.debug('removing all contact data for: ' + selectedKeys.join(', '));
      removeAllLocalContactData(selectedKeys.join(','));
    }

  }).catch(swal.noop);
}

function updateContactProperty(contactId, propertyName, propertyValue) {
  try {
    if(crewMemberIdMap[contactId].hasOwnProperty(propertyName)) {
      crewMemberIdMap[contactId][propertyName] = propertyValue;
    }
    if(crewMemberIdMap[contactId].entityProperties.hasOwnProperty(propertyName)) {
      crewMemberIdMap[contactId].entityProperties[propertyName] = propertyValue;
    }
  } catch(e) {
    console.error(e);
  }
}

function changeDepartment(e) {
  let selectedKeys, selectedMemberText;
  $('#contact-dropdown').removeClass('is-open')

  if(e === null) {
    let crewMember = findCorrespondingContact(clickedMenu.element, croogloocurrentpage.securityPageName 
      === 'crew' ? $(clickedMenu.element).closest('.member-container').attr('id') : null)
    selectedKeys = [crewMember.id];
    selectedMemberText = i18n.t("js.crew.category.text", {count:1, name:findCrewMemberTitle(crewMember)});
  } else {
    selectedKeys = Object.keys(e.panel.selectedItems);
    selectedMemberText = i18n.t("js.crew.category.text", {count:selectedKeys.length, name:e.panel.selectedItems[selectedKeys[0]]});
  }

  let newDepartmentId;

  swal({
    title: i18n.t("crew.menu.department"),
    html: selectedMemberText,
    type: 'info',
    input: 'select',
    inputOptions: mapEntity(allDepartments, 'departmentName'),
    inputPlaceholder: i18n.t("js.crew.department.select"),
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("button.confirm"),
    cancelButtonText: i18n.t("button.cancel"),
    allowOutsideClick: () => !swal.isLoading(),
    showLoaderOnConfirm: true,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    preConfirm: function(deptID) {
      return new Promise(function(resolve, reject) {
        if(deptID === '') {
          reject(i18n.t("js.crew.department.rejection"));
        }
        apicall('personapi', 'changeDepartment', {
          personIDs: selectedKeys.join(','),
          newDepartmentID: newDepartmentId = deptID
        }).then(resolve);
      });
    }
  }).then(function() {
    swal({
      title: i18n.t("response.success"),
      type: 'success',
      showCancelButton: false,
      showCloseButton: false,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("OK"),
      allowOutsideClick: true
    }).catch(swal.noop);

    if(croogloocurrentpage.securityPageName.toLowerCase() === 'crew') {
      try {
        console.debug('must now update ' + selectedKeys.join(', ') + ' department ID to ' + newDepartmentId);
        let newDepartmentName = allDepartments.find(d => d.key.name === newDepartmentId).properties.departmentName;
        selectedKeys.forEach(id => {
          updateDepartmentRowElements(id, true); // remove undeeded department rows
          updateContactProperty(id, 'departmentId', newDepartmentId);
          updateContactProperty(id, 'department', newDepartmentName);
          updateContactProperty(id, 'departmentName', newDepartmentName);
          let crewRow = document.querySelector(`tr.crew-row[personid="${id}"]`);
          crewRow.setAttribute('departmentId', newDepartmentId);
          crewRow.setAttribute('department', newDepartmentName);
          updateDepartmentRowElements(id, false); // add missing department rows
        });
        reorderContacts();
      } catch(e1) {
        console.error(e1);
        // Dynamic UI update failed, reloading page to fix display
        r.reload();
      }
    }

  }).catch(swal.noop);
}

function reorderContacts() {
  console.debug('reordering contacts');
  sortContacts(sortedCrewMembers, getActiveSortOption()); //the contact array
  orderContactRows(); //the actual HTML elements
  adjustDeptVisibiliy();
}

function getActiveSortOption() {
  try {
    if(croogloocurrentpage.securityPageName === 'crew') {
      secureLocalStorage.set('crew-view-mode', 'dept')
    }
  } catch(e) {
    console.error(e);
  }
  return croogloocurrentpage.pageName.toLowerCase() === 'crew'
    ?(document.getElementById('deptOrderOpt').checked
      ?SORT_OPTIONS.BY_DEPTS // crew page dept checked
      :SORT_OPTIONS.ALPHANUM.FIRST_NAME) // crew page dept not checked
    :(['cast', 'agent'].includes(croogloocurrentpage.pageName.toLowerCase()) 
      ?SORT_OPTIONS.NUMBER_NAME_AGENTS // cast page
      :SORT_OPTIONS.ALPHANUM.FIRST_NAME); // all pages but cast and crew
}

function exportCrewList() {

  
  return new Promise(done => {

    swal({
      title: i18n.t("crew.list.version"),
      html: 
      '<div style="margin-top: 30px; display: flex; flex-direction: column; align-items: center; justify-content: center" class="export-crew-pdf">'+
        `<select name="selectColor">`+
          '<option selected value="-">'+i18n.t("js.file.script.color.default")+'</option>'+
          '<option value="white">White</option>'+
          '<option value="blue">Blue</option>'+
          '<option value="pink">Pink</option>'+
          '<option value="yellow">Yellow</option>'+
          '<option value="green">Green</option>'+
          '<option value="goldenrod">Goldenrod</option>'+
          '<option value="buff">Buff</option>'+
          '<option value="salmon">Salmon</option>'+
          '<option value="cherry">Cherry</option>'+
          '<option value="tan">Tan</option>'+
          '<option value="lavender">Lavender</option>'+
        '</select>'+
        '<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; row-gap: 10px">'+
          '<button id="download-pdf" style="padding: 10px 25px; color: white; background-color: #8EACBB; border-radius: 6px; width: 100%; cursor: pointer">'+
            'Download PDF'+
          '</button>'+
          '<button id="send-dist" style="padding: 10px 25px; color: white; background-color: #01aa25; border-radius: 6px; cursor: pointer">'+
            'Send as Distribution'+
          '</button>'+
        '</div>'+
      '</div>',
      customContainerClass: 'swal-container-export',
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      onOpen: () => {

        document.querySelector('.swal2-modal').style.borderRadius = '7px'
        document.querySelector('.swal2-header').style.borderRadius = '7px'

        $('#download-pdf').on('click', () => {
          if($('select[name=selectColor]').val() == "-"){
            cgToast("Must select color before proceeding")
            return
          }
          else {
            $('.swal2-container').css('display', 'none')
            done({
              color: $('select[name=selectColor]').val(),
              functionality: 'download'
            });
          }
        })
  
        $('#send-dist').on('click', () => {
          if($('select[name=selectColor]').val() == "-"){
            cgToast("Must select color before proceeding")
            return
          }
          else {
            $('.swal2-container').css('display', 'none')
            done({
              color: $('select[name=selectColor]').val(),
              functionality: 'send'
            });
          }
        })
      }
    })
  })
  .then((scriptOptions) => {
    showSpinner()
    apicall('personapi', 'generateCrewListPDF', {color: scriptOptions.color})
    .then(resp => {
      hideSpinner()
      if(resp.responseCode === '0') {
        apicall('personapi', 'fetchCrewListPDF', {color: scriptOptions.color})
        .then(resp => {
          if(scriptOptions.functionality === 'download') {
                  window.open(resp.responseMessage, '_blank').focus();
                }
                else {
                  r.loadPage('compose', {attachmentID: resp.entityId})
                }
        })
      }
      else {
        console.debug(resp)
        swal('Could not export', 'Please try again later', 'error')
        console.log("Could not generate crew list")
      }
    })
  })
  .catch((err) => {
    hideSpinner()
    console.error(err)
  })  
}

function createNumberedHeader(number) {
  
  let headerContainer = document.createElement('div')
  let numberWrapper = document.createElement('div')

  numberWrapper.style.display = 'flex'
  numberWrapper.style.alignItems = 'center'
  numberWrapper.style.justifyContent = 'center'
  
  for(let i=1; i<=3; i++) {

    let numberContainer = document.createElement('div')
    let text = document.createElement('p')
    text.innerHTML = `${i}`

    
    numberContainer.style.display = 'flex'
    numberContainer.style.alignItems = 'center'
    numberContainer.style.justifyContent = 'center'
    numberContainer.style.borderRadius = '50%'
    numberContainer.style.height = '40px'
    numberContainer.style.width = '40px'
    
    numberContainer.id = `number${i}`

    text.style.padding = '10px'
    text.style.color = 'white'
    text.style.marginBottom = 0
    if(i === number) {
      numberContainer.style.backgroundColor = '#01aa25'
    }
    else {
      numberContainer.style.backgroundColor = 'gray'
    }
    
    numberContainer.appendChild(text)
    numberWrapper.appendChild(numberContainer)

    if(i < 3) {
      let div = document.createElement('div')
      let line = document.createElement('div')

      div.style.width = '15px'
      div.style.height = '100%'
      div.style.display = 'flex'
      div.style.alignItems = 'center'
      div.style.justifyContent = 'center'
      div.appendChild(line)
      line.style.height = '5px'
      line.style.width = '100%'
      line.style.backgroundColor = 'gray'
      numberWrapper.appendChild(div)
    }

  }

  headerContainer.appendChild(numberWrapper)
  return headerContainer
}

function orderDepartments() {
  let cg_custom_btns = [
    ['strong', 'em', 'underline']
  ];

  $.trumbowyg.svgPath = '/assets/css/trumbowyg/icons.svg';
  
  if(document.getElementById('dropzoneContainer') == null) {
    document.querySelector('.contact-listings').appendChild(dropzoneContainerClone)
  }
  
  let isCrewListMetadataSet = localStorage.getItem('isCrewListMetadataSet')

  if(isCrewListMetadataSet == 'false') {
    
    swal({
      title: 'Generate A Crew List',
      html: '<p style="color: #595959; font-size: 17px"><b>Customize your crew list details, then download or share through distribution</b></p>'
      +'<p style="font-size: 15px">TIP: <i>Contacts without a department assigned will not appear in your crew list</i></p>'
      +'<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; row-gap: 20px">'
        +'<div style="display: flex; align-items: center; justify-content: flex-start; width: 50%;column-gap: 15px">'
          +'<div style="width: 40px; height: 40px;border-radius: 50%; background-color: gray; display: flex; align-items: center; justify-content: center">'
            +'<p style="color: white; margin-bottom: 0; padding: 10px">1</p>'
          +'</div>'
          +'<p style="color: #595959; font-size: 17px; margin-bottom: 0"><b>Add Production Logo</b></p>'
        +'</div>'
        +'<div style="display: flex; align-items: center; justify-content: flex-start; width: 50%; column-gap: 15px">'
          +'<div style="width: 40px; height: 40px;border-radius: 50%; background-color: gray; display: flex; align-items: center; justify-content: center">'
            +'<p style="color: white; margin-bottom: 0; padding: 10px">2</p>'
          +'</div>'
          +'<p style="color: #595959; font-size: 17px; margin-bottom: 0"><b>Add Header Info</b></p>'
        +'</div>'
        +'<div style="display: flex; align-items: center; justify-content: flex-start; column-gap: 15px; width: 50%">'
          +'<div style="width: 40px; height: 40px;border-radius: 50%; background-color: gray; display: flex; align-items: center; justify-content: center">'
            +'<p style="color: white; margin-bottom: 0; padding: 10px">3</p>'
          +'</div>'
          +'<p style="color: #595959; font-size: 17px; margin-bottom: 0"><b>Set Department Order</b></p>'
        +'</div>'
      +'</div>'
      ,
      confirmButtonText: 'Get Started',
      showCloseButton: true
    })
      .then(() => {
      return new Promise(accept => {
        swal({
          title: "Add Logo",    
          html: document.querySelector("#dropzoneContainer"),
          onOpen: function () {
            let numberedHeader = createNumberedHeader(1)
            document.querySelector('.swal2-header').appendChild(numberedHeader)
  
            document.querySelector("#dropzoneContainer").style.display = "flex" ;
            document.getElementById('cgUploadImg').style.height = '100px'
            document.getElementById('dropzoneContainer').style.alignItems = 'center'
            document.getElementById('dropzoneContainer').style.justifyContent = 'center'
            document.getElementById('myDropzone').style.display = 'contents'
           
            let nextButton = document.createElement('button')
            let skipButton = document.createElement('button')
            let buttonContainer = document.createElement('div')
    
            buttonContainer.appendChild(nextButton)
            buttonContainer.appendChild(skipButton)
    
            buttonContainer.style.display = 'flex'
            buttonContainer.style.alignItems = 'center'
            buttonContainer.style.justifyContent = 'center'
            buttonContainer.style.columnGap = '50px'
            buttonContainer.style.marginTop = '40px'
    
            nextButton.style.padding = '10px 45px'
            skipButton.style.padding = '10px 45px'
    
            nextButton.style.backgroundColor = '#01aa25'
            nextButton.style.color = 'white'
            nextButton.style.borderRadius = '7px'
    
            skipButton.style.backgroundColor = 'gray'
            skipButton.style.color = 'white'
            skipButton.style.borderRadius = '7px'
    
            nextButton.innerText = "Next"
            skipButton.innerText = "Skip"
            
            $(nextButton).on('click', () => {
              if(metaDataObject.hasOwnProperty('logoFileId')) {
                accept()
              }
              else {
                cgToast("Must upload logo or else click on skip")
              }
            })
    
            $(skipButton).on('click', () => {
              metaDataObject.logoFileId = ""
              accept()
            })
    
            $('.swal2-content').append(buttonContainer)
    
    
    
          },
          showConfirmButton: false,
          showCloseButton: true,
        })
      })
    })
    .then(() => {
      return new Promise(accept => {
        swal({
          type: 'info',
          title: "Left Header",    
          html:      
          '<div>'
            + '<textarea id="message" class="wysiwyg-email" placeholder="Add your header" rows="2"></textarea>'       
          +'</div>',
          onOpen: () => {
            $('.wysiwyg-email').trumbowyg({
              btns: cg_custom_btns,
              semantic: false,
            }).on('tbwblur', () => {
            });
  
            let numberedHeader = createNumberedHeader(2)
            document.querySelector('.swal2-header').appendChild(numberedHeader)
            document.querySelector('.trumbowyg-box').style.height = '100px'

            // Prevent HTML from being pasted with its styling. Only paste the text.
            document.querySelector('.trumbowyg-editor').addEventListener("paste", function (event) {
              // cancel paste
              event.preventDefault();

              // get text representation of clipboard
              var text = (event.originalEvent || event).clipboardData.getData('text/plain');

              // insert text manually
              document.execCommand("insertHTML", false, text);
            });

            let nextButton = document.createElement('button')
            let skipButton = document.createElement('button')
            let buttonContainer = document.createElement('div')
    
            buttonContainer.appendChild(nextButton)
            buttonContainer.appendChild(skipButton)
    
            buttonContainer.style.display = 'flex'
            buttonContainer.style.alignItems = 'center'
            buttonContainer.style.justifyContent = 'center'
            buttonContainer.style.columnGap = '50px'
            buttonContainer.style.marginTop = '30px'
    
            nextButton.style.padding = '10px 45px'
            skipButton.style.padding = '10px 45px'
    
            nextButton.style.backgroundColor = '#01aa25'
            nextButton.style.color = 'white'
            nextButton.style.borderRadius = '7px'
    
            skipButton.style.backgroundColor = 'gray'
            skipButton.style.color = 'white'
            skipButton.style.borderRadius = '7px'
    
            nextButton.innerText = "Next"
            skipButton.innerText = "Skip"
            
            $(nextButton).on('click', () => {
              if(document.querySelector('.trumbowyg-editor').innerHTML !== "") {
                let inputString = document.querySelector('.trumbowyg-editor').innerHTML
                metaDataObject.leftHeader = formatHtmlString(inputString)
                accept()
              }
              else {
                cgToast("Must input text before proceeding or else click Skip")
              }
            })
    
            $(skipButton).on('click', () => {
              metaDataObject.leftHeader = ""
              accept()
            })
    
            $('.swal2-content').append(buttonContainer)
    
            
          },
          showConfirmButton: false,
          showCloseButton: true,
        })
      })
      .then(() => {
        return new Promise(accept => {
          swal({
            type: 'info',
            title: "Right Header",    
            html:      
            '<div>'
              + '<textarea id="message" class="wysiwyg-email" placeholder="Add your header" rows="2"></textarea>'       
            +'</div>',
            onOpen: () => {        
              $('.wysiwyg-email').trumbowyg({
                btns: cg_custom_btns,
                semantic: false,
              }).on('tbwblur', () => { });
  
              document.querySelector('.trumbowyg-box').style.height = '100px'

              // Prevent HTML from being pasted with its styling. Only paste the text.
              document.querySelector('.trumbowyg-editor').addEventListener("paste", function (event) {
                // cancel paste
                event.preventDefault();

                // get text representation of clipboard
                var text = (event.originalEvent || event).clipboardData.getData('text/plain');

                // insert text manually
                document.execCommand("insertHTML", false, text);
              });
    
              let numberedHeader = createNumberedHeader(2)
              document.querySelector('.swal2-header').appendChild(numberedHeader)
  
              let nextButton = document.createElement('button')
              let skipButton = document.createElement('button')
              let buttonContainer = document.createElement('div')
      
              buttonContainer.appendChild(nextButton)
              buttonContainer.appendChild(skipButton)
      
              buttonContainer.style.display = 'flex'
              buttonContainer.style.alignItems = 'center'
              buttonContainer.style.justifyContent = 'center'
              buttonContainer.style.columnGap = '50px'
              buttonContainer.style.marginTop = '30px'
      
              nextButton.style.padding = '10px 45px'
              skipButton.style.padding = '10px 45px'
      
              nextButton.style.backgroundColor = '#01aa25'
              nextButton.style.color = 'white'
              nextButton.style.borderRadius = '7px'
      
              skipButton.style.backgroundColor = 'gray'
              skipButton.style.color = 'white'
              skipButton.style.borderRadius = '7px'
      
              nextButton.innerText = "Next"
              skipButton.innerText = "Skip"
              
              $(nextButton).on('click', () => {
                if(document.querySelector('.trumbowyg-editor').innerHTML !== "") {
                  let inputString = document.querySelector('.trumbowyg-editor').innerHTML
                  metaDataObject.rightHeader = formatHtmlString(inputString)

                  $('.swal2-container').css('display', 'none')
                  localStorage.setItem('isCrewListMetadataSet', 'true')
  
                  apicall('personapi', 'saveCrewListMetadata', {logoFileId: metaDataObject.logoFileId, leftHeader: metaDataObject.leftHeader, rightHeader: metaDataObject.rightHeader})
                  .then(resp => {
                    console.log(resp)
                  })
                  .catch(err => {
                    console.log(err)
                  })

                  accept()
                }
                else {
                  cgToast("Must input text before proceeding or else click Skip")
                }
              })
      
              $(skipButton).on('click', () => {
                metaDataObject.rightHeader = ""
                $('.swal2-container').css('display', 'none')
                localStorage.setItem('isCrewListMetadataSet', 'true')

        
                apicall('personapi', 'saveCrewListMetadata', {logoFileId: metaDataObject.logoFileId, leftHeader: metaDataObject.leftHeader, rightHeader: metaDataObject.rightHeader})
                .then(resp => {
                  console.log(resp)
                })
                .catch(err => {
                  console.log(err)
                })
                
                
  
                accept()
              })
      
              $('.swal2-content').append(buttonContainer)
    
            },
            showConfirmButton: false,
            showCloseButton: true,
          })
        })
        .then(() => {
          reorderablesDepartments = []
          reorderablesDepartmentsHTML = []
          let modal = document.createElement('div')
          let reorderablesDiv = document.createElement('div')
          reorderablesDiv.classList.add("reorderables-container")
        
          reorderDepartments(reorderablesDiv);
          
          modal.classList.add('order-department-modal')
          modal.appendChild(reorderablesDiv)
        
          $('body').append(modal)
          addDraggableListener()
        })
      })
    })

  }  
  else {
    reorderablesDepartments = []
    reorderablesDepartmentsHTML = []
    let modal = document.createElement('div')
    let reorderablesDiv = document.createElement('div')
    reorderablesDiv.classList.add("reorderables-container")
  
    reorderDepartments(reorderablesDiv);
    
    modal.classList.add('order-department-modal')
    modal.appendChild(reorderablesDiv)
  
    $('body').append(modal)
    addDraggableListener()
  }


}

function reorderDepartments(reorderablesDiv) {

  let unorderedList = document.createElement('ul');
  unorderedList.classList.add("draggable-list", "ul-order-departments");
  
  let closeContainer = document.createElement('div')
  let closeIcon = document.createElement('i')
  closeIcon.classList.add("fa", "fa-close")
  closeIcon.style.fontSize = '18px'
  closeIcon.style.color = '#7B7B7B'
  closeIcon.style.cursor = 'pointer'

  closeIcon.addEventListener('click', () => {
    $('.order-department-modal').css('display', 'none')
  })

  closeContainer.appendChild(closeIcon)
  closeContainer.style.display = 'flex'
  closeContainer.style.alignItems = 'center'
  closeContainer.style.justifyContent = 'flex-end'
  closeContainer.style.padding = '10px'

  let orderHeader = document.createElement('h1')
  orderHeader.innerText = "ORDER DEPARTMENTS"
  orderHeader.style.textAlign = 'center'
  orderHeader.style.color = 'black'
  orderHeader.style.fontWeight = '600'
  orderHeader.style.fontSize = '26px'
  
  let addDepartmentDiv = document.createElement('div')
  let addDepartmentIconContainer = document.createElement('div')
  let addDepartmentIcon = document.createElement('img') 
  let addDepartmentText = document.createElement('p')

  addDepartmentIconContainer.appendChild(addDepartmentIcon)
  addDepartmentIconContainer.appendChild(addDepartmentText)
  addDepartmentDiv.appendChild(addDepartmentIconContainer)

  addDepartmentIcon.src = '../assets/img/add-department.svg'

  addDepartmentText.innerText = 'ADD DEPARTMENT'
  addDepartmentText.style.color = '#2BAAE1'
  addDepartmentText.style.fontSize = '14px'
  addDepartmentText.style.fontWeight = '700'
  addDepartmentText.style.lineHeight = '19px'
  addDepartmentText.style.marginBottom = '0'

  addDepartmentDiv.style.display = 'flex'
  addDepartmentDiv.style.alignItems = 'center'
  addDepartmentDiv.style.justifyContent = 'flex-end'
  addDepartmentDiv.style.paddingRight = '10px'

  addDepartmentIconContainer.style.display = 'flex'
  addDepartmentIconContainer.style.alignItems = 'center'
  addDepartmentIconContainer.style.justifyContent = 'center'
  addDepartmentIconContainer.style.columnGap = '7px'
  addDepartmentIconContainer.style.cursor = 'pointer'

  let noteContainer = document.createElement('div')
  let note = document.createElement('p')
  note.innerHTML = "<i>To reset your crew list headers and upload new ones, go to the Production Settings page</i>"
  note.style.color = "gray"
  note.style.fontSize = '14px'
  note.style.textAlign = 'center'
  note.style.paddingTop = '5px'
  note.style.marginBottom = 0
  noteContainer.appendChild(note)

  $(addDepartmentIconContainer).on('click', () => {
    $('.order-department-modal').css('display', 'none')
    createNewDept()
  })

  let buttonContainer = document.createElement('div')
  let buttonAccept = document.createElement('div')

  buttonAccept.addEventListener('click', () => {
    let departmentOrdering = {}
    let i = 1;
    for(let dept of reorderablesDepartments) {
      departmentOrdering[dept.getAttribute('department')] = i++
    }
    
    showSpinner()
    console.debug("Dept ordering:", departmentOrdering)
    apicall('personapi', 'updateDepartmentRelativeOrder', {}, { props: departmentOrdering })
    .then(resp => {

        hideSpinner()

        if (resp.responseCode == 0) {
            $('.order-department-modal').css('display', 'none')
            swal("Successfully changed department ordering", '', 'success')
            console.debug("Department order updated successfully");

            let containersToReorder = document.querySelectorAll('.listing-container')
            containersToReorder.forEach(item => $(item).detach())

            reorderablesDepartments.forEach((orderedDepartment) => {

              containersToReorder.forEach((departmentToReorder) => {
                if(orderedDepartment.id === departmentToReorder.querySelector('.department-container').getAttribute('id')) {
                  $('.all-listings-container').append(departmentToReorder)
                }
              })
            })

            exportCrewList()

        } else {
            $('.order-department-modal').css('display', 'none')
            swal("Error changing department ordering", 'Please try again later', 'error')
            console.debug("Department NOT updated; something went wrong with updateRelativeDepartmentOrder API");
        }
    })
    .catch(err => {
      hideSpinner()
      console.error(err)
    })
  })



  buttonAccept.style.height = '45px'
  buttonAccept.style.width = '280px';
  buttonAccept.style.borderRadius = '10px'
  buttonAccept.style.backgroundColor = '#0CC265'
  buttonAccept.innerText = "Confirm Department Order"
  buttonAccept.style.color = "white"
  buttonAccept.style.fontWeight = '700'
  buttonAccept.style.fontSize = '18px'
  buttonAccept.style.display = 'flex'
  buttonAccept.style.alignItems = "center"
  buttonAccept.style.justifyContent = "center"
  buttonAccept.style.cursor = 'pointer'

  
  buttonContainer.appendChild(buttonAccept)

  buttonContainer.style.padding = '20px'
  buttonContainer.style.display = 'flex'
  buttonContainer.style.alignItems = 'center'
  buttonContainer.style.justifyContent = 'center'
  buttonContainer.style.columnGap = '20px'


  let departments = document.querySelectorAll('.department-container')
  let departmentToPush = document.querySelectorAll('.department-container')

  departments.forEach((department, index) => {
    let tempDiv = document.createElement('li')
    let departmentDiv = document.createElement('div')
    let iconDiv = document.createElement('div')
    let textDiv = document.createElement('div')
    let icon = document.createElement('i')
    let text = document.createElement('p')

    tempDiv.setAttribute('real-index', index);
    tempDiv.setAttribute('data-index', index);
    tempDiv.classList.add("reorderable-items-departments");

    departmentDiv.classList.add("draggable", "department-order-container");
    departmentDiv.setAttribute("draggable", true);

    icon.setAttribute("class", "fas fa-grip-lines");
    iconDiv.appendChild(icon)

    text.innerText = $(department).find('.department-name')[0].innerText
    textDiv.appendChild(text)
    text.setAttribute("class", "order-department-text")

    departmentDiv.appendChild(iconDiv)
    departmentDiv.appendChild(textDiv)

    tempDiv.appendChild(departmentDiv)

    unorderedList.appendChild(tempDiv);

    reorderablesDepartments.push(departmentToPush[index]);   
    
    reorderablesDepartmentsHTML.push(tempDiv); 
  })

  reorderablesDiv.appendChild(closeContainer)
  reorderablesDiv.appendChild(orderHeader)
  let numberedHeader = createNumberedHeader(3)
  reorderablesDiv.appendChild(numberedHeader)
  reorderablesDiv.appendChild(unorderedList)
  reorderablesDiv.appendChild(addDepartmentDiv)
  reorderablesDiv.appendChild(noteContainer)
  reorderablesDiv.appendChild(buttonContainer)
}

/**
 * This method takes an html string provided by the header workflow and returns a backend compatible 
 * markup version of it containing only <br>, <b>, <i> and <u> tags
 * 
 * Order of operations for this method are as follows:
 * 1. All non-breaking spaces are changed to normal spaces
 * 2. All ampersand representations (&amp;) are changed to normal ampersands (&)
 * 3. All <br>, <b>, <i> and <u> tags have their attributes removed if they have any
 * 4. All opening <p> tags with or without attributes are compltely replaced by <br> tags
 * 5. All closing </p> tags are completely removed
 * 6. All opening <strong> tags with or without attributes are completely replaced by opening <b> tags
 * 7. All closing <strong> tags are completely replaced by closing </b> tags
 * 8. All opening tags of any kind OTHER THAN <br>, <b>, <i> and <u> are completely removed
 * 9. All closing tags of any kind OTHER THAN <br>, <b>, <i> and <u> are completely removed
 * 
 * @param {*} inputString 
 * @returns formatted string for backend usage containing only <br>, <b>, <i> and <u> tags
 */
function formatHtmlString(inputString) {
  const outputString = inputString
    .replace(/&nbsp;/g, " ")
    .replace(/&amp;/g, "&")
    .replace(/<(br|b|i|u)[^>]*>/g, "<$1>")
    .replace(/<p[^>]*>/g, "<br>")
    .replace(/<\/p>/g, "")
    .replace(/<strong\b[^>]*>/g, "<b>")
    .replace(/<\/strong>/g, "</b>")
    .replace(/<([^>]+)><\/\1>/g, "")
    .replace(/<\/?(?!br|b|i|u)(?:(?!\/)[^>])*>/g, "");
  return outputString;
}

function addNewContact() {
  let subcategory = ['cast','crew', 'agent', 'studio', 'union', 'vendor'].includes(croogloocurrentpage.pageName)
    ?croogloocurrentpage.pageName :'crew';
  r.loadPage('contact', {
    subcategory: subcategory,
    isSidesStandAlone: isSidesStandAlone,
    reloadOnClose: false
  });
}

function importContacts(event){
  $(document).on('click', '.swal-close-btn', () => swal.clickCancel());
  $(document).on('click', '.swal-confirm-btn', () => swal.clickConfirm());
  $(document).on('click', '#importFromGmailBtn', (event) => importGoogleContacts(event));
  $(document).on('click', '#importFromVCardBtn', () => window.location.href = '#uploadVCards');
  $(document).on('click', '#importFromExcelBtn', () =>{
    window.location.href = "https://storage.googleapis.com/8810931_croogloo_public/"+ encodeURIComponent('Croogloo Contact Import.xlsx');
    window.location.href = '#uploadTemplates';
  });

  swal({
    type: 'info',
    title: i18n.t("js.crew.import.title"),    
    customContainerClass: 'swal-container-import',
    html:      
    '<div class="button-group cell">'
        +`<button id="importFromGmailBtn" class="swal-confirm-btn button slim blue">${i18n.t("js.crew.import.opts.gmail")}</button>`
        +`<button id="importFromVCardBtn" class="swal-confirm-btn button slim">${i18n.t("js.crew.import.opts.vcard")}</button>`
        +`<button id="importFromExcelBtn" class="swal-confirm-btn button slim green">${i18n.t("js.crew.import.opts.excel")}</button>`        
    +'</div>',
    showConfirmButton: false,
    showCancelButton: false,
    allowOutsideClick: true,
    showCloseButton: true,
  }).catch(swal.noop);
}

function importGoogleContacts(event) {
  if (croogloocurrentpage.getAccessLevel() < 2) {
    cgToast(i18n.t("js.unauthorized"));
    return;
  }
  // Prevent multiple clicks
  if(!event.detail || event.detail == 1) {
    CGGoogleContacts.init().then(function(){
      CGGoogleContacts.importGoogleContacts().then(function() {
      });
    });
  }
}

function exportToGoogleContacts(event) {
  if (croogloocurrentpage.getAccessLevel() < 2) {
    cgToast(i18n.t("js.unauthorized"));
    return;
  }
  // Prevent multiple clicks
  if(!event.detail || event.detail == 1) {
    CGGoogleContacts.init().then(function() {
      CGGoogleContacts.exportToGoogleContacts().then(function() {
      });
    });
  }
}

/**
 * Finds the contact object represented by a table row, using the row's HTML
 * element or any HTML element contained within the said row.
 * @param  {htmlElement} htmlElement An element contained within the row
 * representing the searched contact.
 * @return {Object} The contact corresponding to the given HTML element.
 */
function findCorrespondingContact(htmlElement, id = null) {
  try {
    if(id === null) {
      let enclosingRow = findEnclosingElement(htmlElement, 'TR');
      return crewMemberIdMap[enclosingRow.getAttribute('personId')];
    }
    else {
      return crewMemberIdMap[id]
    }
  } catch(e) {
    console.error(e.message);
    return null;
  }
}

function findEnclosingElement(htmlElement, enclosingTagName, excludeSelf = false) {
  let targetElem = excludeSelf ?htmlElement.parentElement :htmlElement;
  while(targetElem && targetElem.parentElement && targetElem.tagName !== enclosingTagName) {
    targetElem = targetElem.parentElement;
  }
  if(targetElem && targetElem.tagName === enclosingTagName) {
    return targetElem;
  } else {
    throw 'No enclosing element found.';
  }
}

function editContact(relatedElement) {

  $('#contact-dropdown').removeClass('is-open')

  let crewMember = findCorrespondingContact(relatedElement, croogloocurrentpage.securityPageName 
    === 'crew' ? $(clickedMenu.element).closest('.member-container').attr('id') : null)
  let entityProperties = crewMember.entityProperties;
  r.loadPage('contact', {
    personId: crewMember.id, 
    personEntity: entityProperties, 
    isSidesStandAlone: isSidesStandAlone, 
    reloadOnClose: false
  });
}

function hasEitherProperty(object, properties) {
  if(typeof properties === 'string') {
    return object[properties] ?true :false
  }
  for(let p = 0, prop; prop = properties[p++];) {
    if(object[prop]) { return true; }
  }
  return false;
}

function hasAllProperties(object, properties) {
  if(typeof properties === 'string') {
    return object[properties] ?true :false
  }
  for(let p = 0, prop; prop = properties[p++];) {
    if(!object[prop]) { return false; }
  }
  return true;
}

function sendToCompose(requiredProps, filter, missingPropErrorMsg, storeRequiredProps = false) {
  let crewMember = findCorrespondingContact(clickedMenu.element);
  new Promise(function(resolve, reject) {
    if(filter.OR && !hasEitherProperty(crewMember, requiredProps) || filter.AND && !hasAllProperties(crewMember, requiredProps)) {
      reject(missingPropErrorMsg);
    } else {
      validatePageAccess('menu_distribution', resolve, reject);
    }
  }).then(function() {
    if(storeRequiredProps) {
      sessionStorage.setItem('email', crewMember['email']);
    }
    window.location.href = '#compose';
  }, function(error) {
    if(error) {
      //allowing 5 seconds of reading time for each error
      cgToast(error, 5000*(typeof error === 'object' ?error.length :1));
    }
  });
}


//safer than using the actual crewMember.title attribute
function findCrewMemberTitle(crewMember) {
  return (crewMember.firstName ?crewMember.firstName :'')
    + ((crewMember.firstName && crewMember.lastName) ?' ' :'')
    + (crewMember.lastName ?crewMember.lastName :'');
}

function inviteToCroogloo(crewMemberId, crewMemberTitle) {

  let crewMembers = [];
  $('#contact-dropdown').removeClass('is-open')

  if(typeof crewMemberId !== 'string') {
    let crewMember = findCorrespondingContact(clickedMenu.element, croogloocurrentpage.securityPageName 
      === 'crew' ? $(clickedMenu.element).closest('.member-container').attr('id') : null)
    crewMemberId = crewMember.id;
    crewMemberTitle = findCrewMemberTitle(crewMember);
    crewMembers.push(crewMember);
  } else {
    crewMemberId.split(',').forEach(id => {
      crewMembers.push(crewMemberIdMap[id]);
    });
  }

  if(crewMembers.length > MAX_CONCURRENT_INVITATIONS) {
    swalToast({
      type: 'error',
      title: i18n.t("js.crew.invite.error", {MAX_CONCURRENT_INVITATIONS})
    });
    return;
  }

  swal({
    title: i18n.t("js.crew.invite.title", {count:crewMemberId.split(',').length}),
    html: generateSecListRadioOptions() + i18n.t("js.crew.invite.text", {count:crewMemberId.split(',').length, name:crewMemberTitle}),
    type: 'info',
    input: 'text',
    inputClass: 'hidden-input', //used to prevent focus error on reject
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("button.send"),
    cancelButtonText: i18n.t("button.cancel"),
    allowOutsideClick: false,
    showLoaderOnConfirm: true,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    allowOutsideClick: () => !swal.isLoading(),
    preConfirm: function(text) {
      return new Promise(function(resolve, reject) {
        let selectedSecList = document.querySelector('input[name="security-list"]:checked');
        if(selectedSecList == null) {
          reject(i18n.t("js.crew.reject.seclist"));
        }
        let selectedListId = selectedSecList.getAttribute('securityListId');
        let isValidSecurityList = false;
        securityLists.forEach(secList => {
          if(secList.key.name === selectedListId && typeof selectedListId === 'string' && selectedListId.trim()) {
            isValidSecurityList = true;
          }
        });
        if(!isValidSecurityList) {
          throw 'Invalid security list'; // could be manually modified by user
        }
        apicall('personapi', 'inviteToCroogloo', {}, {
          personIdList: crewMemberId.split(','),
          securityListId: selectedListId
        }).then(resp => {
          if(typeof resp == 'object' && typeof resp.responseCode == 'string') {
            resolve(resp);
          } else {
            reject(i18n.t("js.crew.error.invite"));
          }
        }, rejection => {
          throw rejection;
        }).catch(err => {
          console.error(err);
          reject(i18n.t("js.crew.error.invite"));
        });
      });
    }
  }).then(function(apiResp) {
    let personIdList = crewMemberId.split(',');
    let allInviteFailed = haveAllInvitationsFailed(apiResp);//COMEHERE : continue from here
    swal({
      title: allInviteFailed ? i18n.t("js.crew.invite.failure", {count: personIdList.length})
        :i18n.t(apiResp.responseCode == '-1' ? "js.crew.invite.success.partial":"response.success"),
      html: (!allInviteFailed ? i18n.t("js.crew.invite.success.text", {count: crewMemberId.split(',').length}):'') + buildFailureReport(apiResp),
      type: allInviteFailed ? 'error':(apiResp.responseCode == '-1' ?'warning' :'success'),
      showCancelButton: false,
      allowOutsideClick: true,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("OK")
    }).catch(swal.noop);
    function haveAllInvitationsFailed(apiResp) {
      return apiResp.responseCode == '-1' && apiResp.responseMessage.split(',').length == personIdList.length;
    }
    function buildFailureReport(apiResp) {
      if(apiResp.responseCode == '0' || !apiResp.responseMessage.trim()) {
        return '';
      }
      let failureMsgParts = [];
      apiResp.responseMessage.split(',').forEach(failure => {
        try {
          let failedCrewMember = crewMemberIdMap[failure.split('~')[0]];
          let failureReason = '(' + failure.split('~')[1] + ')';
          let failedCrewMemberTitle = '<b>'
            + (failedCrewMember.firstName||'')
            + (failedCrewMember.lastName?(' '+failedCrewMember.lastName):'')
            + '</b>';
          failureMsgParts.push(failedCrewMemberTitle + ' ' + failureReason);
        } catch(ex) {
          console.error(ex);
        }
      });
      let failureMsg = '<br>'+i18n.t("js.crew.invite.failed", {count: failureMsgParts.length, contacts: failureMsgParts.join(', ')});
      return failureMsgParts.length ? failureMsg :'';
    }
  }).catch(err => {
    if(typeof err == 'string' && (['cancel','close','esc'].includes(err) || !err.includes(' '))) {
      console.debug('user dismissed Croogloo invitation swal');
      return;
    }
    console.error(err);
    swal({
      title: i18n.t("response.error"),
      html: i18n.t("js.utils.something.bad"),
      type: 'error',
      showCancelButton: false,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("OK"),      
    }).catch(swal.noop);
  });
}

function generateSecListRadioOptions() {
  let container = document.createElement('div');
  if(typeof securityLists !== 'object' || !securityLists.length) {
    throw 'Failed to fetch security lists';
  }
  securityLists.forEach(secList => {
    let input = document.createElement('input');
    input.type = 'radio';
    input.name = 'security-list';
    input.setAttribute('securityListId', secList.key.name);
    input.id = Utility.getValidId(secList.key.name);

    let label = document.createElement('label');
    label.setAttribute('for', input.id);
    label.textContent = secList.properties.securityListName || secList.key.name;
    
    if(label.textContent === "DH") {
      label.textContent = "DTR"
    }

    container.appendChild(input);
    container.appendChild(label);
  });

  return container.outerHTML;
}

function confirmAndDeleteCrewMember(crewMemberId, crewMemberTitle) {

  $('#contact-dropdown').removeClass('is-open')

  if(typeof crewMemberId !== 'string') {
    let crewMember = findCorrespondingContact(clickedMenu.element, croogloocurrentpage.securityPageName 
      === 'crew' ? $(clickedMenu.element).closest('.member-container').attr('id') : null)
    crewMemberId = crewMember.id;
    crewMemberTitle = findCrewMemberTitle(crewMember);
  }

  swal({
    title: i18n.t("js.crew.delete.title", {count: crewMemberId.split(',').length}),
    text: i18n.t("js.crew.delete.text", {crewMemberTitle}),
    type: 'question',
    input: 'text',
    inputClass: 'hidden-input', //used to prevent focus error on reject
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: '#BC2121',
    confirmButtonText: i18n.t("utils.delete"),
    cancelButtonText: i18n.t("button.cancel"),
    allowOutsideClick: () => !swal.isLoading(),
    showLoaderOnConfirm: true,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    preConfirm: function(text) {
      return new Promise(function(resolve, reject) {
        deleteCrewMember(crewMemberId, crewMemberTitle, resolve, reject);
      });
    }
  }).then(function (successMsg) {
    swal({
      title: i18n.t("response.success"),
      text: successMsg,
      type: 'success',
      showCancelButton: false,
      allowOutsideClick: true,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("OK")
    }).catch(swal.noop);
  }).catch(swal.noop);
}

function fetchSecurityLists(done) {
  apicall('securityadminapi', 'fetchSecurityLists', {}).then(function(resp) {
    if(resp && resp.items) {
      securityLists = resp.items;
    } else {
      throw "Invalid server response to fetchSecurityLists";
    }
    done();
  }, rejection => {
    throw rejection;
  }).catch(err => {
    console.error(err);
    swalToast({
      type: 'error',
      title: i18n.t("js.crew.fetch.sec-groups.failed")
    });
    done();
  });
}

function loadDistrGroups() {
	apicall('distributionapi', 'fetchDistributionLists', {}).then(function(resp){
		if(resp && resp.items){
			allDistrGroups = resp.items;
		}
    loader.check('distro-groups');
	});
}

function loadDepartments() {
	apicall('personapi', 'fetchDepartments', {}).then(function(resp){
		if(resp && resp.items){
      allDepartments = resp.items;
      departmentOrderMap = mapEntity(allDepartments, 'displayOrder');
		}
    loader.check('departments');
	});
}

function mapEntity(entityList, value) {
  let keyValueMap = new Object();
  entityList.forEach(entity => {
    if(typeof entity.key.name === 'string' && entity.key.name.trim() !== '') {
      keyValueMap[entity.key.name] = entity.properties[value];
    }
  });
  return keyValueMap;
}

function findUserAccessLevel() {
  userAccessLevel = croogloocurrentpage.getAccessLevel();
  isSidesStandAlone = false; // DEPRECATED
  loader.check('access-level');
}

// TODO REVIEW
function validatePageAccess(pageMenu, resolve, reject) {
  var tmp = new Object();
  tmp.menuPageName = pageMenu;

  apicall('securityadminapi','fetchPageSecurityFromToken',tmp).then(function(resp){
  	if(parseInt(resp.responseCode) > 0) {
      resolve();
    } else {
      reject(i18n.t("js.crew.distribution.denied"));
    }
  }, function() {
    reject(i18n.t("js.utils.server.error-internet"));
  });
}

const SORT_OPTIONS = {
  AS_IS: 0,
  BY_DEPTS: 1,
  BY_DISPLAY_ORDER: 2,
  NUMBER_NAME_AGENTS: 3,
  ALPHANUM: {
    //these values must all be valid keys from contact objects created in the
    //"formatEntityObj" functionlength
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    JOB_TITLE: 'jobTitle',
    EMAIL: 'email'
  }
}
Object.freeze(SORT_OPTIONS);

// The function to actually fetch all of the person entities to populate the contacts list
function fetchPersons() {
    let subcategoriesToFetch = targetListings.slice();
    if (subcategoriesToFetch.includes('agent') && !subcategoriesToFetch.includes('cast')) {
        subcategoriesToFetch.push('cast'); // required for cast number mapping
    }
    let contactSubcategoryString = croogloocurrentpage.securityPageName === 'allContacts' ? '*' : subcategoriesToFetch.join(',');
    Promise.all([
        apicall('personapi', 'fetchContactsBySubcategories', { subcategories: contactSubcategoryString }),
        targetListings.includes('agent') ?
            apicall('personapi', 'fetchContactAssociations', {}) :
            Promise.resolve([])
    ]).then(async (resp) => {
        let contactEntities = resp[0].items;
        let relationshipEntities = resp[1].items;
        if (Array.isArray(contactEntities)) {
            contactEntities = contactEntities.filter(contact =>
                typeof contact.properties.firstName == 'string' &&
                contact.properties.firstName.length > 0);
            sortedCrewMembers = [];
            let contactEntitiesToDisplay = contactEntities.filter(contactEntity => targetListings.includes(contactEntity.properties.subcategory));
            let castMembers = contactEntities.filter(ce => ce.properties.subcategory == 'cast');
            for (let entity of contactEntitiesToDisplay) {
                try {
                    sortedCrewMembers.push(formatEntityObj(entity, targetListings, relationshipEntities, castMembers));
                } catch (e) {
                    if (!e.isExpected) { console.error(e.message); }
                }
            }
            if (sortedCrewMembers.length > 200) {
                hideToast = cgToast(i18n.t("js.crew.ordering"), {
                    hiding: 'manual',
                    className: 'body-attached',
                    containerID: 'docBody'
                });
                // giving toast time to fade in before intense ordering
                await new Promise(res => setTimeout(res, 100));
            }
        } else {
            throw new Error('invalid server response retrieving contacts');
        }
    }).catch(err => {
        console.error('failed to retrieve contacts and/or relationships', err);
    }).then(function () {
        loader.check('persons');
    });
}

function fillContactTable() {
  sortContacts(sortedCrewMembers, getActiveSortOption());
  generateContacts();  
  if(hideToast) { hideToast() }
  loader.check('contact-table');
}

var isSearching = false;
var searchAgain = false;
function searchContacts(searchText, searchInput = null) {

  if(croogloocurrentpage.pageName.toLowerCase() === 'crew') {
    let regex = new RegExp($('.contact-search input').val(), 'i')
    let membersContainer = document.querySelectorAll('.member-container')

    membersContainer.forEach(member => {
      let id = member.getAttribute('id')
      let name = crewMemberIdMap[id].title
      if(!regex.test(name)) {
        member.style.display = 'none'
      } 
      else {
        member.style.display = 'grid'
      }
    })

    let listingsContainer = document.querySelectorAll('.listing-container')
    listingsContainer.forEach(item => {
      let membersToCheck = item.querySelectorAll('.member-container')
      let hide = true;

      membersToCheck.forEach(member => {
        if(member.style.display !== 'none') {
          hide = false 
        }
      })

      if(hide) {
        item.style.display = 'none'
      }
      else {
        item.style.display = 'block'
      }
    })

    
  }
  else {
    lastRowSelected = undefined ;
    if(isSearching) {
      searchAgain = true;
      console.warn('search already running');
      return;
    }
    isSearching = true;
    let indexHTML = 0 ;
    for(let c = 0, crewMember; crewMember = sortedCrewMembers[c++];) {
      let textMatch = false;
      for(let prop in crewMember) {
        try {
          if(typeof crewMember[prop] === 'string' && crewMember[prop].toLowerCase().includes(searchText.toLowerCase())) {
            textMatch = true;
            break;
          }
        } catch(e) {
          console.error(e);
        }
      }
      try {
        let crewRow = document.getElementById('row_'+crewMember.id);
        if(textMatch) {
          crewRow.setAttribute("index", indexHTML) ;
          indexHTML++ ;
          crewRow.classList.remove('hidden-row');
        } else {
          crewRow.setAttribute("index", "") ;
          crewRow.classList.add('hidden-row');
        }
      } catch(e) {
        console.error(e);
      }
    }
    adjustSelectAllChk();
    adjustDeptVisibiliy();
    isSearching = false;
    if(searchAgain) {
      searchAgain = false;
      searchContacts(searchInput.value, searchInput);
    }
  }


}

function adjustDeptVisibiliy() {
  if(getActiveSortOption() === SORT_OPTIONS.BY_DEPTS) {
    [].slice.call(document.querySelectorAll('tr.crew-department')).forEach((dept) => {
      let deptMembers = document.querySelectorAll('tr.crew-row[departmentId="' + dept.getAttribute('department') + '"]');
      let foundVisibleMember = false;
      for(let m = 0, member; member = deptMembers[m++];) {
        if(!member.classList.contains('hidden-row')) {
          foundVisibleMember = true;
          break;
        }
      }
      if(foundVisibleMember) {
        dept.classList.remove('hidden-row');
      } else {
        dept.classList.add('hidden-row');
      }
    });
  }
}

function sortContacts(contactArr, sortOption) {    
  if(!sortOption || sortOption === SORT_OPTIONS.AS_IS) {
    return contactArr;
  } else if(sortOption === SORT_OPTIONS.BY_DEPTS) {
    return contactArr.sort(compareDepartments)
  } else if(sortOption === SORT_OPTIONS.BY_DISPLAY_ORDER) {
    return contactArr.sort(compareDisplayOrder)
  } else if(sortOption === SORT_OPTIONS.NUMBER_NAME_AGENTS) {
    return contactArr.sort(compareNbNameAgent);
  } else if(Object.values(SORT_OPTIONS.ALPHANUM).includes(sortOption)) {
    try {
      return contactArr.sort(function(a,b) {
        return alphanumCompare(a,b,sortOption);
      });
    } catch(e) {
      //this error can occur if a developer creates a sorting key that doesn't
      //match any key from contact objects created in the
      //"formatEntityObj" function
      console.error(e.message);
    }
  }
  console.error('invalid sorting option');
  return contactArr;
}

function alphanumCompare(p1, p2, sortingProp) {
  const numberP1 = p1[sortingProp].replace(/\D/g,'');
  const numberP2 = p2[sortingProp].replace(/\D/g,'');
  if(Number(numberP1) && Number(numberP2)) {
    return Number(numberP1) - Number(numberP2);
  } else
    return p1[sortingProp].localeCompare(p2[sortingProp]);
}

function compareNbNameAgent(p1, p2) {
  let castNbComp = Utility.compareCastNumber(p1.castNumber, p2.castNumber);
  if(castNbComp != 0) { return castNbComp }
  let p1CastNumbers = p1.entityProperties.castNumbers;
  let p2CastNumbers = p2.entityProperties.castNumbers;
  if(Array.isArray(p1CastNumbers) && Array.isArray(p2CastNumbers) && (p1CastNumbers.length || p2CastNumbers.length)) {
    for(let i = 0; i < Math.max(p1CastNumbers.length, p2CastNumbers.length); i++) {
      if(i == p1CastNumbers.length) {
        return -1;
      } else if(i == p2CastNumbers.length) {
        return 1;
      }
      let castNbComp = Utility.compareCastNumber(p1CastNumbers[i], p2CastNumbers[i]);
      if(castNbComp != 0) { return castNbComp }
    }
  }
  return p1.firstName.localeCompare(p2.firstName);
}

function compareDepartments(p1, p2) {
  try {
        //HARDCODED These 5 departments are always at the top of the department list
        if (p1.entityProperties.departmentName == 'PRODUCERS') {
            return -1;
        }
        if (p2.entityProperties.departmentName == 'PRODUCERS') {
            return 1;
        }
        if (p1.entityProperties.departmentName == 'WRITERS') {
            return -1;
        }
        if (p2.entityProperties.departmentName == 'WRITERS') {
            return 1;
        }
        if (p1.entityProperties.departmentName == 'DIRECTORS') {
            return -1;
        }
        if (p2.entityProperties.departmentName == 'DIRECTORS') {
            return 1;
        }
        if (p1.entityProperties.departmentName == 'PRODUCTION') {
            return -1;
        }
        if (p2.entityProperties.departmentName == 'PRODUCTION') {
            return 1;
        }
        if (p1.entityProperties.departmentName == 'ACCOUNTING') {
            return -1;
        }
        if (p2.entityProperties.departmentName == 'ACCOUNTING') {
            return 1;
        }
        // If both entities have same dept name => order by relativeOrder in that dept or alpha num if no relativeOrder property exists
        if (p1.entityProperties.departmentName == p2.entityProperties.departmentName) {
            if (p1.entityProperties.relativeOrder && p2.entityProperties.relativeOrder) {
                return Utility.compare(p1.entityProperties.relativeOrder, p2.entityProperties.relativeOrder);
                // return (p1.entityProperties.relativeOrder.localeCompare(p2.entityProperties.relativeOrder));
            } else if (p1.entityProperties.relativeOrder && !p2.entityProperties.relativeOrder) {
                return -1;
            } else if (!p1.entityProperties.relativeOrder && p2.entityProperties.relativeOrder) {
                return 1;
            } else {
                return p1.entityProperties.firstName.toUpperCase().localeCompare(p2.entityProperties.firstName.toUpperCase());
            }
        }

    //Sorting alphabetically by dept name (starting after accounting)
    return p1.entityProperties.departmentName.localeCompare(p2.entityProperties.departmentName); 

  } catch(e) {
    console.error(e.message);
    return 0;
  }
}

function compareDisplayOrder(p1, p2) {
  try {
    let displayOrderComp = parseInt(p1.entityProperties.displayOrder) - parseInt(p2.entityProperties.displayOrder);

    if(displayOrderComp == 0) {
      return p1[SORT_OPTIONS.ALPHANUM.LAST_NAME].localeCompare(p2[SORT_OPTIONS.ALPHANUM.LAST_NAME]);
    }
    return displayOrderComp;
  } catch(e) {
    console.error(e);
    return 0;
  }
}

function formatEntityObj(entity, acceptedSubcategories, relationshipEntities, castMembers) {
  //converting subcategory from string to array if need be
  acceptedSubcategories = typeof acceptedSubcategories === 'object' ? acceptedSubcategories :[acceptedSubcategories];

  let entityId = entity.key.name
  let entityProps = entity.properties;

  if(!acceptedSubcategories.includes(entityProps.subcategory)) {
    throw {
      isExpected: true,
      message: 'Member filtered out. Unaccepted subcategory.'
    };
  }

  if(entityProps.subcategory == 'agent') {
    try {
      let castPersonIds = relationshipEntities
        .filter(re => re.properties.personSubcategory1 == 'agent' && re.properties.personId1 == entityId)
        .map(re => re.properties.personId2)
        .concat(relationshipEntities
          .filter(re => re.properties.personSubcategory2 == 'agent' && re.properties.personId2 == entityId)
          .map(re => re.properties.personId1));
      // note that we use toString() on the cast numbers for backwards compatibility alone
      let castPersons = castPersonIds.map(id => castMembers.find(ce => ce.key.name == id))
        .filter(p => p != null && typeof p != 'undefined' && ['string', 'number'].includes(typeof p.properties.castNumber) 
          && Utility.isValidCastNumber(p.properties.castNumber.toString()));
      let castNumbers = castPersons.map(cp => cp.properties.castNumber.toString());
      if(castNumbers.length) {
        entityProps.castNumbers = castNumbers;
        entityProps.castNumber = castNumbers.sort(Utility.compareCastNumber)[0];
        entityProps.castPersons = castPersons;
      }
    } catch (err) {
      console.error(err);
      console.error('failed to find matching cast members');
    }
  }

  if(typeof entityProps.castNumber == 'number') {
    entityProps.castNumber = entityProps.castNumber.toString();
  }

  return {
    firstName : entityProps.firstName || '',
    lastName : entityProps.lastName || '',
    title : entityProps.title || '',
    castNumber : (typeof entityProps.castNumber !== 'string' || !Utility.isValidCastNumber(entityProps.castNumber))
      ?'' :entityProps.castNumber,
    mobile : entityProps.mobile || '',
    office : entityProps.officePhone || '',
    home : entityProps.homePhone || '',
    email :  concatenateEmails(entityProps),
    jobTitle : entityProps.jobTitle || '',
    address : entityProps.address1 || entityProps.address2 || '',
    id : entityId,
    distributionLists: entityProps.distributionLists || '',
    department: entityProps.departmentName || '',
    subcategory: entityProps.subcategory || '',
    entityProperties: entityProps || {}
  };
}

function concatenateEmails(entityProps) {
  return ((entityProps.email || '') + ((typeof entityProps.email2 === 'string'
    && entityProps.email2.trim() !== '') ?(','+entityProps.email2) :'')
    + ((typeof entityProps.email3 === 'string' && entityProps.email3.trim() !== '')
      ?(','+entityProps.email3) :'')).replace(/^,+/, '').replace(/,(?!\s)/g, ', ');
}

function generateContacts(remodel = false, startRow = null, deleteRow) {

  if(croogloocurrentpage.securityPageName === 'crew') {
    
    $('#contact-table').css('display', 'none')

    let departmentsWithMembers = {}
    
    let allListingsContainer = document.createElement('div')
    allListingsContainer.classList.add('all-listings-container')
    
    // data header code

    let checkBoxContainer = document.createElement('div')
    checkBoxContainer.classList.add('checkbox-container-crew')
    checkBoxContainer.setAttribute('id', 'selectAllContainer')

    let checkbox = document.createElement('input')
    checkbox.setAttribute('type', 'checkbox')
    checkbox.setAttribute('id', 'selectAllChk')

    checkBoxContainer.appendChild(checkbox)

    checkBoxContainer.style.display = userAccessLevel > 1 ? 'flex':'none';
    checkbox.addEventListener('click', () => {

      if(checkbox.checked) {
        document.querySelectorAll('.checkbox-container-crew input').forEach(memberContainerCheckbox => {
          if(!memberContainerCheckbox.checked) {
            crewRowClick(null, memberContainerCheckbox, false, true);
          }
        })
      }
      else {
        document.querySelectorAll('.checkbox-container-crew input').forEach(memberContainerCheckbox => {
          if(memberContainerCheckbox.checked) {
            crewRowClick(null, memberContainerCheckbox, false, true);
          }
        })
      }

    })

    let dataHeaderContainer = document.createElement('div')
    dataHeaderContainer.classList.add('data-header-container')

    let nameContainer = document.createElement('div')
    nameContainer.classList.add('property-container-crew')

    let name = document.createElement('p')
    name.innerHTML = "NAME"
    name.classList.add('data-header-text')

    nameContainer.appendChild(name)

    let emailContainer = document.createElement('div')
    emailContainer.classList.add('property-container-crew')

    let email = document.createElement('p')
    email.innerText = "EMAIL"
    email.classList.add('data-header-text')

    emailContainer.appendChild(email)

    let phoneContainer = document.createElement('div')
    phoneContainer.classList.add('property-container-crew')

    let phone = document.createElement('p')
    phone.innerText = "PHONE"
    phone.classList.add('data-header-text')

    phoneContainer.appendChild(phone)

    let listsContainer = document.createElement('div')
    listsContainer.classList.add('property-container-crew')

    let distLists = document.createElement('p')
    distLists.innerText = "LISTS"
    distLists.classList.add('data-header-text')

    listsContainer.appendChild(distLists)

    dataHeaderContainer.appendChild(checkBoxContainer)
    dataHeaderContainer.appendChild(nameContainer)
    dataHeaderContainer.appendChild(emailContainer)
    dataHeaderContainer.appendChild(phoneContainer)
    dataHeaderContainer.appendChild(listsContainer)

    // end of data header code

    $('.crew-listing-container').append(dataHeaderContainer)
    $('.crew-listing-container').append(allListingsContainer)

    allDepartments.forEach((department) => {
      let deptId = department.properties.id
      let filteredMembersByDepartment = sortedCrewMembers.filter(member => (member.department === department.properties.departmentName)) 

        if (filteredMembersByDepartment.length > 0) {
            departmentsWithMembers[deptId] = { members: filteredMembersByDepartment, dept: department }
        }
    })

    let valuesToSort = Object.values(departmentsWithMembers)
        .sort((a, b) => {
            if (a.dept.properties.departmentName == 'PRODUCERS') {
                return -1;
            }
            if (b.dept.properties.departmentName == 'PRODUCERS') {
                return 1;
            }
            if (a.dept.properties.departmentName == 'WRITERS') {
                return -1;
            }
            if (b.dept.properties.departmentName == 'WRITERS') {
                return 1;
            }
            if (a.dept.properties.departmentName == 'DIRECTORS') {
                return -1;
            }
            if (b.dept.properties.departmentName == 'DIRECTORS') {
                return 1;
            }
            if (a.dept.properties.departmentName == 'PRODUCTION') {
                return -1;
            }
            if (b.dept.properties.departmentName == 'PRODUCTION') {
                return 1;
            }
            if (a.dept.properties.departmentName == 'ACCOUNTING') {
                return -1;
            }
            if (b.dept.properties.departmentName == 'ACCOUNTING') {
                return 1;
            }
                        if(a.dept.properties.relativeOrder && b.dept.properties.relativeOrder) {
                          if(a.dept.properties.relativeOrder !== "" && b.dept.properties.relativeOrder !== "") {
                            return parseInt(a.dept.properties.relativeOrder) - parseInt(b.dept.properties.relativeOrder)
                          }
                        }
                        else if(a.dept.properties.displayOrder && b.dept.properties.displayOrder) {
                          return parseInt(a.dept.properties.displayOrder) - parseInt(b.dept.properties.displayOrder)
                        }
                        else {
                          return a.dept.properties.departmentName.toLowerCase() - b.dept.properties.departmentName.toLowerCase()
                        }
                       })

    departmentsWithMembers = {}
    valuesToSort.forEach(item => {
      let deptId = item.dept.properties.id
      departmentsWithMembers[deptId] = item
    })

    for (const [deptId, membersAndDepartment] of Object.entries(departmentsWithMembers)) {

      let memberDepartmentContainer = document.createElement('div')
      memberDepartmentContainer.classList.add('listing-container')

      let reorderBtn = document.createElement("div");
      let reorderBtnText = document.createElement("p")
      let reorderBtnLogo = document.createElement("img")
      reorderBtn.classList.add('reorder-btn-crew')

      reorderBtnLogo.src = "../assets/img/blue-arrow-crew.svg"

      reorderBtn.appendChild(reorderBtnLogo)
      reorderBtn.appendChild(reorderBtnText)
      
      // let saveBtn = document.createElement("input");
      let saveBtn = document.createElement("div");
      let saveBtnText = document.createElement("p")
      let saveBtnLogo = document.createElement("img")
      saveBtnLogo.src = "../assets/img/blue-check-crew.svg"
      saveBtn.classList.add('save-btn-crew')

      saveBtn.appendChild(saveBtnLogo)
      saveBtn.appendChild(saveBtnText)
 

      saveBtnText.innerText = "SAVE CHANGES"
      // saveBtn.setAttribute("class", "save-btn");
      saveBtnText.setAttribute("class", "save-btn-text")
                
      reorderBtnText.innerText = "ORDER MEMBERS"
      // reorderBtn.setAttribute("class", "reorder-btn");
      reorderBtnText.setAttribute("class", "reorder-btn-text")

      memberDepartmentContainer.appendChild(reorderBtn)
      memberDepartmentContainer.appendChild(saveBtn)

      saveBtn.style.display = "none";


      // department header code

      let departmentContainer = document.createElement("div");
      let deptNameContainer = document.createElement("p");
      
      deptNameContainer.classList.add("department-name")
      departmentContainer.classList.add("department-container")
      
      departmentContainer.setAttribute('id', `deptRow_${deptId}`)
      departmentContainer.setAttribute('department', deptId);   
      deptNameContainer.textContent = membersAndDepartment.dept.properties.departmentName 
      
      departmentContainer.appendChild(deptNameContainer)
      memberDepartmentContainer.appendChild(departmentContainer)  
      
      // end of department header code

      
      // member container code

      let reorderablesContainer = document.createElement('ul')
      reorderablesContainer.classList.add('reorderables-container')
      
      membersAndDepartment.members.forEach(member => {
        crewMemberIdMap[member.id] = member;

        let draggableContainer = document.createElement('li')
        draggableContainer.classList.add('draggable-container')

        let memberContainer = document.createElement('div')
        memberContainer.classList.add('member-container')
        memberContainer.setAttribute('id', member.id)
  
        let checkBoxContainer = document.createElement('div')
        checkBoxContainer.classList.add('checkbox-container-crew')

        let draggableHandle = document.createElement('img')
        draggableHandle.src = '../assets/img/crew-reorderable.svg'
        draggableHandle.style.display = 'none'

        checkBoxContainer.appendChild(draggableHandle)

        let checkbox = document.createElement('input')
        checkbox.setAttribute('type', 'checkbox')

        checkBoxContainer.appendChild(checkbox)
  
        let nameContainer = document.createElement('div')
        nameContainer.classList.add('property-container-crew')

        let name = document.createElement('span')
        name.textContent = `${member.firstName} ${member.lastName}`
        name.classList.add('crew-member-text', 'bold')

        let hyphen = document.createElement('span')
        hyphen.style.margin = '0 5px'
        hyphen.textContent = ' - '
        hyphen.classList.add('crew-member-text', 'bold')

        let job = document.createElement('span')
        job.textContent = `${member.jobTitle}`
        job.classList.add('crew-member-text')

        nameContainer.appendChild(name)
        nameContainer.appendChild(hyphen)
        nameContainer.appendChild(job)
  
        let emailContainer = document.createElement('div')
        emailContainer.classList.add('property-container-crew')

        let email = document.createElement('p')
        email.innerText = member.email
        email.classList.add('crew-member-text')

        emailContainer.appendChild(email)
  
        let phoneContainer = document.createElement('div')
        phoneContainer.classList.add('property-container-crew')

        let phone = document.createElement('p')
        phone.innerText = member.mobile !== "" ? `m: ${member.mobile}` : ""
        phone.classList.add('crew-member-text')
  
        phoneContainer.appendChild(phone)

        let listsContainer = document.createElement('div')
        listsContainer.classList.add('property-container-crew')

        let distLists = document.createElement('p')
        // Members with many distro lists can present it as an object where it's created 
        // somewhere upstream when the distro lists are being fetched per member.
        distLists.innerText = member.distributionLists instanceof Object ?
          member.distributionLists.value :
          member.distributionLists;
        distLists.classList.add('crew-member-text', 'dist-list-text')

        listsContainer.appendChild(distLists)
  
        let dotsContainer = document.createElement('div')
        dotsContainer.classList.add('dot-menu-container')

        let dotsImage = document.createElement('img')
        dotsImage.src = '../assets/img/threedots.svg'
        dotsImage.classList.add('icon-more')

        dotsContainer.appendChild(dotsImage)
        
        dotsImage.addEventListener('click', () => {

        })
        allowDblClickEditing(crewMemberIdMap[member.id], [name, email, phone]);

  
        memberContainer.appendChild(checkBoxContainer)
        memberContainer.appendChild(nameContainer)
        memberContainer.appendChild(emailContainer)
        memberContainer.appendChild(phoneContainer)
        memberContainer.appendChild(listsContainer)
        memberContainer.appendChild(dotsContainer)

        draggableContainer.appendChild(memberContainer)

        reorderablesContainer.appendChild(draggableContainer)

      })
      reorderBtn.addEventListener('click', () => {
        reorderBtn.style.display = 'none'
        saveBtn.style.display = 'flex'

        memberDepartmentContainer.querySelector('.reorderables-container').setAttribute('class', 'reorderables-container-reordering draggable-list')


        memberDepartmentContainer.querySelectorAll('.member-container .checkbox-container-crew input').forEach((checkbox, index) => {
          
          let memberContainer = checkbox.parentElement.parentElement
          let draggableContainer = memberContainer.parentElement
          let draggableHandle = checkbox.previousElementSibling

          memberContainer.setAttribute('class', 'member-container-reordering')
          checkbox.style.display = 'none'
          draggableHandle.style.display = 'block'
          memberContainer.classList.add('reorderable-item', 'draggable')
          memberContainer.setAttribute('draggable', true)
          memberContainer.parentElement.setAttribute('data-index', index)
          reorderables.push(memberContainer)
          reorderablesHTML.push(draggableContainer)
          addDraggableListener()            

        })
        
        const unique = (value, index, self) => {
          return self.indexOf(value) === index
        }

        reorderables = reorderables.filter(unique)
        reorderablesHTML = reorderablesHTML.filter(unique)


      })
      
      saveBtn.addEventListener('click', () => {
        saveBtn.style.display = 'none'
        reorderBtn.style.display = 'flex'
        
        memberDepartmentContainer.querySelector('.reorderables-container-reordering').setAttribute('class', 'reorderables-container')
        reorderables.forEach(item => {
          item.setAttribute('class', 'member-container')
          item.setAttribute('draggable', false)
          $(item).find('.checkbox-container-crew img').css('display', 'none')
          $(item).find('.checkbox-container-crew input').css('display', 'block')
        })

        let personOrdering = {};

        let i = 1;
        for (let person of reorderables) {
            personOrdering[person.getAttribute('id')] = i++;
        }

        apicall('personapi', 'updateCrewRelativeOrder', {}, { props: personOrdering }).then(resp => {
            if (resp.responseCode == 0) {
              console.debug("Crew order updated successfully");
            } 
            else {
              console.debug("Crew order NOT updated; something went wrong with saveOrderedCrew API");
            }

            reorderablesHTML = []
            reorderables = []
        });
      })

      memberDepartmentContainer.appendChild(reorderablesContainer)
      // end of member container code
      // add member code

      let memberAddContainer = document.createElement('div')
      memberAddContainer.classList.add("crew-add-member")
      
      memberAddContainer.style.paddingLeft = '21px'  

      let addMemberContainer = document.createElement('div')
      let addMemberText = document.createElement('p')
      let addMemberIcon = document.createElement('img')
      
      addMemberText.style.marginBottom = '0'
      addMemberText.style.color = '#2BAAE1'
      addMemberText.innerText = 'ADD CREW MEMBER'
      addMemberText.style.fontSize = '14px'
      addMemberText.style.fontWeight = '700'
    
      addMemberIcon.src = '../assets/img/add-crew-icon.svg'
    
      addMemberContainer.style.display = 'flex'
      addMemberContainer.style.alignItems = 'center'
      addMemberContainer.style.justifyContent = 'flex-start'
      addMemberContainer.style.columnGap = '10px'
      addMemberContainer.style.cursor = 'pointer'

      $(addMemberContainer).on('click', () => {
        r.loadPage('contact', {
          subcategory: 'crew',
          isSidesStandAlone: isSidesStandAlone,
          reloadOnClose: false
        });
      });
    
      addMemberContainer.appendChild(addMemberIcon)
      addMemberContainer.appendChild(addMemberText)
      
      memberAddContainer.appendChild(addMemberContainer)

      memberDepartmentContainer.appendChild(memberAddContainer)
      
      // end of add member code

        

      allListingsContainer.appendChild(memberDepartmentContainer)
    }
  }
  else {

    let crew;
    let table = document.getElementById("contact-table");
  
    if(remodel){    
      crew = reorderables;
  
      //Resseting all tracker arrays and variable in
      //preparation for next, potential, re-ordering cycle
      reorderablesHTML = [];
      reorderables = [];  
  
      table.deleteRow(deleteRow);    
      table.classList.add("hover");
    }else{
      crew = sortedCrewMembers;
    }
  
    for (let c = 0, crewMember; crewMember = crew[c++];) {
      crewMemberIdMap[crewMember.id] = crewMember;
  
      let row = copyBlueprint('crewRowBlueprint', true, startRow);
      row.id = 'row_' + crewMember.id; 
  
      if(remodel){
        startRow = row.id;
      }
  
      row.setAttribute('personId', crewMember.id);
      row.setAttribute('department', crewMember.entityProperties.departmentName);
      row.setAttribute('departmentId', crewMember.entityProperties.departmentId);
      row.setAttribute('index', c - 1);
  
      //For now we will not update this array, we dont use it in this class anyways
      //UPDATE AT THE END DANIEL
  
      if(!remodel){
        crewIndexedList.push(row);
      }    
  
      let castNumberSpan = $(row).find('span.contact-castNumber')[0];
      if(crewMember.subcategory == 'cast' && crewMember.castNumber != '') {
        castNumberSpan.innerHTML = '#' + crewMember.castNumber + '&nbsp;';
      } else if(crewMember.subcategory == 'agent' && Array.isArray(crewMember.entityProperties.castPersons) 
          && crewMember.entityProperties.castPersons.length) {
        let sortedPersons = crewMember.entityProperties.castPersons
          .sort((a, b) => parseInt(a.properties.castNumber) - parseInt(b.properties.castNumber));
        // console.debug('sortedPersons', sortedPersons);
        for(let i = 0; i < sortedPersons.length; i++) {
          let castPerson = sortedPersons[i];
          if(!castPerson) {
            console.error('invalid cast person detected');
            continue;
          }
          let innerCastNumberSpan = document.createElement('span');
          innerCastNumberSpan.innerHTML = (i == 0 ?'#' :'') + castPerson.properties.castNumber; 
          innerCastNumberSpan.className = 'agent-cast-number-link';
          castNumberSpan.appendChild(innerCastNumberSpan);
          innerCastNumberSpan.onclick = function() {
            global.r.saveExtraParams('contact', {
              personId: castPerson.key.name || parseInt(castPerson.key.id),
              personEntity: castPerson.properties,
              isSidesStandAlone: false, // DEPRECATED 
              reloadOnClose: false
            });
            r.newTab(window.location.href.split('#')[0] + '#contact');
          };
          castNumberSpan.appendChild(document.createTextNode(i + 1 < sortedPersons.length ?', ': ' '));
        }
      }
  
      // Only add the intercom target for the first 20 rows on the page, no more are needed
      if (c < 20) {
        let dotMenu = $(row).find("div.dot-menu-container a")[0];
        dotMenu.setAttribute("data-intercom-target", "Show More_" + c);
  
        let nameDiv = $(row).find("td.contact-main-info")[0];
        nameDiv.setAttribute('data-intercom-target', 'Name_' + c);
      }
  
      let firstNameSpan = $(row).find('span.contact-firstName')[0];
      firstNameSpan.textContent = crewMember.firstName;
      firstNameSpan.setAttribute('editableProp', 'firstName');
  
      let lastNameSpan = $(row).find('span.contact-lastName')[0];
      lastNameSpan.textContent = crewMember.lastName;
      lastNameSpan.setAttribute('editableProp', 'lastName');
  
      let jobDiv = $(row).find('span.contact-job')[0];
      jobDiv.textContent = crewMember.jobTitle;
      jobDiv.style.fontWeight = '400'
      jobDiv.setAttribute('editableProp', 'jobTitle');
  
      //dbl click editing not yet supported for this element
      let distrListDiv = $(row).find('div.contact-list')[0];
      distrListDiv.textContent = crewMember.distributionLists;
      if(!crewMember.distributionLists) {
        distrListDiv.style.cursor = 'pointer';
      }
  
      let emailDiv = $(row).find('div.contact-email')[0];
      emailDiv.textContent = crewMember.email;
      emailDiv.setAttribute('editableProp', 'concatenatedEmails');
  
      let checkboxDiv = $(row).find('div.checkbox-container')[0];
      let checkboxTd = $(row).find('td.td-checkbox')[0];
  
      checkboxTd.style.padding = '0.625rem'
      checkboxTd.style.paddingTop = '25px'
      checkboxTd.style.paddingLeft = '12px'
  
  
      checkboxDiv.style.height = '100%';
      checkboxDiv.style.display = 'flex'
      checkboxDiv.style.alignItems = 'center'
      checkboxDiv.style.justifyContent = 'flex-start'
      
      let mobileDiv = $(row).find('div.contact-mobile')[0];
      if(crewMember.mobile) {
        mobileDiv.textContent = crewMember.mobile;
        mobileDiv.setAttribute('editableProp', 'mobile');
        $(findEnclosingElement(mobileDiv,'DIV',true)).show();
      }
  
      let officeDiv = $(row).find('div.contact-office')[0];
      if(crewMember.office) {
        officeDiv.textContent = crewMember.office;
        officeDiv.setAttribute('editableProp', 'office');
        $(findEnclosingElement(officeDiv,'DIV',true)).show();
      }
  
      let homeDiv = $(row).find('div.contact-home')[0];
      if(crewMember.home) {
        homeDiv.textContent = crewMember.home;
        homeDiv.setAttribute('editableProp', 'home');
        $(findEnclosingElement(homeDiv,'DIV',true)).show();
      }
  
      allowDblClickEditing(crewMember, [firstNameSpan, lastNameSpan, jobDiv,
        emailDiv, mobileDiv, officeDiv, homeDiv]);
    }
  
    if(!remodel){
      toggleDepartmentHeaders();
    }  
  }
  
}

function dragStart(){
  dragStartIndex = +this.closest('li').getAttribute('data-index'); 
}

function dragEnter(){ 
  //use this for css to add shadow backdrop
}

function dragLeave(){  
  //use this to remove css overlay of dragEnter
}

function dragOver(e){
  e.preventDefault();
}

function dragDrop(){
  let dragEndIndex = +this.closest('li').getAttribute("data-index");
  swapItems(dragStartIndex, dragEndIndex);  
} 

function swapItems(startIndex, endIndex) {
  if($('.order-department-modal').is(':visible')) {
    let tempOne = reorderablesDepartmentsHTML[startIndex].querySelector('.draggable.department-order-container')
    let tempTwo = reorderablesDepartmentsHTML[endIndex].querySelector('.draggable.department-order-container')

    //Swapping elements in the DOM
    reorderablesDepartmentsHTML[startIndex].appendChild(tempTwo);
    reorderablesDepartmentsHTML[endIndex].appendChild(tempOne);

    //Swapping elements in the mirrored array so they are sorted properly
    //on the fronend
    let tempArrayMember = reorderablesDepartments[startIndex];
    reorderablesDepartments[startIndex] = reorderablesDepartments[endIndex];
    reorderablesDepartments[endIndex] = tempArrayMember;
  }
  else {

    let tempOne = reorderablesHTML[startIndex].querySelector('.member-container-reordering.draggable')
      let tempTwo = reorderablesHTML[endIndex].querySelector('.member-container-reordering.draggable')

    //Swapping elements in the DOM
    reorderablesHTML[startIndex].appendChild(tempTwo);
    reorderablesHTML[endIndex].appendChild(tempOne);

    //Swapping elements in the mirrored array so they are sorted properly
    //on the fronend
    let tempArrayMember = reorderables[startIndex];
    reorderables[startIndex] = reorderables[endIndex];
      reorderables[endIndex] = tempArrayMember;
  }
}

function addDraggableListener(){

  let draggables = null
  let draggablesItems = null

  if($('.order-department-modal').is(':visible')) {
    draggables = document.querySelectorAll(".draggable.department-order-container");
    draggablesItems = document.querySelectorAll(".draggable-list.ul-order-departments li");
    draggablesItems = [...draggablesItems].filter(item => {
      let textItem = $(item).find('p')[0]
      let text = $(textItem).text()
      if(text !== 'PRODUCERS' && text !== 'ACCOUNTING' && text !== 'WRITERS' && text !== 'DIRECTORS' && text !== 'PRODUCTION') {
        return item
      }
    })
  }
  else {
    draggables = document.querySelectorAll(".draggable.member-container-reordering");
    draggablesItems = document.querySelectorAll(".draggable.member-container-reordering")
  }


  draggables.forEach(row => {
    row.addEventListener("dragstart", dragStart);
  })

  draggablesItems.forEach(item =>{
    item.addEventListener('dragover', dragOver);
    item.addEventListener('drop', dragDrop);
    item.addEventListener('dragenter', dragEnter);
    item.addEventListener('dragleave', dragLeave);
  })
}

function orderContactRows() {
  let rowContainer = document.getElementById('crewRowBlueprint').parentElement;
  for(let c = 0, crewMember; crewMember = sortedCrewMembers[c++];) {
    rowContainer.appendChild(document.getElementById('row_' + crewMember.id));
  }
  toggleDepartmentHeaders();
}

function toggleDepartmentHeaders() {
  
  if (typeof sortedCrewMembers!= "undefined" && sortedCrewMembers.length===0){
    switch(croogloocurrentpage.pageName){
      case "cast":
        castOverlayOn();
        break;
      case "agent":
        agentOverlayOn();
        break;
      case "studio":
        studioOverlayOn();
        break;
      case "union":
        unionOverlayOn();
        break;
      case "vendor":
        vendorOverlayOn();
        break;
    }
  }
  
  if(getActiveSortOption() !== SORT_OPTIONS.BY_DEPTS) {
    $('tr.crew-department').hide();
    return;
  }
  let currentDepartment = null;
  let isFirst = true;
  
  for(let c = 0, crewMember; crewMember = sortedCrewMembers[c++];) {
    try {
      if(currentDepartment !== crewMember.entityProperties.departmentId) {
        currentDepartment = crewMember.entityProperties.departmentId;


        let deptRow = document.getElementById('deptRow_' + currentDepartment);
        // $(deptRow).find('th')[0].style.display = 'flex'
        let reorderBtn = document.createElement("div");
        let reorderBtnText = document.createElement("p")
        let reorderBtnLogo = document.createElement("img")

        reorderBtnLogo.src = "../assets/img/blue-arrow-crew.svg"

        reorderBtn.appendChild(reorderBtnLogo)
        reorderBtn.appendChild(reorderBtnText)
        
        // let saveBtn = document.createElement("input");
        let saveBtn = document.createElement("div");
        let saveBtnText = document.createElement("p")
        let saveBtnLogo = document.createElement("img")
        saveBtnLogo.src = "../assets/img/blue-check-crew.svg"

        saveBtn.appendChild(saveBtnLogo)
        saveBtn.appendChild(saveBtnText)

        saveBtn.style.display = "none"; 

        let userRow = document.getElementById('row_' + crewMember.id);
        let addMember = copyBlueprint('crewDeptRowBP')
        
        if(!deptRow) {     
          
          addMember.setAttribute('class', 'crew-add-member')
          let addMemberContainer = document.createElement('div')
          let addMemberText = document.createElement('p')
          let addMemberIcon = document.createElement('img')
          
          addMemberText.style.marginBottom = '0'
          addMemberText.style.color = '#2BAAE1'
          addMemberText.innerText = 'ADD CREW MEMBER'
          addMemberText.style.fontSize = '14px'
          addMemberText.style.fontWeight = '700'
        
          addMemberIcon.src = '../assets/img/add-crew-icon.svg'
        
          addMemberContainer.style.display = 'flex'
          addMemberContainer.style.alignItems = 'center'
          addMemberContainer.style.justifyContent = 'flex-start'
          addMemberContainer.style.columnGap = '10px'
          addMemberContainer.style.cursor = 'pointer'

          $(addMemberContainer).on('click', () => {
            r.loadPage('contact', {
              subcategory: 'crew',
              isSidesStandAlone: isSidesStandAlone,
              reloadOnClose: false
            });
          });
        
          addMemberContainer.appendChild(addMemberIcon)
          addMemberContainer.appendChild(addMemberText)

          $(addMember).find('th')[0].append(addMemberContainer)
          let containerDiv = document.createElement("div");
          let dpetNameContainer = document.createElement("div");
          let departmentNameContainer = document.createElement('div')

          dpetNameContainer.setAttribute("class", "department-name")
          containerDiv.setAttribute("class", "reorderMemberBtnContainer")

          let departmentName = crewMember.entityProperties.departmentName;

          deptRow = copyBlueprint('crewDeptRowBP');
          deptRow.id = 'deptRow_' + currentDepartment;
          deptRow.setAttribute('department', currentDepartment);   
          dpetNameContainer.textContent = departmentName.toUpperCase();  
          $(deptRow).find('th')[0].appendChild(departmentNameContainer);            
          departmentNameContainer.setAttribute("class", "department-name-container")
          departmentNameContainer.appendChild(dpetNameContainer)
          departmentNameContainer.appendChild(containerDiv)

          saveBtn.setAttribute("id", "saveBtn_" + departmentName);
          saveBtnText.innerText = "SAVE CHANGES"
          saveBtn.setAttribute("class", "save-btn");
          saveBtnText.setAttribute("class", "save-btn-text")
          
          
          reorderBtn.setAttribute("id", "reoderBtn_" + departmentName);
          reorderBtnText.innerText = "ORDER MEMBERS"
          reorderBtn.setAttribute("class", "reorder-btn");
          reorderBtnText.setAttribute("class", "reorder-btn-text")

          let userRow = document.getElementById('row_' + crewMember.id);   
          //Since we get row index before appending the department header row
          //increment by 1 the index of first userRow for that depratment
          let inserIndex = userRow.rowIndex + 1;
          if(!deptInsertIndex.get(departmentName)){
              deptInsertIndex.set(departmentName, inserIndex);
            }          

          containerDiv.appendChild(reorderBtn);          
          containerDiv.appendChild(saveBtn);
        }      
        $(deptRow).insertBefore(userRow);
        if(c > 1) {
          $(addMember).insertBefore(deptRow)
        }
        else if(c == 1) {
          $(addMember).insertAfter($('#contact-table tr:last'))
        }
      }
    } catch(e) {
      console.error(e);
    }
  }
  
  $('tr.crew-department').show();
}

function allowDblClickEditing(crewMember, htmlElemArr) {
  for(let d = 0, elem; elem = htmlElemArr[d++];) {
    elem.ondblclick = () => {
      try {
        editText(crewMember, elem);
      } catch(e) {
        console.error(e.message);
      }
    };
  }
}

function editText(crewMember, elemToChange) {

  if(!elemToChange.hasAttribute('editableProp')) { return; }

  if(userAccessLevel < 2) {
    console.warn('read-only access');
    return;
  }

	let textAsInput = document.createElement('input');
  textAsInput.value = elemToChange.textContent;
	textAsInput.style.width = elemToChange.getBoundingClientRect().width+10+'px';

	let initialSpanDisplay = elemToChange.style.display;
	elemToChange.style.display = 'none';

	$(textAsInput).insertBefore(elemToChange);
	textAsInput.select();

	textAsInput.onblur = function() {

		if(textAsInput.value === '') {
			cgToast(i18n.t("js.crew.text.reject"), 3000);
			$(textAsInput).remove();
			elemToChange.style.display = initialSpanDisplay;
			return;
		}

		let personInfo = {
				id: crewMember.id,
				jobTitle: '~',
				firstName: '~',
				lastName: '~',
				concatenatedEmails: '~',
				homeNb: '~',
				mobileNb: '~',
				officeNb: '~'
		}

    let changedAttribute = elemToChange.getAttribute('editableProp');
    switch(changedAttribute) {
      case 'firstName': personInfo.firstName = textAsInput.value; break;
      case 'lastName': personInfo.lastName = textAsInput.value; break;
      case 'jobTitle': personInfo.jobTitle = textAsInput.value; break;
      case 'concatenatedEmails':
        try {
          validateEmailInput(textAsInput);
        } catch(e) {
          if(e.type && e.type === 'custom-error') {
            cgToast(e.message, { className: 'error-toast' });
          }
          restoreTextField();
          return;
        }
        personInfo.concatenatedEmails = textAsInput.value;
        break;
      case 'mobile': personInfo.mobileNb = textAsInput.value; break;
      case 'office': personInfo.officeNb = textAsInput.value; break;
      case 'home': personInfo.homeNb = textAsInput.value; break;
    }

    console.debug(personInfo);
    apicall('personapi', 'editPerson', personInfo).then(function(resp) {

      if(resp && resp.responseMessage === 'success') {
        elemToChange.textContent = textAsInput.value;
        crewMemberIdMap[crewMember.id][changedAttribute == 'concatenatedEmails'
          ?'email' :changedAttribute] = textAsInput.value;

      } else if(resp && resp.responseMessage) {
        cgToast(resp.responseMessage, {
          className: 'error-toast',
          duration: 5000
        });
      }

      restoreTextField();
    });
  }

  function restoreTextField() {
    $(textAsInput).remove();
    elemToChange.style.display = initialSpanDisplay;
  }

  $(textAsInput).keypress(function(event){
    if(event.keyCode == 13){
      event.preventDefault();
      event.stopImmediatePropagation();
      $(textAsInput).blur();
    }
  });
}

function validateEmailInput(emailInput) {
  let validEmailArr = new Array();
  let emailArr = emailInput.value.split(',');
  emailArr.forEach(email => {
    email = email.trim();
    if(email !== '') {
      if(!Utility.isValidEmailAddress(email)) {
        throw {
          type: 'custom-error',
          message: i18n.t("js.crew.email.reject.invalid", {email})
        }
      }
      validEmailArr.push(email);
    }
  });
  if(validEmailArr.length === 0) {
    throw {
      type: 'custom-error',
      message: i18n.t("js.crew.email.reject.empty")
    }
  }
  emailInput.value = validEmailArr.join(', ');
}

/**
 * Moves the contact dropdown element to the same level as the contact table. 
 * This will prevent it from being deleted alongside any table row it may have 
 * been appended to.
 */
function moveUpContactDropdownNode() {
  let contactDropdown = document.getElementById('contact-dropdown');
  document.getElementById('contact-table').parentElement.appendChild(contactDropdown);
}

function deleteCrewMember(crewMemberId, crewMemberTitle, resolve, reject) {
  apicall('personapi', 'removeContacts', {}, {
    value: crewMemberId
  }).then(function(resp) {
    if(resp.responseCode === '0') {
      try {
        removeAllLocalContactData(crewMemberId);
        resolve(i18n.t("js.crew.delete.crew", {count: crewMemberId.split(',').length, crewMemberTitle: crewMemberTitle}))
      } catch(e) {
        console.error(e);
        resolve(i18n.t("js.crew.deletion"));
      }
    } else {
      throw new Error('invalid server response');
    }
  }).catch(() => {
    reject(i18n.t("js.utils.server.error-internet"));
  });
}

function removeAllLocalContactData(crewMemberIds) {
  moveUpContactDropdownNode();
  for(let i = 0, memberId; memberId = crewMemberIds.split(',')[i++];) {
    if(!memberId) { continue }
    delete crewMemberIdMap[memberId];
    for(let c = 0; c < sortedCrewMembers.length; c++) {
      if(sortedCrewMembers[c].id === memberId) {
        sortedCrewMembers.splice(c, 1);
        break;
      }
    }
    sidePanel.removeSelectedItem(memberId);
    updateDepartmentRowElements(memberId, true);
    $('#row_' + memberId).remove();
  }
  if($('#contact-dropdown').hasClass('is-open')) {
    $(clickedMenu.element).click();
  }
  clickedMenu.element = null;
  adjustSelectAllChk();
}

function updateDepartmentRowElements(memberId, isMemberAboutToBeRemoved) {
  if(croogloocurrentpage.securityPageName !== 'crew') {
    console.debug('ignoring department row update for non-crew contact page');
    return;
  }
  let departmentId = $('#row_' + memberId).attr('departmentId');
  let rowsLeftInDept = document.querySelectorAll('tr.crew-row[departmentId="'
   + departmentId + '"]');
  if(rowsLeftInDept.length <= (isMemberAboutToBeRemoved ?1 :0)) {
    $('tr.crew-department[department="' + departmentId + '"]').remove();
  } else if(document.querySelector('tr.crew-department[department="' + departmentId + '"]') === null) {
    let crewMember = crewMemberIdMap[memberId];
    if(crewMember) {
      let deptRow = copyBlueprint('crewDeptRowBP');
      deptRow.id = 'deptRow_' + departmentId;
      deptRow.setAttribute('department', departmentId);
      $(deptRow).find('th')[0].textContent =
        crewMember.entityProperties.departmentName.toUpperCase();           
      $(deptRow).insertBefore(document.getElementById('row_' + crewMember.id));
    }
  }
}

function studioOverlayOn(){
  document.getElementById("emptyStudioOverlay").style.display = "block";
}

function castOverlayOn(){
  document.getElementById("emptyCastOverlay").style.display = "block";
}

function agentOverlayOn(){
  document.getElementById("emptyAgentOverlay").style.display = "block";
}

function vendorOverlayOn(){
  document.getElementById("emptyVendorOverlay").style.display = "block";
}

function unionOverlayOn(){
  document.getElementById("emptyUnionOverlay").style.display = "block";
}
