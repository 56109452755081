import i18n from './Translation';
import CGGoogleAuth from './CGGoogleAuth';

/**
 * Class to help with importing Google contacts from Google (Gmail) and getting them into Croogloo as contacts.
 */
export default class CGGoogleContacts {

  /**
   * Initialize the Google Contact import flow that asks the user to login to their Google account and 
   * authorize Croogloo to obtain a JWT and OAuth token for acting on the users' behalf.
   */
  static init() {
    return new Promise(async function(resolve, reject) {
      // See https://developers.google.com/identity/gsi/web/guides/use-one-tap-js-api for more info
      // Let's initialize the google client
      croogloo_auth.gContactsAccessToken = undefined;
      google.accounts.id.initialize({
        client_id: '849074252471-u0u1j9iq2qe76qden13cidv2efdvo1e8.apps.googleusercontent.com',
        callback: handleCredentialResponse,
        cancel_on_tap_outside: false,
        prompt_parent_id: 'root'
      });

      // User is now prompted within browser for authentication/authorization
      google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          google.accounts.id.prompt()
        }
      })

      /**
       * Takes a JWT token response and handles it by parsing it and then getting an OAuth 2.0 token.
       * @param {object} response 
       */
      function handleCredentialResponse(response) {
        // One Tap Sign in returns a JWT token
        if (response) {
          // We are passing the signed in email id to oAuth
          // If we pass an email id to oAuth consent and the user has already given the oAuth consent, it will get auto selected.
          const responsePayload = CGGoogleAuth.parseJwt(response.credential);
          oauthSignIn(responsePayload.email);
        } else {
          console.error("Invalid response from google received. Expected a JWT token, instead received a falsy response: " + response);
        }
      }

      function oauthSignIn(googleId) {
        const client = google.accounts.oauth2.initTokenClient({
          client_id: '849074252471-u0u1j9iq2qe76qden13cidv2efdvo1e8.apps.googleusercontent.com',
          scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/contacts',
          hint: googleId,
          prompt: '', // Specified as an empty string to auto select the account which we have already consented for use.
          callback: (tokenResponse) => {
            croogloo_auth.gContactsAccessToken = tokenResponse.access_token;
            resolve();
          },
        });
        client.requestAccessToken();
      }
    });
  }

  static async importGoogleContacts() {
    return new Promise((accept,reject)=> {
      var url = "/googleContact?token=" + croogloo_auth.usertoken
      + "&tenantId=" + croogloo_auth.tenantId
      + "&access_token=" + croogloo_auth.gContactsAccessToken;
      showSpinner();
      return authenticatedcall(url).then(function(resp){
        console.debug(resp);
        hideSpinner();
        CGGoogleContacts.isBusy = false;
        cgToast(i18n.t("js.google.contacts.import")+" <a style='color:lightblue;' href='#taskMonitoring'>"+i18n.t("js.google.status")+"</a>");
        accept()
      });
    });
  }

  static async exportToGoogleContacts() {
    console.debug("CGGoogleContacts is busy: " + CGGoogleContacts.isBusy);
    if (CGGoogleContacts.isBusy === true) {
      return;
    }
    CGGoogleContacts.isBusy = true;
    CGGoogleContacts.resetToken();
    console.debug("[EXPORT CONTACT] CGGoogleContacts not ready yet. Waiting...");
    while (!CGGoogleContacts.isTokenAssigned()) {
      if (!CGGoogleContacts.isBusy) {
        // The task was aborted
        return;
      }
      await new Promise(resolve => setTimeout(resolve, 50));
    }
    console.debug("[EXPORT CONTACT] CGGoogleContacts ready!");
    return new Promise((accept,reject)=> {
      var url = "/googleContact?token=" + croogloo_auth.usertoken
        + "&tenantId=" + croogloo_auth.tenantId
        + "&access_token=" + croogloo_auth.gContactsAccessToken;
      showSpinner();
      return authenticatedcall(url, "POST").then(function(resp){
        console.debug(resp);
        hideSpinner();
        CGGoogleContacts.isBusy = false;
        cgToast(i18n.t("js.google.contacts.export")+" <a style='color:lightblue;' href='#taskMonitoring'>"+i18n.t("js.google.status")+"</a>");
      });
    });
  }

  static resetToken() {
    croogloo_auth.gContactsAccessToken = undefined;
    showSpinner();
    gapi.auth2.getAuthInstance().signIn({prompt:'select_account'}).then(function(resp) {
      CGGoogleContacts.tryAssignToken();
      hideSpinner();
    }, function(error) {
      hideSpinner();
      CGGoogleContacts.isBusy = false;
      console.error(error);
    });
  }

  static tryAssignToken() {
    if(gapi.client.getToken()) {
      croogloo_auth.gContactsAccessToken = gapi.client.getToken().access_token;
    }
  }

  static isTokenAssigned() {
    return !!croogloo_auth.gContactsAccessToken;
  }
}
