import Auth from './login.js';
import i18n from '../components/Translation';
require("babel-core/register");
const uuid = require('uuid/v4');

export class Router {
    constructor(routes, el, auth = new Auth()) {
        this.routes = routes;
        for (let key in routes) {
            if (typeof routes[key] == 'object') {
                routes[key].securityPageName = key;
                routes[key].adaptMenuDisplay();
            }
        }
        this.el = el;
        this.auth = auth;
        window.onhashchange = this.hashChanged.bind(this);
        this.hashChanged();

        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', `${window.innerHeight}px`);
        }
        window.addEventListener('resize', appHeight);
        appHeight();
    }

    hashChanged(ev) {
        if (this.preventHashChangeEvt) {
            this.preventHashChangeEvt = false;
        } else {
            console.log('detected hash change');
            this.load();
        }
    }

    loadPage(pageName, extraParams) {
        this.preventHashChangeEvt = true;
        window.location.hash = pageName;
        this.load(extraParams);
    }

    load(extraParams = null) {
        var self = this;
        if (!this.hasAccess(getPageName(this), getExtraParams(getPageName(this)))) {
            if (window.croogloocurrentpage && window.croogloocurrentpage.pageName) {
                cgToast(i18n.t("js.unauthorized"));
                try {
                    saveSystemActivity({
                        action: 'unauthorized navigate',
                        params: getPageName(this),
                        message: 'User accessed an unauthorized page.'
                    });
                } catch (e) {
                    console.error("Error in navigate saveSystemActivity", e);
                }
                self.preventHashChangeEvt = true;
                window.location.hash = window.croogloocurrentpage.pageName;
                return;
            } else {
                try {
                    saveSystemActivity({
                        action: 'redirect',
                        params: getPageName(this),
                        message: 'Redirect user due to unauthorized access.'
                    });
                } catch (e) {
                    console.error("Error in redirect saveSystemActivity", e);
                }
                this.autoRedirect();
                return;
            }
        }
        if (typeof this.onunload !== 'function') {
            this.onunload = () => Promise.resolve();
        }
        this.onunload().then(() => {
            self.onunload = undefined;
            self.unloadPreviousCSS();
            self.unloadComponents();
            document.getElementById('main').onclick = null;
            let pageName = getPageName(self);
            self.show(pageName, getExtraParams(pageName));
            window.onbeforeunload = null;
        }, () => {
            if (window.location.hash != '#' + window.croogloocurrentpage.pageName) {
                self.preventHashChangeEvt = true;
            }
            window.location.hash = window.croogloocurrentpage.pageName;
        });


        function getPageName(that) {
            return window.location.hash.length &&
                that.routes.hasOwnProperty(window.location.hash.substr(1).split("/")[0])
                ? window.location.hash.substr(1) : that.routes['#default'];
        }

        function getExtraParams(pageName, store = true) {
            let tmpPageParams = null;
            try {
                if (secureLocalStorage.get('extraParams') != '') {
                    try {
                        if (JSON.parse(secureLocalStorage.get('extraParams')).for !== pageName) {
                            secureLocalStorage.remove('extraParams');
                        } else if (!extraParams) {
                            tmpPageParams = JSON.parse(secureLocalStorage.get('extraParams')).params;
                        }
                    } catch (e) {
                        secureLocalStorage.remove('extraParams');
                        console.error(e.message);
                    }
                }
                if (extraParams) {
                    if (store) {
                        self.saveExtraParams(pageName, extraParams);
                    }
                    tmpPageParams = extraParams;
                }
            } catch (err1) {
                console.error(err1);
                localStorage.removeItem('extraParams');
            }
            return tmpPageParams;
        }
    }

    newTab(url) { // prevents browsers from treating the new tab as a popup
        sessionStorage.setItem('redirect_url', url);
        window.open('redirect.html', '_blank');
    }

    saveExtraParams(pageName, extraParams) {
        secureLocalStorage.set('extraParams', JSON.stringify({
            for: pageName,
            params: extraParams
        }));
    }

    hasAccess(pageName, extraParams) {
        try {
            if (window.location.href.endsWith('#') && window.location.href.includes('login.html')) {
                return true; // logging out
            }
            let securityPageName = this.routes[pageName.split('/')[0]].securityPageName;
            let extraParamSecPageName = (typeof extraParams === 'object'
                && extraParams !== null && typeof extraParams.securityPageName == 'string')
                ? extraParams.securityPageName
                : null;
            return croogloo_auth.hasAccess(securityPageName) && !croogloo_auth.isSharedAccess
                || securityPageName === extraParamSecPageName && extraParamSecPageName !== null
                && croogloo_auth.isSharedAccess
                || croogloo_auth.isSharedAccess && securityPageName === 'shared';
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    autoRedirect() {
        for (let pageName in this.routes) {
            if (this.hasAccess(pageName) && !['resetPassword', 'contact', 'shared'].includes(pageName)) {
                this.loadPage(pageName);
                return;
            }
        }
        croogloo_auth.logout();
    }

    clearExtraParams() {
        if (secureLocalStorage.get('extraParams') != '') {
            secureLocalStorage.remove('extraParams');
        }
    }

    deleteExtraParam(param) {
        if (secureLocalStorage.get('extraParams') != '') {
            let extraParams = JSON.parse(secureLocalStorage.get('extraParams'));
            if (typeof extraParams === 'object' && extraParams.params.hasOwnProperty(param)) {
                delete extraParams.params[param];
                if (Object.keys(extraParams.params).length) {
                    secureLocalStorage.set('extraParams', JSON.stringify(extraParams));
                } else {
                    secureLocalStorage.remove('extraParams');
                }
            } else {
                console.debug('Could not find param "' + param + '" in sesion storage.');
            }
        } else {
            console.debug('Could not find param "' + param + '" in sesion storage.');
        }
        if (window.croogloocurrentpage.extraParams.hasOwnProperty(param)) {
            delete window.croogloocurrentpage.extraParams[param];
        } else {
            console.debug('Could not delete param "' + param + '" in current page.');
        }
    }

    unloadComponents() {
        if (!secureLocalStorage.get('keepComponent')) {
            $('.cg-component').remove();
        } else {
            secureLocalStorage.remove('keepComponent');
        }
    }

    unloadPreviousCSS() {
        let pageName;
        try {
            if (!window.croogloocurrentpage) { return; }
            pageName = window.croogloocurrentpage.pageName;
            let stylesheet = document.querySelector('#' + pageName + 'Stylesheet');
            stylesheet.parentElement.removeChild(stylesheet);
        } catch (e) {
            console.warn('could not find a stylesheet to delete for ' + pageName);
        }

    }

    reload() {
        this.load();
    }

    async show(pageName, extraParams) {
        if (!this.auth.isAuthenticated()) {
            try {
                saveSystemActivity({
                    action: 'unauthorized navigate',
                    params: pageName,
                    message: 'User tried to navigate but is unauthenticated.'
                });
            } catch (e) {
                console.error("Error in navigate saveSystemActivity", e);
            }
        } else {
            var splitPages = pageName.split("/");
            pageName = splitPages.splice(0, 1)[0];
            var page = this.routes[pageName];
            try {
                saveSystemActivity({
                    action: 'navigate',
                    params: pageName,
                    message: 'User loaded a page.'
                });
            } catch (e) {
                console.error("Error in navigate saveSystemActivity", e);
            }
            if (!page) {
                this.show('#default');
            } else if (typeof page === 'string') {
                this.show(page, extraParams);
            } else {
                var urlParams = {};
                // Don't really know waht this is doing with urlParams but I haven't been able
                // to trigger it using query params or forwar slash separated strings
                for (var idx in splitPages) {
                    var paramname = page.orderedParams[idx] ? page.orderedParams[idx] : 'param' + idx;
                    urlParams[paramname] = splitPages[idx];
                }
                // Check for query params (anything after the question mark) that indicate we should send the user on a product tour
                // The only supported query param is for Intercom product tours product_tour_id
                if (urlParams.length == 0 ||
                    urlParams[paramname] == 'product_tour_id' ||
                    window.location.href.includes('product_tour_id=')
                ) {
                    // Retrieve the query parameter product_tour_id and use the id after the equals sign
                    goToProductTour(window.location.href.split('=')[1]);
                }
                page.urlParams = urlParams;
                if (!window.isFirstPageLoad) {
                    window.loadingScreen = null;
                }
                $(this.el).empty();
                let randomUUID = uuid();
                window.pageToLoadUUID = randomUUID;
                await page.load(extraParams);
                // this condition is necessary to prevent simultaneous load of multiple
                // pages
                if (randomUUID === window.pageToLoadUUID) {
                    page.show(this.el);
                }
            }
        }
    }
}
