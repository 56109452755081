import i18n from './components/Translation';
import DocUtility from './components/DocUtility';
import 'jquery.tooltips';
import Upload from './components/Upload.js';
import UploadManager from './components/UploadManager.js';
import DownloadNotification from './components/notifications/DownloadNotification.js';
import swal from 'sweetalert2';

export function init() {
  loader
    .add(showSpinner)
    .add(initGlobals, 'globals')
    .add(loadImageFromUrlIfAny, 'image-from-url')
    .add(ui, 'foundation')
    .add(getDataAndDisplayPhotos, ['photo-entities', 'photo-activity'])
    .add(showLoadTime)
    .add(syncPhotoActivity)
    .add(updateAllLatestComments)
    .add(addPhotoActivityUILayer)
    .add(initListeners)
    .add(selectUrlImageIfAny)
    .execute();
}

function ui() {
  folderPath = getFolderPath(); // isSharedCollection must be defined first
  activeFolder = folderPath[folderPath.length-1];
  $('#main').foundation();
  document.title = "Croogloo - Photos";
  adaptBreadcrum();
  $('div.tooltip').remove(); // remove tooltips stuck to page if any
  Utility.loadStylesheet('/assets/css/jquery.tooltips.css');
  Utility.loadStylesheet('/assets/croogloo-custom/css/no-data.css');
  if(!window.uploadManager) {
    new UploadManager();
  }

  loader.check('foundation');
}

/**
 * Loads an image from the URL file path.
 * The URL should be formatted as such "/#photos/FOLDER_ID_X/FOLDER_ID_Y/image_name.jpg".
 * Access to the file and folders will be validated in the backend.
 */
async function loadImageFromUrlIfAny() {
  try {
    let locationHashParts = window.location.hash.split('/');
    if(locationHashParts.length > 1) {
      let filePathItems = locationHashParts.slice(1);
      console.debug('file path items: ', filePathItems);
      let photoPathParts = await validatePhotoPath(filePathItems);
      if(photoPathParts !== null && photoPathParts.length) {
        photoPathParts.splice(0, 0, DEFAULT_FOLDER);
        sessionStorage.setItem('active-photo-folder', 
          JSON.stringify(photoPathParts.slice(0, photoPathParts.length - 1)));  
        sessionStorage.setItem('active-photo-file', 
          JSON.stringify(photoPathParts[photoPathParts.length - 1]));
      }
      window.history.pushState('photos', "Photos", "/#photos");
    }
  } catch(e1) {
    console.error(e1);
    console.error('failed to process location hash parts');
  } finally {
    loader.check('image-from-url');
  }

  function validatePhotoPath(filePathItems) {
    return new Promise(resolve => {
      try {
        let photoPathParts = null;
        apicall('documentsapi', 'validatePhotoPath', {}, {
          value: filePathItems.join(',')
        }).then(resp => {
          if(resp.items.length) {
            photoPathParts = [];
            for(let item of resp.items) {
              if(item.id && item.fileName) {
                photoPathParts.push({
                  id: item.id,
                  name: item.fileName
                });
              } else {
                photoPathParts = null;
                console.error('found invalid item in validated photo path', item);
                break;
              }
            }
          }
        }).catch(() => console.error('server error')).then(() => resolve(photoPathParts));
      } catch(e) {
        console.error(e);
        resolve(null);
      }
    });
  }
}

/**
 * Click on the image loaded from the URL, if any.
 */
function selectUrlImageIfAny() {
  try {
    let activePhotoFile = sessionStorage.getItem('active-photo-file');
    if(activePhotoFile) {
      activePhotoFile = JSON.parse(activePhotoFile);
      let activePhotoThumbnail = document.querySelector(`div.photo-cell[photo-id="${activePhotoFile.id}"] img`);
      if(activePhotoThumbnail) {
        activePhotoThumbnail.click();
      }
    }
  } catch(e) {
    console.error(e);
  } finally {
    sessionStorage.removeItem('active-photo-file');
  }
}

var activeFolder, folderPath;
var photoFolders;
var commentIdMap;
var photoActivities;
var loadTime;
var draggedElement, nbOfDraggedOverLayers;
var isSharedCollection, sharedLinkOptions;
var clickedMenu;
var viewMode, viewModeClass;

const uuid = require('uuid/v4');
const FEEDBACK_LIKED = 'liked';
const FEEDBACK_DISLIKED = 'disliked';
const FEEDBACK_NONE = 'none';
const CROOGLOO_BOT_NAME = 'Croogloo Bot';
const VIEW_MODE = {
  GRID: 'grid',
  LIST: 'list'
};

const LinkAccess = {
  VIEW_ONLY: 0,
  VIEW_COMMENT: 1,
  VIEW_COMMENT_EDIT: 2
};
Object.freeze(LinkAccess);

var SharingType = {
  FOLDER_ONLY: { value: 0 },
  FOLDER_AND_SUB: { value: 1 },
  ALL_FOLDERS: { value: 2 }
};

function initGlobals() {
  photoFolders = {};
  commentIdMap = {};
  photoActivities = [];
  loadTime = {};
  draggedElement = null;
  nbOfDraggedOverLayers = 0;
  clickedMenu = { top: 0, left: 0, element: null };
  sharedLinkOptions = Utility.getPageParam('linkOptions');
  isSharedCollection = sharedLinkOptions !== null;
  DEFAULT_FOLDER = isSharedCollection
    ?{
      id: sharedLinkOptions.sharedFolderId,
      name: sharedLinkOptions.sharedFolderName
    }
    :{
      id: 'PHOTO_ROOT',
      name: 'Photos'
    }
  viewMode = sessionStorage.getItem('photo-view-mode') === VIEW_MODE.GRID
    ?VIEW_MODE.GRID : VIEW_MODE.LIST;
  viewMode = !sessionStorage.getItem('photo-view-mode') ? VIEW_MODE.GRID : viewMode;
  viewModeClass = isGridMode() ?'photo-grid-view' :'photo-list-view';
  adaptViewControllerIcon();
  loader.check('globals');
}

function isGridMode() {
  return viewMode === VIEW_MODE.GRID;
}

function showLoadTime() {
  console.debug(loadTime);
}

function adaptViewControllerIcon() {
  let viewControllerIcon = document.querySelector('#viewControllerContainer > div > i.view-controller-btn');
  viewControllerIcon.title = i18n.t("js.photos.mode."+ (viewMode === VIEW_MODE.GRID ? "list":"grid"));
  viewControllerIcon.classList.add('fa', 'fa-2x', viewMode === VIEW_MODE.GRID ?'fa-list' :'fa-th');
  $(viewControllerIcon).tooltip();
}

function initListeners() {
  if(!croogloo_auth.isSharedAccess && (!isSharedCollection
    || sharedLinkOptions.accessType !== LinkAccess.VIEW_ONLY)) {
    document.getElementById('commentCancelBtn').onclick = function() {
      let textarea = document.getElementById('newCommentArea');
      if(!textarea.disabled) {
        textarea.value = '';
      }
    };
    document.getElementById('commentSubmitBtn').onclick = function() {
      let textarea = document.getElementById('newCommentArea');
      let cancelBtn = document.getElementById('commentCancelBtn');
      let activeFolderId = activeFolder.id;
      saveNewComment(textarea, cancelBtn, undefined, function(cgComment) {
        try {
          if(!isGridMode()) {
            let photoObj = photoFolders[activeFolderId].photos[cgComment.photoId];
            photoObj.updateLatestComment(cgComment.authorName || null, 
              cgComment.content ?formatCommentContent(cgComment) :null);
          }
        } catch(e) {
          console.error(e);
        }
      });
    };
  } else {
    let textarea = document.getElementById('newCommentArea');
    textarea.disabled = true;
    textarea.setAttribute('placeholder', i18n.t("js.photos.comment.disabled"));
    document.getElementById('commentCancelBtn').style.cursor = 'not-allowed';
    document.getElementById('commentSubmitBtn').style.cursor = 'not-allowed';
  }

  for(let cell of document.querySelectorAll('div.photo-cell')) {
    cell.onclick = onPhotoClick;
  }
  if(!croogloo_auth.isSharedAccess && isSharedCollection
    && sharedLinkOptions.accessType === LinkAccess.VIEW_COMMENT_EDIT
    || !isSharedCollection && croogloocurrentpage.getAccessLevel() > 1) {
    //document.getElementById('btnContainerRight').classList.remove('hidden');
    document.getElementById('uploadPhotoBtn').classList.remove('hidden');
    if(!isSharedCollection
      || parseInt(sharedLinkOptions.sharingType) > SharingType.FOLDER_ONLY.value) {
      let newFolderBtn = document.getElementById('newFolderBtn');
      newFolderBtn.onclick = createFolder;
      newFolderBtn.classList.remove('hidden');
    }
    let fileUploadInput = document.getElementById('fileUploadInput');
    fileUploadInput.onchange = function() { uploadPhoto(this.files); };
    document.getElementById('uploadPhotoBtn').onclick = () => fileUploadInput.click();
    document.getElementById('no-data-span').onclick = () => fileUploadInput.click();
    setupPhotoOptionMenu();
    initDragAndDropListeners();
  }

  if(croogloocurrentpage.getAccessLevel() > 1 && !isSharedCollection) {
    let sharePhotoBtn = document.getElementById('sharePhotoBtn');
    sharePhotoBtn.style.visibility = 'visible';
    sharePhotoBtn.onclick = showSharingModal;
  }

  function initDragAndDropListeners() {
    let dropzone = document.getElementById('photoDropZone');
    let draggedOverElemCount = 0;
    let dropZoneBackgroundOverlay = document.getElementById('dropZoneBackgroundOverlay');
    let dropZoneTextOverlay = document.getElementById('dropZoneTextOverlay');
    dropzone.ondragenter = function(event) {
      event.preventDefault();
      if(draggedOverElemCount++ == 0) {
        if(draggedElement !== null) {
          return;
        }
        event.dataTransfer.dropEffect = event.dataTransfer.effectAllowed = 'copy';
        dropZoneTextOverlay.innerHTML = `<i class="fa fa-upload"></i>&nbsp;${i18n.t("js.photos.upload-to")} <b>${activeFolder.name}</b>`;
        dropZoneBackgroundOverlay.classList.add('visible-drop-overlay', 'fade-in');
        dropZoneTextOverlay.classList.add('visible-drop-overlay');
      }
    }
    dropzone.ondragover = function(event) {
      event.dataTransfer.dropEffect = event.dataTransfer.effectAllowed = 'copy';
      event.preventDefault();
    }
    dropzone.ondragleave = function(event) {
      event.preventDefault();
      if(--draggedOverElemCount <= 0) {
        dropZoneBackgroundOverlay.classList.remove('visible-drop-overlay', 'fade-in');
        dropZoneTextOverlay.classList.remove('visible-drop-overlay');
      }
    }
    dropzone.ondrop = function(event) {
      event.preventDefault();
      draggedOverElemCount = 0;
      dropZoneBackgroundOverlay.classList.remove('visible-drop-overlay', 'fade-in');
      dropZoneTextOverlay.classList.remove('visible-drop-overlay');
      if(event.dataTransfer.getData('cell-id')) { return; }
      uploadPhoto((typeof event.dataTransfer.files !== 'undefined' && event.dataTransfer.files !== null)
        ?event.dataTransfer.files
        :[].map.call(event.dataTransfer.items, item => item.getAsFile()));
    }
  }


  /**
   * setupPhotoOptionMenu - only for R & W access
   */
  function setupPhotoOptionMenu() {
    document.getElementById('main').onclick = function(e) {
      adaptContextMenuPositionAndContent(
        (e.target.classList.contains('photo-option-menu') || e.target.parentElement
        && e.target.parentElement.classList.contains('photo-option-menu')) 
          ?e.target.closest('div.photo-option-menu') 
          :e.target, e.clientX, e.clientY, undefined);
    };

    document.getElementById('photo-dropdown').onshow = function() {
      this.style.left = clickedMenu.left - this.clientWidth + 'px';
      this.style.top = clickedMenu.top + 20 + 'px';
    };

    document.getElementById('menu_rename').onclick = function(event) {
      let cell = clickedMenu.element.closest('div.photo-cell, div.folder-cell');
      let photoTitleContainer = cell.querySelector('div.photo-title, div.folder-title');
      let activeFolderId = activeFolder.id;
      let isPhoto = cell.hasAttribute('photo-id');
      let itemId = cell.getAttribute(isPhoto ?'photo-id' :'folder-id');
      let photoTitleSpan = photoTitleContainer.querySelector('span');
      let titleWidth = photoTitleContainer.clientWidth;
      photoTitleSpan.classList.add('hidden');
      toggleLineBreakSiblingVisibility(false);
      let input = document.createElement('input');
      input.type = 'text';
      input.width = titleWidth.toString() + 'px';
      input.value = !(isPhoto && hasExtension(photoTitleSpan.textContent))
        ?photoTitleSpan.textContent
        :photoTitleSpan.textContent.substring(0, photoTitleSpan.textContent.lastIndexOf('.'));
      cell.setAttribute('draggable', 'false'); // to enable input text selection
      input.onclick = function(e) { e.stopImmediatePropagation(); }
      input.onkeydown = function(e) {
        if(e.key.toLowerCase() == 'enter' || e.code.toLowerCase() == 'enter') {
          e.preventDefault();
          e.stopImmediatePropagation();
          updateName(e);
        } else if(e.key.toLowerCase() == 'escape') {
          e.preventDefault();
          e.stopImmediatePropagation();
          cancelRename();
        }
      }
      input.onblur = updateName;
      photoTitleContainer.insertBefore(input, photoTitleSpan);
      input.focus();
      input.select();

      function cancelRename() {
        input.onblur = function() {};
        photoTitleSpan.classList.remove('hidden');
        toggleLineBreakSiblingVisibility(true);
        cell.setAttribute('draggable', 'true');
        $(input).remove();
      }

      function toggleLineBreakSiblingVisibility(shouldBeVisible) {
        if(!isGridMode()) {
          if(shouldBeVisible) {
            photoTitleSpan.nextSibling.classList.remove('hidden');
          } else {
            photoTitleSpan.nextSibling.classList.add('hidden');
          }
        }
      }

      function updateName(e) {
        const MAX_FILENAME_LENGTH = 150;
        if(!input.value.trim() || input.value.trim().length > MAX_FILENAME_LENGTH) {
          cancelRename();
          if(input.value.trim().length > MAX_FILENAME_LENGTH) {
            swalToast({
              type: 'error',
              title: i18n.t("js.photos.limit."+(isPhoto ?'photo' :'folder'), {MAX_LENGTH:MAX_FILENAME_LENGTH.toString()})
            });
          }
          return;
        }
        let newFileName = input.value.trim();
        
        if(isPhoto && hasExtension(photoTitleSpan.textContent)) {          
          newFileName += photoTitleSpan.textContent
            .substring(photoTitleSpan.textContent.lastIndexOf('.'),
            photoTitleSpan.textContent.length);
        }

        var payload = {
          docId: itemId
        };

        if(isSharedCollection) {
          payload.token = sharedLinkOptions.userToken;
          payload.tenantId = sharedLinkOptions.tenantId;
        }

        let itemObject = photoFolders[activeFolderId][isPhoto ?'photos' :'folders'][itemId];
        input.onblur = undefined; // prevent onblur event from firing if update triggered from KeyboardEvent
        apicall('documentsapi', 'changeFileName', payload, {
          value: newFileName
        }).then(resp => {
          if(resp.responseCode === '0') {
            itemObject.name = photoTitleSpan.textContent = newFileName;
            $(input).remove();
            photoTitleSpan.classList.remove('hidden');
            toggleLineBreakSiblingVisibility(true);
            cell.setAttribute('draggable', 'true');
          } else {
            throw new Error('Invalid Response', resp);
          }
        }).catch(err => {
          console.error(err);
          swalToast({
            type: 'error',
            title: i18n.t("js.photos.rename.failure."+(isPhoto ?'photo' :'folder'))
          });
          $(input).remove();
          photoTitleSpan.classList.remove('hidden');
          toggleLineBreakSiblingVisibility(true);
          cell.setAttribute('draggable', 'true');
        });
      }

      function hasExtension(fileName) {
        return /[^\.]+\.(?=[\s\S])/.test(fileName);
      }
    };

    document.getElementById('menu_download').onclick = function(event) {
      let cell = clickedMenu.element.closest('div.photo-cell, div.folder-cell');
      let isPhoto = cell.hasAttribute('photo-id');
      if(isPhoto) {
        let photoObj = photoFolders[activeFolder.id].photos[cell.getAttribute('photo-id')];
        photoObj.download();
      } else {
        let folderObj = photoFolders[activeFolder.id].folders[cell.getAttribute('folder-id')];
        folderObj.downloadAsZip();
      }
    };

    if(folderPath.length > 1) {
      document.getElementById('menu_moveUp').onclick = function() {
        let cell = clickedMenu.element.closest('div.photo-cell, div.folder-cell');
        let newParentId = folderPath[folderPath.length-2].id;
        changeParentFolder(cell, newParentId);
      }
    } else {
      document.getElementById('menu_moveUp').style.display = 'none';
    }

    document.getElementById('menu_delete').onclick = function() {
      let cell = clickedMenu.element.closest('div.photo-cell, div.folder-cell');
      let activeFolderId = activeFolder.id;
      let isPhoto = cell.hasAttribute('photo-id');
      let itemId = cell.getAttribute(isPhoto ?'photo-id' :'folder-id');
      let itemObjParent = photoFolders[activeFolderId][isPhoto ?'photos' :'folders'];
      let itemObj = itemObjParent[itemId];
      if(!(itemObj instanceof Photo) && !(itemObj instanceof Folder)) {
        swalToast({
          type: 'error',
          title: i18n.t("js.photos.delete.photo.failure")
        });
        return;
      }
      swal({
        title: i18n.t("js.photos.delete."+(isPhoto ? 'photo':'folder')+".title"),
        html: i18n.t("js.photos.delete.query."+(itemObj.name? "name":(isPhoto? "photo":"folder")), {name: (itemObj.name||'')}),
        confirmButtonText: i18n.t("yes"),
        confirmButtonColor: '#FF4136',
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        type: 'question',
        showLoaderOnConfirm: true,
        showCloseButton: true,
        allowOutsideClick: () => !swal.isLoading(),
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            apicall('documentsapi', 'deletePhotoItem', {
              itemId: itemObj.id,
              parentId: activeFolderId
            }).then(resp => {
              if(resp.responseCode === '0') {
                cell.remove();
                delete itemObjParent[itemId];
                if(isActiveFolderEmpty()) {
                  document.getElementById('no-data-span').style.display = '';
                  document.getElementById('uploadPhotoBtn').style.display = 'none' ;
                  document.getElementById('sharePhotoBtn').style.display = 'none' ;
                  document.getElementById('viewControllerContainer').style.display = 'none' ;
                }              
                DocUtility.removeCardViews(activeFolderId);
                resolve();
              } else {
                throw new Error('Invalid response code');
              }
            }).catch(err => {
              reject(i18n.t("js.photos.wrong"));
            });
          });
        }
      }).catch(swal.noop);
    }
  }

  // view mode listener
  let viewControllerIcon = document.querySelector('#viewControllerContainer > div > i.view-controller-btn');
  viewControllerIcon.onclick = function() {
    viewMode = viewMode === VIEW_MODE.GRID ?VIEW_MODE.LIST :VIEW_MODE.GRID;
    loadFolder(getCurrentFolderPath());
  }
}

function getCurrentFolderPath() {
  let currentFolderPath;
  try {
    currentFolderPath = JSON.parse(sessionStorage.getItem('active-photo-folder')||'[]');
  } catch(e) {
    console.error(e);
    currentFolderPath = [];
  }
  return currentFolderPath;
}

function adaptContextMenuContent(isPhoto) {
  document.querySelector('#photo-dropdown li > a#menu_download').textContent = i18n.t(isPhoto ? "utils.download":"docs.menu.dwnld-zip");
}

function adaptContextMenuPositionAndContent(centeredIcon, xPos = null, yPos = null, isPhoto) {
  if(typeof isPhoto === 'boolean') {
    adaptContextMenuContent(isPhoto);
  } else {
    console.debug('not updating context menu content');
  }
  adaptContextMenuPosition();

  function adaptContextMenuPosition() {
    if(centeredIcon !== null && centeredIcon.classList.contains('dropdown-ref')) {
      let sideFitOffset = ($(centeredIcon).offset().left < $(document).width() / 2) 
        ?200 :0
      clickedMenu.top = yPos - $('#main').offset().top + $('#main').scrollTop();
      clickedMenu.left = xPos - $('#main').offset().left + sideFitOffset;
      
      if(clickedMenu.element !== null && clickedMenu.element !== centeredIcon
          && $('#photo-dropdown').hasClass('is-open')) {
        clickedMenu.element = centeredIcon;
        $('#photo-dropdown').foundation('open');
      } else {
        clickedMenu.element = centeredIcon;
      }
    } else if(shouldCloseDropdown()) {
      $('#photo-dropdown').foundation('close');
    }
    
    function shouldCloseDropdown() {
      if(!$('#photo-dropdown').hasClass('is-open')) { return false; }
      if($('#photo-dropdown')[0] === centeredIcon) { return false; }
      return true;
    }
  }
}

function showSharingModal() {
  let currentFolder = Object.assign({}, activeFolder);

  SharingType.FOLDER_ONLY.text = i18n.t("js.photos.sharing.opt1", {folder:currentFolder.name});
  SharingType.FOLDER_AND_SUB.text = i18n.t("js.photos.sharing.opt2", {folder:currentFolder.name});
  SharingType.ALL_FOLDERS.text = i18n.t("js.photos.sharing.opt3");

  const LinkOptions = {
    NEVER_EXPIRING: -1,
    DEFAULT_VALIDITY: -1,
    DEFAULT_ACCESS: LinkAccess.VIEW_ONLY,
    DEFAULT_SHARING: SharingType.FOLDER_ONLY
  };
  Object.freeze(LinkOptions);

  const HOUR_MS = 1000 * 3600;
  const DAY_MS = HOUR_MS * 24

  var linkOptions = {
    validityTime: LinkOptions.DEFAULT_VALIDITY,
    accessType: LinkOptions.DEFAULT_ACCESS,
    sharingType: LinkOptions.DEFAULT_SHARING,
    module: 'photo'
  };

  let sharingLinkURL;

  swal.mixin({
    confirmButtonText: i18n.t("button.next")+' &rarr;',
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    progressSteps: ['1', '2'],
    useRejections: false,
    expectRejections: true, 
    showCloseButton: true,  
  }).queue([
    {
      title: i18n.t("js.photos.sharing.title"),
      html: i18n.t("js.photos.sharing.text"),
      input: 'select',
      inputOptions: mapSharingOptions(),
      showLoaderOnConfirm: false,
      allowOutsideClick: ($('#docBody').hasClass('swal2-shown') ? false : true),//Allow to exit out by 'click on grey' only if not multistep      
      onOpen: function(modal) {
        modal.querySelector('select').value = linkOptions.sharingType.value;
      },
      preConfirm: function(folderSharingTypeVal) {
        return new Promise((resolve, reject) => {
          linkOptions.sharingType = Object.values(SharingType)
            .filter(x => x.value === parseInt(folderSharingTypeVal))[0].value;
          resolve();
        });
      }
    },
    {
      title: i18n.t("js.photos.sharing.title"),
      html: generateLinkOptions(),
      animation: false,
      confirmButtonText: i18n.t("js.photos.sharing.btn2"),
      footer: i18n.t("js.photos.sharing.footer"),
      showLoaderOnConfirm: true,
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
      },     
      onOpen: function() {
        try {
          document.querySelector('input.link-access[link-data="'
            + linkOptions.accessType + '"]').checked = true;
          let isCustomTime = true;
          [].forEach.call(document.querySelectorAll('input.link-duration'),
            function(durationInput) {
              if(durationInput.getAttribute('link-data') == (linkOptions.validityTime+'')) {
                durationInput.checked = true;
                isCustomTime = false;
              }
          });
          if(isCustomTime) {
            let nbOfDays = parseInt(linkOptions.validityTime / DAY_MS);
            let nbOfHours = parseInt((linkOptions.validityTime % DAY_MS) / HOUR_MS);
            document.getElementById('link_duration_days').value = nbOfDays + '';
            document.getElementById('link_duration_hours').value = nbOfHours + '';
            document.getElementById('link_expires_custom').checked = true;
            let $customDurationOpt = $('#link_expires_custom');
            $customDurationOpt.addClass('custom-expanded');
            $('#linkCustomDuration').slideToggle(0);
          } else {
            document.getElementById('link_duration_days').value = '1';
            document.getElementById('link_duration_hours').value = '0';
          }
        } catch(e) {
          console.error(e);
          document.getElementById('link_read-only').checked = true;
          document.getElementById('link_expires_never').checked = true;
        }
        $('input[name=link-duration]').off('change');
        $('input[name=link-duration]').on('change', function() {
          let $customDurationOpt = $('#link_expires_custom');
          if($customDurationOpt.is(':checked') &&
              !$customDurationOpt.hasClass('custom-expanded')) {
            $customDurationOpt.addClass('custom-expanded');
            $('#linkCustomDuration').slideToggle(400);
          } else if(!$customDurationOpt.is(':checked') &&
              $customDurationOpt.hasClass('custom-expanded')) {
            $customDurationOpt.removeClass('custom-expanded');
            $('#linkCustomDuration').slideToggle(400);
          }
        });
      },
      preConfirm: function() {
        return new Promise((resolve, reject) => {
          if(document.getElementById('link_expires_custom').checked) {
            let link_duration_days = document.getElementById('link_duration_days');
            let link_duration_hours = document.getElementById('link_duration_hours');
            if(hasInvalidNumber(link_duration_days)) {
              reject(i18n.t("js.photos.sharing.reject.1"));
              return;
            }
            if(hasInvalidNumber(link_duration_hours)) {
              reject(i18n.t("js.photos.sharing.reject.1"));
              return;
            }
            let daysDuration = parseInt(link_duration_days.value);
            let hoursDuration = parseInt(link_duration_hours.value);
            if(daysDuration + hoursDuration === 0) {
              reject(i18n.t("js.photos.sharing.reject.3"));
              return;
            }
            linkOptions.validityTime = daysDuration * DAY_MS
              + hoursDuration * HOUR_MS;
            function hasInvalidNumber(numberInput) {
              numberInput.value = numberInput.value.replace(/\s/g, '');
              if(numberInput.value.match(/^\d+$/) == null
                  || isNaN(parseInt(numberInput.value))
                  || parseInt(numberInput.value) < 0
                  || parseInt(numberInput.value) > 1000) {
                return true;
              } return false;
            }
          } else {
            linkOptions.validityTime = parseInt(document
              .querySelector('input[name="link-duration"]:checked')
              .getAttribute('link-data'));
          }
          linkOptions.accessType = parseInt(document
            .querySelector('input[name="link-access"]:checked')
            .getAttribute('link-data'));

          let sharedFolder = linkOptions.sharingType === SharingType.ALL_FOLDERS.value
            ?DEFAULT_FOLDER
            :currentFolder;
          apicall('metadataAPI', 'generateAccessLink', {}, Object.assign({
              sharedFolderId: sharedFolder.id,
              sharedFolderName: sharedFolder.name
            }, linkOptions)).then(resp => {
            if(resp.responseCode === '0' && typeof resp.responseMessage == 'string'
              && resp.responseMessage.trim() != '') {
              sharingLinkURL = resp.responseMessage;
              resolve();
            } else {
              throw new Error(resp);
            }
          }).catch(err => {
            console.error(err);
            reject(i18n.t("js.photos.sharing.reject.4"));
          });
        });
      }
    }
  ]).then(obj => {
    console.debug('swal resp obj: ', obj);
    if(typeof obj !== 'object' || !obj.hasOwnProperty('dismiss')) {
      swal({
        title: i18n.t("js.photos.link.success.title"),
        text: i18n.t("js.photos.link.success.text"),
        input: 'text',
        inputValue: sharingLinkURL,
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off',
          autocomplete: 'off',
          readonly: 'true'
        },
        type: 'success',
        showCancelButton: false,
        confirmButtonText: i18n.t("js.photos.link.success.button"),        
        onOpen: function(modal) {
          let accessLinkInput = modal.querySelector('input');
          accessLinkInput.style.cursor = 'text';
          accessLinkInput.select();
        },
        preConfirm: function() {
          let linkInput = document.querySelector('div.swal2-content').querySelector('input');
          linkInput.select();
          document.execCommand('copy');
          return Promise.resolve();
        }
      }).catch(swal.noop);
    }
  });
  function mapSharingOptions() {
    let sharingOptionMap = {};
    Object.values(SharingType).forEach(item => {
      sharingOptionMap[item.value] = item.text;
    });
    return sharingOptionMap;
  }
  function generateLinkOptions() {
    let container = document.createElement('div');
    container.style.textAlign = 'left';
    container.style.paddingLeft = '1rem';
    let accessOptTitle = document.createElement('div');
    accessOptTitle.style.lineHeight = '2rem';
    accessOptTitle.style.fontWeight = '400';
    accessOptTitle.textContent = i18n.t("js.photos.modal.perms.title");
    container.appendChild(accessOptTitle);
    addRadioOption('link-access', 'link_read-only', i18n.t("js.photos.modal.perms.view"),
      container, LinkAccess.VIEW_ONLY);
    addRadioOption('link-access', 'link_download', i18n.t("js.photos.modal.perms.vw-com"),
      container, LinkAccess.VIEW_COMMENT, false);
    addRadioOption('link-access', 'link_download-print', i18n.t("js.photos.modal.perms.all"),
      container, LinkAccess.VIEW_COMMENT_EDIT, false);

    let linkDurationTitle = document.createElement('div');
    linkDurationTitle.style.lineHeight = '2rem';
    linkDurationTitle.style.fontWeight = '400';
    linkDurationTitle.textContent = i18n.t("js.modal.expire.title");
    container.appendChild(linkDurationTitle);
    addRadioOption('link-duration', 'link_expires_never', i18n.t("js.modal.expire.never"), container, LinkOptions.NEVER_EXPIRING);
    addRadioOption('link-duration', 'link_expires_24h', i18n.t("js.modal.expire.day"), container, DAY_MS);
    addRadioOption('link-duration', 'link_expires_custom', i18n.t("js.modal.expire.custom"), container, 'custom');

    let customDuration = document.createElement('div');
    customDuration.id = 'linkCustomDuration';
    customDuration.style.display = 'none';

    addNumberInput('link_duration_days', i18n.t("js.modal.expire.ddays"), customDuration);
    addNumberInput('link_duration_hours', i18n.t("js.modal.expire.hhours"), customDuration);

    container.appendChild(customDuration);

    return container.outerHTML;
  }
  function addRadioOption(name, id, title, container, linkData, isEnabled = true) {
    let ro = document.createElement('input');
    ro.type = 'radio';
    ro.id = id;
    ro.name = name;
    ro.className = name;
    ro.setAttribute('link-data', linkData);
    let roLabel = document.createElement('label');
    roLabel.textContent = title;
    roLabel.setAttribute('for', id);
    container.appendChild(ro);
    container.appendChild(roLabel);
    if(!isEnabled) {
      ro.disabled = true;
      ro.title = i18n.t("js.photos.unavailable");
      roLabel.title = i18n.t("js.photos.unavailable");
      roLabel.style.cursor = 'not-allowed';
    }
  }
  function addNumberInput(id, labelTextContent, container) {
    let nbInput = document.createElement('input');
    nbInput.id = id;
    nbInput.type = 'number';
    nbInput.setAttribute('min', 0);
    nbInput.style.display = 'inline-block';
    nbInput.style.width = '20%';
    container.appendChild(nbInput);
    let nbLabel = document.createElement('label');
    nbLabel.textContent = labelTextContent;
    nbLabel.style.marginLeft = '0.5rem';
    nbLabel.style.marginRight = '0.5rem';
    container.appendChild(nbLabel);
  }
}

// NOTE: function for future consideration
// NOTE: webkitGetAsEntry may get renamed getAsEntry
function uploadFilesAndFolders(dataTransferItems) {
  for(let entry of [].map.call(dataTransferItems, item => item.webkitGetAsEntry())) {
    if(entry.isFile) {

    } else if(entry.isDirectory) {

    } else {

    }
  }
}

function onPhotoClick(event) {
  if(isGridMode()) {
    if(event.target.classList.contains('photo-cell')
      || event.target.classList.contains('photo-option-menu')
      || event.target.parentElement
        && event.target.parentElement.classList.contains('photo-option-menu')) {
      return; // outside container clicked
    }
  } else if(event.target.closest('div.photo-option-btn:not(.comment-option-btn)') !== null) {
    console.debug('ignoring photo click for list view');
    return;
  }
  buildCommentSection(this, true);
}

function toggleFullScreenView() {
  let photoViewOverlay = document.getElementById('photoViewOverlay');
  let mainPhotoViewer = document.getElementById('mainPhotoViewer');
  if(photoViewOverlay.classList.contains('side-view')) {
    photoViewOverlay.classList.replace('side-view', 'full-screen-view');
    mainPhotoViewer.classList.replace('large-6', 'large-12');
  } else {
    photoViewOverlay.classList.replace('full-screen-view', 'side-view');
    mainPhotoViewer.classList.replace('large-12', 'large-6');
  }
  document.getElementById('photoViewOverlay').scrollTo(0, 0);
}

function buildCommentSection(cell, rebuildCarousel = false) {
  document.getElementById('defaultPhotoComment').classList.add('hidden');
  $('div.photo-comment-container:not(#defaultPhotoComment)').remove();
  window.displayedPhotoId = cell.getAttribute('photo-id');
  document.body.classList.add('hidden-overflow');
  let photoViewOverlay = document.getElementById('photoViewOverlay');
  photoViewOverlay.classList.add('visible-overlay');
  let mainPhotoViewer = document.getElementById('mainPhotoViewer');
  $('#mainPhotoViewer img.full-sized-photo').remove();
  let photoImgContainer = mainPhotoViewer.querySelector('div.photo-img-container');
  if(photoImgContainer == null) {
    photoImgContainer = document.createElement('div');
    photoImgContainer.className = 'photo-img-container';
    mainPhotoViewer.appendChild(photoImgContainer);
  } else {
    photoImgContainer.querySelector('br').remove();
  }
  let photo = photoFolders[activeFolder.id].photos[cell.getAttribute('photo-id')];
  updateUploaderData(photo);
  if(rebuildCarousel) {
    mainPhotoViewer.classList.replace('large-12', 'large-6');
    photoViewOverlay.classList.remove('full-screen-view');
    photoViewOverlay.classList.add('side-view');
    buildPhotoCarousel(photo, photoImgContainer);
  }
  photoImgContainer.appendChild(document.createElement('br'));
  let photoImg = document.createElement('img');
  photoImg.className = 'full-sized-photo no-text-select';
  photoImg.style = 'max-width: 100%; width: 100%;';
  // width: 100%; or ...
  // height: 750px; or heigth: 545px;
  // object-fit: contain; or ...
  photoImg.setAttribute('photo_id', photo.id);
  photoImg.onclick = toggleFullScreenView;
  getPhotoURL(photo.id, isSharedCollection ?sharedLinkOptions.tenantId :null)
    .then(signedUrl => {
    if(signedUrl !== null) {
      photoImg.src = signedUrl;
      photoImgContainer.appendChild(photoImg);
      if(photo.approval) {
        let verifiedWrapper = document.createElement('img');
        verifiedWrapper.style = 'position: absolute; top: 5%; left: 20%; max-width: 50px; z-index: 5;';
        verifiedWrapper.src = '/assets/img/verified.jpg';
        verifiedWrapper.setAttribute('alt', photo.name);
        photoImgContainer.appendChild(verifiedWrapper);
      }
    } else {
      swalToast({
        type: 'error',
        title: i18n.t("js.photos.wrong2")
      });
    }
  });
  let textarea = document.getElementById('newCommentArea');
  textarea.disabled = true;
  commentIdMap = {};
  let activity = photoFolders[activeFolder.id].photos[photo.id].activity;
  fetchComments(photo.id).then(comments => {
    if(!comments || !comments.length) {
      showDefaultComment();
    } else {
      comments.filter(c => c.parentId == null || c.parentId == '')
      .sort((c1, c2) => parseInt(c2.timestamp) - parseInt(c1.timestamp))
      .forEach(comment => {
        insertComment(comment);
      });
      comments.forEach(comment => {
        commentIdMap[comment.id] = comment;
      });
      let parentIdMap = {};
      comments.filter(c => c.parentId != null && c.parentId != '')
      .forEach(comment => {
        let deepestParentId = getDeepestParentId(comment);
        if(!Array.isArray(parentIdMap[deepestParentId])) {
          parentIdMap[deepestParentId] = [];
        }
        parentIdMap[deepestParentId].push(comment);
      });
      for(let entry of Object.entries(parentIdMap)) {
        entry[1].sort((c1, c2) => parseInt(c1.timestamp) - parseInt(c2.timestamp))
        .forEach(comment => {
          insertComment(comment, entry[0]);
        });
      }
    }
    if(!croogloo_auth.isSharedAccess && (!isSharedCollection
      || sharedLinkOptions.accessType !== LinkAccess.VIEW_ONLY)) {
      textarea.disabled = false;
    }
    activity.lastViewed = new Date().getTime();
    updatePhotoActivity(activity);
    let notification = cell.querySelector('div.photo-notification');
    if(notification !== null) {
      $(notification).remove();
    }
  });
  let newCommentContainer = document.getElementById('newCommentContainer');
  let areThumbsEnabled = (!croogloo_auth.isSharedAccess && (!isSharedCollection
    || sharedLinkOptions.accessType !== LinkAccess.VIEW_ONLY));
  let thumbsUp = document.querySelector('div.large-thumb.large-thumbs-up');
  if(activity.userFeedback == FEEDBACK_LIKED) {
    thumbsUp.classList.add('selected-vote');
  } else {
    thumbsUp.classList.remove('selected-vote');
  }
  if(!areThumbsEnabled) { thumbsUp.classList.add('disabled-options'); }
  thumbsUp.querySelector('span').textContent = activity.nbOfLikes.toString()||'0';
  if(areThumbsEnabled) {
    thumbsUp.onclick = function(event) {
      updateVoteCount('nbOfLikes', FEEDBACK_LIKED, 'nbOfDislikes', FEEDBACK_DISLIKED, activity, newCommentContainer, this);
      this.classList.toggle('selected-vote');
      reflectOnCellThumbs();
    }
  }
  let thumbsDown = document.querySelector('div.large-thumb.large-thumbs-down');
  if(activity.userFeedback == FEEDBACK_DISLIKED) {
    thumbsDown.classList.add('selected-vote');
  } else {
    thumbsDown.classList.remove('selected-vote');
  }
  if(!areThumbsEnabled) { thumbsDown.classList.add('disabled-options'); }
  thumbsDown.querySelector('span').textContent = activity.nbOfDislikes.toString()||'0';
  if(areThumbsEnabled) {
    thumbsDown.onclick = function(event) {
      updateVoteCount('nbOfDislikes', FEEDBACK_DISLIKED, 'nbOfLikes', FEEDBACK_LIKED, activity, newCommentContainer, this);
      this.classList.toggle('selected-vote');
      reflectOnCellThumbs();
    }
  }
  function reflectOnCellThumbs() {
    let cellThumbsUp = cell.querySelector('div.thumbs-up-btn');
    let cellThumbsDown = cell.querySelector('div.thumbs-down-btn');
    for(let pair of [[thumbsUp, cellThumbsUp], [thumbsDown, cellThumbsDown]]) {
      if(pair[0].classList.contains('selected-vote') !== pair[1].classList.contains('selected-vote')) {
        pair[1].classList.toggle('selected-vote');
      }
      pair[1].querySelector('span.vote-count').textContent = pair[0].querySelector('span.vote-count').textContent;
    }
  }
}

function getPhotoURL(fileId, tenantId = null) {
  return new Promise(resolve => {
		let urlParams = { fileId: fileId };
		if(tenantId !== null) {
			urlParams.tenantId = tenantId;
		}
		apicall('documentsapi', 'fetchPhotoURL', urlParams)
			.then(resp => {
				if(resp.responseCode == '0') {
					resolve(resp.responseMessage);
				} else {
					console.debug(resp.responseMessage);
					resolve(null);
				}
			}, function(rejection) {
				throw rejection;
			}).catch(err => {
				cgToast(i18n.t("js.photos.failed-fetch"));
			});
	});
}

async function updateUploaderData(photo) {
  let uploaderDataElem = document.getElementById('uploaderData');
  if(photo.uploaderData === null) {
    uploaderDataElem.style.display = 'none';
    photo.uploaderData = await getUploaderData(photo);
  }
  if(photo.uploaderData !== null) {
    uploaderDataElem.querySelector('div.uploader-name > b').textContent = photo.uploaderData.name;
    uploaderDataElem.querySelector('div.photo-description').textContent = photo.description;
    uploaderDataElem.style.display = '';
  }
}

function updateLatestComments(photoIds) {
  let folderId = activeFolder.id;
  fetchLatestComments(photoIds)
  .then(cgComments => {
    if(folderId !== activeFolder.id) {
      console.warn('active folder changed, ignoring API callback');
      return;
    }
    console.debug('found ' + cgComments.length.toString() + ' latest comments');
    let updatedPhotoIds = [];
    for(let cgComment of cgComments) {
      let photoObj = photoFolders[folderId].photos[cgComment.photoId];
      let content = formatCommentContent(cgComment, (typeof cgComment.parentAuthorName == 'string'
        && cgComment.parentAuthorName.length) ? cgComment.parentAuthorName : null);
      photoObj.updateLatestComment(cgComment.authorName || null, content || null);
      updatedPhotoIds.push(cgComment.photoId);
    }
    for(let photoId of photoIds) {
      if(!updatedPhotoIds.includes(photoId)) {
        let photoObj = photoFolders[folderId].photos[photoId];
        photoObj.updateLatestComment(null, null);
      }
    }
  });
}

function updateAllLatestComments() {
  if(!isGridMode()) {
    updateLatestComments(Object.values(photoFolders[activeFolder.id].photos).map(p => p.id));
  }
}

function getUploaderData(photo) {
  return new Promise(function(resolve, reject) {
    let urlParams = { photoId: photo.id };
    if(isSharedCollection) {
      urlParams.tenantId = sharedLinkOptions.tenantId;
      urlParams.token = sharedLinkOptions.userToken;
    }
    apicall('documentsapi', 'fetchPhotoUploaderData', urlParams)
    .then(resp => {
      if(resp !== null && typeof resp == 'object'
        && resp.hasOwnProperty('name') && resp.hasOwnProperty('description')) {
        resolve(window.displayedPhotoId === photo.id
          ? { name: resp.name }
          : null);
      } else if(resp !== null && typeof resp == 'object'
          && resp.hasOwnProperty('status') && resp.status === 204) {
        console.debug('server missing uploader data');
        resolve(null);
      } else {
        console.error(resp);
        throw new Error('Invalid uploader data response');
      }
    }).catch(err => {
      console.error(err);
      resolve(null);
    });
  });
}

function buildPhotoCarousel(photo, container) {
  $('#photoCarousel').remove();
  let photoCarousel = document.createElement('div');
  photoCarousel.id = 'photoCarousel';
  photoCarousel.className = 'photo-carousel';
  for(let i = 0; i < 2; i++) {
    let carouselShadow = document.createElement('div');
    carouselShadow.className = 'carousel-shadow';
    photoCarousel.appendChild(carouselShadow);
  }
  window.navArrowContainers = [];
  for(let i = 0; i < 2; i++) {
    let navArrowSuperContainer = document.createElement('div');
    navArrowSuperContainer.className = 'nav-arrow-super-container';
    navArrowSuperContainer.classList.add(i % 2 == 0 ?'left-arrow' :'right-arrow');
    let navArrowContainer = document.createElement('div');
    navArrowContainer.className = 'navigation-arrow-container';
    navArrowContainer.classList.add(i % 2 == 0 ?'left-arrow' :'right-arrow');
    let navArrowInnerContainer = document.createElement('div');
    let navArrow = document.createElement('div');
    navArrow.className = 'navigation-arrow';
    navArrow.onclick = function() {
      if(navArrowContainer.classList.contains('left-arrow')) {
        showPrevious();
      } else {
        showNext();
      }
    }
    navArrowInnerContainer.appendChild(navArrow);
    navArrowContainer.appendChild(navArrowInnerContainer);
    navArrowSuperContainer.appendChild(navArrowContainer);
    photoCarousel.appendChild(navArrowSuperContainer);
    window.navArrowContainers.push(navArrowSuperContainer);
  }

  let carouselImgContainer = document.createElement('div');
  carouselImgContainer.className = 'carousel-img-container';
  photoCarousel.appendChild(carouselImgContainer);
  let carouselImgInnerContainer = document.createElement('div');
  carouselImgContainer.appendChild(carouselImgInnerContainer);
  let sortedPhotos = Object.values(photoFolders[activeFolder.id].photos)
    .sort((p1, p2) => p1.name.localeCompare(p2.name));
  let photoIdx = sortedPhotos.indexOf(photo);
  let isCarouselVisible = sortedPhotos.length > 1;
  let areCarouselNavArrowsVisible;
  refillCarousel(photoIdx);

  function refillCarousel(selectedPhotoIdx) {
    for(let thumbnailItem of document.querySelectorAll('img.carousel-thumbnail')) {
      thumbnailItem.remove();
    }
    if(!isCarouselVisible) {
      areCarouselNavArrowsVisible = false;
      photoCarousel.style.display = 'none';
    } else if(sortedPhotos.length <= 5) {
      areCarouselNavArrowsVisible = false;
      for(let i = 0; i < sortedPhotos.length; i++) {
        addCarouselThumbnail(sortedPhotos[i], i);
      }
      for(let navArrowContainer of window.navArrowContainers) {
        navArrowContainer.style.display = 'none';
      }
    } else {
      areCarouselNavArrowsVisible = true;
      for(let i = selectedPhotoIdx - 2; i <= selectedPhotoIdx + 2; i++) {
        let validIdx = validPhotoIndex(i);
        addCarouselThumbnail(sortedPhotos[validIdx], validIdx);
      }
    }
  }

  photoCarousel.onclick = function(event) {
    console.debug(event);
    try {
      if(event.target.tagName.toLowerCase() === 'img'
        && !event.target.classList.contains('selected-thumbnail')) {
        let previouslySelectedTumhbnail = photoCarousel
          .querySelector('img.carousel-thumbnail.selected-thumbnail');
        if(previouslySelectedTumhbnail !== null) {
          previouslySelectedTumhbnail.classList.remove('selected-thumbnail');
        }
        event.target.classList.add('selected-thumbnail');
        buildCommentSection(document.querySelector('div.photo-cell[photo-id="'
          + event.target.getAttribute('photo-id') + '"]'))
      }
    } catch(e1) {
      console.error(e1)
    }
  }

  container.appendChild(photoCarousel);

  let leftOverlayNavArrow = document.querySelector('#photoOverlayLeftNav > i');
  let rightOverlayNavArrow = document.querySelector('#photoOverlayRightNav > i');
  if(isCarouselVisible) {
    leftOverlayNavArrow.classList.remove('hidden');
    rightOverlayNavArrow.classList.remove('hidden');
    leftOverlayNavArrow.onclick = function() {
      onOverlayNavArrowClick(false);
    }
    rightOverlayNavArrow.onclick = function() {
      onOverlayNavArrowClick(true);
    }
  } else {
    leftOverlayNavArrow.classList.add('hidden');
    rightOverlayNavArrow.classList.add('hidden');
  }

  document.addEventListener('keydown', navigationKeyEventCallback);  
  document.getElementById('photoOverlayCloseBtn').onclick = function() {
    photoViewOverlay.classList.remove('visible-overlay');
    document.body.classList.remove('hidden-overflow');
    document.removeEventListener('keydown', navigationKeyEventCallback);
  };
  let textarea = document.getElementById('newCommentArea');
  textarea.addEventListener('focus', function() {
    document.removeEventListener('keydown', navigationKeyEventCallback);
  });
  textarea.addEventListener('blur', function() {
    document.addEventListener('keydown', navigationKeyEventCallback);
  });

  function navigationKeyEventCallback(e) {
    switch(e.keyCode) {
      case 37:
        console.log('left');
        leftOverlayNavArrow.onclick();
        break;
      case 39:
        console.log('right');
        rightOverlayNavArrow.onclick();
        break;
    }
  }

  function onOverlayNavArrowClick(isForwardNavigation) {
    let newSelectedImgIdx = findNewSelectedImgIdx(isForwardNavigation);
    if(areCarouselNavArrowsVisible) {
      refillCarousel(newSelectedImgIdx);
    }
    changeCarouselSelection(newSelectedImgIdx);
  }

  function addCarouselThumbnail(photoObj, idx, insertBeforeElem = null) {
    let carouselThumbnail = document.createElement('img');
    carouselThumbnail.src = photoObj.thumbnailURL;
    carouselThumbnail.className = 'carousel-thumbnail no-text-select'
    carouselThumbnail.setAttribute('photo-id', photoObj.id);
    carouselThumbnail.setAttribute('photo-idx', idx.toString());
    carouselThumbnail.title = photoObj.name;
    $(carouselThumbnail).tooltip();
    if(photoObj.id === window.displayedPhotoId) {
      carouselThumbnail.classList.add('selected-thumbnail');
    }
    if(insertBeforeElem !== null) {
      carouselImgInnerContainer.insertBefore(carouselThumbnail, insertBeforeElem);
    } else {
      carouselImgInnerContainer.appendChild(carouselThumbnail);
    }
  }

  function showNext() {
    let carouselThumnails = document.querySelectorAll('img.carousel-thumbnail');
    let farRightPhotoIdx = parseInt(carouselThumnails[carouselThumnails.length-1].getAttribute('photo-idx'));
    let nextPhotoIdx = validPhotoIndex(farRightPhotoIdx + 1);
    addCarouselThumbnail(sortedPhotos[nextPhotoIdx], nextPhotoIdx);
    carouselThumnails[0].remove();
  }

  function showPrevious() {
    let carouselThumnails = document.querySelectorAll('img.carousel-thumbnail');
    let farLeftPhotoIdx = parseInt(carouselThumnails[0].getAttribute('photo-idx'));
    let previousPhotoIdx = validPhotoIndex(farLeftPhotoIdx - 1);
    addCarouselThumbnail(sortedPhotos[previousPhotoIdx], previousPhotoIdx, carouselThumnails[0]);
    carouselThumnails[carouselThumnails.length - 1].remove();
  }

  function changeCarouselSelection(newSelectedImgIdx) {
    let newSelectedImg = document
      .querySelector(`#photoCarousel img.carousel-thumbnail[photo-idx="${newSelectedImgIdx.toString()}"]`);
    newSelectedImg.click();
  }

  function findNewSelectedImgIdx(isForwardNavigation) {
    let selectedPhotoId = document.querySelector('#photoViewOverlay img.full-sized-photo').getAttribute('photo_id');
    let selectedImgIndex = sortedPhotos.findIndex(p => p.id === selectedPhotoId);
    return validPhotoIndex(selectedImgIndex + (isForwardNavigation ?1 :-1));
  }

  function validPhotoIndex(idx) {
    return (idx >= 0 && idx < sortedPhotos.length)
      ?idx
      :idx < 0
        ?(idx + sortedPhotos.length)
        :(idx - sortedPhotos.length);
  }
}

function saveNewComment(textarea, cancelBtn, parentId = null, onSuccess = null) {
  let isNewCommentEnabled = (!croogloo_auth.isSharedAccess && (!isSharedCollection
    || sharedLinkOptions.accessType !== LinkAccess.VIEW_ONLY));
  if(!isNewCommentEnabled) { throw new Error('saveNewComment access denied.'); }
  if(textarea.value.trim() && !textarea.disabled) {
    textarea.disabled = true;
    cancelBtn.classList.add('unauthorized');
    let now = new Date();
    let cgComment = {
      id: uuid(),
      parentId: parentId,
      photoId: window.displayedPhotoId,
      timestamp: now.getTime(),
      authorToken: croogloo_auth.usertoken,
      content: textarea.value.trim()
    };
    let urlParams = {};
    if(isSharedCollection) {
      if(croogloo_auth.isSharedAccess) {
        throw new Error('Must log in to perform this action.');
      }
      urlParams.tenantId = sharedLinkOptions.tenantId;
    }
    apicall('documentsapi', 'insertPhotoComment', urlParams, cgComment)
      .then(resp => {
      console.debug(resp);
      if(resp && resp.responseCode && resp.responseCode === '0') {
        cgComment.authorName = resp.responseMessage;
        commentIdMap[cgComment.id] = cgComment;
        document.getElementById('defaultPhotoComment').classList.add('hidden');
        insertComment(cgComment,
          parentId == null ?null :getDeepestParentId(cgComment),
          parentId == null ?'commentInputSeparator' :null
        );
        textarea.value = '';
        textarea.disabled = false;
        cancelBtn.classList.remove('unauthorized');
        if(typeof onSuccess === 'function') { onSuccess(cgComment); }
      } else {
        throw new Error('Invalid response to comment insertion');
      }
    }).catch(err => {
      console.error(err);
      swalToast({
        type: 'error',
        title: i18n.t("js.photos.failed.comment.save")
      });
      textarea.disabled = false;
      cancelBtn.classList.remove('unauthorized');
    });
  }
}

function editComment(textarea, cancelBtn, commentId, onSuccess = null) {
  if(textarea.value.trim() && !textarea.disabled) {
    textarea.disabled = true;
    cancelBtn.classList.add('unauthorized');
    let now = new Date();
    let cgComment = Object.assign({}, commentIdMap[commentId]); // losing reference
    cgComment.content = textarea.value.trim();
    cgComment.edited = true;
    let urlParams = {};
    if(isSharedCollection) {
      if(croogloo_auth.isSharedAccess) {
        throw new Error('Must log in to perform this action.');
      }
      urlParams.tenantId = sharedLinkOptions.tenantId;
    }
    apicall('documentsapi', 'editPhotoComment', urlParams, cgComment)
      .then(resp => {
      console.debug(resp);
      if(resp && resp.responseCode && resp.responseCode === '0') {
        commentIdMap[cgComment.id] = cgComment;
        let editedCommentContainer = document.querySelector('div.photo-comment-container[comment-id="'+cgComment.id+'"]');
        editedCommentContainer.querySelector('div.comment-content').innerHTML 
          = formatCommentContent(cgComment);
        let commentTime = editedCommentContainer.querySelector('div.comment-time');
        if(!commentTime.textContent.endsWith(i18n.t("js.photos.edited.suffix"))) {
          commentTime.textContent += i18n.t("js.photos.edited.suffix");
        }
        textarea.value = '';
        textarea.disabled = false;
        cancelBtn.classList.remove('unauthorized');
        if(typeof onSuccess === 'function') { onSuccess(cgComment); }
      } else {
        throw new Error('Invalid response to comment insertion');
      }
    }).catch(err => {
      console.error(err);
      swalToast({
        type: 'error',
        title: i18n.t("js.photos.failed.comment.edit")
      });
      textarea.disabled = false;
      cancelBtn.classList.remove('unauthorized');
    });
  }
}

function adaptBreadcrum() {
  let breadcrum = document.getElementById('breadcrum');
  if(folderPath.length == 1){
    let pageTitle = document.createElement('h2');
    pageTitle.textContent = i18n.t("js.photos.default");
    breadcrum.appendChild(pageTitle);
  }else{
    for(let i = 0, folder; folder = folderPath[i++];) {
      let folderSpan = document.createElement('h2');
      folderSpan.className = 'folder-crum';
      folderSpan.textContent = folder.name;
      let folderIndex = i; // must use local variable that won't be incremented
      folderSpan.onclick = function() {
        if(folderIndex != folderPath.length) {
          loadFolder(folderPath.slice(0, folderIndex));
        }
      }
      breadcrum.appendChild(folderSpan);
      if(i !== folderPath.length) {
        let separator = document.createElement('h2');
        separator.innerHTML = '&nbsp;/&nbsp;';
        breadcrum.appendChild(separator);
      } else {
        folderSpan.classList.add('opened-folder');
      }
    }
  }
}

function loadFolder(folderPathArr) {
  sessionStorage.setItem('active-photo-folder', JSON.stringify(folderPathArr));
  sessionStorage.setItem('photo-view-mode', viewMode);
  // Load page for selected folder
  r.reload();
  
}

/**
 * Retrieve the latest comment from every provided photo ID.
 * @param {Array} photoIds 
 */
function fetchLatestComments(photoIds) {
  return new Promise(resolve => {
    let urlParams = {};
    if(isSharedCollection) {
      urlParams.tenantId = sharedLinkOptions.tenantId;
      urlParams.token = sharedLinkOptions.userToken;
    }
    apicall('documentsapi', 'fetchLatestPhotoComments', urlParams, {
      id: photoIds.join(',')
    }).then(resp => {
      if(!Array.isArray(resp.items)) {
        throw new Error('Response is not an array', resp);
      } else {
        resolve(resp.items);
      }
    }).catch(err => {
      console.error(err);
      swalToast({
        type: 'error',
        title: i18n.t("js.photos.failed.comment.fetch")
      });
      resolve([]);
    });
  });
}

function fetchComments(photoId) {
  return new Promise(resolve => {
    let urlParams = { photoId: photoId };
    if(isSharedCollection) {
      urlParams.tenantId = sharedLinkOptions.tenantId;
      urlParams.token = sharedLinkOptions.userToken;
    }
    apicall('documentsapi', 'fetchPhotoComments', urlParams).then(resp => {
      if(!Array.isArray(resp.items)) {
        throw new Error('Response is not an array', resp);
      } else {
        resolve(resp.items);
      }
    }).catch(err => {
      console.error(err);
      swalToast({
        type: 'error',
        title: i18n.t("js.photos.failed.comment.fetch-def")
      });
      resolve([]);
    });
  });
}

var DEFAULT_FOLDER;

const DEFAULT_ACTIVITY = {
  lastViewed: 0,
  nbOfDislikes: 0,
  nbOfLikes: 0,
  nbOfUnreadComments: 0,
  userFeedback: "none"
}

function getFolderPath() {
  let localFolderPath;
  try {
    let activeFolderJSON = sessionStorage.getItem('active-photo-folder');
    console.debug(activeFolderJSON);
    if(activeFolderJSON == null) {
      localFolderPath = [DEFAULT_FOLDER];
    }
    let storedFolderPath =  JSON.parse(activeFolderJSON);
    if(storedFolderPath && storedFolderPath != null
      && Array.isArray(storedFolderPath)) {
      localFolderPath = storedFolderPath;
    } else {
      localFolderPath = [DEFAULT_FOLDER];
    }
  } catch(err) {
    console.error(err);
    localFolderPath = [DEFAULT_FOLDER];
  }
  if(localFolderPath.length == 1) {
    sessionStorage.setItem('active-photo-folder', JSON.stringify(localFolderPath));
  }
  return localFolderPath;
}

function getDataAndDisplayPhotos() {
  let requestTime = new Date().getTime();
  fetchPhotoEntities(() => {
    setTimeout(function() {
      loadTime.entities = calcAndFormatLoadTime();
      displayPhotos();
      loader.check('photo-entities');
    }, 0);
  });
  fetchPhotoActivity(() => {
    setTimeout(function() {
      loadTime.activities = calcAndFormatLoadTime();
      loader.check('photo-activity');
    }, 0);
  });
  function calcAndFormatLoadTime() {
    return ((new Date().getTime() - requestTime) / 1000).toFixed(2).toString() + 's';
  }
}

function fetchPhotoEntities(done) {
  let folderId = activeFolder.id;
  let urlParams = {
    folderId: folderId,
    subcategory: 'photo'
  };
  if(isSharedCollection) {
    urlParams.tenantId = sharedLinkOptions.tenantId;
    urlParams.token = sharedLinkOptions.userToken;
  }
  apicall('documentsapi', 'fetchItemsInFolderBySubcategory', urlParams).then(resp => {
    if(folderId !== activeFolder.id) {
      console.warn('active folder changed, ignoring API callback');
      return;
    }
    try {
      photoFolders[folderId] = {
        folders: [],
        photos: []
      };
      for(let item of resp.items) {
        try {
          let itemKey = item.key.name || item.key.id;
          if(typeof item.properties.subType == 'string' && item.properties.subType.toLowerCase() == 'folder') {
            photoFolders[folderId].folders[itemKey] = new Folder(item, itemKey);
          } else {
            photoFolders[folderId].photos[itemKey] = new Photo(item, itemKey);
          }
        } catch(e1) {
          console.error(e1);
        }
      }
      done();
    } catch(e2) {
      console.error(e2);
      swalToast({
        type: 'error',
        title: i18n.t("js.photos.wrong3")
      });
      done();
    }
  }).catch(err => {
    console.error(err);
    if(![DEFAULT_FOLDER.id, 'ROOT'].includes(folderId)) {
      sessionStorage.setItem('active-photo-folder', JSON.stringify(DEFAULT_FOLDER));
      // load page with new active photo folder
      r.reload();
    } else {
      swalToast({
        type: 'error',
        title: i18n.t("js.photos.failure")
      });
      done();
    }
  });
}

function fetchPhotoActivity(done) {
  let folderId = activeFolder.id;
  let urlParams = { folderId: folderId };
  if(isSharedCollection) {
    urlParams.tenantId = sharedLinkOptions.tenantId;
  }
  apicall('documentsapi', 'fetchPhotoActivity', urlParams).then(resp => {
    if(folderId !== activeFolder.id) {
      console.warn('active folder changed, ignoring API callback');
      return;
    }
    try {
      for(let item of resp.items) {
        try {
          photoActivities.push(item);
        } catch(e1) {
          console.error(e1);
        }
      }
      done();
    } catch(e2) {
      console.error(e2);
      done();
    }
  }).catch(err => {
    console.error(err);
    done();
  });
}

function syncPhotoActivity() {
  for(let activity of photoActivities) {
    try {
      photoFolders[activeFolder.id].photos[activity.photoId].activity = activity;
    } catch(err) {
      console.error(err);
    }
  }
}

function addPhotoActivityUILayer(affectedCells = null) {
  for(let cell of affectedCells || document.querySelectorAll('div.photo-cell')) {
    try {
      let activity = photoFolders[activeFolder.id]
        .photos[cell.getAttribute('photo-id')].activity;

      let photoBox = cell.querySelector('div.photo-box');

      if(typeof activity.nbOfUnreadComments === 'number'
        && activity.nbOfUnreadComments && !croogloo_auth.isSharedAccess) {
        let notification = document.createElement('div');
        notification.className = 'photo-notification';
        notification.textContent = activity.nbOfUnreadComments > 9 ? '9+':activity.nbOfUnreadComments.toString();
        photoBox.appendChild(notification);
      }

      let areThumbsEnabled = (!croogloo_auth.isSharedAccess && (!isSharedCollection
        || sharedLinkOptions.accessType !== LinkAccess.VIEW_ONLY));

      let photoVoteBar = document.createElement('div');
      photoVoteBar.className = 'photo-option-bar vote-bar ' + viewModeClass;
      let photoVotesContainer = document.createElement('div');
      let thumbsUp = document.createElement('div');
      thumbsUp.appendChild(buildIconElem('fa fa-thumbs-up'));
      thumbsUp.appendChild(buildVoteCountSpan(activity.nbOfLikes));
      thumbsUp.className = 'photo-option-btn thumbs-up-btn ' + viewModeClass;
      if(activity.userFeedback == FEEDBACK_LIKED) {
        thumbsUp.classList.add('selected-vote');
      }
      if(areThumbsEnabled) {
        thumbsUp.title = i18n.t("js.media.like");
        $(thumbsUp).tooltip();
      }
      let thumbsDown = document.createElement('div');
      thumbsDown.appendChild(buildIconElem('fa fa-thumbs-down'));
      thumbsDown.appendChild(buildVoteCountSpan(activity.nbOfDislikes));
      thumbsDown.className = 'photo-option-btn thumbs-down-btn ' + viewModeClass;
      if(activity.userFeedback == FEEDBACK_DISLIKED) {
        thumbsDown.classList.add('selected-vote');
      }
      if(areThumbsEnabled) {
        thumbsDown.title = i18n.t("js.media.dislike");
        $(thumbsDown).tooltip();
      }
      if(areThumbsEnabled) {
        photoVoteBar.onclick = function(event) {
          event.stopImmediatePropagation();
          let clickedThumb;
          let $clickedThumb = $(event.target).closest('div.photo-option-btn');
          if($clickedThumb.length) {
            clickedThumb = $clickedThumb[0];
            if(clickedThumb.classList.contains('thumbs-up-btn')) {
              updateVoteCount('nbOfLikes', FEEDBACK_LIKED, 'nbOfDislikes', FEEDBACK_DISLIKED, activity, photoVoteBar, clickedThumb);
            } else {
              updateVoteCount('nbOfDislikes', FEEDBACK_DISLIKED, 'nbOfLikes', FEEDBACK_LIKED, activity, photoVoteBar, clickedThumb);
            }
            clickedThumb.classList.toggle('selected-vote');
          }
        };
      } else {
        photoVoteBar.classList.add('disabled-options');
      }

      photoVotesContainer.appendChild(thumbsUp);
      photoVotesContainer.appendChild(thumbsDown);
      photoVoteBar.appendChild(photoVotesContainer);

      if(isGridMode()) {
        photoBox.appendChild(photoVoteBar);
      } else {
        let optionBarSeparator = document.createElement('div');
        optionBarSeparator.className = 'option-bar-separator';
        optionBarSeparator.textContent = '|';

        cell.querySelector('div.photo-title').insertBefore(photoVoteBar, cell.querySelector('div.photo-option-bar'));
        cell.querySelector('div.photo-title').insertBefore(optionBarSeparator, cell.querySelector('div.photo-option-bar:not(.vote-bar)'));
      }

      function buildIconElem(className) {
        let icon = document.createElement('i');
        icon.className = className;
        return icon;
      }

      function buildVoteCountSpan(nbValue) {
        let span = document.createElement('span');
        span.classList.add('vote-count');
        span.classList.add('grid-count');
        span.textContent = nbValue.toString()||'0';
        return span;
      }

    } catch(e) {
      console.error(e);
    }
  }
}

function updateVoteCount(targetActivityProp, targetFeedback, otherActivityProp, otherFeedback, activity, thumbsContainer, clickedThumb) {
  if(activity.userFeedback == otherFeedback) {
    activity[otherActivityProp]--;
    let selectedVoteElem = thumbsContainer.querySelector('div.selected-vote');
    selectedVoteElem.classList.toggle('selected-vote');
    selectedVoteElem.querySelector('span').textContent = activity[otherActivityProp].toString();
  }
  let newUserFeedback = activity.userFeedback == targetFeedback ? FEEDBACK_NONE:targetFeedback;
  activity.userFeedback = newUserFeedback;
  activity[targetActivityProp] += newUserFeedback == targetFeedback ? 1:-1;
  clickedThumb.querySelector('span.vote-count').textContent = activity[targetActivityProp].toString();
  updatePhotoActivity(activity);
}

function updatePhotoActivity(activity) {
  activity.lastViewed = parseInt(activity.lastViewed); // can have been converted to a String
  let urlParams = {};
  if(isSharedCollection) {
    if(croogloo_auth.isSharedAccess) { return; }
    urlParams.tenantId = sharedLinkOptions.tenantId;
  }
  apicall('documentsapi', 'updatePhotoActivity', urlParams, activity).then(resp => {
    if(resp.responseCode === '0') {
      console.debug('successfully updated photo activity')
    } else {
      throw new Error('Failed to update photo activity');
    }
  }).catch(err => {
    console.error(err);
  });
}

function insertComment(comment, deepestParentId = null, insertAfterId = null) {
  try {
    insertAfterId = typeof insertAfterId === 'string'
      ?insertAfterId
      :(typeof deepestParentId === 'string'
        ?(findLatestReply()||document.querySelector('div.photo-comment-container[comment-id="'+deepestParentId+'"]').id)
        :null)
    let commentElement = copyBlueprint('defaultPhotoComment', typeof insertAfterId === 'string', insertAfterId);
    if(deepestParentId != null) {
      commentElement.classList.add('reply-comment');
    }
    let avatarContainer = commentElement.querySelector('div.comment-avatar');
    avatarContainer.querySelector('img').remove();
    let avatar = document.createElement('div');
    avatar.classList.add('initial-avatar', 'no-text-select');
    avatar.textContent = comment.authorName[0].toUpperCase();
    avatarContainer.appendChild(avatar);
    commentElement.querySelector('div.comment-author').textContent = comment.authorName;
    commentElement.querySelector('div.comment-time').textContent = formatCommentTime(comment.timestamp)
      + (comment.isEdited ?i18n.t("js.photos.edited.suffix") :'');
    commentElement.querySelector('div.comment-content').innerHTML = formatCommentContent(comment);
    let canReply = (!croogloo_auth.isSharedAccess && (!isSharedCollection
      || sharedLinkOptions.accessType !== LinkAccess.VIEW_ONLY));
    if(canReply) {
      let replyBtn = commentElement.querySelector('div.comment-reply');
      replyBtn.classList.remove('unauthorized');
      replyBtn.onclick = function() {
        let existingReplyCommentArea = document.querySelector('div.comment-area-container[for="'+comment.id+'"]');
        if(existingReplyCommentArea !== null) {
          if(!existingReplyCommentArea.classList.contains('edit-area')) {
            return;
          } else {
            $(existingReplyCommentArea).remove();
          }
        }
        let commentArea = copyBlueprint('newCommentContainer', false);
        commentArea.querySelector('div.large-thumb.large-thumbs-up').remove();
        commentArea.querySelector('div.large-thumb.large-thumbs-down').remove();
        commentArea.setAttribute('for', comment.id);
        for(let child of commentArea.children) {
          child.removeAttribute('id');
        }
        let textarea = commentArea.querySelector('textarea');
        let addCommentBtn = commentArea.querySelector('a.add-comment-btn');
        let cancelBtn = commentArea.querySelector('a.comment-cancel');
        addCommentBtn.onclick = function() {
          saveNewComment(textarea, cancelBtn, comment.id, function(cgComment) {
            $(commentArea).remove();
            updateLatestCommentsWithDelay(cgComment.photoId);
          });
        }
        cancelBtn.onclick = function() {
          tryToHideCommentArea(commentArea, textarea);
        }
        commentArea.style.display = 'none';
        commentElement.querySelector('div.comment-body').appendChild(commentArea);
        $(commentArea).slideDown(400);
        setTimeout(function() {
          textarea.focus();
        }, 450); // must be focused only when slideDown is done
      }
      if(comment.authorToken === croogloo_auth.usertoken) {
        let editBtn = commentElement.querySelector('div.comment-edit');
        editBtn.classList.remove('hidden');
        editBtn.querySelector('i').onclick = function() {
          let existingReplyCommentArea = document.querySelector('div.comment-area-container[for="'+comment.id+'"]');
          if(existingReplyCommentArea !== null) {
            if(existingReplyCommentArea.classList.contains('edit-area')) {
              return;
            } else {
              $(existingReplyCommentArea).remove();
            }
          }
          let commentArea = copyBlueprint('newCommentContainer', false);
          commentArea.querySelector('div.large-thumb.large-thumbs-up').remove();
          commentArea.querySelector('div.large-thumb.large-thumbs-down').remove();
          commentArea.classList.add('edit-area');
          commentArea.setAttribute('for', comment.id);
          for(let child of commentArea.children) {
            child.removeAttribute('id');
          }
          let textarea = commentArea.querySelector('textarea');
          textarea.value = commentIdMap[comment.id].content;
          let editCommentBtn = commentArea.querySelector('a.add-comment-btn');
          editCommentBtn.innerHTML = '<i class="fa fa-pencil"></i>';
          let cancelBtn = commentArea.querySelector('a.comment-cancel');
          editCommentBtn.onclick = function() {
            editComment(textarea, cancelBtn, comment.id, function(cgComment) {
              $(commentArea).remove();
              updateLatestCommentsWithDelay(cgComment.photoId);
            });
          }
          cancelBtn.onclick = function() {
            tryToHideCommentArea(commentArea, textarea);
          }
          commentArea.style.display = 'none';
          commentElement.querySelector('div.comment-body').appendChild(commentArea);
          $(commentArea).slideDown(400);
          setTimeout(function() {
            textarea.focus();
            textarea.select();
          }, 450); // must be focused only when slideDown is done
        }
        let removeBtn = commentElement.querySelector('div.comment-remove');
        removeBtn.classList.remove('hidden');
        removeBtn.querySelector('i').onclick = function() {
          swal({
            title: i18n.t("js.comment.delete.title"),
            text: i18n.t("js.comment.delete.text"),
            type: 'warning',
            confirmButtonText: i18n.t("utils.delete"),
            confirmButtonColor: '#FF4136',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            showCloseButton: true,
            allowOutsideClick: () => !swal.isLoading(),
            preConfirm: function() {
              return new Promise(function(resolve, reject) {
                removeComment(comment.id, comment.photoId).then(resolve).catch(err => {
                  reject(i18n.t("js.comment.delete.reject"));
                });
              });
            }
          }).catch(swal.noop);
        }
      }
    } else {
      // commentElement.querySelector('div.comment-reply').remove();
      commentElement.querySelector('div.comment-edit').remove();
      commentElement.querySelector('div.comment-remove').remove();
    }
    commentElement.setAttribute('comment-id', comment.id);
    commentElement.setAttribute('deepest-parent-id', deepestParentId||'');
    commentElement.classList.remove('hidden');
    if(document.getElementById(commentElement.id) === null) {
      document.getElementById('commentsContainer').appendChild(commentElement);
    }
  } catch(err) {
    console.error(err);
  }

  function findLatestReply() {
    let allReplies = document
      .querySelectorAll('div.photo-comment-container[deepest-parent-id="'+deepestParentId+'"]');
    return allReplies.length > 0
      ?allReplies[allReplies.length-1].id
      :null;
  }
}

function formatCommentContent(comment, authorName = null) {
  return ((comment.parentId == null || comment.parentId == '')
    ?''
    :`<b class="reply-to-author">@${(authorName || commentIdMap[comment.parentId].authorName)}</b>&nbsp;&nbsp;`)
      + escapeHTML(comment.content||'').trim();
}

function escapeHTML(html) {
  let div = document.createElement('div');
  div.textContent = html;
  return div.innerHTML;
}

function tryToHideCommentArea(commentArea, textarea) {
  if(!textarea.disabled) {
    textarea.value = '';
    let $commentArea = $(commentArea);
    $commentArea.slideUp(400);
    setTimeout(function() {
      $commentArea.remove();
    }, 450);
  }
}

function updateLatestCommentsWithDelay(photoId) {
  if(!isGridMode()) {
    setTimeout(function() {
      updateLatestComments([photoId]);
    }, 1000); // one second delay to avoid synchronization issue 
    // when fetching the new latest comment
  }
}

function removeComment(commentId, photoId) {
  return new Promise(function(resolve, reject) {
    let urlParams = { commentId: commentId };
    if(isSharedCollection) {
      if(croogloo_auth.isSharedAccess) {
        reject(i18n.t("js.comment.delete.reject2"));
        return;
      }
      urlParams.tenantId = sharedLinkOptions.tenantId;
    }
    apicall('documentsapi', 'deletePhotoComment', urlParams).then(resp => {
      if(resp.responseCode === '0') {
        for(let commentIdToDelete of resp.responseMessage.split(',')) {
          console.debug('deleting comment with id: ' + commentIdToDelete);
          delete commentIdMap[commentIdToDelete];
          try {
            $(document.querySelector('div.photo-comment-container[comment-id="'+commentIdToDelete+'"]')).remove();
            updateLatestCommentsWithDelay(photoId);
          } catch(e) {
            // could not have been fetched yet in future versions
            console.warn('comment to delete not found', e);
          }
        }
        if(!document.querySelectorAll('div.photo-comment-container:not(#defaultPhotoComment)').length) {
          showDefaultComment();
        }
        resolve();
      } else {
        throw new Error(i18n.t("js.comment.delete.reject3"), resp);
      }
    }).catch(err => {
      console.error(err);
      reject(err);
    });
  });
}

function formatCommentTime(timestamp) {
  timestamp = typeof timestamp == 'string' ?parseInt(timestamp) :timestamp;
  let now = new Date();
  let date = new Date(timestamp);
  const DAY_MS = 1000 * 3600 * 24;
  const MONTH_MS = DAY_MS * 30.42;
  let formattedTime = '';
  if(isToday()) {
    formattedTime = i18n.t("js.time.labels.today", {time:formatTimeOfDay()});
  } else if(isYesterday()) {
    formattedTime = i18n.t("js.time.labels.yesterday", {time:formatTimeOfDay()});
  } else if(isOverAYearOld()) {
    let yearInterval = Math.round((now.getTime() - date.getTime()) / MONTH_MS / 12);
    formattedTime = i18n.t("js.time.year-ago", {count:yearInterval});
  } else if(isOverAMonthOld()) {
    let monthInterval = Math.round((now.getTime() - date.getTime()) / MONTH_MS);
    formattedTime = i18n.t("js.time.month-ago", {count:monthInterval});
  } else {
    let dayInterval = Math.round((now.getTime() - date.getTime()) / DAY_MS);
    formattedTime = i18n.t("js.time.day-ago", {count:dayInterval});
  }

  return formattedTime;

  function isToday() {
    return now.getDate() == date.getDate() && now.getTime() - date.getTime() <= DAY_MS;
  }

  function isYesterday() {
    let yesterday = new Date(now.getTime() - DAY_MS);
    return yesterday.getDate() == date.getDate() && yesterday.getTime() - date.getTime() <= DAY_MS;
  }

  function isOverAYearOld() {
    return now.getTime() - date.getTime() > 12 * MONTH_MS;
  }

  function isOverAMonthOld() {
    return now.getTime() - date.getTime() > MONTH_MS;
  }

  function formatTimeOfDay() {
    return (date.getHours()%12).toString().replace(/^0+$/,'12') + ':'
      + date.getMinutes().toString().padStart(2, '0')
      + (date.getHours() >= 12? ' PM' :' AM');
  }
}

function displayPhotos() {
  let folderList = Object.values(photoFolders[activeFolder.id].folders);
  for(let folder of folderList.sort((f1, f2) => f1.name.localeCompare(f2.name))) {
    folder.build();
  }

  for(let photo of Object.values(photoFolders[activeFolder.id].photos).sort((p1, p2) => p1.name.localeCompare(p2.name))) {
    photo.build();
  }
  if(isActiveFolderEmpty()) {
    document.getElementById('no-data-span').style.display = '';
    document.getElementById('uploadPhotoBtn').style.display = 'none' ;
    document.getElementById('sharePhotoBtn').style.display = 'none' ;
    document.getElementById('viewControllerContainer').style.display = 'none' ;
  }
}

function isActiveFolderEmpty() {
  return (Object.values(photoFolders[activeFolder.id].folders).length === 0
    && Object.values(photoFolders[activeFolder.id].photos).length === 0);
}

function showDefaultComment() {
  let defaultComment = document.getElementById('defaultPhotoComment');
  defaultComment.querySelector('div.comment-time').textContent = formatCommentTime(new Date().getTime());
  defaultComment.classList.remove('hidden');
}

function getDeepestParentId(comment) {
  let deepestParentId = comment.parentId;
  while(typeof commentIdMap[deepestParentId].parentId == 'string' && commentIdMap[deepestParentId].parentId.trim() != '') {
    deepestParentId = commentIdMap[deepestParentId].parentId;
  }
  return deepestParentId;
}

async function uploadPhoto(fileList) {
  let acceptedFiles = [];
  let isAtLeastOneDirectory = false;
  for(let file of fileList) {
    if(!file.type || !file.type.startsWith('image') && !file.name.endsWith('.zip')) {
      if(!file.type) {
        isAtLeastOneDirectory = true;
      } else {
        cgToast(i18n.t("js.photos.not-img", {file:file.name}), {className: 'error-toast'});
      }
    } else {
      acceptedFiles.push(file);
    }
  }
  if(isAtLeastOneDirectory) {
    await new Promise(resolve => { // must not be overwritten by subsequent upload swal     
      swal({
        html: i18n.t("js.folder.upload.rejection"),
        type: 'info',
        showCloseButton: true,
        showConfirmButton: i18n.t("button.gotit"),
      }).then(resolve).catch(resolve);
    });
  }
  document.getElementById('uploadForm').reset();
  let parentFolderId = activeFolder.id;
  showSpinner();
  let upload = await new Upload(activeFolder.id, 'photo', 
    isSharedCollection ?sharedLinkOptions.tenantId :null,
    isSharedCollection ?sharedLinkOptions.userToken :null);
  hideSpinner();
  upload.toGoogleCloudStorage(acceptedFiles).then(resp => {
    if(resp.length < 4) {
      let docFetchAPICalls = [];
      for(let file of resp.filter(f => !f.name.endsWith('zip'))) {
        showSpinner();
        let urlParams = { fileId: file.id };
        if(isSharedCollection) {
          urlParams.tenantId = sharedLinkOptions.tenantId;
        }
        docFetchAPICalls.push(apicall('documentsapi','fetchDocumentById', urlParams));
      }
      Promise.all(docFetchAPICalls).then(resp => {
        console.debug(resp);
        hideSpinner();
        if(Array.isArray(resp)) {
          if(resp.length) {
            document.getElementById('no-data-span').style.display = 'none';
            document.getElementById('uploadPhotoBtn').style.display = '' ;
            document.getElementById('sharePhotoBtn').style.display = '' ;
            document.getElementById('viewControllerContainer').style.display = '' ;
          }
          for(let fileEntity of resp) {
            try {
              let entityKey = fileEntity.key.name || fileEntity.key.id;
              let photoObj = new Photo(fileEntity, entityKey);
              photoFolders[parentFolderId].photos[entityKey] = photoObj;
              let photoList = Object.values(photoFolders[activeFolder.id].photos);
              let newPhotoIdx = photoList.sort((p1, p2) => p1.name.localeCompare(p2.name)).indexOf(photoObj);
              let photoCell = photoObj.build(newPhotoIdx + 1 < photoList.length
                ?photoList[newPhotoIdx + 1].id :null, true);
              if(!isGridMode()) {
                photoObj.updateLatestComment(null, null);              
              }
              photoObj.activity = Object.assign({ photoId: photoObj.id }, DEFAULT_ACTIVITY);
              addPhotoActivityUILayer([photoCell]);
            } catch(e1) {
              console.error(e1);
              // dynamic UI update failed, reloading to fix display
              r.reload();
              break;
            }
          }
        } else {
          // Unexpected server response, reloading to ensure proper UI rendering
          r.reload();
        }
      }).catch(err => {
        console.error(err);
        // Unexpected server error, reloading to ensure proper UI rendering of latest changes
        r.reload();
      });
    } else {
      // Reload for larger volume of files
      r.reload();
    }
  }).catch(err => {
    console.log('photo upload cancelled', err);
  });

  
}

function createFolder() {
  let parentFolderId = activeFolder.id;
  swal({
    title: i18n.t("folder.reg.new"),
    input: 'text',
    inputPlaceholder: i18n.t("folder.new-plchldr"),
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    showLoaderOnConfirm: true,
    confirmButtonText: i18n.t("create"),
    showCloseButton: true,
    allowOutsideClick: () => !swal.isLoading(),    
    preConfirm: folderName => {
      return new Promise(function(resolve, reject) {
        if(!folderName || !folderName.trim()) {
          reject(i18n.t("js.folder.rejection.name.invalid"));
          return;
        } else if(folderName.includes(',')) {
          reject(i18n.t("js.folder.rejection.name.commas"));
          return;
        } else {
          let urlParams = {
            parents: parentFolderId,
            folderName: folderName.trim(),
            isSmartFolder: false,
            page: 'menu_documents'
          };
          if(isSharedCollection) {
            urlParams.token = sharedLinkOptions.userToken;
            urlParams.tenantId = sharedLinkOptions.tenantId;
          }
          apicall('documentsapi', 'addDocumentFolder', urlParams, {}).then(function(resp) {
            if(resp.responseCode !== '0') {
              throw new Error({ msg: 'Invalid Response', err: resp });
            }
            let folderEnt = resp.entity;
            let itemKey = folderEnt.key.name || folderEnt.key.id;
            let newFolder = new Folder(folderEnt, itemKey);
            photoFolders[parentFolderId].folders[itemKey] = newFolder;

            let folderList = Object.values(photoFolders[activeFolder.id].folders);
            let newFolderIdx =  folderList.sort((f1, f2) => f1.name.localeCompare(f2.name)).indexOf(newFolder);
            document.getElementById('no-data-span').style.display = 'none';
            newFolder.build(newFolderIdx + 1 < folderList.length ?folderList[newFolderIdx + 1].id :null);
            resolve();
          }).catch(err => {
            console.error(err);
            reject(i18n.t("js.folder.rejection.failure"));
          });
        }
      });
    }
  }).catch(swal.noop);
}

function hideAllDraggedOverOverlays() {
  for(let draggedOverElem of document.querySelectorAll('div.dragged-over')) {
    draggedOverElem.classList.remove('dragged-over');
  }
}

function hasReadAndWriteOnActiveFolder() {
  return (!croogloo_auth.isSharedAccess && isSharedCollection
    && sharedLinkOptions.accessType === LinkAccess.VIEW_COMMENT_EDIT 
    || !isSharedCollection && croogloocurrentpage.getAccessLevel() > 1);
}

function isNestedFolderEditEnabled() {
  return hasReadAndWriteOnActiveFolder() 
    && (!isSharedCollection 
    || parseInt(sharedLinkOptions.sharingType) > SharingType.FOLDER_ONLY.value);
}

function createDownloadBtn(folderOrPhoto) {
  let downloadBtn = document.createElement('div');
  downloadBtn.innerHTML = '<i class="fa fa-download"></i>';
  downloadBtn.className = 'photo-option-btn ' + viewModeClass;
  downloadBtn.title = i18n.t((folderOrPhoto instanceof Folder ? "docs.menu.dwnld-zip":"utils.download"));
  $(downloadBtn).tooltip();
  downloadBtn.onclick = function(event) {
    event.stopImmediatePropagation();
    if(folderOrPhoto instanceof Folder) {
      folderOrPhoto.downloadAsZip();
    } else {
      folderOrPhoto.download();
    }
  };
  return downloadBtn;
}

function createMoreOptionsBtn() {
  let moreOptionsBtn = document.createElement('div');
  moreOptionsBtn.innerHTML = '<i class="fa fa-cog"></i>';
  for(let attr of document.getElementById('photoMenuTogglerBlueprint').attributes) {
    if(!['id', 'style'].includes(attr.name)) {
      try {
        moreOptionsBtn.setAttribute(attr.name, attr.value);
      } catch(err1) {
        console.error(err1);
      }
    }
  }
  moreOptionsBtn.onclick = (e) => adaptContextMenuContent(e.target.closest('div.cell.folder-cell') === null);
  moreOptionsBtn.className = 'photo-option-btn photo-option-menu dropdown-ref ' + viewModeClass;
  moreOptionsBtn.title = i18n.t("utils.more-opts");
  $(moreOptionsBtn).tooltip();
  return moreOptionsBtn;
}

class Folder {
  constructor(entity, id) {
    this.id = id;
    this.name = entity.properties.fileName;
    this.isSmartFolder = entity.properties.isSmartFolder;
    try {
      let childrenString = ((typeof entity.properties.children == 'string'
        ?entity.properties.children
        :entity.properties.children.value) || '')
          .replace(/',\s*'/g, ',');
      this.nbOfChildren = childrenString == '' ?0 :childrenString.split(',').length;
      this.hasChildren = this.nbOfChildren > 0;
    } catch(err) {
      // could happen for old folders from before major filing system update
      console.error(err);
      this.nbOfChildren = 0;
      this.hasChildren = false;
    }
    this.container = document.getElementById('photoContainer');
  }

  build(insertBeforeId = null) {
    let that = this;
    let cell = document.createElement('div');
    this.cellElem = cell;
    cell.className = 'cell folder-cell ' + viewModeClass;
    cell.classList.add(isGridMode() ? 'small-6' : 'small-12');
    cell.classList.add(isGridMode() ? 'medium-2' : 'large-12');
    cell.setAttribute('folder-id', this.id);
    cell.id = Utility.getValidId(this.id);
    let folderBox = document.createElement('div');
    folderBox.className = 'folder-box ' + viewModeClass;
    let folderIcon = new Image(120, 120);
    folderIcon.src = global.IMG_DIRECTORY + (this.hasChildren
      ?'not-so-empty-folder.svg' :'empty-folder.svg');
    folderBox.appendChild(folderIcon);
    let folderTitleContainer = document.createElement('div');
    folderTitleContainer.className = 'folder-title ' + viewModeClass;
    let folderTitle = document.createElement('span');
    folderTitle.textContent = this.name;
    folderTitle.className = 'dropdown-ref';
    folderTitleContainer.appendChild(folderTitle);

    if(!isGridMode()) {
      let folderContentDesc = document.createElement('span');
      folderContentDesc.className = 'folder-content-desc';
      folderTitleContainer.appendChild(document.createElement('br'));
      folderTitleContainer.appendChild(folderContentDesc);
      if(hasReadAndWriteOnActiveFolder()) {
        folderTitleContainer.appendChild(document.createElement('br'));
        let folderOptionsBar = document.createElement('div');
        folderOptionsBar.className = 'photo-option-bar photo-list-view';
        let folderOptionsBarInnerContainer = document.createElement('div');
        folderOptionsBarInnerContainer.appendChild(createDownloadBtn(that));
        folderOptionsBarInnerContainer.appendChild(createMoreOptionsBtn());
        folderOptionsBar.appendChild(folderOptionsBarInnerContainer);
        folderTitleContainer.appendChild(folderOptionsBar);
      }
      cell.appendChild(folderBox);
      cell.appendChild(folderTitleContainer);      
      this.updateNbOfChildren();
    } else {
      let moreOptionsBtn = createMoreOptionsBtn();
      moreOptionsBtn.style.display = 'none';
      folderBox.appendChild(moreOptionsBtn);
      folderBox.appendChild(folderTitleContainer);
      cell.appendChild(folderBox);
    }

    cell.oncontextmenu = function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      if(hasReadAndWriteOnActiveFolder()) {
        adaptContextMenuPositionAndContent(folderTitle, event.clientX, event.clientY, false);
        $('#photo-dropdown').foundation('open');
      }
    }
    cell.setAttribute('draggable', 'true');
    cell.ondragstart = function(e) {
      draggedElement = that;
      nbOfDraggedOverLayers = 0;
      e.dataTransfer.setData('cell-id', this.id);
    };
    cell.ondragend = function(e) {
      draggedElement = null;
      nbOfDraggedOverLayers = 0;
      hideAllDraggedOverOverlays();
    };
    cell.ondragover = function(e) {
      e.dataTransfer.dropEffect = e.dataTransfer.effectAllowed = 'move';
      e.preventDefault();
    };
    cell.ondragenter = function(e) {
      if(draggedElement instanceof Photo || draggedElement instanceof Folder) {
        hideAllDraggedOverOverlays();
        nbOfDraggedOverLayers++;
        if(!(draggedElement instanceof Folder) 
          || draggedElement.id != this.getAttribute('folder-id')) {
          this.classList.add('dragged-over');
        }
      }
    };
    cell.ondragleave = function(e) {
      if(--nbOfDraggedOverLayers <= 0) {
        this.classList.remove('dragged-over');
      }
    };
    cell.ondrop = function(e) {
      if(!isNestedFolderEditEnabled()) {
        swalToast({
          type: 'info',
          title: i18n.t("js.photos.denied-organize")
        });
        return;
      }
      let doppedCellId = e.dataTransfer.getData('cell-id');
      if(doppedCellId && doppedCellId != this.id) {
        e.stopImmediatePropagation();
        e.preventDefault();
        let droppedCell = document.getElementById(doppedCellId);
        changeParentFolder(droppedCell, that.id, that);
      }
    }
    cell.onclick = function(event) {
      if(event.target.closest('div.photo-option-btn') !== null) {
        console.debug('preventing folder navigation for option btn');
        return;
      }
      let currentFolderPath = getCurrentFolderPath();
      currentFolderPath.push({
        id: this.getAttribute('folder-id'),
        name: photoFolders[activeFolder.id].folders[this.getAttribute('folder-id')].name
      });
      sessionStorage.setItem('active-photo-folder', JSON.stringify(currentFolderPath));
      // Load page for the new active photo folder
      r.reload();
    };
    if(insertBeforeId !== null) {
      this.container.insertBefore(cell, document.querySelector('div.folder-cell[folder-id="' + insertBeforeId + '"]'));
    } else if (document.querySelector('div.photo-cell') !== null) {
      this.container.insertBefore(cell, document.querySelector('div.photo-cell'));
    } else {
      this.container.appendChild(cell);
    }
  }

  updateNbOfChildren(inc = 0) {
    this.nbOfChildren += inc;
    if(!isGridMode()) {
      let folderContentDesc = this.cellElem.querySelector('span.folder-content-desc');
      folderContentDesc.textContent = i18n.t("js.folder.contains", {count:this.nbOfChildren});
    }
  }

  updateFolderIcon() {
    let folderImg = this.cellElem.querySelector('div.folder-box > img');
    if(this.nbOfChildren) {
      folderImg.src = folderImg.src.replace('/empty', '/not-so-empty');
    } else {
      folderImg.src = folderImg.src.replace('/not-so-empty', '/empty');
    }
  }

  downloadAsZip() {
    let that = this;
    showSpinner();
    console.debug('using primary download method');
    apicall('documentsapi', 'downloadFolderAsZip', {
      folderId: that.id
    }).then(resp => {
      if(resp.responseCode === '0') {
        swal({
          title: i18n.t("response.success"),
          html: i18n.t("js.download.zip.in-progress"),       
          type: 'success',
          allowOutsideClick: true
        }).catch(swal.noop);
        DownloadNotification.add(false, false, resp.responseMessage.split(",")[0], 
          resp.responseMessage.split(",")[1] || null, new Date().getTime());
    } else {
        throw new Error('server error');
      }
    }).catch(() => {
      cgToast(i18n.t("js.photos.wrong"));        
    }).then(hideSpinner);
  }

}

function changeParentFolder(childCell, newParentId, newParentObj = null) {
  let isItemAPhoto = childCell.hasAttribute('photo-id');
  let activeFolderId = activeFolder.id;
  let itemId = isItemAPhoto
    ?childCell.getAttribute('photo-id')
    :childCell.getAttribute('folder-id');
  let objectParent = photoFolders[activeFolderId][isItemAPhoto?'photos':'folders'];
  let hideToast = cgToast(i18n.t("js.folder.moving", {thing:((objectParent[itemId]||{}).name||'')}), {
    hiding: 'manual'
  });
  apicall('documentsapi', 'changeParentFolder', {
    oldParentFolderId: activeFolderId,
    newParentFolderId: newParentId,
    filesId: itemId
  }).then(resp => {
    if(resp.responseCode === '0') {
      childCell.remove();
      delete objectParent[itemId];
      if(newParentObj) {
        newParentObj.updateNbOfChildren(1);
        newParentObj.updateFolderIcon();
      } else if(isActiveFolderEmpty()) {
        document.getElementById('no-data-span').style.display = '';
        document.getElementById('uploadPhotoBtn').style.display = 'none' ;
        document.getElementById('sharePhotoBtn').style.display = 'none' ;
        document.getElementById('viewControllerContainer').style.display = 'none' ;
      }
      DocUtility.removeCardViews([activeFolderId, newParentId].join(','));
      hideToast();
    } else {
      throw new Error('Invalid server response');
    }
  }).catch(err => {
    hideToast();
    swalToast({
      type: 'error',
      title: i18n.t("js.folder.move.failure."+(isItemAPhoto ? 'photo':'folder'))
    });
  });
}

class Photo {
  constructor(entity, id) {
    this.id = id;
    this.name = entity.properties.fileName;
    this.imageURL = entity.properties.fileURL;
    this.thumbnailURL = entity.properties.thumbnailURL;
    this.isApproved = entity.properties.approval;
    this.container = document.getElementById('photoContainer');
    this.uploaderData = null;
    this.description = entity.properties.description || i18n.t("js.photos.description.empty");
  }

  build(insertBeforeId, initListener = false) {
    let that = this;
    let cell = document.createElement('div');
    this.cellElem = cell;
    cell.className = 'cell photo-cell ' + viewModeClass;
    cell.classList.add(isGridMode() ? 'small-6' : 'small-12');
    cell.classList.add(isGridMode() ? 'medium-2' : 'large-12');
    cell.id = Utility.getValidId(this.id);
    cell.setAttribute('photo-id', this.id);
    cell.setAttribute('draggable', 'true');
    cell.ondragstart = function(e) {
      draggedElement = that;
      nbOfDraggedOverLayers = 0;
      e.dataTransfer.setData('cell-id', this.id);
    };
    cell.ondragend = function(e) {
      draggedElement = null;
      nbOfDraggedOverLayers = 0;
      hideAllDraggedOverOverlays();
    };

    let photoBox = document.createElement('div');
    photoBox.className = 'photo-box ' + viewModeClass;
    let photoImg = document.createElement('img');
    photoImg.className = 'no-text-select';
    photoImg.src = this.thumbnailURL;
    photoImg.setAttribute('alt', this.name);

    let photoOptions = document.createElement('div');
    photoOptions.className = 'photo-option-bar ' + viewModeClass;
    photoOptions.onclick = e => {
      if(e.target.classList.contains('photo-option-bar')
        || e.target.parentElement.classList.contains('photo-option-bar')) {
        e.stopPropagation();
      }
    };
    let photoOptionBtnContainer = document.createElement('div');

    let downloadOption = createDownloadBtn(that);

    let commentOption = document.createElement('div');
    commentOption.innerHTML = '<i class="fa fa-comment"></i>';
    commentOption.className = 'photo-option-btn comment-option-btn ' + viewModeClass;
    commentOption.title = i18n.t("js.comment.title");
    $(commentOption).tooltip();

    let moreOptionsMenu = null;
    if(!croogloo_auth.isSharedAccess && isSharedCollection
      && sharedLinkOptions.accessType === LinkAccess.VIEW_COMMENT_EDIT
      || !isSharedCollection && croogloocurrentpage.getAccessLevel() > 1) {
      moreOptionsMenu = createMoreOptionsBtn();
    }

    photoOptionBtnContainer.appendChild(downloadOption);
    photoOptionBtnContainer.appendChild(commentOption);
    if(moreOptionsMenu != null) { photoOptionBtnContainer.appendChild(moreOptionsMenu); }
    photoOptions.appendChild(photoOptionBtnContainer);

    photoBox.appendChild(photoImg);
    if(isGridMode()) {
      photoBox.appendChild(photoOptions);
    }
    cell.appendChild(photoBox);
    let photoTitle = document.createElement('div');
    photoTitle.className = 'photo-title dropdown-ref ' + viewModeClass;
    // let photoTitleSpan = document.createElement('span');
    // photoTitleSpan.textContent = this.name;
    // photoTitle.appendChild(photoTitleSpan);
    if(isGridMode() && this.isApproved) {
      let verifiedWrapper = document.createElement('img');
      verifiedWrapper.style = 'position: absolute; top: -25px; right: -25px; max-width: 50px';
      verifiedWrapper.src = '/assets/img/verified.jpg';
      verifiedWrapper.setAttribute('alt', this.name);
      cell.appendChild(verifiedWrapper);
    }
    if(!isGridMode() && this.isApproved) {
      let verifiedWrapper = document.createElement('img');
      verifiedWrapper.style = 'position: absolute; top: 0; left: 0; max-width: 25px';
      verifiedWrapper.src = '/assets/img/verified.jpg';
      verifiedWrapper.setAttribute('alt', this.name);
      cell.appendChild(verifiedWrapper);
    }

    if(isGridMode()){
      let photoDescSpan = document.createElement('span');
      photoDescSpan.textContent = this.name.replace(/[\n\r]/g, ' ');
      photoTitle.appendChild(photoDescSpan);
    }

    if(!isGridMode()) {
      let photoDescSpan = document.createElement('span');
      photoDescSpan.textContent = this.name.replace(/[\n\r]/g, ' ');

      let latestCommentNameSpan = document.createElement('span');
      latestCommentNameSpan.className = 'latest-comment-name';
      
      let latestCommentContentSpan = document.createElement('span');
      latestCommentContentSpan.className = 'latest-comment-content';

      photoTitle.appendChild(document.createElement('br'));
      photoTitle.appendChild(photoDescSpan);
      photoTitle.appendChild(document.createElement('br'));
      photoTitle.appendChild(latestCommentNameSpan);
      photoTitle.appendChild(latestCommentContentSpan);
      photoTitle.appendChild(document.createElement('br'));
      photoTitle.appendChild(photoOptions);
    }

    cell.appendChild(photoTitle);

    if(!isGridMode()) {
      this.updateLatestComment(CROOGLOO_BOT_NAME, i18n.t("js.comment.loading.latest"));
    }

    cell.oncontextmenu = function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      if(hasReadAndWriteOnActiveFolder()) {
        adaptContextMenuPositionAndContent(photoTitle, event.clientX, event.clientY, true);
        $('#photo-dropdown').foundation('open');
      }
    }

    if(insertBeforeId !== null) {
      this.container.insertBefore(cell, document.querySelector('div.photo-cell[photo-id="'+insertBeforeId+'"]'));
    } else {
      this.container.appendChild(cell);
    }

    if(initListener) { // should only be used for new uploads
      cell.onclick = onPhotoClick;
    }

    return cell;
  }

  download() {
    let that = this;
    showSpinner();
    console.debug('using primary download method');
    apicall('documentsapi', 'fetchDownloadLink', {
      fileId: that.id
    }).then(resp => {
      if(resp.responseCode === '0') {
        window.location.href = resp.responseMessage;
      } else {
        throw new Error('server error');
      }
    }).catch(() => {
      console.warn('using failover download method')
      DocUtility.openFile(that.id, that.name, false, true, null, null,
        isSharedCollection ?sharedLinkOptions.tenantId :null);
    }).then(hideSpinner);
  }

  updateLatestComment(commenterName, commentContent) {
    let latestCommentNameSpan = this.cellElem.querySelector('.latest-comment-name');
    let latestCommentContentSpan = this.cellElem.querySelector('.latest-comment-content');

    if(commentContent == null || commenterName == null) {
      commenterName = CROOGLOO_BOT_NAME;
      commentContent = i18n.t("js.comment.none");
    }
    latestCommentNameSpan.textContent = `${commenterName}:`;
    latestCommentContentSpan.innerHTML = `&nbsp;"${commentContent.replace(/[\n\r]/g, ' ')}"`;
  }
}
