import i18n from './components/Translation';
import './ui/ui.js';
import './croogloo/globals.js';
import './components/loading/loadingUtility.js';
import { Router } from './croogloo/router.js';
import Page from './croogloo/page.js';
import { Auth } from './croogloo/login.js';
import { authenticatedcall, apicall } from './croogloo/api.js';
import Notification from './components/Notification.js';
import WatermarkNotification from './components/notifications/WatermarkNotification.js';
import DownloadNotification from './components/notifications/DownloadNotification.js';
import addCancelSubListener from './components/cancelSubscriptionBtn.js';
import { enabledNewProdBtn, enabledSwitchProdBtn } from './components/ProductionBtns.js';
import manageSubscriptionBtn from './components/ManageSubscriptionBtn.js';
import SubscriptionManager from './components/SubscriptionManager.js';
import DynamicFooterButtons from './components/DynamicFooterButtons.js';
import SecureLS from 'secure-ls';

import { init as initCompose } from './compose.js';
import { init as initContact } from './contact.js';
import { init as initCrew } from './crew.js';
import { init as initDistributionList } from './distributionList.js';
import { init as initDistributionSent } from './distributionSent.js';
import { init as initDocuments } from './documents.js';
import { init as initPhotos } from './photos.js';
import { init as initSidesGeneration } from './sidesGeneration.js';
import { init as initWatermark } from './watermark.js';
import { init as initTaskMonitoring } from './taskMonitoring.js';
import { init as initUpload } from './upload.js';
import { init as initUploadVCards } from './uploadVcards.js';
import { init as initSecurityLists } from './securityLists.js';
import { init as initMngPermissions } from './managePermissions.js';
import { init as initTemplates } from './templates.js';
import { init as initDistributionHistory } from './distributionHistory.js';
import { init as initDocViewer } from './docViewer.js';
import { init as initResetPassword } from './resetPassword.js';
import { init as initSecuritySettings } from './securitySettings.js';
import { init as initDashboard } from './dashboard.js';
import { init as initTimeReport } from './timeReport.js';
import { init as initDefault } from './defaultPage.js';
import { init as initIndexpage } from './indexpage.js';

import "../assets/scss/app.scss";

global.secureLocalStorage = new SecureLS({ encodingType: 'aes' });
console.debug("Secure Local Storage Initialized");

function isDefaultLoadEvent(e) {
  console.log("Identifying the 'load' event: ", e);
  if (e.namespace && typeof e.namespace == "string" && e.namespace.includes("zf")) {
    console.log("Foundation custom event fired. Skipping...");
    return false;
  }
  if (e.constructor.name != "Event") {
    console.log("Constructor name of the event is not 'Event'. It is probably not the default event");
    return false;
  }
  return true;
}

var isAlreadyLoading = false;
window.onload = async function(e){
  if (!isDefaultLoadEvent(e)) {
    console.log("It is not a default 'load' event. Halting subsequent window.load logic");
    return;
  }

  $('#jsLangSwitch').on("click", function() {
    let langSwitcher = document.getElementById("langSwitch");
    i18n.changeLanguage(langSwitcher.dataset.locale, (err, t)=>{
      console.debug("New language is : "+i18n.language);
      console.debug(t('test'))
      if(err) console.error(err);
    });
  });

  console.log("window.onload CALLED. isAlreadyLoading:", isAlreadyLoading, e);
  if (isAlreadyLoading) {
    console.log("ALREADY LOADING. EXIT!");
    return;
  }
  isAlreadyLoading = true;

  if ('serviceWorker' in navigator ) {//&& !location.host.startsWith("localhost")) {
    try {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
          console.warn('unregistering a service worker');
          registration.unregister()
        } 
      });
    } catch(e1) {
      console.error(e1);
    }
    // navigator.serviceWorker
    //   .register('/service-worker.js', {scope: '/'})
    //   .then((reg) => {
    //     if (reg.installing) {
    //       console.log('Service worker installing');
    //     } else if (reg.waiting) {
    //       console.log('Service worker installed');
    //     } else if (reg.active) {
    //       console.log('Service worker active');
    //     }
    //   }).catch((err)=>{
    //     console.log(err);
    // });
    // navigator.serviceWorker.addEventListener('controllerchange',(event)=>{
    //   navigator.serviceWorker.controller.addEventListener('statechange',()=>{
    //     console.log("statechange:",this.state);
    //   });
    // });
  }

  document.body.parentElement.style.fontSize = '16px';

  var root = document.querySelector('#main');
  // GLOBAL VARIABLE ACCESSIBLE IN OTHER PLAIN SCRIPTS
  window.cgNotification = new Notification('notification-counter', 'notificationlist');
  window.croogloo_auth = new Auth();

  window.isFirstPageLoad = true;
  await window.croogloo_auth.validate();
  document.querySelector('.initialLoader').style.display = 'none';
  updateAPIVersion();

  croogloo_auth.addLoginListener(function(auth) {
    var initialLoader = root.querySelector('.initialLoader');
    initialLoader.style.display = 'none';
  });

  var btn = document.querySelector('#logout');
  btn.addEventListener('click', () => croogloo_auth.logout(false, true));

  gapi.load('client', async function() {
    croogloo_auth.addLoginListener(function(auth) {
      let endpoint = (location.host.startsWith("localhost") && location.port != '8080')
        ?'http://localhost:8080/' :croogloo_auth.crooglooauth.urlEndpoint;
      window.apicall = apicall(endpoint);
      window.authenticatedcall = authenticatedcall(endpoint);
      if (!croogloo_auth.isSharedAccess) {
        enabledNewProdBtn();
        enabledSwitchProdBtn();
        manageSubscriptionBtn();
        addCancelSubListener();
        new DynamicFooterButtons(); // init footer buttons that require more than an href
        document.getElementById('resetPasswordMenuBtn').style.display = '';
        document.getElementById('logout').style.display = '';
        document.getElementById('navMenuToggle').style.display = '';
        for(let elem of document.querySelectorAll('.no-shared-access')) {
          elem.classList.remove('no-shared-access');
        }
        WatermarkNotification.load();
        DownloadNotification.load();
        saveApplicationAccess();
      } else {
        document.getElementById('loginMenuBtn').style.display = '';
        document.getElementById('userMenu').style.minWidth = '0';
        document.getElementById('offCanvasLeftSidemenu').style.display = 'none';
        document.getElementById('main').style.marginLeft = '0';
        document.getElementById('main').classList.add('shared-access');
        document.getElementById('redButtonLink').textContent = SUPPORT_ADDRESS;
        document.getElementById('redButtonLink').onclick = function() {
          window.location.href = 'mailto:' + SUPPORT_ADDRESS;
        }
      }

      gapi.client.setToken({ access_token: auth.token });
      let usermenuname = document.querySelector("#fullnamemenu");
      if(auth.crooglooauth.firstName) {
        usermenuname.innerHTML = auth.crooglooauth.firstName.charAt(0) + (auth.crooglooauth.lastName.charAt(0)||'');
      } else {
        usermenuname.innerHTML = i18n.t("js.app.guest");
      }

      document.getElementById('offCanvasLeftSidemenu').classList.remove('loading-phase');
      document.getElementById('main').classList.remove('loading-phase');
      document.body.classList.remove('loading-phase');
      if(croogloo_auth.crooglooauth.accountStatus === 'lost password') {
        history.replaceState({}, "lost password", "#resetPassword");
      }
      setupRouter();
      $(document).foundation();

      function saveApplicationAccess() {
        window.apicall('securityadminapi', 'saveApplicationAccess', {
          authToken: croogloo_auth.token
        }, undefined, false)
        .then(resp => {
          if(resp.responseCode !== '0') {
            throw new Error('server error');
          }
        }).catch(() => console.error('error saving application access')); // no need to alert user
      }
    });

    global.UNPROTECTED = Math.random();
    global.pagesWithWriteOnly = ['compose', 'dailyTimeReports', 'distributionList', 'contact', 'upload',
      'uploadVCards', 'uploadDocuments', 'uploadScripts', 'uploadTemplates',
      'watermark'];
    global.pagesBypassSecurity = ['resetPassword', 'termsOfService', 'privacyPolicy'];

    croogloo_auth.hasAccess = function(securityPageName) {
      try {
        let requiredPermission = pagesWithWriteOnly.includes(securityPageName) ?2 :1;
        return pagesBypassSecurity.includes(securityPageName) ||
          this.securityProfile[this.tenantId] &&
          typeof this.securityProfile[this.tenantId][securityPageName] === 'number' &&
          this.securityProfile[this.tenantId][securityPageName] >= requiredPermission;
      } catch(e) {
        return false; // backwards compatibility
      }
    }

    croogloo_auth.getAccessLevel = function(securityPageName) {
      if(this.hasAccess(securityPageName)) {
          return this.securityProfile[this.tenantId][securityPageName];
      }
      return 0;
    }

    croogloo_auth.sendRedAlert = function(errDetails) {
      try {
        window.apicall('dataMaintenance', 'sendRedAlert', {
          token: this.usertoken,
          tenantId: this.tenantId,
          username: this.crooglooauth.userId,
          pageName: window.croogloocurrentpage.pageName || window.location.href,
          errDetails: errDetails || 'no details provided'
        }, undefined, false);
      } catch(e) {
        console.error(e);
      }
    }

    function setupRouter() {
      global.r = new Router(
        {
          dashboard: new Page('dashboard', initDashboard), // should not be deployed for client build
          resetPassword: new Page('resetPassword', initResetPassword),
          compose: new Page('compose', initCompose),
          distributionList: new Page('distributionList', initDistributionList),
          drafts: new Page({page:'drafts', url:'distributionDrafts'}, initDistributionHistory, {status: 0}),
          outbox: new Page({page:'outbox', url:'distributionOutbox'}, initDistributionHistory, {status: 1}),
          sent: new Page({ page: 'sent', url: 'distributionSent' }, initDistributionSent, { status: 2 }), //Had to split up the distributionHistory pages  
          contact: new Page('contact/id', initContact),                                                   //because distributionSent now has a new design compared to outbox and and drafts 
          allContacts: new Page({page:'allContacts', url:'crew'}, initCrew, { targetListings: ['agent', 'cast', 'crew', 'studio', 'union', 'vendor'] }),
          agent: new Page({page:'agent', url:'crew'}, initCrew, { targetListings: ['agent'] }),
          cast: new Page({page:'cast', url:'crew'}, initCrew, { targetListings: ['cast'] }),
          crew: new Page('crew', initCrew, { targetListings: ['crew'] }),
          studio: new Page({page:'studio', url:'crew'}, initCrew, { targetListings: ['studio'] }),
          union: new Page({page:'union', url:'crew'}, initCrew, { targetListings: ['union'] }),
          vendor: new Page({ page: 'vendor', url: 'crew' }, initCrew, { targetListings: ['vendor'] }),
          uploadVCards: new Page('uploadVCards', initUploadVCards, {filetype: 'script'}),
          documents: new Page('documents', initDocuments),
          uploadDocuments: new Page({page:'uploadDocuments', url:'upload'}, initUpload, {filetype: 'reportsAndSchedules'}),
          photos: new Page('photos', initPhotos),
          sidesGeneration: new Page('sidesGeneration', initSidesGeneration),
          watermark: new Page('watermark', initWatermark),
          uploadScripts: new Page({page:'uploadScripts', url:'upload'}, initUpload, {filetype: 'script'}),
          scripts: new Page({page:'scripts', url:'docViewer'},initDocViewer, { subcategory: 'script' }),
          sides: new Page({page:'sides', url:'docViewer'}, initDocViewer, { subcategory: 'side' }),
          uploadTemplates: new Page({page:'uploadTemplates', url:'upload'}, initUpload, {filetype: 'template'}),
          templates: new Page('templates', initTemplates),
          taskMonitoring: new Page('taskMonitoring', initTaskMonitoring),
          securityLists: new Page('securityLists', initSecurityLists),
          managePermissions: new Page('managePermissions', initMngPermissions),
          securitySettings: new Page('securitySettings', initSecuritySettings),
          // Removing the DTR experience on desktop for now.
          // dailyTimeReports: new Page({page:'dailyTimeReports', url:'timeReport'}, initTimeReport),
          // Temporarily suspending these as they are dead links anyways, we never allow the user to click to get to them.
          // termsOfService : new Page('termsOfService', initDefault),
          // privacyPolicy: new Page('privacyPolicy', initDefault),
          '#default': 'dashboard'
        },
        root,
        croogloo_auth
      );
      initIndexpage();
    }

    croogloo_auth.updateSecurityProfile = function() {
      (this.isSharedAccess
        ?Promise.resolve({})
        :window.apicall('securityadminapi', 'getSecurityProfile', {}, undefined, false))
        .then(resp => {
        if(resp && resp.status != 204 && hasProfileChanged(resp)) {
          console.debug('detected change in security profile');
          croogloo_auth.securityProfile[croogloo_auth.tenantId] = resp;
          croogloo_auth.crooglooauth.securityProfile[croogloo_auth.tenantId] = resp;
          secureLocalStorage.set("crooglooauth", JSON.stringify(croogloo_auth.crooglooauth));
          if(!croogloo_auth.hasAccess(croogloocurrentpage.securityPageName)) {
            console.debug('ACCESS REVOKED, redirecting');
            // full reload for security reasons; we want to make sure the menu and 
            // access rules are reloaded
            window.location.reload();
          } else {
            updatePageMenu();
          }
        } else if(!resp || resp.status == 204) {
          console.debug('COULD NOT GET ACCESS, logging out')
          croogloo_auth.logout();
        } else {
          console.debug('no change in page access levels');
        }
      });
      function hasProfileChanged(newProfile) {
        if(Object.keys(newProfile).length !== Object.keys(croogloo_auth.securityProfile[croogloo_auth.tenantId]).length){
          return true;
        }
        for(let page in newProfile) {
          if(croogloo_auth.securityProfile[croogloo_auth.tenantId][page] !== newProfile[page]) {
            return true;
          }
        }
        return false;
      }
    }

    function updatePageMenu() {
      $('#offCanvasLeftSidemenu li, #userMenu li[purpose=nav-menu]').css('display', 'none');
      for(let page in r.routes) {
        if(r.routes[page] instanceof Page) {
          r.routes[page].adaptMenuDisplay();
        } else {
          console.debug('route of ' + page + ' is not a Page');
        }
      }
    }

    croogloo_auth.validateSubscription = function() {
      if(this.isSharedAccess) { return; }
      window.apicall('securityadminapi', 'fetchSubscriptionInfo', {}, undefined, false)
        .then(resp => {
        if(typeof resp === 'object' && resp.hasOwnProperty('isTrial')) {
          console.debug('received the following subscription info: ');
          console.debug(resp);

          if(resp.productionOwnerEmail == croogloo_auth.crooglooauth.email) {
            $('#manageSubscriptionBtn').show();
            if(!resp.isTrial) {
              $('#cancelSubscriptionBtn').show();
              try {
                if(resp.isSetToExpire && global.isUserInformedExpiredProd !== true) {
                  global.isUserInformedExpiredProd = true;
                  
                  swalToast({
                    type: 'info',
                    title: i18n.t("js.app.expire.on", {date:new Date(parseInt(resp.expiringTime))})
                  });
                }
              } catch(err) {
                console.error('Error fetching subscription expiring date');
              }
            }
          }
          try {
            if(!resp.isTrial && resp.isActiveSubscription === false  && global.isUserInformedInvalidProd !== true) {
              global.isUserInformedInvalidProd = true;
              let accessLockTime = parseInt(resp.accessLockTime);
              let isPastDueDelayExpired = accessLockTime <= new Date().getTime();
              if(resp.productionOwnerEmail == croogloo_auth.crooglooauth.email) {
                let prodOwnerPastDueMsg;
                if(isPastDueDelayExpired) {
                  prodOwnerPastDueMsg = i18n.t("js.app.suspended.payment");
                  setTimeout(() => handleNoAccess(croogloo_auth.crooglooauth), 5000);
                } else {
                  prodOwnerPastDueMsg = i18n.t("js.app.past-due", {lockTime: (new Date(accessLockTime).toString().split(/\sGMT/)[0])});
                }
                cgToast(`${prodOwnerPastDueMsg}&nbsp;<a href="${resp.hostedInvoiceUrl}" style="color: lightblue">${i18n.t("js.app.invoice.view")}</a>`, { 
                    hiding: 'manual', className: 'body-attached',
                    containerID: 'docBody',
                    showCloseButton: true
                  }
                );
              } else if(isPastDueDelayExpired) {
                handleNoAccess(croogloo_auth.crooglooauth);
              }
            }
          } catch(e) {
            console.error('error validating active subscription');
          }
          croogloo_auth.crooglooauth.productionOwnerEmail = resp.productionOwnerEmail;
          croogloo_auth.crooglooauth.productionType = resp.productionType;
          croogloo_auth.crooglooauth.billingPeriod = resp.billingPeriod;
          croogloo_auth.crooglooauth.totalNbOfProds = parseInt(resp.totalNbOfProds);
          croogloo_auth.crooglooauth.nbOfAvailableProds  = parseInt(resp.nbOfAvailableProds);
          croogloo_auth.crooglooauth.planId = resp.planId;
          croogloo_auth.crooglooauth.securityListId = resp.securityListId;

          let $officeOnlyMenuItems = $('a.office-only');
          if(resp.productionType == 'office') {
            try {
              if($('a.office-only.restricted-item').length) {
                console.debug('showing office only menu item(s)');
                $officeOnlyMenuItems.removeClass('restricted-item');
                updatePageMenu();
              }
            } catch(e) {
              console.error(e);
            }
          } else {
            // if case production type changes during trial
            $officeOnlyMenuItems.addClass('restricted-item');
          }
          croogloo_auth.crooglooauth.isTrial = resp.isTrial;
          let trialBar = document.getElementById('trialBar');
          // resp.isTrial = true; // DEBUG
          // resp.trialDaysLeft = 0; // DEBUG
          if(resp.isTrial) {
            trialBar.innerHTML = i18n.t("js.app.trial.header", {count: resp.trialDaysLeft}) + '&nbsp;';
            let barOptions = document.createElement('div');
            let upgradeOpt = document.createElement('span');
            upgradeOpt.textContent = i18n.t("js.app.trial.upgrade");
            upgradeOpt.id = 'planUpgradeOpt';
            barOptions.appendChild(upgradeOpt);
            barOptions.innerHTML += '&nbsp;/&nbsp;';
            let dismissOpt = document.createElement('span');
            dismissOpt.textContent = i18n.t("js.app.trial.dismiss");
            barOptions.appendChild(dismissOpt);
            dismissOpt.onclick = function() {
              // we don't set display to none so that it doesn't reappear while
              // switching pages without reloading
              trialBar.style.visibiliy = 'hidden';
              trialBar.style.overflow = 'hidden';
              trialBar.style.height = '0px';
            }
            trialBar.appendChild(barOptions);
            document.getElementById('planUpgradeOpt').onclick = function() {
              new SubscriptionManager(resp).endTrial();
            }
            if(trialBar.style.display == 'none') {
              $(trialBar).slideDown(400);
            }
            if(!resp.trialDaysLeft) {
              new SubscriptionManager(resp).endTrial();
            }
          } else {
            trialBar.style.display = 'none';
          }
        } else {
          throw new Error('failed to get subscription info');
        }
      }).catch(err => {
        console.debug('logging out from app.js');
        if(!croogloo_auth.isSharedAccess) {
          console.error(err);
          croogloo_auth.logout(); // TODO add sessionStorage item for swal
          // explaining logout reason on login page
        } else {
          document.getElementById('trialBar').style.display = 'none';
        }
      });
    }

    croogloo_auth.login(()=>{
      console.log("refresh called or somethin!");
      let lang = 'en';
      if(croogloo_auth && croogloo_auth.crooglooauth && croogloo_auth.crooglooauth.lang){
        lang = croogloo_auth.crooglooauth.lang;
      }

      let button = document.getElementById("langSwitch");
      button.setAttribute("data-locale", lang)
      button.textContent = lang;
      button.click();

      window.apicall('productionapi', 'fetchUserSetting', {settingName: "userLanguage"}).then((resp)=>{
        if(resp.responseCode && resp.responseCode === '0' && resp.responseMessage){
          let be_lang = resp.responseMessage;
          if(lang !== be_lang){
            lang = be_lang;
            croogloo_auth.crooglooauth.lang = be_lang;
            secureLocalStorage.set("crooglooauth", JSON.stringify(croogloo_auth.crooglooauth));
          }
        }
      }).catch(err=>{
        console.log(err);
      }).finally(()=>{
        button.setAttribute("data-locale", lang)
        button.textContent = lang;
        button.click();
      });
      
      try {
        saveSystemActivity({
          action: 'log in',
          params: "NA",
          message: 'User logged in.'
        });
      } catch (e) {
        console.error("Error in log in saveSystemActivity", e);
      }
    });
  });


  function updateAPIVersion() {
    // took around 285 ms to execute in testing, so kept async
    if(location.host.startsWith("localhost") || croogloo_auth.isSharedAccess) {
      console.warn('preventing version check for localhost or shared access');
      return;
    }
    $.ajax({
      url: "/VersionService",
      type: "get",
      crossDomain: true,
      data: {
        xsrf: "8f869fd8b51bcdd4173168f17986d499f8e70ba9"
      },
      success: function(response) {
        console.debug('stored endpoint: ' + croogloo_auth.crooglooauth.urlEndpoint);
        console.debug('current endpoint: ' + response);
        if(croogloo_auth.crooglooauth.urlEndpoint != response) {
          croogloo_auth.crooglooauth.urlEndpoint = response;
          secureLocalStorage.set("crooglooauth", JSON.stringify(croogloo_auth.crooglooauth));
          swalToast({
            title: i18n.t("js.app.version.new"),
            type: 'info',
            timer: 4000
          });
          setTimeout(() => window.location.reload(), 4000);
        }
      },
      error: function(jqXHR, textStatus, error) {
        console.error(error);
      }
    });
  }

  // waiting for class to change with variable delay
  $(window).resize(function() {
    setTimeout(adaptNavMenu, 50);
    setTimeout(adaptNavMenu, 150);
    try {
      $('.dropdown-pane').foundation('close');
    } catch(e) {
      console.warn(e);
    }
  });

  /**
   * Adds the option to toggle the page menu when the window gets too small
   */
  window.adaptNavMenu = function() {
    let $serviceTitle = $('#serviceTitle');
    let activeService = findActiveService();
    $serviceTitle.text(['dev','beta'].includes(activeService) ?activeService :'');
    let $serviceTitleContainer = $serviceTitle.parent();
    let navMenuContainer = document.getElementById('navMenuToggleContainer');
    let leftSideMenu = document.getElementById('offCanvasLeftSidemenu');
    let htmlFontSize = 16;
    try {
      htmlFontSize = parseInt(document.body.parentElement.style.fontSize) || 16;
    } catch(e) {
      console.error(e);
    }
    // We have to manually specify the 64 em minimum screen width to fix a bug
    // with foundation that only happens before the first resize, where the 
    // leftSideMenu will have the is-closed class whilst being visible.
    if(leftSideMenu.classList.contains('is-closed') 
          && document.body.parentElement.clientWidth < 64 * htmlFontSize
        || leftSideMenu.classList.contains('is-open')) {
      $('div.hide-for-small-only').css('visibility', 'hidden');
      $('div.cg-top-bar-left').css('visibility', 'visible');
      $serviceTitleContainer.css('position', 'relative');
      $serviceTitleContainer.insertAfter(navMenuContainer);
    } else {
      $('div.cg-top-bar-left').css('visibility', 'hidden');
      $('div.hide-for-small-only').css('visibility', 'visible');
      $serviceTitleContainer.css('position', 'absolute');
      $serviceTitleContainer.insertBefore(navMenuContainer);
    }
  }
  function findActiveService() {
    try {
      return croogloo_auth.crooglooauth.urlEndpoint
        .match(/\/\/(\S+-dot-)?([^-]+)-dot/)[2] || '';
    } catch(e) {
      console.error('failed to find active service');
      console.error(e);
      return '';
    }
  }

    window.findBrowser = function () {
        if (!!window.chrome && !!window.chrome.webstore)
            return 'chrome';
        if ((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0)
            return 'opera';
        if (typeof InstallTrigger !== 'undefined')
            return 'firefox';
        if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification))
            return 'safari';
        if (/*@cc_on!@*/false || !!document.documentMode)
            return 'internet explorer';
        if (!!window.StyleMedia)
            return 'edge';
        return 'not recognized';
    }
}
