import i18n from './components/Translation';

export function init() {
	$('#main').foundation();
	showSpinner();
  	initGlobals();
  	getData();
	getMetaData();
	initListeners();
}

var stopTaskMonitoringAsync;
var limitNbOfDisplayedTasks;
var offset;
var pauseUpdates;
var lookingForNewTasks;
var otherThreadRunning;
var test;
var alreadyInLoop;

var initialNbOfShowingTasks;

var currentOrderMode;

var nbOfTasksInQueues;

var currentCommunity;
var superAdmin;

var showingTasks;
var allTasks;

var nbOfAppendedTasks;

function initGlobals() {
  stopTaskMonitoringAsync = false;
  limitNbOfDisplayedTasks = 0;
  offset = 0;
  pauseUpdates = false;
  lookingForNewTasks = false;
  otherThreadRunning = false;
  test = 0;
  alreadyInLoop = false;

  initialNbOfShowingTasks = 0;

  currentOrderMode = "processingFirst";

  nbOfTasksInQueues = new Array();
  nbOfTasksInQueues[0] = new Array(); //queue name
  nbOfTasksInQueues[1] = new Array(); //nb of tasks in given queue

  currentCommunity = croogloo_auth.tenantId;

  superAdmin = false;

  showingTasks = new Array();
  allTasks = new Array();

  nbOfAppendedTasks = 0;
}

function initListeners() {
  document.getElementById('showMoreButton').onclick = function() {
    fetchTasksTimingManager(10);
  };
}

function showButtons() {
	$('#showMoreButton').show();
	if (croogloocurrentpage.getAccessLevel() > 1) {
		// $('#deleteCompletedBtn').show(); // DEPRECATED
	}
}

function getData(){}

function getMetaData(){
	verifyPermissions();
	fetchTasks(10);
}

async function fetchTasksTimingManager(nbOfTasksToFetch) {
    showSpinner();
	if(alreadyInLoop || pauseUpdates) return;
    pauseUpdates = true;
	while(pauseUpdates && otherThreadRunning) {
		alreadyInLoop = true;
		await sleep(300);
	} 
	if(!otherThreadRunning) otherThreadRunning = true;

	alreadyInLoop = false;
	fetchTasks(nbOfTasksToFetch);
}


async function fetchTasks(nbOfTasksToFetch){
	var tmp = new Object();
    tmp.offset = offset;
    tmp.nbOfTasksToFetch = nbOfTasksToFetch;
    tmp.superAdmin = superAdmin;
    apicall('tasksapi', 'fetchTasks', tmp).then(async function(resp){
    	hideSpinner();

      	var allQueues = new Array();
    	if(resp && resp.items){
    		var nbOfDisplayedTasksAdded = 0;
    		limitNbOfDisplayedTasks += 10;
    		var initialNbOfTasks = allTasks.length;
    		var chronoPositionIterator = 0;
    		var allTaskChronoPositionIterator = 0;
    		var lastQueue;
    		showingTasks = [];
    		allTasks = [];
    		for(var i = 0 ; i < resp.items.length ; i++){
				var task = new Object();
				var taskClone = new Object();
				var queue;
				for(var j = 0 ; j < resp.items[i].length ; j++){
					task[resp.items[i][j][0]] = resp.items[i][j][1];
					taskClone[resp.items[i][j][0]] = resp.items[i][j][1];
					if(resp.items[i][j][0] === "queue")
						queue = resp.items[i][j][1];

				}

				if(queue) {
					if(task.status === "queued"){
						alterTaskCountInQueue(task.queue, 1);
						alterTasksQueuePosition(task.queue, 1, 1, false);
						task.queuePosition = 1;
						taskClone.queuePosition = task.queuePosition;
					} else {
						task.queuePosition = 0;
						taskClone.queuePosition = 0;
					}
					allTasks.push(taskClone);
  				}

				if(!allQueues.includes(queue)) {
					allQueues.push(queue);
				}

				taskClone.chronoPosition = allTaskChronoPositionIterator++;
				task.chronoPosition = chronoPositionIterator++;
				if(task.communityId === tmp.tenantId || superAdmin) {
					nbOfDisplayedTasksAdded++;
	   				showingTasks.push(task);
				}
			}
    		orderTasks();
    		for(var i = 0, queue ; queue = allQueues[i++];) {
        		refreshTasksQueuePositions(queue);
    		}
    		for(var i = initialNbOfShowingTasks, task ; task = showingTasks[i];i++) {
    			if(i === limitNbOfDisplayedTasks)
    				break;
    			displayTask(task);
    		} if(showingTasks.length === 0)
    			$('#noResultsWarning').show();
    		else
    			showButtons();

    		initialNbOfShowingTasks =  showingTasks.length > limitNbOfDisplayedTasks ? limitNbOfDisplayedTasks:showingTasks.length;
    		limitNbOfDisplayedTasks = initialNbOfShowingTasks;

    		if(limitNbOfDisplayedTasks % 10 !== 0)
    			limitNbOfDisplayedTasks += 10 - initialNbOfShowingTasks % 10;

    		offset = allTasks.length;
    	} else {
    		$('#noResultsWarning').show();
    		limitNbOfDisplayedTasks = 10;
    	}

    	hideSpinner();
    	for(var i = 0 ; i < 30 ; i++) {
    		if(pauseUpdates) {
    			pauseUpdates = false;
    			return;
    		} await sleep(500);
    	}
    	updateTasksStatus();
    });
}

function addSecondaryElements(task){
	switch(task.taskName){
	case "Script Parsing":
		addScriptParsingElements(task);
		break;
	case "Distribution":
		addEmailElements(task);
		break;
	case "Revisions":
		addSidesElements(task, true);
		break;
	case "Sides" :
		addSidesElements(task, false);
		break;
	case "Excel Template" :
		addExcelTemplateElements(task);
		break;
	}
}

function displayTask(task) {
	createTasksMainElements(task)

	if(task.isUsingDefaults && task.isUsingDefaults === 'true')
		addSecondaryElementsWithDefaults(task);
	else
		addSecondaryElements(task);
	applyTaskFilter();
}

function applyTaskFilter() {
	//to be implemented further on
}

function orderTasks() {
	switch(currentOrderMode) {
	case "processingFirst":
		showingTasks.sort(compareProcessingFirst);
		allTasks.sort(compareProcessingFirst);
		break;
	}

	for(var i = 0, task ; task = showingTasks[i];) {
		task.showingPosition = i++;
	}
}

function compareProcessingFirst(a, b){
	if(a.status === b.status) {
		if(a.status === "processing" || a.status === "queued") {
			if(a.chronoPosition < b.chronoPosition)
				return 1;
			return -1;
		} else {
			if(a.chronoPosition < b.chronoPosition)
				return -1;
			return 1;
		}
	} if(a.status === "processing")
		return -1;
	if(b.status === "processing")
		return 1;
	if(a.status === "queued")
		return -1;
	if(b.status === "queued")
		return 1;

	if(a.chronoPosition < b.chronoPosition)
		return -1;
	return 1;
}


async function updateTasksStatus(){
  if(!croogloocurrentpage || croogloocurrentpage.pageName !== 'taskMonitoring') {
    console.log('task update stopped');
    return;
  }
  var taskIds = "";
  var communityIds = "";
  var submittedTimes = "";
  console.log("updating tasks");
  
  if(stopTaskMonitoringAsync) {
    stopTaskMonitoringAsync = false;
    console.log('aborting task updates');
    return;
  }
  
  for(var i = 0, task ; task = allTasks[i++];){
    taskIds += "_SPLITTER98763218_" + task.id;
    communityIds += "_SPLITTER98763218_" + task.communityId;
    submittedTimes += "_SPLITTER98763218_" + task.submittedTime;
  }
  
  var tmp = new Object();
  tmp.taskIds = taskIds;
  tmp.communityIds = communityIds;
  tmp.submittedTimes = submittedTimes;
  
  if(taskIds === "") {
    if(pauseUpdates) {
      pauseUpdates = false;
      return;
    } lookingForNewTasks = true;
    findNewTasks();
    for(var i = 0 ; i < 30 ; i++) {
      if(pauseUpdates) {
        while(lookingForNewTasks) {
          await sleep(300);
        } pauseUpdates = false;
        return;
      } await sleep(500);
    } updateTasksStatus();
    return;
  }
  
  apicall('tasksapi', 'updateTasks', tmp).then(async function(resp){
    if(!resp) {
      console.error('could not communicate with server');
      await sleep(1000);
      updateTasksStatus();
      return;
    }
    if(resp.items) {
      var tasksToChange = new Array();
      var allQueues = new Array();
      for(var i = 0, item ; item = resp.items[i++];) {
        for(var j = 0, task ; task = allTasks[j++];) {
          if(item[0] === task.id && item[1] === task.communityId
              && item[2] === task.submittedTime
              && item[3] !== task.status) {
            task.status = item[3];
            task.failureDetails = item[4];
            
            tasksToChange.push(task.id);
            if(!allQueues.includes(task.queue))
            allQueues.push(task.queue);
            
            for(var k = 0, showingTask ; showingTask = showingTasks[k++];) {
              if(showingTask.id === task.id) {
                showingTask.status = task.status;
                break;
              }
            }
          }
        }
      } orderTasks();
      for(var i = 0, queue ; queue = allQueues[i++];) {
        refreshTasksQueuePositions(queue);
      }
      
      var indexToMoveFrom = needToMoveTasks();
      if(indexToMoveFrom >= 0){
        for(var j = indexToMoveFrom ; j < showingTasks.length ;j++){
          var div = document.getElementById(showingTasks[j].id);
          if(div)
          removeTaskElement(showingTasks[j].id);
        } nbOfAppendedTasks = indexToMoveFrom;
        for(var i = indexToMoveFrom, task ; task = showingTasks[i];i++){
          if(i === limitNbOfDisplayedTasks)
          break;
          displayTask(task);
        }
      } else {
        for(var i = 0, taskId ; taskId = tasksToChange[i++];){
          changeStatus(taskId);
        }
      }
    } if(pauseUpdates) {
      pauseUpdates = false;
      return;
    } lookingForNewTasks = true;
    findNewTasks();
    for(var i = 0 ; i < 30 ; i++) {
      if(pauseUpdates) {
        while(lookingForNewTasks) {
          await sleep(300);
        } pauseUpdates = false;
        return;
      } await sleep(500);
    } updateTasksStatus();
  });
}

function needToMoveTasks() {
	var i = 0;
	var returnValue;
	$('#taskContainer').children('div').each(function () {
		if(this.id !== showingTasks[i].id) {
			returnValue = i;
			return false;
		} if(++i === nbOfAppendedTasks) {
			returnValue = -1;
			return false;
		}

	});
	return returnValue;
}

function alterTaskCountInQueue(queueName, valueDifference) {
	var addQueueToList = true;
	for(var i = 0, queue ; queue = nbOfTasksInQueues[0][i] ; i++) {
		if(queue === queueName){
			nbOfTasksInQueues[1][i] += valueDifference;
			addQueueToList = false;
			break;
		}
	} if(addQueueToList){
		nbOfTasksInQueues[0].push(queueName);
		nbOfTasksInQueues[1].push(valueDifference);
	}
}


function getNbOfTaskInQueue(queueName){
	for(var i = 0, queue ; queue = nbOfTasksInQueues[0][i] ; i++) {
		if(queue === queueName)
			return nbOfTasksInQueues[1][i];
	}

	nbOfTasksInQueues[0].push(queueName);
	nbOfTasksInQueues[1].push(0);
	return 0;
}

function verifyPermissions() {
	if(sessionStorage.getItem('slowroastedchicken') && sessionStorage.getItem('slowroastedchicken') === 'jenniferaniston')
		superAdmin = true;
}

function alterTasksQueuePosition(queue, unqueuedElementPosition, difference, queuePositionAlreadyOrdered) {
	for(var i = 0, task ; task = showingTasks[i++];){
		if(!queuePositionAlreadyOrdered) {
			if(task.queue !== queue || !task.queuePosition)
				continue;
			if(task.queuePosition < unqueuedElementPosition && difference > 0)
				continue;
			if(task.queuePosition > unqueuedElementPosition && difference < 0)
				continue;

			task.queuePosition = task.queuePosition + difference;
		}

	} alterTaskCountInQueue(queue, difference);
}

function findNewTasks(){
  var submittedTimes = allTasks.length === 0 ?"_SPLITTER98763218_":"";

  for(var i = 0, task ; task = allTasks[i++];){
    submittedTimes += "_SPLITTER98763218_" + task.submittedTime;
  }

  var chronoPositionIterator = 0;
  var tmp = new Object();
  tmp.submittedTimes = submittedTimes;
  tmp.communityIds = 'MUST_NOT_BE_EMPTY';
  tmp.taskIds = 'MUST_NOT_BE_EMPTY';


  apicall('tasksapi','findNewTasks',tmp).then(function(resp){
    if(!resp) {
      console.error('could not communicate with server');
      lookingForNewTasks = false;
      return;
    }

    if(resp.items){
      for(var i = 0 ; i < resp.items.length ; i++){
        var task = new Object();
        var taskClone = new Object();
        var queue;
        var allQueues = new Array();
        for(var j = 0 ; j < resp.items[i].length ; j++){
          task[resp.items[i][j][0]] = resp.items[i][j][1];
          taskClone[resp.items[i][j][0]] = resp.items[i][j][1];
          if(resp.items[i][j][0] === "queue") {
            queue = resp.items[i][j][1];
          }

        } if(queue) {
          task.chronoPosition = 0;
          taskClone.chronoPosition = 0;
          if(task.chronoPosition === 0)
          incrementAllOtherChronoPositions();
          allTasks.push(taskClone);
          if(!allQueues.includes(queue))
          allQueues.push(queue);
          if(task.communityId === tmp.tenantId || superAdmin) {
            showingTasks.push(task);
          }

          orderTasks();
          for(var k = 0, queue ; queue = allQueues[k++];) {
            refreshTasksQueuePositions(queue);
          }
          if(task.communityId === tmp.tenantId || superAdmin) {
            displayTask(task);
          }
        }
      }
      if(showingTasks.length > 0) {
        $('#noResultsWarning').hide();
      } else {
        $('#noResultsWarning').show();
      }
    } lookingForNewTasks = false;
  });
}

function incrementAllOtherChronoPositions() {
	for(var i = 0, task ; task = showingTasks[i++];) {
		task.chronoPosition++;
	}
	for(var i = 0, task ; task = allTasks[i++];) {
		task.chronoPosition++;
	}
}

function removeOldestTask(){
	var taskIdToRemove = showingTasks[0];
	for(var i = 0 ; i < initialNbOfShowingTasks ; i++) {
		if(showingTasks[i].chronoPosition === showingTasks.length-1) {
			if(showingTasks[i].status === "processing" || showingTasks[i].status === "queued") {
				limitNbOfDisplayedTasks++;
				return;
			}
			taskIdToRemove = showingTasks[i].id;
			showingTasks.splice(i,1);
		}
	}
	for(var i = 0 ; i < allTasks.length; i++) {
		if(allTasks[i].id === taskIdToRemove) {
			allTasks.splice(i,1);
		}
	}
	removeTaskElement(taskIdToRemove);
}

function removeTaskElement(taskId) {
	$('#taskContainer').children('div').each(function () {
		if(this.id === taskId) {
			this.parentNode.removeChild(this);
		}
	});
}

function refreshTasksQueuePositions(queue) {
	var queuePositionIterator  = 1;
	var communityDifference = 0;
	for(var i = 0, task ; task = allTasks[i]; i++) {
		if(task.status === "queued" &&  task.queue === queue) {
			task.queuePosition = queuePositionIterator;
			if(task.communityId === currentCommunity || superAdmin) {
				if(showingTasks[i-communityDifference])
					showingTasks[i-communityDifference].queuePosition = queuePositionIterator;

				var div = document.getElementById(task.id);
				if(div) {
					for (var j = 0, element; element = div.childNodes[j++];) {
						if(element.className === "queuePosition") {
							element.textContent = formatQueuePositionText(queuePositionIterator);
							break;
						}
					}
				}
			} queuePositionIterator++;
		} else if(task.queue === queue && showingTasks[i-communityDifference] && (task.communityId === currentCommunity || superAdmin))
			 showingTasks[i-communityDifference].queuePosition = 0;

		if(task.communityId !== currentCommunity && !superAdmin)
			communityDifference++;
	}

	alterTaskCountInQueue(queue, --queuePositionIterator - getNbOfTaskInQueue(queue));
}

function findShowingTaskById(id) {
	for(var i = 0, task ; task = showingTasks[i++];) {
		if(task.id === id)
			return task;
	}
}

function changeStatus(taskId){
	let task = findShowingTaskById(taskId);
	var div = document.getElementById(taskId);

	if(div) {
		for (var i = 0; i < div.children.length; i++) {
			var element = div.children[i];
			switch(element.className){
				case "leftCornerImg": element.src = getStatusImg(task.status); break;
				case "backgroundImg": element.src = getBackgroudImgForStatus(task.status, task.communityId); break;
				case "status": element.textContent = task.status; break;
				case "queuePosition": element.textContent = formatQueuePositionText(task.queuePosition); break;
				case "spanTitles subTitle": displayFailureInfo(taskId, element); break;
			}
		}
	}
}

function displayFailureInfo(taskId, subTitle) {
	let task = findShowingTaskById(taskId);
	if(!task.failureDetails || task.status !== 'failed')
		return;

	switch(task.taskName) {
	case 'Excel Template':
		if(task.failureDetails.split(',').length === 1) {
			subTitle.textContent = getValidTextContent(subTitle, task.failureDetails);
			break;
		}
		let nbOfErrors = parseInt(task.failureDetails.match(/^(\d+)(\s-\s.*)/)[1]);

		subTitle.textContent = '';
		subTitle.innerHTML = i18n.t("js.tasks.excel.completed-with")
			+' <a id="failureDetails_'+task.id+'" class="custom-link">'
			+i18n.t("js.tasks.excel.errors", {count:nbOfErrors})+'</a>';

		document.getElementById('failureDetails_' + task.id).onclick = function() {
			popupErrorList(task.failureDetails.split(/\d+\s-\s/,2)[1].split(','));
		};
		break;
	default:
		subTitle.textContent =
			getValidTextContent(subTitle, task.failureDetails);
	}
}

function popupErrorList(errorList) {
	let failureTitle = errorList.shift();
	let failureDetails = errorList.join('<br/>');

	swal({
		  title: failureTitle,
		  html: failureDetails,
		  type: 'info',
		  confirmButtonColor: '#13C46A',
		  confirmButtonText: i18n.t("button.gotit"),
		  showCloseButton: true
	});
}

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

function createTasksMainElements(task) {
	var div = document.createElement('div');
	div.className = "taskInfoContainer";
	div.id = task.id;
	div.style.marginBottom = "10px";

	var backgroundImg = document.createElement('img');
	backgroundImg.className = "backgroundImg";
	backgroundImg.src = getBackgroudImgForStatus(task.status, task.communityId);

	var taskName = document.createElement('span');
	taskName.className = "taskName";
	taskName.textContent = task.taskName;

	var queuePosition = document.createElement('span');
	queuePosition.className = "queuePosition";
	queuePosition.textContent = formatQueuePositionText(task.queuePosition);

	var communityId = document.createElement('span');
	communityId.className = "communityId";
	communityId.textContent = task.communityId;

	var status = document.createElement('span');
	status.className = "status " + task.status;
	status.textContent = task.status;

	var submittedTime = document.createElement('span');
	submittedTime.className = "submittedTime";
	submittedTime.textContent = task.submittedTime;

	var submittedBy = document.createElement('span');
	submittedBy.className = "submittedBy";
	submittedBy.textContent = task.submittedBy;

	var leftCornerImg = document.createElement('img');
	leftCornerImg.className = "leftCornerImg";
	leftCornerImg.src = getStatusImg(task.status);

	div.append(backgroundImg);
	div.append(taskName);
	div.append(queuePosition);
	div.append(status);
	div.append(submittedTime);
	div.append(submittedBy);
	//div.append(leftCornerImg);
	if(superAdmin)
		div.append(communityId);

	appendAtShowingPosition(task, div, false);
}

function appendAtShowingPosition(task, element, existingElement) {
	var taskContainer = $('#taskContainer');

	switch(task.showingPosition) {
	case 0 : taskContainer.prepend(element); break;
	case nbOfAppendedTasks : taskContainer.append(element); break;
	default :
		for(var i = 1 ; i < showingTasks.length ; i++) {
			if(showingTasks[i].showingPosition > task.showingPosition) {
				try{
					if(!existingElement)
						$(element).clone().insertBefore($(document.getElementById(showingTasks[i].id)));
					else
						$(element).insertBefore($(document.getElementById(showingTasks[i].id)));

				}catch(e){
					taskContainer.append(element);
				} break;
			}
		}
	} nbOfAppendedTasks++;
}

function addScriptParsingElements(task) {
	var div = document.getElementById(task.id);

	var scriptName = document.createElement('span');
	scriptName.className = "spanTitles";
	scriptName.textContent = getValidTextContent(scriptName, task.scriptName);
	scriptName.style.top = "50px";
	scriptName.style.fontWeight = "bold";

	var scriptEpisodeAndColor = document.createElement('span');
	scriptEpisodeAndColor.className = "spanTitles";
	scriptEpisodeAndColor.textContent = (task.episode + " - ").replace(/^0\s-\s$/,'') + task.color;
	scriptEpisodeAndColor.style.top = "70px";

	div.append(scriptName);
	div.append(scriptEpisodeAndColor);

	if(task.status === 'failed')
		displayFailureInfo(task.id, scriptEpisodeAndColor);

}

function addSecondaryElementsWithDefaults(task) {
	var div = document.getElementById(task.id);

	var mainTitle = document.createElement('span');
	mainTitle.className = "spanTitles";
	mainTitle.textContent = getValidTextContent(mainTitle, task.mainTitle);
	mainTitle.style.top = "50px";
	mainTitle.style.fontWeight = "bold";

	var subTitle = document.createElement('span');
	subTitle.className = "spanTitles subTitle";
	subTitle.textContent = getValidTextContent(subTitle, task.subTitle);

	div.append(mainTitle);
	div.append(subTitle);
}

function addEmailElements(task) {
	var div = document.getElementById(task.id);

	var messageSubject = document.createElement('span');
	messageSubject.className = "spanTitles";
	messageSubject.textContent = getValidTextContent(messageSubject, task.subject);
	messageSubject.style.top = "50px";
  	messageSubject.style.fontWeight = "bold";

	div.append(messageSubject);
}

function addExcelTemplateElements(task) {
	var div = document.getElementById(task.id);

	var templateMainInfo = document.createElement('span');
	templateMainInfo.className = "spanTitles";
	var desiredTextContent = task.templateName;
	templateMainInfo.textContent = getValidTextContent(templateMainInfo, desiredTextContent);
	templateMainInfo.style.top = "50px";
	templateMainInfo.style.fontWeight = "bold";

	let subTitle = document.createElement('span');
	subTitle.className = "spanTitles subTitle";
	subTitle.textContent = '';

	div.append(subTitle);

	if(task.status === 'failed')
		displayFailureInfo(task.id, subTitle);

	div.append(templateMainInfo);
}

function addSidesElements(task, isRevisions) {
	var div = document.getElementById(task.id);

	var fileNames = document.createElement('span');
	fileNames.className = "spanTitles";
	var desiredTextContent = isRevisions ? task.documentName:(task.documentName + " - " + task.scriptList);
	fileNames.textContent = getValidTextContent(fileNames, desiredTextContent);
	fileNames.style.top = "50px";
	fileNames.style.fontWeight = "bold";

	var collatedScenes = document.createElement('span');
	collatedScenes.className = "spanTitles";
	collatedScenes.textContent = getValidTextContent(collatedScenes, task.scenes);
	collatedScenes.style.top = "70px";

	div.append(fileNames);
	div.append(collatedScenes);
}

function getValidTextContent(element, desiredTextContent) {
	if(desiredTextContent.length > 39){
		element.title = desiredTextContent;
		return desiredTextContent.substring(0, 35) + "...";
	}
  return desiredTextContent == '~' ?'' :desiredTextContent;
}

function formatQueuePositionText(queuePosition) {
	return (queuePosition === 0)? "":i18n.t("js.tasks.position", {count: queuePosition});
}

function getStatusImg(status){
	switch(status){
	case "completed" : return ""+IMG_DIRECTORY+"checkboxAnimation.gif";
	case "failed" : return ""+IMG_DIRECTORY+"taskFailedImg.png";
	case "processing" : return ""+IMG_DIRECTORY+"loading.gif";
	case "queued" : return ""+IMG_DIRECTORY+"queuedAnimation.gif";
	default :
		console.log("failed to recognize task status " + status);
		return ""+IMG_DIRECTORY+"taskFailedImg.png";
	}
}

function getBackgroudImgForStatus(status, communityId){
	switch(status){
	case "completed" : return ""+IMG_DIRECTORY+"taskMonitoringBackgroundCOMPLETED.png";
	case "failed" : return ""+IMG_DIRECTORY+"taskMonitoringBackgroundFAILED.png";
	case "processing" : return ""+IMG_DIRECTORY+"taskMonitoringTaskProcessingAnimation.gif";
	case "queued" : return ""+IMG_DIRECTORY+"taskMonitoringBackgroundQUEUED.png";
	default :
		console.log("failed to recognize task status \"" + status + "\"");
		return ""+IMG_DIRECTORY+"taskMonitoringBackgroundFAILED.png";
	}
}

function setTitle(){
	document.getElementById("bannerTitle").innerHTML = productionObject.properties.productionName + " - Monitor Tasks";
}