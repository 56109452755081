import i18n from '../Translation';
export default class WatermarkNotification {
  constructor() {
    
  }
  
  static load() {
    window.apicall('notificationapi', 'fetchVisibleNotifications', { 
      type: 'watermark' 
    }, undefined, false).then(resp => {
      if(Array.isArray(resp.items)) {
        for(let notification of resp.items) {
          WatermarkNotification.add(notification.is_user_notified, 
            notification.reference_id, null, notification.time_created);
        }
      } else {
        throw new Error('Invalid server response');
      }
    }).catch((err) => {
      console.error('Failed to load watermark notifications.', err);
    });
  }
  
  static add(isUserNotified, referenceId, wmFileName = null, timeCreated) {
    let notifDiv = wmFileName === null 
      ?null 
      :WatermarkNotification.build(wmFileName, i18n.t("js.notification.watermark.init"), referenceId, timeCreated);
    WatermarkNotification.trackProgress(referenceId, notifDiv, 
      isUserNotified, referenceId, timeCreated);

  }

  static build(title, content, referenceId, timeCreated) {
    let notifDiv = document.createElement('div');
    notifDiv.innerHTML = '<div class="wm-notif-title">'+(title||'')+'</div>' + '<div class="wm-notif-content">'+(content||'')+'</div>';
    window.cgNotification.addNotification(i18n.t("watermark.title"), notifDiv, null, false, function() {
      window.apicall('notificationapi', 'updateNotificationVisibility', 
        { referenceId: referenceId, isVisible: false }, undefined, false)
        .then(function(wsr) {
          if(wsr.responseCode !== '0') {
            throw new Error('server error');
          }
          // this notification will not be shown anymore
      }).catch(() => {
        console.error('notif visibility update failed');
      });
    }, timeCreated);
    return notifDiv;
  }

  static trackProgress(taskId, notifDiv, isUserNotified, referenceId, timeCreated) {
    let progressTracker = null;
    apicall('scriptapi', 'saveDummy', {}).then((resp) => { // initiating API
      progressTracker = setInterval(checkProgress, 2000);
      checkProgress();
    });

    function checkProgress() {
      console.debug('checking progress');
      const failedTaskErrMsg = 'failed task';
      apicall('scriptapi', 'fetchWatermarkPrinterStatus', { taskId: taskId }, 
        undefined, false).then(resp => {
        if(notifDiv === null && resp.contentName) {
          notifDiv = WatermarkNotification.build(resp.contentName
            .substring(resp.contentName.startsWith('wp_') ?3 :0), '', 
            referenceId, timeCreated);
        }
        if (resp.responseCode == '-1') {
          throw new Error(failedTaskErrMsg);
        } else {
          let notifContent = notifDiv.querySelector('div.wm-notif-content');
          if (parseInt(resp.responseCode) === 100) {
            clearInterval(progressTracker);
            notifContent.innerHTML = `<a href="${resp.contentURL}">${i18n.t("js.notification.download.click")}</a>`;
            if(!isUserNotified) {
              window.cgNotification.addCounter(1, referenceId);
            }
          } else {
            notifContent.innerHTML = i18n.t("js.notification.watermark.progress", {progress: resp.responseCode})
              .replace(/[\s\S]+\s0%$/, i18n.t("js.notification.watermark.init"))
              .replace(/[\s\S]+\s99%$/, i18n.t("js.notification.watermark.fin"));
          }
        }
      }).catch(err => {
        let isProcessFailed = err instanceof Error && err.message == failedTaskErrMsg;
        if(notifDiv !== null) {
          notifDiv.querySelector('div.wm-notif-content').innerHTML 
            = `<span style="color:red">${i18n.t(isProcessFailed ? "js.notification.download.failed":"js.utils.server.error")}</span>`;
          if(!isUserNotified) {
            window.cgNotification.addCounter(1, referenceId);
          }
        }
        clearInterval(progressTracker);
        console.error(isProcessFailed 
            ?'watermark process failed' 
            :'fetchWatermarkPrinterStatus server error');
      });
    }
  }
  
}