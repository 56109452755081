import i18n from './Translation';
import CrooglooSub from './CrooglooSub.js';

export default class SubscriptionManager {
  constructor(subscriptionInfo) {
    for(let key in subscriptionInfo) {
      this[key] = subscriptionInfo[key];
    }
    this.UNRESTRICTED = null;
    this.PUBLIC_KEY = null;
    this.CURRENCY = null;
    console.debug('subscription info:');
    console.debug(subscriptionInfo);
  }

  updateStripeConfig() {
    let self = this;
    return new Promise(resolve => {
      new TaskList({passReference: true})
      .add(list => {
        fetchPublicKey(() => list.check('public_key'));
        fetchCurrency(() => list.check('currency'));
      }, ['public_key', 'currency'])
      .oncomplete(resolve).execute();
    });

    function fetchPublicKey(done) {
      if(typeof self.PUBLIC_KEY === 'string') {
        done();
        return;
      }
      apicall('productionapi', 'fetchStripePublicKey', {}).then(resp => {
        if(resp.responseCode === '0') {
          self.PUBLIC_KEY = resp.responseMessage;
          if(self.PUBLIC_KEY.includes('test')) {
            console.warn('stripe payment is in test mode');
          }
          done();
        } else {
          throw new Error(resp);
        }
      }).catch(err => {
        console.error(err);
        hideSpinner();
        swalToast({
          title: i18n.t("js.sub.mnger.setting.error"),
          type: 'error'
        });
      });
    }

    function fetchCurrency(done) {
      if(typeof self.CURRENCY === 'string') {
        done();
        return;
      }
      Utility.loadScript('/assets/billing/CurrencyFinder.js', () => {
        CurrencyFinder.getCurrency(currency => {
          self.CURRENCY = currency;
          done();
        });
      });
    }
  }

  checkout(planId) {
    let self = this;
    let stripeHandler;

    return new Promise(async function(resolve) {
      try {
        showSpinner();
        await self.updateStripeConfig();
        setupPaymentRequests(resolve);
        processPayment(croogloo_auth.tenantId, planId);
      } catch(err) {
        console.error(err);
        resolve(null);
      }
    });

    function setupPaymentRequests(resolve) {
      stripeHandler = StripeCheckout.configure({
        key: self.PUBLIC_KEY,
        image: 'https://www.mycroogloo.com/assets/img/logo_8.5x5.88-rgb.png',
        locale: 'auto',
        shippingAddress: true,
        billingAddress: false,
        currency: self.CURRENCY,
        token: function(token, addresses) {
          window.isPaymentSubmitted = true;
          token.currency = self.CURRENCY;
          resolve({ token: token, addresses: addresses });
        },
        closed: function() {
          if(!window.isPaymentSubmitted) {
            resolve(null);
          } else {
            delete window.isPaymentSubmitted;
          }
        }
      });
    }

    function processPayment(tenantId, planId) {
      window.tenantId = tenantId;
      let crooglooSub = new CrooglooSub(planId);
      console.debug(crooglooSub);
      stripeHandler.open({
        name: crooglooSub.getName(),
        description: crooglooSub.getDescription(),
        amount: crooglooSub.getAmount()
      });
      hideSpinner();
    }

    window.addEventListener('popstate', function() {
      stripeHandler.close();
    });
  }

  async endTrial() {
    let self = this;
    if(croogloo_auth.crooglooauth.productionOwnerEmail
        !== croogloo_auth.crooglooauth.email 
        && croogloo_auth.crooglooauth.securityListId !== 'ADMIN') {
      console.debug('logged in user: ' + croogloo_auth.crooglooauth.email);
      console.debug('production owner: ' + croogloo_auth.crooglooauth.productionOwnerEmail);
      if(this.trialDaysLeft > 0) {
        swalToast({
          title: i18n.t("js.sub.mnger.upgrade.admin"),
          type: 'warning'
        });
      } else {
        window.allowedAPIMethods = ['activateSubscriptionPlan'];
        swal({
          title: i18n.t("js.sub.mnger.expired.trial.title"),
          html: i18n.t("js.sub.mnger.expired.trial.text"),
          type: 'warning',
          showConfirmButton: true,
          confirmButtonText: i18n.t("OK"),
          showCancelButton: false,
          showCloseButton: false,
          allowOutsideClick: false,
          useRejections: false,
          expectRejections: false
        }).then(validateFurtherAccess).catch(validateFurtherAccess);
      }
      return;
    }
    showSpinner();
    await SubscriptionManager.initStore();
    await self.updateStripeConfig();
    if(CrooglooSub.areManyPlansForProductionType()) {
      hideSpinner();
      let isPlanConfirmed = await new Promise(resolve => {
        confirmSubscriptionPlan(resolve);
      });
      if(!isPlanConfirmed) {
        console.log('plan could not be confirmed');
        validateFurtherAccess();
        return;
      }
      showSpinner();
    }
    setupPaymentRequests(croogloo_auth.crooglooauth.email,
      croogloo_auth.crooglooauth.productionType, croogloo_auth.crooglooauth.planId);
    processPayment(croogloo_auth.tenantId, croogloo_auth.crooglooauth.email,
      croogloo_auth.crooglooauth.planId);
    let stripeHandler;
    let genericErrMsg = i18n.t("js.sub.mnger.payment.error");
    function setupPaymentRequests(email, product, planId) {
      stripeHandler = StripeCheckout.configure({
        key: self.PUBLIC_KEY,
        image: 'https://www.mycroogloo.com/assets/img/logo_8.5x5.88-rgb.png',
        locale: 'auto',
        shippingAddress: true,
        billingAddress: false,
        currency: self.CURRENCY,
        token: function(token, addresses) {
          showSpinner();
          window.isPaymentSubmitted = true;
          window.apicall('securityadminapi','activateSubscriptionPlan', {
            email: token.email,
            product: product,
            planId: planId,
            stripeToken: token.id,
            cardId: token.card.id,
            currency: self.CURRENCY
          }, addresses, false)
          .then(resp => {
            hideSpinner();
            if(resp.responseCode === '0') {
              swal({
                title: i18n.t("response.success"),
                html: i18n.t("js.sub.mnger.payment.success", { plan: `<b>${new CrooglooSub(planId).getName()}</b>` }),
                type: 'success',
                confirmButtonColor: '#13C46A',
                confirmButtonText: i18n.t("OK"),
                useRejections: false,
                expectRejections: false
              }).then(onProdActivated).catch(onProdActivated);
              function onProdActivated() {
                window.allowedAPIMethods = self.UNRESTRICTED;
                croogloo_auth.crooglooauth.isTrial = false;
                document.getElementById('trialBar').style.display = 'none';
                croogloo_auth.validateSubscription();
              }
            } else {
              let subErrMsg = resp.responseMessage == 'invalid tax data' 
                ?i18n.t("js.sub.mnger.invalid.shipping-address") 
                :genericErrMsg;
              reportSubscriptionFailure(subErrMsg);
            }
          }).catch(function(err) {
            hideSpinner();
            console.error(err);
            reportSubscriptionFailure();
          });
          function reportSubscriptionFailure(subErrMsg = genericErrMsg) {
            swal({
              title: i18n.t("response.error"),
              html: i18n.t("js.sub.mnger.transaction.failed", {subErrMsg}),
              type: 'error',
              confirmButtonColor: '#13C46A',
              confirmButtonText: i18n.t("OK"),
              timer: 10000
            }).then(validateFurtherAccess).catch(validateFurtherAccess);
          }
        },
        opened: function() {
          hideSpinner();
          if(!self.trialDaysLeft) {
            window.allowedAPIMethods = ['activateSubscriptionPlan'];
          }
        },
        closed: function() {
          if(!window.isPaymentSubmitted) {
            swal({
              title: i18n.t("js.sub.mnger.payment.cancelled"),
              type: 'warning',
              showConfirmButton: false,
              allowOutsideClick: false,
              timer: 4000
            }).then(validateFurtherAccess).catch(validateFurtherAccess);
          } else {
            delete window.isPaymentSubmitted;
          }
        }
      });

      window.addEventListener('popstate', function() {
        stripeHandler.close();
      });
    }

    function confirmSubscriptionPlan(done) {
      let plan = new CrooglooSub(croogloo_auth.crooglooauth.planId);
      swal({
        title: i18n.t("js.sub.mnger.plan.title"),
        html: '<div style="text-align:center;margin-bottom:1rem"><b>'
          + i18n.t("js.sub.mng.text.reg", {planName: (plan.getName()||'Croogloo Office')})
          + '</b></div><div><select id="subscriptionPlanSelect">' + plan.optionsOfTypeAsHtml() + '</select></div>',
        type: 'info',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("button.submit"),
        showLoaderOnConfirm: true,
        useRejections: true, //important for swal2 v7.1.2
        expectRejections: true,
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        onOpen: function() {
          try {
            document.getElementById('swal2-content').style.textAlign = 'justify';
          } catch(e) {
            console.error(e);
          }
        },
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            let customReject = function(msg) {
              document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'pointer';
              reject(msg);
            }
            let newSubOption = document.getElementById('subscriptionPlanSelect');
            var newSubPlanId = newSubOption.value;
            if(!newSubPlanId) {
              customReject(i18n.t("js.sub.mng.reject.select"));
              return;
            }
            if(newSubPlanId == plan.product.planId) {
              console.log('same plan selected, resolving');
              resolve();
              return;
            }
            document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'not-allowed';
            let errMsg = i18n.t("js.sub.mng.server.err", {email:'support@croogloo.com'});
            apicall('securityadminapi', 'changeSubscriptionPlan', {newPlanId: newSubPlanId,}).then(function(resp) {
              console.debug(resp);
              if(typeof resp === 'object' && resp.responseCode === '0') {
                croogloo_auth.crooglooauth.planId = newSubPlanId;
                resolve(newSubPlanId);
              } else {
                throw new Error('server error');
              }
            }, err => customReject(errMsg)).catch(err => customReject(errMsg));
          });
        }
      }).then(() => done(true)).catch(() => done(false));
    }

    function validateFurtherAccess() {
      if(self.isTrial && !self.trialDaysLeft) {
        window.forceSwitchProd = true;
        if(typeof window.switchCommunity == 'function' &&
            typeof window.hasAccessToOtherCommunities == 'function' &&
            window.hasAccessToOtherCommunities()) {
          window.switchCommunity();
        } else {
          croogloo_auth.logout();
        }
      } else {
        window.allowedAPIMethods = self.UNRESTRICTED;
      }
    }

    function processPayment(tenantId, email, planId) {
      window.tenantId = tenantId;
      let crooglooSub = new CrooglooSub(planId);
      console.debug(crooglooSub);
      stripeHandler.open({
        name: crooglooSub.getName(),
        description: crooglooSub.getDescription(),
        amount: crooglooSub.getAmount()
      });
    }
  }

  static initStore() {
    if(Array.isArray(global.productList) && global.productList.length) {
      return Promise.resolve(global.productList);
    }
    return new Promise(resolve => {
      Utility.loadScript('/assets/billing/CurrencyFinder.js', () => {
        CurrencyFinder.getCurrency(currency => {
          $.ajax({
            url: devEndpoint + "/fetchStoreProducts",
            type: "get",
            crossDomain: true,
            data: {
              currency: currency,
              activePlanId: croogloo_auth.crooglooauth.planId
            },
            success: function(resp) {
              try {
                let productList = JSON.parse(resp);
                if(Array.isArray(productList) && productList.length) {
                  global.productList = productList;
                  resolve(productList);
                } else {
                  console.error('invalid response', resp);
                  global.productList = [];
                  resolve([]);
                }
              } catch(e) {
                console.error(e);
                global.productList = [];
                resolve([]);
              }
            },
            error: (xhr, status, error) => {
              console.error(xhr, status, error);
              global.productList = [];
              resolve([]);
            }
          });
        });
      });
    });
  }
}
