import i18n from './Translation';

export default class CGGoogleAuth {
  static preventLoginDefault = false;

  static init() {
    return new Promise(async function (resolve, reject) {
      /**
       * Takes a JWT token response and handles it by parsing it and then getting an OAuth 2.0 token.
       * @param {object} response 
       */
      function handleCredentialResponse(response) {
        // One Tap Sign in returns a JWT token
        if (response) {
          // We are passing the signed in email id to oAuth
          // If we pass an email id to oAuth consent and the user has already given the oAuth consent, it will get auto selected.
          const responsePayload = CGGoogleAuth.parseJwt(response.credential);
          oauthSignIn(responsePayload.email);
        } else {
          console.error("Invalid response from google received. Expected a JWT token, instead received a falsy response: " + response);
        }
      }

      function oauthSignIn(googleId) {
        const client = google.accounts.oauth2.initTokenClient({
          client_id: '849074252471-u0u1j9iq2qe76qden13cidv2efdvo1e8.apps.googleusercontent.com',
          scope: 'https://www.googleapis.com/auth/drive',
          hint: googleId,
          prompt: '', // Specified as an empty string to auto select the account which we have already consented for use.
          callback: (tokenResponse) => {
            croogloo_auth.crooglooauth.gDriveAccessToken = tokenResponse.access_token;
            secureLocalStorage.set("crooglooauth", JSON.stringify(croogloo_auth.crooglooauth));
            resolve();
          },
        });
        client.requestAccessToken();
      }

      let crooglooauthLocal = JSON.parse(secureLocalStorage.get('crooglooauth'))

      if (crooglooauthLocal.gDriveAccessToken && crooglooauthLocal.gDriveAccessToken !== undefined) {
        croogloo_auth.crooglooauth.gDriveAccessToken = crooglooauthLocal.gDriveAccessToken
        resolve()
      }
      else {
        croogloo_auth.crooglooauth.gContactsAccessToken = undefined;
        google.accounts.id.initialize({
          client_id: '849074252471-u0u1j9iq2qe76qden13cidv2efdvo1e8.apps.googleusercontent.com',
          callback: handleCredentialResponse,
          cancel_on_tap_outside: false,
          prompt_parent_id: 'root'
        });

        google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
            google.accounts.id.prompt()
          }
        })
      }
    });
  }

  /**
  * Parses a JSON Web Token (JWT) and returns its payload as a JSON object.
  * JWT tokens consist of three parts: header, payload, and signature, separated by periods ('.').
  * This function extracts the payload part, decodes it from URL-safe base64 encoding, and returns it as a JSON object.
  *
  * @example
  * Example JWT token (header and signature are truncated for brevity):
  * "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.XXX"
  * The decoded JSON payload would be:
  * { "sub": "1234567890", "name": "John Doe", "iat": 1516239022, "email": "johndoe@example.com" }
  * 
  * Note that this is only an example, and that different JWT tokens will have different payloads.
  * 
  * @param {string} token - The JWT to be parsed.
  * @return {object} The decoded JSON payload of the JWT.
  */
  static parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString().split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  static signIn(callback) {
    CGGoogleAuth.init().then(() => {
      callback();
    });
  }

  static signOut(callback) {
    let crooglooAuthLocal = JSON.parse(secureLocalStorage.get('crooglooauth'))
    crooglooAuthLocal.gDriveAccessToken = undefined
    secureLocalStorage.set('crooglooauth', JSON.stringify(crooglooAuthLocal))
  }

  static onSignIn(e) {
    console.debug('on Google sign in');
    if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
      cgToast(i18n.t("js.google.link"), {
        containerID: 'docBody',
        className: 'body-attached'
      });
      if (typeof window.onGoogleSignIn === 'function') {
        console.warn('calling window sign in callback');
        window.onGoogleSignIn();
      }
    } else {
      cgToast(i18n.t("js.google.unlink"), {
        // must be shown while redirecting to documents' root folder
        containerID: 'docBody',
        className: 'body-attached'
      });
      if (typeof window.onGoogleSignOut === 'function') {
        console.warn('calling window sign out callback');
        window.onGoogleSignOut();
      }
      if (window.location.hash.startsWith('#documents')
        && window.location.hash !== '#documents') {
        r.loadPage('documents');
      }
    }
  }

  static async apicall(gDrivePath) {
    // TODO this should be private and fetched from Google Secret Manager.
    const API_KEY = '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'

    return new Promise((resolve, reject) => {

      let folderId = gDrivePath[gDrivePath.length - 1].folderId
      let requestURL = gDrivePath.length === 1 ? 'https://www.googleapis.com/drive/v3/files' : `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents&fields=files(id,name,mimeType,createdTime,modifiedTime,parents),nextPageToken&orderBy=name&key=${API_KEY}`

      fetch(requestURL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${croogloo_auth.crooglooauth.gDriveAccessToken}`
        }
      })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(error => console.error(error));
    });
  }

}
