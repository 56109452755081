import i18n from './components/Translation';
import DocUtility from './components/DocUtility';
import WatermarkNotification from './components/notifications/WatermarkNotification.js';
import swal from 'sweetalert2';
import Upload from './components/Upload.js';
import UploadManager from './components/UploadManager.js';
import FileDropper from './components/FileDropper.js';

export function init() {
  loader.add(initGlobals, 'globals')
    .add(ui, 'foundation')
    .add(loadExtraLibraries)
    .add(initFileTreeListeners)
    .add(loadAdvSettings)
      .add(getMetaData, ['documents', 'distroLists', 'scriptApi', 'persons'])
    .add(initOtherListeners, 'upload-listeners')
    .execute();
}

function ui() {
  $('#main').foundation();
  $('#extraMemberSelect').chosen({
    no_results_text: i18n.t("js.wtmrk.enter"),
    width: "100%"
  }).change((evt, params) => {
    if (params.deselected) {
      $('#extraMemberSelect').trigger('chosen:updated');
    }
  });
  setTitle();
  loader.check('foundation');
}

var Dragdealer;
var fileCollection;
var crooglooFolderMap;
var hasFileCollectionChanged;
var selectedDocuments;
var selectedDistro;
var cachedDistributionGroup;
var taskRunning;
var distroList;
var status;
var message;
var defaultSettings;
var upload
var fileDropper;
var dropzoneContainerClone;

const MAX_SELECTED_FILES = 5;

function initGlobals() {
  fileCollection = [];
  crooglooFolderMap = {};
  hasFileCollectionChanged = false;
  selectedDocuments = new Object();
  selectedDistro = [];
  cachedDistributionGroup;
  taskRunning = false;
  distroList = "";
  defaultSettings = new Object();
  setDefaultSettings()
  status = "";
  message = "";
  upload = null;
  fileDropper = null
  dropzoneContainerClone = document.getElementById('dropzoneContainer')

  loader.check('globals');
}

const uuid = require('uuid/v4');
require('jstree');

function loadExtraLibraries() {
  Utility.loadStylesheet('/assets/jstree/style.min.css');
  Utility.loadStylesheet('/assets/jstree/js-tree-custom.css');
}

function initFileTreeListeners() {

  document.getElementById('fileUploadBtn').onclick = toggleFileViewer; 
  document.querySelector('#jstreeCloseBtn').onclick =  toggleFileViewer;
}

function getMetaData() {
  showSpinner();
  initCrooglooDocs(() => loader.check('documents'));
    initDistroGroups(() => loader.check('distroLists'));
    preloadScriptApi(() => loader.check('scriptApi'));
    fetchPersons(() => loader.check('persons'));
  initFileInSessionStorage();
}

// Unused right now because we only allow to watermark for distro lists not individuals
function fetchPersons(done) {
    const maxContactsPerRequests = 300;
    let wtmrkContacts = loadSelectedFromContactsPage();
    apicall('personapi', 'countContactPages', { pageSize: maxContactsPerRequests }).then(async (resp) => {
        try {
            fetchContacts();
        } catch (error) {
            console.log(error);
        }
    });

  //Backup option in case of an error
  function fetchContacts(){
    apicall('personapi', 'fetchContacts', {}).then(async (resp) => {
      if (resp && resp.items) {
        appendContacts(resp.items);
        $('#extraMemberSelect').trigger('chosen:updated');
          endExtraMemberLoading();
          hideSpinner();
        done();
      }
    });
  }

  function appendContacts(items){
    let select = document.getElementById('extraMemberSelect');
    
    items.sort((a,b) => {
      return Utility.compare(a.properties.firstName, b.properties.firstName) || Utility.compare(a.properties.lastName, b.properties.lastName)
    })

    items.forEach(person => {
      try {
        let option = document.createElement('option');
        option.value = person.key.name;
        option.textContent = person.properties.title || Utility.extractPersonTitle(person.properties);
        option.className = 'extra-person';
        if(wtmrkContacts != null && wtmrkContacts.includes(person.key.name)){
          option.selected = true;
        }
        select.appendChild(option);
      } catch(e) {
        console.error(e);
      }
    });
  }

  function loadSelectedFromContactsPage(){
    const wtmrkFromContactKey = 'watermark_contacts';
    let wtmrkContacts = sessionStorage.getItem(wtmrkFromContactKey);
    if(wtmrkContacts != null){
      sessionStorage.removeItem(wtmrkFromContactKey);
      return wtmrkContacts.split(',');
    }
    return null;
  }

  function endExtraMemberLoading() {
    let extraMemberSelect = document.getElementById('extraMemberSelect');
    extraMemberSelect.setAttribute('data-placeholder', i18n.t("js.wtmrk.contacts"));
    $(extraMemberSelect).prop('disabled', false).trigger('chosen:updated');
  }
}

function preloadScriptApi(done) {
  apicall('scriptapi', 'saveDummy', {}).then((resp) => {
    done();
  });
}

function initOtherListeners() {

  preventMultiselectLineOne('line-one-chk');
  preventMultiselectLineTwo('line-two-chk');
  preventMultiselectLineThree('line-three-chk');

  forceCheckOnFocus('line-text-input');

  document.getElementById('wmIPAddress').onclick = function () {
    if (defaultSettings.lineTwo == this) {
        cgToast(i18n.t("js.wtmrk.IP-warning"), 5000)
    }
  };
  document.getElementById('js-tree-select').onclick = selectDocument;

  $('#main').off('keydown');
  $('#main').on('keydown', e => {
    // accepts: [tab] and [,] convert to [enter]
    if ([9, 188].includes(e.keyCode) && e.target && e.target.tagName == 'INPUT' && $(e.target).hasClass('chosen-search-input')) {
      e.preventDefault();
      let e2 = jQuery.Event('keyup');
      e2.keyCode = 13
      $(e.target).trigger(e2);
    }
  });

  $('#extraMembersContainer').find('input.chosen-search-input').keyup(e => {
    console.debug(e);
    console.debug($('#extraMembersContainer').find('li.active-result'));
    if (e.keyCode === 13 &&
      $('#extraMembersContainer').find('li.active-result').length === 0) {
      let inputValue = $('#extraMembersContainer').find('input.chosen-search-input').val();
      addExtraName(inputValue);
    }
  });

  document.getElementById('wmAndPrintBtn').onclick = processWatermarkingJob;
  document.getElementById('resetBtn').onclick = () => r.reload(); // reset via soft reload
  
  initUploadListeners(() => loader.check('upload-listeners'));

  async function initUploadListeners(done) {
    if(!window.uploadManager) {
      new UploadManager();
    }
  
    // if you change second parameter in Upload constructor you can get modify
    // the select category options in the Swal

    upload = await new Upload(null, 'reportsAndSchedules');
  
    fileDropper = new FileDropper('myDropzone', i18n.t("js.upload.drop"), (files, event) => uploadFiles(files));

    let fileUploadInput = document.getElementById('fileUploadInput');
    fileUploadInput.onchange = event => {
      uploadFiles(fileUploadInput.files);
    }

    document.getElementById('myDropzone').onclick = function() {
      document.getElementById('fileUploadForm').reset();
      fileUploadInput.click();
    }

    document.getElementById('scriptListUpdateBtn').onclick = () => {
      showSpinner();
      getMetaData(hideSpinner);
    };

    done();
  }

}

function uploadFiles(files) {
  upload.toGoogleCloudStorage(files).then((result) => {
    console.debug('upload success', result);

    // for uploaded document(s), loops over docs that were uploaded and assigns
    // them to selectDocuments object which allows for the docs to appear
    // on the same page without having to reselect them to show them

    for(let i=0; i<result.length; i++) {
      if(!Object.keys(selectedDocuments).includes(result[i].id)) {
        selectedDocuments[result[i].id] = {
          id: result[i].id,
          name: result[i].newname,
          type: 'file',
          nodeID: null,
          uploaded: true
        }
      }

      showSelectedDocument(result[i])
    }
  }, (errFileName) => {
    console.error('failed upload ' + errFileName);
  });
}

function setDefaultSettings() {
  let recepientName = document.getElementById('wmFullNameDiv')
  let blank = document.getElementById('wmBlankDiv')
  let singlePDF = document.getElementById('single_combined')

  defaultSettings.lineOne = recepientName
  defaultSettings.lineTwo = blank
  defaultSettings.lineThree = singlePDF

  selectButton(defaultSettings.lineOne)
  selectButton(defaultSettings.lineTwo)
  selectButton(defaultSettings.lineThree)
}


function preventMultiselectLineOne(buttonClass) {
  let buttonArr = document.querySelectorAll('.' + buttonClass);

  buttonArr.forEach((button) => {
    button.addEventListener('click', () => {
      if(defaultSettings.lineOne === button) {
        defaultSettings.lineOne = null;
        removeButtonSelection(button)
        toggleContainer()

        if(defaultSettings.lineOne.id == 'firstOtherCheckbox') {
          clearSelection()
        }
              
      }
      else if(defaultSettings.lineOne !== null) {
        let elem = document.getElementById(defaultSettings.lineOne.id);
        defaultSettings.lineOne = button
        removeButtonSelection(elem)
        selectButton(button)
        toggleContainer()

        if(defaultSettings.lineOne.id == 'firstOtherCheckbox') {
          clearSelection()
        }
      }
      else {
        defaultSettings.lineOne = button;
        selectButton(button)
        toggleContainer()
        
        if(defaultSettings.lineOne.id == 'firstOtherCheckbox') {
          clearSelection()
        }
      }
      
    })
  })
}


function toggleContainer() {

  let container = document.getElementById('distro-contact-ctnr')
  let recepientName = document.getElementById('wmFullNameDiv')

  if(defaultSettings.lineOne == recepientName) {
    $('.right-side-container').css('align-items', 'flex-start')
    container.style.display = 'flex'
  }
  else {
    $('.right-side-container').css('align-items', 'center')
    container.style.display = 'none'
  }
}

function preventMultiselectLineTwo(buttonClass) {
  let buttonArr = document.querySelectorAll('.' + buttonClass);

  buttonArr.forEach((button) => {
    button.addEventListener('click', () => {
      if(defaultSettings.lineTwo === button) {
        defaultSettings.lineTwo = null;
        removeButtonSelection(button)
      }
      else if(defaultSettings.lineTwo !== null) {
        let elem = document.getElementById(defaultSettings.lineTwo.id);
        defaultSettings.lineTwo = button
        removeButtonSelection(elem)
        selectButton(button)
      }
      else {
        defaultSettings.lineTwo = button;
        selectButton(button)
      }
      
    })
  })

}

function preventMultiselectLineThree(buttonClass) {
  let buttonArr = document.querySelectorAll('.' + buttonClass);

  buttonArr.forEach((button) => {
    button.addEventListener('click', () => {
      if(defaultSettings.lineThree !== null) {
        let elem = document.getElementById(defaultSettings.lineThree.id);
        defaultSettings.lineThree = button
        removeButtonSelection(elem)
        selectButton(button)
      }
      else {
        defaultSettings.lineThree = button;
        selectButton(button)
      }
      
    })
  })
}

function selectButton(element) {
  $(element).removeClass('hollow');
}

function removeButtonSelection(element) {
  $(element).addClass('hollow')
}

function forceCheckOnFocus(textInputClass) {
  let lineTextInputs = document.querySelectorAll('.' + textInputClass);
  lineTextInputs.forEach((line) => {
    line.onfocus = function () {
      let correspondingChkbx =
        document.querySelector('#' + this.id.replace('Input', 'Checkbox'));
      if(this.id == 'firstOtherInput') {
          removeButtonSelection(defaultSettings.lineOne)
          selectButton(correspondingChkbx)
          defaultSettings.lineOne = correspondingChkbx
          toggleContainer()

          if(defaultSettings.lineOne.id == 'firstOtherCheckbox') {
            clearSelection()
          }
      }
      else if(this.id == 'secondOtherInput') {
        removeButtonSelection(defaultSettings.lineTwo)
        defaultSettings.lineTwo = correspondingChkbx
        selectButton(correspondingChkbx)
      }
    }
  });
}

function clearSelection() {

  let checkboxes = document.querySelectorAll('.distro-list-chbx');

  checkboxes.forEach((chkbox) => {
    if(chkbox.checked) {
      chkbox.checked = false;
    }
  })
}

var sliders = {
  sizeSlider: 100,
  opacitySlider: 14,
  orientationSlider: 45
};

function initSliders() {
  new Dragdealer('size-slider', {
    x: 1,
    animationCallback: function (x, y) {
      let displayedValue = Math.round(x * 90 + 10);
      $('#size-slider .value').text(displayedValue + "%");
      sliders.sizeSlider = displayedValue;
    }
  });

  new Dragdealer('opacity-slider', {
    x: 1 / 6,
    animationCallback: function (x, y) {
      let displayedValue = Math.round(x * 90 + 10);
      $('#opacity-slider .value').text(displayedValue + "%");
      sliders.opacitySlider = displayedValue;
    }
  });

  new Dragdealer('orientation-slider', {
    x: 1,
    animationCallback: function (x, y) {
      let displayedValue = Math.round(x * 45);
      $('#orientation-slider .value').text(displayedValue);
      sliders.orientationSlider = displayedValue;
    }
  });
}

function loadAdvSettings() {
  let passwordDiv = createPasswordDiv();
  document.querySelector('#advSettingsContainer').appendChild(passwordDiv);
  Dragdealer = require('dragdealer');
  initSliders();
}

var preventPswBlurEvt = false;
function createPasswordDiv() {
  let passwordContainer = document.createElement('div');
  passwordContainer.style.maxWidth = '300px';
  passwordContainer.className = 'password-container';
  passwordContainer.style.display = 'none';

  let passwordProtectTitle = document.createElement('div');
  passwordProtectTitle.style.cursor = 'pointer';
  passwordProtectTitle.style.display = 'table-cell';
  passwordContainer.appendChild(passwordProtectTitle);

  passwordProtectTitle.onclick = function (e) {

    if (!document.getElementById('wmPasswordInput')) {
      let passwordInput = document.createElement('input');
      passwordInput.type = 'password';
      passwordInput.id = 'wmPasswordInput';
      passwordInput.style.width = '240px';
      passwordInput.placeholder = i18n.t("js.wtmrk.password");
      passwordInput.style.display = 'none';
      passwordInput.onblur = function (e) {
        if (!this.value && !preventPswBlurEvt) {
          $(this).slideUp("fast");
        }
      }

      passwordInput.onkeyup = function () {
        if (this.value) {
          lockPasswordIcon();
        } else {
          unlockPasswordIcon();
        }
      }

      passwordInput.onfocus = function () {
        preventPswBlurEvt = false;
      }

      this.onmousedown = function () {
        preventPswBlurEvt = true;
      }

      passwordContainer.appendChild(passwordInput);
      $(passwordInput).slideDown("fast", function () {
        passwordInput.focus();
      });
    } else {
      $(document.getElementById('wmPasswordInput')).slideToggle("fast", function () {
        preventPswBlurEvt = false;
        if ($(this).is(':visible')) {
          this.focus();
        }
      });
    }
  }

  let lockIcon = document.createElement('i');
  lockIcon.className = 'fa fa-unlock-alt prefix';
  lockIcon.style.color = '#02BF57';
  lockIcon.id = 'lockIcon';

  let span = document.createElement('span');
  span.textContent = i18n.t("js.wtmrk.pwd-prot");
  span.style.color = '#02BF57';

  passwordProtectTitle.append(lockIcon);
  passwordProtectTitle.append(span);

  return passwordContainer;
}

function lockPasswordIcon() {
  let lockIcon = document.getElementById('lockIcon');
  if (lockIcon.className.includes('unlock-alt')) {
    lockIcon.className = lockIcon.className.replace('unlock-alt', 'lock');
  }
}

function unlockPasswordIcon() {
  let lockIcon = document.getElementById('lockIcon');
  if (!lockIcon.className.includes('unlock-alt')) {
    lockIcon.className = lockIcon.className.replace('lock', 'unlock-alt');
  }
}

function initFileInSessionStorage() { // TODO
  var fileId = sessionStorage.getItem("fileId");
  var fileName = sessionStorage.getItem("fileName");
  if (fileId) {
    selectedDocuments[fileId] = {
      id: fileId,
      name: fileName,
      type: 'file',
      nodeID: null
    };
    showSelectedDocument(selectedDocuments[fileId]);
    sessionStorage.clear();
  }
}

function setTitle() {
  document.title = "Croogloo - Watermark Printer";
}

function isPasswordProtected() {
  let passwordInput = document.getElementById('wmPasswordInput');
  return (passwordInput && passwordInput.value) ? true : false;
}

function getWmPassword() {
  let passwordInput = document.getElementById('wmPasswordInput');
  return (passwordInput && passwordInput.value) ? passwordInput.value : '~';
}

function findLineFromID(id) {
  return id.match(/Other|Title|Department|FirstName|LastName|FullName|Blank|IPAddress|Date/)[0];
}

function isRecipientNeeded(lineOneType, lineTwoType) {
  return !(lineOneType == 'Other' &&
    ['Other', 'Blank', 'IPAddress', 'Date'].includes(lineTwoType));
}

function processWatermarkingJob() {
  if (!selectedDocuments || !Object.keys(selectedDocuments).length) {
    cgToast(i18n.t("js.wtmrk.empty"));
    return;
  }

  if(Object.keys(selectedDocuments).length > MAX_SELECTED_FILES) {
    cgToast(i18n.t("js.wtmrk.select-max", {MAX_SELECTED_FILES}));
    return;
  }

  let selectedLists = new Array();
  $('.distro-list-chbx:checkbox:checked').each(function () {
    selectedLists.push(this.parentElement.id);
  });
  selectedLists = selectedLists.join(',');

  let lineOneElem = defaultSettings.lineOne
  let lineTwoElem = defaultSettings.lineTwo

  if (lineOneElem === null) { cgToast(i18n.t("js.wtmrk.req.selection", {lineNo:1})); return; }
  if (lineTwoElem === null) { cgToast(i18n.t("js.wtmrk.req.selection", {lineNo:2})); return; }

  let lineOneType = findLineFromID(lineOneElem.id);
  let lineTwoType = findLineFromID(lineTwoElem.id);

  let lineOneContent;
  let lineTwoContent;

  if (lineOneType === 'Other' && !$('#firstOtherInput').val()) {
    cgToast(i18n.t("js.wtmrk.req.specify-first"), 3000);
    $('#firstOtherInput').focus();
    return;
  } else if (lineOneType === 'Other') {
    lineOneContent = $('#firstOtherInput').val();
  }

  if (lineTwoType === 'Other' && !$('#secondOtherInput').val()) {
    cgToast(i18n.t("js.wtmrk.req.specify-second"), 3000);
    $('#secondOtherInput').focus();
    return;
  } else if (lineTwoType === 'Other') {
    lineTwoContent = $('#secondOtherInput').val();
  }

  if (!selectedLists && !getExtraNames().trim()
    && isRecipientNeeded(lineOneType, lineTwoType)
    && !getExtraPersons().trim()) {
    cgToast(i18n.t("js.wtmrk.contact-none"));
    return;
  }

  var tmp = new Object();
  tmp.distributionListId = document.getElementById('defaultMode').checked ? (selectedLists || '~') : '~';
  tmp.fileId = Object.keys(selectedDocuments).join(',');
  tmp.extraNames = document.getElementById('defaultMode').checked
    ? (getExtraNames() || ((!isRecipientNeeded(lineOneType, lineTwoType)
      && !getExtraPersons().trim()) ? 'default' : '~'))
    : 'default';
  tmp.extraPersonsId = getExtraPersons() || '~';
  tmp.firstLine = lineOneContent || '~';
  tmp.secondLine = lineTwoContent || '~';
  tmp.firstLineType = lineOneType;
  tmp.secondLineType = lineTwoType;
  tmp.isPasswordProtected = isPasswordProtected();
  tmp.wmPassword = getWmPassword();
  tmp.isBulk = document.querySelector('#single_combined') === defaultSettings.lineThree ||
    !document.getElementById('defaultMode').checked;
  tmp.rotation = parseInt(sliders.orientationSlider);//default = 45;
  tmp.opacity = parseInt(sliders.opacitySlider) / 100;//default = 0.3;
  tmp.textWidth = parseInt(sliders.sizeSlider) / 100;//default = 1;

  saveSystemActivity({
    action: 'submit',
    params: findSystemActivityParams(tmp),
    message: 'User submitted a file to be watermarked.'
  });

  showSpinner();
  apicall('scriptapi', 'processWatermarkPrinter', tmp).then((resp) => {
    if (resp && resp.responseCode && resp.responseCode === '0') {
      window.cgNotification.addCounter();
      WatermarkNotification.add(false, resp.responseMessage, 
        !tmp.fileId.includes(',') 
          ?resp.contentName.substring(3) 
          :resp.contentName, new Date().getTime());
      hideSpinner();
      swal({
        type: 'success',
        title: i18n.t("js.wtmrk.process"),
        html: i18n.t("js.wtmrk.processing", {count: Object.keys(selectedDocuments).length}) + i18n.t("js.wtmrk.proceesing-info"),
        allowOutsideClick: false,
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("close"),
        allowOutsideClick: true
      }).catch(swal.noop);
    } else {
      throw new Error('Watermark initiatization failure');
    }
  }).catch((err) => {
    console.error('failed to initiate watermarking task', err);
    swalToast({
      type: 'error',
      title: i18n.t("js.wtmrk.error")
    });
  });
}

function initDistroGroups(resolve) {
  apicall('distributionapi', 'fetchDistributionListNames', {})
  .then((resp) => {
    if (resp && resp.items) {
      console.log('generated list from current entities');
      generateDistroLists(resp.items.map(entity => {
        return {
          distributionListId: entity.key.name,
          distributionListName: entity.properties.distributionListName
        }
      }));
      resolve();
    } else {
      throw new Error('invalid server response');
    }
  }).catch(() => {
    console.error('failed to retrieve distribution lists');
  });
}

function generateDistroLists(distrLists) {
  document.getElementById('distroListLoader').style.display = 'none';
  //sorting lists alphabetically
  distrLists.sort((a, b) =>
    a.distributionListName.localeCompare(b.distributionListName));

  // adding ALL CREW list at first position
  distrLists.splice(0, 0, {
    distributionListId: '__all', 
    distributionListName: i18n.t("js.wtmrk.all-contacts") 
  });
  //creating the distribution list checkboxes
  distrLists.forEach((list) => {
    let listElem = copyBlueprint('distrListBP', true);
    listElem.id = list.distributionListId;
    $(listElem).find('label')[0].textContent = list.distributionListName.toUpperCase();
  });
}

function initCrooglooDocs(done) {

  var to = false;
  $('.search-input').keyup(function () {
    if (to) { clearTimeout(to); }
    to = setTimeout(function () {
      var v = $('.search-input').val();
      $('#fileTree').jstree(true).search(v);
    }, 250);
  });

  fileCollection = [];
  crooglooFolderMap = {};
  fileCollection.push({ "id": "ROOT", "parent": "#", "text": "Documents", 'state': { 'opened': true } });

  fetchCrooglooFiles('ROOT', 'ROOT', 2).then(() => {
    console.debug('fetching croogloo files done');
    document.getElementById('systemFileLoader').style.display = 'none';
    loadDocTree(done);
  });
}

function fetchCrooglooFiles(parentFolder, jstreeParent, recursions) {
  return new Promise(async function (resolve) {
    recursions--;
    let resp = await fetchFilesInFolder(parentFolder);
    // console.debug(resp);
    let folders = new Array();
    let completedFolders = 0;
    for (var i = 0; i < resp.items.length; i++) {
      var obj = resp.items[i].properties;
      if (obj.subType !== 'Folder' && (typeof obj.fileName !== 'string' || !obj.fileName.toLowerCase().trim().endsWith('pdf'))) {
        continue; // only PDF files can we watermarked
      }
      hasFileCollectionChanged = true;
      try {
        obj.id = obj.id.replace(/%20/g, ' ');
        obj.parentFolder = obj.parentFolder.replace(/%20/g, ' ');
        obj.parents.value = obj.parents.value.replace(/%20/g, ' ');
      } catch (e) {
        console.debug(e);
      }
      if (obj.id && obj.fileName) {
        var icons = (obj.subType !== "Folder") ? "jstree-file" : (obj.fileName.endsWith('.zip') ? "icon-zip" : "jstree-folder");
        let fileSize = obj.size || obj.fileSize || '0';
        if (isNaN(fileSize)) { fileSize = '0' }
        let nodeId = 'n' + uuid(); // making sure element random ID starts with letter
        let node = {
          "id": nodeId,
          "parent": jstreeParent,
          "text": obj.fileName,
          "icon": icons,
          "li_attr": {
            "id": obj.id,
            "type": obj.subType || 'file',
            "isFilled": recursions === 0 ? "0" : "1"
          }
        };
        fileCollection.push(node);
        crooglooFolderMap[node.id] = node;
        if (obj.subType === "Folder") {
          folders.push({
            folderId: obj.id,
            nodeId: nodeId
          });
        }
      }
    }
    if (recursions > 0 && folders.length > 0) {
      folders.forEach(folderIdMap => {
        fetchCrooglooFiles(folderIdMap.folderId, folderIdMap.nodeId, recursions).then(() => {
          completedFolders++;
          checkCGCompletion();
        });
      });
    } else {
      checkCGCompletion();
    }
    function checkCGCompletion() {
      if (completedFolders >= folders.length || recursions <= 0) {
        resolve();
      }
    }
  });
}

function fetchFilesInFolder(folderId) {
  return apicall('documentsapi', 'fetchItemsInFolder', { folderId: folderId });
}

function loadDocTree(done) {
  $('#fileTree').jstree({
    'core': {
      'data': fileCollection,
      'multiple': true
    },
    "search": {
      "case_insensitive": true,
      "show_only_matches": true
    },
    checkbox: {
      three_state : true,
      whole_node : true,
      tie_selection : true
    },
    "plugins": ["search", "checkbox"]
  }).on('changed.jstree', function (e, data) {
    let selectedNodes = data.selected.map(d => data.instance.get_node(d))
      .filter(node => node.li_attr.type == 'file');
    let selectedNodeIds = selectedNodes.map(node => node.li_attr.id);
    for(let docId of Object.keys(selectedDocuments)) {

      //added uploaded property for selectedDocuments object to differentiate
      //between selected documents and uploaded documents

      if(!selectedNodeIds.includes(docId) && selectedDocuments[docId].uploaded == undefined) {
        // delete removed keys without affecting selection order
        delete selectedDocuments[docId];
      }
    }
    for (let node of selectedNodes) {
      if(!Object.keys(selectedDocuments).includes(node.li_attr.id)) {
        selectedDocuments[node.li_attr.id] = {
          id: node.li_attr.id,
          name: node.text,
          type: node.li_attr.type,
          nodeID: node.id
        };
      }
    }
  }).on('open_node.jstree', function (e, data) {
    let node = data.node;
    if (node.id == 'ROOT' || node.li_attr.type && node.li_attr.type == 'Folder') {
      let foldersToFill = [];
      node.children.forEach(childId => {
        let child = data.instance.get_node(childId);
        if (child.li_attr.type == 'Folder' &&
          child.li_attr.isFilled === '0') {
          foldersToFill.push(child);
        }
      });
      if (foldersToFill.length > 0) {
        showSpinner();
        hasFileCollectionChanged = false;
        let filledFolders = 0;
        foldersToFill.forEach(folderNode => {
          fetchCrooglooFiles(folderNode.li_attr.id, folderNode.id, 1)
            .then(() => {
              crooglooFolderMap[folderNode.id].li_attr.isFilled = '1';
              if (++filledFolders === foldersToFill.length) {
                refreshDocTree();
                hideSpinner();
              }
            });
        });
      }
    }
  }).on('loaded.jstree', done);
}

function refreshDocTree() {
  // we make sure there's been a change to prevent jstree bug where tree
  // automatically closes after refreshing
  if (hasFileCollectionChanged) {
    $('#fileTree').jstree(true).settings.core.data = fileCollection;
    $('#fileTree').jstree(true).refresh();
  }
}

function toggleFileViewer() {

  if (!$('#js-tree').is(':visible')) {
    $('.folder-select-title label').text("Select up to 5 Documents")
    let shallowCopy = Object.assign({}, selectedDocuments);
    $('#fileTree').jstree('deselect_all');
    selectedDocuments = Object.assign({}, shallowCopy);
    for(let nodeID of Object.values(selectedDocuments).map(d => d.nodeID)) {
      try {
        if(nodeID !== null) {
          $('#fileTree').jstree('select_node', nodeID);
        }
      } catch(e) {
        console.error(e);
      }
    }

    let template = document.getElementById('wrapper');   
    $('#js-tree').show();
    
    swal({
      allowOutsideClick: true,
      showCloseButton: true,
      html: template.innerHTML,
      showCancelButton: false,
      showConfirmButton: false, 
      animation: false,     
      
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
        $('#js-tree').hide();
      }
    }); 
  }else{
    $('#js-tree').hide();
    $('.swal2-popup').removeClass('swal2-noanimation');
    $('.swal2-popup').addClass('swal2-hide');
    swal.close();    
  }
   
}

function removeSelectedDocument(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  let docElem = e.target.closest('div.attachment-box');
  delete selectedDocuments[docElem.getAttribute('fileid')];
  docElem.remove();
}

function addExtraName(name) {
  name = name.trim();
  if (!name) { return }

  let isExistingName = false;
  $('#extraMemberSelect').find('option[value="' + name + '"]').each(function () {
    this.selected = true;
    isExistingName = true;
  });

  if (!isExistingName) {
    let select = document.getElementById('extraMemberSelect');
    let option = document.createElement('option');
    option.value = name;
    option.textContent = name;
    option.selected = true;
    option.className = 'extra-name';
    select.appendChild(option);
  }

  $('#extraMemberSelect').trigger('chosen:updated');
}

function getExtraNames() {
  let extraNames = [];
  [].forEach.call(document.querySelectorAll('#extraMemberSelect > option.extra-name:checked'), option => {
      extraNames.push(option.value);
    });
  return extraNames.join(',');
}

function getExtraPersons() {
  let extraPersonsId = [];
  [].forEach.call(document.querySelectorAll('#extraMemberSelect > option.extra-person:checked'), option => {
      extraPersonsId.push(option.value);
    });
  return extraPersonsId.join(',');
}

function selectDocument(e) {
  let documentsToShow = [];
  for(let document of Object.values(selectedDocuments)) {
    if (!document || document.id === 'ROOT' || document.type == "Folder") {
      continue;
    } else {
      documentsToShow.push(document);
    }
  }
  if(!documentsToShow.length) {
    cgToast(i18n.t("js.wtmrk.select-file"), 3000);
    return;
  } else if(documentsToShow.length > MAX_SELECTED_FILES) {
    cgToast(i18n.t("js.wtmrk.select-max", {MAX_SELECTED_FILES}), 3000);
    return;
  }
  $('div.attachment-box:not(#attachmentBlueprint)').remove();
  for(let document of documentsToShow) {
    console.log(document)
    showSelectedDocument(document);
  }
  toggleFileViewer();
}

function showSelectedDocument(document) {
  let docElem = copyBlueprint('attachmentBlueprint', true);
  docElem.setAttribute('fileid', document.id);
  docElem.querySelector('div.file-name').textContent = document.name;
  docElem.onclick = function(e) {
    browseSelectedDocument(e, document);
  }
  docElem.querySelector('div.delete-file').onclick = removeSelectedDocument;
}

function browseSelectedDocument(e, document) {
  e.preventDefault();
  if (!e.target.className.includes('delete-file') &&
    !e.target.className.includes('icon-exit')) {    
     
    if(!document.id.toLowerCase().endsWith('pdf')){
      DocUtility.openFile(document.id, document.name);

    }else{
      showSpinner();
      DocUtility.getFileURL(document.id, document.isWatermarked, null).then(fetchedURL => {        
        buildFileViewer(fetchedURL);   
        hideSpinner();
      });
      
    }
  }
}

function buildFileViewer(fileLink) {

  let overlay = document.getElementById('overlay');
  let viewBody = document.getElementById('viewerBody');
  let viewContainer = document.getElementById('fileContainer');
  let btn = document.getElementById('closeBtn')

  let fileIframe = document.createElement('iframe');
  fileIframe.setAttribute('src', fileLink);
  fileIframe.innerHTML = "Iframe not supported"; 

  viewContainer.classList.add('active');
  overlay.classList.add('active');

  viewBody.appendChild(fileIframe);

  btn.addEventListener('click', () =>{

    viewContainer.classList.remove('active');
    overlay.classList.remove('active');
    viewBody.removeChild(fileIframe);
  });

  overlay.addEventListener('click', ()=>{
    viewContainer.classList.remove('active');
    overlay.classList.remove('active');
    viewBody.removeChild(fileIframe);
  })
}