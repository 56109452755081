const RANDOM = null;


global.showInitialLoadingScreen = function() {
  document.getElementById('offCanvasLeftSidemenu').classList.add('loading-phase');
  document.getElementById('main').classList.add('loading-phase');
  document.body.classList.add('loading-phase');
  showLoadingScreen();
}

global.showLoadingScreen = function(loadingMsg = RANDOM) {

  let loadingScreen = window.loadingScreen
    || (window.loadingScreen = buildLoadingScreen());

  if(loadingScreen.style.display == 'block') {
    return; // loading screen is already showing
  }

  // document.body.classList.add('no-scroll');
  loadingScreen.classList.replace('hidden-overlay', 'visible-overlay');

  showSpinner();
  loadingScreen.style.display = 'block';

  /**
   * Adapts the height of the loading screen based on header (and formerly footer as well)
   * @returns the screenoverlay element with adapted size
   */
  function buildLoadingScreen() {

    let headerHeight = Utility.getHeaderHeight();

    let screenOverlay = document.createElement('div');
    screenOverlay.id = 'loadingScreen';
    screenOverlay.style.display = 'none';
    screenOverlay.className = 'screen-overlay visible-overlay';

    let adaptiveHeight = 'calc(100vh - ' + headerHeight + 'px)';
    screenOverlay.style.height = adaptiveHeight;
    document.getElementById('main').appendChild(screenOverlay);

    return screenOverlay;
  }
}

global.hideLoadingScreen = function() {
  try {
    let loadingScreen = document.getElementById('loadingScreen');
    loadingScreen.classList.replace('visible-overlay', 'hidden-overlay');

    // allow scrolling after loading screen has faded out
    // delay should match .visible-overlay and .hidden-overlay
    // animation-duration defined in cgBase.css
    setTimeout(function() {
      // document.body.classList.remove('no-scroll');
      loadingScreen.style.display = 'none';
    }, 500);
  } catch(e) {
    console.error('failed to hide loading screen');
    console.error(e);
  }
}
