import i18n from './components/Translation';
import moment from 'moment';
import DocUtility from './components/DocUtility';
import DataFetcher from './utils/dataUtility.js';
import getExpiringContacts from './components/ContactManager.js';
import 'jquery-datetimepicker';
import 'jquery.tooltips';
import CGGoogleContacts from './components/CGGoogleContacts.js';
import swal from 'sweetalert2';

export function init() {
	loader.add(initGlobals, 'globals')
		.add(ui, "ui")
		.add(loadPaperWall)
		.add(initializeIntercom)
		.add(loadDashboardCallTime)
		.add(getAllData, ["contacts"])
		.execute();
}

/**
 * Initialize Intercom after fetching the unique user hash of the user. The user hash is generated when the user logs in.
 */
async function initializeIntercom() {
	try {
		const resp = await apicall('personapi', 'fetchIntercomUserHash', {});

		if (resp && resp.responseCode && resp.responseCode === '0') {
			croogloo_auth.crooglooauth.intercomUserHash = resp.responseMessage;
			if (croogloo_auth.crooglooauth.productionType) {
				window.intercomSettings = {
					app_id: 'la6o2t9v',
					user_hash: croogloo_auth.crooglooauth.intercomUserHash,
					croogloo_first_name: croogloo_auth.crooglooauth.firstName,
					email: croogloo_auth.crooglooauth.email,
					croogloo_production_type: croogloo_auth.crooglooauth.productionType,
				};
			} else {
				// It's possible for the productionType to not yet be set depending on how fast the page loads 
				// and calls the method where the value is set: croogloo_auth.validateSubscription in app.js
				// If the value isn't set yet, wait 1 second and try again.
				console.debug('Waiting 1 second before trying to gett the productionType again from crooglooauth')
				await new Promise(r => setTimeout(r, 1000));
			}
		} else {
			croogloo_auth.crooglooauth.intercomUserHash = '';
			window.intercomSettings = {
				app_id: 'la6o2t9v',
			};
			console.error('Could not fetch intercom user hash, check backend logs.');
		}

		(function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/la6o2t9v'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
	} catch (err) {
		console.error(err);
	}
}

function loadPaperWall() {
	loadPaperWallDocuments("callsheet", "#paper-wall-callsheet", "#paper-wall-callsheet-updated-time");
	// TODO: Gradually enable each document type
	// loadPaperWallDocuments("shooting", "#paper-wall-shooting", "#paper-wall-shooting-updated-time");
	// loadPaperWallDocuments("script", "#paper-wall-script", "#paper-wall-script-updated-time");
	// loadPaperWallDocuments("chrono", "#paper-wall-chrono", "#paper-wall-chrono-updated-time");
	// loadPaperWallDocuments("side", "#paper-wall-side", "#paper-wall-side-updated-time");
	// loadPaperWallDocuments("oneliner", "#paper-wall-oneliner", "#paper-wall-oneliner-updated-time");
	// loadPaperWallDocuments("dood", "#paper-wall-dood", "#paper-wall-dood-updated-time");
	// loadPaperWallDocuments("prepschedule", "#paper-wall-prepschedule", "#paper-wall-prepschedule-updated-time");
	// loadPaperWallDocuments("crewList", "#paper-wall-crewList", "#paper-wall-crewList-updated-time");
	
	function loadPaperWallDocuments(subCategory, anchorElement, updatedTimeElement) {
		let payload = new Object();
		payload.subcategory = subCategory;
		apicall('documentsapi', 'fetchDocumentsBySubcategory', payload).then(async function(resp) {
			console.debug(subCategory + " Documents fetched!", resp);
			resp.items.sort(function (a, b) {
				return parseInt(a.properties.lastModifBaseTime) > parseInt(b.properties.lastModifBaseTime)? -1 :
					parseInt(a.properties.lastModifBaseTime) < parseInt(b.properties.lastModifBaseTime)? 1 : 0;
			});
			let foundValidDocument = false;
			for (let i in resp.items) {
				let fetchedDocument = resp.items[i];
				if (fetchedDocument.key.name) {
					$(anchorElement).on("click", function() {
						
						if(!fetchedDocument.key.name.toLowerCase().endsWith('pdf')){
							DocUtility.openFile(fetchedDocument.key.name, fetchedDocument.properties.fileName, fetchedDocument.properties.isWatermarked === '1')
				  
						  }else{
							showSpinner();
							DocUtility.getFileURL(fetchedDocument.key.name, fetchedDocument.properties.isWatermarked === '1', null).then(fetchedURL => {            
							  buildFileViewer(fetchedURL); 
							  hideSpinner();  
							});
							
						  }
					});
					let d = moment(parseInt(fetchedDocument.properties.lastModifBaseTime))
						.locale(i18n.language)
						.format(i18n.t("js.moment.dashboard.format"));
					$(updatedTimeElement).text(i18n.t("js.dash.cs-time", {time:d}));
					foundValidDocument = true;
					break;
				}
			}
			if (!foundValidDocument) {
				$(updatedTimeElement).text(i18n.t("js.dash.cs-none"));
			}
		});
	}
}

function buildFileViewer(fileLink) {

	let overlay = document.getElementById('overlay');
	let viewBody = document.getElementById('viewerBody');
	let viewContainer = document.getElementById('fileContainer');
	let btn = document.getElementById('closeBtn')
  
	let fileIframe = document.createElement('iframe');
	fileIframe.setAttribute('src', fileLink);
	fileIframe.innerHTML = "Iframe not supported"; 
 
	viewContainer.classList.add('active');
	overlay.classList.add('active');
  
	viewBody.appendChild(fileIframe);
  
	btn.addEventListener('click', () =>{
  
	  viewContainer.classList.remove('active');
	  overlay.classList.remove('active');
	  viewBody.removeChild(fileIframe);
	});
  
	overlay.addEventListener('click', ()=>{
	  viewContainer.classList.remove('active');
	  overlay.classList.remove('active');
	  viewBody.removeChild(fileIframe);
	})
  }

function cleanCalendarClass(id) {
	return `g-cal-${id.replace(/[^A-Za-z\d]/g, '_')}`;
}

function ui() {
	$('#main').foundation();
	var productionTitleElem = document.getElementById('production-title');
	try {
		let productionTitleText = croogloo_auth.crooglooauth.communitiesIdNameMap[croogloo_auth.tenantId];
		if(!productionTitleText || typeof productionTitleText != 'string' || !productionTitleText.trim()) {
			throw new Error('Invalid production name', productionTitleText);
	}
	productionTitleElem.innerHTML = productionTitleText;
	let dashboardUsername = document.getElementById("dashboard-username");
	let userFullName = croogloo_auth.crooglooauth.firstName + " " + croogloo_auth.crooglooauth.lastName;
	dashboardUsername.innerHTML = userFullName;
	} catch(e) {
		console.error(e);
		productionTitleElem.innerHTML = i18n.t("js.dash.greetings", {userFullName});
	}
	initListeners();
	loader.check('ui');
}

function getAllData() {
	fetchSecurityLists();
	new TaskList({ passReference: true })
	.add(list => {
		fetchPersons(() => list.check('persons'));
		fetchDistributionLists(() => list.check('distroLists'));
	}, ['persons', 'distroLists'])
	.oncomplete(() => {
		areContactsLoaded = true;
		loader.check('contacts');
	})
		.execute();
}

function fetchSecurityLists() {
	securityLists = [];
	apicall('securityadminapi', 'fetchSecurityLists', {}).then(function(resp) {
		if(Array.isArray(resp.items)) {
			for(let secListEntity of resp.items) {
				let listId = secListEntity.properties.securityListId;
				securityLists.push({
					listId: listId,
					listName: listId[0] + listId.substring(1, listId.length).toLowerCase(),
					displayOrder: parseInt(secListEntity.properties.displayOrder)
				});
			}
			securityLists.sort((a, b) => a.displayOrder - b.displayOrder);
			areSecurityListsLoaded = true;
			copyFetchSecurityLists = securityLists;
		}
	}).catch(() => {
		console.error('failed to get security lists')
	});
}

const SMS_MAX_CHAR = 160;
const CHUNKED_SMS_MAX_CHAR = 1600;

// Do not delete for now, we will bring this back soon
const crooglooCalendars = {
	SCENES: {
		id: 'crooglooSceneCalendar',
		color: '#FFDC00',
		title: 'Scenes',
		eventType: 'CS_SCENES',
		isEditable: false // different time property in use
	},
	CALLSHEET: {
		id: 'crooglooCallsheetCalendar',
		color: '#13C46A',
		title: 'Callsheet',
		eventType: 'CS_CALLSHEET',
		isEditable: false // different time property in use
	},
	OTHER: {
		id: 'crooglooOtherCalendar',
		color: 'gray',
		title: 'Other',
		eventType: 'CS_CALENDAR',
		isEditable: true
	},
};

// Do not delete for now, we will bring this back soon
const uuid = require('uuid/v4');
var isNewUser;
var fetchedGridDates;
var upload;
var areSecurityListsLoaded;

var areContactsLoaded;
var googleCalendars;
var securityLists;
var copyFetchSecurityLists;

function initGlobals() {
	isNewUser = false;
	areContactsLoaded = false;
	// Will be used again soon for calendar feature, do not remove
	googleCalendars = {};
	fetchedGridDates = [];
	securityLists = [];
	areSecurityListsLoaded = false;
	upload = null;
	loader.check('globals')
}

// Do not delete for now, we will bring this back soon
function getCrooglooCalendarForId(id) {
	for(let calendar of Object.values(crooglooCalendars)) {
		if(calendar.id == id) {
			return calendar;
		}
	}
	throw new Error('Croogloo calendar not found for id: ' + id);
}

function initListeners() {
	validateAccess('#textMsgIcon', 'compose', showSMSModal);
	validateAccess('#emailIcon', 'compose');
	validateAccess('#sidesGenIcon', 'sidesGeneration');
	validateAccess('#googleContactImportContainer > a', 'crew', importGoogleContacts, 2);

	/**
	 * Confirms access to a page and allow navigation to it upon when clicking
	 * on the icon witht he provided ID. Adds a disabled style to the icon
	 * otherwise.
	 * @param  {string} selectors           Selectors used to retrieve the icon(s).
	 * @param  {string} securityPageName The page to which the icon(s) will
	 * redirect if the user has access to it. Should match one of the Page
	 * objects' securityPageName property.
	 * @param {function} alternateBehavior (optional) A function to execute
	 * instead of redirecting the Page whose securityPageName was used to
	 * determine access.
	 */
	function validateAccess(selectors, securityPageNames, alernateBehavior = null,
		requiredAccessLevel = 1) {
		let iconList = document.querySelectorAll(selectors);
		if (hasAccessToAllSecurityPages()) {
			let onIconClick = typeof alernateBehavior === 'function'
				? alernateBehavior
				: function () {
					r.loadPage(Array.isArray(securityPageNames) ? securityPageNames[0] : securityPageNames);
				};
			iconList.forEach(icon => icon.onclick = onIconClick);
		} else {
			iconList.forEach(icon => icon.classList.add('disabled-icon'));
		}

		function hasAccessToAllSecurityPages() {
			if (typeof securityPageNames == 'string') {
				return croogloo_auth.getAccessLevel(securityPageNames) >= requiredAccessLevel;
			} else if (Array.isArray(securityPageNames)) {
				for (let pageName of securityPageNames) {
					if (croogloo_auth.getAccessLevel(pageName) < requiredAccessLevel) {
						return false;
					}
					return true;
				}
			} else {
				console.error('invalid securityPageNames property, blocking access', securityPageNames);
				return false;
			}
		}
	}

	function showAddContactsPrompt(event) {//Skipped since icon is not shown, swal NOT UPDATED
		let addContactInputOptions = {
			// googleImport: 'Import Google Contacts',
			vCardImport: 'Import from vCards',
			spreadsheetImport: 'Import from Spreadsheet',
			manualCreation: 'Direct to Contact Card'
		};

		let selectedInputOption = null;

		swal({
			title: 'Add Contacts',
			html: 'Please select your preferred method to create or import contacts.',
			input: 'select',
			inputPlaceholder: 'Please make a selection',
			inputOptions: addContactInputOptions,
			confirmButtonText: 'Next',
			showCancelButton: true,
			preConfirm: function (creationMethod) {
				return new Promise((resolve, reject) => {
					if (!creationMethod) {
						reject('Please make a selection');
						return;
					}
					resolve(selectedInputOption = creationMethod);
				});
			}
		}).then(creationMethod => {
			switch (creationMethod) {
				case 'googleImport': showGoogleImportSwal(); break;
				case 'vCardImport': showVCardImportSwal(); break;
				case 'spreadsheetImport': showSpreadsheetImportSwal(); break;
				case 'manualCreation': showManualCreationSwal(); break;
			}
		}).catch(swal.noop);

		function showGoogleImportSwal() {//COMEHERE : BIG TEXT
			showContactCreationRedirectSwal("If you haven't already, you will "
				+ "need to give Croogloo access to your Google account to continue. "
				+ "We value your trust and will never access Google data which you have "
				+ "not requested. Please review the <i>Google Integration</i> section of our "
				+ '<a class="contact-creation-swal-link" target="_blank" href="privacy.html">Privacy Policy</a> to '
				+ 'understand how we use your data or '
				+ '<span id="googleImportSupportLink" class="contact-creation-swal-link">contact support</span> '
				+ "if you have any additional questions.",
				function () {
					// onOpen function
					document.getElementById('googleImportSupportLink').onclick = function () {
						window.hideTroubleshooterOnSupport = true;
						document.getElementById('redButtonLink').click();
					};
				}, function () {
					// onComplete function
					importGoogleContacts(event);
				}
			);
		}

		function showVCardImportSwal() {
			showContactCreationRedirectSwal("You will be redirected to the vCard upload "
				+ "page. From there you can either drag and drop your vCards or select them "
				+ "manually from your System Explorer in order to import your contacts.",
				function () {
					// onOpen function
				}, function () {
					// onComplete function
					r.loadPage('uploadVCards');
				}
			);
		}

		function showSpreadsheetImportSwal() {
			let contactImportDownloadLink = "https://storage.googleapis.com/8810931_croogloo_public/" + encodeURIComponent('Croogloo Contact Import.xlsx');
			showContactCreationRedirectSwal("You will be redirected to the template "
				+ `upload page. Fill in our <a class="contact-creation-swal-link target="download" href="${contactImportDownloadLink}">Contact Template</a>`
				+ ' and upload it there to import your contacts.',
				function () {
					// onOpen function
				}, function () {
					// onComplete function
					r.loadPage('uploadTemplates');
				}
			);
		}

		function showManualCreationSwal() {
			showContactCreationRedirectSwal("You will be redirected to the contact creation page.", function () {
				// onOpen function
			}, function () {
				// onComplete function
				r.loadPage('contact');
			}
			);
		}

		function showContactCreationRedirectSwal(html, onOpen, onSubmit) {
			if (selectedInputOption == null || !addContactInputOptions.hasOwnProperty(selectedInputOption)) {
				console.error('invalid selectedInputOption for contact creation prompt');
			}
			swal({
				title: addContactInputOptions[selectedInputOption] || 'Add Contacts',
				html: html,
				confirmButtonText: 'Continue',
				showCancelButton: true,
				type: 'info',
				onOpen: onOpen
			}).then(onSubmit).catch(swal.noop);
		}

	}

	getExpiringContacts();

	$('#main').off('keydown');
	$('#main').on('keydown', e => {
		// accepts: [tab] and [,] convert to [enter] on chosen select
		if ([9, 188].includes(e.keyCode) && e.target && e.target.tagName == 'INPUT' &&
			$(e.target).hasClass('chosen-search-input')) {
			e.preventDefault();
			let e2 = jQuery.Event('keyup');
			e2.keyCode = 13
			$(e.target).trigger(e2);
		}
	});
}

var allPersons;
function fetchPersons(done) {
	const maxContactsPerRequests = 300;
	
    return new Promise((resolve) => {
        apicall('personapi', 'countContactPages', { pageSize: maxContactsPerRequests })
            .then((resp) => {
                let contactPagesFetched = false;
                let pageCount = 1;
                if (resp.responseCode && resp.responseCode === '0' && resp.responseMessage) {
                    pageCount = JSON.parse(resp.responseMessage);
                    contactPagesFetched = true;
                }
                if (contactPagesFetched) {
                    let requests = new Array();
                    for (let i = 0; i < pageCount; i++) {
                        requests.push(apicall('personapi', 'fetchContactsPageable', { pageNo: i, pageSize: maxContactsPerRequests }));
                    }
                    Promise.allSettled(requests).then((responses) => {
                        allPersons = new Array();
                        for (let resp of responses)
                            Array.prototype.push.apply(allPersons, resp.value.items);
                        done();
                    });
                } else {
                    fetchContacts();
                }
            });

        function fetchContacts() {
            apicall('personapi', 'fetchContacts', {}).then(resp => {
                if (resp && resp.items) {
                    console.debug(resp.items);
                    allPersons = resp.items;
                }
                done();
            });
        }
    }).catch(() => {
        console.log("Page count not retrieved from personapi.countContactPages");
    });
}

var distributionLists;
function fetchDistributionLists(done) {
	DataFetcher.fetchDistributionLists().then(lists => {
		distributionLists = lists;
		done();
	}, function() {
		done();
	});
}

// Do not delete for now, we will bring this back soon
function mapCalendarSwalSelect() {
	let swalSelectMap = {};
	for(let crooglooCalendar of Object.values(crooglooCalendars)) {
		if(crooglooCalendar.isEditable) {
			swalSelectMap[crooglooCalendar.id] = `${crooglooCalendar.title} (Croogloo)`;
		}
	}
	for(let calendarId in googleCalendars) {
		if(['owner', 'writer'].includes(googleCalendars[calendarId].accessRole)) {
			swalSelectMap[calendarId] = `${googleCalendars[calendarId].summary} (Google)`;
		}
	}
	return swalSelectMap;
}

function loadDashboardCallTime() {
	let payload = new Object();
	payload.kind = 'CS_CALLSHEET';
	apicall('scriptapi', 'getCalendarEntities', payload).then(function(resp){
		if(resp && resp.items){
			console.debug(resp);
			let callsheets = resp.items;
			let closestCallsheet;
			let closestCallsheetDiff;
			let today = moment();
			for (let i in callsheets) {
				let callsheet = callsheets[i];
				let callsheetProperties = callsheet["properties"];
				let setcell = callsheetProperties["setCell"];
				let dateObject = moment(callsheetProperties["date"] + "," + callsheetProperties["month"] + "," + callsheetProperties["year"], "DD,MMMM,YYYY");
				let date = dateObject.locale(i18n.language).format("MMMM Do");
				let callTime = moment(callsheetProperties["callTime"], "HHmm");
				let daysDiff = dateObject.diff(today, 'days');
				if (daysDiff >= 0 && (closestCallsheetDiff === undefined || closestCallsheetDiff > daysDiff)) {
					closestCallsheet = {setcell:setcell, date:date, callTime: callTime.format("h:mm a")};
					closestCallsheetDiff = daysDiff;
				}
			}
			let isCallTimeSet = false;
			if (closestCallsheet) {
				console.log("Closest coming callsheet: ", closestCallsheet);
				if ("Invalid date" !== closestCallsheet.callTime && "Invalid date" !== closestCallsheet.date) {
					$("#dashboard-call-time").html(i18n.t("js.dash.callsheet.time", {callTime: closestCallsheet.callTime}));
					$("#dashboard-call-date").text(i18n.t("js.dash.callsheet.date", {date: closestCallsheet.date}));
					isCallTimeSet = true;
				}
			}
			if (!isCallTimeSet) {
				console.log("Dashboard call time is not set.");
				$("#dashboard-call-time").html(i18n.t("js.dash.welcome"));
			}
		}
	});
}

// Do not delete for now, we will bring this back soon
function loadCallsheet() {
	let payload = new Object();
	payload.kind = 'CS_CALLSHEET';
	apicall('scriptapi', 'getCalendarEntities', payload).then(function(resp){
		if(resp && resp.items){
			console.debug(resp);
			let callsheets = resp.items;
			let events = [];
			let closestCallsheet;
			let closestCallsheetDiff;
			let today = moment();
			for (let i in callsheets) {
				let callsheet = callsheets[i];
				let callsheetProperties = callsheet["properties"];
				let callsheetKey = callsheet["key"];
				let setcell = callsheetProperties["setCell"];
				let dateObject = moment(callsheetProperties["date"] + "," + callsheetProperties["month"] + "," + callsheetProperties["year"], "DD,MMMM,YYYY");
				let date = dateObject.format("dddd, LL");
				let callTime = moment(callsheetProperties["callTime"], "HHmm");
				let lunchTimeFrom = moment(callsheetProperties["lunchFrom"], "HHmm");
				let lunchTime = lunchTimeFrom.format("HH:mm");
				let lunchTimeTo;
				let lunchTimeEvent = {
					title:"Lunch Time",
					start:dateObject.set({ 'hour' : lunchTimeFrom.get('hour'), 'minute' : lunchTimeFrom.get('minute'), 'second' : lunchTimeFrom.get('second') }).format("LLL"),
					calendarId: crooglooCalendars.CALLSHEET.id,
					eventId: callsheetProperties.eventId + '_lunch',
					editable: false,
					startEditable: false,
					durationEditable: false,
					className: cleanCalendarClass(crooglooCalendars.CALLSHEET.id),
					color: crooglooCalendars.CALLSHEET.color,
					object: callsheet,
					description: callsheetProperties["description"]||''
				};
				if (callsheetProperties["lunchTo"]) {
					lunchTimeTo = moment(callsheetProperties["lunchTo"], "HHmm");
					lunchTime = lunchTime + lunchTimeTo.format(" [to] HH:mm");
					lunchTimeEvent.end = dateObject.set({ 'hour' : lunchTimeTo.get('hour'), 'minute' : lunchTimeTo.get('minute'), 'second' : lunchTimeTo.get('second') }).format("LLL");
				}
				let daysDiff = dateObject.diff(today, 'days');
				if (daysDiff >= 0 && (closestCallsheetDiff === undefined || closestCallsheetDiff > daysDiff)) {
					closestCallsheet = {setcell:setcell,date:date, lunchTime:lunchTime, callTime: callTime.format("HH:mm")};
					closestCallsheetDiff = daysDiff;
				}
				let callTimeEvent = {
					title:"Call Time",
					start:dateObject.set({ 'hour' : callTime.get('hour'), 'minute' : callTime.get('minute'), 'second' : callTime.get('second') }).format("LLL"),
					calendarId: crooglooCalendars.CALLSHEET.id,
					eventId: callsheetProperties.eventId + '_call',
					editable:false,
					startEditable: false,
					durationEditable: false,
					className: cleanCalendarClass(crooglooCalendars.CALLSHEET.id),
					color: crooglooCalendars.CALLSHEET.color,
					object: callsheet,
					description: callsheetProperties["description"]||''
				};
				events.push(callTimeEvent);
				events.push(lunchTimeEvent);
			}
			if (closestCallsheet) {
				$('#callsheet-date').text(closestCallsheet.date);
				$('#callsheet-time').text(closestCallsheet.callTime);
				$('#lunch-time').text(closestCallsheet.lunchTime);
				$('#setcell').text(closestCallsheet.setcell);
			} else {
				// $('#callsheet-container').hide();
			}
		}
	});
}

// Do not delete for now, we will bring this back soon
function loadScenes() {
	let payload = new Object();
	payload.kind = 'CS_SCENES';
	apicall('scriptapi', 'getCalendarEntities', payload).then(function(resp){
		if(resp && resp.items) { //  && resp.items.length > 0
			let scenes = resp.items;
			let events = [];
			let gridEvents = [];
			for (let i in scenes) {
				let sceneProperties = scenes[i]["properties"];
				let sceneKey = scenes[i]["key"];
				let timeOfDay = sceneProperties["timeOfDay"]? "(" + sceneProperties["timeOfDay"] + ")" : "";
				let date = moment(sceneProperties["date"] + "," + sceneProperties["month"] + "," + sceneProperties["year"], "DD,MMMM,YYYY").format("LL");
				let title = timeOfDay;
				let episodeAndScene = ((typeof sceneProperties.episode == 'string' && sceneProperties.episode.trim().length > 0)
					?(sceneProperties.episode + '-') :'') + (sceneProperties.sceneNumbers || '');
				if (sceneProperties["episode"]) {
					title += sceneProperties["episode"] + "-";
				}
				title += sceneProperties["sceneNumbers"] + ": " + sceneProperties["sceneName"];
				let color = sceneProperties["color"];
				if (!color || color.trim() === '') {
					color = sceneProperties["interiorExterior"] == "INT"? "#00b96e" : "#F18A3A";
				}
				let id = Utility.getValidId(sceneKey["name"]);
				let event = {
					id: id,
					calendarId: crooglooCalendars.SCENES.id,
					eventId: sceneProperties.eventId,
					title: scenes[i].fullTitle = title,
					start: date,
					editable: false,
					startEditable: false,
					durationEditable: false,
					className: cleanCalendarClass(crooglooCalendars.SCENES.id),
					color: crooglooCalendars.SCENES.color,
					allDay: true,
					object: scenes[i],
					description: sceneProperties["description"]||''
				};
				events.push(event);
				gridEvents.push(Object.assign(Object.assign({}, event), { title: episodeAndScene }));
			}
			console.debug(resp);
		}
	});
}

// Do not delete for now, we will bring this back soon
function loadCalEvents() {
	let payload = new Object();
	payload.kind = 'CS_CALENDAR';
	// IMPORTANT - the API method must create an eventId property if it's missing
	apicall('scriptapi', 'getCalendarEntities', payload).then(function(resp){
		if(resp && resp.items) { //  && resp.items.length > 0
			let calEvents = resp.items;
			let gridEvents = [], listEvents = [];
			for (let i in calEvents) {
				let calEventProperties = calEvents[i]["properties"];
				let calEventKey = calEvents[i]["key"];
				let start = calEventProperties["startDateTime"];
				let end = calEventProperties["endDateTime"];
				let title = calEventProperties["subject"];
				let titleAndDesc = title;
				 // + ((typeof calEventProperties["description"] == 'string'
					// && calEventProperties["description"].trim() != '')
					// ?(' - ' + calEventProperties["description"])
					// :'');

					let color = calEventProperties["color"];
					let id = Utility.getValidId(calEventKey["id"]);
					let listedEvents = [];
					let gridEvent = {
						id: id,
						title: title,
						calendarId: crooglooCalendars.OTHER.id,
						eventId: calEventProperties.eventId,
						start: start,
						end: end,
						editable: false,
						startEditable: false,
						durationEditable: false,
						className: cleanCalendarClass(crooglooCalendars.OTHER.id),
						color: crooglooCalendars.OTHER.color,
						object: calEvents[i],
						description: calEventProperties["description"]||''
					};
					gridEvents.push(gridEvent);
					let listEvent = Object.assign({}, gridEvent);
					listEvent.title = titleAndDesc;
					listEvents.push(listEvent);
				}
				console.debug(resp);
			}
		});
}

// Do not delete for now, we will bring this back soon
function downloadTemplate(templateName) {
	templateName = templateName.replace(/,/g , '%20');
	var filePath = "https://storage.googleapis.com/8810931_croogloo_public/"
	+ templateName;
	var downloadLink = document.createElement('a');
	downloadLink.href = filePath;
	downloadLink.download = "Croogloo Contact Template";
	downloadLink.target = '_blank';
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
}

function showFeedLoading() {
	document.getElementById("feed-loading-gif").style.display = "inline";
}

function hideFeedLoading() {
	document.getElementById("feed-loading-gif").style.display = "none";
}


//DEPRECATED
function loadCallsheetPDF() {
	Utility.loadScript('/assets/js/pdfobject.min.js', () => loadPDF());

	function loadPDF() {
		console.debug(PDFObject.pdfobjectversion);
		if(!PDFObject.supportsPDFs){
			console.warn("Boo, inline PDFs are not supported by this browser");
			return;
		}
		var options = {
			height: "600px",
			width: "100%",
			page: '1',
			id: 'callsheet-pdf',
			pdfOpenParams: {
				view: 'FitH',
				zoom: '100',
				pagemode: 'none',
				search: 'CALL',
				toolbar: '0',
				statusbar: '0',
				navpanes: '0',
				scrollbar: '0'
			}
		};
		var pdfUrl = "";
		var payload = new Object();
		payload.subcategory = "callsheet";
		apicall('documentsapi', 'fetchDocumentsBySubcategory', payload).then(async function(resp) {
			console.debug(resp);
			loader.check('loadCallsheet');
			if (resp.items.length < 1) {
				// document.getElementById("no-callsheet").style.display = "block";
				// document.getElementById("callsheet-preview").style.display = "none";
				return;
			}
			resp.items.sort(function (a, b) {
				return a.properties.lastModifBaseTime < b.properties.lastModifBaseTime;
			});
			pdfUrl = await DocUtility.getSignedURL(resp.items[0].key.name);
			// PDFObject.embed(pdfUrl, "#callsheet-preview", options);
		});
	}
}

function importGoogleContacts(event) {
	if (croogloo_auth.getAccessLevel('crew') < 2) {
		cgToast(i18n.t("js.unauthorized"))//'Unauthorized Access');
		return;
	}
  // Prevent multiple clicks
  if(!event.detail || event.detail == 1) {
  	CGGoogleContacts.init().then(function(){
  		CGGoogleContacts.importGoogleContacts().then(function() {
  		});
  	});
  }
}

function loadingRequirement(userMsg, isRequirementMet) {
	return new Promise(async function(resolve) {
		let hideLoadingToast = null;
		try {
			while(!isRequirementMet()) {
				console.debug('waiting for requirement to be met');
				if(hideLoadingToast === null) {
					hideLoadingToast = cgToast(userMsg, { hiding: 'manual' });
				}
				await new Promise(r => setTimeout(r, 300));
			}
		} catch(e1) {
			console.error(e1);
		}
		if(typeof hideLoadingToast == 'function') {
			hideLoadingToast();
		}
		resolve();
	});
}

async function showSMSModal() {
	await loadingRequirement(i18n.t("js.sms.loading"), () => areContactsLoaded);

	swal({
		title: i18n.t("js.sms.swal.title"),
		html: buildSMSSwalHTML(),
		confirmButtonColor: '#13C46A',
		confirmButtonText: i18n.t("button.send"),
		showCancelButton: true,
		cancelButtonText: i18n.t("button.cancel"),
		showLoaderOnConfirm: true,
		useRejections: true, //important for swal2 v7.1.2
		expectRejections: true,
		showCloseButton: true,
		allowOutsideClick: () => !swal.isLoading(),
		onOpen: function(swal) {
			renderHTMLContent();
		},
		preConfirm: function() {
			return new Promise(function(resolve, reject) {
				let messageContent = document.getElementById('smsMsgBody').value.trim();
				if(messageContent == '') {
					reject(i18n.t("js.sms.reject.empty"));
					return;
				}
				if(!document.getElementById('allContactsSelect').value && !document.getElementById('distroListSelect').value) {
					reject(i18n.t("js.sms.reject.recipient"));
				return;
			}
			let extraPhoneNumbers = [];
			let recipientsId = [];
			$("#allContactsSelect option:selected").each(function () {
				if(this.getAttribute('type') == 'mobile') {
					extraPhoneNumbers.push(this.value);
				} else {
					recipientsId.push(this.value);
				}
			});
			let distroListIds = [];
			$("#distroListSelect option:selected").each(function () {
				distroListIds.push(this.value);
			});
			apicall('distributionapi', 'sendMassSms', {
				smsmessage: messageContent,
				extraListMembersInputVal: extraPhoneNumbers.join(','),
				extraemail: recipientsId.join(','),
				distributionListId: distroListIds.join(',')
			}).then(resolve).catch(err => {
				console.error(err);
				try {
					if(JSON.stringify(err).toLowerCase().includes('gateway')) {
						resolve({ items: [] });
						return;
					}
				} catch(e1) {
					console.error(e1);
				}
				resolve(null);
			});
		});
		}
	}).then(resp => {
		console.debug('resp is::');
		console.debug(resp);

		// making sure the response is a list of failed deliveries
		if(resp !== null && typeof resp === 'object'
			&& typeof resp.items === 'object'
			&& typeof resp.items.length === 'number') {

			let failedDeliveries = resp.items;

		swal({
			title: i18n.t( "js.completed."+(!failedDeliveries.length ? "all-done":"warnings")),
			html: i18n.t("js.sms.success") + (failedDeliveries.length?formatFailureWarning():'') + '.',
			confirmButtonColor: '#13C46A',
			confirmButtonText: i18n.t("OK"),
			type: !failedDeliveries.length ?'success' :'warning',
			allowOutsideClick: true
		}).catch(swal.noop);

		function formatFailureWarning() {
			let formattedFailureStr = formatFailedDeliveries();
			if(!formattedFailureStr.length) {
				console.error('failed to format failure details');
				return '';
			}
			return i18n.t("js.sms.failure", {failure: formattedFailureStr, count: failedDeliveries.length});
			/*return ', but the following recipient' + (failedDeliveries.length>1?'s':'')
			+ ' could not receive the message: ' + formattedFailureStr;*/

			function formatFailedDeliveries() {
				let failureStringParts = [];
				failedDeliveries.forEach(failure => {
					try {
						failureStringParts.push('<b>'
							+ (failure.intendedRecipient.title||failure.intendedRecipient.mobile)
							+ "</b> (" + failure.failureDetails + ")");
					} catch(e) {
						console.error(e);
					}
				});
				return failureStringParts.length ?failureStringParts.join(', ') :'';
			}
		}
	} else {
		swal({
			title: i18n.t("response.error"),//'Error',
			html: i18n.t("js.sms.error"),/*'Something went wrong sending your text. Please contact '+ 'support for more information.',*/
			confirmButtonColor: '#13C46A',
			confirmButtonText: i18n.t("OK"),
			type: 'error'
		}).catch(swal.noop);
	}
}).catch(swal.noop);
	function addExtraMobile(mobile) {
		let option = document.createElement('option');
		option.value = mobile;
		option.setAttribute('mobile', mobile);
		option.setAttribute('type', 'mobile');
		option.textContent = mobile;
		option.className = 'extra-person';
		option.selected = true;
		document.getElementById('allContactsSelect').appendChild(option);
		$('#allContactsSelect').trigger('chosen:updated');
	}

	function buildSMSSwalHTML() {
		$('#smsSwalContent').remove();
		let container = document.createElement('div');
		container.id = 'smsSwalContent';
		let smsBodyContainer = document.createElement('div');
		smsBodyContainer.style.position = 'relative';
		let smsBody = document.createElement('textarea');
		smsBody.setAttribute('maxlength', CHUNKED_SMS_MAX_CHAR + '');
		smsBody.id = 'smsMsgBody';
		smsBody.setAttribute('placeholder', i18n.t("js.sms.swal.message"));
		let smsCharCount = document.createElement('span');
		smsCharCount.innerHTML = '0/' + SMS_MAX_CHAR;
		smsCharCount.id = 'smsCharCount';
		smsBodyContainer.appendChild(smsBody);
		smsBodyContainer.appendChild(smsCharCount);

		let largeSMSWarning = document.createElement('div');
		largeSMSWarning.id = 'largeSMSWarning';
		largeSMSWarning.textContent = i18n.t("js.sms.swal.warning");

		let personSelect = createPersonSelectBox();
		let distroSelect = createDistroSelectBox();

		container.appendChild(smsBodyContainer);
		container.appendChild(largeSMSWarning);
		container.appendChild(personSelect);
		container.appendChild(distroSelect);

		return container.outerHTML;
	}

	function createPersonSelectBox() {
		let select = document.createElement('select');
		select.setAttribute('multiple', 'multiple');
		select.setAttribute('data-placeholder', i18n.t("js.sms.swal.recipients"));
		select.id = 'allContactsSelect';
		allPersons.forEach(function(person) {
			let option = document.createElement('option');
			option.value = person.key.name;
			option.setAttribute('mobile', person.properties.mobile || '-');
			option.setAttribute('type', 'person');
			option.textContent = person.properties.title || Utility.extractPersonTitle(person.properties);
			option.className = 'extra-person';
			if(typeof person.properties.mobile === 'string' &&
				person.properties.mobile.match(/\d+/) !== null) {
				option.title = (person.properties.title || Utility.extractPersonTitle(person.properties)) + ': ' + person.properties.mobile;
		} else {
			console.debug('reject phone nb: ' + person.properties.mobile);
			option.setAttribute('disabled', 'disabled');
			option.title = i18n.t("js.sms.swal.recpt-opts");
		}
		select.appendChild(option);
	});
		return select;
	}

	function createDistroSelectBox() {
		let select = document.createElement('select');
		select.setAttribute('multiple', 'multiple');
		select.setAttribute('data-placeholder', i18n.t("settings.prod.report.distro-plchldr"));
		select.id = 'distroListSelect';
		distributionLists.forEach(function(list) {
			let option = document.createElement('option');
			option.value = list.key.name;
			option.setAttribute('type', 'list');
			option.textContent = list.properties.distributionListName;
			select.appendChild(option);
		});
		return select;
	}

	function renderHTMLContent() {
		['allContactsSelect', 'distroListSelect'].forEach(selectId => {
			$('#' + selectId).chosen({
				width: '100%',
				hide_results_on_select: false
			});
		});
		let smsMsgBody = document.getElementById('smsMsgBody');
		smsMsgBody.addEventListener('input', adaptCharCount);
		function adaptCharCount() {
			let charCountSpan = document.getElementById('smsCharCount');
			if(this.value.length > SMS_MAX_CHAR) {
				charCountSpan.innerHTML = this.value.length + '/' + CHUNKED_SMS_MAX_CHAR;
				charCountSpan.style.color = 'lightcoral';
				document.getElementById('largeSMSWarning').classList.add('showing-warning');
			} else {
				charCountSpan.innerHTML = this.value.length + '/' + SMS_MAX_CHAR;
				charCountSpan.style.color = 'inherit';
				document.getElementById('largeSMSWarning').classList.remove('showing-warning');
			}
		}
		$('#allContactsSelect_chosen').find('input.chosen-search-input').keyup(e => {
			console.debug(e);
			if($('#allContactsSelect_chosen').find('li.active-result').length === 0) {
				let inputValue = $('#allContactsSelect_chosen')
				.find('input.chosen-search-input').val();
				let $noResultLi = $('#allContactsSelect_chosen').find('li.no-results');
				if(inputValue.match(/^[\d-\(\)\s]+$/) !== null
					&& inputValue.replace(/[\(\)-]/g,'').trim() != '')	{
					if($noResultLi.length) {
						$noResultLi[0].innerHTML = i18n.t("js.sms.swal.recpt-enter")
							+ $noResultLi[0].innerHTML.split(/[\s\S](?=<span)/)[1];
					}
					if(e.keyCode === 13) {
						addExtraMobile(inputValue);
					}
				} else {
					if($noResultLi.length) {
						$noResultLi[0].innerHTML = i18n.t("js.sms.swal.recpt-nomatch")
							+ $noResultLi[0].innerHTML.split(/[\s\S](?=<span)/)[1];
					}
				}
			}
		});
	}
}

/**
 * This method is used when the "invite to croogloo" option in the top banner dropdown menu is clicked.
 * The first swal displays all of their contacts and disables the contacts that are already invited to croogloo.
 * The user can select any number of contacts, then click next.
 * The second swal prompts the user to select a securtiy group for each of the selected contacts. 
 * The method loops through each of the selected contacts and calls the "inviteToCroogloo" api for their respective security group
 * If the api calls were successfull the success is shown, if it is unsuccessfull it displays an error swal and states the names and reasons of each failure
 */
export async function inviteToCrooglooFromTopBar() {
	getAllData();
	await loadingRequirement(i18n.t("js.sms.loading"), () => areContactsLoaded);
	var selectedEmails = [];
	let selectedNames = [];
	//First swal, selecting which contacts to invite to croogloo
	swal({
		title: "Invite Users",
		progressSteps: [1,2],
		currentProgressStep: 0,
		html: buildInviteSwalHTML(),
		confirmButtonColor: '#13C46A',
		confirmButtonText: i18n.t("button.next"),
		showCancelButton: true,
		cancelButtonText: i18n.t("button.cancel"),
		showLoaderOnConfirm: true,
		useRejections: true, //important for swal2 v7.1.2
		expectRejections: true,
		showCloseButton: true,
		allowOutsideClick: () => !swal.isLoading(),
		onOpen: function(swal) {
			renderHTMLContent();
		},
		preConfirm: function() {
			return new Promise(function(resolve,reject) {
			if(!document.getElementById('allContactsSelect').value) {
				reject(i18n.t("js.sms.reject.recipient"));
				
			}
			else{
			$("#allContactsSelect option:selected").each(function(text) {
				selectedEmails.push(this.value);
				selectedNames.push(this.textContent);
				resolve();
			});} 
			});
		}
	}).then(resp => {
		//Second swal, choosing security group for each selected contact
		swal({
			title:"Select Security Group",
			html: chooseSecurityGroupHtml(),
			progressSteps: [1,2],
			currentProgressStep: 1,
			confirmButtonColor: '#13C46A',
			confirmButtonText: i18n.t("crew.menu.invite"),
			showCancelButton: true,
			cancelButtonText: i18n.t("button.cancel"),
			allowOutsideClick: true,
			useRejections: true, //important for swal2 v7.1.2
			expectRejections: true,
			preConfirm: function(){
				return new Promise(function(resolve, reject) {
					var selectedSecurityGroups = [];
					selectedNames.forEach(selectedName => {
						var nameId = selectedName;
						var selectedVal = document.getElementById(nameId).value.toUpperCase();
						if (selectedVal === 'SECURITY GROUP') {
							reject(i18n.t("js.crew.reject.seclist"));
							return;
						}
						selectedSecurityGroups.push(selectedVal);
					});
					if(selectedSecurityGroups.length==selectedNames.length){
						for(var i=0; i<selectedEmails.length; i++){
							var email = []
							email=[selectedEmails[i]];
							var group = selectedSecurityGroups[i];
							apicall('personapi', 'inviteToCroogloo', {}, {
							personIdList: email,
							securityListId: group
							}).then(resp => {
							if(typeof resp == 'object' && typeof resp.responseCode == 'string') {//checking if the apicall is valid, also used  in invite to croogloo method on crew.js
								resolve(resp);
							} else {
								reject(i18n.t("js.unauthorized"));
							}
							}, rejection => {
							throw rejection;
							}).catch(err => {
							console.error("Response from inviteToCroogloo was invalid"+err);
							reject(i18n.t("js.unauthorized"));
							});
						}
					}
				});
			}
		}).then(function(apiResp) {
			let personIdList = selectedEmails;
			let inviteFailed = haveInvitationsFailed(apiResp);
			let emailsLength = selectedEmails.length;
			// Third swal, displays success or failure message depending on the api response
			swal({
			  title: inviteFailed ? i18n.t("js.crew.invite.failure_other"):i18n.t("response.success"),
			  html: (!inviteFailed ? i18n.t("js.crew.invite.success.text", {count: emailsLength}):'') + buildFailureReport(apiResp),
			  type: inviteFailed ? 'error':'success',
			  showCancelButton: false,
			  allowOutsideClick: true,
			  confirmButtonColor: '#13C46A',
			  confirmButtonText: i18n.t("OK")
			}).catch(swal.noop);

			function haveInvitationsFailed(apiResp) {
			  return apiResp.responseCode == '-1' ;
			}

			function buildFailureReport(apiResp) { //Displays the name of the contact along with the reason why it failed for each failed invitation as a paragraph
			  if(apiResp.responseCode == '0' || !apiResp.responseMessage.trim()) {
				return '';
			  }
			  let failureMsgParts = [];
			  apiResp.responseMessage.split(',').forEach(failure => {
				try {
				  let failedCrewId = failure.split('~')[0]
				  for (var i=0; i<selectedEmails.length;i++){
					if (failedCrewId == selectedEmails[i]){
						var failedCrewName = selectedNames[i];
					}
				  }
				  let failureReason = '(' + failure.split('~')[1] + ')';
				  let failedCrewMemberTitle = '<b>'
					+ (failedCrewName ||'')
					+ '</b>';
				  failureMsgParts.push(failedCrewMemberTitle + ' ' + failureReason);
				} catch(ex) {
				  console.error(ex);
				}
			  });
			  let failureMsg = '<br>'+i18n.t("js.crew.invite.failed", {count: failureMsgParts.length, contacts: failureMsgParts.join(', ')});
			  return failureMsgParts.length ? failureMsg :'';
			}
		  }).catch(err => {
			if(typeof err == 'string' && (['cancel','close','esc'].includes(err) || !err.includes(' '))) {
			  console.debug('user dismissed Croogloo invitation swal');
			  return;
			}
			console.error(err);
			//If an error is thrown this error swal is displayed
			swal({
			  title: i18n.t("response.error"),
			  html: i18n.t("js.utils.something.bad"),
			  type: 'error',
			  showCancelButton: false,
			  confirmButtonColor: '#13C46A',
			  confirmButtonText: i18n.t("OK"),      
			}).catch(swal.noop);
		  });
		
		function chooseSecurityGroupHtml(){
			$('.chooseSecurityGroupTable').remove();
			let container = document.createElement('div');
			let table = document.createElement('table');
			table.className='chooseSecurityGroupTable';
			for(var i=0; i< selectedNames.length; i++ ){
				var name= selectedNames[i];
				function securityGroupDropdown(){
					fetchSecurityLists();
					let container = document.createElement('select');
					container.setAttribute('id', name);
					var defaultOption = document.createElement('option');
					defaultOption.setAttribute("selected", "" );
					defaultOption.setAttribute("disabled", "" );
					defaultOption.innerHTML = "Security Group";
					container.appendChild(defaultOption);
					for(var i=0; i<copyFetchSecurityLists.length; i++){
						var groupName = copyFetchSecurityLists[i].listName;
						let option = document.createElement('option');
						//Displaying DTR instead of Dh
						if (groupName === 'Dh' || groupName === 'DH' || groupName === "dh"){
							option.setAttribute('label', 'DTR');
						}
						option.setAttribute('value', groupName);
						option.innerHTML = groupName;
						container.appendChild(option);  
					}
					return (container.outerHTML);
				}
				let row = document.createElement('tr');
				let col1 = document.createElement('td');
				col1.className = "name";
				let col2 = document.createElement('td');
				col2.innerHTML = securityGroupDropdown();
				col1.innerHTML= selectedNames[i];
				row.appendChild(col1);
				row.appendChild(col2);
				table.appendChild(row);
			}
			let numInvite = document.createElement('p');
			numInvite.setAttribute('class', 'numInvite');

			if (i<2){
				numInvite.innerHTML = "An email will be sent inviting "+ i+ " contact to Croogloo"
			}
			else{
				numInvite.innerHTML = "An email will be sent inviting "+ i+ " contacts to Croogloo"
			}
			container.appendChild(table);
			container.appendChild(numInvite);
			return container.outerHTML;
		}
	}).catch(swal.noop);
	

	//Setting the confirm and cancel buttons to zIndex 0 so the dropdown of all contacts goes above it
	document.querySelector('.swal2-actions').style.position='relative';
	document.querySelector('.swal2-actions').style.zIndex='0';

	function buildInviteSwalHTML() {
		$('#inviteSwal').remove();
		let container = document.createElement('div');
		container.id = 'inviteSwal';
		let personSelect = createPersonSelectBox();
		let paragraph = createParagraph();
		container.appendChild(personSelect);
		container.appendChild(paragraph);

		return container.outerHTML;
	}

	function createPersonSelectBox() {
		let select = document.createElement('select');
		select.setAttribute('multiple', 'multiple');
		select.setAttribute('data-placeholder', 'Search contacts');
		select.id = 'allContactsSelect';
		allPersons.forEach(function(person) {
			let option = document.createElement('option');
			option.value = person.key.name;
			option.setAttribute('email', person.properties.email || '-');
			option.setAttribute('type', 'person');
			option.textContent = person.properties.title || Utility.extractPersonTitle(person.properties);
			option.className = 'extra-person';
			if(typeof person.properties.email === 'string' &&
				person.properties.email.match(/@/) !== null) {
				option.title = (person.properties.title || Utility.extractPersonTitle(person.properties)) + ': ' + person.properties.email;
			} 
			else {
			console.debug('reject email: ' + person.properties.email);
			option.setAttribute('disabled', 'disabled');
			option.title = i18n.t("js.sms.swal.recpt-opts");
			}
			let isInvitedToCroogloo = person.properties.inviteToCroogloo == 'Y';
			if(isInvitedToCroogloo){
				option.setAttribute('disabled', 'disabled');
				option.title = "user already invited";
			}
		select.appendChild(option);
	  	});
		return select;
	}

	function createParagraph() {
		let sentence = document.createElement('p');
		sentence.setAttribute('class', 'paragraph');
		var text = document.createTextNode("An email will be sent inviting contacts to Croogloo.");
		sentence.appendChild(text);

		return sentence;
	}

	function renderHTMLContent() {
		['allContactsSelect'].forEach(selectId => {
			$('#' + selectId).chosen({
				width: '100%',
				hide_results_on_select: false
			});
		});

		$('#allContactsSelect_chosen').find('input.chosen-search-input').keyup(e => {
			if($('#allContactsSelect_chosen').find('li.active-result').length === 0) {
				let inputValue = $('#allContactsSelect_chosen')
				.find('input.chosen-search-input').val();
				let $noResultLi = $('#allContactsSelect_chosen').find('li.no-results');
				if(inputValue.match(/^[\d-\(\)\s]+$/) !== null
					&& inputValue.replace(/[\(\)-]/g,'').trim() != '')	{
					if($noResultLi.length) {
						$noResultLi[0].innerHTML = i18n.t("js.sms.swal.recpt-enter")
							+ $noResultLi[0].innerHTML.split(/[\s\S](?=<span)/)[1];
					}
					if(e.keyCode === 13) {
						addExtraMobile(inputValue);
					}
				} else {
					if($noResultLi.length) {
						$noResultLi[0].innerHTML = i18n.t("js.sms.swal.recpt-nomatch")
							+ $noResultLi[0].innerHTML.split(/[\s\S](?=<span)/)[1];
					}
				}
			}
		});
	}
}
