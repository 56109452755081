import i18n from './components/Translation';
import Validator from './components/paramValidator.js';
import getExpiringContacts from './components/ContactManager.js';
import DownloadNotification from './components/notifications/DownloadNotification.js';
import swal from 'sweetalert2';

export function init() {
  loader.add(showSpinner)
        .add(initGlobals, 'globals')
        .add(ui, 'foundation')
        .add(loadExtraLibraries, 'extraLibs')
        .add(getAllData, ['contacts', 'distroLists'])
        .add(initListeners)
        .execute();
}

function ui() {
  $('#main').foundation();
  disableViewBySwitch();
  loader.check('foundation');
  if(window.croogloocurrentpage.getAccessLevel() < 2) {
    $('#btnContainerLeft').css('display','none');
    $('#actionBtn').parent().hide();
  }
}

var contactQuery;

var groupMembers;
var notGroupMembers;

var groupMembers2;
var notGroupMembers2;

var currentSelection;
var list1;

var currentGroup;
var otherGroup;
var selectedGroup;

var selectedGroupContact;

var _firstVisist;
var isNewListCreated;

var selectedExport;
var exportDistroCollection;
var distributionListMap;

var viewByList = true;
var viewByContact = false;

const PERSON_CHUNK_SIZE = 50; // see addPerson and removePerson
// This is the ID of the list for ALL contacts
const ALL_CREW_LIST_ID = '__all';

const LIST_TRANSACTION = {
  ADDITION: { apiMethod: 'addDistributionListMember' },
  REMOVAL: { apiMethod: 'removeDistributionListMember' }
};

Object.freeze(LIST_TRANSACTION);

function initGlobals() {
  groupMembers = [];
  notGroupMembers = [];

  groupMembers2 = [];
  notGroupMembers2 = [];

  currentSelection = "";
  list1 = null;

  currentGroup = [];
  otherGroup = [];
  selectedGroup = "";

  selectedGroupContact = "";

  _firstVisist = false;
  isNewListCreated = false;

  selectedExport = [];
  exportDistroCollection = [];
  distributionListMap = {};

  loader.check('globals');
}

function loadExtraLibraries() {
  require('jstree');
  Utility.loadStylesheet('/assets/jstree/style.min.css');
  Utility.loadStylesheet('/assets/jstree/js-tree-custom.css');
  loader.check('extraLibs');
}

function getSelectedListName(selectId) {
  let listName = document.querySelector('#' + selectId + ' > option[value="' + document.getElementById(selectId).value + '"]').textContent;
  if (listName.lastIndexOf('(') > 0) {
    listName = listName.substring(0, listName.lastIndexOf('(') - 1);
  }
  if (!listName) {
    console.error('Failed getting selected list for ' + selectId);
  }
  return listName || '';
}

/**
 * Function that shows the delete swal for deleting a distribution list.
 */
function showDeleteModal() {
  if (document.getElementById('distributionGroupList').value === ALL_CREW_LIST_ID) {
    console.error('cannot delete ALL CREW list');
    return;
  }
  var chosenListName = findSelectedCellForLists().innerHTML;
  var chosenListId = findSelectedCellForLists().id;

  if(document.getElementById('distributionGroupList')) {
    let listNameToDelete = chosenListName;
    swal({
      title: i18n.t("js.dislist.delete.title"),
      html: i18n.t("js.dislist.delete.html", {listNameToDelete}),
      showConfirmButton: true,
      confirmButtonColor: '#BC2121',
      confirmButtonText: i18n.t("utils.delete"),
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      type: 'question',
      showLoaderOnConfirm: true,
      showCloseButton: true,     
      allowOutsideClick: () => !swal.isLoading(),
      preConfirm: function() {
        return new Promise(resolve => {
          deleteGroup(resolve , chosenListId);
        });
      }
    }).then(function() {
      swal({
        title: i18n.t("response.success"),
        showConfirmButton: true,
        confirmButtonText: i18n.t("OK"),
        confirmButtonColor: '#13C46A',
        showCancelButton: false,
        type: 'success',
        allowOutsideClick: () => !swal.isLoading()
      });
    }).catch(swal.noop);
  } else {
    cgToast(i18n.t("js.dislist.empty"));
    console.error('removal option should be disabled and protected by the groupSelectionBlocker function');
  }
}

/**
 * Function that shows the swal to edit a distribution list.
 */
function showEditModal() {
    swal({
      title: i18n.t("js.dislist.edit.title"),
      input: 'text',
      inputPlaceholder: i18n.t("js.dislist.edit.placeholder"),
      showConfirmButton: true,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("button.confirm"),
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      showLoaderOnConfirm: true,
      showCloseButton: true,
      allowOutsideClick: () => !swal.isLoading(),
      preConfirm: function(newName) {
        return new Promise((resolve, reject) => {
          editGroupName(newName, resolve, reject);
        });
      }
    }).then(function() {
      swal({
        title: i18n.t("response.success"),
        showConfirmButton: true,
        confirmButtonText: i18n.t("OK"),
        confirmButtonColor: '#13C46A',
        showCancelButton: false,
        type: 'success',
        allowOutsideClick: true
      });
    }).catch(swal.noop);
  
}

var currentListName = "";

/**
 * Function that shows the compare swal for comparing distribution lists.
 */
function showCompareModal() {
  var chosenList = findSelectedCellForLists().id;
  
  if(document.getElementById('distributionGroupList')) {
    $('#compareListContent').remove();
    let container = document.createElement('div');
    container.id = 'compareListContent';
    let select = document.createElement('select');
    select.id = "compareListSelect";
    let headerOption = document.createElement('option');
    headerOption.value = "";
    headerOption.setAttribute("disabled", "disabled");
    headerOption.setAttribute("selected", "selected");
    select.appendChild(headerOption);
    apicall('distributionapi', 'fetchDistributionLists', {}).then(function (resp) {
      for (let i in resp.items) {
        let list = resp.items[i];
        if (list.key.name === ALL_CREW_LIST_ID) {
          continue;
        } else if (list.key.name === chosenList) {
          currentListName = list.properties.distributionListName;
          headerOption.textContent = i18n.t("js.dislist.compare.placeholder", {currentListName});
          continue;
        }
        let option = document.createElement('option');
        option.value = list.key.name;
        option.textContent = list.properties.distributionListName;
        select.appendChild(option);
      }
      container.appendChild(select);
      let compareListTableDiv = document.createElement('div');
      compareListTableDiv.id = "compareListTableDiv";
      container.appendChild(select);
      container.appendChild(compareListTableDiv);
      swal({
        title: i18n.t("js.dislist.compare.title", {currentListName}),
        html: container.outerHTML,
        width: '50%',
        customClass: 'swal-compare',
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        useRejections: true,
        expectRejections: true,
        allowOutsideClick: () => !swal.isLoading(),
        onOpen: function(swal) {
          renderCompareListHTMLContent();
        }
      }).then(function() {
        console.log("Closed compareList Dialog");
      }).catch(swal.noop);
    });
  }
}

function renderCompareListHTMLContent() {
  $('#compareListSelect').change(function() {
    $('#compareListTableDiv').html("");
    console.debug(this.value, groupMembers, notGroupMembers);
    let payload = new Object();
    payload.distributionListId = this.value;
    apicall('distributionapi','fetchDistributionListMembers',payload).then(function(resp){
      console.debug(resp);
      let uniqueToCurrentList = groupMembers.filter(function(groupMember) {
        return !resp.items.some(function(otherListMember) {
          return groupMember.personId == otherListMember.personId && otherListMember.selected;
        });
      }).sort(sortByName);
      let inBothLists = groupMembers.filter(function(groupMember) {
        return resp.items.some(function(otherListMember) {
          return groupMember.personId == otherListMember.personId && otherListMember.selected;
        });
      }).sort(sortByName);
      let excludedInCurrentList = notGroupMembers.filter(function(notGroupMember) {
        return resp.items.some(function(otherListMember) {
          return notGroupMember.personId == otherListMember.personId && otherListMember.selected;
        });
      }).sort(sortByName);
      console.debug("unique to the current list: ",uniqueToCurrentList);
      console.debug("in both: ", inBothLists);
      console.debug("unique to the other list: ", excludedInCurrentList);
      let table = document.createElement('table');
      let head = table.createTHead();
      head.id = "compareTableHeader";
      let headRow = head.insertRow(0);
      let headerCellFirst = headRow.insertCell(0);
      headerCellFirst.innerHTML = i18n.t("js.dislist.compare.onlyin", {currentListName});
      let headerCellSecond = headRow.insertCell(1);
      headerCellSecond.innerHTML = i18n.t("js.dislist.compare.inboth");
      let headerCellThird = headRow.insertCell(2);
      headerCellThird.innerHTML = i18n.t("js.dislist.compare.onlyin", {currentListName: $("#compareListSelect option:selected").text()});
      document.getElementById('compareListTableDiv').appendChild(table);
      let maxCount = uniqueToCurrentList.length > inBothLists.length?
        uniqueToCurrentList.length > excludedInCurrentList.length?
        uniqueToCurrentList.length : excludedInCurrentList.length :
        inBothLists.length > excludedInCurrentList.length?
        inBothLists.length : excludedInCurrentList.length;
      console.debug("longest list: " + maxCount);
      let body = table.createTBody();
      body.id = "compareTableBody";
      for (let i = 0; i < maxCount; i++) {
        let row = body.insertRow(i);
        let cellFirst = row.insertCell(0);
        cellFirst.className = "currentList";
        let cellSecond = row.insertCell(1);
        let cellThrid = row.insertCell(2);
        cellThrid.className = "otherList";
        cellFirst.innerHTML = "-";
        cellSecond.innerHTML = "-";
        cellThrid.innerHTML = "-";
        if (uniqueToCurrentList[i]) {
          cellFirst.innerHTML = uniqueToCurrentList[i].firstName + " " + uniqueToCurrentList[i].lastName;
        }
        if (inBothLists[i]) {
          cellSecond.innerHTML = inBothLists[i].firstName + " " + inBothLists[i].lastName;
        }
        if (excludedInCurrentList[i]) {
          cellThrid.innerHTML = excludedInCurrentList[i].firstName + " " + excludedInCurrentList[i].lastName;
        }
      }
    });
  });
}

function sortByName(a, b) {
  
  if (a.firstName.trim().toUpperCase() > b.firstName.trim().toUpperCase()) {
    return 1;
  } else if (a.firstName.trim().toUpperCase() < b.firstName.trim().toUpperCase()){
    return -1;
  } else {
    if (a.lastName.trim().toUpperCase() > b.lastName.trim().toUpperCase()) {
      return 1;
    } else {
      return -1;
    }
  }
}

function showAddModal(){
  swal({
    title: i18n.t("js.dislist.create.title"),
    input: 'text',
    inputPlaceholder: i18n.t("js.dislist.create.placeholder"),
    showConfirmButton: true,
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("button.confirm"),
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    showLoaderOnConfirm: true,
    showCloseButton: true,
    allowOutsideClick: () => !swal.isLoading(),
    preConfirm: function(newName) {
      return new Promise((resolve, reject) => {
        addGroup(newName, resolve, reject);
      });
    }
  }).then(function() {
    swal({
      title: i18n.t("response.success"),
      showConfirmButton: true,
      confirmButtonText: i18n.t("OK"),
      confirmButtonColor: '#13C46A',
      showCancelButton: false,
      type: 'success',
      allowOutsideClick: () => !swal.isLoading()
    });
  }).catch(swal.noop);
}

function showDuplicateModal() {
  if(document.getElementById('distributionGroupList')!== '') {
    swal({
      title: i18n.t("js.dislist.duplicate.tmp")
    })

    swal({
      title: i18n.t("js.dislist.duplicate.title"),
      input: 'text',
      inputPlaceholder: i18n.t("js.dislist.duplicate.placeholder"),
      showConfirmButton: true,
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("button.confirm"),
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      showLoaderOnConfirm: true,
      showCloseButton: true,
      allowOutsideClick: () => !swal.isLoading(),
      preConfirm: function(listName) {
        return new Promise((resolve, reject) => {
          duplicateGroup(listName, resolve, reject);
        });
      }
    }).then(function() {
      swal({
        title: i18n.t("response.success"),
        showConfirmButton: true,
        confirmButtonText: i18n.t("OK"),
        confirmButtonColor: '#13C46A',
        showCancelButton: false,
        type: 'success',
        allowOutsideClick: true
      });
    }).catch(swal.noop);
  } else {
    cgToast(i18n.t("js.dislist.empty"));
    console.error('duplicate option should be disabled and protected by the groupSelectionBlocker function');
  }
}

function initExportModal(){

  let table = document.getElementById("distributionGroupList");
  let rows = table.getElementsByTagName("tr");
  let listOfCells = [];

  for(var i=0;i<rows.length;i++){
    var currentRow = rows[i];
    var cell = currentRow.getElementsByTagName("td")[0];
    listOfCells.push(cell);
  }

  var distroOpt = listOfCells;

  let val = $.map(distroOpt, function(option) {
    return {"val" : option.id, "text" : option.innerHTML};
  });

  let distroCollection = [];
  distroCollection.push({"id": "ROOT", "parent": "#", "text": i18n.t("js.utils.select.all"), 'state' : { 'opened' : true }});

  val.forEach(function(opt){
    if(opt.val !== '') {
      distroCollection.push({"id": opt.val, "parent": "ROOT", "text": opt.text});
    }
  });

  exportDistroCollection = distroCollection;

  $('#distroTree').jstree({

    "themes": {
      "theme": "apple","dots": false,"icons": false
    },
    'core' : {
      'data' : distroCollection,
      "themes":{
        "icons":false
      }
    },
    "plugins": ["themes", "html_data", "checkbox", "sort", "ui", "search"]
  });

  $('#distroTree').on('changed.jstree', function (e, data) {
    selectedExport = [];
    var i, j;
    for(i = 0, j = data.selected.length; i < j; i++) {
      let node = data.instance.get_node(data.selected[i]);
      if(node.id !== 'ROOT') {
        selectedExport.push(node.id);
      }
    }
  });

  var to = false;
  $('.search-input').keyup(function () {
    if(to) { clearTimeout(to); }
    to = setTimeout(function () {
      var v = $('.search-input').val();
      $('#distroTree').jstree(true).search(v);
    }, 250);
  });
}

function exportDistributionLists(){
  if(!selectedExport.length) {
    cgToast(i18n.t("js.dislist.empty"));
    return;
  }

  exportViewToggle();

  let inputHtml = "<br><p>"+i18n.t("js.dislist.export.option.title")+"</p>"+
    '<div id="exportDiv"><input type="radio" name="export" id="exportExcel" value="excel"/><label for="exportExcel">'+
    i18n.t("js.dislist.export.option.excel")+'</label><br>' +
    '<input type="radio" name="export" id="exportVcard" value="vcard"/> <label for="exportVcard">'+
    i18n.t("js.dislist.export.option.vcard")+'</label><br>' +
    '<input type="radio" name="export" id="exportCSV" value="csv"/><label for="exportCSV">'+
    i18n.t("js.dislist.export.option.google")+'</label>' +
    '</div>';

  swal({
    title: i18n.t("js.dislist.export.title"),
    html: inputHtml,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: i18n.t("js.dislist.export.button"),
    cancelButtonText: i18n.t("button.cancel"),
    confirmButtonColor: '#13C46A',
    cancelButtonColor: '#F44336',
    animation: false, //We can disable animation since export options only apears after file chosing modal  
    onClose: () =>{
      $('.swal2-popup').removeClass('swal2-noanimation');
      $('.swal2-popup').addClass('swal2-hide');
    }
  }).then(function (result) {

    let sel = $("input[name='export']:checked").val();
    console.log(sel);

    if(sel == "excel") {

      let reqOpen = true;
      let tmp = new Object();
      tmp.distributionList = selectedExport.join(',');
     
      swal({
        title: i18n.t("utils.loading"),
        html: i18n.t("js.dislist.export.html"),
        showCloseButton: false,
        confirmButtonText: i18n.t("OK"),
        confirmButtonColor: '#13C46A',        
      }).then(function(){
        if(reqOpen)
        showSpinner();
      });
      apicall('importexportapi', 'exportDistributionListToExcel', tmp).then(function(resp){
        if(resp) {
          reqOpen = false;
          setTimeout(function(){
            window.location = resp.responseMessage;
            hideSpinner();
          }, 500);
        }
      });

    } else if(sel == "vcard") {

      let reqOpen = true;
      let tmp = new Object();
      tmp.distributionList = selectedExport.join(',');
      
      swal({
        title: i18n.t("utils.loading"),
        html: i18n.t("js.dislist.export.html"),
        showCloseButton: true,
        confirmButtonText: i18n.t("OK"),
        confirmButtonColor: '#13C46A',        
        allowOutsideClick: true
      }).then(function(){

        if(reqOpen)
        showSpinner();
      })
      apicall('viewAPI','generateVCardFromDistributionList',tmp).then(function(resp){
        if(resp) {
          reqOpen = false;
          setTimeout(function(){
            window.location = resp.responseMessage;
            hideSpinner();
          }, 500);
        }
      });
    } else if(sel == 'csv') {
      console.debug('processing csv contact export');
      showSpinner();
      apicall('documentsapi', 'exportDistroListsToGoogleCsv', {}, {
        distributionListId: selectedExport.join(',')
      }).then(resp => {
        if(resp.responseCode === '0') {
          swal({
            title: i18n.t("response.success"),
            html: i18n.t("js.dislist.export.csv.html"),
            showCloseButton: true,
            type: 'success',
            allowOutsideClick: true
          }).catch(swal.noop);
          DownloadNotification.add(false, false, resp.responseMessage.split(",")[0], 
            resp.responseMessage.split(",")[1] || null, new Date().getTime());
        } else {
          throw new Exception('invalid server response');
        }
      }).catch(() => {
        //ERROR: no outside click
        swal({
          title: i18n.t("js.utils.oops"),
          html: i18n.t("js.dislist.export.csv.error", {spanId:"csvExportSupportLink"}),
          showCloseButton: true,
          type: 'error',
          onOpen: function() {
            document.getElementById('csvExportSupportLink').onclick = function() {
              window.hideTroubleshooterOnSupport = true;
              document.getElementById('redButtonLink').click();
            };
          }
        }).catch(swal.noop);
      }).then(hideSpinner);
    }
  }).catch(swal.noop);

  console.debug(selectedExport);
}

function exportViewToggle(){  

  var chosenList = findSelectedCellForLists();
  
  if (!$('#js-tree').is(':visible')) {  

    let template = document.getElementById('wrapper');   
    $('#js-tree').show();

    $('#distroTree').jstree('deselect_all');
    $('#distroTree').on('changed.jstree', function() {
      try {
        $('#distroTree').jstree('select_node', chosenList);
      } catch (e) {
        console.error(e);
      }
      });
    $('#distroTree').jstree(true).settings.core.data = exportDistroCollection;
    $('#distroTree').jstree(true).refresh();
    selectedExport = [];
    
    swal({
      allowOutsideClick: true,
      showCloseButton: true,
      html: template.innerHTML,
      showCancelButton: false,
      showConfirmButton: false, 
      animation: false,     
      
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
        $('#js-tree').hide();
      }
    }); 
  }else{
    $('#js-tree').hide();
    $('#exportListSearchInput').val('');
    $('#distroTree').jstree('search', '');
    $('.swal2-popup').removeClass('swal2-noanimation');
    $('.swal2-popup').addClass('swal2-hide');
    swal.close();    
  }
}

function validateListName(listName) {//comehere
  listName = listName.trim();
  if(listName === '') {
    throw { message: i18n.t("js.dislist.reject.name") }
  }
}

function addGroup(newListName, resolve, reject) {

  newListName = newListName.trim();

  try {
    validateListName(newListName);
  } catch(e) {
    reject(e.message);
    return;
  }

	var tmp = new Object();
	tmp.distributionListName = newListName;

	saveSystemActivity({
		action: 'submit',
		params: findSystemActivityParams(tmp),
		message: 'User created a distribution list.'
	});

	apicall('distributionapi','addDistributionList',tmp).then(function(resp) {
		if(resp && resp.responseCode && resp.responseCode === '0'){
      resolve();
      showSpinner();
			fillCombos(tmp.distributionListName, resp.entityId, () => {
        $('#distributionGroupList option[value="'+resp.entityId+'"]')
          .prop('selected', true);
        createTablesForList(findSelectedCellForLists().id);
        hideSpinner();
      });
		} else if(resp && resp.responseCode && resp.responseCode === '-1') {
			reject(resp.responseMessage);
		}
	});
}

function editGroupName(newName, done, reject) {

  newName = newName.trim();

  try {
    validateListName(newName);
  } catch(e) {
    reject(e.message);
    return;
  }

  var tmp = new Object();
	tmp.newDistributionListName = newName;
  tmp.currentDistributionListId = findSelectedCellForLists().id;

	saveSystemActivity({
		action: 'submit',
		params: findSystemActivityParams(tmp),
		message: 'User edited a distribution list name.'
	});

	apicall('distributionapi','editDistributionListName',tmp).then(function(resp) {

		if(resp && resp.responseCode && resp.responseCode === '0'){
			done();
      showSpinner();
			fillCombos(tmp.newDistributionListName, tmp.currentDistributionListId, () => {
        createTablesForList(tmp.currentDistributionListId);
        hideSpinner();
      });

		} else if(resp && resp.responseCode && resp.responseCode === '-1') {
			cgToast(resp.responseMessage, 3000);
			hideSpinner();
		}

	});
}

function duplicateGroup(duplicateListName, resolve, reject) {
  duplicateListName = duplicateListName.trim();

  try {
    validateListName(duplicateListName);
  } catch(e) {
    reject(e.message);
    return;
  }

  var tmp = new Object();
	tmp.newDistributionListName = duplicateListName;
  tmp.clonedDistributionListId =  findSelectedCellForLists().id;

	saveSystemActivity({
		action: 'submit',
		params: findSystemActivityParams(tmp),
		message: 'User duplicated a distribution list.'
	});

	apicall('distributionapi','duplicateDistributionList',tmp).then(function(resp) {
		if(resp && resp.responseCode && resp.responseCode === '0'){
      resolve();
      showSpinner();
			fillCombos(tmp.newDistributionListName, resp.entityId, () => {
        $('#distributionGroupList option[value="'+resp.entityId+'"]').prop('selected', true);
        createTablesForList(findSelectedCellForLists().id);
        hideSpinner();
      });

		} else if(resp && resp.responseCode && resp.responseCode === '-1') {
			reject(resp.responseMessage);
		}
	});
}

function deleteGroup(done, listId) {
	var tmp = new Object();
	tmp.distributionListId = listId;

	saveSystemActivity({
		action: 'submit',
		params: findSystemActivityParams(tmp),
		message: 'User removed a distribution list.'
	});

	apicall('distributionapi','removeDistributionList',tmp).then(function(resp) {
    done();
		if(resp){
      showSpinner();
			if(resp.responseCode === '0') {
        // TODO whether this is still needed should be tested out by removing
        // all lists. The selection should go back to its disabled option.
			}
      delete distributionListMap[tmp.distributionListId];
      $('div.contact-search').css('visibility', 'hidden');
      $('div.contact-search > label > input').val("");
      fillCombos('', '', () => {
        createTablesForList(findSelectedCellForLists().id);
        hideSpinner();
      });

		}
	});
}

function hideAllTableElements() {
  $('table.distro-table').hide();
  $('#tableControlsContainer').hide();
  $('div.mngmt-text').hide();
  $('li.edit-list-action-btns').hide();
  $('div#tablePlaceholder').hide();
  $('div.add-remove-btn > a').hide();
  disableViewBySwitch();
}

function toggleCB() {
	var x = document.getElementById("distributionGroupList").value;

  if (currentSelection != x && x != null && x != "") {
		currentSelection = x;
		createTablesForList(findSelectedCellForLists().id);
	}
}

//this method creates the table for list!!!!
function createTablesForList(id) {
  //take the id of the selected list and use it 
  var tmp = new Object();
	var distlist = '';

  selectedGroup = id;
  distlist = id;
	tmp.distributionListId = id;

	var tmp2 = new Object();
	tmp2.distributionListId = id;

	if(!distlist) {
    hideAllTableElements();
    return;
  }

  // NOTE: The departmentId property/attribute may be modified for functional
  // NOTE: purposes and therefor should not be used as an API method parameter
	apicall('distributionapi','fetchDistributionListMembers',tmp).then(function(resp) {
    $('.container').css('display', 'flex')
    if (resp.items) {
      groupMembers = [];
      notGroupMembers = [];
      let deptIdNameMap = {}; // used to squash redundant departments together
			for (var i = 0; i < resp.items.length; i ++) {

				resp.items[i].sortingValue = i;
        resp.items[i].departmentId = Utility.cleanForKey(
          resp.items[i].departmentId||'DEPARTMENT_NONE'
        );

        if(!resp.items[i].department) {
          resp.items[i].department = 'NONE';
        }

        // squashing redundant departments together
        let cleanedDeptName = Utility.cleanForKey(resp.items[i].department);
        if(!deptIdNameMap.hasOwnProperty(cleanedDeptName)) {
          deptIdNameMap[cleanedDeptName] = resp.items[i].departmentId;
        } else {
          resp.items[i].departmentId = deptIdNameMap[cleanedDeptName];
        }

				if (resp.items[i].selected == true) {
					groupMembers.push(resp.items[i]);
				}
				else {
					notGroupMembers.push(resp.items[i]);
				}
			}
     
      groupMembers = groupMembers.sort(compareDeptWithDefaults);
      notGroupMembers = notGroupMembers.sort(compareDeptWithDefaults);

      console.debug(groupMembers);
      console.debug(notGroupMembers);

			$('#defaultOrderBtn').show();

      rebuildTable();

			$('.list-select').trigger('chosen:updated');

			return currentGroup, otherGroup;
		}
	});

	$('.list-select').trigger('chosen:updated');

	return selectedGroup, currentGroup, otherGroup;
}

// rebuilds table for contacts!!!!
function reloadTableContact(preventReload) {
	var tmp = new Object();

	var table = document.getElementById("distributionContactList");
  var rows = table.getElementsByTagName("tr");
  var distcontact = '';
  var selectedIdOfContact;
  for (var i=0; i < rows.length; i++){
    var currentRow = rows[i];
    var cell = currentRow.getElementsByTagName("td")[0];
    if (cell && cell.hasAttribute('class')) {
      selectedIdOfContact = cell.id;
    }
  }
  
	if (table!=='undefined') { 
    distcontact = selectedIdOfContact;
    selectedGroup = selectedIdOfContact;
  }

  tmp.personId = distcontact;

  if(!tmp.personId) {
    hideAllTableElements();
    return;
  }

	apicall('distributionapi', 'fetchDistributionListsWithContact', tmp).then(function(resp) {
        if (resp.items) {
      groupMembers2 = [];
      notGroupMembers2 = [];

      for (var i = 0; i < resp.items.length; i ++) {

        if (resp.items[i].selected == true) {
          groupMembers2.push(resp.items[i]);

        }
        else {
          notGroupMembers2.push(resp.items[i]);
        }
      }
      rebuildTable();
    }

	});
	return null;
}

//function needed to create new list of distro lists 
function fillCombo(listId, entityName, valueAttrib, displayAttrib, sortAttrib, selectedGroup, selectedGroupId, callback) {
	showSpinner();
  
  //emptying the previous tables to avoid duplicates of the table
  if (document.getElementById("dist-table")){
    var emptytable = document.getElementById("dist-table");
    var first = emptytable.firstElementChild;
    while(first){
      first.remove();
      first = emptytable.firstElementChild;
    }
    emptytable.remove();
  }

	var tmp = new Object();
	tmp.entityName=entityName;
	tmp.valueAttrib=valueAttrib;
	tmp.displayAttrib=displayAttrib;
	tmp.sortAttrib=sortAttrib;
	tmp.filterAttribute='-';
	tmp.filterValue='-';

	var select = document.getElementById(listId);
  $('#'+listId+' > option').each(function(idx, opt) {
    if(opt.value) {
      $(this).remove();
    }
  });

  // IMPORTANT -- only specific kinds are allowed for the "entityName" property,
  // see API method implementation for details
  apicall('distributionapi', 'getComboValues', tmp).then(function (resp) {
    switch (listId) {
      case 'distributionGroupList':
        distributionListMap = {};
        resp.items.forEach(item => {
            distributionListMap[item.value] = item.label;
          });
        loader.check('distroLists');
        break;
      case 'distributionContactList':
        resp.items.forEach(item => {
          item.label = item.label.toUpperCase();
        });
        loader.check('contacts');
        break;
      default:
        console.error('select element id changed without updating loader reference');
    }

    let items = resp.items.sort((a, b) => {
      return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
    });
    var str = "<table id='dist-table'> ";

    // Another switch to add BOLD elements only for the distributionGroup table element, not for contacts.
    switch (listId) {
      case 'distributionGroupList':
        for (var i = 0; i <= items.length; i++) {
          var item = items[i];
          if (item != null) {
            str = str + "<tr><td style='font-weight:bold;' id=\"" + item.value + "\">" + item.label + "</td><tr>";
          }
        }
        break;
      case 'distributionContactList':
        for (var i = 0; i <= items.length; i++) {
          var item = items[i];
          if (item != null) {
            str = str + "<tr><td id=\"" + item.value + "\">" + item.label + "</td><tr>";
          }
        }
        break;
      default:
        console.error('select element id changed without updating loader reference');
    }
    str += "</table>";
    select.innerHTML += str;

    //There is a bug that creates empty rows, this is removing them
    var distTable = document.getElementById('dist-table');
    var rows = distTable.getElementsByTagName('tr');
    rows.forEach(currentRow => {
      if (currentRow.innerHTML === '') {
        currentRow.remove();
      }
    });

    toggleCB();
    initExportModal();
    addRowHandlers();
    if (callback) {
      callback();
    }

    });
    function addRowHandlers() {
      // for the list view option
      var table = document.getElementById("distributionGroupList");
      var rows = table.getElementsByTagName("tr");
      for (var i = 0; i < rows.length; i++) {
        var currentRow = rows[i];
        var cell = currentRow.getElementsByTagName("td")[0];
        if (cell.hasAttribute('class')){
          cell.removeAttribute('class');
        }

        var createClickHandler = function(row) {
          return function() {

            //removing the class clicked from all other contacts to remove previous background color
            var table = document.getElementById("distributionGroupList");
            var rows = table.getElementsByTagName("tr");
            for (var i = 0; i < rows.length; i++) {
              var currentRow2 = rows[i];
              if (currentRow2 !== row){
                var cell = currentRow2.getElementsByTagName("td")[0];
                cell.removeAttribute("class");
              }
            };
            var cell = row.getElementsByTagName("td")[0];
            cell.setAttribute('class', 'clicked');
            var id = cell.id;
            createTablesForList(id);

            // checking if "All contacts" was clicked and removing/adding options
            if (cell.id === ALL_CREW_LIST_ID ){
              var extraOptions = document.getElementsByClassName('hide-for-allContact');
              extraOptions.forEach(el => {
                el.style.display = "none"
              });
              cell.innerHTML = "ALL CONTACTS";
            }
            else{
              var extraOptions = document.getElementsByClassName('hide-for-allContact');
              extraOptions.forEach( el => {
                el.style.display = "flex";
              });
            }
          };
        };
        currentRow.onclick = createClickHandler(currentRow);
      }


      // for the contact view option
      var table = document.getElementById("distributionContactList");
      var rows = table.getElementsByTagName("tr");

      for (var i = 0; i < rows.length; i++) {
        var currentRow =rows[i];
        var cell = currentRow.getElementsByTagName("td")[0];
        if (cell && cell.hasAttribute('class')) {
          cell.removeAttribute('class');
        }

        var createClickHandler = function(row) {
          return function() {

            //removing the class clicked from all other contacts to remove previous background color
            var table = document.getElementById("distributionContactList");
            var rows = table.getElementsByTagName("tr");
            for (var i = 0; i < rows.length; i++) {
              var currentRow2 = rows[i];
              if (currentRow2 !== row){
                var cell = currentRow2.getElementsByTagName("td")[0];
                cell.removeAttribute("class");
              }
            };


            var cell = row.getElementsByTagName("td")[0];
            cell.setAttribute('class', 'clicked');
            reloadTableContact(true);
          };
        };
        currentRow.onclick = createClickHandler(currentRow);
      }

    }
}

function fillCombos(selectedGroup, selectedGroupId, callback) {
	fillCombo("distributionGroupList","distribution_list","distributionListId",
			"distributionListName","distributionListName", selectedGroup,
			selectedGroupId, callback);
}

function getMetaData() {
	// Get the list of previous scores
	fillCombos('', '');
	fillCombo("distributionContactList", "PERSON", "id", "title", "title", contactQuery, '', function() {
      $('#contactManageOpt').click();
      adaptDisplayToFilters();
      let $contactList = $('#distributionContactList');
      $contactList[0].value = contactQuery;
      $contactList.trigger('chosen:updated');
      reloadTableContact(true);
  });
}


function getAllData() {
  getStoredData();
  getMetaData();
  getExpiringContacts();
}

function getStoredData() {
  contactQuery = Utility.getPageParam('contactId');
  r.deleteExtraParam('contactId');
}

function compareDeptWithDefaults(a, b) {
  let aName = (a.firstName + " " + a.lastName); 
  let bName = (b.firstName + " " + b.lastName);
  let result = (Number) (aName.localeCompare(bName, undefined, {sensitivity: 'base'}));  
  return result;

}

function compareNbNameAgent(p1, p2) {
  let sortingProp = 'firstName';
  let p1Nb = parseContactNumber(p1.getAttribute(sortingProp));
  let p2Nb = parseContactNumber(p2.getAttribute(sortingProp));
  if(p1Nb !== p2Nb) { return p1Nb - p2Nb }
  return p1.getAttribute(sortingProp).localeCompare(p2.getAttribute(sortingProp));
}

function parseContactNumber(string) {
  let matcher = string.match(/^\s*#*[\s-]*(\d+)/);
  return matcher !== null ?parseInt(matcher[1]) :Infinity;
}

function compareNameWithDefaults(a,b) {
  let nameSortBy = document.getElementById('sort-name-button').getElementsByTagName('i')[0].className;
  let depSortBy = document.getElementById('sort-dep-button').getElementsByTagName('i')[0].className;
 
  if(depSortBy == "fa fa-sort-down") {
    a = a.department;
    b = b.department;
    return a < b ?-1 :b < a ?1 :0;
  } else if(depSortBy == "fa fa-sort-up") {
    a = a.department;
    b = b.department;
    return b < a ?-1 :a < b ?1 :0;
  } else if(nameSortBy == "fa fa-sort-up") {    
    a = (a.firstName + " " +  a.lastName).toUpperCase();
    b = (b.firstName + " " +  b.lastName).toUpperCase();   
    return b < a ?-1 :a < b ?1 :0;
  } else {    
    a = (a.firstName + " " +  a.lastName).toUpperCase();
    b = (b.firstName + " " +  b.lastName).toUpperCase();   
    return a < b ?-1 :b < a ?1 :0;
  }
}
let nbChecked = 0;
let nbCheckboxes = 0;

function notifyChildCheckboxStateChange(checked){
  nbChecked += (checked ? 1:-1);
  let checkParent = $('#selectAllChk');
  if(nbChecked == 0){
    checkParent.prop("indeterminate", false);
    checkParent.prop("checked", false);
  }else if(nbChecked == nbCheckboxes){
    checkParent.prop("indeterminate", false);
    checkParent.prop("checked", true);
  }else{
    checkParent.prop("indeterminate", true);
  }
}

function setupSelectParent(){
  let target = getManagementMode() === MNGMT.BY_LIST ?'distro-child-chk' :'contact-chk';
  nbChecked = 0;
  nbCheckboxes = $("."+target).length;
  let checkParent = $('#selectAllChk');
  checkParent.prop("indeterminate", false);
  checkParent.prop("checked", false);
}

function initListeners() {
  // preventing listener duplicationupMembers
  $('.addModal').off('click');
  $('.addModalConfirm').off('click');

  $('#menu_editListName').on('click', showEditModal);
  $('#menu_compareLists').on('click', showCompareModal);
  $('#createListBtn').on('click', showAddModal);
  $('#menu_exportList').on('click', exportViewToggle);

  // these listeners require a list to be selected
  $('#menu_duplicateList').on('click', showDuplicateModal);
  $('#menu_deleteList').on('click', showDeleteModal);
  $('#menu_mergeList').on('click', mergeLists);

  $('#js-tree-select').on('click', exportDistributionLists);

  $('.addModalCancel').on('click', function() {
    $('.addModal').hide();
  });
  $('#exportModalCloser').on('click', exportViewToggle);
  $('.parentDisable').on('click',exportViewToggle);//Closes modal on backround click

  if(window.croogloocurrentpage.getAccessLevel() > 1) {
    $('#menu_editListName').show();
  }
  $('#listModifBtnsContainer').show();

  $('#addMemberBtn').on('click', toggleSelectedMembers);
  $('#removeMemberBtn').on('click', toggleSelectedMembers);
  
  // select-all / unselect-all / invert selection
  $('#selectAllChk').on('click', (e)=>{
    let checkbox = e.target;
    if(nbChecked > 0)
      checkbox.checked = false;
    toggleAllCheckboxes(checkbox.checked);
  });

  $('#invert-selection').on('click', toggleAllCheckboxes);

  $('#listManageOpt').on('click', (e)=>{
    swapMode(MNGMT.BY_LIST);
  });
  $('#contactManageOpt').on('click', (e)=>{
    swapMode(MNGMT.BY_CONTACT);
  });
  //list/contact management switch
  function swapMode(mode){
    $('div.contact-search > label > input').val("");
    $("input:checkbox").prop("checked", false);
    $('#listOptsContainer').toggle();
    $('#contactOptsContainer').toggle();
    adaptDisplayToFilters();
  }

  //member/non-member switch
  $('.show-members-switch > input').change(() => {
    $('div.contact-search > label > input').val("");
    rebuildTable();
  });

  //select boxes
  $('select#distributionContactList').change(reloadTableContact);
  $('select#distributionGroupList').change(function () {
    enableViewBySwitch();
    if (this.value !== ALL_CREW_LIST_ID) {
      $('li.edit-list-action-btns').show();
    } else {
      $('li.edit-list-action-btns').hide();
    }
    enableOptionBtns();
    createTablesForList(findSelectedCellForLists().id);
  });

  $('.contact-search input').focus(function() {
    $(this).parent().addClass('focused');
  }).blur(function() {
    $(this).parent().removeClass('focused');
  });
  document.querySelector('.contact-search > label > input').onkeyup = function() {
    if(this.value)
      setTimeout(() => searchContacts(this.value, this), 0);
  }
  document.querySelector('.contact-search > label > input').addEventListener('input', (e) => {
    const value = e.currentTarget.value;
    if(value === '')
      setTimeout(() => searchContacts(value, e.currentTarget), 0);
  })

  // Sort buttons
  document.getElementById('sort-name-button').addEventListener("click", function(e) {
    let iconTag = document.getElementById('sort-name-button').getElementsByTagName('i')[0];
    // reset the other sort icon
    document.getElementById('sort-dep-button').getElementsByTagName('i')[0].className = "fa fa-sort";
    toggleSortIcon(iconTag);
    rebuildTable();
  });
  document.getElementById('sort-dep-button').addEventListener("click", function(e) {
    let iconTag = document.getElementById('sort-dep-button').getElementsByTagName('i')[0];
    // reset the other sort icon
    document.getElementById('sort-name-button').getElementsByTagName('i')[0].className = "fa fa-sort";
    toggleSortIcon(iconTag);
    rebuildTable();
  });

  document.getElementById('viewContact').onclick = function(){
    // Hiding the list option button 
    $('#actionBtn').parent().css('display', 'none')

    // Initializing to the first contact and removing other selected
    var table = document.getElementById("distributionContactList");
    var rows = table.getElementsByTagName("tr");
    
    for (var i=0; i<rows.length; i++){
      var currentRow = rows[i];
      var cell = currentRow.getElementsByTagName("td")[0];
      if (cell) {
        cell.removeAttribute('class');
        if (i == 0) {
          cell.setAttribute('class', 'clicked');
          reloadTableContact(true);
        }
      }
    }

    viewByList = false;
    viewByContact = true;
    var viewByContact = document.getElementById('viewContact');
    var viewList = document.getElementById('list-selected');
    viewList.style.backgroundColor = '#ffffff';
    viewByContact.style.backgroundColor = '#DEF7EE';
    // This sets the display of the correct container element.
    document.getElementById('distributionGroupList').style.display = 'none';
    document.getElementById('distributionContactList').style.display = 'block';
  }

  document.getElementById('list-selected').onclick = function () {
    //Show list option btn
    $('#actionBtn').parent().css('display', 'flex')

    // Initializing to the first list and removing other selected 
    var table = document.getElementById("distributionGroupList");
    var rows = table.getElementsByTagName("tr");
    
    for (var i=0; i<rows.length; i++){
      var currentRow = rows[i];
      var cell = currentRow.getElementsByTagName("td")[0];
      cell.removeAttribute('class');
      if (i==0){
        cell.setAttribute('class', 'clicked');
        createTablesForList(cell.id);
      }
    }

    viewByList = true;
    viewByContact = false;
    var viewByContact = document.getElementById('viewContact');
    var viewList = document.getElementById('list-selected');
    viewList.style.backgroundColor = '#DEF7EE';
    viewByContact.style.backgroundColor = '#ffffff';
    // This sets the display of the correct container element.
    document.getElementById('distributionGroupList').style.display = 'block';
    document.getElementById('distributionContactList').style.display = 'none';
    document.getElementById('distributionGroupList').querySelectorAll('td').forEach(item => item.style.fontWeight = 700)
  }

  document.getElementById("btnContainerLeft").onclick = function(){toggleSelectedMembers();}

  // Initializing to the first list
  var table = document.getElementById("dist-table");
  if (table.rows.length > 0) {
    var currentRow = table.rows[0];
    var cell = currentRow.getElementsByTagName("td")[0];
    cell.setAttribute('class', 'clicked');
    createTablesForList(cell.id);
  }
  $('#list-selected').trigger('click')
}

function mergeLists() {
  let selectedListId = findSelectedCellForLists().id;
  
  let selectedListName = distributionListMap[selectedListId];
  let selectedListNameNoCount = selectedListName.substring(0, selectedListName.lastIndexOf(' ('));
  if(getMemberCount(selectedListId) === 0) {
    cgToast(i18n.t("js.dislist.merge.empty") , {className: 'info-toast'});
    return;
  }
  if (typeof selectedListId !== 'string' || selectedListId === ALL_CREW_LIST_ID) {
    console.error('cannot merge - no selected list');
    return;
  }
  swal({
    title: i18n.t("js.dislist.merge.title"),
    customContainerClass: 'swal-container-merge-lists',
    html: buildMergeListHTML(),
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("js.dislist.merge.button"),
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    showLoaderOnConfirm: true,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    showCloseButton: true,
    allowOutsideClick: () => !swal.isLoading(),
    onOpen: function(swal) {
      updateMergeListSwalContent();
    },
    preConfirm: function() {
			return new Promise(function(resolve, reject) {
        let currentListOption = document
          .querySelector('div.merge-options > input[value=into-current-list]');
        let newListOption = document
          .querySelector('div.merge-options > input[value=into-new-list]');
        if(!currentListOption.checked && !newListOption.checked) {
          reject(i18n.t("js.dislist.merge.reject.option"));
          return;
        }
        let newListName = document.getElementById('newListInputForMerge').value.trim();
        if(newListOption.checked) {
          try {
            validateListName(newListName);
          } catch(e) {
            reject(e.message);
            return;
          }  
        }
        let selectedListIDs = $('div.swal2-content select.distro-list-select').val();
        if(!selectedListIDs || !selectedListIDs.length) {
          reject(i18n.t("js.dislist.merge.reject.empty"));
          return;
        }
        let payload = new Object();
        if(newListOption.checked) {
          selectedListIDs.push(selectedListId);
          payload.name = newListName;
        } else {
          payload.listId = selectedListId;
        }
        payload.listIds = [].concat(selectedListIDs); // shallow copy
        apicall('distributionapi', 'mergeDistributionLists', {}, payload)
        .then(function(resp) {
          if(resp && resp.responseCode && resp.responseCode === '0') {
            resolve();
            showSpinner();
            fillCombos(payload.name||selectedListNameNoCount, resp.entityId, () => {
              $('#distributionGroupList option[value="'+resp.entityId+'"]').prop('selected', true);
              createTablesForList(findSelectedCellForLists().id);
              hideSpinner();
            });
          } else if(resp && resp.responseCode && resp.responseCode === '-1') {
            reject(resp.responseMessage || i18n.t("js.utils.something.wrong"));
          }
        }).catch(() => {
          reject(i18n.t("js.utils.something.wrong"));
        });
      });
    }
  }).then(function() {
    swal({
      title: i18n.t("response.success"),
      showConfirmButton: true,
      confirmButtonText: i18n.t("OK"),
      confirmButtonColor: '#13C46A',
      showCancelButton: false,
      type: 'success',
      allowOutsideClick: true
    }).catch(swal.noop);
  }).catch(swal.noop);

  function buildMergeListHTML() {
    $('#mergeListSwalContent').remove();
    let container = document.createElement('div');
    container.id = 'mergeListSwalContent';
    let mergeOptions = document.createElement('div');
    mergeOptions.className = 'merge-options';
    buildRadioOption(i18n.t("js.dislist.merge.into-opt1", {selectedListNameNoCount}), 'into-current-list', 'merge-option', mergeOptions);
    buildRadioOption(i18n.t("js.dislist.merge.into-opt2"), 'into-new-list', 'merge-option', mergeOptions);
    container.appendChild(mergeOptions);
    let newListInput = document.createElement('input');
    newListInput.id = 'newListInputForMerge';
    newListInput.type = 'text';
    newListInput.setAttribute('placeholder', i18n.t("js.dislist.merge.new-list-name"));
    newListInput.style.display = 'none';
    container.appendChild(newListInput);
    container.appendChild(buildDistroListSelect());
    return container.outerHTML;

    function buildRadioOption(labelHTML, value, name, parentElement) {
      let input = document.createElement('input');
      input.type = 'radio';
      input.name = name;
      input.value = value;
      let inputId = input.id = Utility.uniqueID();
      let label = document.createElement('label');
      label.innerHTML = labelHTML;
      label.setAttribute('for', inputId);
      parentElement.appendChild(input);
      parentElement.appendChild(label);
      return input;
    }

    function buildDistroListSelect() {
      let select = document.createElement('select');
      select.className = 'distro-list-select';
      select.setAttribute('multiple', 'multiple');
      select.setAttribute('data-placeholder', i18n.t("js.dislist.merge.select.placeholder", {selectedListNameNoCount}));
      select.style.display = 'none';  // so it doesn't briefly appear before 
                                      // chosen select is constructed
      select.style.padding = '0.5rem';
      for(let listEntry of Object.entries(distributionListMap)
          .filter(e => e[0] !== selectedListId)) {
        let option = document.createElement('option');
        option.value = listEntry[0];
        option.textContent = listEntry[1];
        if(getMemberCount(listEntry[0]) === 0) {
          option.setAttribute('disabled', 'disabled');
        }
        select.appendChild(option);
      }
      return select;
    }
  }

  function updateMergeListSwalContent() {
    let currentListOption = document.querySelector('div.merge-options > input[value=into-current-list]');
    currentListOption.checked = true;
    let newListOption = document.querySelector('div.merge-options > input[value=into-new-list]');
    currentListOption.onchange = function() {
      if(this.checked) {
        $('#newListInputForMerge').slideUp(400);
      }
    };
    newListOption.onchange = function() {
      if(this.checked) {
        let $newListInput = $('#newListInputForMerge');
        $newListInput.slideDown(400);
        $newListInput[0].focus();
      }
    };
    $('div.swal2-content select.distro-list-select').chosen({
      width: '100%',
      hide_results_on_select: false,
    });
  }

  function getMemberCount(listId) {
    return parseInt(/\((\d+)\)$/.exec(distributionListMap[listId])[1]);
  }
}


function toggleSortIcon(iconTag) {
  switch(iconTag.className) {
    case "fa fa-sort":
    case "fa fa-sort-up":
      iconTag.className = "fa fa-sort-down";
      break;
    case "fa fa-sort-down":
      iconTag.className = "fa fa-sort-up";
      break;
  }
}

function rebuildTable() {
  if(getMemberDisplayMode() === FILTER.SHOW_MEMBERS) {
    if(getManagementMode() === MNGMT.BY_LIST) {
      showMembers();
    } else {
      showContactLists();
    }
  }
  else {
    if(getManagementMode() === MNGMT.BY_LIST) {
      showNonMembers()
    } else {
      showNonContactLists();
    }
  }
  setupSelectParent();
}

/**
 * The regex matching in this function doesn't work.
 */
// function updateListMemberCount(memberCountDiff, selectedListId = null) {
//   var select = findSelectedCellForLists();
//   let selectedOption = document.querySelector('#' + select.id
//     + ' > option[value="' + (selectedListId || select.value) + '"]');
//   let initialMemberCount = parseInt(selectedOption.textContent
//     .match(/(\()(\d+)(\))$/)[2]);
//   distributionListMap[selectedListId || select.value] = selectedOption
//     .textContent = selectedOption.textContent.replace(/\(\d+\)$/,
//       '(' + (initialMemberCount + memberCountDiff) + ')');
// }

function toggleSelectedMembers() {
  switch(getManagementMode()) {
    case MNGMT.BY_LIST:
      let personIdArr = new Array();
      $('input.distro-child-chk').each(function(idx, input) {
        if(input.checked) {
          let personId = input.getAttribute('personId');
          if(!personIdArr.includes(personId)) {
            personIdArr.push(personId);
          }
        }
      });
      if(personIdArr.length) {
        if(getMemberDisplayMode() === FILTER.SHOW_MEMBERS) {
          removePerson(personIdArr.join(','));
          // TODO not working right now.
          // updateListMemberCount(0 - personIdArr.length);
        } else {
          addPerson(personIdArr.join(','));
          // TODO not working right now.
          // updateListMemberCount(personIdArr.length);
        }
      } else {
        cgToast(i18n.t("js.dislist.contact.empty")); //No contact selected error is coming from here.
      }
      break;
    case MNGMT.BY_CONTACT:
      let distroIdArray = new Array();
      $('input.contact-chk').each(function(idx, input) {
        if(input.checked) {
          distroIdArray.push(input.getAttribute('distributionListId'));
        }
      });
      if (distroIdArray.length) {
        if(getMemberDisplayMode() === FILTER.SHOW_MEMBERS) {
          console.log('removing distr list from contact');
          // TODO not working right now.
          // distrIdArr.forEach(function (listId) {
          //   updateListMemberCount(-1, listId);
          // });
          removePersonFromSelectedLists(distroIdArray.join(','));
        }
        else {
          console.log('adding distr list to contact');
          // TODO not working right now.
          // distrIdArr.forEach(function (listId) {
          //   updateListMemberCount(1, listId);
          // });
          addPersonToSelectedLists(distroIdArray.join(','));
        }
      }
      break;
    default: throw 'Invalid management mode';
  }
}

function enableViewBySwitch() {
  $('#viewBySwitch').removeClass('disabled-switch');
  $('#viewBySwitch').off('click');
}

function disableViewBySwitch() {
  $('#viewBySwitch').addClass('disabled-switch');
  $('#viewBySwitch').on('click', groupSelectionBlocker);
}

function disableOptionBtns(message) {
  $('#actionBtn').parent().css('display','none')
}

function enableOptionBtns() {
  $('#actionBtn').parent().css('display','flex')
}

function groupSelectionBlocker(event, message) {
  event.preventDefault();
  event.stopImmediatePropagation();
  console.log(message);
  if (message) {
    cgToast(message);
  } else {
    cgToast(i18n.t("js.dislist.empty"));
  }
}

const MNGMT = { BY_LIST: 0, BY_CONTACT: 1 }
const FILTER = { SHOW_MEMBERS: 0, SHOW_NON_MEMBERS: 1 }
const VIEW_BY = { DEPARTMENT: 0, NAME: 1 }

function getManagementMode() {
  return viewByList ? MNGMT.BY_LIST : MNGMT.BY_CONTACT;
}

function getMemberDisplayMode() {
  return $('.show-members-switch > input#dist-members').is(':checked') ? FILTER.SHOW_MEMBERS : FILTER.SHOW_NON_MEMBERS;
}

function getViewByMode() {
  return document.getElementById('deptOrderOpt').checked ? VIEW_BY.DEPARTMENT : VIEW_BY.NAME;
}

/**
 * Adapts select boxes, text content and tables' display to the selected filters
 */
function adaptDisplayToFilters() {
  $("input:checkbox").prop("checked", false);
  $("input:checkbox").prop("indeterminate", false);
  switch(getMemberDisplayMode()) {
    case FILTER.SHOW_MEMBERS:
      $('#addMemberBtn').hide();
      $('#removeMemberBtn').show();
      break;
    case FILTER.SHOW_NON_MEMBERS:
      $('#removeMemberBtn').hide();
      $('#addMemberBtn').show();
      break;
    default: throw 'Invalid member display mode returned';
  }

  switch(getManagementMode()) {
    case MNGMT.BY_LIST:
      $('#listOptsContainer').show();
      $('#contactOptsContainer').hide();

      //select boxes
      $('select#distributionContactList').hide();
      $('select#distributionGroupList').show();
      if(document.getElementById('distributionGroupList').value) {
        enableViewBySwitch();
        if (document.getElementById('distributionGroupList').value !== ALL_CREW_LIST_ID) {
          $('li.edit-list-action-btns').show();
          $('#btnContainerLeft').show();
          enableOptionBtns();
        } else {
          $('li.edit-list-action-btns').hide();
          $('#btnContainerLeft').hide();
          disableOptionBtns("Cannot be done for ALL CONTACTS list");
        }
        //include view by filter
        $('div#orderOptContainer').css('visibility', 'visible');
      } else {
        $('li.edit-list-action-btns').hide();
        disableViewBySwitch();
      }

      //data tables
      $('table#contactTable').hide();
      $('table#distroListTable').show();
      $('#tableControlsContainer').show();
      $('div.mngmt-text').show();

      //include agents switch
      //visibility is changed rather than display to prevent UI changes
      $('div#agentSwitchContainer').css('visibility', 'visible');

      if(window.croogloocurrentpage.getAccessLevel() < 2) {
        $('#menu_duplicateList').css('display','none');
        $('#menu_deleteList').css('display','none');
      }else{
        $('#createListBtn').show();
      }

      // create, duplicate, remove and export options
      if (document.getElementById('distributionGroupList').value !== ALL_CREW_LIST_ID) {
        $('a.option-btn').show();
      }
      break;
    case MNGMT.BY_CONTACT:
      $('#listOptsContainer').hide();
      $('#contactOptsContainer').show();

      //select boxes
      $('select#distributionGroupList').hide();
      $('li.edit-list-action-btns').hide();
      $('select#distributionContactList').show();

      //data tables
      $('table#distroListTable').hide();
      $('table#contactTable').show();
      $('#tableControlsContainer').show();
      $('div.mngmt-text').show();

      //include agents switch
      //visibility is changed rather than display to prevent UI changes
      $('div#agentSwitchContainer').css('visibility', 'hidden');

      //exclude view by filter
      $('#orderOptContainer').css('visibility', 'hidden');


      // add or remove selected btn
      $('#btnContainerLeft').show();

      $('div.contact-search').css('visibility', 'hidden');
      $('div.contact-search > label > input').val("");
      break;
    default: throw 'Invalid management mode returned';
  }
}

function showMembers()
{
  adaptDisplayToFilters();
  loadDistroListTable(groupMembers);
}

function showNonMembers()
{
  adaptDisplayToFilters();
  loadDistroListTable(notGroupMembers);
}

function loadDistroListTable(data) {
  let viewBy = getViewByMode();
  if (viewBy != VIEW_BY.DEPARTMENT) {
    data.sort(compareNameWithDefaults);
    $('div.contact-search').css('visibility', 'visible');
  } else {
    $('div.contact-search').css('visibility', 'hidden');
    $('div.contact-search > label > input').val("");
  }
  clearTable();
  fillTable();
  reconfigureTable();

  function clearTable() {
    let allRows = document.querySelectorAll('#distroListTable tbody tr');
    for(let r = allRows.length-1; r >= 0; r--) {
      if(!allRows[r].className.includes('cg-blueprint')) {
        allRows[r].parentNode.removeChild(allRows[r]);
      }
    }
  }

  function fillTable() {
    if(!data.length) {
      $('div#tablePlaceholder').show();
      return;
    } else {
      $('div#tablePlaceholder').hide();
    }
    for(let i = 0; i < data.length; i++) {
      if (viewBy == VIEW_BY.DEPARTMENT) {
        let foundParentRow = false;
        let departmentLabels = document.querySelectorAll('tr.distro-row td label');
        for(let j = 0, label; label = departmentLabels[j++];) {
          if(label.getAttribute('departmentId') === data[i].departmentId) {
            addChildRow(data[i], Utility.findEnclosingElement(label, 'TR'));
            foundParentRow = true;
            break;
          }
        }
        if(!foundParentRow) {
          let parentRow = createParentRow(data[i]);
          addChildRow(data[i], parentRow);
        }
      } else {
        addRow(data[i]);
      }
    }
    alphaSortNonCrew();
  }

  function alphaSortNonCrew() {
    ['CAST','AGENT'].forEach(deptName => {
      let lastAddedRow = document.querySelector('tr.parent-row[departmentId="DEPARTMENT_'+deptName+'"]');
      [].slice.call(document.querySelectorAll('tr[departmentId="DEPARTMENT_'+deptName+'"]:not(.parent-row)'))
      .sort(compareNbNameAgent).forEach(childRow => {
        if(lastAddedRow.nextSibling) {
          childRow.parentElement.insertBefore(childRow, lastAddedRow.nextSibling);
        } else {
          childRow.parentElement.appendChild(childRow);
        }
        lastAddedRow = childRow;
      });
    });
  }

  function reconfigureTable() {

    /* Expandable table rows */
    $('#distroListTable tr.distro-row.parent-row').off();
    $('#distroListTable tr.distro-row.parent-row').on('click', function(e) {
      if((/label|input|a|i/i).exec(e.target.tagName)) { return; }
      e.stopImmediatePropagation();
      e.preventDefault();

      $(this).toggleClass('toggle-open')
      var rowParent = $(this).data('id');

      $('.child-collapse').each(function() {
        var childID = $(this).data('parent');
        if (childID === rowParent) {
          $(this).toggleClass('child-expanded');
        }
      });
    });

    // adding relation between parent and child checkboxes - triggered by child
    $('input.distro-child-chk').off('change');
    $('input.distro-child-chk').on('change', function() {
      let isChecked = this.checked;
      let departmentSize = document.getElementsByClassName(this.className).length;
      let checkedDeptMembers = 0;
      $('input.' + this.className.replace(/\s/g,'.')).each(function(idx, input) {
        if(input.checked) {
          checkedDeptMembers++;
        }
      });
      let parentRow = document.getElementById('parentRow_'+this.getAttribute('departmentId'));
      if(checkedDeptMembers === departmentSize) {
        $(parentRow).find('input.parent-chk')[0].indeterminate = false;
        $(parentRow).find('input.parent-chk')[0].checked = true;
      } else if(checkedDeptMembers === 0) {
        $(parentRow).find('input.parent-chk')[0].indeterminate = false;
        $(parentRow).find('input.parent-chk')[0].checked = false;
      } else {
        $(parentRow).find('input.parent-chk')[0].checked = false;
        $(parentRow).find('input.parent-chk')[0].indeterminate = true;
      }
    });

    // adding relation between parent and child checkboxes - triggered by parent
    $('input.parent-chk').off('change');
    $('input.parent-chk').on('change', function() {
      let isParentChecked = this.checked;
      $('input.' + this.getAttribute('departmentId')).each(function(idx, input) {
        input.checked = isParentChecked;
      });
    });

    /* Striped expandable table rows */
    $('tr.distro-row:odd').addClass('odd');
    $('#distroListTable .child-collapse').each(function() {
      var parentColor = $(this).prevAll('.distro-row').css('background-color');
      if (parentColor === 'rgb(254, 254, 254)') {
        $(this).css('background-color', '#F7F7F7');
      } else {
        $(this).css('background-color', '#fefefe');
      }
    });

    // Alphabetically ordered row
    $('#distroListTable tr.distro-row.alphabetical').off();
    $('#distroListTable tr.distro-row.alphabetical').on('click', function(e) {
      if((/label|input|a|i/i).exec(e.target.tagName)) { return; }
      e.stopImmediatePropagation();
      e.preventDefault();
      $(this).find("input[type=checkbox]").trigger('click');
    });
  }

  function createParentRow(person) {
    let rowName = person.department.trim().toUpperCase();
    let departmentId = person.departmentId;
    let nbOfParentRows = document.querySelectorAll('tr.distro-row').length;
    let row = copyBlueprint('parentRowBP', true);
    row.id = getRowId(person);
    row.setAttribute('data-id', nbOfParentRows);
    row.setAttribute('departmentId', departmentId);

    let checkbox = document.querySelector('tr#'+row.id+' td input');
    checkbox.setAttribute('departmentId', departmentId);
    checkbox.id = 'checkbox-parent-'+nbOfParentRows;
    let label = document.querySelector('#'+row.id+' td label');
    label.textContent = rowName;
    label.setAttribute('departmentId', departmentId);
    label.setAttribute('for', checkbox.id);

    return row;
  }

  function addChildRow(person, parentRow) {
    let tr = document.createElement('tr');
    tr.setAttribute('data-parent', parentRow.getAttribute('data-id'));
    tr.setAttribute('departmentId', person.departmentId);
    tr.setAttribute('firstName', person.firstName);
    tr.className = 'child-collapse';
    if(parentRow.className.includes('toggle-open')) {
      tr.className += ' child-expanded';
    }

    let tdDept = document.createElement('td');
    tdDept.innerHTML = parentRow.children[0].innerHTML
      .replace(/checkbox-parent-\d+/g,'checkbox-child-' +
      document.querySelectorAll('tr').length);
    tr.appendChild(tdDept);

    let checkbox = $(tr).find('input')[0];
    checkbox.className = 'distro-child-chk ' + person.departmentId;
    checkbox.setAttribute('departmentId', person.departmentId);
    checkbox.setAttribute('personId', person.personId);

    addTableData('first-name', person.firstName, tr);
    addTableData('last-name', person.lastName, tr);
    addTableData('job-name', person.jobTitle, tr);

    tr.appendChild(document.createElement('td'));
    let nextDeptRow = document.querySelector('tr.parent-row[data-id="'
      + (parseInt(parentRow.getAttribute('data-id'))+1)+'"]');
    if(nextDeptRow != null) {
      parentRow.parentElement.insertBefore(tr, nextDeptRow);
    } else {
      parentRow.parentElement.appendChild(tr);
    }
  }

  function addRow(person) {
    let nbOfParentRows = document.querySelectorAll('tr.distro-row').length;
    let row = copyBlueprint('parentRowBP', true);
    row.id = getRowId(person);
    row.setAttribute('department-id', person.departmentId);
    row.setAttribute('person-id', person.personId);
    row.setAttribute('data-id', nbOfParentRows);
    row.classList.remove("parent-row");
    row.classList.add("alphabetical");
    if (nbOfParentRows % 2 == 1) {
      row.classList.add("odd");
    }

    let checkbox = document.querySelector('tr#'+row.id+' td input');
    checkbox.setAttribute('departmentId', person.departmentId);
    checkbox.setAttribute('personId', person.personId);
    checkbox.id = 'checkbox-parent-'+nbOfParentRows;
    checkbox.classList.add("distro-child-chk");
    checkbox.addEventListener('click', (e) => notifyChildCheckboxStateChange(e.target.checked));
    let label = document.querySelector('#'+row.id+' td label');
    label.textContent = person.firstName + " " + person.lastName;
    label.setAttribute('departmentId', person.departmentId);
    label.setAttribute('for', checkbox.id);

    let editBtn = document.querySelector('tr#'+row.id+' td a');
    $(editBtn).on('click', function(e) {
      showSpinner();
      let payload = new Object();
      payload.personId = person.personId;
      apicall('personapi', 'fetchContact', payload).then(function(resp) {
        console.debug(resp);
        hideSpinner();
        quickModifyPerson(resp.entity);
      }, function() {
        console.error("Failed to fetch contact");
      });
    });

    let departmentColumn = document.querySelector('#'+row.id+' td.department-name');
    departmentColumn.innerHTML = person.department;
    let jobNameColumn = document.querySelector('#'+row.id+' td.job-name');
    jobNameColumn.innerHTML = person.jobTitle;
    var lastColumn = document.querySelector('#'+row.id+' td.toggle-tree');
    lastColumn.parentNode.removeChild(lastColumn);
    row.appendChild(document.createElement('td'));
  }

  function addTableData(className, content, row) {
    let td = document.createElement('td');
    td.className = className;
    td.textContent = content;
    row.appendChild(td);
  }
}

function getRowId(person) {
  if(getViewByMode() == VIEW_BY.DEPARTMENT){
    return Utility.getValidId('parentRow_' + person.departmentId);
  } else {
    return Utility.getValidId('parentRow_' + person.departmentId + '_' + person.personId);
  }
}

var isSearching = false;
var searchAgain = false;
function searchContacts(searchText, searchInput) {
  if(getMemberDisplayMode() === FILTER.SHOW_MEMBERS) {
    var sortedCrewMembers = groupMembers;
  } else {
    var sortedCrewMembers = notGroupMembers;
  }
  if(isSearching) {
    searchAgain = true;
    console.warn('search already running');
    return;
  }
  isSearching = true;
  for(let c = 0, crewMember; crewMember = sortedCrewMembers[c++];) {
    let textMatch = false;
    for(let propVal of [
        (crewMember.firstName||'') + ' ' + (crewMember.lastName||''), 
        crewMember.jobTitle
    ]) {
      if(propVal.trim().toLowerCase().includes(searchText.toLowerCase())) {
        textMatch = true;
        break;
      }
    }
    try {
      document
        .querySelector(`tr.distro-row[person-id="${crewMember.personId}"][department-id="${crewMember.departmentId}"]`)
        .style.display = textMatch ?'table-row' :'none';
    } catch(e) {
      console.error(e);
    }
  }
  isSearching = false;
  if(searchAgain) {
    searchAgain = false;
    searchContacts(searchInput.value, searchInput);
  }
}

function toggleAllCheckboxes(shouldBeChecked) {
  if(shouldBeChecked !== undefined){
    if(shouldBeChecked) nbChecked = nbCheckboxes;
    else nbChecked = 0;
  }
  let targetCheckboxClass = getManagementMode() === MNGMT.BY_LIST ? 'distro-child-chk' :'contact-chk';
  $('input.'+targetCheckboxClass).each(function(idx, input) {
    if(shouldClick(input)) {
      if(shouldBeChecked === undefined) $(input).click();
      else input.checked = shouldBeChecked;
    }
  });
  function shouldClick(input) {
    return input.closest('tr.distro-row, tr.contact-row').style.display !== 'none' 
      && (typeof shouldBeChecked === undefined || input.checked !== shouldBeChecked);
  }
}

function quickModifyPerson(person) {
  let personProperties = person.properties;
  personProperties.personId = person.key.name;
  swal({
    title: i18n.t("js.dislist.modify.contact"),
    html: '<div class="grid-x">' +
    '<div class="medium-3 cell">' +
    '<label for="quick_modify_firstName" class="text-left middle">'+i18n.t("contact.firstname")+'</label>' +
    '</div>' +
    '<div class="medium-9 cell">' +
    '<input type="text" class="mandatory" name="firstName" id="quick_modify_firstName" placeholder="'+i18n.t("js.utils.type-here")+'">' +
    '</div>' +
    '<div class="medium-3 cell">' +
    '<label for="quick_modify_lastName" class="text-left middle">'+i18n.t("contact.lastname")+'</label>' +
    '</div>' +
    '<div class="medium-9 cell">' +
    '<input type="text" class="mandatory" name="lastName" id="quick_modify_lastName" placeholder="'+i18n.t("js.utils.type-here")+'">' +
    '</div>' +
    '<div class="medium-3 cell">' +
    '<label for="quick_modify_email" class="text-left middle">'+i18n.t("contact.email")+'</label>' +
    '</div>' +
    '<div class="medium-9 cell">' +
    '<input type="text" class="mandatory" name="email" id="quick_modify_email" placeholder="'+i18n.t("js.utils.type-here")+'">' +
    '</div>' +
    '<div class="medium-3 cell">' +
    '<label for="quick_modify_secondEmail" class="text-left middle">'+i18n.t("contact.2nd-email")+'</label>' +
    '</div>' +
    '<div class="medium-9 cell">' +
    '<input type="text" name="secondEmail" id="quick_modify_secondEmail" placeholder="'+i18n.t("js.utils.type-here")+'">' +
    '</div>' +
    '<div class="medium-3 cell">' +
    '<label for="quick_modify_otherEmails" class="text-left middle">'+i18n.t("contact.other-email")+'</label>' +
    '</div>' +
    '<div class="medium-9 cell">' +
    '<input type="text" name="otherEmails" id="quick_modify_otherEmails" placeholder="'+i18n.t("js.utils.type-here")+'">' +
    '</div>' +
    '<div class="medium-3 cell">' +
    '<label for="quick_modify_mobile" class="text-left middle">'+i18n.t("contact.mobile")+'</label>' +
    '</div>' +
    '<div class="medium-9 cell">' +
    '<input type="text" name="mobile" id="quick_modify_mobile" placeholder="'+i18n.t("js.utils.type-here")+'">' +
    '</div>' +
    '</div>',
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("button.confirm"),
    showLoaderOnConfirm: true,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    showCloseButton: true,
    allowOutsideClick: () => !swal.isLoading(),
    onOpen: function() {
      $('#quick_modify_firstName').val(personProperties.firstName);
      $('#quick_modify_lastName').val(personProperties.lastName);
      $('#quick_modify_email').val(personProperties.email);
      $('#quick_modify_secondEmail').val(personProperties.email2);
      $('#quick_modify_otherEmails').val(personProperties.email3);
      $('#quick_modify_mobile').val(personProperties.mobile);
    },
    preConfirm: function() {
      return new Promise((resolve, reject) => {
      $('#quick_modify_firstName').css('border-color', '');
      $('#quick_modify_lastName').css('border-color', '');
      $('#quick_modify_email').css('border-color', '');
      $('#quick_modify_secondEmail').css('border-color', '');
      $('#quick_modify_otherEmails').css('border-color', '');
      if ($('#quick_modify_firstName').val().trim() === '') {
        $('#quick_modify_firstName').css('border-color', 'red');
        $('#quick_modify_firstName').focus();
        reject(i18n.t("js.contact.new.reject.empty", {field: "$t(js.contact.new.field.firstname)"}));
        return;
      }
      if ($('#quick_modify_lastName').val().trim() === '') {
        $('#quick_modify_lastName').css('border-color', 'red');
        $('#quick_modify_lastName').focus();
        reject(i18n.t("js.contact.new.reject.empty", {field: "$t(js.contact.new.field.lastname)"}));
        return;
      }
      if ($('#quick_modify_email').val().trim() === '') {
        $('#quick_modify_email').css('border-color', 'red');
        $('#quick_modify_email').focus();
        reject(i18n.t("js.contact.new.reject.empty", {field: "$t(js.contact.new.field.email)"}));
        return;
      }
      if (!Utility.isValidName($('#quick_modify_firstName').val().trim())) {
        $('#quick_modify_firstName').css('border-color', 'red');
        $('#quick_modify_firstName').focus();
        reject(i18n.t("js.contact.new.reject.invalid", {field: "$t(js.contact.new.field.firstname)"}));
        return;
      }
      if (!Utility.isValidName($('#quick_modify_lastName').val().trim())) {
        $('#quick_modify_lastName').css('border-color', 'red');
        $('#quick_modify_lastName').focus();
        reject(i18n.t("js.contact.new.reject.invalid", {field: "$t(js.contact.new.field.lastname)"}));
        return;
      }
      if ($('#quick_modify_email').val().trim() !== '' &&
        !Utility.isValidEmailAddress($('#quick_modify_email').val().trim())) {
        $('#quick_modify_email').css('border-color', 'red');
        $('#quick_modify_email').focus();
        reject(i18n.t("js.contact.new.reject.invalid", {field: "$t(js.contact.new.field.email)"}));
        return;
      }
      if ($('#quick_modify_secondEmail').val().trim() !== '' &&
        !Utility.isValidEmailAddress($('#quick_modify_secondEmail').val().trim())) {
        $('#quick_modify_secondEmail').css('border-color', 'red');
        $('#quick_modify_secondEmail').focus();
        reject(i18n.t("js.contact.new.reject.invalid", {field: "$t(js.dislist.field.second-email)"}));
        return;
      }
      if ($('#quick_modify_otherEmails').val().trim() !== '') {
        let otherEmails = $('#quick_modify_otherEmails').val().trim().split(",");
        for (let i in otherEmails) {
          let otherEmail = otherEmails[i];
          if (!Utility.isValidEmailAddress(otherEmail)) {
            $('#quick_modify_otherEmails').css('border-color', 'red');
            $('#quick_modify_otherEmails').focus();
            reject(i18n.t("js.dislist.other.invalid", {otherEmail}));
            return;
          }
        }
      }
      personProperties.securitygroup = personProperties.securityGroups.length > 0? personProperties.securityGroups[0] : '';
      personProperties.firstName = $('#quick_modify_firstName').val();
      personProperties.lastName = $('#quick_modify_lastName').val();
      personProperties.email = $('#quick_modify_email').val();
      personProperties.email2 = $('#quick_modify_secondEmail').val();
      personProperties.email3 = $('#quick_modify_otherEmails').val();
      personProperties.mobile = $('#quick_modify_mobile').val();
      for (let key in personProperties) {
        if (typeof personProperties[key] === 'string' && personProperties[key].trim() === '') {
          personProperties[key] = '~';
        }
      }
      for (const key of ['inviteToCroogloo', 'welcomeToCroogloo', 'receivedSafeSender']) {
        if (!personProperties[key]) {
          personProperties[key] = "N";
        }
      }
        for (const key of ['agency', 'startDate', 'endDate']) {
        if (!personProperties[key]) {
          personProperties[key] = "~";
        }
      }
      personProperties.includeInDTR = true;
      personProperties.office = personProperties.officePhone;
      personProperties.home = personProperties.homePhone;
      $("input[personId='" + personProperties.personId + "']").parent().find('label')[0].innerHTML = (personProperties.firstName + " " + personProperties.lastName);
      personProperties.isNewContact = false;
      console.debug(personProperties);
      saveSystemActivity({
        action: 'submit',
        params: findSystemActivityParams(personProperties),
        message: 'User submitted a contact information.'
      });
      Validator.validateEditPerson(personProperties);
        apicall('personapi', 'editPerson', {}, personProperties).then(function (r) {
        if(r && r.responseMessage && r.responseMessage === 'success') {
          console.debug(r);
          resolve();
        } else if(r && r.responseMessage && typeof r.responseMessage == 'string') {
          reject(r.responseMessage);
        }
      }, (err)=>{
        console.error(err);
        cgToast(i18n.t("js.dislist.something.wrong"), {className: 'error-toast'});
        reject();
      });
     });
    }
  }).then(function() {
    swal({
      title: i18n.t("response.success"),
      type: 'success',
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("OK"),
      allowOutsideClick: true
    }).catch(swal.noop);
  }).catch(swal.noop);
}

function loadContactTable(data) {
  clearTable();
  fillTable();
  reconfigureTable();

  function clearTable() {
    let allRows = document.querySelectorAll('#contactTable tbody tr');
    for(let r = allRows.length-1; r >= 0; r--) {
      if(!allRows[r].className.includes('cg-blueprint')) {
        allRows[r].parentNode.removeChild(allRows[r]);
      }
    }
  }

  function fillTable() {
    let table = document.getElementById('contactTable');
    if(!data.length) {
      
      $('div#tablePlaceholder').show();
    } else {
      $('div#tablePlaceholder').hide();
      for(let i = 0; i < data.length; i++) {
        createContactRow(data[i].name, data[i].listId);
      }
    }
  }

  function reconfigureTable() {
    /* Striped expandable table rows */
    $('tr.contact-row:odd').addClass('odd');
  }

  function createContactRow(rowName, distributionListId) {
    let nbOfParentRows = document.querySelectorAll('tr.contact-row').length;
    let row = copyBlueprint('contactRowBP', true);
    row.setAttribute('data-id', 'contact_' + nbOfParentRows);

    let checkbox = document.querySelector('tr#'+row.id+' td input');
    checkbox.id = 'checkbox-contact-'+nbOfParentRows;
    checkbox.className = 'contact-chk';
    checkbox.addEventListener('click', (e) => notifyChildCheckboxStateChange(e.target.checked));
    checkbox.setAttribute('distributionListId', distributionListId);
    let label = document.querySelector('#'+row.id+' td label');
    label.textContent = rowName;
    label.setAttribute('for', checkbox.id);

    return row;
  }
}

function showContactLists()
{ 
  adaptDisplayToFilters();
  loadContactTable(groupMembers2);
}

function showNonContactLists()
{
  adaptDisplayToFilters();
  loadContactTable(notGroupMembers2);
}

function addPerson(personId) {
  let distributionListId = findSelectedCellForLists().id;
  saveSystemActivity({
    action: 'submit',
    params: findSystemActivityParams({
      personId: personId,
      distributionListId: distributionListId
    }),
    message: 'User added a member to a distribution list.'
  });

  updateDistroListMembers(personId, distributionListId, LIST_TRANSACTION.ADDITION);
}

function removePerson(personId) {
  let distributionListId = findSelectedCellForLists().id;
  saveSystemActivity({
    action: 'submit',
    params: findSystemActivityParams({
      personId: personId,
      distributionListId: distributionListId
    }),
    message: 'User removed a member from a distribution list.'
  });

  updateDistroListMembers(personId, distributionListId, LIST_TRANSACTION.REMOVAL);
}

function updateDistroListMembers(personIdConcat, distributionListId, listTransaction) {
  showSpinner();

  let personIdList = personIdConcat.split(',');
  let personIdChunk = [];

  while(personIdList.length) {
    let chunkSize = Math.min(personIdList.length, PERSON_CHUNK_SIZE)
    personIdChunk.push(personIdList.splice(0, chunkSize));
  }

  new TaskList({ passReference: true }).add(list => {
    let nbOfChunksLeft = personIdChunk.length;
    personIdChunk.forEach(chunk => {
      console.debug('submitted personId chunk to ' + listTransaction.apiMethod);
      console.debug(chunk);
      apicall('distributionapi', listTransaction.apiMethod, {
        distributionListId: distributionListId,
        personId: chunk.join(',')
      }).then(resp => {
        if(--nbOfChunksLeft === 0) {
          list.check('chunks_sent');
        }
      }).catch(err => {
        console.error(err);
        swalToast({
          type: 'error',
          title: i18n.t("js.dislist.update.list.failure"),
          confirmButtonText: i18n.t("OK")
        });
        list.check('chunks_sent');
      });
    });
  }, 'chunks_sent').oncomplete(function() {
    createTablesForList(findSelectedCellForLists().id);
    hideSpinner();
  }).execute();
}

function addPersonToSelectedLists(listId) {
  var params = new Object();
  params.distributionListIds = listId;
  params.personId = document.getElementById("distributionContactList").value;

  //alert(JSON.stringify(params));
  saveSystemActivity({
    action: 'submit',
    params: findSystemActivityParams(params),
    message: 'User added a member to a distribution list by contact.'
  });

  showSpinner();

  apicall('distributionapi','addDistributionListMembersByContact',params).then(function(resp) {
    reloadTableContact();
    hideSpinner();
  });
}

function removePersonFromSelectedLists(listId) {
  var params = new Object();
  params.distributionListIds = listId;
  params.personId = document.getElementById("distributionContactList").value;

  //alert(JSON.stringify(params));
  saveSystemActivity({
    action: 'submit',
    params: findSystemActivityParams(params),
    message: 'User removed a member from a distribution list by contact.'
  });

  showSpinner();

  apicall('distributionapi','removeDistributionListMemberByContact',params).then(function(resp) {
    reloadTableContact();
    hideSpinner();
  });

}

/**
 * Finds the cell that has been selected by the user in the table.
 * 
 * @returns the cell for the selected row in the table
 */
function findSelectedCellForLists() {
  var table = document.getElementById("dist-table");
  var rows = table.getElementsByTagName("tr");
  var foundCell;
  for (var i = 0; i < rows.length; i++) {
    var currentRow = rows[i];
    var cell = currentRow.getElementsByTagName("td")[0];
    if (cell.classList.contains("clicked")) { // If the cell is selected
      foundCell = cell;
      return foundCell;
    }
  }
  // If no cell was found, select the first cell in the table whichi should be the ALL CONTACTS cell with id matching ALL_CREW_LIST_ID
  // This should only happen if the page has just been reloaded.
  if (foundCell === undefined) {
    rows[0].getElementsByTagName("td")[0].classList.add("clicked");
    foundCell = rows[0].getElementsByTagName("td")[0]
  }

  return foundCell;
}
