import i18n from './Translation';
import CrooglooSub from './CrooglooSub.js';
import SubscriptionManager from './SubscriptionManager.js';

export default function manageSubscriptionBtn() {
  let manageSubscriptionBtn = document.getElementById('manageSubscriptionBtn');
  manageSubscriptionBtn.onclick = async function() {
    if(!croogloo_auth.crooglooauth || !croogloo_auth.crooglooauth.hasOwnProperty('isTrial')) {
      console.debug('not ready for manageSubscriptionBtn');
      cgToast(i18n.t("js.sub.mng.not-ready"));
      return;
    } else if(croogloo_auth.crooglooauth.email !== croogloo_auth.crooglooauth.productionOwnerEmail) {
      console.debug('prevented use of manageSubscriptionBtn - should not be visible');
      return;
    }
    await SubscriptionManager.initStore();
    let plan = new CrooglooSub(croogloo_auth.crooglooauth.planId);
    swal({
      title: i18n.t("js.sub.mng.title"),
      html: '<div style="text-align:center;margin-bottom:1rem">'
        + i18n.t("js.sub.mng.text."+(croogloo_auth.crooglooauth.isTrial ?'trial':'reg'), {planName: (plan.getName()||'Croogloo Office')})
        + '</b></div>' +
      '<div><select id="subscriptionPlanSelect">' +
      '<option value disabled selected>'+i18n.t("js.sub.mng.select")+'</option>' +
      plan.optionsAsHtml(true, true) +
      '</select></div>' +
      (!croogloo_auth.crooglooauth.isTrial?'<div style="font-size:0.8rem;text-align:center">'+i18n.t("js.sub.mng.trial.note")+'</div>':''),
      type: 'info',
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("button.submit"),
      showLoaderOnConfirm: true,
      useRejections: true, //important for swal2 v7.1.2
      expectRejections: true,
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      onOpen: function() {
        try {
          document.getElementById('swal2-content').style.textAlign = 'justify';
        } catch(e) {
          console.error(e);
        }
      },
      preConfirm: function() {
        return new Promise(function(resolve, reject) {
          let customReject = function(msg) {
            document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'pointer';
            reject(msg);
          }
          let newSubOption = document.getElementById('subscriptionPlanSelect');
          var newSubPlanId = newSubOption.value;
          if(!newSubPlanId) {
            customReject(i18n.t("js.sub.mng.reject.select"));
            return;
          }
          document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'not-allowed';
          let errMsg = i18n.t("js.sub.mng.server.err", {email: "support@croogloo.com"});
          apicall('securityadminapi', 'changeSubscriptionPlan', {
            newPlanId: newSubPlanId,
          }).then(function(resp) {
            console.debug(resp);
            if(typeof resp === 'object' && resp.responseCode === '0') {
              resolve(newSubPlanId);
            } else {
              throw new Error('server error');
            }
          }, err => customReject(errMsg)).catch(err => customReject(errMsg));
        });
      }
    }).then(function(newSubPlanId) {
      swal({
        title: i18n.t("response.success"),
        html: croogloo_auth.crooglooauth.isTrial
          ? i18n.t("js.sub.mng.done.trial", {newplan: `<b>${new CrooglooSub(newSubPlanId).getName()}</b>`})
          : i18n.t("js.sub.mng.done.reg"),
        type: 'success',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK"),
        useRejections: false,
        expectRejections: false
      }).then(function() {
        if(croogloo_auth.crooglooauth.isTrial) {
          // Soft reload to update config now that trial is over
          r.reload();
        }
      }).catch(swal.noop);
    }).catch(swal.noop);
  }
}
