import i18n from './components/Translation';
import swal from "sweetalert2";

export function init() {
  r.onunload = () => {
    if (croogloo_auth.crooglooauth.accountStatus === 'lost password') {
      if (window.location.hash) {
        cgToast(i18n.t("js.pwd.lost"));
      }
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  }
  loader.add(ui)
    .add(initListeners)
    .execute();
}

function ui() {
  $('#main').foundation();
}

function initListeners() {
  document.getElementById('changePassButton').onclick = changePassword;

  $('#showPasswordCheckbox').off();
  $('#showPasswordCheckbox').on('change', function () {
    if (this.checked) {
      document.getElementById("oldPassword").type = "text";
      document.getElementById("newPassword").type = "text";
      document.getElementById("newPasswordCopy").type = "text";
    }
    else {
      document.getElementById("oldPassword").type = "password";
      document.getElementById("newPassword").type = "password";
      document.getElementById("newPasswordCopy").type = "password";
    }
  });
}

function changePassword() {
  var oldPassword = $('#oldPassword').val();
  var newPassword = $('#newPassword').val();
  var newPasswordCopy = $('#newPasswordCopy').val();

  if (oldPassword === "" || newPassword === "" || newPasswordCopy === "") {
    $('#emptyFieldError').show();
    return;
  }
  else
    $('#emptyFieldError').hide();

  if (newPassword !== newPasswordCopy) {
    $('#unmatchingPasswordError').show();
    return;
  } $('#unmatchingPasswordError').hide();

  var passResetInfo = new Object();
  passResetInfo.oldPassword = oldPassword;
  passResetInfo.newPassword = newPassword;

  showSpinner();
  apicall('userAPI', 'changePassword', {}, passResetInfo).then(resp => {
    hideSpinner();
    var passwordResetContext = resp.items[0];
    if (passwordResetContext === "password successfully changed") {
      $('#gapiMethodError').hide();

      saveSystemActivity({
        action: 'request',
        params: findSystemActivityParams(passResetInfo),
        message: 'User successfully changed his password.'
      });

      swal({
        title: i18n.t("js.pwd.success"),
        type: 'success',
        confirmButtonText: i18n.t("button.confirm"),
        confirmButtonColor: '#13C46A',
        allowOutsideClick: true,
      });
      clearAllFields();
    } else if (passwordResetContext === "account activated" || passwordResetContext === "renew lost password complete") {
      $('#gapiMethodError').hide();

      var completionMessage = passwordResetContext === 'account activated'
        ? 'activated their account.'
        : 'recovered their password.'

      saveSystemActivity({
        action: 'request',
        params: findSystemActivityParams(passResetInfo),
        message: 'User successfully ' + completionMessage
      });

      apicall('securityadminapi', 'fetchUserSecurityList', {}).then(response => {
        if (response.responseCode !== "0") {
          console.error(response);
          console.error('Could not fetch user security list, redirecting to login screen for Mycroogloo');
          croogloo_auth.logout(false, false, 'default');
        } else {
          if (response.responseMessage === 'ADMIN') {
            croogloo_auth.logout(false, false, 'default');
          } else {
            window.location = 'https://app.mycroogloo.com';
          }
        };
      }).catch(error => {
        console.error(error);
        croogloo_auth.logout(false, false, 'default');
      });

    } else {
      saveSystemActivity({
        action: 'request',
        params: findSystemActivityParams(passResetInfo),
        message: 'User failed to change their password.'
      });

      $('#gapiMethodError').html(resp.items[0]);
      $('#gapiMethodError').show();
    }
  });
}

function clearAllFields() {
  $('#oldPassword').val("");
  $('#newPassword').val("");
  $('#newPasswordCopy').val("");
}
