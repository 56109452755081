import i18n from "i18next";
import moment from 'moment';
import en_file from "../../assets/js/i18n/languages/en.json";
import fr_file from "../../assets/js/i18n/languages/fr.json";

i18n.init({
    lng: localStorage.getItem("crooglooLocale"),
    fallbackLng: ["en", "fr"],
    resources: {
        en: { translation: en_file },
        fr: { translation: fr_file }
    },
    keySeparator: false,//might turn that back on -> gotta make the codes into actual codes
    interpolation: {
        escapeValue: false,
        format: function(value, format, lng) {
            if(value instanceof Date && format){
                return moment(value).locale(lng).format(format);
            }
        }
    },
    skipOnVariables: true
});

export default i18n;