import i18n from './components/Translation';
import Validator from './components/paramValidator.js';
import 'jquery.tooltips';
import '../assets/js/jquery.daterangepicker.min.js';
import swal from 'sweetalert2';

// person is initialized with mandatory fields with undefined value.
var person;
var issaved;
var skipUnloadCheck;
var relationshipSelected;
var relationshipDeselected;
var relationshipTargetSubcategory;
var newUnavailabilitiesAdded = []
var newUnavailabilitiesRemoved = []
var crewMember;
var securityGroups = []

export function init() {
	r.onunload = (c, n) => {
		var iss = issaved;
		return new Promise((resolve, reject) =>{
			if (!iss && !skipUnloadCheck) {				
				swal({
					title: i18n.t("js.contact.quit"),
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#13C46A',
					confirmButtonText: i18n.t("yes"),
					cancelButtonText: i18n.t("no"),
					showCloseButton: true,
					allowOutsideClick: true,
				}).then((conf)=>{
					if (conf) {
						resolve();
					} else{
						reject();
					}
				}, ()=> {
					reject();
				})
			} else {
				resolve();
			}
		});
	}
	loader
		.add(initGlobals, 'globals')
		.add(() => loadDepartments(() => loader.check('depts')))
		.add(() => loadSecurityGroups(() => loader.check('secg')))
		.add(formBind, ['formBind','depts','secg'])
		.add(loadDistrGroups, 'distgroups')
		.add(ui, 'ui')
		.add(initCalendarListeners)
		.add(loadPreDefined)
		.add(preFocusInput)
		.execute();
}

function initGlobals() {
	person = {
		id: 0, isNewContact: true, inviteToCroogloo: "N", includeInDTR: true, welcomeToCroogloo: "N",
		agent: '~', email2: '~', email3: '~', mobile: '~', mandatory: [
			"category", "firstName", "lastName", "email", "departmentId", "jobTitle"
		], addUnavailabilities: [], delUnavailabilities: [], mergedUnavailabilities: []
	};
	issaved = true;
	skipUnloadCheck = false;
	relationshipSelected = new Set();
	relationshipDeselected = new Set();
	loader.check('globals');
}

function ui(){
	$('#main').foundation();
  	Utility.loadStylesheet('/assets/css/jquery.tooltips.css');
	$(".checklist input[type='checkbox']").on('change', function() {
	    $(this).closest('li').toggleClass('box-checked', this.checked)
	});
	loader.check('ui');
}

function initCalendarListeners() {

	let tempRangeVal = null

	Utility.loadStylesheet('/assets/css/daterangepicker.min.css');
	if ($('.date-picker-wrapper').length) {
		$('.date-picker-wrapper').remove();
	}


	let rangeInput = $('#inactivity-range');
	let calendarColors = $('#date-range-colors') ;

	$("#start-display .delBtn").on("click", () => {
		$("#start-date-display").val(i18n.t("contact.start-end.no-start-date")) ;
		$("#date-range-start").val("") ;
	}) ;

	let startRange = $('#date-range-start') ;

	let startRangeConfig = {
		singleDate : true,
		getValue: function() {
			return $(this).val() ;
		},
		setValue: function(s) {

			if(!$(this).is(':disabled') && s != $(this).val()){
				if (s > $('#date-range-end').val() && $('#date-range-end').val() !== "") {
					cgToast(i18n.t("contact.start-end.past-end")) ;
				}
				else {
					$('#date-range-start').val(s);
					$('#start-date-display').val($('#date-range-start').val()) ;
				}
			}
		},
	} ;

	$('#start-plus-icon').dateRangePicker(startRangeConfig)


	$("#end-display .delBtn").on("click", () => {
		$("#end-date-display").val(i18n.t("contact.start-end.no-end-date")) ;
		$("#date-range-end").val("") ;
	}) ;
	
	let endRangeConfig = {
		singleDate : true,
		getValue: function() {
			return $(this).val() ;
		},
		setValue: function(s) {
			if (!$(this).is(':disabled') && s != $(this).val()) {
				if (s < $('#date-range-start').val()) {
					cgToast(i18n.t("contact.start-end.before-start")) ;
				}
				else {
					$('#date-range-end').val(s);
					$('#end-date-display').val($('#date-range-end').val()) ;
				}
			}
		},
	} ;
	
	$('#end-plus-icon').dateRangePicker(endRangeConfig)
	
	rangeInput.dateRangePicker({
		getValue: function(){
			
			$(this).data('dateRangePicker').clear()
			
			$('.end-day').html() == '...' ? $('#addInactiveDates').css("background-color", "gray") : $('#addInactiveDates').css("background-color", "#01AA25")
			document.querySelectorAll('.day.toMonth.valid').forEach((item) => {
	
				let time = item.getAttribute('time')
				let tempDate = new Date(parseInt(time))
				time = `${tempDate.getFullYear()}-${tempDate.getMonth()+1}-${tempDate.getDate()}`
				
				person.addUnavailabilities.forEach(date => {
					
					let formattedDate = date.start.split('-')
					if(formattedDate[2].charAt(0) === '0') {
						formattedDate[2] = formattedDate[2].charAt(1)
					}
	
					formattedDate = formattedDate.join('-')
	
					if(formattedDate == time) {
						item.style.backgroundColor = 'red'
					}
				})
	
				item.addEventListener('click', (e) => {
					e.preventDefault()
					$('.end-day').html() == '...' ? $('#addInactiveDates').css("background-color", "gray") : $('#addInactiveDates').css("background-color", "#01AA25")
				})
			})
	
			return $(this).val();
		},
		setValue: function(s) {
			if(!$(this).is(':disabled') && s != $(this).val()){
				tempRangeVal = s
			}
		},
	});

	$('.month-wrapper').ready(function () {
		let datePickerBody = document.getElementsByClassName('month-wrapper')[0];
		let datePickerLabel = document.createElement('div');
		datePickerLabel.style.textAlign = 'center';
		datePickerLabel.style.color = 'black';
		datePickerLabel.innerHTML = '<b style="color:black;"><span style="font-size:1rem;">'+i18n.t("js.contact.dates.title")+'</span>'
			+ '<br/>'+i18n.t("js.contact.dates.text.line1")+'<br/>'+i18n.t("js.contact.dates.text.line2")+'<br/>'+i18n.t("js.contact.dates.text.line3")+'<br/></b><br/>'
			+ '<button class="button small" id="startDatePickerSubmitBtn">'+i18n.t("button.submit")+'</button>';
		datePickerBody.appendChild(datePickerLabel);
		
	
		let datePickerBody1 = document.getElementsByClassName('month-wrapper')[1];
		let datePickerLabel1 = document.createElement('div');
		datePickerLabel1.style.textAlign = 'center';
		datePickerLabel1.style.color = 'black';
		datePickerLabel1.innerHTML = '<b style="color:black;"><span style="font-size:1rem;">'+i18n.t("js.contact.dates.title")+'</span>'
			+ '<br/>'+i18n.t("js.contact.dates.text.line1")+'<br/>'+i18n.t("js.contact.dates.text.line2")+'<br/>'+i18n.t("js.contact.dates.text.line3")+'<br/></b><br/>'
			+ '<button class="button small" id="endDatePickerSubmitBtn">'+i18n.t("button.submit")+'</button>';
		datePickerBody1.appendChild(datePickerLabel1) ;
	
		$('#startDatePickerSubmitBtn').click(function(e) {
			e.preventDefault();
			$('#start-plus-icon').data('dateRangePicker').close();
		});
	
		$('#endDatePickerSubmitBtn').click(function(e) {
			e.preventDefault();
			$('#end-plus-icon').data('dateRangePicker').close();
		});
	
	
	
		let unavailabilitiesBody = document.getElementsByClassName("month-wrapper")[2] ;
		let unavailabilitiesLabel = document.createElement("div") ;
		unavailabilitiesLabel.style.textAlign = "center" ;
		unavailabilitiesLabel.style.color = "black" ;
		unavailabilitiesLabel.innerHTML = '<b style="color:black;"><span style="font-size:1rem;">'+i18n.t("js.contact.inactive.title")+'</span><br/>'+i18n.t("js.contact.inactive.text.line1")+'<br/></b>'
		+ '<button class="button small" id="addInactiveDates">'+i18n.t("button.submit")+'</button>' ;
		unavailabilitiesBody.appendChild(unavailabilitiesLabel) ;
		
		document.getElementById("addInactiveDates").addEventListener('click', (e) => {
			e.preventDefault();
	
			if($('.end-day').html() === '...') return
	
			$('#inactivity-range').data('dateRangePicker').close();
			let range = tempRangeVal
			if(range === "") return;
			
			tempRangeVal = null
			let valuesRange = range.split("to");
			let startDateRange = new Date(valuesRange[0].trim()) ;
			let endDateRange = new Date(valuesRange[1].trim()) ;
			let beginningDate = new Date($("#date-range-start").val()) ;
	
			if (beginningDate !== "" && (startDateRange < beginningDate || endDateRange < beginningDate)) {
				cgToast(i18n.t("contact.start-end.before-start")) ;
				return ;
			}
	
			let endDate = new Date($("#date-range-end").val()) ;
			if (endDate !== "" && (startDateRange > endDate || endDateRange > endDate)) {
				cgToast(i18n.t("contact.start-end.past-end")) ;
				return ;
			}
	
			let rangeObject = {
				start: valuesRange[0].trim(),
				end: valuesRange[1].trim()
			}
	
			let inactivityDiv = $('#inactivity-range')
	
			var dateArray = getDates(new Date(rangeObject.start).addDays(1), new Date(rangeObject.end).addDays(1));
			for (let i = 0; i < dateArray.length; i ++ ) {
				var dateString = new Date(dateArray[i].getTime() - (dateArray[i].getTimezoneOffset() * 60000 ))
				.toISOString()
				.split("T")[0];
				
				person.addUnavailabilities.push({
					start: dateString,
					end: dateString
				})
	
				newUnavailabilitiesAdded.push({
					start: dateString
				})
			}
	
			const uniqueDates = [];
			const uniqueDatesToBePrinted = []
	
			person.addUnavailabilities = person.addUnavailabilities.filter(element => {
				const isDuplicate = uniqueDates.includes(element.start);
	
				if (!isDuplicate) {
					uniqueDates.push(element.start);
	
					return true;
				}
	
				return false;
			});
	
			newUnavailabilitiesAdded = newUnavailabilitiesAdded.filter(element => {
				const isDuplicate = uniqueDatesToBePrinted.includes(element.start);
	
				if (!isDuplicate) {
					uniqueDatesToBePrinted.push(element.start);
	
					return true;
				}
	
				return false;
			});
			
	
			person.addUnavailabilities.sort((a,b) => {
				return new Date(a.start) - new Date(b.start)
			})
	
			newUnavailabilitiesAdded.sort((a,b) => {
				return new Date(a.start) - new Date(b.start)
			})
	
			mapOutInactivityDivs()
		
		});
	
	
	});
}

function mapOutInactivityDivs() {

	$('#inactivity-range').empty()

	person.addUnavailabilities.forEach(item =>  {
		let startDateArr = item.start.split('-')
		let endDateArr = item.end.split('-')

		// startDate consists of a date in the format YYYY-MM-DD
        // by using split('-') we get an array of length 3 that contains DD at index 2

		while(parseInt(startDateArr[2]) <= parseInt(endDateArr[2])) {

			let divToPush = document.createElement('div')
			let textToPush = document.createElement('p')
			let iconToPush = document.createElement('i')

			textToPush.innerHTML = startDateArr.join('-')
			textToPush.style.textAlign = 'center'
			textToPush.style.marginBottom = '0'
			textToPush.style.color = "black"
			textToPush.style.flex = '1'

			iconToPush.className = "fa fa-close";
			iconToPush.style.color = '#7B7B7B';
			iconToPush.style.cursor = 'pointer';
			iconToPush.style.paddingRight = '5px'
			iconToPush.id = startDateArr.join('-')

			divToPush.style.display = 'flex'
			divToPush.style.alignItems = 'center'
			divToPush.style.justifyContent = 'center'
			divToPush.style.border = '1px solid #CCCCCC'
			divToPush.style.padding = '3px'
			divToPush.style.borderRadius = '5px'
			divToPush.style.height = '30px'
			divToPush.style.width = '175px';
			divToPush.style.backgroundColor = '#F5F5F5'

			divToPush.appendChild(textToPush)
			divToPush.appendChild(iconToPush)

			$('#inactivity-range').append(divToPush)
			
			let temp = (parseInt(startDateArr[2]) + 1).toString()
			startDateArr[2] = temp
		}

	})	

	abilityToRemoveInactivityDivs()
}

// this function simply adds X amount of days between a start date and returns it in a specific format

Date.prototype.addDays = function(days) {
	var dat = new Date(this.valueOf())
	dat.setDate(dat.getDate() + days);
	return dat;
}

// getDates gets a range of dates between a start/end date in a specific format

function getDates(startDate, stopDate) {
	var dateArray = new Array();
	var currentDate = startDate;
	while (currentDate <= stopDate) {
		dateArray.push(currentDate)
		currentDate = currentDate.addDays(1);
	}
	return dateArray;
}


function abilityToRemoveInactivityDivs() {

	$('#inactivity-range > div > i').on('click', (e) => {
		e.stopPropagation()
		let childNode = document.getElementById(e.target.id)
		childNode.parentNode.style.display = 'none'
		let deletedDate = e.target.id.trim()

		person.delUnavailabilities.push({
			start: deletedDate,
			end: deletedDate
		})

		newUnavailabilitiesRemoved.push({
			start: deletedDate
		})

		person.addUnavailabilities = person.addUnavailabilities.filter(item => item.start != deletedDate)
		
	})
	
}




function preFocusInput() {
	if(typeof Utility.getPageParam('focusOnLoad') === 'string') {
		try {
			document.getElementById(Utility.getPageParam('focusOnLoad')).focus();
		} catch(e) {
			console.error(e);
		}
	}
}

function initListeners() {


	$('#more-info-container').on('click', (e) => {
		e.stopPropagation()
		e.preventDefault()
		$('#more-info-container').remove()
		$('#content-addInfo').css('display', 'block')
	})


	function securityGroupsHTML() {
		let select = document.createElement('select')
		select.id = "securityGroupCombo"
		select.setAttribute('name', 'securitygroup')

		let option = document.createElement('option')
		option.value = "Placeholder"
		option.textContent = "Please select a Security Group"
		select.appendChild(option)

		securityGroups.forEach(item => {
			let option = document.createElement('option')
			item.key.name = item.key.name
			option.value = item.key.name
			option.textContent = item.key.name === "DH" ? "DTR" : item.key.name
			select.appendChild(option)
		})


		return select
	}
	setVal('distribution_invite', '');

	document.querySelector('.slider.round').addEventListener('click', () => {


		if(!document.getElementById('distribution_invite').checked) {
			swal({
				title: "Security Group",
				html: securityGroupsHTML(),
				type: 'info',
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonColor: '#13C46A',
				confirmButtonText: "Confirm",
				cancelButtonText: i18n.t("button.cancel"),
				showCloseButton: true,
				preConfirm: function() {
					return new Promise((resolve, reject) => {
						let securityGroupCombo = document.getElementById('securityGroupCombo')
						if(securityGroupCombo.value === "Placeholder" || securityGroupCombo.value === "") {
							reject("Please select a Security Group");
							return;
						}
						person.securitygroup = securityGroupCombo.value
						setVal('distribution_invite', securityGroupCombo.value);
						document.querySelector('.text-left').innerHTML = `<b>${securityGroupCombo.value === 'DH' ? 'DTR' : securityGroupCombo.value} ACCESS</b>`
						resolve();
					});
				}
			})
			.catch(result => {
				if(result === 'cancel' || result === 'close') {
					document.getElementById('distribution_invite').checked = false
				}	
				else {
					console.error(result)
				}
			});
		}
		else {
			document.querySelector('.text-left').innerHTML = "Invite To Croogloo"
			person.inviteToCroogloo = "N"
			person.securitygroup = ""
		}
	})
	
}

function formBind(){
	var personForm = document.querySelector("#personForm");
	var fields = personForm.querySelectorAll('input, select');
	console.log("personform: ", personForm, fields)
	fields.forEach((f) => {
		f.onchange = function(event) {
			issaved = false;
			var field = event.target;			
			field.classList.remove('mandatory');

			if (field.name == 'category') {
				personForm.querySelector("#relationship").style = "display:none;";
				personForm.querySelector("#agencycell").style = "display:none;";
				personForm.querySelector("#distribution_job_title").style.display = "" ;

				switch (field.value) {
					case 'cast':
						personForm.querySelector("#relationship").style.display = "flex";
						personForm.querySelector(".cast-number-cell").style.display = "block"
						personForm.querySelector(".contact-checkbox-container").style.display = "flex" ;
						fillRelationshipSelect('Agent');
						break;
					case 'agent':
						personForm.querySelector("#agencycell").style = "";
						personForm.querySelector("#relationship").style.display = "flex";
						personForm.querySelector(".cast-number-cell").style.display = "block"
						personForm.querySelector(".contact-checkbox-container").style.display = "none" ;
						personForm.querySelector("#distribution_job_title").style.display = "none" ;
						fillRelationshipSelect('Cast');
						break;
					case 'crew':
						personForm.querySelector(".contact-checkbox-container").style.display = "flex" ;
						personForm.querySelector("#relationship").style.display = "none";
						personForm.querySelector(".cast-number-cell").style.display = "none"
						break;
					case "studio" :
						personForm.querySelector(".contact-checkbox-container").style.display = "none" ;
						personForm.querySelector("#relationship").style.display = "none";
						personForm.querySelector(".cast-number-cell").style.display = "none"
						break ;
					case "vendor" :
						personForm.querySelector(".contact-checkbox-container").style.display = "none" ;
						personForm.querySelector("#relationship").style.display = "none";
						personForm.querySelector(".cast-number-cell").style.display = "none"
						break;
					case "union" :
						personForm.querySelector(".contact-checkbox-container").style.display = "none" ;
						personForm.querySelector("#relationship").style.display = "none";
						personForm.querySelector(".cast-number-cell").style.display = "none"
						break;
					default:
				}
				adaptJobTitleLabel(field.value);
			} else if(field.name=='departmentId') {
				var opt = personForm.querySelector('#' + field.value + '_optid');
				person['departmentName'] = opt.textContent;
				try {
					person['deptDisplayOrder'] = parseInt(opt.getAttribute('displayorder')) + 10;
				} catch (error){
					person['deptDisplayOrder'] = 1;
				}
			} 

			person[field.name] = field.value.trim();
			
      
		}
		if (person.mandatory.includes(f.name)) {
			let lf = document.querySelector('label[for="' + f.id + '"]');
			if (lf) {
				lf.classList.add('mandatory');
			}
		}
	});
	var saveBtn = document.querySelector("#saveBtn");//COMEHERE
	saveBtn.onclick = function (event) {
		event.stopImmediatePropagation();
		event.preventDefault();
		var ready=true;
		if ('mandatory' in person) {
			let first=null;
			console.log("person: ", person)
			for (var k in person.mandatory){
				var v=person.mandatory[k];
				if (person[v]===undefined || person[v].trim()==='') {
					var el = personForm.querySelector("[name="+v+"]");
					if(!$(el).is(':visible')) { continue; }
					el.classList.add('mandatory');
					first = first==null?el:first;
					ready=false;
					//break;
				}
			}
			if (first) first.focus();
		}
		let invalidEmails = [];
		if((invalidEmails = areEmailsValid()).length) {
			ready = false;
			invalidEmails.forEach(invalidEmail => {
				Utility.showErrorCgToast(i18n.t("js.contact.reject.email", {email:invalidEmail.value}));
				personForm.querySelector("[name=" + invalidEmail.name + "]").focus();
			})
		}
		if (!Utility.isValidName(person.firstName + " " + person.lastName)) {
			ready = false;
			Utility.showErrorCgToast(i18n.t("js.contact.reject.full.name", {firstname: person.firstName, lastname: person.lastName}));
    	}
		let castNumberField = document.getElementById('castNumberField');
		if(person.category != 'cast' || castNumberField.value.trim().length == 0) {
			person[castNumberField.name] = null;
		} else if (!Utility.isValidCastNumber(castNumberField.value.trim())) {
			ready = false;
			cgToast(i18n.t("js.contact.reject.cast-no"), {
				className: 'error-toast',
				showCloseButton: true,
				hiding: 'manual'
			});
		} else {
			person[castNumberField.name] = castNumberField.value = castNumberField.value.trim().replace(/^0*(?=\d)/, '');
		}
		if(ready){
			console.debug('emails validated!');
			if (person.isNewContact) {
				person.id = ('PERSON_' + person.firstName + '_' + person.lastName + '_'+ person.jobTitle).toUpperCase();
			}

			//TODO ? : clear fields that aren't present in certain categories to not store unnecessary data ?
			//example : when putting info in 'more info' when category is 'crew' and then switch to 'agent', the info in 'more info' is still present and will be stored

			console.debug(person);
			var tmp = new Object();
			tmp.id = person.id;
			tmp.isNewContact = person.isNewContact;
			tmp.jobTitle = person.jobTitle;
			tmp.firstName = person.firstName;
			tmp.lastName = person.lastName;
			tmp.subcategory = person.category;
			tmp.email = person.email || '~';
			tmp.email2 = ('email2' in person) && person.email2!='' ? person.email2 :'~';
			tmp.email3 = ('email3' in person) && person.email3!='' ? person.email3 :'~';
			tmp.home = ('home' in person) && person.home!='' ? person.home :'~';
			tmp.mobile = person.mobile || '~';
			tmp.office = ('office' in person) && person.office != '' ? person.office : '~';
			tmp.address1 = ('address1' in person) && person.address1!='' ? person.address1 :'~';
			tmp.locality = ('locality' in person) && person.locality!='' ? person.locality :'~';
			tmp.state = ('state' in person) && person.state!='' ? person.state :'~';
			tmp.postalcode = ('postalcode' in person) && person.postalcode != '' ? person.postalcode : '~';
			tmp.securitygroup = ('securitygroup' in person) && person.securitygroup!='' ? person.securitygroup:'~';
			tmp.departmentId = person.category == 'crew' ?person.departmentId :('DEPARTMENT_'+person.category.toUpperCase());
			tmp.departmentName = person.category == 'crew' ?person.departmentName :person.category.toUpperCase();
      		tmp.castNumber = (person.category == 'cast' && ('castNumber' in person)) ?person.castNumber :null;
			// TODO change "deptDisplayOrder" to "displayOrder" and find deptDisplayOrder in the back-end
			// TODO "deptDisplayOrder" is not assigned properly on this page, but is later updated in a maintenance worker
			tmp.deptDisplayOrder = person.category == 'crew' ?person.deptDisplayOrder :1;
			tmp.inviteToCroogloo = ('securitygroup' in person) && person.securitygroup!='' ? 'Y' : 'N';
			tmp.includeInDTR = true
			tmp.receivedSafeSender = person.welcomeToCroogloo;
			tmp.creditName = ('creditName' in person) && person.creditName!='' ? person.creditName:'~';
			tmp.contactName = ('contactName' in person) && person.contactName!='' ? person.contactName:'~';
			tmp.contactNumber = ('contactNumber' in person) && person.contactNumber!='' ? person.contactNumber:'~';
			tmp.allergies = ('allergies' in person) && person.allergies!='' ? person.allergies:'~';
			tmp.gift = ('gift' in person) && person.gift!='' ? person.gift:'~';

			
			if ($('#date-range-start').val().trim()) {
				tmp.startDate = $('#date-range-start').val().trim();
			} else {
				tmp.startDate = "~";
			}

			if ($('#date-range-end').val().trim()) {
				tmp.endDate = $('#date-range-end').val().trim();
			} else {
				tmp.endDate = "~";
			}

			let hideSaveToast = cgToast(i18n.t("js.utils.saving"), { hiding: 'manual' });

			saveSystemActivity({
				action: 'submit',
				params: findSystemActivityParams(tmp),
				message: 'User submitted a contact information.'
			});

      		Validator.validateEditPerson(tmp);
			let isInvitedToCroogloo = tmp.inviteToCroogloo == 'Y';


			apicall('personapi', 'editPerson', {}, tmp).then(function (r) {
				let asyncFunctions = [];
				hideSaveToast();
				if(r && r.responseMessage && r.responseMessage === 'success') {
					let swalList = new Array() ;
					person.id = r.responseCode;
					console.debug(r);
					setVal("distribution_email", r.entity.properties.email);
					setVal("distribution_second_email", r.entity.properties.email2);
					setVal("distribution_other_emails", r.entity.properties.email3);
          			person.isNewContact = false;
					if (isInvitedToCroogloo) {
						let inviteUserCheckbox = document.getElementById('distribution_invite');
						inviteUserCheckbox.disabled = true;
						if (!inviteUserCheckbox.checked) { // in case clicked while saving
						inviteUserCheckbox.checked = true;
						$(inviteUserCheckbox).change();
						}
					}
          			issaved = true; // must be called after $(inviteUserCheckbox).change();
					console.debug(relationshipSelected.size, relationshipDeselected.size);
					if (person.dstoAdd && person.dstoAdd.length>0) {
						var tmp = new Object();
						tmp.personId = person.id;
						tmp.distributionListIds = person.dstoAdd.join(',');
						asyncFunctions.push(apicall('distributionapi','addDistributionListMembersByContact',tmp).then(function(resp){
							issaved = true;
							swalList.push(i18n.t("js.contact.update.distro")) ;
							person.dstoAdd = [];
						}, (err)=>{
							console.error(err);
							Utility.showErrorCgToast();
						}));;
					}
					if (relationshipDeselected.size > 0) {
						let payload = new Object();
						payload.targetPersonIds = Array.from(relationshipDeselected).join(',');
						payload.personId = person.id;
						asyncFunctions.push(apicall('personapi','removePersonAssociation',payload).then((resp) => {
							if (resp.responseMessage && resp.responseMessage == 'success') {
								relationshipDeselected.clear();
								swalList.push(i18n.t("js.contact.update.relationship")) ;
							} else {
								Utility.showErrorCgToast(r.responseMessage, 5000);
							}
						}, (err)=>{
							console.error(err);
							Utility.showErrorCgToast();
						}));
					}
					if (relationshipSelected.size > 0) {
						let payload = new Object();
						payload.targetPersonIds = Array.from(relationshipSelected).join(',');
						payload.personId = person.id;
						payload.personSubcategory = person.category;
						payload.targetPersonSubcategory = relationshipTargetSubcategory;
						asyncFunctions.push(apicall('personapi','createPersonAssociation',payload).then((resp) => {
							if (resp.responseMessage && resp.responseMessage == 'success') {
								relationshipSelected.clear();
								swalList.push(i18n.t("js.contact.update.relationship")) ;
							} else {
								Utility.showErrorCgToast(r.responseMessage, 5000);
							}
						}));
					}
					if (person.dstoRemove && person.dstoRemove.length > 0) {
						var tmp = new Object();
						tmp.personId = person.id;
						tmp.distributionListIds = person.dstoRemove.join(',');
						asyncFunctions.push(apicall('distributionapi','removeDistributionListMemberByContact',tmp).then(function(resp){
							issaved = true;
							swalList.push(i18n.t("js.contact.update.distro")) ;
							person.dstoRemove=[];
						}));
					}

					if(person.addUnavailabilities && person.addUnavailabilities.length > 0){
						var tmp = new Object();
						tmp.personId = person.id;
						tmp.unavailabilities = person.addUnavailabilities;

						let ranges = ''

						if(newUnavailabilitiesAdded.length > 1) {
							ranges = `${newUnavailabilitiesAdded[0].start} to ${newUnavailabilitiesAdded[newUnavailabilitiesAdded.length - 1].start}`
						}
						else if(newUnavailabilitiesAdded.length == 1) {
							ranges = newUnavailabilitiesAdded[0].start
						}

						// ranges checks for inactive days stored in newUnavailbiltiesAdded variable, if empty then no range is to be found

						asyncFunctions.push(apicall('personapi','addUnavailabilitiesByContact', tmp).then(function(resp){ //COMEHERE 
							if (resp.responseMessage && resp.responseMessage == 'success') {
								issaved = true;
								person.addUnavailabilities = [];
								if(ranges !== '') {
									swalList.push(i18n.t("contact.unavailable.add.success", { ranges }));
								}
							} else {
								if(ranges !== '') {
									swalList.push(i18n.t("contact.unavailable.add.error", {ranges})) ;
								}
							}
						}));
					}

					if(person.delUnavailabilities && person.delUnavailabilities.length > 0){
						var tmp = new Object();
						tmp.personId = person.id;
						tmp.unavailabilities = person.delUnavailabilities;
						let ranges = ''

						if(newUnavailabilitiesRemoved.length > 1) {
							ranges = `${newUnavailabilitiesRemoved[0].start} to ${newUnavailabilitiesRemoved[newUnavailabilitiesRemoved.length - 1].start}`
						}
						else if(newUnavailabilitiesRemoved.length == 1) {
							ranges = newUnavailabilitiesRemoved[0].start
						}
						
						// ranges checks for inactive days stored in newUnavailbiltiesRemoved variable, if empty then no range is to be found

						asyncFunctions.push(apicall('personapi','removeUnavailabilitiesByContact', tmp).then(function(resp){ //COMEHERE 
							if (resp.responseMessage && resp.responseMessage == 'success') {
								issaved = true;
								person.delUnavailabilities = [];
								if(ranges !== '') {
									swalList.push(i18n.t("contact.unavailable.del.success", { ranges }));
								}
							} else {
								if(ranges !== '') {
									swalList.push(i18n.t("contact.unavailable.del.error", {ranges})) ;
								}
							}
						}));
					}

					//when all apicalls are over, reload unavailabilities
					Promise.all(asyncFunctions).then(data => {
						cgToast(i18n.t('dtr.saving.saved'))
						person.addUnavailabilities = [] ;
						person.delUnavailabilities = [] ;
						var personId = window.croogloocurrentpage.extraParams.personId||'';
						person.isNewContact = personId=='';
						
						apicall('personapi', 'fetchUnavailabilitiesByContact', { personId: personId }).then(resp => {
							if(Array.isArray(resp.items)) {
								if(resp.items.length == 0){
									console.info("EMPTY! NO INACTIVITY RANGE!");
								}
							} else {
								throw new Error('unexpected server response');
							}
						}).catch(() => {
							Utility.showErrorCgToast(i18n.t("contact.unavailable.fetch.error"), 5000);
							console.error('failed to retrieve unavailability list for ' + personId);
						});
					});
					

					function buildContactSavedHtml() {
						let container = document.createElement("div") ;
						container.id = "contactSwalContent" ;
						let contactSavedContainer = document.createElement("div") ;
						let ul = document.createElement("ul") ;

						swalList.forEach((content) => {
							let li = document.createElement("li") ;
							li.textContent = content ;
							ul.appendChild(li) ;
						}) ;

						contactSavedContainer.appendChild(ul) ;
						container.appendChild(contactSavedContainer) ;

						return container.outerHTML ;
					}
				} else if(r && r.responseMessage && typeof r.responseMessage == 'string') {
					let errToastHtml = i18n.t("js.utils.something.wrong"); // should not be required (just there to 
																	// ensure there never is an undefined error message)
					if (r.responseMessage == 'This email address is already taken' && r.entity && r.entity.key.name !== person.id) {
						try {
							global.r.saveExtraParams(window.croogloocurrentpage.securityPageName, {
								personId: r.entity.key.name || parseInt(r.entity.key.id),
								personEntity: r.entity.properties,
								isSidesStandAlone: false, // DEPRECATED 
								reloadOnClose: false
							});
							errToastHtml = i18n.t("js.contact.email.taken")
								+ '&nbsp;<a href="#contact" target="_blank" class="no-text-select" style="color: lightblue;">'
								+i18n.t("js.contact.email.cgview")+'</a>';
						} catch (e1) {
							console.error(e1);
							console.error('failed to save extra params to load contact with given email');
							errToastHtml = r.responseMessage;
						}
					} else {
						errToastHtml = r.responseMessage;
					}
					Utility.showErrorCgToast(errToastHtml, 8000);
				}
			}, (err)=>{
				console.error(err);
				Utility.showErrorCgToast();
			}); 
		}
		event.preventDefault();
		event.stopPropagation();
		return false;
	}
	var closeBtn = document.getElementById('closeBtn');
	closeBtn.onclick = (event) => {
		event.preventDefault();
		event.stopPropagation();
		window.history.back(-1);
	}
	var addAnotherBtn = document.getElementById('addAnotherBtn');
	addAnotherBtn.onclick = (event) => {
		event.preventDefault();
		event.stopPropagation();
		r.onunload().then(() => {
			// We must use skipUnloadCheck to prevent the unload function from being 
			// triggered again after the hash change. We have to call onunload here 
			// because we only want to use r.clearExtraParams() if we know for certain 
			// that the unload was triggered by the addAnotherBtn element, whether 
			// the unsaved data prompt was needed or not. 
			skipUnloadCheck = true;
			r.clearExtraParams();
			let subcategorySelect = document.getElementById('distribution_category');
			if(subcategorySelect.value) {
				try {
					global.r.saveExtraParams(window.croogloocurrentpage.securityPageName, {
						subcategory: subcategorySelect.value,
						isSidesStandAlone: false, // DEPRECATED
						reloadOnClose: false
					});
				} catch(err1) {
					console.error(err1);
				}
			}
			// Reload to clear form and add another contact
			r.reload();
		}, function() {
			console.debug('cancelled "add another" action');
		});
	}
	loader.check('formBind')
}

function fillRelationshipSelect(targetSubcategories) {
  	relationshipTargetSubcategory = targetSubcategories.toLowerCase();
  	let label = document.getElementById('relationshipLabel');
	label.innerHTML = '<a style="margin-bottom: 0" id="addRelationshipTarget" class="button small"><i class="fa fa-plus"></i></a>';
	$('#relationship').show();
  	document.getElementById('addRelationshipTarget').onclick = function (e) {
    	createNewRelationshipTarget(targetSubcategories);
  	}

  let payload = new Object();
  payload.subcategories = relationshipTargetSubcategory;
  apicall('personapi', 'fetchContactsBySubcategories', payload).then(async (resp) => {
    $('#relationshipSelect').empty();
    $('#relationshipSelect').chosen('destroy');

    let select = document.getElementById('relationshipSelect');
	let placeholder = "";
	if(targetSubcategories == 'Agent'){
		placeholder = i18n.t("js.contact.relationship.agent");
	}else if(targetSubcategories == 'Cast'){
		placeholder = i18n.t("js.contact.relationship.cast");
	}else {
		placeholder = i18n.t("js.contact.relationship.default", {targetSubcategories});
	}
    select.setAttribute('data-placeholder', placeholder);
    console.debug(resp, person);
    let fetchedContacts = resp.items
      .filter(c => typeof c.properties.firstName == 'string' && c.properties.firstName.length > 0);
    for (let item of fetchedContacts) {
      let option = document.createElement('option');
      option.value = item.key.name;
      option.textContent = item.properties.firstName + ' ' + item.properties.lastName + ' (' + item.properties.email + ')';
      option.setAttribute('name', Utility.getValidId(item.key.name));
      select.appendChild(option);
    }
    $('#relationshipSelect').chosen({
      no_results_text: i18n.t("js.contact.relationship.unrecognized", {targetSubcategories:"$t(js.contact."+targetSubcategories+")"}),
      width: '100%'
      // max_shown_results: 15
    }).on('change', function (evt, params) {
      console.debug(evt, params, $('#relationshipSelect').val());
      if (params.selected) {
        relationshipSelected.add(params.selected);
        relationshipDeselected.delete(params.selected);
      }
      if (params.deselected) {
        relationshipDeselected.add(params.deselected);
        relationshipSelected.delete(params.deselected);
      }
      console.debug(relationshipSelected, ' ', relationshipDeselected);
    });
    if (!person.isNewContact) {
      let tmp = new Object();
      tmp.personIds = person.id;
      tmp.personSubcategory = person.category;
      tmp.targetPersonSubcategory = relationshipTargetSubcategory;
      apicall('personapi', 'fetchContactsFromAssociation', tmp).then(async (resp) => {
        for (let i = 0, item; item = resp.items[i++];) {
          let option = document.querySelector('option[value="' + item.key.name + '"]');
          option.setAttribute('selected', 'selected');
        }
        $('#relationshipSelect').trigger('chosen:updated');
      }, (err) => {
        console.error(err);
        Utility.showErrorCgToast();
      });
    }
  }, (err) => {
    console.error(err);
    Utility.showErrorCgToast();
  });
}

function areEmailsValid() {
	let invalidEmails = new Array();
	['email','email2','email3'].forEach(prop => {
		if(typeof person[prop] === 'string') {
			person[prop].split(',').forEach(email => {
				email = email.trim();
				if(email.trim() !== '' && email.trim() !== '~') {
					if(!Utility.isValidEmailAddress(email)) {
						invalidEmails.push({
							name: prop,
							value: email
						});
					}
				}
			});
		}
	});
	return invalidEmails;
}

function setVal(name, val) {
	try {
		val = (typeof val === 'undefined' || val === '~') ?'' :val;
		var el = document.getElementById(name);
		$(el).val(val.toString().trim());
		$(el).change();
	} catch (e) {
		console.error("error setting " + name + " to " + val, e);
	}
}
function loadPreDefined() {
	var cat = window.croogloocurrentpage.extraParams.subcategory||'';
	if (cat) {
		document.getElementById('distribution_category').value = cat;
		$('#distribution_category').change();
		issaved=true;
  	}
//   let includeInDailyTimeReportCheck = document.getElementById('includeInDailyTimeReportCheck');
  	let inviteUserCheckbox = document.getElementById('distribution_invite');
	var personId = window.croogloocurrentpage.extraParams.personId||'';
	person.isNewContact = personId=='';
	if (personId) {
		person.id = personId;
    	crewMember = window.croogloocurrentpage.extraParams.personEntity || {};
		person.category = crewMember.subcategory

		$('#date-range-start').val(crewMember.startDate)
		$('#date-range-end').val(crewMember.endDate)
		
		if(typeof crewMember.castNumber == 'number') {
			crewMember.castNumber = crewMember.castNumber.toString();
		}

		setVal("castNumberField", crewMember.castNumber);
		setVal("distribution_job_title", crewMember.jobTitle);
		setVal("distribution_first_name", crewMember.firstName);
		setVal("distribution_last_name", crewMember.lastName);
		setVal("distribution_mobile", crewMember.mobile);
		setVal("distribution_office_phone", crewMember.officePhone);
		setVal("distribution_home_phone", crewMember.homePhone);
		setVal("distribution_email", crewMember.email);
		setVal("distribution_second_email", crewMember.email2);
		setVal("distribution_other_emails", crewMember.email3);
		setVal("distribution_address", crewMember.address1);
		setVal("distribution_city", crewMember.locality);
		setVal("distribution_province", crewMember.state);
		setVal("distribution_zip", crewMember.postalcode);
		setVal("distribution_category", crewMember.subcategory);
		setVal("distribution_credit_name", crewMember.creditName) ;
		setVal("distribution_emergency_contact_name", crewMember.contactName) ;
		setVal("distribution_emergency_contact_number", crewMember.contactNumber) ;
		setVal("distribution_allergies", crewMember.allergies) ;
		setVal("distribution_gift", crewMember.gift) ;

		//COMEHERE! : add a loading thing to the unavailabilities tab?
		apicall('personapi', 'fetchUnavailabilitiesByContact', { personId: personId })
		.then(resp => {
			if(Array.isArray(resp.items)) {
				if(resp.items.length == 0){
					console.info("EMPTY! NO INACTIVITY RANGE!");
				}else{
					for(let item of resp.items){
						person.addUnavailabilities.push({
							start: item.properties.start,
							end: item.properties.end
						})
					}

					mapOutInactivityDivs()
				}
			} else {
				throw new Error('unexpected server response');
			}
		})
		.catch(() => {
			Utility.showErrorCgToast(i18n.t("contact.unavailable.fetch.error"), 5000);
			console.error('failed to retrieve unavailability list for ' + personId);
		});
		//TODO : hide the loading thing for the unavailabilities tab?

		if (crewMember.subcategory == 'cast') {
			setVal("distribution_agent", crewMember.agency);
		} else if (crewMember.subcategory == 'agent') {
			setVal("distribution_agency", crewMember.agency);
		}
		setVal("distributionCombo", crewMember.departmentId);

		apicall('personapi', 'fetchPersonSecurityLists', { personId: personId }).then(resp => {
			if(Array.isArray(resp.items)) {
				if(resp.items.length == 1) { // only one is currently supported
					crewMember.securityGroups = resp.items[0];
					document.getElementById('distribution_invite').checked = true
					let inviteToCrooglooText = document.querySelector('.text-left')
					inviteToCrooglooText.textContent = `${crewMember.securityGroups === 'DH' ? 'DTR' : crewMember.securityGroups} ACCESS`
					inviteToCrooglooText.style.fontWeight = '700'
					
					setVal("securityGroupCombo", crewMember.securityGroups);
					crewMember.inviteToCroogloo = 'Y';
					issaved = true; // REVIEW if we just set issaved here without using a timestamp, changes made before lists are retrieved 
									// will not be accounted for if the user tries to leave the page
				} else {
					initListeners()
					crewMember.inviteToCroogloo = 'N';
					crewMember.securityGroups = '';
				}
			} 
			else {
				initListeners()
				throw new Error('unexpected server response');
			}
		})
		.catch(() => {
			initListeners()
			// log the error and keep the security list options disabled 
			console.error('failed to retrieve security lists for ' + personId);
			crewMember.securityGroups = '';
		});
			
    
		if(crewMember.receivedSafeSender &&
			crewMember.receivedSafeSender.toUpperCase() === 'Y') {
			var el = document.getElementById('distribution_welcome');
			el.checked = true;
			$(el).change();
		}

		var distlistform = document.getElementById('groupsForm');
		var labels = distlistform.querySelectorAll('label');
		var selectedlist = crewMember.distributionLists?crewMember.distributionLists.split(','):[];
		for (var i in selectedlist) selectedlist[i] = selectedlist[i].trim();
		labels.forEach((l)=>{
			if (selectedlist.indexOf(l.innerText)>=0){
				$(l).click();
			}
		});
		person.dstoAdd = []; //reset changes after loading to edit
		person.dstoRemove = [];
		issaved = true;
	} else {

		initListeners()
    	inviteUserCheckbox.disabled = false;
		apicall('distributionapi', 'fetchCachedEmails', {}).then(resp => {
			if(resp && resp.items && resp.items.length) {
				console.debug('cached emails:');
				console.debug(resp.items);
				if(Utility.getPageParam('showCachedContacts') === true) {
					r.deleteExtraParam('showCachedContacts');
					showCachedContacts();
				} else {
					let uniqueID = Utility.uniqueID();
					let hideToast = cgToast(i18n.t("js.contact.pending", {count:resp.items.length})
						+' -&nbsp;<a style="color:lightblue" class="cached-content-link">'
						+i18n.t("js.contact.email.cgview")+'</a>', {
						hiding: 'manual',
						id: uniqueID,
						className: 'body-attached',
						containerID: 'docBody',
						showCloseButton: true
					});
					let toastHidden = false; //to prevent concurrent hiding
					setTimeout(function() {
						if(!toastHidden) {
							toastHidden = true;
							hideToast();
						}
					}, 8000);
					document.querySelector('#'+uniqueID+' > a').onclick = function() {
						if(!toastHidden) {
							toastHidden = true;
							hideToast();
						}
						showCachedContacts();
					}
				}
				function showCachedContacts() {										
					swal({
						title: i18n.t("js.contacts.pending.title"),
						html: pendingContactListHtml(),
						type: 'info',
						showConfirmButton: true,
						showCancelButton: true,
						confirmButtonColor: '#13C46A',
						confirmButtonText: i18n.t("js.contacts.pending.load"),
						cancelButtonText: i18n.t("button.cancel"),
						showCloseButton: true,
						preConfirm: function() {
							return new Promise(resolve => {
								let pendingContactSelect = document.getElementById('pendingContactSelect');
								if(!pendingContactSelect.value) {
									reject(i18n.t("js.contacts.pending.reject"));
									return;
								}
								setVal('distribution_email', pendingContactSelect.value);
								resolve();
							});
						}
					}).catch(swal.noop);
				}
				function pendingContactListHtml() {
					let listHtml = '<select id="pendingContactSelect">';
					resp.items.sort().forEach(email => {
						listHtml += '<option value="' + email + '">' + email + '</option>';
					});
					listHtml += '</select>';
					return listHtml;
				}
			}
		});
	}

	if ($('#date-range-start').val() !== '' && $('#date-range-end').val() !== '') {
		$('#start-date-display').val($('#date-range-start').val()) ;
		$('#end-date-display').val($('#date-range-end').val()) ;
	}
}

function adaptJobTitleLabel(subcategory) {
	try {
		let jobTitleLabelText;
		switch(subcategory) {
			case 'cast': 
				jobTitleLabelText = 'cast';
				break;
			case 'union': 
				jobTitleLabelText = 'union';
				break;
			case 'vendor': 
				jobTitleLabelText = 'vendor';
				break;
			default: 
				jobTitleLabelText = 'default';
		}
		document.querySelector('#distribution_job_title').placeholder = i18n.t("js.contacts.job-title."+jobTitleLabelText);
	} catch(e1) {
		console.error(e1);
	}
}

function distributionListBind() {
	var distList = document.querySelector("#groupsForm");
	var fields = distList.querySelectorAll("input");
	fields.forEach((f) => {
		f.onchange = function(event) {
			if (!('distributionList' in person)){
				person['distributionList'] = [];
			}
			person.dstoAdd=person['dstoAdd']||[];
			person.dstoRemove=person['dstoRemove']||[];
			var c = event.target.checked;
			if (c) {
				if (person.distributionList.indexOf(event.target.value) < 0){
					person.distributionList.push(event.target.value);
					person.dstoAdd.push(event.target.value);
				}
			} else {
				var idx = person.distributionList.indexOf(event.target.value);
				if (idx > -1) {
					person.distributionList.splice(idx, 1);
					person.dstoRemove.push(event.target.value);
				}
			}
			var realtime = document.getElementById('tab1').checked;
			if (realtime) {
				if (person.dstoAdd.length>0) {
					var tmp = new Object();
					tmp.personId = person.id;
					tmp.distributionListIds = person.dstoAdd.join(',');
					apicall('distributionapi','addDistributionListMembersByContact',tmp).then(function(resp){
						issaved=true;
						cgToast(i18n.t("js.utils.update"));
						person.dstoAdd=[];
					})
				}
				if (person.dstoRemove.length>0) {
					var tmp = new Object();
					tmp.personId = person.id;
					tmp.distributionListIds = person.dstoRemove.join(',');
					apicall('distributionapi','removeDistributionListMemberByContact',tmp).then(function(resp){
						issaved=true;
						cgToast(i18n.t("js.utils.update"));
						person.dstoRemove=[];
					})
				}
			} else {
				issaved=false;
			}
		}
	});
}
function loadSecurityGroups(done) {
	new Promise((s,r)=>{
		apicall('securityadminapi', 'fetchSecurityLists', {})
		.catch(function(e){
			console.error(e);
		})
		.then(function(resp){
			var v=[{key:{name:""},properties:{securityListName:i18n.t("js.crew.reject.seclist")}}];
			if(resp && resp.items){
				securityGroups = resp.items
				s();
			} else {
				r();
			}
		});
	}).then(done);
}

function loadDistrGroups() {
	apicall('distributionapi', 'fetchDistributionLists', {}).then(function(resp){
		if(resp && resp.items){
			var el = document.querySelector("#ulDistributionGroups");
			resp.items.sort(distroListCompare).map((item)=>{
				var input = document.createElement("input");
				input.type="checkbox";
				input.name="groups";
				input.id="group_"+item.key.name;
				input.value=item.key.name;
				var label = document.createElement("label");
				label.htmlFor="group_"+item.key.name;
				label.appendChild(document.createTextNode(item.properties.distributionListName));
				var div = document.createElement("div");
				div.className = "checkborder";
				label.appendChild(div);
				var li = document.createElement("li");
				li.appendChild(input);
				li.appendChild(label);
				el.appendChild(li);
			});
			setTimeout(() => {
				document.getElementsByClassName('dist-group-nav')[0].scrollTop = 0;
			}, 500)
			
			distributionListBind();
		}
		loader.check('distgroups');
  });
  
  /**
   * Sorts distribution list entities using their distributionListName property.
   * If the two lists have the same name, except for the first occurrence of a number
   * at the same index, they will be sorted numerically according to that number.
   * For instance, "Crew List - 9" will get sorted before "Crew List - 11".
   * 
   * @param {CrooglooEntity} e1 A distribution list CrooglooEntity object.
   * @param {CrooglooEntity} e2 A distribution list CrooglooEntity object.
   */
  function distroListCompare(e1, e2) {
    try {
      let list1 = e1.properties.distributionListName;
      let list2 = e2.properties.distributionListName;
      let numberRegex = /\d+/;
      let list1NbMatch = list1.match(numberRegex);
      let list2NbMatch = list2.match(numberRegex);
      if(list1NbMatch != null && list2NbMatch != null 
          && list1.indexOf(list1NbMatch[0]) === list2.indexOf(list2NbMatch[0])
          && list1.replace(numberRegex, '') === list2.replace(numberRegex, '')) {
        try {
          return parseInt(list1NbMatch[0]) - parseInt(list2NbMatch[0]);
        } catch(e1) {
          console.error(e1);
        }
      }
      return list1.localeCompare(list2);
    } catch(e) {
      console.error(e);
      return 0;
    }
  }
}

function loadDepartments(done) {
	apicall('personapi', 'fetchDepartments', {}).then(function (r) {
    if(r && r.items){
      var v = [{key:{name:""},properties:{title:i18n.t("js.contact.department.default"),displayOrder:"-1"}}];
      r.items = v.concat(r.items);
      var el = document.querySelector("#distributionCombo");
      r.items.forEach((dep)=>{
        if (dep){
          var o = document.createElement('option');
          o.value = dep.key.name;
          o.id = dep.key.name+'_optid';
          o.setAttribute('displayOrder', dep.properties.displayOrder);
          let deptTitle = dep.properties.title.trim().toUpperCase();
          o.setAttribute('deptTitle', deptTitle);
          o.textContent = deptTitle;
          el.appendChild(o);
        }
      });
      document.getElementById('addDeptBtn').onclick = createNewDept;
    }
    done();
  });
}

export default function createNewDept() {

	swal({
		title: i18n.t("js.contact.new.department.title"),
		confirmButtonText: i18n.t("button.save"),
		confirmButtonColor: '#13C46A',
		showCancelButton: true,
		cancelButtonText: i18n.t("button.cancel"),
		type: 'question',
		input: 'text',
		allowOutsideClick: () => !swal.isLoading(),
		showLoaderOnConfirm: true,
		showCloseButton: true,
		preConfirm: function(text) {

			return new Promise(function(resolve, reject) {

				let invalidChars = findUnsupportedChars(text);

				text = text.trim();
				if(!text) {
					reject(i18n.t("js.contact.new.department.reject.name"));
				} else if(invalidChars.length > 0) {
					reject(i18n.t("js.contact.new.department.reject.character", {count:invalidChars.length, characters:invalidChars.join(', ')}));
				} else if(document.querySelector('#distributionCombo option[value="'+ text.trim().toUpperCase() + '"]') !== null) {
					reject(i18n.t("js.contact.new.department.reject.duplicate"));
				} else {
					let newDeptDisplayOrder = null;
					let $existingDeptOpt = $('#distributionCombo option');
					$existingDeptOpt.each((idx, opt) => {
						if(opt.textContent.localeCompare(text) > 0 && opt.getAttribute('displayOrder') != '-1') {
							newDeptDisplayOrder = (parseInt(opt.getAttribute('displayOrder'))-1)+'';
							return false;
						}
					});

					if($existingDeptOpt.length > 1 && newDeptDisplayOrder === null) {
						newDeptDisplayOrder = parseInt($existingDeptOpt[$existingDeptOpt.length-1].getAttribute('displayOrder'))-1;
						newDeptDisplayOrder = (newDeptDisplayOrder >= 0 ?newDeptDisplayOrder :0)+'';
					} else if(parseInt(newDeptDisplayOrder) !== NaN && parseInt(newDeptDisplayOrder) < 0 || newDeptDisplayOrder === null) {
						newDeptDisplayOrder = '0';
					}

					apicall('productionapi','createDepartment',{
						deptName: text,
						displayOrder: newDeptDisplayOrder
					}).then(resp => {
						if(resp && resp.responseCode && resp.responseCode === '0') {
							resolve(resp.entity);
						} else if(resp && resp.responseCode && resp.responseCode === '-1') {
							reject(i18n.t("js.contact.new.department.reject.duplicate"));
						} else {
							reject(i18n.t("js.utils.connection.error"));
						}
					});

				}
			});
		}
	}).then(function(deptEntity) {

		let select = document.getElementById('distributionCombo');

		let option = document.createElement('option');
		option.value = deptEntity.key.name;
		option.id = deptEntity.key.name + '_optid';
		option.setAttribute('displayOrder', deptEntity.properties.displayOrder);
		let deptTitle = deptEntity.properties.title.trim().toUpperCase();
		option.setAttribute('deptTitle', deptTitle);
		option.textContent = deptTitle;

		let insertBeforeElem = null;
		$('#distributionCombo option').each((idx, opt) => {
			if(opt.textContent.localeCompare(option.textContent) > 0 && opt.getAttribute('displayOrder') != '-1') {
				insertBeforeElem = opt;
				return false;
			}
		});
		if(insertBeforeElem !== null) {
			select.insertBefore(option, insertBeforeElem);
		} else {
			select.appendChild(option);
		}

		setVal('distributionCombo', option.value)
	}).catch(swal.noop);

	function findUnsupportedChars(docName){

		let invalidChars = ['<','>',':','\\','"','/','|','?','!','%','~',',','*','#','$','.'];

		let invalidCharsFound = new Array();

		for(let i = 0, car; car = docName.charAt(i++);) {
			if(invalidChars.includes(car)) {
				invalidCharsFound.push(car);
			}
		}

		return invalidCharsFound;
	}
}

// New Agent and New Cast both get created here
function createNewRelationshipTarget(targetSubcategory) {
	swal({
		title: i18n.t("js.contact.new."+targetSubcategory),
		confirmButtonText: i18n.t("button.save"),
		confirmButtonColor: '#13C46A',
		showCancelButton: true,
		cancelButtonText: i18n.t("button.cancel"),
		type: 'question',
		html: getNewRelationshipTargetHTML(),
		allowOutsideClick: () => !swal.isLoading(),
		showLoaderOnConfirm: true,
		showCloseButton: true,
		onOpen: function() {
			$('#new_relationship_firstName').css('border-color', '#ddd');
			$('#new_relationship_lastName').css('border-color', '#ddd');
			$('#new_relationship_email').css('border-color', '#ddd');
			$('#new_relationship_jobTitle').css('border-color', '#ddd');
			$('#new_relationship_mobile').css('border-color', '#ddd');
		},
		preConfirm: function(text) {
			let displayorder = $("option[value=DEPARTMENT_" + targetSubcategory.toUpperCase() +"]").attr('displayorder');
			let personProperties = {
				subcategory: targetSubcategory.toLowerCase(),
				isNewContact: true,
				inviteToCroogloo: "N",
				welcomeToCroogloo: "N",
				receivedSafeSender: "N",
				includeInDTR: true,
				email2: '~',
				email3: '~',
				mobile: '~',
				home: '~',
				office: '~',
				address1: '~',
				locality: '~',
				state: '~',
				postalcode: '~',
				startDate: "~",
				endDate: "~",
				castNumber: null, // TODO should offer cast number field on swal for new cast members, or relationship won't be visible on Agent page 
				departmentId: 'DEPARTMENT_' + targetSubcategory.toUpperCase(),
				departmentName: targetSubcategory.toUpperCase(),
				deptDisplayOrder: displayorder? parseInt(displayorder) : 1
			};
			return new Promise((resolve, reject) => {
				$('#new_relationship_firstName').css('border-color', '#ddd');
				$('#new_relationship_lastName').css('border-color', '#ddd');
				$('#new_relationship_email').css('border-color', '#ddd');
				$('#new_relationship_jobTitle').css('border-color', '#ddd');
				$('#new_relationship_mobile').css('border-color', '#ddd');
				if ($('#new_relationship_firstName').val().trim() === '') {
					$('#new_relationship_firstName').css('border-color', 'red');
					$('#new_relationship_firstName').focus();
					reject(i18n.t("js.contact.new.reject.empty", {field:"$t(js.contact.new.field.firstname)"}));
					return;
				}
				if ($('#new_relationship_lastName').val().trim() === '') {
					$('#new_relationship_lastName').css('border-color', 'red');
					$('#new_relationship_lastName').focus();
					reject(i18n.t("js.contact.new.reject.empty", {field:"$t(js.contact.new.field.lastname)"}));
					return;
				}
				if ($('#new_relationship_jobTitle').val().trim() === '') {
					$('#new_relationship_jobTitle').css('border-color', 'red');
					$('#new_relationship_jobTitle').focus();
					reject(i18n.t("js.contact.new.reject.empty", {field:"$t(js.contact.new.field.title)"}));
					return;
				}
				if ($('#new_relationship_email').val().trim() === '') {
					$('#new_relationship_email').css('border-color', 'red');
					$('#new_relationship_email').focus();
					reject(i18n.t("js.contact.new.reject.empty", {field:"$t(js.contact.new.field.email)"}));
					return;
				}
				if (!Utility.isValidName($('#new_relationship_firstName').val().trim())) {
					$('#new_relationship_firstName').css('border-color', 'red');
					$('#new_relationship_firstName').focus();
					reject(i18n.t("js.contact.new.reject.invalid", {field:"$t(js.contact.new.field.firstname)"}));
					return;
				}
				if (!Utility.isValidName($('#new_relationship_lastName').val().trim())) {
					$('#new_relationship_lastName').css('border-color', 'red');
					$('#new_relationship_lastName').focus();
					reject(i18n.t("js.contact.new.reject.invalid", {field:"$t(js.contact.new.field.lastname)"}));
					return;
				}
				if ($('#new_relationship_email').val().trim() !== '' &&
					!Utility.isValidEmailAddress($('#new_relationship_email').val().trim())) {
					$('#new_relationship_email').css('border-color', 'red');
					$('#new_relationship_email').focus();
					reject(i18n.t("js.contact.new.reject.invalid", {field:"$t(js.contact.new.field.email)"}));
					return;
				}
				personProperties.securitygroup = '';
				personProperties.firstName = $('#new_relationship_firstName').val();
				personProperties.lastName = $('#new_relationship_lastName').val();
				personProperties.email = $('#new_relationship_email').val();
				personProperties.jobTitle = $('#new_relationship_jobTitle').val();
				personProperties.mobile = $('#new_relationship_mobile').val();
				personProperties.id = ('PERSON_' + personProperties.firstName + '_'
					+ personProperties.lastName + '_'
				 	+ personProperties.jobTitle).toUpperCase();
				for (let key in personProperties) {
					if (typeof personProperties[key] === 'string' && personProperties[key].trim() === '') {
						personProperties[key] = '~';
					}
				}
				console.debug(personProperties);
				saveSystemActivity({
					action: 'submit',
					params: findSystemActivityParams(personProperties),
					message: 'User submitted a contact information.'
				});
				Validator.validateEditPerson(personProperties);
				apicall('personapi', 'editPerson', {}, personProperties).then(function (r) {
					if(r && r.responseMessage && r.responseMessage === 'success') {
						console.debug(r);
						resolve(r.entity);
					} else if(r && r.responseMessage && typeof r.responseMessage == 'string') {
						reject(r.responseMessage);
					}
				}, (err)=>{
						console.error(err);
						Utility.showErrorCgToast();
						reject();
					});
				});
		}
	}).then(function(personEntity) {
		console.debug(personEntity);
		let select = document.getElementById('relationshipSelect');
		let option = document.createElement('option');
		option.value = personEntity.key.name;
		option.textContent = personEntity.properties.firstName + ' ' + personEntity.properties.lastName
		+ ' (' + personEntity.properties.email + ')';
		option.setAttribute('name', Utility.getValidId(personEntity.key.name));
		option.setAttribute('selected', 'selected');
		select.appendChild(option);
    $('#relationshipSelect').trigger('chosen:updated');
    issaved = false; // must do it manually because the onchange event will not be triggered
		relationshipSelected.add(personEntity.key.name);
	}).catch(swal.noop);

	function getNewRelationshipTargetHTML() {
		return '<div class="grid-x">' +
	    '<div class="medium-3 cell">' +
	    '<label for="new_relationship_firstName" class="text-left middle">'+i18n.t("contact.firstname")+'</label>' +
	    '</div>' +
	    '<div class="medium-9 cell">' +
	    '<input type="text" class="mandatory" name="firstName" id="new_relationship_firstName" placeholder="'+i18n.t("js.utils.type-here")+'">' +
	    '</div>' +
	    '<div class="medium-3 cell">' +
	    '<label for="new_relationship_lastName" class="text-left middle">'+i18n.t("contact.lastname")+'</label>' +
	    '</div>' +
	    '<div class="medium-9 cell">' +
	    '<input type="text" class="mandatory" name="lastName" id="new_relationship_lastName" placeholder="'+i18n.t("js.utils.type-here")+'">' +
	    '</div>' +
	    '<div class="medium-3 cell">' +
	    '<label for="new_relationship_jobTitle" class="text-left middle">'+i18n.t("contact.job")+'</label>' +
	    '</div>' +
	    '<div class="medium-9 cell">' +
	    '<input type="text" class="mandatory" name="lastName" id="new_relationship_jobTitle" placeholder="'+i18n.t("js.utils.type-here")+'">' +
	    '</div>' +
	    '<div class="medium-3 cell">' +
	    '<label for="new_relationship_email" class="text-left middle">'+i18n.t("contact.email")+'</label>' +
	    '</div>' +
	    '<div class="medium-9 cell">' +
	    '<input type="text" class="mandatory" name="email" id="new_relationship_email" placeholder="'+i18n.t("js.utils.type-here")+'">' +
	    '</div>' +
	    '<div class="medium-3 cell">' +
	    '<label for="new_relationship_mobile" class="text-left middle">'+i18n.t("contact.mobile")+'</label>' +
	    '</div>' +
	    '<div class="medium-9 cell">' +
	    '<input type="text" name="mobile" id="new_relationship_mobile" placeholder="'+i18n.t("js.utils.type-here")+'">' +
	    '</div>' +
	    '</div>';
	}
}
