import i18n from './Translation';
import CrooglooSub from './CrooglooSub.js';
import SubscriptionManager from './SubscriptionManager.js';
import swal from 'sweetalert2';

export function enabledNewProdBtn() {
  let addProdBtn = document.getElementById('addProductionBtn');
  addProdBtn.onclick = async function() {
    if(!croogloo_auth.crooglooauth || !croogloo_auth.crooglooauth.hasOwnProperty('isTrial')) {
      console.debug('not ready for addProductionBtn');
      cgToast(i18n.t("js.sub.mng.not-ready"));
      return;
    } else if(croogloo_auth.crooglooauth.isTrial) {
        swalToast({
          type: 'error',
          title: i18n.t("js.sub.add.trial")
        });
        return;
    }
    await SubscriptionManager.initStore();
    let plan = new CrooglooSub(croogloo_auth.crooglooauth.planId);
    let stripeTokenObj = null;
    let addresses = null;
    let nbOfAvailableProds = croogloo_auth.crooglooauth.nbOfAvailableProds;
    let isPrepaidProdAvailable = nbOfAvailableProds > 0;
    swal({
      title: i18n.t("js.sub.add.title"),
      html: (isPrepaidProdAvailable
          ?('<div style="margin-bottom: 0.5rem"><b>' + plan.getName() + '</b>'
            + (nbOfAvailableProds == global.INFINITE_NB_OF_PRODS ? '':i18n.t("js.sub.add.prods-left", {nbOfAvailableProds}))
            + '</div>')
          :'')
        + i18n.t("js.sub.add.text")
        + '<br/><br/>'
        + '<input id="newProdName" type="text" placeholder="'+i18n.t("js.sub.add.name.holder")+'"/>'
        + (isPrepaidProdAvailable ? '':('<div><select id="subscriptionPlanSelect">'
            +'<option value disabled selected>' + i18n.t("js.sub.mng.select")+'</option>'
            + plan.optionsAsHtml() + '</select></div>'))
        + '<input id="addProductionCheckbox" type="checkbox"/><label for="addProductionCheckbox">' + i18n.t("js.sub.add.trasnfer.label")+'</label>',
      type: 'info',
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("button.submit"),
      showLoaderOnConfirm: true,
      useRejections: true, //important for swal2 v7.1.2
      expectRejections: true,
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      allowOutsideClick: () => !swal.isLoading(),
      showCloseButton: true,
      onOpen: function() {
        try {
          document.getElementById('swal2-content').style.textAlign = 'justify';
        } catch(e) {
          console.error(e);
        }
      },
      preConfirm: function() {
        return new Promise(async function(resolve, reject) {
          let customReject = function(msg) {
            document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'pointer';
            reject(msg);
          }
          let newProdName = document.getElementById('newProdName').value.trim();
          if(!newProdName) {
            customReject(i18n.t("js.sub.add.reject.name"));
            return;
          }

          let newSubPlanId, newSubProduct;
          if(!isPrepaidProdAvailable) {
            let newSubSelect = document.getElementById('subscriptionPlanSelect');
            newSubPlanId = newSubSelect.value;
            if(!newSubPlanId) {
              customReject(i18n.t("js.sub.mng.reject.select"));
              return;
            }
            newSubProduct = document.querySelector('#subscriptionPlanSelect option:checked').getAttribute('product');
          } else {
            newSubPlanId = croogloo_auth.crooglooauth.planId;
            newSubProduct = croogloo_auth.crooglooauth.productionType;
          }

          document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'not-allowed';
          let errMsg = i18n.t("js.sub.mng.server.err", {email: "support@croogloo.com"});
          // TODO Always check that the production name is available BEFORE checkout.
          // TODO Even though it's functional right now, a user prompted to choose
          // TODO another production name could decide to cancel AFTER checkout,
          // TODO which is an undesirable behavior.
          if(stripeTokenObj === null && !isPrepaidProdAvailable) {
             let stripeCheckoutData = await new SubscriptionManager().checkout(newSubPlanId);
             if(stripeCheckoutData) {
               stripeTokenObj = stripeCheckoutData.token;
               addresses = stripeCheckoutData.addresses;
             }
          }

          if(stripeTokenObj === null && !isPrepaidProdAvailable) {
            customReject(i18n.t("js.sub.add.cancelled"));
            return;
          }
          apicall('productionapi', 'createProdForExistingUser', {
            newProdName: newProdName,
            stripeToken: isPrepaidProdAvailable ?'-' :stripeTokenObj.id,
            product: newSubProduct,
            subscriberEmail: isPrepaidProdAvailable ?'-' :stripeTokenObj.email,
            cardId: isPrepaidProdAvailable ?'-' :stripeTokenObj.card.id,
            currency: isPrepaidProdAvailable ?'-' :stripeTokenObj.currency,
            planId: newSubPlanId
          }, addresses, false).then(function(resp) {
            try {
              if(resp.responseCode == '0') {
                resolve();
              } else if(resp.responseCode == '-1' && resp.responseMessage) {
                customReject(resp.responseMessage);
              } else {
                customReject(errMsg);
              }
            } catch(e) {
              console.error(e);
              customReject(errMsg);
            }
          }, err => customReject(errMsg)).catch(err => customReject(errMsg));
        });
      }
    }).then(function() {
      swal({
        title: i18n.t("response.success"),
        text: i18n.t("js.sub.add.success"),
        type: 'success',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK"),
        allowOutsideClick: true,
      }).then(() => croogloo_auth.validateSubscription())
        .catch(() => croogloo_auth.validateSubscription());
    }).catch(swal.noop);
  }
  addProdBtn.style.display = 'block';
}

export function enabledSwitchProdBtn() {
  let switchProdBtn = document.getElementById('switchProductionBtn');
  switchProdBtn.onclick = window.switchCommunity = switchCommunity;

  // we show the switch production btn if user has access to at least one other
  window.hasAccessToOtherCommunities = function() {
    for(let communityID in croogloo_auth.crooglooauth.communitiesAndAccess) {
      if(croogloo_auth.crooglooauth.communitiesAndAccess[communityID] &&
          communityID != croogloo_auth.crooglooauth.defaultCommunity) {
        return true;
      }
    }
    return false;
  }

  if(window.hasAccessToOtherCommunities()) {
    switchProdBtn.style.display = 'block';
  }

  function switchCommunity() {
      let authObj = croogloo_auth.crooglooauth;
    swal({
      title: i18n.t("js.sub.switch.title"),
      html: i18n.t("js.sub.switch.text") + '<br><br>' + communitySelectHTML(),
      type: 'question',
      confirmButtonText: i18n.t("utils.select"),
      showCancelButton: window.forceSwitchProd !== true,
      cancelButtonText: i18n.t("button.cancel"),
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
      showCloseButton: true,      
      preConfirm: function() {
        return new Promise((resolve, reject) => {
          let selectedCommunityId = document.getElementById('communitySwitchSelect').value;
          if(!selectedCommunityId || !selectedCommunityId.trim()) {
            reject(i18n.t("js.sub.switch.reject"));
            return;
          }
          $.ajax({
            url: devEndpoint + "/CommunitySwitchServlet",
            type: "post",
            crossDomain: true,
            data: {
              token: authObj.token,
              tenantId: authObj.defaultCommunity,
              toCommunity: selectedCommunityId
            },
            success: function(resp) {
              try {
                  let partialAuth = resp;
                
                if(partialAuth.responseCode != '0') {
                  reject(i18n.t("js.sub.switch.denied", {community: (authObj.communitiesIdNameMap[selectedCommunityId]||selectedCommunityId)}));
                  return;
                }

                authObj.securityProfile = partialAuth.securityProfile;
                authObj.sms2fa = partialAuth.sms2fa;
                authObj.defaultCommunity = selectedCommunityId;

                secureLocalStorage.set("crooglooauth", JSON.stringify(authObj));
                sessionStorage.clear();
                console.debug('cleared session storage');
                // Full reload required to switch production environment safely
                window.location.reload();
              } catch(e) {
                console.error(e);
                handleSwitchRejections(function() {
                  reject(i18n.t("js.utils.server.error-support"));
                });
              }
            }, error: function(err) {
              console.error(err);
              handleSwitchRejections(function() {
                reject(i18n.t("js.utils.server.error-support"));
              });
            }
          });
        });
      }
    }).catch(handleSwitchRejections);
    function handleSwitchRejections(onReject) {
      if(window.forceSwitchProd) {
        window.location.href = "login.html" + location.hash.split("/")[0];
      } else if(typeof onReject == 'function') {
        onReject();
      }
    }
    function communitySelectHTML() {
      let communitySelect = document.createElement('select');
      communitySelect.id = 'communitySwitchSelect';
      let atLeastOneOtherProdAccess = false;
      let sortedCommunities = Object.keys(authObj.communitiesAndAccess).sort();
      for(let communityID of sortedCommunities) {
        try {
          if(authObj.communitiesAndAccess[communityID] &&
              communityID != authObj.defaultCommunity) {
            let communityOpt = document.createElement('option');
            communityOpt.textContent = authObj.communitiesIdNameMap[communityID] || communityID;
            communityOpt.value = communityID;
            communitySelect.appendChild(communityOpt);
            atLeastOneOtherProdAccess = true;
          }
        } catch(e) {
          console.error(e);
        }
      }
      if(!atLeastOneOtherProdAccess) {
        throw new Error(i18n.t("js.sub.switch.none"));
      }
      return communitySelect.outerHTML;
    }
  }
}
