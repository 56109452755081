import swal from 'sweetalert2';
import i18n from './components/Translation';

export function init() {
  loader.add(ui)
        .add(initGlobals, 'globals')
        .add(getMetaData, 'grid-loaded')
        .add(initListeners)
        .execute();
}

function ui() {
  $('#main').foundation();
}

var accessChanges;

function initGlobals() {
  accessChanges = {};
  loader.check('globals');
}

function initListeners() {
  document.getElementById('saveBtn').onclick = saveChangesToDatastore;
  $('.disabled-page').off('click');
  $('.unknown-page').off('click');
  $('.disabled-page').on('click', function() {
    if(this.classList.contains('unknown-page')) {
      console.debug('ignoring disabled-page listener for unknown-page');
      return;
    }
    swal({
      title: i18n.t("js.perms.disabled.title"),
      html: i18n.t("js.perms.disabled.text"),
      type: 'info',
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("OK"),
      allowOutsideClick: true,
      showCloseButton: true,
      onOpen: function() {
        swal.getContent().classList.add('justified-text');
      }
    });
  });
  $('.unknown-page').on('click', function() {
    swal({
      title: i18n.t("js.perms.unaccessible.title"),
      html: i18n.t("js.perms.unaccessible.text"),
      type: 'info',
      showCancelButton: true,
      confirmButtonText: i18n.t("js.perms.button.uh-oh"),
      cancelButtonText: i18n.t("button.gotit"),
      cancelButtonColor: '#13C46A',
      showCloseButton: true,
      allowOutsideClick: true,
      onOpen: function() {
        swal.getContent().classList.add('justified-text');
      }
    }).then(function() {
      swal({
        title: i18n.t("js.perms.unaccessible.title"),
        html: i18n.t("js.perms.unaccessible.text-more")+" \u{1f631}",
        type: 'info',
        animation: false,
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("button.gotit"),
        showCloseButton: true,
        allowOutsideClick: true,
        onClose: () => {
          $('.swal2-popup').removeClass('swal2-noanimation');
          $('.swal2-popup').addClass('swal2-hide');
        },
        onOpen: function() {
          swal.getContent().classList.add('justified-text');
        }
      }).catch(swal.noop);
    }).catch(swal.noop);
  });
}

function saveChangesToDatastore() {
  if (croogloocurrentpage.getAccessLevel() < 2) {
    cgToast(i18n.t("js.unauthorized"));
    return;
  }
  let accessChangesList = Object.values(accessChanges);
  console.log(accessChangesList);
  if(!accessChangesList.length) {
    cgToast(i18n.t("js.perms.nothing"));
    return;
  }
  swal({
    title: i18n.t("js.perms.confirm.title"),
    text: i18n.t("js.perms.confirm.text", {count:accessChangesList.length}),
    type: 'warning',
    showCloseButton: true,
    confirmButtonText: i18n.t("button.confirm"),
    confirmButtonColor: '#13C46A',
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    allowOutsideClick: () => !swal.isLoading(),
    showLoaderOnConfirm: true,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,  
    preConfirm: function() {
      return new Promise((resolve, reject) => {
        document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'not-allowed';
        
        saveSystemActivity({
          action: 'submit',
          params: JSON.stringify(accessChangesList),
          message: 'User submitted Permission Changes.'
        });

        apicall('securityadminapi','saveAccessPermissions',{},{value: JSON.stringify(accessChangesList)}).then(resp => {
          if(resp && resp.responseCode === '0') {
            document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'pointer';
            resolve();
          } else {
            reject('server error');
          }
        });
      });
    }
  }).then(function() {
    // Confirm success and soft-reload to make sure menu and security config are updated
    swal({
      title: i18n.t("dtr.saving.saved"),
      type: 'success',
      showCloseButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonText: i18n.t("close"),
      confirmButtonColor: '#13C46A',
      allowOutsideClick: true
    }).then(() => r.reload(), () => r.reload()).catch(() => r.reload());
  }).catch(swal.noop);
}

function getMetaData() {
	var tmp = new Object();
	tmp.entityType = "security_list_access";
	showSpinner();
  apicall('securityadminapi','fetchAccessPermissions',{}).then(resp => {
    // console.warn(resp);
    if(!resp.items) { return; }
    resp.items.sort((o1, o2) => {
      try {
        return o1.securityMenuName.split('_')[0] == o2.securityMenuName.split('_')[0]
          ?o1.securityMenuTitle.localeCompare(o2.securityMenuTitle)
            :(o1.securityMenuName.startsWith('mobile') ?1 :-1);
      } catch(e) {
        console.error(e);
      }
    });
    let groupTitleRow = document.createElement('tr');
    groupTitleRow.appendChild(document.createElement('th'));
    let groupIndexes = [], groupIdx = 0;
    resp.items[0].orderedGroups.forEach(groupId => {
      let th = document.createElement('th');
      th.textContent = groupId;
      if(th.textContent === "DH") {
        th.textContent = "DTR"
      }
      th.className = 'idHeader';
      th.style.height = '65px';
      th.style.borderBottom = '1px solid #E0E0E0';
      groupIndexes[groupIdx++] = groupId;
      groupTitleRow.appendChild(th);
    });
    document.getElementById("permissionTable").appendChild(groupTitleRow);

    let legend = ['NO', 'RO', 'RW'];
    const items = resp.items.filter(item => !item.securityMenuTitle.match('Mobile'));
    for(let i = 0, menu; menu = items[i]; i++) {
      let tr = document.createElement('tr');
      tr.style.backgroundColor = i%2==0?'#F0F0F0':'#F8F8F8';
      let menuTitle = document.createElement('th');
      menuTitle.style.padding = '10px 5px';
      menuTitle.textContent = menu.securityMenuTitle || menu.securityMenuName;
      menuTitle.setAttribute('width', '160px');
      menuTitle.appendChild(document.createElement('br'));
      if(menu.affectedPages && menu.affectedPages.length) {
        let affectedPages = document.createElement('span');
        affectedPages.className = 'affected-pages';
        affectedPages.innerHTML = formatAffectedPagesTitles(menu.affectedPages);
        menuTitle.appendChild(affectedPages);
      }
      tr.appendChild(menuTitle);
      for(let j = 0; j < groupIdx; j++) {
        let td = document.createElement('td');
        td.appendChild(createSelect(menu.securityMenuName, groupIndexes[j],
          menu.groupsAccessValue[groupIndexes[j]], i, j));
        tr.appendChild(td);
      }
      document.getElementById("permissionTable").appendChild(tr);
    }
    function formatAffectedPagesTitles(affectedPages) {
      let formattedTitles = '';
      affectedPages.forEach(page => {
        if(typeof page.pageTitle !== 'string' || !page.pageTitle.trim()) {
          return;
        }
        if(formattedTitles !== '') {
          formattedTitles += ',&nbsp;';
        }
        let pageSpan = document.createElement('span');
        pageSpan.textContent = page.pageTitle;
        if(page.isEnabled === false) { // also checking type for extra precautions
          pageSpan.classList.add('disabled-page');
        }
        if(!Object.keys(r.routes).includes(page.pageName) && page.pageName !== 'dailyTimeReports') {
          pageSpan.classList.add('unknown-page');
        }
        formattedTitles += pageSpan.outerHTML;
      });
      return formattedTitles;
    }
    function createSelect(securityMenuName, groupId, selectedVal, row, column) {
      let select = document.createElement('select');
      select.className = 'selectTableData';
      select.style.backgroundColor = 'Transparent';
      for(let k = 0; k < legend.length; k++) {
        let opt = document.createElement('option');
        opt.value = k;
        opt.textContent = legend[k];
        select.appendChild(opt);
      }
      select.value = selectedVal;
      select.disabled = groupId == 'ADMIN' && securityMenuName == 'menu_security';
      select.onchange = function(e) {
        let gridCellKey = row + '_' + column;
        if(accessChanges.hasOwnProperty(gridCellKey) &&
          accessChanges[gridCellKey].initialValue == parseInt(select.value)) {
          delete accessChanges[gridCellKey];
          this.style.backgroundColor = 'Transparent';
        } else {
          accessChanges[gridCellKey] = {
            groupId: groupId,
            menuName: securityMenuName,
            initialValue: selectedVal,
            newValue: parseInt(this.value)
          };
          this.style.backgroundColor = 'lightcoral';
        }
      }
      return select;
    }
    loader.check('grid-loaded');
  });
}
