export default class ParamValidator {
  static validateEditPerson(params) {
    let mandatoryStringParams = {
      list: [
        'id',
        'jobTitle',
        'departmentId',
        'departmentName',
        'firstName',
        'lastName',
        'subcategory',
        'email',
        'email2',
        'email3',
        'home',
        'mobile',
        'office',
        'address1',
        'locality',
        'state',
        'postalcode',
        'agency',
        'inviteToCroogloo',
        'receivedSafeSender',
        'securitygroup',
        'startDate',
        'endDate',
        'castNumber'
      ],
      defaultValue: '-'
    }, mandatoryIntParams = {
      list: [
        'deptDisplayOrder'
      ],
      defaultValue: 1
    }, mandatoryBooleanParams = {
      list: [
        'isNewContact'
      ],
      defaultValue: false
    };
    let paramKeys = Object.keys(params);
    for(let paramType of [mandatoryStringParams, mandatoryIntParams,
      mandatoryBooleanParams]) {
      for(let param of paramType.list) {
        if(!paramKeys.includes(param)) {
          params[param] = paramType.defaultValue;
        }
      }
    }
  }
}
