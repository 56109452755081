export default class Page {
  /**
   * This class is responsible for loading all of the pages in the application.
   * index.html is the only file that stays consistent across the application and other pages 
   * are loaded in a single page application style.
   * 
   * The URL is set below as this.url and the load function is responsible for loading the view into the page.
   * 
   * This constructor for the page is responsible for loading the correct stylesheet and html 
   * based on the url of the page to be loaded. It's called from app.js and each Page is created on 
   * the user going to a specific route such as mycroogloo.com/#default
   */
  constructor(pageInfo, init, params, script, stylesheet) {
    let url = typeof pageInfo === 'object' ?pageInfo.url :pageInfo;
    var spliturl = url.split("/");
    url = spliturl.splice(0,1)[0];
    this.script = script;
    this.stylesheet = stylesheet || 'styles/' + url + '.css';
    this.pageName = typeof pageInfo === 'object' ?pageInfo.page :url;
    this.url = 'views/' + url + '.html';
    this.orderedParams = spliturl;
    this.params = params || {};
    this.initFunction = init;
  }

  adaptMenuDisplay() {
    if(croogloo_auth.hasAccess(this.securityPageName)) {
      $('#offCanvasLeftSidemenu li a[href="#' + this.pageName 
        + '"]:not(.restricted-item), #user-menu-options li[purpose=nav-menu] a[href="#' 
        + this.pageName + '"]')
      .each((idx, elem) => {
        $(elem).closest('li').css('display','list-item');
        if(!elem.getAttribute('purpose')) {
          $(elem).closest('li.parent-menu').css('display','list-item');
        }
      });
    }
  }

  getAccessLevel() {
    return croogloo_auth.getAccessLevel(this.securityPageName);
  }

  /**
   * VERY IMPORTANT: This method fetches the entire html from this.url then loads it into the browser page 
   * to make it into a single page application.
   */
  load(extraParams) {
    this.extraParams = extraParams || {};
    return fetch(this.url)
      .then(res => {
        return res.text();
      })
      .then(res => {
        this.html = res;
        return res;
      });
  }

  unload(currentPage, nextPage) {
    // this method should be override by page that whats to now when page is unloaded.
    // should return a promise with resolve (true) or reject (false)
    return Promise.resolve();
  }

  show(el) {
    window.loader = new TaskList()
      .oncomplete(function () {
        window.isFirstPageLoad = false;
        adaptNavMenu();
        hideSpinner();
      });

    $(el).append(this.html);
    this.container = el;

    if(this.script) {
      var script = document.createElement('script');
      script.async = true;
      script.src = this.script;
      el.appendChild(script);
      console.log('appending page custom script');
    }

    this.appendStylesheet();
    window.croogloocurrentpage = this;
    croogloo_auth.updateSecurityProfile();
    croogloo_auth.validateSubscription();
    let that = this;
    document.title = "Croogloo - " + (this.pageName || "Connecting Crew");

    if(this.initFunction){
      new Promise((s,r)=>{
        this.initFunction();
        s();
      }).then(function(){
        console.log(that.pageName + " initialized!");
      });
    }
  }

  appendStylesheet() {

    let href = this.stylesheet;

    let linkId = this.pageName + "Stylesheet";

    //testing css file existence
    $.get(href)
      .done(() => {
        if(!document.getElementById(linkId)) {
          createAndAppendLink();
        } else {
          console.log('link already loaded'); //shouldn't happen
        }
      }).fail(() => {
        console.warn('no css file found for ' + this.pageName);
      });

    let classRef = this;

    //creating the stylesheet link
    function createAndAppendLink() {
      classRef.stylesheet = href;
      let link = document.createElement('link');
      link.id = linkId;
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = href;
      link.media = 'all';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }
}
