import i18n from '../components/Translation';

export default class DataFetcher {
  static fetchDistributionLists() {
    return new Promise(function(resolve, reject) {
      apicall('distributionapi', 'fetchDistributionLists', {}).then(resp => {
        if(resp && resp.items) {
          console.debug(resp.items);
          resolve(resp.items);
        } else {
          throw resp ;
        }
      }, err => {
        console.error(err);
        throw err;
      }).catch(err => {
        alertAndReject(err);
      });
      function alertAndReject(err) {
        if(err !== 'request context changed') {
          ["js.data.err.lists", "js.data.err.features"].forEach(errMsg => {
            cgToast(i18n.t(errMsg), {
              className: 'error-toast',
              hiding: 'manual',
              showCloseButton: true
            });
          });
        }
        reject(err);
      }
    })
  }
}
