import i18n from './components/Translation';

export function init() {
  loader
  .add(showSpinner)
  .add(ui)
  .add(initGlobals)
  .add(loadTemplates, 'templates')
  .execute();
}

function ui() {
  $('#main').foundation();
}

function initGlobals() {
}

$.urlParam = function(name) {
  var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results == null) {
    return null;
  } else {
    return results[1] || 0;
  }
}

function loadTemplates() {
  document.title = "Croogloo - Templates";
  showSpinner();
  var layoutPageField = "pageHeader";

  var tmp = new Object();
  tmp.layoutPageField = layoutPageField;
  apicall('metadataAPI','getCustomPageIDs',tmp).then(function(resp) {
    if (resp.items) {
      var webTemplates = new Object();
      for (var i = 0; i < resp.items.length; i++) {
        var pageId = resp.items[i].split(":")[0];
        var entityId = resp.items[i].split(":")[2];
        var linkURL = new Object();
        linkURL.customPageId = pageId;
        linkURL.serverReload = '1';
        if (entityId!=undefined && entityId.length>0) {
          linkURL.entityId = entityId;
        }
        webTemplates[pageId] = linkURL;
      }

      addTemplateToTable(IMG_DIRECTORY + 'templates/crewIntake.png', i18n.t("js.templates.crew-intake"), '', '', () => googleFormOptionsPopup("crew_intake"), true);
      addTemplateToTable(IMG_DIRECTORY + 'templates/accidentInvestReport.png', i18n.t("js.templates.accident-rpt"), '', 'Croogloo Accident Report.docx');
      addTemplateToTable(IMG_DIRECTORY + 'templates/callSheet.png', i18n.t("js.templates.call-sheet"), 'Croogloo Call Sheet.xlsm', 'Croogloo Call Sheet.docx');
      addTemplateToTable(IMG_DIRECTORY + 'templates/PEAR.png', i18n.t("js.templates.carbon-calc"), 'Croogloo Carbon Calculator - PEAR.xlsm', '');
      addTemplateToTable(IMG_DIRECTORY + 'templates/chequeRequest.png', i18n.t("js.templates.cheque-req"), '', 'Croogloo Cheque Request.doc');
      addTemplateToTable(IMG_DIRECTORY + 'templates/crewList.png', i18n.t("js.templates.contact-imp"), 'Croogloo Contact Import.xlsx', '');
      addTemplateToTable(IMG_DIRECTORY + 'templates/dpr.png', i18n.t("js.templates.dpr"), '', 'Croogloo DPR.docx');
      addTemplateToTable(IMG_DIRECTORY + 'templates/equipmentOrder.png', i18n.t("js.templates.equip-ordr"), 'Croogloo Equipment Order.xlsx', '');
      addTemplateToTable(IMG_DIRECTORY + 'templates/gasReceipts.png', i18n.t("js.templates.gas-receipts"), 'Croogloo Gas Receipts.xlsx', 'Croogloo Gas Receipts.docx');
      addTemplateToTable(IMG_DIRECTORY + 'templates/greenSeal.png', i18n.t("js.templates.green-seal"), 'GPG_PEACH_v6.xlsb', '');
      addTemplateToTable(IMG_DIRECTORY + 'templates/lossAndDamage.png', i18n.t("js.templates.loss-dmg"), '', 'Croogloo Loss and Damage.docx');
      addTemplateToTable(IMG_DIRECTORY + 'templates/mastercardReceipts.png', i18n.t("js.templates.mastercard-recpt"), 'Croogloo Mastercard Receipts.xlsm', '');
      addTemplateToTable(IMG_DIRECTORY + 'templates/mileageForm.jpeg', i18n.t("js.templates.mileage"), '', 'Croogloo Mileage Form.docx');
      addTemplateToTable(IMG_DIRECTORY + 'templates/pettyCashAdvanceReq.png', i18n.t("js.templates.petty-req"), '', 'Croogloo Petty Cash Advance Request.doc');
      addTemplateToTable(IMG_DIRECTORY + 'templates/pettyCashEnvelope.png', i18n.t("js.templates.petty-env"), 'Croogloo Petty Cash Envelope.xlsm', '');
      addTemplateToTable(IMG_DIRECTORY + 'templates/productionChecklist.png', i18n.t("js.templates.prod-chcklist"), 'PEACH - Checklist & EMA Green Seal.xlsb', '');
      addTemplateToTable(IMG_DIRECTORY + 'templates/PLUM.png', i18n.t("js.templates.lumber"), 'Croogloo PLUM.xlsx', '');
      addTemplateToTable(IMG_DIRECTORY + 'templates/travelMemo.jpg', i18n.t("js.templates.travel-memo"), '', 'Croogloo Travel Memo.doc');
      addTemplateToTable(IMG_DIRECTORY + 'templates/visaReceipts.png', i18n.t("js.templates.visa-recpt"), 'Croogloo VISA Receipts.xlsm', '');

      document.getElementById("requestMoreBtn").addEventListener("click", openRequestPopup);
    }
    loader.check('templates');
  }).catch(err => {
    console.error(err);
    swalToast({
      type: 'error',
      title: i18n.t("js.templates.error")
    });
    loader.check('templates');
  });

}

function addTemplateToTable(srcImage, templateName, linkToExcel, linkToPdf, alternateFunction, boldTitle = false) {
  var div = document.createElement('div');
  div.className = 'cell small-3 template-cell';
  div.onclick = function () {
    if (!alternateFunction) {
      if (isOnlyOneValidLink(linkToExcel, linkToPdf)) {
        // the invalid/empty link will be ignored in triggerIconLink
        triggerIconLink(linkToExcel, 'excel');
        triggerIconLink(linkToPdf, 'word');
      } else {
        addIcons(linkToExcel, linkToPdf);
      }
    } else {
      alternateFunction();
    }
    function isOnlyOneValidLink() {
      let nbOfValidLinks = 0;
      for (let link of arguments) {
        if (typeof link == 'string' && link.trim().length && ++nbOfValidLinks > 1) {
          return false;
        }
      }
      return true;
    }
  }

  var img = document.createElement('img');
  img.src = srcImage;
  img.className = 'templateImage';
  div.append(img);
  var templateTitle = document.createElement('p');
  templateTitle.textContent = templateName;
  templateTitle.title = templateName;
  templateTitle.style.marginTop = '5px';
  templateTitle.style.fontSize = '16px';
  if (boldTitle) {
    templateTitle.style.fontWeight = 'bold';
  }
  div.append(templateTitle);
  document.getElementById('templateGrid').appendChild(div);
}

  function googleFormOptionsPopup(formType) {
    showSpinner();
    apicall('documentsapi', 'fetchReadOnlyGoogleFormUrl', { formType }).then(response => {
      if (response) {
        hideSpinner();
        if (response.responseCode === '0') {
          addIcons(response.responseMessage, response.responseMessage);
        } else if (response.responseCode === '-1') {
          console.error(response.responseMessage);
        }
      }
    })
  }

  function openRequestPopup() {
    swal({
      title: i18n.t("js.templates.req.title"),
      text: i18n.t("js.templates.req.text"),
      input: 'textarea',
      inputPlaceholder: i18n.t("js.templates.req.plchldr"),
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("button.confirm"),
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      allowOutsideClick: true
    }).then(function(text) {
      console.log(text);
      if(!text) {
        swal({
          title: i18n.t("response.error"),
          html: i18n.t("js.templates.req.error"),
          type: 'error',
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("OK")
        });
      } else {
        var tmp = new Object();
        tmp.requestMessageBody = text;
        showSpinner();

        saveSystemActivity({
          action: 'request',
          params: findSystemActivityParams(tmp),
          message: 'User tried to send a template request.'
        });

        apicall('distributionapi','sendTemplateRequest',tmp).then(function(resp) {
          hideSpinner();
          if(resp && resp.responseCode === '1') {
            swal({
              title: i18n.t("js.templates.req.thanks"),
              text: i18n.t("js.templates.req.success"),
              type: 'success',
              confirmButtonText: i18n.t("OK"),
              confirmButtonColor: '#13C46A',
              allowOutsideClick: true
            });
            saveSystemActivity({
              action: 'request',
              params: findSystemActivityParams(tmp),
              message: 'User SUCCEEDED to send a template request.'
            });
          } else {
            swal({
              title: i18n.t("js.templates.req.err"),
              html: i18n.t("js.templates.req.failure", {text}),
              type: 'error',
              confirmButtonColor: '#13C46A',
              confirmButtonText: i18n.t("OK"),
              allowOutsideClick: false
            });
            saveSystemActivity({
              action: 'request',
              params: findSystemActivityParams(tmp),
              message: 'User FAILED a template request.'
            });

          }
        });
      }
    }).catch(swal.noop);
  }

  /* I kept this function for backward compatibility with existing word/excel templates.
    It tests to see if the 2 links provided are the same, if they are it is a Google form as both icons
    need the link for distribution or viewing. If they're different we know it's an existing word/excel
    download file. This condition determines what pop up the user sees, and what links are provided when
    the user clicks on the icons. If/when all forms become Google Forms, these conditions can be removed.
  */
  function addIcons(link1, link2) {

    const isGoogleForm = link1 === link2;
    let linkText1 = isGoogleForm ? i18n.t('js.templates.form.distribute') : 'excel';
    let linkText2 = isGoogleForm ? i18n.t('js.templates.form.view') : 'word';
    let iconSrc1 = isGoogleForm ? 'email.png' : 'xls.png';
    let iconSrc2 = isGoogleForm ? 'googleFormsIcon.png': 'doc.png';
    let sweetAlertTitle = isGoogleForm ? i18n.t("js.templates.action") : i18n.t("js.templates.format");

    const sweetAlertHtml =
      `<div class="iconsPopupContainer">
        <figure class="iconTextContainer">
          <img src=${IMG_DIRECTORY + iconSrc1} id="icon1" class="iconsPopup"/>
          <figcaption class="iconTextInfo">${linkText1}</figcaption>
        </figure>
        <figure class="iconTextContainer">
          <img src=${IMG_DIRECTORY + iconSrc2} id="icon2" class="iconsPopup"/>
          <figcaption class="iconTextInfo">${linkText2}</figcaption>
        </figure>
      </div>`
    ;

    swal({
      title: sweetAlertTitle,
      html: sweetAlertHtml,
      showConfirmButton: false,
      allowOutsideClick: true,
      showCancelButton: true,
      onOpen: () => {
        $('#icon1')
          .on('click', () => {triggerIconLink(link1, linkText1)})
          .on('mouseover', () => {enlargeImg($('#icon1'));})
          .on('mouseleave', restoreImgSize);
        $('#icon2')
          .on('click', () => {triggerIconLink(link2, linkText2)})
          .on('mouseover', () => {enlargeImg($('#icon2'));})
          .on('mouseleave', restoreImgSize);
      }
    });
    
  }

  function enlargeImg(element) {
    $(element).animate({height: '100px', margin: '2.5px 40px'}, 'fast');
  }

  function restoreImgSize() {
    $(this).animate({height: '75px', margin: '15px 40px'}, 'fast');
  }

  function triggerIconLink(link, category) {
    if(typeof link != 'string' || !link.trim().length) {
      console.debug('invalid link skipped');
      return;
    }

    console.log('link is : ');
    console.log(link);

    if(link === 'TBD') {
      swal({
        title: i18n.t("js.templates.not-ready"),
        imageUrl: IMG_DIRECTORY + 'constructionCone.png',
        imageWidth: 120,
        imageHeight: 150,
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("close"),
        allowOutsideClick: true,
        showCloseButton: true
      });
      return;
    }

    saveSystemActivity({
      action: 'request',
      params: 'Link~' + link + '||Category~' + category,
      message: 'User accessed or downloaded a template.'
    });

    switch(category) {
      // Have hardcode these strings as the switch doesn't seem to take the translated string value of the i18n.t() function.
      // I'll look into how to fix this. I imagine it's how i18n runs under the hood.
      case 'View form' || 'Voir le formulaire' : viewForm(link); break;
      case 'Distribute form' || 'Distribuer le formulaire' : distributeForm(link); break;
      case 'excel': downloadTemplate(link); break;
      case 'word': downloadTemplate(link); break;
    }

    swal.close();
  }

  function viewForm(googleFormLink) {
    r.newTab(googleFormLink);
  }

  function distributeForm(googleFormLink) {
    r.loadPage('compose', { emailBody: `${i18n.t('js.compose.google.form.share')} ${googleFormLink}`, emailSubject: 'Crew Info Sheet' });
  }

  function downloadTemplate(templateName) {
    var filePath = "https://storage.googleapis.com/8810931_croogloo_public/"+ encodeURIComponent(templateName);
    window.location.href = filePath;
  }
