import i18n from './Translation';
import DocUtility from './DocUtility';
import SmartFolder from './SmartFolder.js';
import swal from 'sweetalert2';
require('jstree');

export default class Upload {
  constructor(folderid = null, filetype = 'reportsAndSchedules', tenantId = null, sharingToken = null) {
    this.progress;
    this.uploadprocess;
    this.tenantId = tenantId || croogloo_auth.tenantId;
    this.sharingToken = sharingToken;

    this._page;
    this._fileType = filetype;
    this.uploadType = []
    this.sharedHtmlInput = "";
    this.categoryHtmlInput = "";

    this.page = "";

    this._tempFileName = "";
    this.uploadInformationValue = [];

    this.popupShowing = false;

    /********* FOLDER SELECTION *********/

    this.smartFolders = []
    this.folderCollection = [];
    this.selectedFolders = new Array();

    this.isInvalidKeyToastShowing = false;

    this.shouldPublishToStudio;
    //watermarking
    this.currentWmFirstLine;
    this.currentWmSecondLine;

    this.wmFirstOtherField;
    this.wmSecondOtherField;

    this.addedFileList;

    this.wasParentFolderPredefined = typeof folderid == 'string' && folderid.length > 0;
    this.parentFolderId = folderid;

    this.fileoptions=null;

    Utility.loadStylesheet('/assets/jstree/style.min.css');
    Utility.loadStylesheet('/assets/jstree/js-tree-custom.css');
    Utility.loadStylesheet('/assets/croogloo-custom/css/watermarkingOptions.css');
    Utility.loadScript('/assets/croogloo-custom/js/watermarkingOptions.js');
    this.addJsTree();
    return this.init();
  }
  fromDropbox(dropboxfilepath, file) {
    var self = this;
    return new Promise((accept, reject) => {
      if (file && typeof file === 'object') {
        self.newFile({name: file.name, src: 'dropbox'}, f => {
          var url = "/DropboxImport?token=" + croogloo_auth.usertoken + "&tenantId=" + self.tenantId + "&dropbox_accesstoken=" + croogloo_auth.crooglooauth.dropboxToken + "&filepath=" + dropboxfilepath + "&destfile=" + f.id;
          return authenticatedcall(url);
        }).then(accept, reject);
      }
    });
  }
  listFromDropbox(formData) {
    return new Promise((resolve, reject) => {
      this.getUploadFolders({}, {}, false).then(wasFolderSelected => {
        if(!wasFolderSelected) {
          this.showUploadCancelAlert({}, function(){}, true);
          reject(i18n.t("js.upload.reject.cancelled"));
          return;
        }
        let url = "/DropboxImport?token=" + croogloo_auth.usertoken + "&tenantId=" 
          + this.tenantId + "&dropbox_accesstoken=" + croogloo_auth.crooglooauth.dropboxToken;
        formData.append('parentCrooglooFolderId', this.parentFolderId);
        DocUtility.removeCardViews(this.parentFolderId);
        for(let entry of formData.entries()) {
          console.debug('form data item', entry[0], entry[1]);
        }
        swal({
          title: i18n.t("response.success"),
          html: i18n.t("js.upload.import"),
          confirmButtonText: i18n.t("OK"),
          type: 'success',
          allowOutsideClick: true
        }).catch(swal.noop);
        authenticatedcall(url, 'POST', formData, 'multipart/form-data').then(resolve).catch(err => reject(err));
      });
    });
  }
  fromGoogleDrive(itemid, file) {
    var self = this;
    return new Promise((accept,reject)=> {
      if (file && typeof file === 'object') {
        self.newFile({name: file.name, src: 'google_drive'},(f)=>{
          var url = "/GDriveImport?token=" + croogloo_auth.usertoken
            + "&tenantId=" + self.tenantId
            + "&googlefileid=" + itemid
            + "&croogloofileid=" + f.id
            + "&filename=" + f.finalname
            + "&access_token=" + croogloo_auth.gDriveAccessToken;
          return authenticatedcall(url);
        }).then(accept,reject);
      }
    });
  }
  toGoogleCloudStorage(files, logoUpload = false) {

    var self = this;
    self.isFirstFileSucceeded = false;
    return new Promise((accept,reject) => {
      if (files && typeof files === 'object') {
        if (files.constructor.name === "File") {
          files = [files];
        } else if (files.constructor.name === 'FileList') {
          var temp = [];
          for (var i=0;i<files.length;i++) {
            temp.push(files[i]);
          }
          files = temp;
        } else if (Array.isArray(files) && files.length>0 && files[0].constructor.name=='File') {
          // do nothing, it is expected an array of File
        } else {
          reject();
        }
        let uploadpromises = [];
        let uploadedfiles = [];
        let notpanels = [];
        self.uploadInformationValue=[];
        var options = false;
        let p = Promise.resolve();
        var controlidx = 0;
        var MB = 1048576;
        files.acceptedLength = files.length;
        let validFileIdx = 0;
        for (let idx = 0; idx < files.length; idx++) {
            if(files[idx].size > 1024*MB) {
              files.acceptedLength--;
              cgToast(i18n.t("js.upload.too-large", {name: files[idx].name}), {
                duration: 8000,
                className: 'error-toast body-attached',
                containerID: 'docBody'
              });
              files[idx].validFileIdx = -1;
            } else {
              files[idx].validFileIdx = validFileIdx++;
            }
        }
        for (let idx = 0; idx < files.length; idx++) {
          if(files[idx].size > 1024*MB) { continue }
          p = p.then(()=>{
            let fileToUpload = null;
            for (let i = 0; i < files.length; i++) {
              if(files[idx].validFileIdx === controlidx) {
                fileToUpload = files[idx];
                controlidx++;
                break;
              }
            }
            if(fileToUpload) {
              fileToUpload.src = 'local_system';
            }
            return self.newFile(fileToUpload, async function(f) {

              if(window.uploadManager) {
                window.uploadManager.add(f.id, f.finalname);
              }
           
              let notif = document.createElement('span');
              notif.style.whiteSpace = 'normal';
              let notpanel = cgNotification.addNotification(i18n.t("js.upload.title"), notif);
              notpanel.htmlElement.style.backgroundColor="#FFF176";
              notpanels.push(notpanel);
              let progress = (v) => {
                try {
                  if(window.uploadManager && window.uploadManager.uploadBoxes[f.id]) {
                    window.uploadManager.uploadBoxes[f.id].updateProgress(v);
                  }
                  if (v < 100) notif.innerText = f.finalname + ": " + v + "%";
                  else notif.innerText = i18n.t("js.upload.saving", {filename: f.finalname});
                } catch(e1) {
                  console.error(e1);
                }
              }
              let uploadData = await fetchUploadData(f.id);
              let url = uploadData.signedURL;
              return new Promise((done,err)=>{
                console.debug('using primary upload method');
                let xhrMain = new XMLHttpRequest();
                xhrMain.open('PUT', url);
                xhrMain.setRequestHeader("Content-Type", uploadData.contentType);
                xhrMain.onload = () => {
                  if (xhrMain.status >=200 && xhrMain.status <300) {
                    console.debug('primary method succeeded');
                    done();
                  } else {
                    console.error('primary upload method failed');
                    failover();
                  }
                }
                xhrMain.onerror = () => {
                  // console.error(arguments);
                  console.error('primary upload method failed');
                  failover();
                }
                xhrMain.upload.onprogress = (eventUpload) => {
                  if (eventUpload.lengthComputable && progress) {
                    progress(Math.ceil(eventUpload.loaded/eventUpload.total*100));
                  }
                }
                xhrMain.send(f);
                function failover() {
                  console.debug('using failover upload method');
                  let formDataV2 = new FormData();
                  formDataV2.append("fileName", f.id);
                  formDataV2.append("file", f);
                  authenticatedcall("/UploadServletV2",'POST',formDataV2, "multipart/form-data", progress).then(()=>{
                    done();
                  },()=>{
                    err(f.finalname);
                  });
                }
              });
              function fetchUploadData(fileId) {
                return new Promise(resolve => {
                  apicall('documentsapi', 'fetchUploadLink', {
                    fileId: fileId
                  }).then(resp => {
                    if(resp.responseCode !== '0') {
                      throw new Error('invalid server response');
                    } else {
                      resolve({
                        signedURL: resp.contentURL,
                        contentType: resp.responseMessage
                      });
                    }
                  }).catch(() => {
                    console.error('server error retrieving uploadURL');
                    resolve({});
                  });
                });
              }
            }, options, logoUpload)
            .then((fileepromise)=>{
              if (fileepromise){
                uploadpromises.push(fileepromise[1]);
                uploadedfiles.push(fileepromise[0]);
              }
              return fileepromise;
            }, () => {
              console.debug('a file upload was cancelled');
            });
          });
          p = p.then(()=>{
            if(!files[idx].isCancelled && files.acceptedLength > idx + 1
              && !self.isFirstFileSucceeded) {
              self.isFirstFileSucceeded = true;
              return swal({
                title: i18n.t("js.utils.apply.all"),
                text: i18n.t("js.upload.apply-all.text"),
                type: 'question',
                showCancelButton: true,
                confirmButtonText: i18n.t("yes"),
                cancelButtonText: i18n.t("no"),
                showCloseButton: true,
              }).then((value)=>{
                if (value) options=true;
                return Promise.resolve();
              },()=>Promise.resolve());
            }
          });
        }
        p.then(()=>{
          Promise.all(uploadpromises).then(()=>{
            //Comment out for now, could come in handy if users tell us too many notifs   
            //for (var idx in notpanels) notpanels[idx].remove();         
            for (var idx in notpanels){         
              notpanels[idx].htmlElement.style.backgroundColor='#62df74';

              let text = notpanels[idx].htmlElement.childNodes[1].innerText;
              let nameBegin = text.substring(0, text.indexOf(':') + 1);             
              
              notpanels[idx].htmlElement.childNodes[1].innerText = nameBegin + " " +i18n.t("file.uploaded")
              
            } 
            
            accept(uploadedfiles);
          }).catch(err => {
            for (var idx in notpanels) notpanels[idx].remove();   
            console.error(err);
          });
        }, reject);
      } else {
        reject();
      }
    });
  }
  /**
   * {file} is a File object
   * {uploadfunction} should upload the file and return a Promise
   * {_fileType} can be (reportsAndSchedules or template or script or reports or schedules)
   * {parentFolderId} the id of the parent folder
   * {parentFolderName} the name of the parent folder
   */
  newFile(file, uploadfunction, options, logoUpload = false) {
    if (!file || !uploadfunction) {
      return Promise.reject();
    }
    this.uploadprocess = uploadfunction;
    //this.addedFileList = [file];
    var self = this;
    return new Promise((procSuccess, procFail)=>{

      // logoUpload applies only to generate sides page uploading of logo
      // it allows us to skip checking for existing file and skip categorization

      if(logoUpload === true) {
        procSuccess([file, self.processFile(file)]);
      }
      else {
        self.checkFileExists(file).then((f) => {
          if (!options){
            if ( self._fileType != "template") //!self.popupShowing &&
            self.showUploadScreen(f).then(procSuccess, procFail);
            else if ( self._fileType == "template") //!self.popupShowing &&
            self.showTemplateUploadScreen(f).then(procSuccess, procFail);
          } 
          else {
            procSuccess([f, self.processFile(f)]);
          }
        }).catch(err => {
          // console.error(err);
          self.showUploadCancelAlert(file, procFail);
        });
      }
    });
  }

  init() {
    let that = this;
    return new Promise(resolve => {
      new TaskList({ passReference: true })
      .add(list => {
        that.adaptPageToFileType();
        that.initFolderTree(() => list.check('folder-tree'));
        that.getSharedField(() => list.check('shared-field'));
        that.initCategoryHtmlInput();
      }, ['folder-tree', 'shared-field'])
      .oncomplete(() => resolve(that))
      .execute();
    });
  }

     adaptPageToFileType() {
      var self = this;
      adaptPageMenu();
      adaptUploadType();

      function adaptPageMenu() {
        switch (self._fileType) {
          case "template":
            self._page = "menu_templates";
            break;

          case "script":
            self._page = "menu_scripts";
            break;

          case "reportsAndSchedules":
            self._page = "menu_documents";
            break;

          case "photo":
            self._page = "menu_documents";
            break;

          case "reports":
            self._page = "menu_documents";
            break;

          case "schedules":
            self._page = "menu_schedules";
            break;
        }
      }

      function adaptUploadType() {
        self.uploadType = DocUtility.getFileCategories(self._fileType);
      }

    }

    setTitle() {
      let pageTitle;
      let title = '';
      switch (this._fileType) {
        case 'reportsAndSchedules':
          pageTitle = '';
          break;
        case 'script':
          pageTitle = '';
          break;
        case 'template':
          pageTitle = i18n.t("js.upload.titles.template");
          title = i18n.t("js.upload.titles.template");
          break;
        case 'photo':
          pageTitle = i18n.t("js.upload.titles.photo");
          break;
        default:
          pageTitle = i18n.t("js.upload.titles.default");
      }
      document.title = 'Croogloo - ' + pageTitle;
      document.getElementById('pageTitle').textContent = title;
    }
    
    initCategoryHtmlInput() {
      this.categoryHtmlInput = '<select name="categorySelector" class="browser-default" id="subCategorySelect">'
        + '<option disabled selected value>'+i18n.t("js.documents.category.placeholder")+'</option>';
      for (var i = 0; i < this.uploadType.length; i++) {
        this.categoryHtmlInput += '<option value="' + this.uploadType[i].key + '">' + this.uploadType[i].value + '</option>';
      }
      this.categoryHtmlInput += '</select>';
    }

    static isFileNameOrUrlEmpty(fileName, fileUrl) {
      if(fileName === undefined || fileName === "" || fileName === null) {
        return true
      }

      if(fileUrl === undefined || fileUrl === "" || fileUrl === null) {
        return true
      }

      return false
    }

  /**
   * Custom replacement of DH or dh string value with DTR to display in the frontend 
   * should not affect sharing settings ie: dh will still be sent to the backend API
   * @param {String} sharedString unparsed value of what security groups this document is shared with
   */
  replaceDHInShared(sharedString) {
    let modifiedString = sharedString;
    if (sharedString.includes('dh')) {
      modifiedString = sharedString.replace('dh', 'dtr');
    }
    if (sharedString.includes('DH')) {
      modifiedString = sharedString.replace('DH', 'DTR');
    }
    if (sharedString.includes('Dh')) {
      modifiedString = sharedString.replace('Dh', 'DTR');
    }

    return modifiedString;
  }

     getSharedField(callback) {
      var self = this;
      var secListItems = [];
      apicall('securityadminapi', 'fetchSecurityLists', {}).then(function(resp) {
        if (resp.items) {
          self.sharedHtmlInput = '<div id="sharedInput">';
          for (var i = 0; i < resp.items.length; i++) {
            secListItems.push(resp.items[i].properties);
          }
          secListItems.sort(function(a, b) {
            return parseInt(a.displayOrder) - parseInt(b.displayOrder);
          });
          for (var i = 0; i < secListItems.length; i++) {
            var item = secListItems[i].securityListId;
            // Capitalize the first letter only, lowercase the rest. Ex:  Admin, Exec
            var label = item[0] + item.substring(1, item.length).toLowerCase()
            // Special case where DH needs to be displayed as DTR but the id/value itself should not change.
            // The formatting for DH is Dh here
            if (label == 'Dh') {
              label = self.replaceDHInShared(label)
            }
            // the ADMIN list and the user's security list must be checked and disabled
            // to prevent the user from revoking his own access or an admin's access to a file
            // (even though the backend wouldn't allow ADMIN access to be revoked)
            if (item.toUpperCase() == 'ADMIN' ||
              typeof croogloo_auth.crooglooauth.securityListId == 'string' &&
              croogloo_auth.crooglooauth.securityListId.toUpperCase() == item.toUpperCase()) {
              self.sharedHtmlInput += '<input type="checkbox" name="sharedInput" id="' + item + '" disabled checked>&nbsp;' + label + '&emsp;';
            } else {
              // If the document is already shared with an existing security group
              self.sharedHtmlInput += '<input type="checkbox" name="sharedInput" id="' + item + '">&nbsp;' + label + '&emsp;';
            }
          }
          self.sharedHtmlInput += '</div>';
        }
        callback && callback();
      }).catch(err => {
        console.error('failed to prepare shared field')
        callback && callback();
      });
    }
    
    processFile(file) {
      var self = this;
      file.finalname = file.newname || file.name;
      file.id = DocUtility.generateUniqueId(file.finalname);
      return new Promise((accept, reject) => {
        self.uploadprocess(file).then(() => {
          self.saveFileEntity(file).then(accept, function() {
            console.error('error in upload process');
            window.uploadManager.uploadBoxes[file.id].markAsFailed();
            reject();
          });
        }).catch(err => {
          console.error('error in upload process', err);
          window.uploadManager.uploadBoxes[file.id].markAsFailed();
          reject();
        });
      });
    }

     saveFileEntity(file) {
      var self = this;
      var fixedFileName = getValidFileName(file);
      var newFileId = file.id;

      var bucketName = "8810931_" + self.tenantId.toLowerCase();

      var uploadedFileURL = "https://storage.googleapis.com/" + bucketName + "/" + newFileId;

      var newFile = new Object();
      var newFileAttributes = [];

      newFile._ID = newFileId; 
      newFile._NAME = fixedFileName;
      newFile._fileType = self._fileType;
      newFile.entityType = "DOCUMENT";
      newFile.pageName = "documents";
      newFile.tenantId = self.tenantId;

      newFileAttributes.push({id: "id", value: newFileId});
      newFileAttributes.push({id: "category", value: "DOCUMENT"});

      var fileExtension = file.name.split('.').pop().toLowerCase();

      if (fileExtension == undefined || fileExtension == null) {
        fileExtension = "";
      }

      var secList = [];
      var category = '';
      var wmk = 0;
      var episode = '';
      var color = '';
      var watermark, wmFirstLine, wmSecondLine, wmWithEncryption;

      var currFile = self.uploadInformationValue.length > 0 ? self.uploadInformationValue[0] : {episode: "0", name: newFile._NAME, shared: ['ADMIN'], watermark: 0};
 

      if (self.uploadInformationValue.length>1) {
        for (var idv in self.uploadInformationValue) {
          let item = self.uploadInformationValue[idv];
          if (item.name == fixedFileName) {
            currFile = item;
            break;
          }
        }
      }
    
      for (var j = 0; j < currFile.shared.length; j++) {
        secList.push(currFile.shared[j]);
      }
      

      newFileAttributes.push({id: "limitToType", value: 'securityList'});
      newFileAttributes.push({id: "limitTo", value: secList.join()});


      category = currFile.category
        ? currFile.category
        : "other";

      watermark = currFile.watermark
        ? 1
        : 0;
      newFileAttributes.push({id: "isWatermarked", value: watermark});

      wmFirstLine = currFile.wmFirstLine
        ? currFile.wmFirstLine
        : "";
      if (wmFirstLine === 'Other') {
        wmFirstLine = 'Other~' + self.wmFirstOtherField;
      }
      newFileAttributes.push({id: "firstWatermarkLine", value: wmFirstLine});

      wmSecondLine = currFile.wmSecondLine
        ? currFile.wmSecondLine
        : "";
      if (wmSecondLine === 'Other') {
        wmSecondLine = 'Other~' + self.wmSecondOtherField;
      }
      newFileAttributes.push({id: "secondWatermarkLine", value: wmSecondLine});

      wmWithEncryption = currFile.wmWithEncryption === '1' ?'1' :'0';
      newFileAttributes.push({id: "watermarkWithEncryption", value: wmWithEncryption});

      episode = currFile.episode
        ? currFile.episode
        : "";
      newFileAttributes.push({id: "episode", value: episode});

      color = currFile.color
        ? currFile.color
        : "";
      newFileAttributes.push({id: "color", value: color});

      newFileAttributes.push({id: 'description', value: currFile.description});
      newFileAttributes.push({id: "fileExtension", value: fileExtension});
      newFileAttributes.push({id: "bucket", value: bucketName});
      newFileAttributes.push({id: "showInReports", value: "0"});
      newFileAttributes.push({id: "submittedTo", value: ""});
      newFileAttributes.push({id: "fileURL", value: uploadedFileURL});
      newFileAttributes.push({id: "image", value: ""});
      newFileAttributes.push({id: "approval", value: ""});
      newFileAttributes.push({id: "approvedDate", value: ""});
      newFileAttributes.push({id: "approvedBy", value: ""});
      newFileAttributes.push({id: "showInHub", value: "0"});
      newFileAttributes.push({id: "title", value: fixedFileName});
      newFileAttributes.push({id: "isSubmitted", value: "0"});;
      newFileAttributes.push({id: "size", value: file.size});
      newFileAttributes.push({id: "priority", value: "0"});
      newFileAttributes.push({id: "subtitle", value: fixedFileName});
      newFileAttributes.push({id: "submittedDate", value: ""});
      newFileAttributes.push({id: "fileName", value: fixedFileName});
      newFileAttributes.push({id: "docDescription", value: ""});
      newFileAttributes.push({id: "submittedBy", value: ""});
      newFileAttributes.push({id: "parentFolder", value: self.parentFolderId ? self.parentFolderId.split(',', 2)[0] : 'ROOT'});
      newFileAttributes.push({id: "parents", value: (file.parents = self.parentFolderId ? self.parentFolderId : 'ROOT')});
      self.parentFolderId ? DocUtility.removeCardViews(self.parentFolderId) : ''
      newFileAttributes.push({id:"children", value:""});
      newFileAttributes.push({id: "uploader_token", value: croogloo_auth.usertoken });
      var date = new Date().toISOString();
      newFileAttributes.push({id: "timeCreated", value: date});
      newFileAttributes.push({id: "subcategory", value: category});
      newFileAttributes.push({id: "lastModifBaseTime", value: new Date().getTime()});
      newFileAttributes.push({id: "source", value: file.src || 'local_system'});

      newFile.attributes = newFileAttributes;

      return new Promise(async (newFileSuccess,newFileFail)=>{

      if(Upload.isFileNameOrUrlEmpty(fixedFileName, uploadedFileURL)) {
        cgToast("file name or url were missing, could not upload file")
        newFileFail("Could not upload document, file name or url were missing")
      }

      saveSystemActivity({
        action: 'upload', params: findSystemActivityParams(newFile).split('||attributes~')[0],
        message: 'User uploaded a document from the upload page.'
      });

      //preventing early deletion of referenced object
      currFile = $.extend(true, {}, currFile);
      let newEntityUrlParams = {
        tenantId: self.tenantId,
        page: self._page
      };
      if(this.sharingToken !== null) {
        newEntityUrlParams.token = this.sharingToken;
      }

      if(this.shouldPublishToStudio) {
        newEntityUrlParams.cloudProviderName = "BOX"
        newEntityUrlParams.cloudAuthToken = croogloo_auth.crooglooauth.boxToken

        let boxRefreshToken = JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken
        await croogloo_auth.box(boxRefreshToken, true)

        console.log("refresh token successfully called")
      }

      apicall('documentsapi',
          !fixedFileName.endsWith('.zip')?'saveDocument':'addZipAsFolder',
          newEntityUrlParams, newFile).then(function(resp) {

        if (resp) {
          window.uploadManager.uploadBoxes[newFileId].setCompleted();

          if(fixedFileName.endsWith('.zip') && resp.responseMessage && resp.responseMessage == 'parsing error') {
            cgToast(i18n.t("js.upload.parsing.error"), {
              duration: 5000,
              containerID: 'docBody',
              className: 'body-attached'
            });
          }

          if (currFile.isParsed) {
            self.parseScript(newFileId, currFile.name, currFile.name, currFile.episode, currFile.color);
          }

          if (self._fileType == "template" && !fixedFileName.endsWith('.zip')) {

            var excelFile = new Object();
            excelFile.fileId = newFileId;
            excelFile.fileName = fixedFileName;
            excelFile.tenantId = self.tenantId;
            console.log("about to process excel template");

            saveSystemActivity({
              action: 'submit',
              params: findSystemActivityParams(excelFile),
              message: 'User submitted an excel template to be parsed.'
            });

            apicall('importexportapi', 'processExcelTemplate', excelFile).then(function(resp) {
              //if(resp && resp.responseCode === '-1')
              //cgToast("Invalid community name.", 3000);
              console.log("just processed excel template");
            });
            newFileSuccess(fixedFileName);
          } else {
            newFileSuccess(fixedFileName);
          }
        } else  {
          newFileFail("Could not upload document");
        }
      }, ()=>{
        newFileFail("Could not upload document");
      }).catch(() => {
        newFileFail("Could not upload document");
      });

    }); //end promise return
    }
    
    showTemplateUploadScreen(file) {
      let self = this;
      self.popupShowing = true;
      //var file = addedFileList.shift();
      var filename = getValidFileName(file);
      var object = new Object();

      var htmlContent = '<p><u><b>'+ filename +'</b></u>'
        + '</p><form class="uploadForm"><label style="font-size:18px;">'+i18n.t("documents.shared")+'</label>'
        + self.sharedHtmlInput + '</form>'

      return new Promise((showNext, reject)=>{
        swal({
          title: i18n.t("js.upload.template.title"),
          type: 'question',
          html: htmlContent,
          showCancelButton: true,
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("button.next"),
          cancelButtonText: i18n.t("button.cancel"),
          showCloseButton: true,
          showCancelButton: true,
          useRejections: true, //important for swal2 v7.1.2
          expectRejections: true,
          preConfirm: function() {
            return new Promise(function(resolve) {
              object.name = filename;
              object.shared = [];
              $('#sharedInput').children().each(function() {
                if ($(this).is(':checked'))
                  object.shared.push($(this).attr('id'));
                }
              );
              object.category = 'other';
              object.watermark = 0;
  
              object.episode = '0';
  
              //must make a copy of the object to prevent deletion
              //uploadInformationValue.push($.extend(true,{},object));
              self.uploadInformationValue.push(object);
              resolve([object]);
            })
          }
        }).then(async function() {

          if(!await self.getUploadFolders(object, file)) {
            throw 'popup closed';
          }

        })
        .then(async () => {
          // TODO: re-enable for Publish to Studio feature
          // await this.publishToStudioSwal(file)
          showNext([file, self.processFile(file)]);
        })
        .catch(error => {
          // console.error(error);
          self.showUploadCancelAlert(file, rejectNext);
        });
      });
    }

    checkFileExists(file) {
      var self = this;
      var filename = getValidFileName(file);
      var folder = self.parentFolderId;

      return new Promise((done,rejectExit)=>{
        DocUtility.ensureFileNameUnicity(filename, newName => {
          file.newname = newName;
          
          done(file);
        }, rejectExit, self.tenantId);
      });
    }

    async publishToStudioSwal(self) {

      let selectedCloudProvider = null
      this.shouldPublishToStudio = false;

      return new Promise(accept => {

        swal({
          type: 'info',    
          title: i18n.t("docs.menu.import.cloud"),       
          showCancelButton: true,
          showConfirmButton: true,
          showCloseButton: true,
          allowOutsideClick: true,
          confirmButtonText: i18n.t("button.confirm"),
          cancelButtonText: i18n.t("js.sides.callsheet.skip"),
          html: 
            '<select style="height: 45px; margin: 0;" id="select_provider">'
              +'<option value=""> Select Studio Destination </option>'
              +'<option value="BOX">Box</option>'
              +'<option value="GDRIVE">Dropbox</option>'
              +'<option value="DBOX">Google Drive</option>'
              +'<option value="SHAREPOINT">Sharepoint</option>'
            +'</select>'
          ,
          onOpen: () =>{
      
            
            if(JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken !== null && JSON.parse(global.secureLocalStorage.get('crooglooauth')).hasOwnProperty('boxRefreshToken')) {
              document.getElementById('select_provider').value = "BOX"
              $('.swal2-confirm.swal2-styled').css('background-color', 'rgb(19, 196, 106)')
              selectedCloudProvider = "BOX"
            }else {
      
              $('.swal2-confirm.swal2-styled').css('background-color', '#aaa')
            }

            $('.swal2-content').css('padding', '15px 30px')
      
            $('#select_provider').on('change', async (e) => {
              let cloudProvider = e.target.value
              if(cloudProvider === "BOX") {
                
                selectedCloudProvider = "BOX"
      
                if(JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken !== null && JSON.parse(global.secureLocalStorage.get('crooglooauth')).hasOwnProperty('boxRefreshToken')) {
                  $('.swal2-confirm.swal2-styled').css('background-color', 'rgb(19, 196, 106)')            
                }
                else {
                  var w = 450;
                  var h = 600;
                  var left = 0;
                  var top = 0;
      
                  window.open("/box.html", "_blank",
                  'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width='
                  + w + ',height=' + h + ',top=' + top + ',left=' + left);
                }
              }
              else {
                $('.swal2-confirm.swal2-styled').css('background-color', '#aaa')
                selectedCloudProvider = null
              }
            })
          },
    
          preConfirm: () =>{      
      
            return new Promise(function (resolve, reject) {       
              if(JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken !== null && JSON.parse(global.secureLocalStorage.get('crooglooauth')).hasOwnProperty('boxRefreshToken')) {
                if(selectedCloudProvider !== null) {
                  resolve()
                }
              }
              reject()
            });
          }
        }).then(async (isConfirmed) =>{
            if(isConfirmed && selectedCloudProvider !== null) {
              this.shouldPublishToStudio = true
              accept()
            }
        })  
        .catch(isCancelled => {
          accept()
        })
      })
    }

    generateWatermarkingOption() {
      var self = this;
      self.currentWmFirstLine = '';
      self.currentWmSecondLine = '';
      self.wmFirstOtherField = '';
      self.wmSecondOtherField = '';

      var wmSettingsSwalContainer = document.createElement('div');
      wmSettingsSwalContainer.id = 'wmSettingsSwalContainer';
      var idnamemap = {};
      //////////////////////////////////////////////////////////////////////

      //first line options
      let firstLineDiv = new WatermarkOptionDiv('lineOne', i18n.t("watermark.line1.title"));
      idnamemap['wmFullName']='FullName';
      firstLineDiv.addLine([
        {id: 'wmFullName', label: i18n.t("watermark.line1.rec-name"), type: 'checkbox'}
      ]);
      idnamemap['firstOtherCheckbox']='Other';
      firstLineDiv.addLine([
        {id: 'firstOtherCheckbox', label: i18n.t("other"), type: 'checkbox'},
        {id: 'firstOtherInput', label: null, type: 'text'}
      ]);
      firstLineDiv.build(wmSettingsSwalContainer);

      //second line options
      let secondLineDiv = new WatermarkOptionDiv('lineTwo', i18n.t("watermark.line2.title"));
      idnamemap['wmTitle']='Title';
      idnamemap['wmDepartment']='Department';
      secondLineDiv.addLine([
        {id: 'wmTitle', label: i18n.t("watermark.line2.opts-title"), type: 'checkbox'},
        {id: 'wmDepartment', label: i18n.t("watermark.line2.opts-dept"), type: 'checkbox'}
      ]);
      idnamemap['wmDate']='Date';
      idnamemap['wmIPAddress']='IPAddress';
      secondLineDiv.addLine([
        {id: 'wmDate', label: i18n.t("watermark.line2.opts-date"), type: 'checkbox'},
        {id: 'wmIPAddress', label: i18n.t("watermark.line2.opts-ip"), type: 'checkbox'}
      ]);
      idnamemap['wmBlank']='Blank';
      secondLineDiv.addLine([
        {id: 'wmBlank', label: i18n.t("watermark.line2.opts-blank"), type: 'checkbox'}
      ]);
      idnamemap['secondOtherCheckbox']='Other';
      secondLineDiv.addLine([
        {id: 'secondOtherCheckbox', label: i18n.t("watermark.line2.opts-other"), type: 'checkbox'},
        {id: 'secondOtherInput', label: null, type: 'text'}
      ]);
      secondLineDiv.build(wmSettingsSwalContainer);

      WatermarkOptionDiv.addEncryptionOption(wmSettingsSwalContainer);

      //////////////////////////////////////////////////////////////////////

      //the elements' event listeners from watermarkingOptions.js do not work
      //on swal
      $(document).on('click', '.wm-checkbox-lineOne', function() {
        if (this.checked) {
          //self.currentWmFirstLine = this.id.match(/Other|Title|Department|FirstName|LastName|FullName|Blank|IPAddress|Date/)[0];
          self.currentWmFirstLine = idnamemap[this.id];
        } else {
          self.currentWmFirstLine = '';
        }
        let ref = this;
        $('.wm-checkbox-lineOne').each(function() {
          if (ref.id !== this.id) {
            this.checked = false;
          }
        });
      });
      $(document).on('click', '.wm-checkbox-lineTwo', function() {
        if (this.checked) {
          //self.currentWmSecondLine = this.id.match(/Other|Title|Department|FirstName|LastName|FullName|Blank|IPAddress|Date/)[0];
          self.currentWmSecondLine = idnamemap[this.id];
        } else {
          self.currentWmSecondLine = '';
        }
        let ref = this;
        $('.wm-checkbox-lineTwo').each(function() {
          if (ref.id !== this.id) {
            this.checked = false;
          }
        });
      });
      $(document).on('focus', '#firstOtherInput', function() {
        if (!$('#firstOtherCheckbox').is(':checked')) {
          $('#firstOtherCheckbox').click();
        }
      });
      $(document).on('blur', '#firstOtherInput', function() {
        self.wmFirstOtherField = this.value;
      });
      $(document).on('focus', '#secondOtherInput', function() {
        if (!$('#secondOtherCheckbox').is(':checked')) {
          $('#secondOtherCheckbox').click();
        }
      });
      $(document).on('blur', '#secondOtherInput', function() {
        self.wmSecondOtherField = this.value;
      });
      return wmSettingsSwalContainer.outerHTML;
    }
     showUploadScreen(file) {
      var self = this;
      self.popupShowing = true;

      var filename = getValidFileName(file);
      var cate = "";
      var object = new Object();

      let htmlContent = '<p><u><b>'+ filename +'</b></u>'+
      '</p><form class="uploadForm"><label style="font-size:18px;">'+i18n.t("documents.shared")+'</label>'+self.sharedHtmlInput+'<br>'+
      (self._fileType == 'photo' ?''
      :('<label style="font-size:18px;">'+i18n.t("category")+'</label>'+ self.categoryHtmlInput +
      '<br><label style="font-size:18px;">'+i18n.t("episode")
      +'</label><input id="episodeEntry" placeholder="'+i18n.t("js.compose.episode.number")+'" type="text" name="episode"><br>'))+
      '</form>';

      return new Promise((showNext, rejectNext)=>{

      swal({
        title: i18n.t("js.compose.file.info.title"),
        type: 'question',
        html: htmlContent,
        confirmButtonText: i18n.t("button.next"),
        confirmButtonColor: '#13C46A',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        useRejections: true, //important for swal2 v7.1.2
        expectRejections: true,
        animation: ($('#docBody').hasClass('swal2-shown') ? false : true),
        onClose: () =>{
          $('.swal2-popup').removeClass('swal2-noanimation');
          $('.swal2-popup').addClass('swal2-hide');
        },
        onOpen: function(swal) {
          $('#episodeEntry').off();
          $('#episodeEntry').on('keypress', function(event) {
            return self.validKey(event.key);
          });
        },
        preConfirm: function() {
          return new Promise(function(resolve) {
            object.name = filename;
            object.shared = [];
            $('#sharedInput').children().each(function() {
              if ($(this).is(':checked'))
                object.shared.push($(this).attr('id'));
              }
            );
            if ($('select[name=categorySelector]').length && $('select[name=categorySelector]').val()) {
              cate = $('select[name=categorySelector]').val();
              object.category = cate;
            } else if(self.uploadType.length === 1) {
              object.category = cate = self.uploadType[0].key;
            }

            if($('#watermarkCheckbox').is(':checked') && object.name.trim().endsWith('.pdf')){
    					atLeastOneWatermarkedFile = true;
    					object.watermark = 1;
            } else {
              if($('#watermarkCheckbox').is(':checked')) {
                cgToast(i18n.t("js.upload.wtmrk.pdf-only", {fileName: object.name}), {
                   hiding: 'manual',
                   showCloseButton: true,
                   className: 'error-toast'
                 });
              }
              object.watermark = 0;
            }

            object.episode = ($('#episodeEntry').length ?$('#episodeEntry').val().trim() :null) || '0';

            //must make a copy of the object to prevent deletion
            //uploadInformationValue.push($.extend(true,{},object));
            self.uploadInformationValue.push(object);
            resolve([object]);
          })
        }
      }).then(async function(result) {
        if(["script", "revision"].includes(cate) && !filename.endsWith(".zip")) {
          if(!await self.getScriptInfo(object, filename)) {
            throw 'popup closed';
          }
        }

        if(["photo"].includes(cate)) {
          if(!await DocUtility.getDocumentDescription(object)) {
            throw 'popup closed';
          }
        }

        if(filename.endsWith(".pdf") || filename.endsWith(".zip") && cate !== 'photo') {
          if(!await self.getWatermarkOptions(object)) {
            throw 'popup closed';
          }
        }

        if(!await self.getUploadFolders(object, file)) {
          throw 'popup closed';
        }

        
      })
        .then(async () => {
          // TODO: re-enable for Publish to Studio feature
          // await this.publishToStudioSwal(file)
          showNext([file, self.processFile(file)]);
      })
      .catch(error => {
        self.showUploadCancelAlert(file, rejectNext);
      });

      });// end Promise return
    }

    showUploadCancelAlert(file, callback, areManyFiles = false) {
      file.isCancelled = true;
      swal({
        title: i18n.t("js.upload.reject.cancelled"),
        text: i18n.t("js.upload.not."+ (areManyFiles ? "many":"one")),
        type: 'error',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK"),
        useRejections: true, //important for swal2 v7.1.2
        expectRejections: true
      }).then(callback).catch(callback);
    }

    getScriptInfo(object, filename) {
      let self = this;
      // '<option selected value="-"> Paper Chase </option>'+ instead Script Version
      return new Promise(resolve => {
        var scriptInfoHtml = "<form><div class='colorSelector'>"+i18n.t("js.file.script.color.text")+"<div><br>"+
        '<div><select name="colorMult" class="browser-default" id="colorMult">'+
        '<option selected value="-">'+i18n.t("js.file.script.version")+'</option>'+
        '<option value="draft">Draft</option>'+
        '<option value="single">Single</option>'+
        '<option value="double">Double</option>'+
        '<option value="triple">Triple</option>'+
        '<option value="quadruple">Quadruple</option>'+
        '<option value="quintuple">Quintuple</option></select>'+
        '<select name="colorVal" class="browser-default" id="colorVal">'+
        '<option selected value="-">'+i18n.t("js.file.script.color.default")+'</option>'+
        '<option value="white">White</option>'+
        '<option value="blue">Blue</option>'+
        '<option value="pink">Pink</option>'+
        '<option value="yellow">Yellow</option>'+
        '<option value="green">Green</option>'+
        '<option value="goldenrod">Goldenrod</option>'+
        '<option value="buff">Buff</option>'+
        '<option value="salmon">Salmon</option>'+
        '<option value="cherry">Cherry</option>'+
        '<option value="tan">Tan</option>'+
        '<option value="lavender">Lavender</option>'+
        '</select></div>'+
        '<br><label style="font-size:18px;display:inline-block;">'+i18n.t("js.file.script.parse")+'&emsp;</label>'+
        '<input type="checkbox" id="parseCheckbox" value="1" checked></form>';

        swal({
          title: i18n.t("js.file.script.version"),
          html: scriptInfoHtml,
          showCloseButton: true,
          confirmButtonText: i18n.t("button.next"),
          confirmButtonColor: '#13C46A',
          cancelButtonText: i18n.t("button.cancel"),
          animation: false,
          useRejections: true, //important for swal2 v7.1.2
          expectRejections: true,
          preConfirm: function(){
            return new Promise(function(done, reject) {
              if($('#parseCheckbox').is(':checked') && $('select[name=colorVal]').val() == "-"){
                reject(i18n.t("js.file.script.reject.color"))
              } else if($('#parseCheckbox').is(':checked') && (/\.pdf$/i).exec(filename) === null) {
                reject(i18n.t("js.file.script.reject.pdf-only"));
              } else {
                done({
                  isParseChecked: $('#parseCheckbox').is(':checked'),
                  paperChase: $('select[name=colorMult]').val(),
                  color: $('select[name=colorVal]').val()
                });
              }
            });
          }
        }).then(function(scriptOptions){
          var mult = scriptOptions.paperChase;
          var col = scriptOptions.color;
          mult = mult !== "-" ?mult :"";
          col = col !== "-" ?col :"";

          if(mult == 'draft') {
            object.color = 'draft';
          }
          else if(mult && col){
            if(mult == 'single') {
              object.color = col;
            }
            else {
              object.color = mult+col[0].toUpperCase()+col.substring(1);
            }
          }
          else if(col){
            object.color = col;
          }
          if($('#parseCheckbox').is(':checked') && !object.episode){
            object.episode = 0;
          }

          if(scriptOptions.isParseChecked){
            object.isParsed = true;
          }
          resolve(true);
        }, dismiss => resolve(false));
      });
    }

    getWatermarkOptions(object) {
      // users should not see the watermarking options if they don't have access to it
      if(!croogloo_auth.hasAccess('watermark')) {
        object.wmFirstLine = null;
        object.wmSecondLine = null;
        object.wmWithEncryption = '0';
        object.watermark = 0;
        return Promise.resolve(true);
      }

      let self = this;
      return new Promise(resolve => {
        swal({
          title: i18n.t("js.documents.menu.wtmrk.add"),
          html: self.generateWatermarkingOption(),
          type: 'info',
          input: 'text',
          inputClass: 'hidden-input', //used to prevent focus error on reject
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("button.confirm"),
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: i18n.t("js.sides.callsheet.skip"),
          background: '#fff url(' + IMG_DIRECTORY + 'watermarkLogo.png)',
          animation: false,
          showLoaderOnConfirm: false,
          useRejections: true, //important for swal2 v7.1.2
          expectRejections: true,
          onOpen: function() {
            document.getElementById('wmFullName').click();
            document.getElementById('wmBlank').click();
          },
          preConfirm: function(text) {
            return new Promise(function(done, reject) {
              if(!self.currentWmFirstLine || !self.currentWmSecondLine) {
                reject(i18n.t("js.file.wtmrk.reject.selection"));
              } else if(self.currentWmFirstLine === 'Other'
                  && (!self.wmFirstOtherField || !self.wmFirstOtherField.trim()) ||
                  self.currentWmSecondLine === 'Other'
                  && (!self.wmSecondOtherField || !self.wmSecondOtherField.trim())) {
                reject(i18n.t("js.file.wtmrk.reject.other"));
              }
              else {
                done(document.getElementById('encryptChk') != null &&
                  document.getElementById('encryptChk').checked);
              }
            });
          }
        }).then(function(addEncryption) {
          object.wmFirstLine = self.currentWmFirstLine;
          object.wmSecondLine = self.currentWmSecondLine;
          object.wmWithEncryption = addEncryption === true ?'1' :'0';
          object.watermark = 1;
          resolve(true);
        }, dismiss => resolve(dismiss === 'cancel')); // cancel = skip button
      });
    }

    // NOTE validate access to documents if we plan to integrate this
    // component somewhere other than Files
    getUploadFolders(object, file, allowMultiSelect = true, forceFolderSelection = false) {
      if(this._fileType == 'photo') {
        return Promise.resolve(true);
      }
      // We check typeof this.parentFolderId === 'string' to make sure that 
      // the preselected directory is not a smart folder, but rather the active directory
      // or whichever directory the user is dragging file(s) into. The user always
      // has to confirm uploads into smart folders when using this module.
      let preselectedFolderIds;
      if(!this.wasParentFolderPredefined) {
        // must reset parentFolderId before each folder selection in a multi-file upload
        this.parentFolderId = null;
      }
      if(typeof this.parentFolderId === 'string' && !forceFolderSelection
          && (preselectedFolderIds = this.getPreselectedUploadFolders(file, object)).length === 1) {
        try {
          this.selectedFolders = [{
            id: preselectedFolderIds[0],
            name: this.folderCollection.find(f => f.id == preselectedFolderIds[0]).text
          }];
          this.changeParentFolder();
          $('#folderTreeContainer').remove();
          return Promise.resolve(true);
        } catch(e1) {
          console.error(e1);
        }
      }
      let self = this;
      return new Promise(resolve => {
        swal({
          title: i18n.t("js.file.destination"),
          html: '<div id="folderTreeContainer"></div>',
          input: 'text',
          inputClass: 'hidden-input', //used to prevent focus error on reject
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("button.confirm"),
          showCancelButton: true,
          cancelButtonText: i18n.t("button.cancel"),
          showCloseButton: true,
          animation: true,
          showLoaderOnConfirm: false,
          useRejections: true, //important for swal2 v7.1.2
          expectRejections: true,
          allowOutsideClick: () => !swal.isLoading(),
          onOpen: function() {
            self.generateFolderTree(document.getElementById('folderTreeContainer'),
              allowMultiSelect, () => self.preSelectFolders(file, object));
          },
          preConfirm: function(text) {
            return new Promise(function(done, reject) {
              if(!self.selectedFolders.length) {
                reject(i18n.t("js.file.wtmrk.reject.selection"));
                return;
              }
              self.changeParentFolder();
              done();
            });
          }
        }).then(() => {
          $('#folderTreeContainer').remove();
          resolve(true);
        }, () => {
          $('#folderTreeContainer').remove();
          resolve(false);
        });
      });
    }

    parseScript(fileId, fileName, desc, episode, version) {
      let self = this;

      var tmp = Object();
      tmp.episode = episode;
      tmp.color = version;//.toLowerCase();
      tmp.scriptName = fileName;
      tmp.fileId = fileId;
      tmp.description = desc;
      tmp.tenantId = self.tenantId;

        apicall('scriptapi', 'parseScript', tmp, undefined, false).then(function (resp) {
        if(resp.responseCode && resp.responseCode === '-1') {
          tmp.responseMessage = resp.responseMessage;
          saveSystemActivity({
            action: 'submit',
            params: findSystemActivityParams(tmp),
            message: 'User failed to parsed a script from the upload page.'
          });
          if(resp.responseMessage === 'PDF unreadable') {
            let uniqueID = 'a' + new Date().getTime();
            cgToast(i18n.t("js.upload.unparsable",{scriptName:tmp.scriptName})+'&nbsp;<a class="more-details-msg '
            + 'no-text-select" id="'+uniqueID+'_link">'+i18n.t("js.upload.details")+'</a>',
            { duration: 3600000,  showCloseButton: true, id: uniqueID+'_toast' });
            $('#'+uniqueID+'_link').off('click');
            $('#'+uniqueID+'_link').on('click', function() {
              showUnreadablePDFDetails();
              $('#'+uniqueID+'_toast > div > span.cg-toast-close-btn').click();
            });
          } else {
            cgToast(i18n.t("js.upload.script.failed.parsing"), i18n.t("js.upload.script.failed.file"));
          }
        } else {
          let taskId = resp.entityId;

          let hideParsing = cgToast(i18n.t("js.upload.script.parsing", {scriptName: tmp.scriptName}), {
            containerID: 'docBody',
            className: 'body-attached',
            hiding: 'manual'
          });

          window.nbOfParsingScripts = (window.nbOfParsingScripts || 0) + 1;
          waitForParsingTaskCompletion().then(() => {
            hideParsing();
            window.nbOfParsingScripts--;
            if(window.croogloocurrentpage.securityPageName === 'sidesGeneration') {
              document.getElementById('scriptListUpdateBtn').click();
            }
          }).catch(err => {
            console.error(err);
            hideParsing(); // hide the toast since we don't know if it's still parsing or not
          });

          saveSystemActivity({
            action: 'submit',
            params: findSystemActivityParams(tmp),
            message: 'User parsed a script from the upload page.'
          });

          function waitForParsingTaskCompletion() {
            let maxNbOfChecks = 75;
            let nbOfChecks = 0;
            return new Promise((resolve, reject) => {
              checkParsingTaskCompletion(resolve, reject);
            });

            function checkParsingTaskCompletion(resolve, reject) {
              console.debug(`checking script parsing task completion for ${taskId}`);
              apicall('tasksapi', 'checkScriptParsingTaskCompletion', { taskId: taskId }, undefined, false).then(resp => {
                if(resp.responseCode !== '0') {
                  throw new Error('server error');
                }
                if(resp.responseMessage == 'done') {
                  resolve();
                } else if(++nbOfChecks > maxNbOfChecks) {
                  console.error(`checked script parsing task over ${maxNbOfChecks.toString()} times for ${taskId}`)
                  throw new Exception('checked script parsing task too many times');
                } else {
                  console.debug(`checking script parsing task again in a few seconds for ${taskId}`);
                  setTimeout(() => checkParsingTaskCompletion(resolve, reject), 8000); // check again in 8 seconds
                }
              }).catch(err => {
                console.error('failed to wait for parsing task completion');
                reject(i18n.t("js.utils.server.error"));
              });
            }
          }
        }
      });

      function showUnreadablePDFDetails() {
        swal({
          title: i18n.t("js.upload.pdf.unparsable.title"),
          text: i18n.t("js.upload.pdf.unparsable.text"),
          type: 'info',
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("OK"),
          onOpen: function() {
            swal.getContent().className += ' swal2-justified';
          }
        });
      }
    }

    validKey(key) {
      var self = this;
      if (key === 'Enter') {
        return false; //important
      }
      var isValid = !isNaN(key) || key === 'Backspace' || /Arrow/.exec(key) !== null;
      if (!isValid && !self.isInvalidKeyToastShowing) {
        self.isInvalidKeyToastShowing = true;
        cgToast(i18n.t("js.utils.reject.number.only"), 3000);
        setTimeout(function() {
          self.isInvalidKeyToastShowing = false
        }, 3000);
      }
      return isValid;
    }

    /********* FOLDER SELECTION *********/

    initFolderTree(done){
      this.folderCollection.push({"id": "ROOT", "parent": "#", "text": "Documents", 'state' : { 'opened' : true }});
      this.fetchAllFolders(done);
    }

    fetchAllFolders(done) {
      let self = this;
      let urlParams = { tenantId: self.tenantId };
      if(this.sharingToken !== null) {
        urlParams.token = this.sharingToken;
      }
      apicall('documentsapi', 'fetchAllFolders', urlParams).then(resp => {
        if(typeof resp.items !== 'object' || resp.items.length && typeof resp.items[0].properties !== 'object') {
          console.error('failed to fetch folders');
          done();
          return;
        }

        let allFolderIds = [];
        resp.items.forEach(folder => {
          try {
            folder.properties.id = folder.properties.id.replace(/%20/g, ' ');
            folder.properties.parentFolder = folder.properties.parentFolder.replace(/%20/g, ' ');
            folder.properties.parents.value = folder.properties.parents.value.replace(/%20/g, ' ');
          } catch(e) {
            console.debug(e);
          }
          allFolderIds.push(folder.properties.id);
        });

        // TODO move to docUtility to avoid duplicated code on compose.js
        let validCollection;
        do { // we assume there's no circular dependencies
          validCollection = true;
          for(let i = 0; i < resp.items.length; i++) {
            let testedFolderId = null;
            try {
              testedFolderId = resp.items[i].key.name;
              let folderProps = resp.items[i].properties;
              let parentId = (typeof folderProps.parents == 'object' && folderProps.parents.value)
                ?folderProps.parents.value.split(',')[0] :folderProps.parentFolder;
              if((!parentId || !allFolderIds.includes(parentId)) && folderProps.id !== 'ROOT' || !folderProps.fileName) {
                throw 'Invalid folder: ' + folderProps.id;
              }
            } catch(e) {
              // removing the folders from the list of entities that will be used
              // to generate the JS tree
              resp.items.splice(i, 1);
              if(testedFolderId !== null) {
                // must remove the folder from the allFolderIds list so that its children
                // folders are not considered as valid, creating a bug in the JS tree
                // generation process
                let testedFolderIdIndex = allFolderIds.findIndex(fId => fId === testedFolderId);
                if(testedFolderIdIndex !== -1) {
                  allFolderIds.splice(testedFolderIdIndex, 1);
                }
              }
              validCollection = false;
              console.error(e);
              break;
            }
          }
        } while(!validCollection);

        resp.items.forEach(folder => {
          try {
            let folderProps = folder.properties;
            let parentId = (typeof folderProps.parents == 'object' && folderProps.parents.value)
              ?folderProps.parents.value.split(',')[0] :folderProps.parentFolder;

            if(parentId && folderProps.id !== 'ROOT') {
              self.folderCollection.push({
                id: folderProps.id,
                parent: parentId,
                text: folderProps.fileName,
                icon: folderProps.fileName.endsWith('.zip') ?"icon-zip" :"jstree-folder"
              });
              if(folderProps.isSmartFolder) {
                self.smartFolders.push(folderProps);
              }
            }
          } catch(e) {
            console.error(e);
          }
        });
        done();
      });
    }

    generateFolderTree(container, allowMultiSelect = true, onload) {
      let self = this;
      self.selectedFolders = [];
      let div = document.createElement('div');
      div.id = 'folderTree';
      container.appendChild(div);
      console.debug('allowMultiSelect: ', allowMultiSelect);
      $(div).jstree({
        'core': {
          'data': self.folderCollection,
          'multiple': allowMultiSelect
        },
        sort: function(a, b) {
          try {
            return a.replace(/%20/g,' ').localeCompare(b.replace(/%20/g, ' '));
          } catch(e) {
            return 0;
          }
        },
        checkbox: {
          three_state : false, // prevent checking children
          whole_node : true,
          tie_selection : true
        },
        "plugins": ["checkbox", "sort"]
      }).on('changed.jstree', function (e, data) {
        var i, j;
        self.selectedFolders = [];
        for(i = 0, j = data.selected.length; i < j; i++) {
          let node = data.instance.get_node(data.selected[i]);
          self.selectedFolders.push({
            id: node.id,
            name : node.text,
            depth: node.parents.length
          });
        }
        self.selectedFolders.sort((f1, f2) => {
          return (f1.depth - f2.depth) || f1.name.localeCompare(f2.name);
        });
      }).on('loaded.jstree', onload || function(){});
    }

    preSelectFolders(file, fileProps) {
      let selectedFolderIds = this.getPreselectedUploadFolders(file, fileProps);
      for(let i = 0; i < selectedFolderIds.length; i++) {
        try {
          $('#folderTree').jstree('select_node', selectedFolderIds[i]);
        } catch(e) {
          console.error(e);
        }
      }
    }

    getPreselectedUploadFolders(file, fileProps) {
      let self = this;
      if(typeof self.selectedFolderIds !== 'string' || self.selectedFolderIds.includes(',')) {
        self.selectedFolderIds = null;
      }
      let selectedFolderIds = SmartFolder.getMatchingFolderIds(file, fileProps,
        self.smartFolders, typeof self.parentFolderId === 'string' ?self.parentFolderId :'ROOT');
      if(typeof self.parentFolderId === 'string' && !selectedFolderIds.includes(self.parentFolderId)) {
        selectedFolderIds.push(self.parentFolderId);
      }
      return selectedFolderIds;
    }

    // DEPRECATED
    getJsTreeHTML() {
      return $('#js-tree').html();
    }

    // DEPRECATED
    showSelectFolder() {
      $('#js-tree').toggle();
      $('.swal2-modal.swal2-show').toggle();
    }

    changeParentFolder() {
       let folderIds = [], folderNames = [];
       if(!this.selectedFolders.length) {
         this.parentFolderId = 'ROOT';//selectedFolder.id;
         this.parentFolderName = 'Documents';//selectedFolder.name;
       } else {
         this.selectedFolders.forEach(folder => {
           folderIds.push(folder.id);
           folderNames.push(folder.name);
         });
         this.parentFolderId = folderIds.join(',');
         this.parentFolderName = folderNames.join(', ');
       }
    }

    addJsTree() {
      var jsel = document.getElementById('js-tree');
      if (!jsel) {
        var newjsel = document.createElement('div');
        newjsel.id='js-tree';
        newjsel.style.display='none';
        newjsel.innerHTML = "<div class=\"folder-select-title\">"+
    			"<label style=\"font-size: 16px\">"+i18n.t("js.upload.folder.select")
    			"<a id=\"jstreeCloseBtn\" class=\"closer\">x</a></label>"+
    		"</div>"+
    		"<div class=\"tree-container\">"+
    			"<div id=\"js-tree-2\" class=\"js-tree-2\"></div>"+
    		"</div>"+
    		"<a id=\"js-tree-select\" class=\"button\">"+i18n.t("utils.select")+"</a>";
        var mainarea = document.getElementById('main');
        mainarea.appendChild(newjsel);
      }
    }

}
