import Notification from '../Notification.js';
import i18n from '../Translation';

export default class DownloadNotification {
  constructor() {
    
  }
  
  static load() {
    window.apicall('notificationapi', 'fetchVisibleNotifications', { 
      type: 'download' 
    }, undefined, false).then(resp => {
      if(Array.isArray(resp.items)) {
        for(let notification of resp.items) {
          DownloadNotification.add(notification.is_user_notified, 
            notification.is_browser_notif_sent || false, 
            notification.reference_id, null, notification.time_created);
        }
      } else {
        throw new Error('Invalid server response');
      }
    }).catch((err) => {
      console.error('Failed to load watermark notifications.', err);
    });
  }
  
  static add(isUserNotified, isBrowserNotifSent, referenceId, fileName = null, timeCreated) {
    let notifDiv = fileName === null 
      ?null 
      :DownloadNotification.build(fileName, i18n.t("js.notification.download.processing"), referenceId, timeCreated);
    DownloadNotification.trackProgress(referenceId, notifDiv, isUserNotified, isBrowserNotifSent, referenceId, timeCreated);
  }

  static build(title, content, referenceId, timeCreated) {
    let notifDiv = document.createElement('div');
    notifDiv.innerHTML = '<div class="wm-notif-title">'+(title||'')+'</div>' + '<div class="wm-notif-content">'+(content||'')+'</div>';
    window.cgNotification.addNotification(i18n.t("js.notification.download.title"), notifDiv, null, false, function() {
      window.apicall('notificationapi', 'updateNotificationVisibility', { referenceId: referenceId, isVisible: false }, undefined, false)
        .then(function(wsr) {
          if(wsr.responseCode !== '0') {
            throw new Error('server error');
          }
          // this notification will not be shown anymore
      }).catch(() => {
        console.error('notif visibility update failed');
      });
    }, timeCreated);
    return notifDiv;
  }

  static trackProgress(taskId, notifDiv, isUserNotified, isBrowserNotifSent, referenceId, timeCreated) {
    let isTempDocDownloadReady = false;
    let progressTracker = setInterval(checkProgress, 2500);

    function checkProgress() {
      console.debug('checking progress');
      apicall('documentsapi', 'isTempDocDownloadReady', { fileId: referenceId }, undefined, false).then(resp => {
        if(resp.responseCode == 0) {
          if(resp.responseMessage == 'true' && isTempDocDownloadReady === false) {
            isTempDocDownloadReady = true;
            clearInterval(progressTracker);
            apicall('documentsapi', 'fetchTempDocDownloadLink', { fileId: referenceId }, 
                undefined, false).then(innerResp => {
              if(notifDiv === null) {
                notifDiv = DownloadNotification.build(innerResp.contentName, '', referenceId, timeCreated);
              }
              if(innerResp.responseCode === '0') {
                let notifContent = notifDiv.querySelector('div.wm-notif-content');
                notifContent.innerHTML = `<a href="${innerResp.contentURL}">${i18n.t("js.notification.download.click")}</a>`;
                if(!isUserNotified) {
                  window.cgNotification.addCounter(1, referenceId);
                  if(!isBrowserNotifSent) {
                    Notification.showBrowserNotification(i18n.t("js.notification.download.ready"), 
                      innerResp.contentName, innerResp.contentURL, referenceId);
                  }
                }
              } else {
                throw new Error('server error');
              }
            }).catch(err => {
              console.error('server error fetching download link');
              throw new Error(err);
            });
          } else {
            console.debug('waiting for temp doc ' + referenceId + ' to be ready');
          }
        } else {
          throw new Error('server error');
        }
      }).catch(() => {
        clearInterval(progressTracker);
        console.error('failed to track download process');
        if(notifDiv !== null) {
          notifDiv.querySelector('div.wm-notif-content').innerHTML = '<span style="color:red">'
            +i18n.t("js.notification.download.failed")
            +'</span>';
        }
      });
    }
  } 
}