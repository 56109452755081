export function init() {
  loader.add(ui, 'ui')
        .add(initGlobals, 'globals')
        .add(initListeners, 'listeners')
        .execute();
}

function ui() {
    $('#main').foundation();
    loader.check('ui')
  }

function initGlobals() {
	loader.check('globals')
}

function initListeners() {
    loader.check('listeners')
}