import i18n from '../components/Translation';
import DocUtility from '../components/DocUtility';
import Page from './page.js';
import SecureLS from 'secure-ls';

export class Auth {
  constructor(){
    this.loginListeners = [];
    this.crooglooauth = null;
  }
  validate() {
    let that = this;
    return new Promise(resolve => {
      DocUtility.clearStoredFiles();
      var storedcrooglooauth;
      let isCrooglooAuthStored = secureLocalStorage.getAllKeys().includes('crooglooauth');
      console.debug('isCrooglooAuthStored: ', isCrooglooAuthStored, secureLocalStorage.getAllKeys());
      try {
        let securedAuthJSON = secureLocalStorage.get('crooglooauth');
        if(securedAuthJSON) {
          storedcrooglooauth = JSON.parse(securedAuthJSON);
        } else {
          secureLocalStorage.remove('crooglooauth');
        }
      } catch(e) {
        console.error(e);
        if(isCrooglooAuthStored) { // temporary backward compatibility block for SecureLS
          try {
            // try parsing data with the previous version's storage's encryption
            let deprecatedLS = new SecureLS();
            let securedAuthJSON = deprecatedLS.get('crooglooauth');
            if(securedAuthJSON) {
              storedcrooglooauth = JSON.parse(securedAuthJSON);
              deprecatedLS.clear(); // must remove any incompatible data
              secureLocalStorage.set('crooglooauth', securedAuthJSON);
              console.debug('updated crooglooauth storage encryption');
            } else {
              secureLocalStorage.remove('crooglooauth');
            }    
          } catch(e2) {
            console.error(e2);
            secureLocalStorage.remove("crooglooauth");
          }
        } else {
          secureLocalStorage.remove("crooglooauth");
        }
      }
      if (storedcrooglooauth && storedcrooglooauth.authenticationToken && storedcrooglooauth.sms2fa == '0') {
        setPropertiesFromAuthObj(storedcrooglooauth);
        resolve();
      } else {
        if(sessionStorage.getItem('tmpUsername') == null && (!storedcrooglooauth
            || !storedcrooglooauth.authenticationToken
            || storedcrooglooauth.sms2fa == '0')) {
          console.debug('tmpUsername not found in sessionStorage - logging out');
          if(sessionStorage.getItem('sharing_id') != null) {
            that.retrieveAccessLink().then(resolve).catch(err => {
              console.error(err);
              that.logout();
            });
          } else {
            that.logout();
          }
          return;
        } else {
          sessionStorage.removeItem('tmpUsername');
        }
        console.debug('stored auth not found - using AuthenticationService');
        showInitialLoadingScreen();
        $.ajax({
          url: devEndpoint + "/AuthenticationService",
          type: "post",
          crossDomain: true,
          success: function(resp) {
            try {
                let authObj = resp;
              console.debug('auth obj resp:', authObj);
              setPropertiesFromAuthObj(authObj);
              if(authObj.responseCode != '0') {
                console.error('invalid server response to authentication service');
                that.logout();
                return;
              }
              if(!authObj.communitiesAndAccess[authObj.defaultCommunity]) {
                console.debug('user does not have access to production');
                handleNoAccess(authObj);
              } else {
                  secureLocalStorage.set("crooglooauth", JSON.stringify(resp));
                let storedSharingId = sessionStorage.getItem('sharing_id');
                sessionStorage.clear();
                if(storedSharingId !== null) {
                  sessionStorage.setItem('sharing_id', storedSharingId);
                }
                console.debug('cleared session storage except for sharing_id');
                if(authObj.sms2fa == '1') {
                  if(authObj.mobile && authObj.mobile.length > 4) {
                    let end = Date.now() + (5*60*1000);
                    let now = Date.now();
                    let r = Math.floor((end-now)/1000);
                    let timeinterval = setInterval(()=>{
                      let sec = Math.floor((r)%60);
                      let min = Math.floor((r)/60);
                      let countdowndom = document.getElementById('countdown');
                      if (countdowndom)
                      if (min>0 || sec>0) countdowndom.innerText = (min<10?'0'+min:min)+":"+(sec<10?'0'+sec:sec);
                      else {
                        countdowndom.innerText = "Timeout!";
                        setTimeout(() => {
                          handleNoAccess(authObj);
                        }, 5000);
                        clearInterval(timeinterval);
                      }
                      r--;
                    },1000);
                    let hiddenNb = "****" + authObj.mobile
                      .substring(authObj.mobile.length - 4, authObj.mobile.length);
                    $.ajax({
                      url: devEndpoint+"/Sms2faServlet",
                      type: "post",
                      async: false,
                      crossDomain: true,
                      data: {
                        mobile: authObj.mobile,
                        xsrf: "8f869fd8b51bcdd4173168f17986d499f8e70ba9"
                      },
                      success: (codeSendResp) => {
                        if (codeSendResp === '0') {
                          swal({//COMEHERE
                            title: i18n.t("2fa.title"),
                            html: '<p>'+i18n.t("js.login.2fa.text", {hiddenNb})+'</p><span id="countdown"></span>',
                            input: 'text',
                            inputPlaceholder: i18n.t("2fa.input.sms"),
                            animation: "slide-from-top",
                            showLoaderOnConfirm: true,
                            showCancelButton: true,
                            cancelButtonText: i18n.t("int.usr-opts.swt-prod"),
                            confirmButtonText: i18n.t("button.submit"),
                            preConfirm: (code)=>{
                              return new Promise((accept,reject)=>{
                                if (code.length == 5 && !isNaN(code)) {
                                  $.ajax({
                                    url: devEndpoint+"/Sms2faServlet",
                                    type: "get",
                                    async: false,
                                    crossDomain: true,
                                    data: {
                                      code: code,
                                      xsrf: "8f869fd8b51bcdd4173168f17986d499f8e70ba9"
                                    },
                                    success: (codeValidationResp) => {
                                      if (codeValidationResp === '0') {
                                        authObj.sms2fa = '0';
                                          secureLocalStorage.set("crooglooauth", JSON.stringify(authObj));
                                        accept();
                                      } else {
                                        reject(i18n.t("js.login.2fa.reject"));
                                      }
                                    },
                                    error: (xhr, status, error) => {
                                      console.error(xhr,status,error);
                                      reject(i18n.t("js.login.2fa.reject"));
                                    }
                                  });
                                } else {
                                  reject(i18n.t("js.login.2fa.reject"));
                                }
                              });
                            }
                          }).then(resolve).catch(() => handleNoAccess(authObj));
                        } else {
                          handleNoAccess(authObj);
                        }
                      },
                      error: (xhr,status,error) => {
                        console.error(xhr,status,error);
                        handleNoAccess(authObj);
                      }
                    });
                  } else {
                    let defaultProdTitle = authObj.communitiesIdNameMap[authObj.defaultCommunity] || authObj.defaultCommunity;
                    swal({
                      type: 'warning',
                      title: i18n.t("js.login.2fa.denied.title"),
                      html: i18n.t("js.login.2fa.denied.text", {production: `<b>${defaultProdTitle}</b>`}),
                    }).then(() => handleNoAccess(authObj))
                    .catch(() => handleNoAccess(authObj));
                  }
                } else {
                  resolve();
                }
              }
            } catch(e) {
              console.error(e);
              that.logout();
            }
          },
          error: function(err) {
            that.logout();
          }
        });
      }
      global.handleNoAccess = function(authObj) {
        let accessibleCommunities = [];
        for(let communityID in authObj.communitiesAndAccess) {
          if(authObj.communitiesAndAccess[communityID] && communityID != authObj.defaultCommunity) {
            accessibleCommunities.push(communityID);
          }
        }
        if(!accessibleCommunities.length) {
          console.debug('user does not have access to any community');
          informNoAccessAndLogout();
        } else {
          console.debug('user has access to at least one community');
          showCommunitySwitchAlert(authObj);
        }
      }
      function setPropertiesFromAuthObj(authObj) {
        that.token = authObj.authenticationToken;
        that.usertoken = authObj.token;
        that.tenantId = authObj.defaultCommunity;
        that.securityProfile = authObj.securityProfile;
        that.isSharedAccess = authObj.isSharedAccess || false;
        that.crooglooauth = authObj;
      }
      function informNoAccessAndLogout() {
        swal({
          type: 'warning',
          title: i18n.t("js.login.no-access.title"),
          html: i18n.t("js.login.no-access.text"),
          allowOutsideClick: false,
          showConfirmButton: false,
          showCloseButton: false,
          showCancelButton: false,
          timer: 15000,
          useRejections: false,
          expectRejections: false,
        }).then(() => that.logout()).catch(() => that.logout());
      }

      function showCommunitySwitchAlert(authObj) {
        let defaultProdTitle = authObj.communitiesIdNameMap[authObj.defaultCommunity]
          || authObj.defaultCommunity;
        swal({
          title: i18n.t("int.usr-opts.swt-prod"),
          html: i18n.t("js.login.community-switch.alert", {production: `<b>${defaultProdTitle}</b>`})
           + '<br><br>' + communitySelectHTML(),
          type: 'info',
          confirmButtonText: i18n.t("utils.select"),
          showCancelButton: true,
          cancelButtonText: i18n.t("int.usr-opts.logout"),
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise((resolve, reject) => {
              let selectedCommunityId =
                document.getElementById('communitySwitchSelect').value;
              if(!selectedCommunityId || !selectedCommunityId.trim()) {
                reject(i18n.t("js.sub.switch.reject"));
                return;
              }
              $.ajax({
                url: devEndpoint + "/CommunitySwitchServlet",
                type: "post",
                crossDomain: true,
                data: {
                  token: authObj.token,
                  tenantId: authObj.defaultCommunity,
                  toCommunity: selectedCommunityId
                },
                success: function(resp) {
                  try {
                    let partialAuth = resp;
                    console.debug('partialAuth', partialAuth);

                    if(partialAuth.responseCode != '0') {
                      reject(i18n.t("js.sub.switch.denied", {community: (authObj.communitiesIdNameMap[selectedCommunityId]||selectedCommunityId)}));
                      return;
                    }

                    authObj.securityProfile = partialAuth.securityProfile;
                    authObj.sms2fa = partialAuth.sms2fa;
                    authObj.defaultCommunity = selectedCommunityId;

                    secureLocalStorage.set("crooglooauth", JSON.stringify(authObj));
                    window.location.reload();
                  } catch(e) {
                    console.error(e);
                    that.logout();
                  }
                }, error: function(err) {
                  console.error(err);
                  that.logout();
                }
              });
            });
          }
        }).catch(() => that.logout());
        function communitySelectHTML() {
          let communitySelect = document.createElement('select');
          communitySelect.id = 'communitySwitchSelect';
          let sortedCommunities = Object.keys(authObj.communitiesAndAccess).sort();
          for(let communityID of sortedCommunities) {
            try {
              if(authObj.communitiesAndAccess[communityID] &&
                  communityID != authObj.defaultCommunity) {
                let communityOpt = document.createElement('option');
                communityOpt.textContent = authObj.communitiesIdNameMap[communityID] || communityID;
                communityOpt.value = communityID;
                communitySelect.appendChild(communityOpt);
              }
            } catch(e) {
              console.error(e);
            }
          }
          return communitySelect.outerHTML;
        }
      }
    });
  }
  retrieveAccessLink() {
    console.debug('retrieving access link');
    let that = this;
    if(sessionStorage.getItem('sharing_id') == null) {
      return Promise.reject();
    }
    try {
      if(secureLocalStorage.get('accessLink') != '' && JSON.parse(secureLocalStorage.get('accessLink')).id == sessionStorage.getItem('sharing_id')) {
        let accessLink = JSON.parse(secureLocalStorage.get('accessLink'));
        if(!that.isAuthenticated(accessLink.id)) {
          let crooglooauth = buildAuthObjFromAccessLink(accessLink);
          setPropertiesFromAuthObj(crooglooauth);
          secureLocalStorage.set('crooglooauth', JSON.stringify(crooglooauth));
        }
        return Promise.resolve(accessLink);
      }
    } catch(e) {
      console.error(e);
      secureLocalStorage.remove('accessLink');
    }
    return new Promise((resolve, reject) => {
      $.ajax({
        url: devEndpoint + "/RetrieveAccessLink",
        type: "get",
        crossDomain: true,
        data: {
          linkId: sessionStorage.getItem('sharing_id')
        },
        success: function(resp) {
          let accessLink = JSON.parse(resp);
          if(accessLink.responseCode == '0') {
            console.debug('successfully retrieved access link');
            secureLocalStorage.set('accessLink', resp);
            if(!that.isAuthenticated(accessLink.id)) {
              let crooglooauth = buildAuthObjFromAccessLink(accessLink);
              setPropertiesFromAuthObj(crooglooauth);
              secureLocalStorage.set('crooglooauth', JSON.stringify(crooglooauth));
            }
            resolve(accessLink);
          } else {
            console.error('invalid access link response code ' + accessLink.responseCode);
            reject();
          }
        },
        error: (xhr, status, error) => {
          console.error(xhr, status, error);
          reject();
        }
      });
    });
    function setPropertiesFromAuthObj(authObj) {
      that.token = authObj.authenticationToken;
      that.usertoken = authObj.token;
      that.tenantId = authObj.defaultCommunity;
      that.securityProfile = authObj.securityProfile;
      that.isSharedAccess = authObj.isSharedAccess;
      that.crooglooauth = authObj;
    }
    function buildAuthObjFromAccessLink(accessLink) {
      let authObj = {
        authenticationToken: 'accesslink',
        token: accessLink.userToken,
        defaultCommunity: accessLink.tenantId,
        urlEndpoint: accessLink.urlEndpoint,
        sms2fa: '0',
        isSharedAccess: true,
        sharingLinkId: accessLink.id,
        securityProfile: {}
      };
      authObj.securityProfile[accessLink.tenantId] = {};
      return authObj;
    }
  }
  isAuthenticated(expectedLinkId = null) {
    try {
      let crooglooauthJSON = secureLocalStorage.get("crooglooauth");
      if(crooglooauthJSON != '') {
        var crooglooauth = JSON.parse(crooglooauthJSON);
        if (crooglooauth && crooglooauth.authenticationToken) {
          if(crooglooauth.isSharedAccess && expectedLinkId !== null
            && crooglooauth.sharingLinkId !== expectedLinkId) {
            return false;
          }
          return true;
        }
      }
      return false;
    } catch(e) {
      console.error(e);
      return false;
    }
  }
    login(callback) {
        var crooglooauth = JSON.parse(secureLocalStorage.get("crooglooauth"));
    if (crooglooauth) {
      this.token = crooglooauth.authenticationToken;
      this.usertoken = crooglooauth.token;
      this.tenantId = crooglooauth.defaultCommunity;
      this.securityProfile = crooglooauth.securityProfile;
      console.debug('login success');
      for (var idx in this.loginListeners){
        var f = this.loginListeners[idx];
        f(this);
      }
      this.setupIdleTimout();
      if (typeof callback === 'function') {
        callback();
      }
    }
  }
  setupIdleTimout() {
    let that = this;
    var idleTime = 0;
    $(document).ready(function () {
      var idleInterval = setInterval(timerIncrement, 60000);

      $(this).mousemove(function (e) {
        idleTime = 0;
      });

      $(this).keypress(function (e) {
        idleTime = 0;
      });
    });

    const MAX_IDLE_MINS = 20; // NOTE: must also update related text in security settings if we change that
    function timerIncrement() {
      idleTime = idleTime + 1;
      if (idleTime >= MAX_IDLE_MINS) {
        try {
          handleInactivity();
        } catch(e) {
          console.error(e);
          idleTime = 0; // must reset to avoid sending a request every time the 
                        // timer is incremented if the error keeps happening
        }
      }
    }

    function checkInactivityTimeoutSetting(done) {
      let isInactivityTimeoutEnabled = true; // true by default
      try {
        window.apicall('productionapi', 'fetchUserSetting', {
          settingName: 'isInactivityTimeoutEnabled'
        }).then(resp => {
          if(resp.responseCode === '0') {
            // true unless explicitly false for maximum security
            isInactivityTimeoutEnabled = resp.responseMessage !== 'false';
          } else {
            throw new Error('invalid server response');
          }
        }).catch(() => {
          console.error('invalid server response to inactivity timeout setting');
        }).then(() => done(isInactivityTimeoutEnabled));
      } catch(err1) {
        console.error(err1);
        done(isInactivityTimeoutEnabled);
      }
    }

    function handleInactivity() {
      idleTime = 0; // prevent multiple calls to retrieve the user setting and 
      // make sure we only check again in MAX_IDLE_MINS if the setting is currently 
      // that the user should not be logged out
      console.debug('checking inactivity timeout setting');
      checkInactivityTimeoutSetting(isInactivityTimeoutEnabled => {
        console.debug('isInactivityTimeoutEnabled: ', isInactivityTimeoutEnabled);
        if(isInactivityTimeoutEnabled) {
          swal({//COMEHERE
            title: i18n.t("js.login.inactivity.title"),
            html: i18n.t("js.login.inactivity.html", {MAX_IDLE_MINS}),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#13C46A',
            confirmButtonText: i18n.t("js.login.inactivity.option.logout"),
            cancelButtonText: i18n.t("js.login.inactivity.option.stay"),
            allowOutsideClick: false,
            timer: 30000
          }).then(
            function () {
              that.logout(true);
            },
            function(dismiss) {
              if(dismiss === 'timer') {
                sessionStorage.setItem('logout_reason', 'inactivity_timeout');
                that.logout(true);
              }
            }
          ).catch(swal.noop);
        }
      });
    }
  }
  logout(isInactivityTimeout = false, isUserAction = false, nextPageLogin = null) {
    console.debug('logging out from login.js');
    let that = this;
    try {
      window.isLoggingOut = true;
      if(this.token) {
        let userToken = this.usertoken;
        let authToken = this.token;
        clearLoginData();
        if(typeof window.showSpinner == 'function') {
          window.showSpinner();
        }
        $.ajax({
          url: devEndpoint + "/logoutservice",
          type: "post",
          crossDomain: true,
          async: true,
          data: {
            token: userToken,
            authToken: authToken,
            isInactivityTimeout: isInactivityTimeout.toString(),
            xsrf: "8f869fd8b51bcdd4173168f17986d499f8e70ba9"
          },
          success: (resp) => {
            console.debug('logout successful', resp);
            goToLoginPage();
          },
          error: (xhr,status,error) => {
            console.error(xhr, status, error);
            console.error('logoutservice server error');
            goToLoginPage();
          }
        });
      } else {
        clearLoginData();
        goToLoginPage();
      }
    } catch(err) {
      console.error(err);
      goToLoginPage();
    }
    function clearLoginData() {
      secureLocalStorage.remove("crooglooauth");
      let logoutReason = null;
      if(sessionStorage.getItem('logout_reason')) {
        logoutReason = sessionStorage.getItem('logout_reason');
      }
      sessionStorage.clear();
      if(logoutReason) {
        sessionStorage.setItem('logout_reason', logoutReason);
      }
      that.token = null;
      that.tenantId = null;
      that.usertoken = null;
    }
    function goToLoginPage() {
      if(typeof window.hideSpinner == 'function') {
        window.hideSpinner();
      }
      let homePageURL = 'login.html';
      if(nextPageLogin != null && typeof nextPageLogin == 'string'){
        homePageURL += '#' + nextPageLogin;
      }else{
        if(location.hash && !isUserAction) {
          try {
            homePageURL += location.hash;
          } catch(err1) {
            console.error(err1);
          }
        }
      }
      window.location.href = homePageURL;
    }
  }
  dropbox(authobj) {
    var self = this;
    authenticatedcall('/DropboxAuth?token='+self.usertoken+'&dropbox_accesstoken='+
            authobj.access_token+'&dropbox_tokentype='+authobj.token_type+'&dropbox_uid='+
            authobj.uid+'&dropbox_accountid='+authobj.account_id,'POST').then((resp)=>{
      self.crooglooauth.dropboxToken = authobj.access_token;
      secureLocalStorage.set("crooglooauth", JSON.stringify(self.crooglooauth));
      if(typeof croogloocurrentpage.onDropboxLogin === 'function') {
        croogloocurrentpage.onDropboxLogin();
      }
      console.debug('DropboxAuth resp', resp);
    },(err) => {
      console.error(err);
    });
  }
  dropboxunlink() {
    var self = this;
    return new Promise((accept,reject)=>{
      authenticatedcall('/DropboxAuth?token='+self.usertoken,'DELETE').then((resp)=>{
        self.crooglooauth.dropboxToken = null;
        secureLocalStorage.set("crooglooauth", JSON.stringify(self.crooglooauth));
        accept();
      },(err)=>{
        reject(err);
      });
    });
  }

  async box(code, refresh=false) {
    var self = this;
    const authenticationUrl = 'https://api.box.com/oauth2/token'
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("client_id", "5rncboriuq6806ijnchkhs9r5j6k757f");
    urlencoded.append("client_secret", "kEdkl7s50RbQYj0C0uFE5xuwlavKJcVe");
    urlencoded.append(refresh ? "refresh_token" : "code", code);
    urlencoded.append("grant_type", refresh ? "refresh_token" : "authorization_code");
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch(authenticationUrl, requestOptions)
    .then(response => response.text())
    .then(result => {
      result = JSON.parse(result)
      self.crooglooauth.boxToken = result.access_token;
      self.crooglooauth.boxRefreshToken = result.refresh_token
      self.crooglooauth.boxTokenExpiry = result.expires_in
      secureLocalStorage.set("crooglooauth", JSON.stringify(self.crooglooauth));

      if($('.swal2-container').is(":visible")) {
        $('.swal2-confirm.swal2-styled').css('background-color', 'rgb(19, 196, 106)')
      }

      if(typeof croogloocurrentpage.onBoxLogin === 'function') {
        croogloocurrentpage.onBoxLogin();
      }
    })
    .catch(error => console.log('error', error));
  }
  
  boxunlink() {
    var self = this;
    return new Promise((accept)=>{
      self.crooglooauth.boxToken = null;
      self.crooglooauth.boxRefreshToken = null
      secureLocalStorage.set("crooglooauth", JSON.stringify(self.crooglooauth));
      accept();
    });
  }

  addLoginListener(f){
    this.loginListeners.push(f);
  }
}
export class Login extends Page {
  constructor(auth) {
    super('login', null);
    this.croogloo_auth = auth;
    this.initFunction = ()=>{
      var btn = document.querySelector('#signinButton');
      var ca = this.croogloo_auth;
      btn.addEventListener('click', function(e){
        ca.login(()=>{
          var url = location.href;
          window.location = "#home";
        });
      });
    }
  }
}
