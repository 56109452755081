import i18n from './Translation';
import './Notification.css';
/**
* This component is avaliable in window.cgNotification
* To add a notification use: cgNotification.addNotification(title,content,action)
* where: (title: string)(content: string or HTMLElement)(action: Event to be clicked)
*/
export default class Notification {
  constructor(counterid,panelid) {
    var self = this;
    this.countid = counterid;
    this.counterbadge = document.getElementById(this.countid);
    this.counterbadge.onclick = (e)=>self.showmenu();
    this.counterspan = this.counterbadge.querySelector("span");
    this.panelid = panelid;
    this.panel = document.getElementById(this.panelid);
    this.counter = 0;
    this.notificationlist = [];
    this.unseenReferences = [];
  }

  addCounter(n = 1, referenceId = null) {
    if(referenceId !== null) {
      if(!this.unseenReferences
        .filter(r => r.referenceId == referenceId).length) {
        this.counter += n;
        this.unseenReferences.push({
          referenceId: referenceId,
          isSaved: false
        });
      }
    } else {
      this.counter += n;
    }
    this.updateCounter();
  }

  updateCounter() {
    if (this.counter > 0) {
      this.counterspan.innerText = this.counter;
      this.counterspan.style.visibility="visible";
    } else {
      this.counterspan.style.visibility="hidden";
    }
  }

  showmenu() {
    this.counter = 0;
    this.updatePersistentNotifStatus()
    this.updateCounter();
    this.counterspan.style.visibility="hidden";
    if (this.notificationlist.length==0) {
      $(this.panel).empty();
      this.panel.innerHTML="<p class=\"empty\">"+i18n.t("js.notification.empty")+"</p>";
    }
  }

  updatePersistentNotifStatus() {
    for(let unseenReference of this.unseenReferences.filter(r => r.isSaved === false)) {
      window.apicall('notificationapi', 'updateNotificationStatus', 
      { referenceId: unseenReference.referenceId, isUserNotified: true }, 
      undefined, false).then(function(wsr) {
        if(wsr.responseCode !== '0') {
          throw new Error('server error');
        } else {
          unseenReference.isSaved = true;
          // alerts won't be shown anymore for this notification
        }
      }).catch(() => {
        console.error('notif status update failed');
      });
    }
  } 
  addNotification(title, content, action, incrementCounter = true, onremove = null, timeCreated = new Date().getTime()) {   
    var self = this;
    if (self.notificationlist.length==0) {
      $(self.panel).empty();
      var cleanall=document.createElement('p');
      cleanall.innerText=i18n.t("js.notification.clean");
      cleanall.classList.add("empty");
      cleanall.onclick = (e)=>{
        var idx = self.notificationlist.length-1;
        var item;
        while ((item = self.notificationlist[idx--])) {
          item.remove();
        }
      }
      self.panel.appendChild(cleanall);
    }
    if (incrementCounter) {
      self.addCounter();
    }
    var not = document.createElement('div');
    not.className = "notificationitem";
    not.setAttribute('time_created', timeCreated.toString());
    not.innerHTML = "<h1>"+title+"<i class=\"icon-exit\"></i></h1><div class=\"notif-content\"></div>";
    if (typeof content==='string') not.querySelector('div.notif-content').innerText=content;
    else not.querySelector('div.notif-content').appendChild(content);
    not.style.display = 'none';
    self.panel.insertBefore(not, this.panel.firstChild);
    let notList = document.querySelectorAll('div.notificationitem');
    let sortedNotList = [].slice.call(notList).sort((n1, n2) => {
      return parseInt(n2.getAttribute('time_created')) - parseInt(n1.getAttribute('time_created'));
    });
    for(let childNot of sortedNotList) {
      self.panel.insertBefore(childNot, this.panel.lastChild);
    }
    not.style.display = 'block';
    if (action) not.onclick = (event)=>{if (!event.target.classList.contains('icon-exit'))action();};
    not.querySelector('i').onclick = (e) => {
      not.classList.add('removing');
      setTimeout(()=>{
        $(not).remove();
        self.notificationlist.splice(self.notificationlist.indexOf(not),1);
        if (self.notificationlist.length==0) {
          $(self.panel).empty();
          self.panel.innerHTML="<p class=\"empty\">"+i18n.t("js.notification.empty")+"</p>";
        }
        if(typeof onremove === 'function') {
          onremove();
        }
      },500);
    }
    var _r = {htmlElement:not,remove:()=>{
      $(not.querySelector('i')).click();
      self.counter = self.counter==0?0:self.counter-1;
      self.updateCounter();
    }};
    self.notificationlist.push(_r);
    return _r;
  }

  static showBrowserNotification(title, body, url = null, referenceId = null) {
    if (!('Notification' in window)) {
      console.log('browser notifications not supported');
    }
    
    else if (window.Notification.permission === 'granted') {
      notify();
    }
    
    else if (window.Notification.permission !== 'denied') {
      window.Notification.requestPermission().then(function(permission) {
        if (permission === 'granted') {
          notify();
        }
      });
    }

    if(referenceId) {
      window.apicall('notificationapi', 'updateBrowserNotificationStatus', {
        referenceId: referenceId, 
        isBrowserNotifSent: true
      }, undefined, false).then(function(wsr) {
        if(wsr.responseCode !== '0') {
          throw new Error('server error');
        }
        console.debug('browser notification status updated');
      }).catch(() => {
        console.error('browser notification status update failed');
      });
    }

    function notify() {
      let options = {
        body: body,
        icon: global.IMG_DIRECTORY + 'icon_152.png' //'https://storage.googleapis.com/8810931_croogloo_public/croogloo-logo.jpg'
      };
      let notification = new window.Notification(title, options);
      notification.onclick = function(event) {
        if(url !== null) {
          event.preventDefault();
          window.location.href = url;
        }
      };
    }
  }

}
