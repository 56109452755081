export default class Trigger {
  /**
   * @param {string} description Trigger description.
   * @param {function} isConditionMet Function that returns true if a confition
   * is met, false otherwise.
   */
  constructor(description, isConditionMet) {
    this.description = description;
    this.isConditionMet = function() {
      try {
        return isConditionMet(...arguments);
      } catch(e) {
        console.error(e);
        return false;
      }
    };
  }
  toString() {
    return this.description;
  }
}
