import i18n from './Translation';
import DocUtility from './DocUtility';
import './UploadManager.css';

export default class UploadManager {
  constructor() {
    this.uploadBoxes = {};
    this.container = this.build();
    this.container.style.display = 'none';
    window.uploadManager = this;
  }

  add(fileId, fileName) {
    this.uploadBoxes[fileId] = new UploadBox(fileId, fileName, this.container);
    this.container.style.display = 'block';
    this.updateNbOfInProgressUploads();
  }

  remove(fileId) {
    this.uploadBoxes[fileId].box.remove();
    delete this.uploadBoxes[fileId];
    if(!Object.keys(this.uploadBoxes).length) {
      this.container.style.display = 'none';
    }
    this.updateNbOfInProgressUploads();
  }

  removeAll() {
    let allFileIds = Object.keys(this.uploadBoxes);
    for(let fileId of allFileIds) {
      this.remove(fileId);
    }
  }

  updateNbOfInProgressUploads() {
    let nbOfInProgressUploads = Object.values(this.uploadBoxes).filter(ub => !ub.isUploadCompleted).length;
    let headerText = this.container.querySelector('div.upload-manager-header > div.header-text');
    headerText.textContent = i18n.t("js.upload.upload", {count: nbOfInProgressUploads})
  }

  build() {
    if(document.getElementById('uploadManagerContainer') !== null) {
      throw new Error('upload manager container already built');
    }
    let that = this;

    let container = document.createElement('div');
    container.id = 'uploadManagerContainer';

    let header = document.createElement('div');
    header.className = 'upload-manager-header';

    let headerText = document.createElement('div');
    headerText.className = 'header-text';
    headerText.textContent = i18n.t("js.upload.upload_zero");

    let minimizeBtn = document.createElement('div');
    minimizeBtn.textContent = '-';
    minimizeBtn.title = i18n.t("js.upload.minimize");
    minimizeBtn.onclick = function() {
      this.textContent = this.textContent == '-' ?'+' :'-';
      this.title = i18n.t("js.upload."+ (this.textContent == '-' ? 'minimize':'maximize'));
      container.classList.toggle('minimized-manager');
    };

    let closeBtn = document.createElement('div');
    closeBtn.textContent = 'x';
    closeBtn.title = i18n.t("close");
    closeBtn.onclick = function() {
      that.removeAll();
    };

    header.appendChild(headerText);
    header.appendChild(minimizeBtn);
    header.appendChild(closeBtn);

    container.appendChild(header);
    document.body.appendChild(container);
    return container;
  }
}

class UploadBox {
  constructor(fileId, fileName, parent) {
    this.fileId = fileId;
    this.fileName = fileName;
    this.isUploadCompleted = false;
    this.box = this.build(parent);
  }

  build(parent) {
    let that = this;

    let box = document.createElement('div');
    box.className = 'upload-box';

    let uploadPctOverlay = document.createElement('div');
    uploadPctOverlay.className = 'upload-box upload-pct-overlay';
    box.appendChild(uploadPctOverlay);

    let fileTypeSection = document.createElement('div');
    fileTypeSection.className = 'upload-box-section upload-box-file-type';
    fileTypeSection.appendChild(createFileTypeIcon(parseFileExtension(this.fileName)));

    let fileNameSection = document.createElement('div');
    fileNameSection.className = 'upload-box-section upload-box-file-name';
    
    let fileNameElem = document.createElement('div');
    fileNameElem.textContent = this.fileName;
    fileNameSection.appendChild(fileNameElem);

    let uploadPctSection = document.createElement('div');
    uploadPctSection.className = 'upload-box-section upload-box-upload-pct';
    let uploadPctElem = document.createElement('div');
    uploadPctElem.className = 'upload-box-pct-text';
    uploadPctElem.textContent = '0';

    let hideBtn = document.createElement('div');
    hideBtn.className = 'upload-box-hide-btn';
    hideBtn.textContent = 'x';
    uploadPctSection.appendChild(uploadPctElem);

    box.appendChild(fileTypeSection);
    box.appendChild(fileNameSection);
    box.appendChild(uploadPctSection);
    box.appendChild(hideBtn);
    parent.appendChild(box);

    hideBtn.onclick = function() {
      window.uploadManager.remove(that.fileId);
    };

    return box;

    function parseFileExtension(fileName) {
      try {
        return fileName.toLowerCase().match(/\.([a-z0-9]{2,4})$/)[1] || null;
      } catch(e) {
        console.error(e);
        return null;
      }
    }

    function createFileTypeIcon(extention) {
      that.isDownloadable = true;
      let icon = document.createElement('i');
      extention = extention || '';
      if(extention == 'pdf') {
        icon.className = 'icon-file-pdf';
      } else if(['xls', 'xlsx', 'xlsm'].includes(extention)) {
        icon.className = 'icon-file-excel';
      } else if(['png', 'jpg', 'jpeg', 'gif'].includes(extention)) {
        icon.className = 'icon-file-picture';
      } else if(extention == 'zip') {
        icon.className = 'icon-zip';
        that.isDownloadable = false;
      } else {
        icon.className = 'icon-file';
      }
      return icon;
    }
  }

  updateProgress(pct, maxPct = 99) {
    let adjustedPct = Math.max(Math.min(parseInt(pct), maxPct), 0);

    let uploadPctOverlay = this.box.querySelector('div.upload-box.upload-pct-overlay');
    uploadPctOverlay.style.width = adjustedPct.toString() + '%';

    let uploadPctElem = this.box.querySelector('div.upload-box-section.upload-box-upload-pct > div.upload-box-pct-text');
    uploadPctElem.textContent = adjustedPct.toString();
  }

  setCompleted() {
    this.updateProgress(100, 100);
    let uploadPctOverlay = this.box.querySelector('div.upload-box.upload-pct-overlay');
    uploadPctOverlay.classList.add('successful-upload');
    let fileNameSection = this.box.querySelector('div.upload-box-section.upload-box-file-name');
    this.isUploadCompleted = true;
    window.uploadManager.updateNbOfInProgressUploads();
    if(this.isDownloadable) {
      let that = this;
      fileNameSection.classList.add('downloadable-file');
      fileNameSection.firstChild.onclick = function() {
        DocUtility.downloadFile(that.fileId);
      };
    }
  }

  markAsFailed() {
    let uploadPctOverlay = this.box.querySelector('div.upload-box.upload-pct-overlay');
    uploadPctOverlay.classList.add('failed-upload');
    this.updateProgress(100, 100);
    let uploadPctElem = this.box.querySelector('div.upload-box-section.upload-box-upload-pct > div.upload-box-pct-text');
    uploadPctElem.textContent = '0';
    this.isUploadCompleted = true;
    window.uploadManager.updateNbOfInProgressUploads();
  }
}