import i18n from './components/Translation';
import DocUtility from './components/DocUtility';
import DownloadNotification from './components/notifications/DownloadNotification.js';
import Upload from './components/Upload.js';
import UploadManager from './components/UploadManager.js';
import FileDropper from './components/FileDropper.js';

export function init(){
  loader.add(ui, 'foundation')
    .add(showSpinner)
    .add(initGlobals)
    .add(prepareScriptDropdown)
    .add(prepareQuickSearch)
    .add(getMetaData, ['scripts', 'callsheets'])
    .add(initListeners, ['generalListeners', 'upload-listeners'])
    .add(enableScripts)
    .execute();
}

function ui(){
  $('#main').foundation();
  sceneInsertionBar = document.createElement("img");
  sceneInsertionBar.src = IMG_DIRECTORY + "sceneOrderDivider.png";
  sceneInsertionBar.id = "sceneInsertionBar";
  sceneInsertionBar.style.width = "100%";
  sceneInsertionBar.style.maxHeight = "22.4px";
  sceneInsertionBar.addEventListener('dragover', (e)=> e.preventDefault());
  loader.check('foundation');

  let container = document.getElementById("selectedScenesContainer");
  container.setAttribute("data-content", i18n.t("js.sides.selection.no-scene"));
}

const UNSEL_SCENE_OPTIONS = {
  REMOVE: 0,
  CROSSOUT: 1,
  CROSSOUT_MASK: 2
};

var allScriptsMap;
var allSelectedScenes;
var dragging;
var dragged;
var draggedOver;
var sceneInsertionBar;
var selectedScripts;
var allScripts
var scriptList;
var callsheets;
var upload
var fileDropper;
var scriptMarkupSupport;
var currentScript ;
var loadedScripts = [];
var dropzoneContainerClone;
const MAX_LATEST_VERSIONS = 5 ;

///////////////////////////////////////////////////////////////////////////////

function enableScripts() {
  let uploadedScripts = document.getElementById('uploadedScripts');
  uploadedScripts.setAttribute('data-placeholder', i18n.t("js.sides.scripts.select"));
  $(uploadedScripts).prop('disabled', false).trigger('chosen:updated');
}

function prepareScriptDropdown() {
  $("#uploadedScripts").chosen({
    no_results_text: i18n.t("js.sides.nomatch"),
    hide_results_on_select: false,
    search_contains: true
  });
}

function prepareQuickSearch() {
  $("#quickSceneSelect").chosen({
    no_results_text: i18n.t("js.sides.nomatch"),
    hide_results_on_select: false,
    search_contains : false,
    enable_split_word_search : false
  }) ;

  $("#quickSceneSelect").on('change', (event, action) => {
    if(action.hasOwnProperty('selected')) {
      let selectedOption = document.querySelector(`#quickSceneSelect option[value="${action.selected}"]`);
      let xPos = selectedOption.getAttribute('xpos');
      let yPos = selectedOption.getAttribute('ypos');
      document.querySelector(`input[xpos="${xPos}"][ypos="${yPos}"]`).dispatchEvent(
        new MouseEvent('click', { 
          bubbles: false,
          cancelable: true,
          view: window 
        })
      );
    }
  });
  
  // we have to replace the chosen search bar input because of its unaccessible shadow-root
  let quickSelectChosenInput = document.querySelector('#quickSceneSelect_chosen li.search-field input');
  let noShadowInput = document.createElement('textarea');
  noShadowInput.className = quickSelectChosenInput.className;
  noShadowInput.classList.add('input-replacement');
  noShadowInput.setAttribute('placeholder', i18n.t("sides.gen.qck-sel.plchldr"));
  noShadowInput.setAttribute('rows', '1');
  noShadowInput.setAttribute('wrap', 'soft');
  
  document.querySelector('#quickSceneSelect_chosen ul').onclick = function(e) {
    noShadowInput.click();
  }
  
  noShadowInput.onclick = function(e) {
    e.stopImmediatePropagation();
    e.preventDefault();
    this.focus(); // not done automatically
  }
  
  noShadowInput.onkeyup = function(e) {
    quickSelectChosenInput.dispatchEvent(new e.constructor(e.type, e));
  }
  
  noShadowInput.onblur = function(e) {
    quickSelectChosenInput.dispatchEvent(new e.constructor(e.type, e));
  }
  
  noShadowInput.onkeydown = function(e) {
    quickSelectChosenInput.dispatchEvent(new e.constructor(e.type, e));
    if(e.keyCode == 13 && !e.shiftKey) {
      e.preventDefault();
      this.value = '';
      quickSelectChosenInput.value = '';
    }
  }
  
  noShadowInput.onfocus = function(e) {
    quickSelectChosenInput.dispatchEvent(new e.constructor(e.type, e));
  }
  
  noShadowInput.oncut = function(e) {
    quickSelectChosenInput.dispatchEvent(new e.constructor(e.type, e));
  }

  noShadowInput.onpaste = function(e) {
    quickSelectChosenInput.dispatchEvent(new e.constructor(e.type, e));
  }

  noShadowInput.oninput = function(e) {
    quickSelectChosenInput.value = this.value;
  }

  quickSelectChosenInput.classList.add('hidden-input');
  quickSelectChosenInput.parentElement.insertBefore(noShadowInput, quickSelectChosenInput);
}

function initGlobals() {
  allScriptsMap = {};
  allSelectedScenes = new Array();
  dragging = false;

  scriptMarkupSupport = {};
  callsheets = new Array();
  selectedScripts = new Array();
  dropzoneContainerClone = document.getElementById('dropzoneContainer')

  upload = null;
  fileDropper = null;
}

function initListeners() {
  initGeneralListeners();
  initUploadListeners(() => loader.check('upload-listeners'));

  function initGeneralListeners() {
    document.getElementById('submitBtn').onclick = getSelectedScenes;
    document.getElementById('resetBtn').onclick = resetSides ;
    document.getElementById('orderChronoBtn').onclick = orderScenesByScriptAndChronologicalOrder;
    document.getElementById('selectedScriptBtn').onclick = selectScripts;
    document.getElementById('uploadNewScriptBtn').onclick = showSwalNewScript;
    loader.check('generalListeners');
  }

  async function initUploadListeners(done) {
    if(!window.uploadManager) {
      new UploadManager();
    }
  
    upload = await new Upload(null, 'script');
  
    fileDropper = new FileDropper('myDropzone', i18n.t("js.upload.drop"), (files, event) => uploadFiles(files));

    let fileUploadInput = document.getElementById('fileUploadInput');
    fileUploadInput.onchange = event => {
      uploadFiles(fileUploadInput.files);
    }

    document.getElementById('myDropzone').onclick = function() {
      document.getElementById('fileUploadForm').reset();
      fileUploadInput.click();
    }

    document.getElementById('scriptListUpdateBtn').onclick = () => {
      showSpinner();
      getMetaData(hideSpinner);
    };

    done();
  }
}

function showSwalNewScript() {

  if(document.getElementById('dropzoneContainer') == null) {
    document.querySelector('.generate-sides').appendChild(dropzoneContainerClone)
  }

  swal({
    html: document.querySelector("#dropzoneContainer"),
    confirmButtonText: i18n.t("close"),
    onOpen : function () {
      document.querySelector("#dropzoneContainer").style.display = "block" ;
    }
  }) ;
}


function selectScripts() {
  $("scriptsSelect li input").each(function(i) {
    if (this.checked == true) {
      $('#uploadedScripts').trigger("change", {selected : this.value}) ;
      $(`#uploadedScripts option[value=${this.value}]`).prop('selected', true) ;
    }
  }) ;
  $("#uploadedScripts").trigger('chosen:updated');

  if(selectedScripts.length == 0) {
    cgToast(i18n.t("js.sides.selection.no-script"));
    return;
  }
}

function resetSides() {
  resetQuickSceneSelect() ;
  allSelectedScenes = new Array() ;
  resetSceneCheckboxes() ;
  resetSceneNbSelected() ;
  updateSelectedScenesList() ;
  $('#selectedScenesContainer p').remove()
}

function resetQuickSceneSelect() {
  document.querySelectorAll(`.scene-description-input`).forEach(function(element) {
    if (element.checked) {
      let xpos = element.getAttribute("xpos") ;
      let ypos = element.getAttribute("ypos") ;
      loadedScripts.forEach((optgroup) => {
        if (optgroup.querySelector(`option[xpos="${xpos}"][ypos="${ypos}"]`) !== null) {
          optgroup.querySelector(`option[xpos="${xpos}"][ypos="${ypos}"]`).selected = false;
        }
      }) ;
      $('#quickSceneSelect').trigger('chosen:updated');
    }
  }) ;
}

function resetSceneCheckboxes() {
  $("table.sceneTable tr").each(function() {
    $(this).find("input").prop('checked', false) ;
  });
  $("div[table-type='locations'] ul li").each(function() {
    $(this).find("input").prop('checked', false) ;
  });
  $("div[table-type='characters'] ul li").each(function() {
    $(this).find("input").prop('checked', false) ;
  });
}

function resetSceneNbSelected() {
  $("#script-tabs button").each(
    function() {
      $(this).find("span.script-nbselected").text("0");
    }
  )
}

function orderScenesByScriptAndChronologicalOrder() {
  // Will parse the script's colour first alphabetically then the scene number within each script
  // Very helpful for episodic where we have 101, 102 etc. 101 will come before 102.
  if (Number.isInteger(allSelectedScenes[0])) {
    allSelectedScenes.sort(function (a, b) {
      return Utility.compare(a.scriptColor, b.scriptColor) || Utility.compare(parseInt(a.sceneNo), parseInt(b.sceneNo));
    });
  } else {
    allSelectedScenes.sort(function (a, b) {
      // Sort the script into episode + scene when it's stored as a floating point number
      // The scene must be cast into an integer as well since it's a string before.

      // The use case for this is scene numbers that look like this in order: 13.1, 13.1A, 13.1B, 13.2, 13.3 ..., 13.10, 13.11, 13.11A, 13.11B etc.
      const aEpisode = Math.floor(a.sceneNo);
      const aScene = parseInt((a.sceneNo + "").split(".")[1]);
      const bEpisode = Math.floor(b.sceneNo);
      const bScene = parseInt((b.sceneNo + "").split(".")[1]);

      return Utility.compare(a.scriptColor, b.scriptColor) || Utility.compare(aEpisode, bEpisode) || Utility.compare(aScene, bScene);
    });
  }
  updateSelectedScenesList() ;
}

function uploadFiles(files) {
  upload.toGoogleCloudStorage(files).then((result) => {
    console.debug('upload success', result);
  }, (errFileName) => {
    console.error('failed upload ' + errFileName);
  });
}

function fetchCallSheets() {
  apicall('documentsapi', 'fetchCallSheets', { targetEpisode: '2' }).then(resp => {
    console.debug('callsheets loaded');
    callsheets = resp.items;
    loader.check('callsheets');
  });
}

function getMapLength(object) {
  return Object.keys(object).length
}

function getMetaData(done = function() {}) {
  fetchCallSheets();

  // The script has an episode value
  function episodic(maybeEpisodeNumber) {
    return typeof (maybeEpisodeNumber) !== undefined && maybeEpisodeNumber !== "0";
  }

  // Takes a key and attaches a random integer between 1 and 1000000
  function getUniqueString(key) {
    // Output example: "107+12431"
    const randomInt = Math.floor(Math.random() * 1000000);
    return `${key}+${randomInt}`;
  }

  var tmp = new Object();
  allScriptsMap = {};
  apicall('scriptapi', 'fetchAllScripts', tmp).then((resp) => {
    if (resp.items) {
      allScripts = resp.items;
      const latestScriptMap = new Map();
      const otherScriptMap = new Map();

      // If more than 5 scripts are uploaded:
      // We show the most recent 5 versions in the Latest Versions tab by date added newest to oldest
      // We also show in the latest versions any scripts related to an episode
      // Any other scripts get put into the Other Scripts tab

      if (allScripts.length <= MAX_LATEST_VERSIONS) {
        console.debug("Only showing 5 most recent scripts since that is all that exists")
        allScripts.forEach(function (scriptItem, index) {
          // The index of the map is just the index of the item and the list is sorted in descending order from newest to oldest
          latestScriptMap.set(index, scriptItem);
        })
        console.debug("latest is: " + JSON.stringify(latestScriptMap));
      } else {
        console.debug("More than 5 scripts, sorting them into latest versions and other scripts")
        // Desired flow:
        // - If episodic, latest versions shows top 5 where episode goes newest to oldest
        // also, if 2 scripts exist for the same episode, only show the newest one in latest versions, all others go into other scripts
        // - If no episode, just sort newest to oldest and show 5, after 5 put them in other scripts
        // - If a mix of both, take most recent 5 entirely and if any duplicate episodes exist, put older episodes into other
        let otherScriptCounter = 0;
        let latestVersionCounter = 0;
        allScripts.forEach(scriptItem => {
          const episodeNumber = scriptItem[0];
          // Once we get to the fifth script, put any other scripts in the other script section
          if (latestScriptMap.size < MAX_LATEST_VERSIONS) {
            if (episodic(episodeNumber)) {
              if (latestScriptMap.has(episodeNumber)) {
                // There is already a newer script for this episode inside of our latest version script map so put it in other version
                if (otherScriptMap.has(episodeNumber)) {
                  otherScriptMap.set(getUniqueString(episodeNumber), scriptItem);
                } else {
                  otherScriptMap.set(episodeNumber, scriptItem);
                }
              } else {
                latestScriptMap.set(episodeNumber, scriptItem);
              }
            } else {
              // if no episode and less than 5 elements in our map, add the script to the latest script map
              latestScriptMap.set(latestVersionCounter, scriptItem);
              latestVersionCounter++;
            }
          } else {
            // Put all other scripts in the other script map
            if (episodic(episodeNumber)) {
              if (otherScriptMap.has(episodeNumber)) {
                otherScriptMap.set(getUniqueString(episodeNumber), scriptItem);
              } else {
                otherScriptMap.set(episodeNumber, scriptItem);
              }
            } else {
              // if no episode and less than 5 elements in our map, add the script to the latest script map
              otherScriptMap.set(otherScriptCounter, scriptItem);
              otherScriptCounter++;
            }
          }
        });

        console.debug("latest is: " + JSON.stringify(latestScriptMap));
        console.debug("other is: " + JSON.stringify(otherScriptMap));
      }

      var oldScriptOptions = `<optgroup label="${i18n.t("js.sides.version.latest", { count: latestScriptMap.size })}">`;
      let latestVersion = document.createElement("span") ;
      latestVersion.textContent = i18n.t("js.sides.version.latest", { count: latestScriptMap.size });
      document.getElementById('uploadedScriptsList').appendChild(latestVersion) ;
      document.getElementById('uploadedScriptsList').appendChild(document.createElement("div")) ;

      // Populate the latest scripts tab options and list of scripts
      if (latestScriptMap.size > 0) {
        new Map([...latestScriptMap].sort((a, b) => {
          Date.parse(b[3]) > Date.parse(a[3])
        }))
          .forEach(script => {
            createOption(script);
            createList(script, 0);
          });
      }
      oldScriptOptions += `</optgroup><optgroup label="${i18n.t("js.sides.version.other")}">`;

      let other = document.createElement("span") ;
      other.textContent = i18n.t("js.sides.version.other") + " \u25BA" ;
      other.id = "otherSpan" ;
      other.setAttribute("visible", "false") ;
      document.getElementById('uploadedScriptsList').appendChild(other) ;
      let otherDiv = document.createElement("div") ;
      otherDiv.id = "otherList" ;
      document.getElementById('uploadedScriptsList').appendChild(otherDiv) ;

      other.addEventListener('click', function() {
        
        let visible = other.getAttribute("visible") ;

        if (visible === "true") {
          other.setAttribute("visible", "false") ;
          other.textContent = i18n.t("js.sides.version.other") + " \u25BA" ;
        }
        else {
          other.setAttribute("visible", "true") ;
          other.textContent = i18n.t("js.sides.version.other") + " \u25BC" ;
        }

        let otherList = document.querySelectorAll("#otherList li");
        let otherListArray = Array.from(otherList);
        otherListArray.forEach(listElement => {
          if (other.getAttribute("visible") === "false") {
            listElement.style.display = "none";
          } else {
            listElement.style.display = "";
          }
        }) ;
      });

      // Populate the other scripts tab options and list of scripts
      if (otherScriptMap.size > 0) {
        new Map([...otherScriptMap].sort((a, b) => {
          Date.parse(b[3]) > Date.parse(a[3])
        }))
          .forEach(script => {
            createOption(script);
            createList(script, 1);
          });
      }

      oldScriptOptions += '</optgroup>';

      /**
       * Creates the option to select an element within a script select div (latest or other)
       * @param {Array} script 
       * @param {number} index
       */
      function createOption(script) {
        let i = -1;
        if (script[5]) { // fileId
          i = getMapLength(allScriptsMap);
        } else {
          return; //How am I supposed to use it if it doesn't have a fileId?
        }
        oldScriptOptions += "<option value=\"" + i + "\" data-subtext=\""
          + script[2].substring(0, script[2].lastIndexOf(".pdf"))+"\""
          + " color=\""+script[1]+"\" episodenb=\""+script[0]+"\" "
          + "file_id=\""+(script[5]||'')+"\">"
          + (script[0] + " - ").replace(/^0\s-\s$/,'') + addSpaceToColor(script[1])
          + "</option>";

        //as of now, if scene nb contains ", " it may send the wrong
        //scene nb info to the createAndStoreSides api method
        if(script[0].split(", ").length > 1) {
          console.error("UNSUPPORTED SCENE NUMBER FORMAT. "+"Scene number cannot contain the following format : \", \".");
        }

        // If there is a fileId then set the fileId as the script so we can display the scenes from that script
        if (script[5]) { // fileId
          allScriptsMap[script[5]] = script;
        }
        scriptMarkupSupport[script[2]] = script[4] == '1';
      }

      /**
       * Generates the list elements for scripts within a script select div (latest or other)
       * @param {Array} script
       * @param {number} divNo which div it is in the option group (0 is latest, 1 is other scripts)
       */
      function createList(script, divNo) {
        let i = -1;
        if (script[5]) { // fileId
          i = getMapLength(allScriptsMap);
        } else {
          return; //How am I supposed to use it if it doesn't have a fileId?
        }

        const episode = script[0];
        const colour = script[1];
        let li = document.createElement("li") ;
        let label = document.createElement("label") ;
        label.textContent = (episode + " - ").replace(/^0\s-\s$/, '') + addSpaceToColor(colour);
        
        li.setAttribute("value", i - 1) ;
        li.appendChild(label) ;
        document.querySelectorAll("#scriptsSelect div")[divNo].appendChild(li) ;

        li.addEventListener('click', function (e) {
          if(e.target.tagName === "INPUT") { return; }
          e.stopImmediatePropagation();
          e.preventDefault();
          let oldScript = currentScript ;
          currentScript = li.value ;
          li.style.backgroundColor = "#DEF7EE";
          if (oldScript !== undefined && oldScript !== li.value) {
            document.querySelectorAll("#scriptsSelect li[value='" + oldScript + "']")[0].style.backgroundColor = "white" ;
            removeScript(oldScript) ;
            $('#uploadedScripts').trigger("change", { selected: i - 1 });
            $(`#uploadedScripts option[value=${i - 1}]`).prop('selected', true);
            let $oldOptGroup = $('#optGroup_' + oldScript);
            $oldOptGroup.remove() ;
            loadedScripts.forEach((optgroup) => {
              if (optgroup.id === "optGroup_" + currentScript) {
                let $quickSceneSelect = $('#quickSceneSelect');
                $quickSceneSelect[0].appendChild(optgroup);
                $quickSceneSelect.trigger('chosen:updated');
              }
            }) ;
          }
          else {
            if (oldScript !== undefined) {
              removeScript(oldScript) ;
              let $oldOptGroup = $('#optGroup_' + oldScript);
              $oldOptGroup.remove() ;
              loadedScripts.forEach((optgroup) => {
              if (optgroup.id === "optGroup_" + currentScript) {
                let $quickSceneSelect = $('#quickSceneSelect');
                $quickSceneSelect[0].appendChild(optgroup);
                $quickSceneSelect.trigger('chosen:updated');
              }
            }) ;
            }
            $('#uploadedScripts').trigger("change", {selected : i - 1}) ;
            $(`#uploadedScripts option[value=${i - 1}]`).prop('selected', true) ;
            
          }
          $("#uploadedScripts").trigger('chosen:updated');
        });
      }
    }

    document.getElementById('uploadedScripts').innerHTML = oldScriptOptions;

    $("#uploadedScripts").off('change');
    $("#uploadedScripts").on('change', (event, action) => {
      if(action.hasOwnProperty('selected')) {
        selectedScripts.push(action.selected);
        addScript(action.selected);
        document.getElementById("submitBtn").style.display = "" ;
        document.getElementById("resetBtn").style.display = "flex" ;
        document.getElementById("orderChronoBtn").style.display = "" ;
      } else {
        selectedScripts.splice(selectedScripts.indexOf(action.deselected), 1);
        removeScript(action.deselected);
      }

      if(selectedScripts.length == 0) {
        let $quickSceneSelect = $('#quickSceneSelect');
        $quickSceneSelect.empty();
        $quickSceneSelect.trigger('chosen:updated');
        $quickSceneSelect.closest('div.cell').slideUp(400);
        
        document.getElementById('dropzoneContainer').style.display = '';
        document.getElementById('scriptsWhenEmpty').style.display = '';
        document.getElementById('scriptsWhenChosen').style.display = 'none';
        document.getElementById("submitBtn").style.display = "none" ;
        document.getElementById("resetBtn").style.display = "none" ;
        document.getElementById("orderChronoBtn").style.display = "none" ;
        return;
      } else {
        document.getElementById('dropzoneContainer').style.display = 'none';
        document.getElementById('scriptsWhenEmpty').style.display = 'none';
        document.getElementById('scriptsWhenChosen').style.display = '';
      }
    });

    $("#uploadedScripts").trigger('chosen:updated');
    loader.check('scripts');
  }).catch(err => {
    console.error('server error: ' + err);
  })
  .then(done);
}

function showScript(scriptIndex){
  let bodies = document.getElementById("script-bodies");
  try{
    bodies.querySelector(".tab-content.active").className = "tab-content";
  }catch(e){}
  bodies.querySelector(`#content_script_${scriptIndex}`).className = "tab-content active" ;
}

function removeScript(scriptIndex){
  let pane = document.getElementById("script_"+scriptIndex)

  $('#quickSceneSelect').trigger('chosen:updated');
}

function dropTarget(event){
  dragging = false;
  try{
    document.getElementById("selectedScenesContainer").removeChild(sceneInsertionBar);
  }catch(e){
    console.warn(e);
  }
  if(dragged == draggedOver){
    return;
  }
  let draggedScene = allSelectedScenes[dragged];
  allSelectedScenes.splice(dragged, 1);
  allSelectedScenes.splice(draggedOver, 0, draggedScene);
  event.dataTransfer.clearData();
  updateSelectedScenesList();
}

function updateSelectedScenesList(){
  let container = document.getElementById("selectedScenesContainer");
  container.setAttribute("data-i18n", '') ;
  container.innerHTML = '';

  allSelectedScenes.forEach((scene, index)=>{
    let tr = document.createElement("tr");
    tr.draggable = true;
    tr.className = "sceneListItem";
    tr.style.cursor = "pointer";
    tr.title = i18n.t("js.sides.selected.scenes.title");
    let tdNo = document.createElement("td") ;
    let dragIcon = document.createElement('img')
    dragIcon.src = "../assets/img/drag-and-drop.svg"
    let dragText = document.createElement('p')
    dragText.style.marginBottom = 0
    dragText.textContent = scene.text.split(",")[0];
    tdNo.appendChild(dragIcon)
    tdNo.appendChild(dragText)
    tdNo.className = "drag-icon-container" ;
    let tdDescription = document.createElement("td") ;
    tdDescription.innerHTML = scene.text.split(",")[1];

    tr.append(tdNo) ;
    tr.append(tdDescription) ;

    tr.addEventListener('dragstart', (e)=>{
      e.dataTransfer.setData('text/plain', e.target.id);
      dragging = true;
      dragged = index;
      var img = new Image();
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
      e.dataTransfer.setDragImage(img, 0, 0);
    });
    tr.addEventListener('dragover', (e)=>{
      e.preventDefault();
      draggedOver = index;
      if(draggedOver > dragged){
        $(sceneInsertionBar).insertAfter(tr);
      }else if(draggedOver == dragged){
        try{container.replaceChild(sceneInsertionBar);}catch(e){}
      }else{
        container.insertBefore(sceneInsertionBar, tr);
      }
    });
    tr.addEventListener('dragend', dropTarget);

    let tdScript = document.createElement("td") ;
    tdScript.className = "sceneListScriptDesc";
    tdScript.textContent = scene.scriptColor;
    tr.appendChild(tdScript);


    let tdOptions = document.createElement("td") ;
    tdOptions.className = "list-item-icon-container"

    let iClone = document.createElement("img");
    iClone.src = "../assets/img/sides-gen-copy-icon.svg"
    iClone.title = i18n.t("js.sides.selected.scenes.dup");
    iClone.setAttribute("aria-hidden", true);
    iClone.onclick = () =>{
      allSelectedScenes.splice(index, 0, scene);
      updateSelectedScenesList();
    };
    tdOptions.appendChild(iClone);

    let iTrash = document.createElement("img");
    iTrash.src = "../assets/img/sides-gen-close-icon.svg"
    iTrash.title = i18n.t("js.sides.selected.scenes.rem");
    iTrash.style.fontSize = "1rem";
    iTrash.setAttribute("aria-hidden", true);
    iTrash.onclick = () => {
      
      allSelectedScenes.splice(index, 1);
      let filtered = allSelectedScenes.filter((value) => { return (value.scriptId == scene.scriptId && value.sceneNo == scene.sceneNo); });
      if (filtered.length > 0) {
        updateSelectedScenesList();
      } else {
        try {
          document.querySelector(`.scene-description-input[sceneNo="${scene.sceneNo}"][xpos="${scene.scriptId}"]`).click();
        } catch (e) {
          console.warn("Failed to uncheck a scene : " + e);
          updateSelectedScenesList();
        }
      }
      if($('#selectedScenesContainer').find('tr').length === 0) {
        $('#selectedScenesContainer p').remove()
      }      
    };
    tdOptions.appendChild(iTrash);
    tr.append(tdOptions);
    container.appendChild(tr);
  });
}

function addScript(scriptIndex){
  const scriptObj = Object.values(allScriptsMap)[scriptIndex];

  var tmp = new Object();
  tmp.script = scriptObj[2];
  tmp.episode = scriptObj[0];
  tmp.color = scriptObj[1];
  tmp.fileId = scriptObj[5] || '-';

  if (document.getElementById("content_script_"+scriptIndex) !== null || document.getElementById("optGroup_"+scriptIndex) !== null) {
    showScript(scriptIndex) ;
  } else {
    showSpinner();
    apicall('scriptapi', 'fetchScriptScenes', tmp).then((resp) => {
      if (resp.items){
        let scriptName = (scriptObj[0] + " - ").replace(/^0\s-\s$/,'') + addSpaceToColor(scriptObj[1]);
        let fileName = scriptObj[2];
        let fileId = scriptObj[5];
  
        let tabsContainer = $('#script-tabs');
        $('#script-tabs').css("display", "none");
        tabsContainer.append(createScriptTab(scriptName, scriptIndex));
  
        let scenes = resp.items;
        let bodiesContainer = $('#script-bodies');
        bodiesContainer.append(createScriptBody(scriptName, fileName, scriptIndex, fileId, scenes));
  
        let $quickSceneSelect = $('#quickSceneSelect');
        $quickSceneSelect.trigger('chosen:updated');
        $quickSceneSelect.closest('div.cell').slideDown(400, function() {});
        
        let optgroup = document.createElement('optgroup');
        optgroup.setAttribute('label', scriptName);
        optgroup.id = "optGroup_"+scriptIndex;
        
        let j = 0;
        for(let scene of scenes){
          let option = document.createElement('option');
            option.value = scriptIndex + '__' + j;
            option.textContent = `${scene[0]}` + (scene[1] == '' ?`` :` (${scene[1].replace(/,/g, ', ')})`);
            option.setAttribute('characters', scene[1]);
            option.setAttribute('xpos', scriptIndex);
            option.setAttribute('ypos', j);
            optgroup.appendChild(option);
            j++;
        }
        loadedScripts.push(optgroup);
        $quickSceneSelect[0].appendChild(optgroup);
        $quickSceneSelect.trigger('chosen:updated');
  
        showScript(scriptIndex);
        hideSpinner();
      }
    });
  }
}

function createScriptBody(scriptName, fileName, scriptId, fileId, scenes){
  let bodyContent = document.createElement('div');
  bodyContent.className = "tab-content";
  bodyContent.id = "content_script_"+scriptId;
  bodyContent.append(createScriptBodyHeader(scriptName, fileName, fileId));

  let filterTab = document.createElement("div");
  filterTab.className="filter-tab";

  let createBtn = (type)=>{
    let button = document.createElement("a");
    button.className = "button short";
    button.textContent = i18n.t("js.sides.buttons."+type);
    button.onclick = ()=>{
      filterTab.querySelectorAll(".active").forEach(node=>node.className = "button short");
      button.className = "button short active";
      bodyContent.querySelectorAll('.sceneTable.active').forEach(node=>node.className = "sceneTable");
      bodyContent.querySelector(`#${type}-table-${scriptId}`).className = "sceneTable active";
    }
    return button;
  }

  let scenesBtn = createBtn("scene");
  scenesBtn.className += " active";
  filterTab.appendChild(scenesBtn);
  filterTab.appendChild(createBtn("character"));
  filterTab.appendChild(createBtn("location"));
  bodyContent.appendChild(filterTab);
  bodyContent.append(createScriptBodyContent(scriptId, scenes));
  return bodyContent;
}

function createScriptBodyContent(scriptId, scenes){
  let container = document.createElement('div');
  container.className = "tab-body-content scenesContainer cell";

  let scenesTable = document.createElement('table');
  scenesTable.className = "sceneTable active";
  scenesTable.id = `scene-table-${scriptId}`;
  scenesTable.setAttribute("table-type", "scenes");
  container.append(scenesTable);

  let thead = document.createElement("thead") ;
  let thNo = document.createElement("th") ;
  let thDesc = document.createElement("th") ;
  
  thNo.textContent = "#" ;
  thDesc.textContent = "Description" ;
  thNo.style.fontWeight = "bold" ;
  thNo.style.paddingLeft = "20px" ;
  thDesc.style.fontWeight = "bold" ;
  thDesc.style.paddingLeft = "0px" ;
  scenesTable.append(thead) ;
  thead.append(thNo)
  thead.append(thDesc)


  thead.className = "select-scrips-head"

  let characters = new Object();
  let locations = { INT: {}, EXT: {}, OTHER: {} }
  let cpt = 0;
  scenes.forEach(scene => {
    //Fetch characters
    try{
      scene[1].split(',').forEach(name=>{
        if(name.trim()){
          if(!characters.hasOwnProperty(name)){
            characters[name] = [];
          }
          characters[name].push(scene[0].split(',')[0]);
        }
      });
    }catch(e){
      console.error(e);
    }
    
    //Fetch scenes
    let sceneDesc = scene[0];
    try{
      let sceneNb = sceneDesc.split(',')[0].trim();
      const trimPattern = new RegExp(/(^\s*[^a-zA-Z\d]*\s*)|(\s*[^a-zA-Z\d]*\s*)$/, 'g');
      let truncScene = sceneDesc.substring(sceneNb.length+1).replace(trimPattern, '');
      let intExt = truncScene.split(truncScene.includes('.') ?/\./ :/(\s+)|(\s*$)/)[0].replace(trimPattern, '');
      truncScene = truncScene.substring(intExt.length).replace(trimPattern, '');
      let location = truncScene.split(truncScene.search(/\(|,|--|DAY|NIGHT/i) >= 0
        ?(/\s*(\(|,|--|DAY|NIGHT)\s*/i) :/(\s*-\s*)|(\s*$)/)[0].replace(trimPattern, '');

      if(sceneNb && intExt && location) {
        let locationMap = locations[locations.hasOwnProperty(intExt) ?intExt :'OTHER'];
        if(!locationMap.hasOwnProperty(location)) {
          locationMap[location] = [];
        }
        locationMap[location].push(sceneNb);
      }
    }catch(e){
      console.warn('failed to find location for scene "' + sceneDesc + '"');
      console.debug(e);
    }

    let tr = document.createElement("tr");
    let td= document.createElement("td");
    td.classList.add('script-td')
    const xpos = scriptId;
    const ypos = cpt;
    const sceneNo = scene[0].split(',')[0];

    let checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.className = "scene-description-input";
    checkbox.name = "checkscenes";
    checkbox.value = sceneNo + '__' + xpos;
    checkbox.setAttribute('xpos', xpos);
    checkbox.setAttribute('ypos', ypos);
    checkbox.setAttribute('sceneNo', sceneNo);
    checkbox.setAttribute("characters", scene[1]);
    checkbox.id = `check${ypos}_${xpos}`;
    checkbox.onchange = ()=>{
      let isSelected = checkbox.checked;
      //Update the quick scene selector
      loadedScripts.forEach((optgroup) => {
        if (optgroup.querySelector(`option[xpos="${xpos}"][ypos="${ypos}"]`) !== null) {
          optgroup.querySelector(`option[xpos="${xpos}"][ypos="${ypos}"]`).selected = isSelected ;
        }
      }) ;
      
      $('#quickSceneSelect').trigger('chosen:updated');
      
      //Update the span
      let span = document.querySelector(`#script_${xpos} .script-nbselected`);
      let nb = parseInt(span.textContent, 10);
      nb += (isSelected)? 1:-1;
      span.textContent = nb;

      if(!isSelected){
        document.querySelectorAll(`#content_script_${scriptId} .scene-selector-input[scenes*="${sceneNo}"]`).forEach(element=>{
          let elemScenes = element.getAttribute("scenes").split(',');
          if(element.checked && elemScenes.includes(sceneNo)){
            element.checked = false;
          }
        });
        allSelectedScenes = allSelectedScenes.filter((value)=>{
          return !(value.scriptId == scriptId && value.sceneNo == sceneNo);
        });
      }else{
        let scriptObj = Object.values(allScriptsMap)[scriptId];
        allSelectedScenes.push({
          scriptId: scriptId,
          sceneNo: sceneNo,
          text: scene[0],
          scriptColor: (scriptObj[0] + " - ").replace(/^0\s-\s$/,'') + addSpaceToColor(scriptObj[1])
        });
      }
      updateSelectedScenesList();
    };
    td.appendChild(checkbox);
    td.style.paddingRight = "0px" ;

    let label = document.createElement("label");
    label.className = "scene-description no-text-select";
    label.htmlFor = checkbox.id;
    label.textContent = scene[0].split(",")[0];
    label.style.fontWeight = "normal" ;
    let tdDescription = document.createElement("td") ;
    tdDescription.textContent = scene[0].split(",")[1] ;
    td.appendChild(label);
    tr.appendChild(td);
    tr.appendChild(tdDescription) ;
    tdDescription.style.paddingLeft = "5px"
    tdDescription.style.paddingRight = "10px" 

    td.style.paddingTop = "5px" ;
    td.style.paddingBottom = "5px" ;
    td.style.paddingLeft = '10px'
    tr.addEventListener('click', function(e) {
      if((/label|input|a|i/i).exec(e.target.tagName)) { return; }
      e.stopImmediatePropagation();
      e.preventDefault();
      $(this).find("input[type=checkbox]").trigger('click');
    });
    scenesTable.appendChild(tr);
    cpt++;
  });

  let charactersTable = document.createElement('div');
  charactersTable.className = "sceneTable";
  charactersTable.id = `character-table-${scriptId}`;
  charactersTable.setAttribute("table-type", "characters");
  container.append(charactersTable);
  createDropdownLists(charactersTable, "character", characters, scriptId)

  let locationsTable = document.createElement('div');
  locationsTable.className = "sceneTable";
  locationsTable.id = `location-table-${scriptId}`;
  locationsTable.setAttribute("table-type", "locations");
  container.append(locationsTable);
  getMapLength(locations.INT)
  if (getMapLength(locations.INT) > 0) {
    createLocationDropdownContent(locationsTable, "INT", locations.INT, scriptId);
  }
  if (getMapLength(locations.EXT) > 0) {
    createLocationDropdownContent(locationsTable, "EXT", locations.EXT, scriptId);
  }
  if (getMapLength(locations.OTHER) > 0) {
    createLocationDropdownContent(locationsTable, "OTHER", locations.OTHER, scriptId);
  }

  return container;
}

function createScriptBodyHeader(scriptName, fileName, fileId){
  return document.createElement('div');
}

function createLocationDropdownContent(container, title, locations, scriptId){
  let locationTitle = document.createElement("div");
  locationTitle.textContent = i18n.t("js.sides.locations."+title);
  locationTitle.className = "scene-location-title";
  container.appendChild(locationTitle);
  createDropdownLists(container, "location", locations, scriptId, title);
}

function createDropdownLists(container, type, items, scriptId, opt='0'){
  let ul = document.createElement("ul");

  Object.keys(items).sort().forEach(name=>{
    let li = document.createElement("li");
    let scenes = items[name];
    
    let checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.className = "scene-selector-input";
    checkbox.setAttribute("scenes", scenes);
    checkbox.setAttribute(type, name);
    checkbox.id = `${type}-${name}-${scriptId}-${opt}`;
    checkbox.onchange = ()=>{
      const selected = checkbox.checked;
      scenes.forEach(sceneNo=>{
        let chbox = document.querySelector(`.scene-description-input[xpos="${scriptId}"][sceneNo="${sceneNo}"]`);
        if(chbox.checked !== selected){
          chbox.click();//To trigger the onchange event
        }
      });
      if(selected){
        cgToast(i18n.t("js.sides.scene.selection.message", {count:scenes.length, scenes:scenes.join(', ').replace(/,\s([^,]+)$/, ` ${i18n.t("utils.and")} $1`)}))
      }
    }

    let label = document.createElement("label");
    label.textContent = name;
    label.htmlFor = checkbox.id;
    label.title = i18n.t("js.sides.lists.title", {name, scenes, count:scenes.length});
    label.className = "scene-description"

    let span = document.createElement("span");
    span.textContent = i18n.t("js.sides.lists.span", {scenes:scenes.join(', '), count:scenes.length});
    
    span.className = "scenes-subinfo";
    span.style.display = "none" ;
    label.appendChild(span);

    checkbox.style.marginRight = "30px" ;
    li.appendChild(checkbox);
    li.appendChild(label);
    ul.appendChild(li);

    li.addEventListener('click', function(e) {
      if(e.target.tagName === "INPUT") { return; }
      e.stopImmediatePropagation();
      e.preventDefault();
      $(this).find("input[type=checkbox]").trigger('click');
    });
  });

  container.appendChild(ul);
}

function createScriptTab(scriptName, scriptId){
  let tab = document.createElement('button');
  tab.className = 'tab-pane-item';
  tab.id = 'script_'+scriptId;
  tab.onclick = () => {showScript(scriptId);}

  let name = document.createElement('span');
  name.className = "script-name";
  name.textContent = scriptName;
  tab.append(name);
  
  let selectInfo = document.createElement('span');
  selectInfo.className = "script-nbselected";
  selectInfo.textContent = "0";//No scene selected yet for that script
  tab.append(selectInfo);

  return tab;
}

function addSpaceToColor(color){
  if(color.match(/[a-z][A-Z][a-z]+/g) != null){
    var colors = color.split(/(?=[A-Z])/);
    return colors[0] + " " + colors[1].charAt(0).toLowerCase() + colors[1].substring(1, colors[1].length);
  }
  return color;
}

function getSelectedScenes() {
  var scenesForSides = new Array();
  var scriptsForSides = new Array();
  var fileIdsForSides = new Array();

  for(var i=0, scene; scene=allSelectedScenes[i]; i++){
    let scriptObj = Object.values(allScriptsMap)[scene.scriptId];
    scenesForSides[i] = scene.sceneNo;
    scriptsForSides[i] = scriptObj[2];
    fileIdsForSides[i] = scriptObj[5] || '-';
  }

  if(scenesForSides.length == 0) {
    cgToast(i18n.t("js.sides.selection.no-scene"));
    return;
  }

  let printOptions = '';
  let fileNames = '';

  var tmp = new Object();
  tmp.isRevisions = false;

  let selectionData = {
    scenes: scenesForSides.join(','),
    scripts: scriptsForSides.join(','), // scripts will only be used as failover for backward compatibility
    filesId: fileIdsForSides.join(',')  // if a file id in filesId is not found
  }

  var scriptOptionNumber = document.getElementById("uploadedScripts").value;
  if(scriptOptionNumber == -1) {
    return;
  }

  swal({
    title: i18n.t("js.sides.format.options.title"),
    html: createFormatOptionCheckboxes(),
    showCloseButton: true,
    onOpen: function(swal) {

      document.getElementById('oneUpCheckbox').checked = true;

      let largeTwoUpFormatInput = document.getElementById('largeTwoUpFormat');
      largeTwoUpFormatInput.checked = true;
      let largeTwoUpFormatLabel = document.querySelector(`label[for="${largeTwoUpFormatInput.id}"]`);

      let twoUpSettingsCogs = document.createElement('i');
      twoUpSettingsCogs.className = 'fa fa-cogs';
      twoUpSettingsCogs.id = 'twoUpSettingsCogs';
      twoUpSettingsCogs.setAttribute("data-content", i18n.t("int.usr-opts.settings"));
      twoUpSettingsCogs.onclick = function() {
        $('#twoUpFormatsContainer').toggle(400);
      };
      document.querySelector('div.swal2-container > div').appendChild(twoUpSettingsCogs);

    },
    confirmButtonText: i18n.t("button.next"),
    confirmButtonColor: '#13C46A',
    allowOutsideClick: false,
    showLoaderOnConfirm: false,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    preConfirm: function() {
      return new Promise(function(resolve, reject) {
        let text = document.getElementById('sidesGenFileNameInput').value;

        if(!document.getElementById('oneUpCheckbox').checked && !document.getElementById('twoUpCheckbox').checked) {
          reject(i18n.t("js.sides.format.options.reject.format"));
          return;
        }

        let invalidChars = findUnsupportedChars(text);

        if(!text) {
          reject(i18n.t("js.sides.format.options.reject.filename"));
          return;
        }

        if(invalidChars.length > 0) {
          reject(i18n.t("js.sides.format.options.reject.characters", {count:invalidChars.length, characters:invalidChars.join(', ')}));
          return;
        }

        if(document.getElementById('oneUpCheckbox').checked) {
          fileNames += getValidFileName(text);
          printOptions += '1-UP';
        }

        if(document.getElementById('twoUpCheckbox').checked) {
          if(document.getElementById('largeTwoUpFormat').checked) {
            fileNames += (fileNames === '' ?'' :',') + getValidFileName(text + ((/(.?2.?UP.?)$/i.exec(text))?'' :' (2-UP)'));
            printOptions += (printOptions === '' ?'' :',') + '2-UP';
          } else {
            fileNames += (fileNames === '' ?'' :',') + getValidFileName(text + ((/(.?S2.?UP.?)$/i.exec(text)) ?'' :' (S2-UP)'));
            printOptions += (printOptions === '' ?'' :',') + 'S2-UP';
          }
        }

        tmp.printOptions = printOptions;
        tmp.fileNames = fileNames;

        resolve();
      });
    }
  }).then(function() {
    swal({
      animation: false,
      title: i18n.t("js.sides.output.options.title"),
      html: createSceneOptCheckboxes(),
      input: 'text',
      inputClass: 'hidden-input', //used to prevent focus error on reject
      showCloseButton: true,
      confirmButtonText: callsheets.length === 0 ? i18n.t("button.submit") : i18n.t("button.next"),
      confirmButtonColor: '#13C46A',
      allowOutsideClick: false,
      onOpen: function(swal) {
        if(document.getElementsByClassName('swal2-modal').length > 0) {
          document.getElementsByClassName('swal2-modal')[0].style.minHeight = '0px';
        }

        if(document.getElementsByClassName('swal2-spacer').length > 0) {
          document.getElementsByClassName('swal2-spacer')[0].style.display = 'none';
        }

        ['circleScChk', 'continuityArrChk'].forEach(chkId => {
          document.getElementById(chkId).onclick = function(e) {
            let incompScript = [];
            for(let a = 0; a < scriptsForSides.length; a++) {
              if(!scriptMarkupSupport[scriptsForSides[a]]) {
                if(!incompScript.includes(scriptsForSides[a])) {
                  incompScript.push(scriptsForSides[a]);
                }
                e.preventDefault();
                e.stopImmediatePropagation();
              }
            }
            if(incompScript.length > 0) {
              cgToast(
                i18n.t("js.sides.output.options.reject.incompatible", {count:incompScript.length}), {
                  showCloseButton: true,
                  hiding: 'manual',
                  className: 'error-toast body-attached',
                  containerID: 'docBody'
                }
              );
            }
          }
        });
        document.getElementById('circleScColor').onclick = changeBlockColor;
        document.getElementById('contArrowColor').onclick = changeBlockColor;
      },
      showLoaderOnConfirm: true,
      useRejections: true, //important for swal2 v7.1.2
      expectRejections: true,
      preConfirm: function(text) {
        return new Promise(function(resolve, reject) {
          let selectedSceneUnselMode = document.querySelector('input[name="cg-unsel-sc-opt"]:checked');
          if(selectedSceneUnselMode === null) {
            reject(i18n.t("js.sides.output.options.reject.selection"));
            return;
          }
          tmp.unselScenesRemovalMode = parseInt(selectedSceneUnselMode.value);
          tmp.withContinuityArrows = document.getElementById('continuityArrChk').checked;
          tmp.withCircledScenes = document.getElementById('circleScChk').checked;
          tmp.contArrowsColor = document.getElementById('contArrowColor').getAttribute('color');
          tmp.sceneCirclesColor = document.getElementById('circleScColor').getAttribute('color');

          resolve();
        });
      }
    }).then(function () {
      // No callsheets to attach so submit the request to generate the sides.
      if (callsheets.length === 0) {
        submitSidesRequest(tmp, selectionData);
      } else {
        swal({
          animation: false,
          title: i18n.t("js.sides.callsheet.title"),
          html: createCallSheetOptions(),
          input: 'text',
          inputClass: 'hidden-input', //used to prevent focus error on reject
          showCloseButton: true,
          confirmButtonText: i18n.t("button.submit"),
          confirmButtonColor: '#13C46A',
          allowOutsideClick: false,
          showLoaderOnConfirm: true,
          showCancelButton: true,
          cancelButtonText: i18n.t("js.sides.callsheet.skip"),
          useRejections: true, //important for swal2 v7.1.2
          expectRejections: true,
          preConfirm: function (text) {
            return new Promise(function (accept, refuse) {
              console.debug('checking callsheet settings');

              let callSheetId = document.getElementById('callSheetSelect').value;
              if (!callSheetId) {
                accept();
                return;
              }

              let pageInput = document.getElementById('callSheetPageInput');
              if (pageInput.value.trim().length !== 0) { // must accept empty string (matches all pages)
                for (let pageNbString of pageInput.value.trim().split(/,\s*/g)) {
                  if (pageNbString.trim().match(/^\d+(\s*-\s*\d+)?$/) == null) {
                    refuse(i18n.t("js.sides.callsheet.refuse"));
                    return;
                  }
                }
              }
              try {
                document.querySelector('.swal2-styled.swal2-cancel').style.cursor = 'not-allowed';
              } catch (e) {
                console.error(e);
              }

              selectionData.callSheetId = callSheetId;
              selectionData.callSheetPages = pageInput.value.trim().replace(/^\s*$/, '-');
              accept();
            });
          }
        }).then(function () {
          submitSidesRequest(tmp, selectionData);
        }, dismiss => {
          if (dismiss === 'cancel') { // cancel = skip button);
            submitSidesRequest(tmp, selectionData);
          }
        });
      }
    }).catch(swal.noop);
  }).catch(swal.noop);
}

function submitSidesRequest(tmp, selectionData) {
  let generationResults = 'failed';

  saveSystemActivity({
    action: 'submit',
    params: 'scenes~' + selectionData.scenes,
    message: 'Sides submitted for synchronous generation.'
  });

  console.debug(tmp, selectionData);
  showSpinner();

  apicall('scriptapi', 'createAndStoreSidesSync', tmp, selectionData).then((resp) => {
    // best to clear stored files whether there's been an error or not, 
    // in case some of the file formats have been successfully generated
    DocUtility.clearStoredFiles();
    if(resp.responseCode === '0') {
      generationResults = 'sync';
      DownloadNotification.add(false, false, resp.responseMessage.split(",")[0], 
        resp.responseMessage.split(",")[1] || null, new Date().getTime());
    } else {
      throw new Error('server error');
    }
  }).catch(() => {
    console.error('sides gen failure');
    generationResults = 'server error';
  }).then(function() {
    hideSpinner();
    if(generationResults === 'sync') {
      swal({
        title: i18n.t("response.success"),
        html: i18n.t("js.sides.generating.title"),
        type: 'success',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK"),
        showCancelButton: false,
      }).catch(swal.noop);
    } else {
      let failureMsg = (generationResults && generationResults !== 'failed')
        ? i18n.t("js.sides.gen.failure.msg1",{results:generationResults}):i18n.t("js.sides.gen.failure.msg2");
  
      swal({
        title: i18n.t("js.sides.gen.failure.title"),
        type: 'warning',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK"),
        html: failureMsg,
        allowOutsideClick: false,
        useRejections: true, //important for swal2 v7.1.2
        onOpen: function() {
          document.getElementById('sidesSupportLink').onclick = function() {
            window.hideTroubleshooterOnSupport = true;
            document.getElementById('redButtonLink').click();
          };
        }
      }).catch(swal.noop);
    }        
  });
}

function createFormatOptionCheckboxes() {//COMEHERE : CONTINUE FROM HERE
  let swalContent = document.createElement('div');

  let outputOptions = document.createElement('div');
  outputOptions.style.margin = '30px 0 30px 0';

  let oneUp = document.createElement('input');
  oneUp.id = 'oneUpCheckbox';
  oneUp.type = 'checkbox';
  oneUp.style.verticalAlign = 'middle';

  let oneUpLabel = document.createElement('label');
  oneUpLabel.id = 'oneUpLabel';
  oneUpLabel.setAttribute('for','oneUpCheckbox');
  oneUpLabel.title = i18n.t("js.sides.format.options.1up.title");
  oneUpLabel.textContent = i18n.t("js.sides.format.options.1up.label");

  let twoUp = document.createElement('input');
  twoUp.id = 'twoUpCheckbox';
  twoUp.type = 'checkbox';
  twoUp.style.verticalAlign = 'middle';

  let twoUpLabel = document.createElement('label');
  twoUpLabel.id = 'twoUpLabel';
  twoUpLabel.setAttribute('for','twoUpCheckbox');
  twoUpLabel.title = i18n.t("js.sides.format.options.2up.title");
  twoUpLabel.textContent = i18n.t("js.sides.format.options.2up.label");;

  let twoUpFormats = document.createElement('div');
  twoUpFormats.id = 'twoUpFormatsContainer';
  twoUpFormats.style.display = 'none';
  let twoUpFormatsTitle = document.createElement('div');
  twoUpFormatsTitle.textContent = i18n.t("js.sides.format.options.2up.opts.label");
  twoUpFormats.appendChild(twoUpFormatsTitle);
  let largeTwoUpFormatInput = document.createElement('input');
  largeTwoUpFormatInput.type = 'radio';
  largeTwoUpFormatInput.name = 'two-up-format';
  largeTwoUpFormatInput.id = 'largeTwoUpFormat';
  let largeTwoUpFormatLabel = document.createElement('label');
  largeTwoUpFormatLabel.textContent = i18n.t("js.sides.format.options.2up.opt1.label");
  largeTwoUpFormatLabel.value = 'large';
  largeTwoUpFormatLabel.setAttribute('for', 'largeTwoUpFormat');
  let smallTwoUpFormatInput = document.createElement('input');
  smallTwoUpFormatInput.type = 'radio';
  smallTwoUpFormatInput.name = 'two-up-format';
  smallTwoUpFormatInput.id = 'smallTwoUpFormat';
  let smallTwoUpFormatLabel = document.createElement('label');
  smallTwoUpFormatLabel.textContent = i18n.t("js.sides.format.options.2up.opt2.label");
  smallTwoUpFormatLabel.value = 'small';
  smallTwoUpFormatLabel.setAttribute('for', 'smallTwoUpFormat');

  twoUpFormats.appendChild(largeTwoUpFormatInput);
  twoUpFormats.appendChild(largeTwoUpFormatLabel);
  twoUpFormats.appendChild(smallTwoUpFormatInput);
  twoUpFormats.appendChild(smallTwoUpFormatLabel);

  let fileNameInputContainer = document.createElement('div');
  fileNameInputContainer.style.position = 'relative';
  let fileNameInput = document.createElement('input');
  fileNameInput.type = 'text';
  fileNameInput.setAttribute('placeholder', i18n.t("documents.filename"));
  fileNameInput.style.borderRadius = '0.2rem';
  fileNameInput.style.paddingRight = '50px';
  fileNameInput.id = 'sidesGenFileNameInput';
  fileNameInputContainer.appendChild(fileNameInput);

  let extensionSpan = document.createElement('span');
  extensionSpan.textContent = '.pdf';
  extensionSpan.id = 'sidesGenSwalExt';
  extensionSpan.style.color = 'gray';
  extensionSpan.style.fontSize = '20px';
  extensionSpan.style.position = 'absolute';
  extensionSpan.style.right = '0.75rem';
  extensionSpan.style.top = '0.4rem';
  extensionSpan.style.fontWeight = '400';
  fileNameInputContainer.appendChild(extensionSpan);


  outputOptions.appendChild(oneUp);
  outputOptions.appendChild(oneUpLabel);
  outputOptions.appendChild(twoUp);
  outputOptions.appendChild(twoUpLabel);
  outputOptions.appendChild(twoUpFormats);
  swalContent.appendChild(outputOptions);
  swalContent.appendChild(fileNameInputContainer);

  return swalContent.outerHTML;
}

function createCallSheetOptions() {
  let container = document.createElement('div');

  let selectCSTitle = document.createElement('div');
  selectCSTitle.innerHTML = i18n.t("js.sides.callsheet.text");
  selectCSTitle.style.marginTop = '0.6rem';
  selectCSTitle.style.marginBottom = '0.5rem';
  container.appendChild(selectCSTitle);

  let csSelect = document.createElement('select');
  csSelect.id = 'callSheetSelect';

  let orderedCallsheets = DocUtility.sortCallsheetOptions(callsheets);

  orderedCallsheets.forEach(callsheet => {
    let csOpt = document.createElement('option');
    if(typeof callsheet.properties.fileName == 'string') {
      csOpt.textContent = callsheet.properties.fileName;
      csOpt.value = callsheet.key.name;
    }
    csSelect.appendChild(csOpt);
  });
  container.appendChild(csSelect);

  let lineBreak = document.createElement('br');
  lineBreak.style.lineHeight = '2rem';
  container.appendChild(lineBreak);

  let selectPagesTitle = document.createElement('div');
  selectPagesTitle.innerHTML = i18n.t("js.sides.callsheet.subtext.p1")
    +'<br/><span style="font-size:0.75rem">'
    +i18n.t("js.sides.callsheet.subtext.p2")
    +'</span>';
  selectPagesTitle.style.marginBottom = '0.5rem';
  container.appendChild(selectPagesTitle);

  let pageInput = document.createElement('input');
  pageInput.id = 'callSheetPageInput';
  pageInput.type = 'text';
  pageInput.placeholder = 'Ex.: 1, 3, 5 - 8';
  container.appendChild(pageInput);

  return container.outerHTML;
}

function createSceneOptCheckboxes() {
  let removeUnselDiv = document.createElement('div');
  removeUnselDiv.id = 'removeUnselDiv';
  removeUnselDiv.style.margin = '20px 0 0 0';

  let removeUnselTitle = document.createElement('p');
  removeUnselTitle.textContent = i18n.t("js.sides.scene-opts.unsel.title");
  removeUnselTitle.style.color = '#9e9e9e';

  let crossOutMaskUnselChkbx = document.createElement('input');
  crossOutMaskUnselChkbx.type = 'radio';
  crossOutMaskUnselChkbx.id = 'crossOutMaskUnselChkbx';
  crossOutMaskUnselChkbx.name = 'cg-unsel-sc-opt';
  crossOutMaskUnselChkbx.className = 'cg-unsel-sc-opt';
  crossOutMaskUnselChkbx.value = UNSEL_SCENE_OPTIONS.CROSSOUT_MASK.toString();
  crossOutMaskUnselChkbx.setAttribute('checked', true);

  let crossOutMaskUnselLabel = document.createElement('label');
  crossOutMaskUnselLabel.setAttribute('for', 'crossOutMaskUnselChkbx');
  crossOutMaskUnselLabel.textContent = i18n.t("js.sides.scene-opts.unsel.cross-mask");

  let crossOutUnselChkbx = document.createElement('input');
  crossOutUnselChkbx.type = 'radio';
  crossOutUnselChkbx.id = 'crossOutUnselChkbx';
  crossOutUnselChkbx.name = 'cg-unsel-sc-opt';
  crossOutUnselChkbx.className = 'cg-unsel-sc-opt';
  crossOutUnselChkbx.value = UNSEL_SCENE_OPTIONS.CROSSOUT.toString();

  let crossOutUnselLabel = document.createElement('label');
  crossOutUnselLabel.setAttribute('for', 'crossOutUnselChkbx');
  crossOutUnselLabel.textContent = i18n.t("js.sides.scene-opts.unsel.cross");

  let removeUnselChkbx = document.createElement('input');
  removeUnselChkbx.type = 'radio';
  removeUnselChkbx.id = 'removeUnselChkbx';
  removeUnselChkbx.name = 'cg-unsel-sc-opt';
  removeUnselChkbx.className = 'cg-unsel-sc-opt';
  removeUnselChkbx.value = UNSEL_SCENE_OPTIONS.REMOVE.toString();

  let removeUnselLabel = document.createElement('label');
  removeUnselLabel.setAttribute('for', 'removeUnselChkbx');
  removeUnselLabel.textContent = i18n.t("js.sides.scene-opts.unsel.hide");

  removeUnselDiv.appendChild(removeUnselTitle);
  removeUnselDiv.appendChild(removeUnselChkbx);
  removeUnselDiv.appendChild(removeUnselLabel);
  removeUnselDiv.appendChild(crossOutUnselChkbx);
  removeUnselDiv.appendChild(crossOutUnselLabel);
  removeUnselDiv.appendChild(crossOutMaskUnselChkbx);
  removeUnselDiv.appendChild(crossOutMaskUnselLabel);

  let selectedScenesDiv = document.createElement('div');
  selectedScenesDiv.id = 'selectedScenesDiv';
  selectedScenesDiv.style.margin = '20px 0 20px 0';

  let selScenesTitle = document.createElement('p');
  selScenesTitle.textContent = i18n.t("js.sides.scene-opts.sel.title");
  selScenesTitle.style.color = '#9e9e9e';

  let selScLeftContainer = document.createElement('div');
  selScLeftContainer.style.display = 'inline-block';
  selScLeftContainer.style.textAlign = 'left';
  selScLeftContainer.style.marginLeft = '10px';

  let circleScChk = document.createElement('input');
  circleScChk.type = 'checkbox';
  circleScChk.id = 'circleScChk';

  let circleScLabel = document.createElement('label');
  circleScLabel.setAttribute('for', 'circleScChk');
  circleScLabel.id = 'circleScLabel';
  circleScLabel.textContent = i18n.t("js.sides.scene-opts.sel.circle");

  let selScRightContainer = document.createElement('div');
  selScRightContainer.style.display = 'inline-block';
  selScRightContainer.style.textAlign = 'left';

  let circleScColor = document.createElement('div');
  circleScColor.id = 'circleScColor';
  circleScColor.className = 'color-block';
  circleScColor.setAttribute('color', 'black');

  let continuityArrChk = document.createElement('input');
  continuityArrChk.type = 'checkbox';
  continuityArrChk.id = 'continuityArrChk';

  let continuityArrLabel = document.createElement('label');
  continuityArrLabel.setAttribute('for', 'continuityArrChk');
  continuityArrLabel.id = 'continuityArrLabel';
  continuityArrLabel.textContent = i18n.t("js.sides.scene-opts.sel.arrow");

  let contArrowColor = document.createElement('div');
  contArrowColor.id = 'contArrowColor';
  contArrowColor.className = 'color-block';
  contArrowColor.setAttribute('color', 'black');

  let leftLineBreak = document.createElement('br');
  leftLineBreak.style.lineHeight = '28px';

  let rightLineBreak = document.createElement('br');
  rightLineBreak.style.lineHeight = '28px';

  selectedScenesDiv.appendChild(selScenesTitle);
  selScLeftContainer.appendChild(circleScChk);
  selScLeftContainer.appendChild(circleScLabel);
  selScLeftContainer.appendChild(leftLineBreak);
  selScLeftContainer.appendChild(continuityArrChk);
  selScLeftContainer.appendChild(continuityArrLabel);
  selectedScenesDiv.appendChild(selScLeftContainer);

  selScRightContainer.appendChild(circleScColor);
  selScRightContainer.appendChild(rightLineBreak);
  selScRightContainer.appendChild(contArrowColor);
  selectedScenesDiv.appendChild(selScRightContainer);

  return removeUnselDiv.outerHTML + selectedScenesDiv.outerHTML;
}

function changeBlockColor() {
  let newColor;

  switch(this.getAttribute('color')) {
    case 'black': newColor = 'red'; break;
    case 'red': newColor = 'blue'; break;
    case 'blue': newColor = 'green'; break;
    case 'green': newColor = 'black'; break;
  }

  this.setAttribute('color', newColor);
  this.style.backgroundColor = getRgb(newColor);
}

function getRgb(color) {
  switch(color.toLowerCase().trim()) {
    case "red": return 'rgb(255, 0, 0)';
    case "green": return 'rgb(0, 255, 0)';
    case "blue": return 'rgb(0, 0, 255)';
    default: return 'rgb(0, 0, 0)'; // black
  }
}

function findUnsupportedChars(docName){
  let invalidChars = ['<','>',':','\\','"','/','|','?','!','%','~',',','*','#','$'];
  let invalidCharsFound = new Array();
  for(let i = 0, car; car = docName.charAt(i++);) {
    if(invalidChars.includes(car)) {
      invalidCharsFound.push(car);
    }
  }
  return invalidCharsFound;
}