import i18n from './Translation';
import './SidePanel.css';

export default class SidePanel {
  /**
   * The side panel constructor
   * @param {String} [containerID='main'] The panel's parent element's ID.
   * Note that the given parent element's position will be set to relative.
   * @constructor
   */
  constructor(containerID = 'main', useNoOptionPlaceholder = false) {
    this.useNoOptionPlaceholder = useNoOptionPlaceholder;
    this.selectedItems = new Object();
    this.container = document.getElementById(containerID);
    this.container.style.position = 'relative';
    this.htmlElement = createSidePanel(this);

    function createSidePanel(that) {
      let panel = document.createElement('div');
      panel.className = 'component side-panel';
      panel.style.visibility = 'hidden';
      that.container.appendChild(panel);

      let content = that.panelContent = document.createElement('div');
      content.className = 'panel-content';
      panel.appendChild(content);

      let selectedCount = document.createElement('div');
      selectedCount.className = 'panel-selected-count';
      selectedCount.textContent = i18n.t("js.sidepanel.empty.count");
      content.appendChild(selectedCount);

      let xButton = document.createElement('div');
      xButton.className = 'panel-x-button';
      xButton.textContent = 'x';
      xButton.onclick = function() { that.hide() };
      content.appendChild(xButton);

      let noOptionPlaceholder = document.createElement('div');
      noOptionPlaceholder.textContent = i18n.t("js.sidepanel.empty.label");
      noOptionPlaceholder.className = 'panel-no-option-placeholder';
      noOptionPlaceholder.style.display = 'none'; // should be hidden before CSS loads
      content.appendChild(noOptionPlaceholder);

      let hideOpt = document.createElement('div');
      hideOpt.className = 'panel-hide-option';
      hideOpt.innerHTML = i18n.t("js.sidepanel.hide") + '&nbsp;&nbsp;';
      hideOpt.onclick = function() { that.hide() };
      content.appendChild(hideOpt);

      let hideImg = document.createElement('i');
      hideImg.className = 'icon-chevron-right';
      hideOpt.appendChild(hideImg);

      return panel;
    }
  }

  /**
   * Adds a list item to the panel
   * @param  {htmlElement} liIcon      The icon as an htmlElement
   * @param  {string} liText      The list item's text
   * @param  {function} clickAction [optional] The function to execute on click
   * @param  {string Array} categories  [optional] The array of corresponding categories
   */
  addListItem(liIcon, liText, clickAction, categories) {
    let content = this.htmlElement.querySelector('.panel-content');

    let li = document.createElement('div');
    li.className = 'panel-list-item';
    li.setAttribute('categories', typeof categories === 'object'
      ?categories.join('~') :(typeof categories === 'string' ?categories :''));

    let innerLi = document.createElement('div');
    innerLi.style.display = 'table';

    let iconDiv = document.createElement('div');
    iconDiv.className = 'panel-li-icon';
    if(liIcon && typeof liIcon === 'string') {
      let icon = new Image(25, 25);
      icon.src = liIcon;
      iconDiv.appendChild(icon);
    } else if(liIcon) {
      iconDiv.appendChild(liIcon);
    }
    innerLi.appendChild(iconDiv);

    let liTextDiv = document.createElement('div');
    liTextDiv.textContent = liText;
    liTextDiv.className = 'panel-li-text';
    innerLi.appendChild(liTextDiv);

    li.appendChild(innerLi);

    let that = this;
    if(typeof clickAction === 'function') {
      li.onclick = function(event) {
        event.panel = that;
        clickAction(event);
      }
    }
    content.appendChild(li);
  }

  /**
   * Adds a custom selected item to the selection map.
   * @param  {string} referenceID [description]
   * @param  {*} item [description]
   */
    addSelectedItem(referenceID, item) {
    this.selectedItems[referenceID] = item;
    let nbOfSelectedItems = Object.keys(this.selectedItems).length;
    this.htmlElement.querySelector('div.panel-selected-count').textContent = i18n.t("js.sidepanel.selection", {count: nbOfSelectedItems});
  }

  /**
   * Removes a selected item from the selection map
   * @param  {string} referenceID The referenceID of the element to delete
   */
  removeSelectedItem(referenceID) {
    try {
      delete this.selectedItems[referenceID];
      let nbOfSelectedItems = Object.keys(this.selectedItems).length;
      this.htmlElement.querySelector('div.panel-selected-count').textContent = i18n.t("js.sidepanel.selection", {count: nbOfSelectedItems});
    } catch(e) {
      console.debug('item ' + referenceID + ' does not exist in the side panel');
    }
  }

  /**
   * Removes all selected items from the selection map
   */
  clearSelected() {
    this.selectedItems = new Object();
    this.htmlElement.querySelector('div.panel-selected-count').textContent = i18n.t("js.sidepanel.empty.count");
  }

  /**
   * Shows the side panel
   * @param  {string Array} categories [optional] An array of list-item categories to
   * be displayed
   */
  show(categories) {
    let isAtLeastOneVisibleOption = false;
    if(categories) {
      categories = typeof categories === 'string' ?[categories] :categories;
      [].slice.call(this.htmlElement.querySelectorAll('div.panel-list-item'), 0).forEach((item) => {
        item.style.display = 'none';
        categories.forEach((category) => {
          if(item.getAttribute('categories').split('~').includes(category)) {
            item.style.display = 'inline-block';
            isAtLeastOneVisibleOption = true;
          }
        });
      })
    } else {
      [].slice.call(this.htmlElement.querySelectorAll('div.panel-list-item'), 0).forEach((item) => {
        item.style.display = 'inline-block';
        isAtLeastOneVisibleOption = true;
      });
    }
    if(this.useNoOptionPlaceholder) {
      this.htmlElement.querySelector('div.panel-no-option-placeholder').style.display = isAtLeastOneVisibleOption ? 'none':'block';
    }
    this.htmlElement.style.visibility = 'visible';
    this.htmlElement.classList.add('show-sidepanel');
  }

  /**
   * Hides the panel
   */
  hide() {
    this.htmlElement.classList.remove('show-sidepanel');
  }
}